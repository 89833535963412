import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Footer from "./../Footer";
import PaymentSuccessInnner from "./PaymentSuccessInner";
const PaymentSuccessful = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <PaymentSuccessInnner/>
            <Footer />
        </>
    );
};

export default PaymentSuccessful;