import axios from "axios";
import { CurrentUrl as Url } from "./UrlApi";

export const getStateList = async () => {
  return await fetch(`${Url}Pincode/State`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetpincodeByState = async (statename, cityname) => {
  const token = localStorage.getItem("Logkey");
  if (cityname === "") {
    return await fetch(`${Url}Pincode/Getpincode`, {
      method: "POST",
      body: JSON.stringify({ state: statename }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }).then((data) => data.json());
  } else {
    return await fetch(`${Url}Pincode/Getpincode`, {
      method: "POST",
      body: JSON.stringify({ state: statename, cityname: cityname }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }).then((data) => data.json());
  }
};
export const GetcityByState = async (Statenm) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Pincode/Getcitynames/${Statenm}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
// export const SaveZone=async (zonename,pincodes)=>{
export const SaveZone = async (zonedata) => {
  const token = localStorage.getItem("Logkey");
  if (zonedata.zoneid === 0) {
    return await axios
      .post(
        `${Url}Createzone`,
        JSON.stringify({
          Zonename: zonedata["zonename"],
          Pincodes: zonedata["pincodes"],
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `*`,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  } else {
    return await axios
      .put(
        `${Url}Updatezone`,
        JSON.stringify({
          zonename: zonedata["zonename"],
          pincodes: zonedata["pincodes"],
          zoneid: zonedata["zoneid"],
        }),
        {
          headers: {
            "access-control-allow-credentials": "true",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `*`,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
};
export const GetZoneName = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getcurrentlpzone`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetpincodeByzoneId = async (zoneId) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getpincodebyzone/${zoneId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const Checkzonenm = async (zonenm) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}checkname/${zonenm}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const DeleteZone = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios(`${Url}DeleteZone/${id}`, {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetzoneByid = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getzonebyid/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const autocompletepins = async (pins) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Pincode/autocomplete/${pins}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
