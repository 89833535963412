import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Footer from "./../Footer";
import PaymentFailureInner from "./PaymentFailureInner";
const PaymentFailure = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PaymentFailureInner/>
      <Footer />
    </>
  );
};

export default PaymentFailure;