import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import VendorTemplate from "./VendorTemplate";
import Footer from "./../Footer";
const VendorOrderPlaced = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <VendorTemplate />
      <Footer />
    </>
  );
};

export default VendorOrderPlaced;
