import * as React from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useState, useEffect } from "react";
import {CustomersofVendor} from "../Services/VendorApi"
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Admin/`;
function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default function VendorCustomerList() {
  const [rows, setCustomers] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");

  // const Refreshafterdelete = (emailVal) => {
  //   CustomersofVendor(emailVal)
  //     .then((result) => {
  //       result.data.sort(
  //         (a, b) => new Date(b.inserttime) - new Date(a.inserttime)
  //       );
  //       setCustomers(result.data);
  //     })
  //     .catch((r) => {
  //       console.log(r);
  //     });
  // };

  // const Delete = async (emailVal) => {
  //   const token = localStorage.getItem("Logkey");
  //   return await fetch(`${Url}DeleteUserVendor?email=${emailVal}`, {
  //     method: "PUT",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "content-type": "text/plain; charset=utf-8",
  //       "Access-Control-Allow-Origin": `*`,
  //     },
  //   })
  //     .then((res) => {
  //       Refreshafterdelete(emailVal);
  //       console.log(res);
  //       return res;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       return e;
  //     });
  // };




  // const CustomerSwitch = ({ email, isActive, activateuser, Deactivateuser }) => {
  //   const [switchValue, setSwitchValue] = useState(isActive);
  
  //   const handleChange = (e) => {
  //     if (e.target.checked) {
  //       activateuser(email)
  //         .then(() => {
  //           setSwitchValue(true);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     } else {
  //       Deactivateuser(email)
  //         .then(() => {
  //           setSwitchValue(false);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   };
  
  //   return (
  //     <Switch checked={switchValue} onChange={handleChange} />
  //   );
  // };

  const columns = [
    // { field: "id", headerName: "ID" },
    { field: "name", headerName: "Customer  Name", flex: 1 },
    { field: "inserttime", headerName: "Date", type: "date", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    // {
    //   field: "isactive",
    //   flex: 1,
    //   headerName: "Active / Deactive",
    //   sortable: false,
    //   flex: 1,
    //   renderCell: (params, index) => {
    //     return (
    //       // <Switch
    //       //   checked={params.value}
    //       //   onChange={(e) => {
    //       //     let email = params.row.email;
    //       //     if (e.target.checked) {
    //       //       activateuser(email);
    //       //     } else {
    //       //       Deactivateuser(email);
    //       //     }
    //       //   }}
    //       // />
    //       <CustomerSwitch
    //       email={params.row.email}
    //       isActive={params.value}
    //       activateuser={activateuser}
    //       Deactivateuser={Deactivateuser}
    //     />
    //     );
    //   },
    // },
    // {
    //   field: "action",
    //   width: 150,
    //   headerName: "Action",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const role = localStorage.getItem("user_role").split(",");
    //     return (
    //       <Box className="d-flex">
    //         {role.includes("Admin") ? (
    //           <>
    //             <Button href={"/#/userdetails?email=" + params.row.email}>
    //               <Avatar sx={{ width: 40, height: 40, bgcolor: grey[300] }}>
    //                 <DriveFileRenameOutlineTwoToneIcon
    //                   fontSize="small"
    //                   sx={{ color: grey[900] }}
    //                 />
    //               </Avatar>
    //             </Button>
    //           </>
    //         ) : (
    //           <></>
    //         )}
    //         <Button
    //           onClick={() => {
    //             console.log("need to delete", params.row.email);
    //             Delete(params.row.email);
    //           }}
    //         >
    //           <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
    //             <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
    //           </Avatar>
    //         </Button>
    //       </Box>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = () => {
    setCustomers([]);

    var params = "";
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    CustomersofVendor(localStorage.getItem("user_email"),params)
      .then((result) => {
       // console.log(result);
        result.data.sort(
          (a, b) => new Date(b.inserttime) - new Date(a.inserttime)
        );
        setCustomers(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Customers List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Customers List</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value="all"
                  label="Report Type"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="Activate">Active</MenuItem>
                  <MenuItem value="Deactivate">Deactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                inputFormat="DD/MM/YYYY"
                  label="From"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                onClick={() => {
                  loadCustomers();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
            <div style={{ height: "80vh", width: "100%" }}>
              <DataGrid
                componentsProps={{
                  toolbar: {
                    printOptions: {},
                  },
                }}
                sx={{
                  p: "1rem",
                  "@media print": {
                    ".table-ui": { border: "none" },
                  },
                }}
                className="table-ui"
                getRowId={(row) => row.id ?? row.name}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </Card>
        </div>
      </section>
    </div>
  );
}
