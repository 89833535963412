import axios from "axios";
import { CurrentUrl } from "./UrlApi";
let Url = `${CurrentUrl}User/`;
let paymenturl = `${CurrentUrl}api/Payment/initialize`;
let confirmpayment = `${CurrentUrl}api/Payment/confirm`;
let walletpaymenturl = `${Url}InitializeWalletMoney`;
let walletconfirmpaymenturl = `${Url}AddWalletMoney`;

localStorage.setItem("mypaymenturl", paymenturl);
localStorage.setItem("paymentconfirm", confirmpayment);
localStorage.setItem("walletpaymenturl", walletpaymenturl);
localStorage.setItem("walletconfirmpaymenturl", walletconfirmpaymenturl);

// let paymenturlcc = `${CurrentUrl}PaymentCC/InitializePayment`;
// let confirmpaymentcc = `${CurrentUrl}PaymentCC/ConfirmPayment  `;

// localStorage.setItem("mypaymenturlcc", paymenturlcc);
// localStorage.setItem("paymentconfirmcc", confirmpaymentcc);
export const CustomerDashboardDetails = async (email) => {
  //debugger;
  const token = localStorage.getItem("Logkey");
  // const { token } = useAuth();
  // console.log("Token from useAuth:", token);
  return await axios
    .get(`${CurrentUrl}User/GetDashboardDetails?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log("my api response", response);
      return response;
    })
    .catch((error) => {
      console.log("my api error", error);
      return error.response;
    });
};
export const GetOrderItemsList = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetOrderItemslist?orderid=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

export const downloadDocketcustomer = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}DownloadLabelAPI?OrderNo=${orderid}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        //accept: "text/xml",
        "content-type": "application/pdf",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const downloadMasterDocketcustomer = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}GenerateMasterDocket?OrderNo=${orderid}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        //accept: "text/xml",
        "content-type": "application/pdf",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetUserLastLogin = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetUserLastlogin?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetcityByState = async (Statename) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${CurrentUrl}Pincode/Getcitynames/${Statename}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetCompanyCity = async (Statename) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${CurrentUrl}Pincode/Getcitynames/${Statename}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetBusinessCity = async (Statename) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${CurrentUrl}Pincode/Getcitynames/${Statename}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const setdefaultbank = async (email, accountNo) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}setIsDefault?Email=${email}&Accountnumber=${accountNo}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "text/xml",
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetVendorRules = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetVendorRules?lpid=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const UserLastLogin = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}UserLastLogin?Email=${email}`,
      JSON.stringify({
        email: localStorage.getItem("user_email"),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const PieChart = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetPieChart?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetCustomerBasicDetails = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetCustomerBasicDetails?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetMvikasAccountType = async (email, amount) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetMVikasAccount?Email=${email}&Amount=${amount}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetMvikasAccountSettings = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetMVikasAccount?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetPaymentList = async (email, param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetPayments?Email=${email}&${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Pickupslot = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}PickupSlot?Lpid=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const DeliverySlot = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetDeliverySlot?Lpid=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetCurrentAddress = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetCurrentAddress?Address_Id=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetCurrentBankDetails = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetCurrentAccountList?AccountNo=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetPayLaterdata = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetPaylater?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const TrackingOrder = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}TrackingOrderStatus?Orderid=${obj}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const UpdateUserDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}UpdateCustomerDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const PayLater = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${CurrentUrl}api/Payment/PayLater`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const ToPayPayment = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my topay body", obj);
  return await fetch(`${CurrentUrl}api/Payment/ToPay`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const walletPayment = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${CurrentUrl}api/Payment/BookOrderByWallet`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const AddCustomerBankDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("banking", obj);
  return await fetch(`${Url}AddBankAccountdetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const Deletebank = async (email) => {
  const token = localStorage.getItem("Logkey");
  // console.log("my post", obj);
  return await fetch(`${Url}DeleteBankAccount?AccountNo=${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    // body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const UpdateBank = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}UpdateBankAccountdetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const Deleteaddress = async (email) => {
  const token = localStorage.getItem("Logkey");
  // console.log("my post", obj);
  return await fetch(`${Url}DeleteAddress?Address_Id=${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    // body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const AddNewAddress = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AddNewAddress`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const CustomerChangePassword = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}CustomerChangePassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};
export const GetCustomerOrderlist = async (email, orderType, param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetCustomerOrderlist?Email=${email}&${param}&IsB2C=${orderType}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetServiceType = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetServiceType`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetBankList = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetBankAccountList?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetCustomerInvoice = async (email, param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetInvoiceList?Email=${email}&${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetAddressList = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetAddressList?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetStateList = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${CurrentUrl}Pincode/State`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Viewbankdetails = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetCurrentAccountList?AccountNo=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("my response", response);
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Viewaddress = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetCurrentAddress?Address_Id=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

//----------------------------------------------

export const GenerateOTP = async (number) => {
  return await fetch(`${Url}Regotp/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
export const ResendOTP = async (number) => {
  return await fetch(`${Url}Resendotp/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
export const SaveReg = async (userData) => {
  console.log("userData", userData);
  return await axios
    .post(
      `${Url}Signup`,
      JSON.stringify({
        name: userData.uname,
        email: userData.email,
        mobileno: userData.mobile,
        password: userData.password,
        usertype: userData.utype,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Login = async (email, password, remember) => {
  return await axios
    .post(
      `${Url}Login`,
      JSON.stringify({
        username: email,
        password: password,
        rememberme: remember,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Accept: "text/html",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GenerateCurrentUserlogin = async (token, user_id) => {
  //debugger;

  return await fetch(`${Url}Getcurrentuserdetail?Id=${user_id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
// LOG IN WITH OTP LOG IN WITH OTP
export const LoginWithOTP = async (number) => {
  return await fetch(`${Url}Otplogin/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
export const Loginwithno = async (mobile, remember) => {
  return await axios
    .post(
      `${Url}Login`,
      JSON.stringify({ username: mobile, rememberme: remember }),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Checknumber = async (number) => {
  return await fetch(`${Url}Checknumber/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
export const Checkemail = async (email) => {
  return await fetch(`${Url}Verifyemail/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((data) => data.json());
};
export const Logout = async () => {
  return await axios
    .post(`${Url}Signout`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
// SubmitAccount SubmitAccount
export const UserAccount = async (details) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Updateprofile`, JSON.stringify(details), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
//VerifyEmail; //VerifyEmail;
export const VerifyEmail = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}SendConfirmEmail`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
//POST VerifyEmail; // POST VerifyEmail;
export const VerifyEmailPost = async (id, Token) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}ConfirmEmail`,
      JSON.stringify({
        id: id,
        token: Token,
      }),
      {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
//Forget Pass; // Forget Pass;;
export const ForgetPassword = async (email) => {
  return await axios
    .post(`${Url}Forgetpasswordtoken/${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
//Add New Pass; // Add New Pass
export const AddForgetPassword = async (password, token, email) => {
  return await axios
    .post(
      `${Url}Forgetpassword`,
      JSON.stringify({
        email: email,
        token: token,
        password: password,
      }),
      {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const SendEnquiry = async (userdata) => {
  return await axios
    .post(`${Url}Sendequiry`, JSON.stringify(userdata), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getformula = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getformula`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const SaveAddress = async (userdata) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Saveaddress`, JSON.stringify(userdata), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetAddress = async () => {
  const token = localStorage.getItem("Logkey");
  console.log("");
  return await axios
    .get(`${Url}GetAddress`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const saveratesettings = async (name, value) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}saveratesettings`,
      JSON.stringify({ name: name, value: value }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `*`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getratesettings = async (
  name,
  id = localStorage.getItem("user_id")
) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getratesettings?name=${name}&lpid=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Deletesetting = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios(`${Url}deletesetting/${id}`, {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetOrderReview = async (Orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetOrderReview?Orderid=${Orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetReviewList = async (lpid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetReviewDetailList?lpid=${lpid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetReviewDetails = async (lpid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetReviewDetails?lpid=${lpid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const CheckIsReviewed = async (customerid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CheckIsReviewed?customerid=${customerid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const submitorderreview = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}AddReview`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetItemCategories = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetItemCategories`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetItemSubCategories = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetItemSubCategories?Id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetItemPackagingType = async (type) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetItemPackagingType?Type=${type}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GenerateDocket = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GenerateDocket?OrderId=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GenerateMvikasDocketNumber = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetMvikasDocketNumber`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
//wallet money apis
export const getAvailableWalletBalance = async (userid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetWalletBalance?userId=${userid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const getAvailablePaymentMethodforWallet = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetWalletPaymentMethod`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
// export const addMoneyInWallet = async (obj) => {
//   const token = localStorage.getItem("Logkey");
//   return await axios
//     .post(`${Url}InitializeWalletMoney`, obj, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": `*`,
//       },
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

// export const confirmWalletMoney = async () => {
//   const token = localStorage.getItem("Logkey");
//   return await axios
//     .post(`${Url}AddWalletMoney`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": `*`,
//       },
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

export const GetFloorData = async (lpId, IsB2c) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetAppointmentAndFloorCharges?lpId=${lpId}&IsB2C=${IsB2c}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetFloorDataCharge = async (FloorId, Boxes, IsB2c, lpId) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CalculatedFloorCharge?FloorId=${FloorId}&Boxes=${Boxes}&IsB2C=${IsB2c}&lpId=${lpId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
