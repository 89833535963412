import React from 'react';


const TeamAdvisor = (prop) => {
    const teamMembers = [
        {
            id: 1,
            name: 'Mr AV Kamlakar ',
            designation: '35+ Years Experience, Ex-CEO, SAIL-ISP, DSP and ASP, Director Technical of Maco Corporation',
            // education: '',
            linkedin: 'linkedin.com/in/av-kamlakar-bab862221/',
            imageUrl: 'team6.jpeg',
        },
        {
            id: 2,
            name: 'Mr. Rajender Singh ',
            designation: '35+ Years Exeperience, Ex - General Manager SAIL',
            linkedin: 'linkedin.com/in/rajendra-singh-7a478b28/',
            imageUrl: 'team8.jpg',
        },
        {
            id: 3,
            name: 'Mr. CS Kalaiselvum (M.A.,B.L.) ',
            designation: '37+ Years in Logistics, Ex.SR.GM Professional Couriers Network Limited',
            linkedin: '.linkedin.com/in/kalaiselvum-cs-aa7a121a6/',
            imageUrl: 'team7.jpg',
        },
    ];

    return (
        <div className='team-bg pb-0' id={prop.sectionId}>
            <div className="container team-page">
                <div className="team-heading mb-3">Our Valuable Chief Advisor</div>
                <div className="row team-cards justify-content-center ">
                    {teamMembers.map(member => (
                        <TeamMemberCard key={member.id} member={member} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const TeamMemberCard = ({ member }) => {
    return (
        <div className="col-sm-4">
            <div className='team-card'>
                <div className="team-img">
                    <img src={`/images/${member.imageUrl}`} alt={member.name} className="member-image" />
                </div>
                <div className="card-bottom">
                    <div className="member-name">{member.name}</div>
                    <div className="member-designation-1">{member.designation}</div>
                    {/* <div className="member-education">{member.education}</div> */}
                    <div className='icon-link'>
                        <a href={`https://${member.linkedin}`} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s LinkedIn Profile`}>
                            <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a></div>
                </div>
            </div>
        </div>
    );
};

export default TeamAdvisor;

