import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Invoice from "./Invoice";
import Footer from "./Footer";
const VenderInvoice = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Invoice />
      <Footer />
    </>
  );
};

export default VenderInvoice;
