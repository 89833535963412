import { useLocation } from "react-router";
import * as React from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import {
  GetPurchaseOrderList,
} from "../Services/AdminApi";
import { useState, useEffect } from "react";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

function NoRowsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-boxes"></i>
      <p>No order data available</p>
    </Stack>
  );
}

function NoResultsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fal fa-list"></i>
      <p>No results in DataGrid</p>
    </Stack>
  );
}

const columns = [
  { field: "order_id", headerName: "Order No.", width: 150 },
  { field: "invoiceNumber", headerName: "Invoice Number", width: 150 },
  { field: "vendor_Name", headerName: "LP Name", width: 150 },
  { field: "customername", headerName: "Customer Name", width: 150 },
  { field: "customerphone", headerName: "Customer Phone", width: 150 },
  { field: "customeremail", headerName: "Customer Email", width: 150 },
  { field: "noOfArticles", headerName: "Articles", width: 150 },

  { field: "itemname", headerName: "Item Name", width: 150 },
  { field: "lspDocketNo", headerName: "LSP Docket Number", width: 200 },
  { field: "transporterName", headerName: "Transporter Name", width: 150 },
  { field: "mvikasDocketNo", headerName: "MVikas Docket Number", width: 200 },
  { field: "eWayBillNo", headerName: "E-way Bill Number", width: 150 },
  { field: "eWayBillExpDate", headerName: "E-way Bill Expiry", width: 150 },
  { field: "transporterName", headerName: "Transporter Name", width: 150 },

  { field: "order_Date", headerName: "Order Date", width: 150 },
  { field: "order_Status", headerName: "Order Status", width: 150 },
  
{ field: "orderRemarks", headerName: "Order Remarks", width: 300 },

  { field: "frompincode", headerName: "From Pin", width: 150 },
  { field: "topincode", headerName: "To Pin", width: 150 },
  { field: "cust_Deliveryaddress",headerName: "Delivery Address",width: 300,},
  { field: "delivery_name",headerName: "Delivery Person name",width: 150,},
  { field: "delivery_mobile",headerName: "Delivery Contact No.",width: 150,},

  { field: "pickup_Address", headerName: "Pickup Address", width: 200 },
  { field: "pickup_name", headerName: "Pickup Person Name", width: 200 },
  { field: "pickup_mobile", headerName: "Pickup Person Contact", width: 200 },
  { field: "chargable_weight", headerName: "Chargable Wt.", width: 150 },
  { field: "taxableAmount", headerName: "Taxable Amt.", width: 150 },
  { field: "gst_Amount", headerName: "GST Amt.", width: 150 },
  { field: "total_Amount", headerName: "Total Amount", width: 150 },
];

export default function CustomerPurchase() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const myParam = useLocation().search;
  const order_status = new URLSearchParams(myParam).get("order_status");
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setData([]);
    setLoading(true);
    var params = "";
    if (order_status) {
      setStatus(order_status);
    }
    if (order_status != null && order_status != undefined && order_status != "") {
      params += `status=${order_status}&`;
    }
    // if (search != null && search != undefined && search != "") {
    //   params += `search=${search}&`;
    // }
    // if (fromDate != null && fromDate != undefined && fromDate != "") {
    //   params += `fromDate=${fromDate}&`;
    // }
    // if (toDate != null && toDate != undefined && toDate != "") {
    //   params += `todate=${toDate}`;
    // }

    GetPurchaseOrderList(params)
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredData = data.filter((row) => {
    // const isSearchMatch =
    //   row.order_id.toLowerCase().includes(search.toLowerCase()) ||
    //   row.order_Status.toLowerCase().includes(search.toLowerCase()) ||
    //   row.customername.toLowerCase().includes(search.toLowerCase()) ||
    //   row.customerphone.includes(search) ||
    //   row.mvikasDocketNo.includes(search) ||
    //   row.invoiceNumber.includes(search) ||
    //   row.lspDocketNo.includes(search) ||
    //   row.itemname.includes(search) ||        
    //   row.customeremail.toLowerCase().includes(search.toLowerCase());

    const isSearchMatch = Object.keys(row).some((key) => {
      const value = row[key];
  
      if (value !== null && value !== undefined) {
        if (typeof value === 'string' || value instanceof String) {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return value.toString().includes(search);
      }
  
      return false;
    });


      const orderDate = parseDate(row.orderDate, 'DD-MM-YYYY');
      let isDateInRange = true;

      if (fromDate) {
        const fromDateTime = new Date(fromDate);
        fromDateTime.setHours(0, 0, 0, 0);
        isDateInRange = isDateInRange && orderDate >= fromDateTime;
      }

      if (toDate) {
        const toDateTime = new Date(toDate);
        toDateTime.setHours(23, 59, 59, 999);
        isDateInRange = isDateInRange && orderDate <= toDateTime;
      }

      return isSearchMatch && isDateInRange;
    })

  
function parseDate(dateString, format) {
  const parts = dateString.split('-').map(Number);
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Purchase Report</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">My Payments</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                required
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {/* <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select labelId="Status" id="Status" fullWidth label="Status">
                  <MenuItem value="">
                    <em>Status</em>
                  </MenuItem>
                  <MenuItem value={"new"}>New </MenuItem>
                  <MenuItem value={"Pending"}>Pending </MenuItem>
                  <MenuItem value={"Approved"}>Approved</MenuItem>
                  <MenuItem value={"Rejected"}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={(e) => {
                    if (e) {
                      setfromDate(e.toString());
                    } else {
                      setfromDate("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(e) => {
                    if (e) {
                      setToDate(e.toString());
                    } else {
                      setToDate("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                onClick={() => {
                  loadData();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
          {loading ? (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Loading...</p>
                </div>
              ) : (
            <DataGrid
              sx={{
                p: "1rem",
                "@media print": {
                  ".table-ui": { border: "none" },
                },
              }}
              className="table-ui"
              disableColumnMenu
              disableDensitySelector
              getRowId={(row) => row.id ?? row.order_id}
              rows={filteredData}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay,
                NoResultsOverlay,
              }}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />)}
          </Card>
        </div>
      </section>
    </div>
    
  );
}
