import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
const Footer = () => {

const navigate =useNavigate();
  const handleapiDoc =()=>{
    navigate("/api");
  }
  const handletermsconditionsDownload = () => {
    const link = document.createElement("a");
    link.href = "images/TermsandConditions.pdf";
    link.download = "TermsandConditions.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleprivacypolicyDownload = () => {
    const link = document.createElement("a");
    link.href = "images/PrivacyPolicy.pdf";
    link.download = "PrivacyPolicy.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlereturnrefundDownload = () => {
    const link = document.createElement("a");
    link.href = "images/ReturnandRefund.pdf";
    link.download = "ReturnandRefund.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleshippingpolicyDownload = () => {
    const link = document.createElement("a");
    link.href = "images/ShippingPolicy.pdf";
    link.download = "ShippingPolicy.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlepricingDownload = () => {
    const link = document.createElement("a");
    link.href = "images/PricingforProducts.pdf";
    link.download = "PricingforProducts.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlecomplaintsDownload = () => {
    const link = document.createElement("a");
    link.href = "images/ComplaintsandGrievance.pdf";
    link.download = "ComplaintsandGrievance.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link style={{ cursor: 'pointer' }} to="home">
                      <img
                        src="images/logo-white.svg"
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p style={{ marginBottom: "4px" }}>
                      MVIKAS Super Logistics coordinates the movement of
                      supplies and materials in an organised manner.
                    </p>
                    <p style={{ color: "#FFF", fontSize: "18px", fontWeight : "" }}>
                      No one delivers faster, better and cheaper than us!
                    </p>
                  </div>
                  <div className="footer-social-icon" style={{ marginTop: "8px" }}>
                    <span>Follow us:</span>
                    <a href="https://www.facebook.com/mvikassuperlogistics" aria-label="Facebook">
                      <i className="fab fa-facebook-f facebook-bg" />
                    </a>
                    <a href="https://twitter.com/mvikaslogistics" aria-label="Twitter">
                      <i className="fab fa-twitter twitter-bg" />
                    </a>
                    <a href="https://www.linkedin.com/company/mvikas-super-logistics/" aria-label="LinkedIn">
                      <i className="fab fa-linkedin linkdin-bg" />
                    </a>
                    <a href="https://www.instagram.com/mvikassuperlogistics/" aria-label="Instagram">
                      <i className="fab fa-instagram google-bg" />
                    </a>
                  </div>

                </div>
              </div>
              <div className="col-xl-2 off col-lg-2 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <div className="h3">Useful Links</div>
                  </div>
                  <ul>
                    <li>
                      <Link style={{ cursor: 'pointer' }} to="home">Home</Link>
                    </li>
                    <li>
                      <Link style={{ cursor: 'pointer' }} to="about-us">About Us</Link>
                    </li>
                    <li>
                      <Link style={{ cursor: 'pointer' }} to="our-services">Our Services</Link>
                    </li>
                    <li>
                      <Link style={{ cursor: 'pointer' }} to="contact-us">Contact us</Link>
                    </li>
                    <li>
                    <a
                        style={{ cursor: "pointer" }}
                        onClick={handleapiDoc}
                      >
                        Api Documentation
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 off col-lg-2 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <div className="h3">Our Policies</div>
                  </div>
                  <ul>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handletermsconditionsDownload}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleprivacypolicyDownload}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handlereturnrefundDownload}
                      >
                        Return, Refund & Cancellation
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleshippingpolicyDownload}
                      >
                        Shipping Policy
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handlepricingDownload}
                      >
                        Pricing for Product
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handlecomplaintsDownload}
                      >
                        Complaints & Grievance
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <div className="h3">Subscribe</div>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don't miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button aria-label="Send message">
                        <i className="fab fa-telegram-plane" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="copyright-text">
              <p>Copyright © 2020 MVIKAS Super Logistics. All Right Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
