import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import {
  KycStatusofCustomer,
  ApproveOrRejectKyc
  , CheckDrivingLicense, CheckPassport, CheckPanCard, CheckGst, CheckTan
} from "../Services/KycApi"
import Button from "@mui/material/Button";
import { CurrentUrl } from "../Services/UrlApi";
import { toast } from "react-toastify";
let Url = `${CurrentUrl}`;
const CustomerKyc = () => {

  const [kycdetailsData, setkycdetailsData] = useState({})
  const [isTanValidated, setIsTanValidated] = useState(false);
  const [isGstValidated, setisGstValidated] = useState(false)
  const [documentNumber1, setDocumentNumber1] = useState(kycdetailsData?.documentNumber);
  const [dob1, setDob1] = useState('');
  const [documentNumber2, setDocumentNumber2] = useState(kycdetailsData?.documentNumber2);
  const [dob2, setDob2] = useState('');
  const [noKycRecords, setNoKycRecords] = useState(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const customerId = urlParams.get('customerId');

    if (customerId) {
      kycdetails(customerId);
    } else {
      console.error("Customer Id not found in URL");
    }
  }, []);

  const kycdetails = (customerId) => {
    KycStatusofCustomer(customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("data", data);
        // setkycdetailsData(data.data);
        // setDocumentNumber1(data.data.documentNumber);
        // setDocumentNumber2(data.data.documentNumber2);
        if (data.data) {
          setkycdetailsData(data.data);
          setDocumentNumber1(data.data.documentNumber);
          setDocumentNumber2(data.data.documentNumber2);
          setNoKycRecords(false);
        } else {
          setNoKycRecords(true);
        }

      })
      .catch(error => {
        setNoKycRecords(true);
        console.error("Error fetching KYC details: ", error);
        toast.error("Error fetching KYC details");
      });
  };

  const handleKycStatus = (e) => {
    console.log("e.target", e.target.value);
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const customerId = urlParams.get('customerId');
    const statusvalue = e.target.value
    let body = {
      userId: customerId,
      isAdminApproved: parseInt(statusvalue)
    }
    console.log("body", body);
    ApproveOrRejectKyc(body).then((response) => {
      console.log("response", response);
      // if (response.code === 200) {
      //   toast.success("Successfully updated")
      // } else {

      //   toast.error("Error updating")
      // }
    }
    )
  }

  // const handleValidatedocumentNumber = (e) => {
  //   const document = e.target.value
  //   let panCardVerificationBody = {
  //     idNumber: document
  //   }
  //   CheckPanCard(panCardVerificationBody).then((response) => {
  //     if (response.code === 200) {
  //       toast.success("Successfully validated document")
  //     } else {
  //       toast.error("Error validating document")
  //     }
  //   })
  // }
  const handleValidatedocumentNumber1 = () => {
    const document1Type = kycdetailsData?.document1Type;
    let requestBody = {
      idNumber: documentNumber1,
    };

    if (document1Type === 'DL' || document1Type === 'Passport') {
      requestBody.dob = dob1;
    }

    let apiCall;
    if (document1Type === 'PAN') {
      apiCall = CheckPanCard(requestBody);
    } else if (document1Type === 'Passport') {
      apiCall = CheckPassport(requestBody);
    } else if (document1Type === 'DL') {
      apiCall = CheckDrivingLicense(requestBody);
    }

    apiCall.then((response) => {
      if (response.code === 200) {
        toast.success("Successfully validated document");
      } else {
        toast.error("Error validating document");
      }
    });
  };
  const handleValidatedocumentNumber2 = () => {
    const document2Type = kycdetailsData?.document2Type;
    let requestBody = {
      idNumber: documentNumber2,
    };

    if (document2Type === 'DL' || document2Type === 'Passport') {
      requestBody.dob = dob2;
    }

    let apiCall;
    if (document2Type === 'PAN') {
      apiCall = CheckPanCard(requestBody);
    } else if (document2Type === 'Passport') {
      apiCall = CheckPassport(requestBody);
    } else if (document2Type === 'DL') {
      apiCall = CheckDrivingLicense(requestBody);
    }

    apiCall.then((response) => {
      if (response.code === 200) {
        toast.success("Successfully validated document");
      } else {
        toast.error("Error validating document");
      }
    });
  };

  const handleGstVerification = (e) => {
    const document = e.target.value
    let body = {
      idNumber: document
    }
    CheckGst(body).then((response) => {
      if (response.code === 200) {
        toast.success("Successfully validated document")
        setisGstValidated(true)
      } else {
        toast.error("Error validating document")
      }
    })
  }
  const handleTanVerification = (e) => {
    const document = e.target.value
    let body = {
      idNumber: document
    }
    CheckTan(body).then((response) => {
      if (response.code === 200) {
        toast.success("Successfully validated document")
        setIsTanValidated(true);
      } else {
        toast.error("Error validating document")
      }
    })
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">KYC verification</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">KYC verification</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          {noKycRecords ? ( 
            <Card className="custum-shadow mt-3 p-4">
              <h4>No KYC records found</h4>
            </Card>
          ) : (
            <>
              <Card className="custum-shadow mt-3 p-4">
                <div className="row text-ui">
                  <div className="col-lg-4 mb-3">
                    <h5>Name</h5>
                    <h4>{kycdetailsData?.customerName || 'NA'}</h4>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <h5>Business Type</h5>
                    <h4>{kycdetailsData?.bussinessType || 'NA'}</h4>
                  </div>
                  {kycdetailsData.bussinessType !== "Individual" && (
                    <>
                      <div className="col-lg-4 mb-3">
                        <h5>Company Type</h5>
                        <h4>{kycdetailsData?.companyType || 'NA'}</h4>
                        <div className="d-flex mt-3">
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3 d-flex align-items-center">
                        <div className="text">
                          <h5>GSTIN No.</h5>
                          <h4>{kycdetailsData?.gstno || 'NA'}</h4>
                        </div>
                        <Button
                          variant="contained"
                          size="small"
                          className="ms-auto"
                          value={kycdetailsData.gstno}
                          onClick={handleGstVerification}
                        >
                          {isGstValidated ? 'Validated' : 'Validate'}

                        </Button>
                      </div>
                      <div className="col-lg-4 mb-3  d-flex align-items-center">
                        <div className="text">
                          <h5>Tan No.</h5>
                          <h4>{kycdetailsData?.tanNo || 'NA'}</h4>
                        </div>
                        <Button
                          variant="contained"
                          size="small"
                          className="ms-auto"
                          value={kycdetailsData.tanNo}
                          onClick={handleTanVerification}
                        >
                          {isTanValidated ? 'Validated' : 'Validate'}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div className="row text-ui">
                  <div className="col-lg-6">
                    <h5>Photo Identification</h5>
                    <img className="img-text"
                      src={`${Url}${kycdetailsData?.photoIdentification}`}
                      alt="document" />
                  </div>
                </div>

              </Card>

              <Card className="custum-shadow mt-3 p-4">

                <h5 className="mt-4">ID & Address Proofs</h5>
                <div className="row text-ui mt-3">
                  <div className="col-lg-6">
                    <h5>Document 1</h5>
                    <h4>{kycdetailsData?.document1Type || 'NA'}</h4>
                  </div>
                </div>
                <div className="row text-ui mt-3">

                  <div className="col-lg-6 mb-3">
                    <h5>Front Image</h5>
                    <img className="img-text" src={`${Url}${kycdetailsData?.document1FrontSide}`} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <h5>Back Image</h5>
                    <img className="img-text" src={`${Url}${kycdetailsData?.document1BackSide}`} />
                  </div>
                </div>
                <div className="row text-ui">

                  <div className="col-lg-6">
                    <h5>Document Number - </h5>
                    <input type="text"
                      className="form-control"
                      value={documentNumber1}
                      onChange={(e) => setDocumentNumber1(e.target.value)}
                    />
                  </div>
                  {kycdetailsData.document1Type == "DrivingLicense" || kycdetailsData.document1Type == "Passport" && (
                    <>
                      <div className="col-lg-6">
                        <h5>Date of Birth - </h5>
                        <input
                          type="date"
                          className="form-control"
                          value={dob1}
                          onChange={(e) => setDob1(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* <div className="d-flex mt-3">
                  <Button
                    variant="contained"
                    size="small"
                    className="ms-auto"
                    onClick={handleValidatedocumentNumber1}
                  >
                    Validate
                  </Button>
                </div> */}



                <div className="row text-ui mt-4">
                  <div className="col-lg-6">
                    <h5>Document 2</h5>
                    <h4>{kycdetailsData?.document2Type || 'NA'}</h4>
                  </div>
                </div>

                <div className="row  text-ui mt-3">
                  <div className="col-lg-6 mb-3">
                    <h5>Front Image</h5>
                    <img className="img-text" src={`${Url}${kycdetailsData?.document2FrontSide}`} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <h5>Back Image</h5>
                    <img className="img-text" src={`${Url}${kycdetailsData?.document2BackSide}`} />
                  </div>
                </div>

                <div className="row text-ui">

                  {/* <div className="col-lg-6">
                <h5>Document Number</h5>
                <input type="text" 
                className="form-control"
                value={kycdetailsData?.documentNumber2 || 'NA'} 
                />

              </div> */}

                  <div className="col-lg-6">
                    <h5>Document Number</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={documentNumber2}
                      onChange={(e) => setDocumentNumber2(e.target.value)}
                    />

                  </div>
                  {kycdetailsData.document2Type == "DrivingLicense" || kycdetailsData.document2Type == "Passport" && (
                    <>
                      <div className="col-lg-6">
                        <h5>Date of Birth - </h5>
                        <input
                          type="date"
                          className="form-control"
                          value={dob2}
                          onChange={(e) => setDob2(e.target.value)}
                        />
                      </div>
                    </>
                  )}   </div>

                {/* <div className="d-flex mt-3">

                  <Button
                    variant="contained"
                    size="small"
                    className="ms-auto"
                    onClick={handleValidatedocumentNumber2}
                  >
                    Validate
                  </Button>
                </div> */}


              </Card>


              <div className="d-flex mt-3">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleKycStatus}
                  value={1}
                  className="ms-auto"
                >
                  Approve
                </Button>
                <Button

                  variant="contained"
                  size="small"
                  onClick={handleKycStatus}
                  value={2}
                  className="ms-2"
                >
                  Reject
                </Button>
              </div>
            </>
          )}

        </div>
      </section>
    </div >
  );
};

export default CustomerKyc;
