import React from 'react'
import Header from '../Admin/Header'
import Footer from './Footer'
import Sidebar from '../Admin/Sidebar'
import WeightReconcillation from './WeightReconcillation'

const WeightReconcillationParent = () => {
   
    return (
        <>
        <Header/>
        <Sidebar />
        <WeightReconcillation />
        {/* <Footer/> */}
        </>
      )
}

export default WeightReconcillationParent