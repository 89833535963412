import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <>
            <div id="container-Error">

                <h1>404</h1>

                <h3>Looks like the page you're looking for doesn't exist.</h3>

                <Link to="/">Take me back home</Link>
            </div>
        </>
    )
}

export default Error
