import React from "react";
import SupSec from "./SupSec";

const Support = () => {
  return (
    <div className="support-section">
      <div className="container-fluid text-white" style={{backgroundColor: '#5d0000' }}>
        <div className="container-lg">
          <div className="row p-3 justify-contenr-center">
            <div className="col-lg-4 col-sm-4">
              <SupSec
                heading="SUPPORT CENTER"
                text1="Give us a call"
                text2=" 011-46039993"
                icon="fas fa-headset"
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <SupSec
                heading="OUR ASSURANCE"
                text1="Easy, fast and pocket friendly delivery"
                text2="Door-step pickup & drop"
                icon="fas fa-balance-scale"
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <SupSec
                heading="Network"
                text1="Delivery across more than 29000 pincodes"
                icon="fas fa-users"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
