import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Rate/`;

export const GetUnitTypes = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetUnitTypes`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UploadRate = async (Data, ratesheetid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}uploadrate/${ratesheetid}`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Uploadb2cRate = async (Data, ratesheetid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}UploadB2CRates/${ratesheetid}`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getratebyservice = async (serviceid, vendorid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getratebyservice/${serviceid}?LpId=${vendorid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getcourierratebyservice = async (serviceid, vendorid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetB2CRateByService/${serviceid}?LpId=${vendorid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getlpdatabylpid = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getlpdetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetRates = async (
  ratedata,
  grosswt,
  totalwt,
  units,
  topay,
  topayoption,
  b2c,
  boxes
) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}Getrates`,
      JSON.stringify({
        frompin: ratedata.frompin,
        topin: ratedata.topin,
       // chargableweight: ratedata.shipmentweight,
        chargableweight: ratedata.weightinkg,
        shipvalue: ratedata.shipmentvalue,
        cod: ratedata.cod,
        grosswt: grosswt,
        dtotalwt: totalwt,
        units: units,
        cust_Email: localStorage.getItem("user_email"),
        toPay: topayoption,
        isB2C: b2c,
        boxes : boxes
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("getrates api response", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const GetRatesforlpupdate = async (
//   ratedata,
//   grosswt,
//   totalwt,
//   units,
//   topay,
//   topayoption
// ) => {
//   return await axios
//     .post(
//       `${Url}Getrates`,
//       JSON.stringify({
//         frompin: ratedata.frompin,
//         topin: ratedata.topin,
//         chargableweight: ratedata.shipmentweight,
//         shipvalue: ratedata.shipmentvalue,
//         cod: ratedata.cod,
//         grosswt: grosswt,
//         dtotalwt: totalwt,
//         units: units,
//         cust_Email: "",
//         toPay: topayoption,
//       }),
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

export const GetRatesforlpupdate = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}Getrates`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
export const getratepincodewise = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}getratepincodewise`,
      JSON.stringify({
        fromPinCode: parseInt(Data.fromPinCode),
        toPincode: Data.toPincode,
        shipmentValue: parseFloat(Data.shipmentValue),
        shipmentWt: parseFloat(Data.shipmentWt),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const InsertAdditionalCharges = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${CurrentUrl}Orders/InsertAdditionalChargesIntoMasters`,
      JSON.stringify(Data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const UpdateAdditionalCharges = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${CurrentUrl}Orders/UpdateAdditionalChargesIntoMasters`,
      JSON.stringify(Data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetAdditionalMastersData = async () => {
  const token = localStorage.getItem("Logkey");
  const userId = localStorage.getItem("user_id");
  return await axios
    .get(
      `${CurrentUrl}Orders/GetAllMasterAdditionalChargers?LpId=` + userId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
// export const GetB2CZoneByLp = async (Data) => {
//   const lpid = localStorage.getItem("user_id");
//   const token = localStorage.getItem("Logkey");
//   return await axios
//     .post(
//       `${CurrentUrl}GetB2CZoneByLp`,
//       JSON.stringify(Data),
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

export const GetB2CZoneByLp = async (Data) => {
  const lpid = localStorage.getItem("user_id");
  const token = localStorage.getItem("Logkey");

  return await axios.post(
    `${CurrentUrl}GetB2CZoneByLp`,
    JSON.stringify(Data),
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      responseType: 'blob',
    }
  )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filename.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
