import React, { useState, useEffect } from "react";
import {
  Getzonesbyservice,
  Getserviceshavingzone,
  Getpincodebyservices,
  Deletemappedpincode,
} from "../Services/ServiceApi";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  ListItemText,
  ListItem,
  List,
  Modal,
  Button,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewMapingData from "./ViewMapingData";
const ViewMapServicezone = () => {
  const navigate = useNavigate();
  const [zoneName, setZoneName] = useState([]);
  const [servicename, setServiceName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [innerexpanded, setinnerExpanded] = useState(false);
  let Head;
  const [zonenm, setZonenm] = useState("");
  const [allpincode, setAllPincode] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (serviceid, zoneid) => {
    setOpen(true);
    setAllPincode(
      // <Suspense fallback={<Loading />}>
      <ViewMapingData zoneid={zoneid} serviceid={serviceid} />
      // </Suspense>
    );
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "500px",
    p: 4,
    overflowY: "scroll",
  };
  useEffect(() => {
    BindService();
  }, []);

  const BindService = () => {
    Getserviceshavingzone().then((res) => {
      setLoading(false);
      setServiceName(res);
    });
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setinnerExpanded(false);
  };
  const innerhandleChange = (panel) => (event, isExpanded) => {
    setinnerExpanded(isExpanded ? panel : false);
  };
  const handleDelete = (pin, serviceid, zoneid) => {
    if (window.confirm("Do you really want to remove this pincode")) {
      let list = [...data];

      list = list.filter((val) => {
        return val.pincode !== pin;
      });

      setData(list);
      Deletemappedpincode({
        serviceid: serviceid,
        zoneid: zoneid,
        pincode: pin,
      }).then((res) => {
        if (res.status === 200)
          toast.success("pincode has been removed successfully");
        else toast.error("Technical Error");
      });
    }
  };
  const InnnerTogglespan = (serviceid, zoneid, e) => {
    if (!e.target.classList.contains("Mui-expanded")) {
      Getpincodebyservices(serviceid, zoneid).then((dt) => {
        if (dt.length !== 0) {
          setData(dt);
        }
      });
    }
  };
  const Togglespan = (e, id) => {
    if (!e.target.classList.contains("Mui-expanded")) {
      Getzonesbyservice(id).then((res) => {
        if (Object.keys(res.data).length >= 1) {
          setZoneName(res.data);
        } else {
          setZoneName([]);
        }
      });
    }
  };

  Head = servicename.map((head, i) => (
    <Accordion
      expanded={expanded === `heading${head.id}`}
      onChange={handleChange(`heading${head.id}`)}
      onClick={(e) => Togglespan(e, head.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`heading${head.id}bh-content`}
        id={head.id}
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {head.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ background: "rgb(0 123 255 / 8%)" }}>
        <Typography>
          {" "}
          <List
            sx={{ padding: "20px" }}
            component="div"
            aria-label="mailbox folders"
          >
            {zoneName.length !== 0
              ? zoneName.map((zonenm, id) => {
                  return (
                    <>
                      <ListItem button>
                        <ListItemText primary={zonenm.name} />{" "}
                        <Button
                          onClick={() => {
                            handleOpen(head.id, zonenm.id);
                            setZonenm(zonenm.name);
                          }}
                        >
                          <RemoveRedEyeIcon />
                        </Button>
                      </ListItem>
                      <Divider />
                    </>
                  );
                })
              : "No Data Found"}{" "}
          </List>
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container  px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 mt-3">
              <h3>View Mapped Service</h3>
              <div>
                <Link to="/mapservicezone" className="text-white btn btn-info">
                  Map
                </Link>
              </div>
            </div>
            <div
              style={{
                background: "#8080801f",
                margin: "20px",
                padding: "10px",
              }}
            >
              {Head.length === 0 ? "No Data Found" : Head}
            </div>
          </div>{" "}
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    position: "sticky",
                    top: "-33px",
                    backgroundColor: "white",
                    zIndex: 999,
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {zonenm}
                  <CloseIcon
                    onClick={() => setOpen(false)}
                    sx={{ cursor: "pointer", fontSize: "28px" }}
                  />
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {allpincode}
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default ViewMapServicezone;
