import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Metro from "../Components/Metro";
import SpecialZone from "../Components/SpecialZone";

const SpecialZoneDataAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <SpecialZone />
      {/* <Footer/>    */}
    </>
  );
};

export default SpecialZoneDataAdmin;
