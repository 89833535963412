import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {
  GetSMSProviders,
  ActivateSmsProvider,
} from "../../src/Services/AdminApi";
import { useState, useEffect } from "react";

export default function SmsProviderList() {
  const [smsproviderslist, setsmsproviderslist] = useState([]);
  const [activeProvider, setActiveProvider] = useState(null);
  

  const [activatesmsprovider, setactivatesmsprovider] = useState({
    smsId: 0,
    isActive: true,
  });

  useEffect(() => {
    GetSMSProviders()
      .then((result) => {
        setsmsproviderslist(result);

        const activeProvider = result.find((provider) => provider.isActive);
        setActiveProvider(activeProvider);
      })

      .catch((r) => {
        console.log(r);
      });
  }, []);

 

  const handlesmsproviderchange = () => {
    const changedProvider = smsproviderslist.find(
      (provider) => provider.smsId === activatesmsprovider.smsId
    );
    changedProvider.isActive = activatesmsprovider.isActive;
    setsmsproviderslist([...smsproviderslist]);
      ActivateSmsProvider(activatesmsprovider)
      .then((result) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">SMS Provider</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">SMS Provider</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow py-3 px-3">
            <Alert className="mb-3" severity="success">
              Currently{" "}
              {activeProvider ? <b>{activeProvider.providerName}</b> : "No"} SMS
              provider is active
            </Alert>

            <div className="row">
              {smsproviderslist.map((provider) => (
                <div className="col-sm-4" key={provider.smsId}>
                  <Card className="sms-box">
                    <CardContent>
                      <h5>{provider.providerName}</h5>
                      <p>{provider.displayText}</p>
                    </CardContent>
                    <CardActions className="card-footer">
                      <FormControlLabel
                        control={
                          <Switch
                          onChange={() =>
                            setactivatesmsprovider({
                              smsId: provider.smsId,
                              isActive: !provider.isActive,
                            })
                          }
                            defaultChecked={provider.isActive}
                            size="small"
                          />
                        }
                        label={provider.isActive ? "active" : "inactive"}
                      />
                    </CardActions>
                  </Card>
                </div>
              ))}
            </div>

            <CardActions className="card-action-box">
              <Button
                onClick={handlesmsproviderchange}
                variant="contained"
                className="custum-btn"
              >
                Update change
              </Button>
            </CardActions>
          </Card>
        </div>
      </section>
    </div>
  );
}
