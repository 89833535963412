import React, { useEffect, useState } from "react";
import {
  Getpincodebyservices,
  Deletemappedpincode,
} from "../Services/ServiceApi";
import {  toast } from "react-toastify";
import { Chip, Badge } from "@mui/material";

const ViewMapingData = (props) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    BindService();
  }, []);

  const BindService = () => {
    setLoading(true);
    Getpincodebyservices(props.serviceid, props.zoneid).then((res) => {
      console.log(res);
      setLoading(false);
      setData(res);
    });
  };
  const handleDelete = (pin, serviceid, zoneid) => {
    if (window.confirm("Do you really want to remove this pincode")) {
      let list = [...data];

      list = list.filter((val) => {
        return val.pincode !== pin;
      });

      setData(list);
      Deletemappedpincode({
        serviceid: serviceid,
        zoneid: zoneid,
        pincode: pin,
      }).then((res) => {
        if (res.status === 200)
          toast.success("pincode has been removed successfully");
        else toast.error("Technical Error");
      });
    }
  };
  if (loading) {
    return <h3>Loading....</h3>;
  } else {
    return (
      <>
        {data.map((pins, pinkey) => {
          return (
            <>
              <Badge
                badgeContent={pins.oda === "Serviceable" ? "STD" : pins.oda}
                color="error"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 6,
                    height: 15,
                    minWidth: 15,
                    left: 12,
                    top: 2,
                  },
                }}
              >
                <Chip
                  value={pins.pincode}
                  label={pins.pincode}
                  p={1}
                  variant="outlined"
                  onDelete={() =>
                    handleDelete(pins.pincode, props.serviceid, props.zoneid)
                  }
                  sx={{
                    marginBottom: "5px",
                    marginRight: "5px",
                  }}
                />
              </Badge>
            </>
          );
        })}
      </>
    );
  }
};

export default ViewMapingData;
