import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}api/KYCVerification/`;

export const KycStatusofCustomer = async (UserId) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetUserKYCDetails?UserId=${UserId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((res) => res);
};

export const ApproveOrRejectKyc = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}ApprovedorRejectKYCDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};


export const CheckPanCard = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CheckPanCard`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const CheckGst = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Checkgstin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const CheckTan = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CheckTAN`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const CheckPassport = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CheckPassport`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const CheckDrivingLicense = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CheckDrivingLicense`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};