import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Orders/`;

export const SaveOrderdata = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Insertorder`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const submitreview = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}submitreview`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetOrderdata = async (
  lpid,
  date = { fromdate: "", todate: "" }
) => {
  //debugger;
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(
      `${Url}Getorders?Lpid=${lpid}&Fromdate=${date.fromdate}&Todate=${date.todate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const markasread = async (statename, cityname) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}markasread`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getinvoice = async (orderid) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}Getinvoice?orderno=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
