import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import SalesInvoicesList from "./salesinvoiceslist";
import Footer from "./Footer";
const AdminInvoice = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <SalesInvoicesList />
      <Footer />
    </>
  );
};

export default AdminInvoice;
