import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import TrackYourOrder from './Components/TrackYourOrder'; 


const urlParams = new URLSearchParams(window.location.search);
const orderKeyParam = urlParams.get('Orderkey');

const renderApp = () => {
  ReactDOM.render(
    <HashRouter hashType="noslash">
      <App />
    </HashRouter>,
    document.getElementById('root')
  );
};

if (window.location.pathname === '/TrackYourOrder' && orderKeyParam) {
  ReactDOM.render(
    <BrowserRouter>
      <TrackYourOrder orderKey={orderKeyParam} />
    </BrowserRouter>,
    document.getElementById('root')   );
} else {
  renderApp(); 
}

serviceWorkerRegistration.register();