import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useParams,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;

const SubmitReview = () => {
  const { orderId } = useParams();
// const { Orderkey } = useParams();
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const Orderkey = searchParams.get("Orderkey");
  const [navbarLogo, setNavbarLogo] = useState("./images/logo.svg");
  const [trackorder, setTrackorder] = useState("");
  const [orderkey, setOrderkey] = useState("");
  const [trackitemstatus, settrackitemstatus] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
  };

  // useEffect(() => {
  //   handleTrackOrder();
  // }, []);
  useEffect(() => {
    if (Orderkey) {
      handleTrackOrder(Orderkey); 
     }
  }, [Orderkey]);

  // const handleTrackOrder = async (e) => {
  //   e.preventDefault();
  //   const token = localStorage.getItem("Logkey");
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     }
  //   };
  //   try {
  //     const orderId = window.location.hash.split("=")[1];
  //     if (!orderId) {
  //       throw new Error("Order ID not found in the URL");
  //     }
  //     console.log("this is orderid: " + orderId);

  //     const response = await fetch(
  //       `${Url}GetOrderedItemStatuss?orderId=${orderId}`, requestOptions
  //     );
  //     const trackorder = await response.json();
  //     setTrackorder(trackorder);
  //     setSelectedItemId(null);
  //     if (!trackorder.orderItems || trackorder.orderItems.length === 0) {
  //       toast("No record found");
  //       settrackitemstatus([]);
  //     } else {
  //       settrackitemstatus([]);
  //       setOpen(true);
  //     }

  //   } catch (error) {
  //     console.error("Error fetching order details:", error);
  //     toast("Error fetching order details");
  //   }
  // };
  const handleTrackOrder = async (orderId) => {
    const token = localStorage.getItem("Logkey");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await fetch(
        `${Url}GetOrderedItemStatuss?orderId=${orderId}`, requestOptions
      );
      const trackorder = await response.json();
      setTrackorder(trackorder);
      setSelectedItemId(null);
      if (!trackorder.orderItems || trackorder.orderItems.length === 0) {
        toast("No record found");
        settrackitemstatus([]);
      } else {
        settrackitemstatus([]);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      toast("Error fetching order details");
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleTrackOrder();
    }
  };
  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
    handleTrackOrderItem(itemId);
  };
  const handleTrackOrderItem = async (itemId) => {
    const token = localStorage.getItem("Logkey");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(
      `${Url}TrackingOrderedItemStatuss?ItemId=${itemId}`, requestOptions
    );
    const itemresponse = await response.json();
    settrackitemstatus(itemresponse);
    if (!itemresponse.status || itemresponse.status.length === 0) {
      toast("No record found");
    } else {
      setOpen(true);
    }
  };
  const formatEta = (eta) => {
    let dateParts;
    if (eta.includes('-')) {
      // Format: DD-MM-YYYY
      dateParts = eta.split(/[- :]/);
    } else {
      // Format: MM/DD/YYYY
      dateParts = eta.split(/[\/ :]/);
      // Swap day and month positions
      [dateParts[0], dateParts[1]] = [dateParts[1], dateParts[0]];
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
    const year = parseInt(dateParts[2], 10);

    // Create a Date object with only date (no time)
    const date = new Date(year, month, day);

    // Format the date as desired
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };



  return (
    <>
      <section className="content" style={{ padding: "10px 0px" }}>
        <div className="container-fluid">
          <Card className="custum-shadow py-3 px-3 review-form-ui">
            <div className="logo-box">
              <img src={navbarLogo} className="img-fluid logo-img" alt="" />
            </div>
            {/* {trackorder?.statusModels &&
              trackorder.statusModels.map((status, index) => (
                <Box key={index}>
                  <h4 className="mt-3">{status.itemId}</h4>
                  <ul
                    className={`order-status-list mt-0 ${
                      status.status ? "" : "hide-box"
                    }`}
                  >
                    {status.status &&
                      status.status.map((orderItemStatus, statusIndex) => (
                        <li
                          key={statusIndex}
                          className={`${orderItemStatus ? "active" : ""}`}
                        >
                          <h4>{orderItemStatus.orderItemStatus}</h4>
                          <span>
                            {orderItemStatus.orderItemRemarks[0]?.statusDate}
                          </span>
                          {orderItemStatus.orderItemRemarks &&
                            orderItemStatus.orderItemRemarks.length > 0 && (
                              <ol className="remarks-list">
                                {orderItemStatus.orderItemRemarks.map(
                                  (remark, remarkIndex) => (
                                    <li key={remarkIndex}>{remark.remarks}</li>
                                  )
                                )}
                              </ol>
                            )}
                        </li>
                      ))}
                  </ul>
                </Box>
              ))} */}

            <Box >
              <div className="order-info">
                <div className="row order-head">
                  {trackorder.orderId && (
                    <div className="col-sm-6">
                      <h4>Order ID: {trackorder.orderId}</h4>
                      <span >Total Box: {trackorder.noOfBoxes}</span>
                      <span>Booked on : {formatEta(trackorder.bookedAt)}</span>
                    </div>
                  )}
                  {trackorder.eta && (
                    <div className="col-sm-6">
                      <h4 className="text-right">Current status : {trackorder.status}</h4>
                      <span className="text-right">Last Updated : {formatEta(trackorder.statusDate)}</span>
                      <span className="text-right">ETA : {formatEta(trackorder.eta)}</span>
                    </div>
                  )}
                </div>
                <div className="order-tabs">
                  <ul className="">
                    {trackorder?.orderItems && trackorder.orderItems.map((status, index) => (
                      <li
                        className={selectedItemId === status.itemId ? 'selected-item' : ''}
                        key={status.itemId}
                        onClick={() => handleItemClick(status.itemId)}
                      >{status.itemId}</li>

                    ))}
                  </ul>
                </div>
              </div>


              <ul className={`order-status-ui mt-0 ${trackitemstatus.status ? '' : 'hide-box'}`}>
                {trackitemstatus.status && trackitemstatus.status.map((item, statusIndex) => (
                  <li key={statusIndex}
                    className={`outside-li ${item.orderItemRemarks.length === 0 ? 'inactive' : 'active'}`}
                  >
                    <div className="status-head">
                      <img src={`${process.env.PUBLIC_URL}/images/${item.orderItemStatus.toLowerCase()}.png`}
                        alt="" />

                      <div className="text">
                        <h4>Your order is {item.orderItemStatus}</h4>
                        <span>{item.orderStatusDate}</span>
                      </div>
                    </div>

                    {item.orderItemRemarks && item.orderItemRemarks.length > 0 && (
                      <>
                        <div className="remark">Remarks</div>
                        <ol className="remarks-ui">
                          {item.orderItemRemarks.map((remark, remarkIndex) => (
                            <li key={remarkIndex}>{remark.remarks} - {remark.statusDate} </li>
                          ))}
                        </ol>
                      </>
                    )}
                  </li>
                ))}
              </ul>

            </Box>

            <p className="copyright-p">
              Copyright © 2023 MVIKAS Super Logistics. All rights reserved.
            </p>
          </Card>
        </div>
      </section>
    </>
  );
};

export default SubmitReview;
