import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Dashboard/`;

export const Vendorneworder = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}Vendorneworder`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const dashboardAllOrder = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}Getallorders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
