import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { GetNotificationsUserlist, ActiveorDeactivateUserNotification } from '../../Services/AdminApi'
import { useParams, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

export default function Mistemplate() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [assignrolesopen, setOpenRole] = useState(false);
  const [userList, setUserList] = useState([]);
  const { notificationid } = useParams();

  // Use useLocation to get the location object, which includes search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationId = searchParams.get('notificationid');

  // Log the notificationId to check if it's correctly retrieved
  console.log('NotificationId:', notificationId || notificationid);

  //  useEffect(() => {
  //    // Fetch user data based on notificationId
  //    GetNotificationsUserlist(notificationId)
  //      .then((response) => setUserList(response))
  //      .catch((error) => console.error('Error fetching user list:', error));
  //  }, [notificationId]);


  useEffect(() => {
    GetNotificationsUserlist(notificationId || notificationid)
      .then((response) => setUserList(response))
      .catch((error) => console.error('Error fetching user list:', error));
  }, [notificationId, notificationid]);

  const handleSwitchChange = async (userId, currentStatus) => {
    console.log(userId, currentStatus);
    const newStatus = !currentStatus;

    try {
      // Update user status via API
      let response = await ActiveorDeactivateUserNotification(notificationId, userId, newStatus);
      if (response.status === 200) {
        toast.success(`User notification updated successfully!`);

        setUserList((prevUsers) =>
          prevUsers.map((user) =>
            user.userId === userId ? { ...user, isActive: newStatus } : user
          )
        );
      }
      else {
        toast.error("An error occurred while updating the user notification status.");
      }
    } catch (error) {
      console.error("Switch change error:", error);
      toast.error(`Error updating user status: ${error.message}`);
    }
  };

  const columnsdata = [
    { field: 'sno', headerName: 'Sr. No.', width: 50, },
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileNo', headerName: 'Mobile No.', width: 150 },
    { field: 'role', headerName: 'Roles', width: 120 },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            onChange={() => handleSwitchChange(params.row.userId, params.row.isActive)}
          />
        )
      },
    },
  ];


  const assignrolesClickOpen = () => {
    setOpenRole(true);
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Daily MIS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Daily MIS</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content mt-4">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={userList}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              getRowId={(user) => user.userId}
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>
      <ToastContainer />
      {/*  */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className='delete-alert'>
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to inactive this user?</p>
            <Button onClick={assignrolesClose} variant="contained">Yes</Button>
            <Button onClick={assignrolesClose} autoFocus variant="outlined" className='ml-2'>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}