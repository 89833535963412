import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import CustomerTemplate from "./CustomerTemplate";
import Footer from "./../Footer";
const CustomerOrderPlaced = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <CustomerTemplate />
      <Footer />
    </>
  );
};

export default CustomerOrderPlaced;
