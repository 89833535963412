import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Rolespage from "./Rolespage";
import Footer from "./../Footer";
const RolesAssigned = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Rolespage />
      <Footer />
    </>
  );
};

export default RolesAssigned;
