import React from "react";
import { Link } from "react-router-dom";
const styles = {
  socialMain: {
    padding: "40px 15px 0px",
  },
  section: {
    padding: "60px 0",
    background: "#f5f5f5",
  },
  a: {
    width: "100px",
    height: "100px",
  },
  h6: {
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: '1px',
    color: "#752727",
  },
  p: {
    fontSize: "13px",
    fontWeight: "600",
  }
}
const SectionOne = () => {
  return (
    <div >
      <section style={styles.section} id="about">
        <div className="h1">OUR SOLUTIONS</div>

        <div className="social">
          <ICons title="Instant Quotes" para="Get delivery prices with a simple click" icon="fas fa-mouse fa-2x" />
          <ICons title="Compare Prices" para="Compare prices across different delivery agents/logistic partners" icon="fas fa-hand-holding-usd fa-2x" />
          <ICons title="Reach" para="Get PAN India delivery" icon="fas fa-parachute-box fa-2x" />
          <ICons title="Delivery Standards" para="PAN India delivery within 12 hours* and delivery across the world in 48 hours*" icon="fas fa-truck fa-2x" />
          <ICons title="Competitive Prices" para="PAN India delivery at INR 39 and International delivery at INR 450" icon="fas fa-hard-hat fa-2x" />
          <ICons title="Support Centre" para="For queries call 011-46039993" icon="fas fa-phone-alt fa-2x" />
        </div>
      </section>
    </div>
  );
};

const ICons = (prop) => {
  return (
    <div style={styles.socialMain} className="socialMain col-lg-2 col-sm-6">
      <Link style={styles.a} to="/" aria-label={prop.title}>
        <i className={prop.icon}></i>
      </Link>
      <span style={styles.h6}>{prop.title}</span>
      <p style={styles.p}>{prop.para}</p>
    </div>
  );
};


// const ICons = (prop) => {
//   return (
//     <div style={styles.socialMain} className="socialMain col-lg-2 col-sm-6">
//       <Link style={styles.a} to="/" aria-label={prop.title}>
//         <i className={prop.icon}></i>
//         <h6 style={styles.h6}>{prop.title}</h6>
//         <p style={styles.p}>{prop.para}</p>
//       </Link>
//     </div>
//   );
// };
export default SectionOne;
