import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PurchaseInvoiceList from "./purchaseinvoicelist";
import Footer from "./Footer";
const AdminInvoice = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PurchaseInvoiceList />
      <Footer />
    </>
  );
};

export default AdminInvoice;
