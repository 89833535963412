import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { GetNotificationsCard, ActiveorDeactivateNotification } from '../../Services/AdminApi';
export default function Notifications() {
  const [notificationsapidata, setnotificationsapidata] = useState([])

  useEffect(() => {
    GetNotificationsCard().then((response) => {
      console.log("my notifications", response);
      setnotificationsapidata(response)
    })
  }, [])

  // const handleSwitchChange = async (notificationId, currentStatus ) => {
  //   const newStatus = !currentStatus;

  //   try {
  //     await ActiveorDeactivateNotification(notificationId, newStatus);

  //     setnotificationsapidata((prevNotifications) =>
  //       prevNotifications.map((notification) =>
  //         notification.notificationId === notificationId
  //           ? { ...notification, isActive: newStatus }
  //           : notification
  //       )
  //     );
  //     toast.success(`Notification ${newStatus ? 'activated' : 'deactivated'} successfully!`);
  //   } catch (error) {
  //     console.error("Switch change error:", error);
  //     toast.error("An error occurred while updating the notification status.");
  //   }
  // };
  const handleSwitchChange = async (item) => {
    console.log(item);
    const { notificationId, isActive, notification } = item;
    const newStatus = !isActive;

    try {
      await ActiveorDeactivateNotification(notificationId, newStatus);

      setnotificationsapidata((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.notificationId === notificationId
            ? { ...notification, isActive: newStatus }
            : notification
        )
      );

      toast.success(`Notification ${newStatus ? 'activated' : 'deactivated'} successfully!`);

    } catch (error) {
      console.error("Switch change error:", error);
      toast.error("An error occurred while updating the notification status.");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Notifications</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Notifications</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <section className="content">
        <div className="container-fluid">
          <div className='row'>

            {notificationsapidata.map((item) => {
              return (
                <div className='col-lg-3 mb-3' key={item.notificationId}>
                  <Card className='sms-box email-box'>
                    <CardContent className='email-content'>
                      <span>Common Template</span>
                      <h5>{item.notification}</h5>
                    </CardContent>
                    <CardActions className='card-footer'>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item.isActive}
                           // onChange={() => handleSwitchChange(item.notificationId, item.isActive)}
                           onChange={()=>{handleSwitchChange(item)}}
                            size="small"
                          />
                        }
                        label={item.isActive ? 'On' : 'Off'}
                      />
                      <Link 
                      // to={`/${item.notificationRoute}?${item.notificationId}`} 
                      to={`/${item.notificationRoute}?notificationid=${item.notificationId}`}
                      className='ml-auto'>
                        <IconButton aria-label="edit">
                          <GridViewOutlinedIcon />
                        </IconButton>
                      </Link>

                    </CardActions>
                  </Card>
                </div>
              )
            })}


          </div>
        </div>
      </section>
    </div>
  );
}