import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import DiscrepancyView from "./DisputeDiscrepancyView";
const DisputeDiscrepancyLabel = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <DiscrepancyView />
      <Footer />
    </>
  );
};

export default DisputeDiscrepancyLabel;
