import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import MapServiceZone from '../Components/MapSeriveZone';
import Header from './Header'

const MapServiceAdmin = () => {
    const navigate = useNavigate();
    const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));
  
    useEffect(() => {
      if (userlog === undefined || userlog === null) {
        navigate("/");
      }
    }, []);
    return (
        <>
        <Header />
        <Sidebar />
        <MapServiceZone />
         {/* <Footer/>      */}
        </>
    )
}

export default MapServiceAdmin
