import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import {
  GetCustomerInvoice,
} from "../Services/UserApi";
import "./pdf.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default function Invoices() {
  function pdfhandle(params) {
    setInvoice(params.row);

    window.html2canvas = html2canvas;
    var doc = new jsPDF("p", "px", [790, 1000]);

    var content = document.getElementById("pdf-content");
    document.getElementById("pdf-content").style.display = "block";
    doc.html(content, {
      callback: function (doc) {
        doc.save("MyInvoice");
        document.getElementById("pdf-content").style.display = "none";
      },
    });
  }

  const columns = [
    { field: "sno", headerName: "S.No", width: 100 },
    { field: "invoiceNo", headerName: "Invoices No.", width: 180 },
    { field: "date", headerName: "Invoices Date",  width: 150 },
    { field: "vendor_Name", headerName: "Service From", width: "300" },
    { field: "admin_name", headerName: "Payment To", flex: 1 },
    {
      field: "taxable_Amount",
      headerName: "Taxable Amount",
      flex: 1,
    },
    {
      field: "cgst",
      headerName: "GST",
      flex: 1,
    },
    {
      field: "total_Amount",
      headerName: "Total Amount",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      // renderCell: (params) => {
      //   return <Chip label="Paid" color="success" size="small" />;
      // },
    },
    {
      field: "download",
      headerName: "Download",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <SimCardDownloadIcon />
          </IconButton>
        );
      },
    },
  ];
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [status, setstatus] = useState("");

  useEffect(() => {
    loadCustomerInvoice();
  }, []);

  const loadCustomerInvoice = () => {
    setData([]);

    var params = "";
    // if (search != null && search != undefined && search != "") {
    //   params += `search=${search}&`;
    // }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    // if (fromDate != null && fromDate != undefined && fromDate != "") {
    //   params += `fromDate=${fromDate}&`;
    // }
    // if (toDate != null && toDate != undefined && toDate != "") {
    //   params += `todate=${toDate}`;
    // }

    GetCustomerInvoice(localStorage.getItem("user_email"), params)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.issueDate) - new Date(a.issueDate)
        );
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const filteredData = data.filter((row) => {
    // const isSearchMatch =
    //   row.invoiceNo.toLowerCase().includes(search.toLowerCase()) ||
    //   row.vendor_Name.toLowerCase().includes(search.toLowerCase()) ||
    //   row.item.includes(search) ;
    const isSearchMatch = Object.keys(row).some((key) => {
      const value = row[key];
  
      if (value !== null && value !== undefined) {
        if (typeof value === 'string' || value instanceof String) {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return value.toString().includes(search);
      }
  
      return false;
    });


      const orderDate = parseDate(row.date, 'DD-MM-YYYY');
      let isDateInRange = true;

      if (fromDate) {
        const fromDateTime = new Date(fromDate);
        fromDateTime.setHours(0, 0, 0, 0);
        isDateInRange = isDateInRange && orderDate >= fromDateTime;
      }

      if (toDate) {
        const toDateTime = new Date(toDate);
        toDateTime.setHours(23, 59, 59, 999);
        isDateInRange = isDateInRange && orderDate <= toDateTime;
      }

      return isSearchMatch && isDateInRange;
    })
    function parseDate(dateString, format) {
      const parts = dateString.split('-').map(Number);
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }

  return (
    <div className="content-wrapper" id="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Invoices</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Invoices</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                // required
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  onChange={(e) => setstatus(e.target.value)}
                  labelId="Status"
                  id="Status"
                  fullWidth
                  label="Status"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={"Paid"}>Paid</MenuItem>
                  <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={(e) => {
                    if (e) {
                      setfromDate(e.toString());
                    } else {
                      setfromDate("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(e) => {
                    if (e) {
                      setToDate(e.toString());
                    } else {
                      setToDate("");
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                onClick={() => {
                  loadCustomerInvoice();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
              <DataGrid
                sx={{
                  p: "1rem",
                  "@media print": {
                    ".table-ui": { border: "none" },
                  },
                }}
                disableDensitySelector
                className="table-ui"
                rows={filteredData}
                getRowId={(row) => row.id ?? row.invoiceNo}
                columns={columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                autoHeight={true}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => {
                  setRowsPerPage(newPageSize);
                }}
              />
          </Card>
        </div>
      </section>

      <div id="pdf-content" style={{ display: "none" }}>
        <div
          className="page"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0px 20px",
            }}
          >
            <tr>
              <td style={{ width: "10%" }}>
                <img src="images/logo-pdf.png" style={{ width: "140px" }} alt=""/>
              </td>
              <td
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#F00",
                  textAlign: "center",
                }}
              >
                TAX INVOICE
              </td>
            </tr>
          </table>
          <table style={{ width: "100%", fontSize: "14px" }}>
            <tr>
              <td
                colspan="3"
                style={{ fontSize: "18px", fontWeight: "600", color: "#F00" }}
              >
                {invoice.admin_name}
              </td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                <p style={{ width: "200px", margin: 0 }}>
                  {invoice.admin_address}  <br />
                  {invoice.admin_city}  <br />
                  {invoice.admin_state}  <br />
                  {invoice.admin_Pincode}  <br />

                </p>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>GSTIN:</td>
                    <td>{invoice.admin_gst}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>State:</td>
                    <td>{invoice.admin_city}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>PAN:</td>
                    <td>{invoice.admin_Pan}</td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Invoice Date:</td>
                    <td>{invoice.issue_Date}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Invoice No.:</td>
                    <td>{invoice.invoiceNo}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Reference No.:</td>
                    <td>{invoice.refrenceNo}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Customer Name</td>
                  </tr>
                  <tr>
                    <td>{invoice.customer_Name}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Customer GSTIN:</td>
                  </tr>
                  <tr>
                    <td>{invoice.cgsTin}-</td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Billing Address</td>
                  </tr>
                  <tr>
                    <td>
                      {/* DUMMY DUMMY,
                      <br />
                      DELHI
                      <br />
                      Delhi, 121006 */}
                      {invoice.bilingAddress}
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Shipping Address</td>
                  </tr>
                  <tr>
                    {/* <td>
                      DUMMY DUMMY,
                      <br />
                      DELHI
                      <br />
                      Delhi, 121006
                    </td> */}
                    {invoice.shippingAddress}
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
            <tr>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Place of Supply:</td>
                    <td>{invoice.admin_city}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
          </table>

          <table
            className="item-table"
            style={{
              width: "100%",
              fontSize: "14px",
              textAlign: "left",
              border: "1px solid #c9c6a5",
              borderCollapse: "separate",
              borderSpacing: "0px",
            }}
          >
            <thead style={{ background: "#fffac3" }}>
              <tr>
                <th>S.No.</th>
                <th>Item</th>

                <th>Quantity</th>
                <th>Rate/Item</th>

                <th>Taxable Value</th>
                <th style={{ borderLeft: "1px solid #c9c6a5" }}>GST</th>

                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ verticalAlign: "top", textAlign: "center" }}>
                <td style={{ textAlign: "center" }}>1.</td>
                <td style={{ textAlign: "center" }}>
                  <p style={{ margin: "0px", fontWeight: "500" }}>
                    MVIKAS LOGISTICS FEES
                  </p>
                  <span style={{ fontSize: "12px", display: "block" }}>
                    - As per Annexure
                  </span>
                </td>

                <td style={{ textAlign: "center" }}>1.00</td>
                <td style={{ textAlign: "center" }}> {invoice.taxable_Amount}</td>

                <td style={{ textAlign: "center" }}>
                  {invoice.taxable_Amount}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid #c9c6a5",
                    textAlign: "center",
                  }}
                >
                  {invoice.cgst}
                  <span style={{ fontSize: "12px", display: "block" }}>
                    @18%
                  </span>
                </td>

                <td style={{ textAlign: "center" }}>{invoice.total_Amount}</td>
              </tr>
            </tbody>
            <tfoot
              style={{
                background: "#fffac3",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              <tr>
                <td colspan="4" style={{ textAlign: "left" }}>
                  Total
                </td>
                <td style={{ textAlign: "center" }}>
                  {invoice.taxable_Amount}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid #c9c6a5",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {invoice.cgst}
                </td>

                <td style={{ textAlign: "center" }}>{invoice.total_Amount}</td>
              </tr>
            </tfoot>
          </table>

          <table
            className="table-3"
            style={{
              width: "100%",
              fontSize: "14px",
              textAlign: "right",
              borderCollapse: "separate",
              borderSpacing: "0px 10px",
              fontSize: "15px",
            }}
          >
            <tr>
              <td>Taxable Amount: {invoice.taxable_Amount}</td>
            </tr>
            <tr>
              <td>Total Tax: {invoice.cgst}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px", fontWeight: "800" }}>
                Invoice Total: Rs.{invoice.total_Amount}
              </td>
            </tr>
            {/* <tr>
              <td>
                Total amount (in words): {invoice.total_Amount}
              </td>
            </tr> */}
          </table>

          <table
            style={{
              width: "100%",
              fontSize: "13px",
              textAlign: "left",
              borderCollapse: "separate",
              borderSpacing: "0px 5px",
              marginTop: "50px",
            }}
          >
            <tr>
              <td style={{ width: "50%" }}>
                <table
                  style={{
                    width: "100%",
                    border: "2px solid #ffc9c9",
                    fontSize: "18px",
                    color: "#000",
                    borderSpacing: "15px",
                  }}
                >
                  <tr>
                    <td style={{ fontWeight: "600" }}>Bank Details:</td>
                  </tr>
                  <tr>
                    <td>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            style={{ fontWeight: "600", padding: "10px 0 0" }}
                          >
                            Account Number:
                          </td>
                          <td>{invoice.accountNumber}</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "600", padding: "10px 0 0" }}
                          >
                            Bank Name:
                          </td>
                          <td>{invoice.bankName}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td
                            style={{ fontWeight: "600", padding: "10px 0 0" }}
                          >
                            IFSC:
                          </td>
                          <td>{invoice.ifsc}</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "600", padding: "10px 0 0" }}
                          >
                            Beneficiary Name:
                          </td>
                          <td>{invoice.beneficiaryName}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "50%" }}>
                <table
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      For MVIKAS SUPER LOGISTICS PRIVATE LIMITED
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "2px solid #000",
                        height: "100px",
                        textAlign: "center",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>Authorised Signatory</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table
            style={{
              width: "100%",
              fontSize: "13px",
              textAlign: "left",
              borderSpacing: "0px 5px",
            }}
          >
            <tr>
              <td>
                <b>Terms & Conditions:</b>
              </td>
            </tr>
            <tr>
              <td>
                1. Loading/Unloading will be under shippers scope.
                <br />
                2. Tenure of service and payment terms for this invoice would be
                governed as per the agreement between the Customer and MVIKAS.
                <br />
                3. Any payment made is covered under 'Contract' u/s 194C. TDS,
                if applicable, shall be @ 2%.
                <br />
                4. This invoice is valid, subject to realization of due
                payments, as mentioned in details above.
                <br />
                5. This is a Digitally Signed Invoice and not require any
                signature.
                <br />
                6. Shipper must ensure insurance of its goods.
                <br />
                7. Consignee is responsible for illigal goods.
                <br />
                8. Carrier is not responsible for any Damage, Breakage, Fire,
                Leakage & Accident.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
