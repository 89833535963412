import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetZoneName, GetpincodeByzoneId } from "../Services/ZoneApi";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import {
  GetAllService,
  mapServiceZone,
  Getpincodebyservices,
} from "../Services/ServiceApi";
import Loading from "./Loading";
import { list } from "fontawesome";

const MapServiceZone = () => {
  const [zonelist, setZonelist] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [zonepincode, setZonepincode] = useState([]);
  const [allservice, SetAllService] = useState([]);
  const [selectedPincodeQuery, setSelectedPincodeQuery] = useState("");
  const [pincodeQuery, setPincodeQuery] = useState("");
  const [data, setZoneData] = useState({
    serviceid: 0,
    zoneid: "",
    pincodes: [],
  });
  const [errormessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  //Get All State name in dropdown
  useEffect(() => {
    let mounted = true;
    setLoading(false);
    GetZoneName().then((items) => {
      if (mounted) {
        setZonelist(items);
      }
    });

    GetAllService().then((res) => {
      SetAllService(res);

      return () => (mounted = false);
    });
  }, []);
  let ddlzoneNamelist;
  ddlzoneNamelist = zonelist.map((val, i) => {
    return (
      <>
        <option value={val.id} key={i}>
          {val.name}
        </option>
      </>
    );
  });

  let ddlserviceNamelist;
  ddlserviceNamelist = allservice.map((val, i) => {
    return (
      <>
        <option value={val.id} key={i}>
          {val.name}
        </option>
      </>
    );
  });

  //Show checklist by selected pin
  //Remove checklist by uncheck
  const RemovecheckBox = (i) => {
    document.getElementsByName(i).checked = false;
    const newPincode = zonepincode.filter((val) => {
      return val.pincode !== i;
    });
    setZonepincode(newPincode);
    setPincode([...pincode, i]);
    setZoneData({
      ...data,
      ["pincodes"]: zonepincode.filter((val) => {
        return val.pincode !== i;
      }),
    });
  };
  const onSubmitZone = (e) => {
    e.preventDefault();
    if (
      data.zoneid === "" ||
      data.serviceid === "" ||
      data.pincodes.length === 0
    ) {
      toast.warn("Please fill all the mandatory fields");
    } else {
      setLoading(true);
      mapServiceZone(data).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setZoneData({
            serviceid: "",
            zoneid: "",
            pincodes: [],
          });
          setPincode([]);
          setZonepincode([]);
          document.getElementById("allPincode").style.display = "none";
          toast.success("Record save successfully");
        } else {
          toast.error("Technical Error");
        }
      });
    }
  };
  const inputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "zoneid") {
      setPincode([]);
      setLoading(true);
      GetpincodeByzoneId(value).then((items) => {
        Getpincodebyservices(data.serviceid, value).then((pins) => {
          setZoneData({
            ...data,
            zoneid: value,
          });
          const newpin = items.filter(function (item) {
            return (
              pins
                .map(function (e) {
                  return e.pincode;
                })
                .indexOf(item) === -1
            );
          });

          setErrorMessage("");
          if (newpin.length === 0)
            setErrorMessage(
              "All pincodes of this zone already mapped with this service"
            );
          else {
            setPincode(newpin);
          }
          setLoading(false);
          document.getElementById("allPincode").style.display = "block";
        });
      });
    } else if (name === "serviceid") {
      setErrorMessage("");
      setPincode([]);
      setZonepincode([]);
      setZoneData({
        ...data,
        pincodes: [],
        zoneid: "",
        serviceid: e.target.value,
      });
    }
  };
  const inputCheckEvent = (e) => {
    const newPincode = pincode.filter((val, indx) => {
      return val !== e;
    });
    const name = "pincodes";

    setZonepincode([
      ...zonepincode,
      { ["pincode"]: e, ["oda"]: "Serviceable" },
    ]);
    setPincode(newPincode);
    setZoneData({
      ...data,
      [name]: [...data.pincodes, { ["pincode"]: e, ["oda"]: "Serviceable" }],
    });
  };
  const checkODAEvent = (e) => {
    const list = [...zonepincode];

    let indx = list.findIndex((x) => x.pincode.toString() === e.target.id);
    list[indx]["oda"] = !list[indx]["oda"];
    setZonepincode(list);
    setZoneData({
      ...data,
      ["pincodes"]: list,
    });
  };
  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container  px-3 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h3>Service & Zone Mapping</h3>
              <div>
                <Link
                  to="/ViewMapService"
                  className="text-white btn btn-info mr-2"
                >
                  View Mapping
                </Link>
                {/* <Link to="/CreateService" className="text-white btn btn-info">
                Add Service
              </Link> */}
              </div>
            </div>
            <form onSubmit={onSubmitZone}>
              <div className="row px-lg-5 px-3">
                <div className="col-md-4 col-sm-6 col-lg-5 p-0 mr-3">
                  <span>
                    Select Service Name:<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    name="serviceid"
                    value={data.serviceid}
                    className="form-control"
                    onChange={inputEvent}
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {ddlserviceNamelist}
                  </select>
                </div>
                {data.serviceid !== "" ? (
                  <div className="col-md-4 col-sm-6 col-lg-5 p-0 p-lg-auto">
                    <span>
                      Select Zone Name:<span style={{ color: "red" }}>*</span>
                    </span>

                    <select
                      name="zoneid"
                      onChange={inputEvent}
                      className="form-control"
                      value={data.zoneid}
                    >
                      <option selected hidden>
                        Select
                      </option>
                      {ddlzoneNamelist}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {/* PINCODE PINCODE FETCH */}
                <div className="row ">
                  <div
                    className="col-md-12 mt-5 "
                    id="allPincode"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2  ">
                        <h5 className="pl-2">Select Pincode </h5>
                      </div>
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2 ">
                        <h5 className="pl-2">Selected Pincode </h5>
                      </div>
                      <div
                        className="col-md-4 col-6 col-lg-5 p-lg-3 p-0 overflow-scroll mr-0 mr-lg-4"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={pincodeQuery}
                            name="pincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setPincodeQuery(e.target.value);
                            }}
                          />
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Move all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  if (pincode.length !== 0) {
                                    list =
                                      zonepincode.length === 0
                                        ? []
                                        : zonepincode;
                                    pincode.map((val) =>
                                      list.push({
                                        ["pincode"]: val,
                                        ["oda"]: "Serviceable",
                                      })
                                    );
                                    setZonepincode(list);
                                    setZoneData({
                                      ...data,
                                      ["pincodes"]: list,
                                    });
                                    setPincode([]);
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </span>
                        {pincode
                          .filter((post) => {
                            if (pincodeQuery === "") {
                              return post;
                            } else if (
                              post
                                .toString()
                                .toLowerCase()
                                .includes(pincodeQuery.toString())
                            ) {
                              return post;
                            }
                          })
                          .map((val, i) => (
                            <div
                              className="SelectPin"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1234",
                                padding: "5px 10px",
                                margin: "4px",
                                borderRadius: "15px",
                              }}
                              onClick={(e) => inputCheckEvent(val)}
                              value={data.pincodes}
                              key={i}
                            >
                              {val}
                              <br />
                            </div>
                          ))}
                        {errormessage}
                      </div>

                      <div
                        className="col-md-4 col-6 col-lg-5  p-lg-3 p-0 overflow-scroll"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Remove all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  if (zonepincode.length !== 0) {
                                    list = [];
                                    zonepincode.map((val) =>
                                      list.push(val.pincode)
                                    );
                                    setPincode(list);
                                    setZonepincode([]);
                                    setZoneData({ ...data, pincodes: [] });
                                  }
                                }}
                                style={{
                                  transform: "rotate(180deg)",
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={selectedPincodeQuery}
                            name="selectedPincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setSelectedPincodeQuery(e.target.value);
                            }}
                          />
                        </span>
                        {zonepincode.length !== 0
                          ? zonepincode
                              .filter((post) => {
                                if (selectedPincodeQuery === "") {
                                  return post;
                                } else if (
                                  post.pincode
                                    .toString()
                                    .toLowerCase()
                                    .includes(selectedPincodeQuery.toString())
                                ) {
                                  return post;
                                }
                              })
                              .map((val, i) => (
                                <div
                                  style={{
                                    backgroundColor: "#1234",
                                    padding: "6px 9px",
                                    margin: "4px",
                                    borderRadius: "15px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    maxHeight: " 38px",
                                    height: "100%",
                                  }}
                                  key={i}
                                  value={data.pincodes}
                                >
                                  <span>{val.pincode}</span>
                                  <div className="d-flex">
                                    <span
                                      style={{
                                        marginRight: "7px",
                                        display: "none",
                                      }}
                                    >
                                      {" "}
                                      <input
                                        type="checkbox"
                                        className="btn-check"
                                        id={val.pincode}
                                        autoComplete="off"
                                        checked={val.oda}
                                        onClick={(e) => checkODAEvent(e)}
                                      />
                                      <label
                                        className="btn-sm btn-outline-dark p-1"
                                        style={{
                                          borderRadius: "15px",
                                          marginBottom: "0px",
                                        }}
                                        for={val.pincode}
                                      >
                                        ODA
                                      </label>
                                    </span>

                                    <Tooltip title="Remove" arrow>
                                      <CancelIcon
                                        onClick={(e) =>
                                          RemovecheckBox(val.pincode)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                          float: "right",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              ))
                          : ""}
                      </div>
                      <div className=" col-11 d-flex justify-content-end">
                        <input
                          type="submit"
                          className="btn btn-success  mt-3 mt-lg-3 mb-5 mr-5"
                          value="Submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* SUBMIT BUTTON */}
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }
};
export default MapServiceZone;
