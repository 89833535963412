import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { green, yellow, red } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import { GetWeightDiscrepancy, GetDiscrepancyRemarks } from "../Services/AdminApi";
import Stack from "@mui/material/Stack";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import debounce from 'lodash/debounce';
import { CurrentUrl } from "../Services/UrlApi";
import { Padding } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';

import $ from 'jquery';
let Url = `${CurrentUrl}Admin/`;

function CustomToolbar() {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport
                printOptions={{
                    hideToolbar: true,
                }}
            />
        </GridToolbarContainer>
    );
}
const DiscrepancyView = () => {
    const navigate = useNavigate();
    const [rows, setDiscrepencyHistory] = useState([]);
    const [search, setSearch] = useState("");
    const [fromDate, setfromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [status, setStatus] = useState("");
    const rowsPerPageOptions = [10, 20];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [open, setOpen] = React.useState(false);
    const [lptoshowlist, setlptoshowlist] = useState([]);
    const [selectedlptoshow, setselectedlptoshow] = useState("");
    const [userId, setUserId] = useState(null);
    const [selectedLPs, setSelectedLPs] = useState([]);
    const [remarks, setRemarks] = useState({ remark: "" });
    const [remarksClose, setRemarksClose] = useState({ remarksClosedispute: "" });
    const { customerId, order_id, lpid, discrepancyId } = useParams();
    const [cardData, setCardData] = useState({});
    const [remarksList, setRemarkList] = useState([]);
    const [remarksListOther, setRemarkListOther] = useState([]);
    const [remarkText, setRemarkText] = useState("");
    const [remarkCloseText, setRemarkCloseText] = useState("");
    const [isAcceptDiscrepancyOpen, setAcceptDiscrepancyOpen] = useState(false);
    const [isDisputeDiscrepancyOpen, setDisputeDiscrepancyOpen] = useState(false);
    const [disputeId, setdisputeId] = useState("");
    // const [discrepancyId, setDiscrepancyId] = useState(0);
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openDisputeDialog, setOpenDisputeDialog] = useState(false);

    useEffect(() => {
        loadDiscrepancyHistory();
    }, [customerId, order_id, lpid, discrepancyId]);

    const loadDiscrepancyHistory = () => {
        const userid = localStorage.getItem("user_id");
        setDiscrepencyHistory([]);
        setUserId(userid);
        // var params = "";
        var params = {
            "CustomerId": customerId,
            "OrderId": order_id,
            // discrepancyId:discrepancyId
        };
        GetWeightDiscrepancy(params)
            .then((result) => {
                if (result) {
                    console.log(`resultdata990`, result.discrepancyLists);
                    setDiscrepencyHistory(result.discrepancyLists);
                    setCardData(result);
                } else {
                    console.log("Response data is undefined or empty.");
                }
            })
            .catch((r) => {
                console.log(r);
            });
    };
    // useEffect(() => {
    //     // setStatusdropdown([{ id: "0", unit: "kg" }, { id: "1", unit: "gram" }]);
    //     console.log("Fetched ID from URL:", customerId);

    // }, []);

    useEffect(() => {
        const handleModalClose = () => {
            setRemarkText(""); // Clear the textarea when modal is closed
            setRemarkCloseText(""); // Clear the textarea when modal is closed
        };

        // Attach event listeners for when the modals are hidden
        $('#acceptdiscrepancy').on('hidden.bs.modal', handleModalClose);
        $('#disputediscrepancy').on('hidden.bs.modal', handleModalClose);

        // Cleanup event listeners on component unmount
        return () => {
            $('#acceptdiscrepancy').off('hidden.bs.modal', handleModalClose);
            $('#disputediscrepancy').off('hidden.bs.modal', handleModalClose);
        };
    }, []);

    const getdiscrepancyRemarks = () => {
        setRemarkList([]);
        // var params = "";
        var params = {
            DiscrepencyId: discrepancyId,
            DisputeId: 0
        };
        GetDiscrepancyRemarks(params)
            .then((result) => {
                if (result) {
                    console.log(`resultdata990`, result);
                    const filteredRemarks = result.filter(message => message.userId === userId);
                    setRemarkList(filteredRemarks);
                    const filteredRemarksOther = result.filter(message => message.userId != userId);
                    setRemarkListOther(filteredRemarksOther);
                } else {
                    console.log("Response data is undefined or empty.");
                }
            })
            .catch((r) => {
                console.log(r);
            });
    }

    const debouncedOnChange = debounce((value) => {
        setRemarks({ ...remarks, remark: value });
    }, 300);
    const debouncedOnChangClose = debounce((value) => {
        setRemarksClose({ ...remarksClose, remarksClose: value });
    }, 300);
    const AddRemarks = (e) => {
        const userid = localStorage.getItem("user_id");
        console.log(`remarks`, remarks);
        if (remarks.remark !== "") {
            AddSaveRemarks(remarks).then((res) => {
                if (res.data.code === 200) {
                    // toast.success(res.message);
                    // setRemarkText("");
                    // setOpenAcceptDialog(false);
                    // setRemarks({ remark: "" });
                    // // document.getElementById('acceptdiscrepancy').classList.remove('show');
                    // // document.getElementsByClassName('modal-backdrop')[0].remove(); // Remove the modal backdrop
                    // // $('#acceptdiscrepancy').modal('hide');

                    if (res.data.code === 200) {
                        toast.success("Remarks saved successfully");
                        // loadDiscrepancyHistory();
                        setOpenAcceptDialog(false);
                        setRemarks({ remark: "" });
                    } else {
                        toast.error("Something went wrong");
                        setOpenAcceptDialog(false);
                    }
                } else {
                    toast.error(res.message);
                }
            })
        } else {
            // Handle error or warning
        }
    };

    const AddSaveRemarks = async (remarks) => {
        const userid = localStorage.getItem("user_id");
        const token = localStorage.getItem("Logkey");
        return await axios
            .post(
                `${Url}AddRemarksAndStatusClosedByAdmin`,
                JSON.stringify({
                    "remarks": remarks['remark'],
                    "remarksbyId": userid,
                    "isClosed": true,
                    "discrepencyId": discrepancyId,
                    "disputeId": disputeId
                }),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": `*`,
                    },
                }
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    const CloseRemarks = (e) => {
        const userid = localStorage.getItem("user_id");
        console.log(`Dispute remarks`, remarksClose);
        if (remarksClose.remarksClosedispute !== "") {
            CloseSaveRemarks(remarksClose).then((res) => {
                console.log("discard remarks", res);
                if (res.data.code === 200) {
                    // toast.success(res.message);
                    // setRemarkCloseText(""); // Clear the textarea
                    // // document.getElementById('disputediscrepancy').classList.remove('show');
                    // // document.getElementsByClassName('modal-backdrop')[0].remove(); // Remove the modal backdrop          
                    // $('#disputediscrepancy').modal('hide');
                    if (res.data.code === 200) {
                        toast.success("Remarks saved successfully");
                        // loadDiscrepancyHistory();
                        setOpenDisputeDialog(false);
                        setRemarksClose({ remarksClosedispute: "" });
                    } else {
                        toast.error("Something went wrong");
                        setOpenDisputeDialog(false);
                    }
                } else {
                    toast.error(res.message);
                }
            })
        } else {
            // Handle error or warning
        }
    };

    const CloseSaveRemarks = async (remarks) => {
        const userid = localStorage.getItem("user_id");
        const token = localStorage.getItem("Logkey");
        return await axios
            .post(
                `${Url}AddRemarksAndStatusClosedByAdmin`,
                JSON.stringify({
                    "remarks": remarks['remarksClosedispute'],
                    "remarksbyId": userid,
                    "isClosed": false,
                    "discrepencyId": discrepancyId,
                    "disputeId": disputeId
                }),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": `*`,
                    },
                }
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    const columns = [
        { field: "statusDate", width: 180, headerName: "Status Updated On" },
        {
            field: "orderDetail",
            width: 190,
            headerName: "Order Details",
            renderCell: (params) => {
                const orderDetail = params.row.orderDetail.replace(/\n/g, "<br>");
                return <div dangerouslySetInnerHTML={{ __html: orderDetail }} />;
            },
        },
        {
            field: 'shippingDetails',
            headerName: 'Shipping Details',
            width: 180,
            renderCell: (params) => {
                // const shippingDetails = params.row.shippingDetails.replace(/\n/g, "<br>");
                // return <div dangerouslySetInnerHTML={{ __html: shippingDetails }} />;
                const shippingDetails = params.row.shippingDetails;
                const lines = shippingDetails.split('\n');
                const htmlContent = lines.map((line, index) => {
                    if (index === 0) {
                        return `<div><strong>AWB:</strong> ${line}</div>`;
                    } else {
                        return `<div>${line}</div>`;
                    }
                }).join('');

                return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;

            },
        },
        { field: "appliedWeight", headerName: "Applied Weight", width: 130, },
        { field: "chargedWeight", headerName: "Charged Weight", type: "date", width: 130, },
        { field: "extraWeight", headerName: "Excess Weight", width: 130, },
        { field: "excessCharges", headerName: "Excess Charges", width: 130, },
        {
            field: "disputeStatus",
            width: 180,
            headerName: "Status",
            sortable: false,
            renderCell: (params) => {
                const disputeStatus = params.row.disputeStatus;
                // const role = localStorage.getItem("user_role").split(",");
                return (
                    <Box className="d-flex">
                        <span class="badge badge-danger">{disputeStatus}</span>
                    </Box>
                );
            },
        },
        {
            field: "remark",
            width: 150,
            headerName: "Remark",
            sortable: false,
            renderCell: () => {
                // const role = localStorage.getItem("user_role").split(",");
                return (
                    <Button size="small" data-toggle="modal" data-target="#remarkspopup" onClick={getdiscrepancyRemarks} variant="contained">View</Button>
                );
            },
        },
        {
            field: "action",
            width: 180,
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                // const role = localStorage.getItem("user_role").split(",");
                const disputeStatus = params.row.disputeStatus;
                const discrepancyid = params.row.id;
                const disputeId = params.row.disputeId;

                // <>
                // <>
                //     <Button className="mr-1" size="small" data-toggle="modal" data-target="#acceptdiscrepancy" variant="contained">Add Remark</Button>
                // </>
                // <Button size="small" data-toggle="modal" data-target="#disputediscrepancy" variant="contained">Close</Button>
                // </>
                // <>
                //   <Button className="mr-1" size="small" onClick={() => handleAcceptClick(discrepancyid, disputeId)} variant="contained">Add remarks</Button>
                //  <Button className="mr-1" size="small" onClick={() => handleAcceptClick(discrepancyid, disputeId)} variant="contained">Accept</Button>

                //  <Button className="mr-1" size="small" onClick={() => handleDisputeClick(discrepancyid, disputeId)} variant="contained">Discard</Button>
                // </>

                if (disputeStatus === "Discrepancy Accepted") {
                    return (
                        <Button className="mr-1" size="small" onClick={() => handleAcceptClick(discrepancyid, disputeId)} variant="contained">Add remarks</Button>
                    );
                } else if (disputeStatus === "Dispute Pending" || disputeStatus === "Dispute Discrepancy") {
                    return (
                        <>
                            <Button className="mr-1" size="small" onClick={() => handleAcceptClick(discrepancyid, disputeId)} variant="contained">Accept</Button>
                            <Button className="mr-1" size="small" onClick={() => handleDisputeClick(discrepancyid, disputeId)} variant="contained">Discard</Button>
                        </>
                    );
                } else {
                    return null;
                }


            },
        },
    ];
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        // SearchSalesOrder();
    };
    const handleBlur = () => {
        // SearchSalesOrder();
    };
    const handleStatusChange = (e) => {
        const value = e.target.value;
        setStatus(value);
        // SearchSalesOrder();
    };
    const handleFromDateChange = (e) => {
        // setfromDate(e.toString());
        // SearchSalesOrder();
    };
    const handleCloseDialog = () => {
        setOpenAcceptDialog(false);
        setRemarks({ remark: "" });
    };

    const handleCloseDisputeDialog = () => {
        setOpenDisputeDialog(false);
        setRemarksClose({ remarksClosedispute: "" });
    };

    const handleAcceptClick = (discrepancyid, disputeId) => {
        // setDiscrepancyId(discrepancyid);
        setdisputeId(disputeId);
        setOpenAcceptDialog(true);
    };

    const handleDisputeClick = (discrepancyid, disputeId) => {
        // setDiscrepancyId(discrepancyid);
        setdisputeId(disputeId);
        setOpenDisputeDialog(true);
    };

    const isReadOnly = customerId === "MV-60091";
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="main-title">Weight Discrepancy</h1>
                                <span className="sub-text">Take action on your pending weight discrepancies. track weight disputes and view history</span>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <span>Home</span>
                                    </li>
                                    <li className="breadcrumb-item active">Customers List</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row mb-3 mx-0">
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui ">
                            <p>Total Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.totalDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Total Closed Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.totalClosedDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>New Discrepancy Count</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.newDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Rejected By Courier</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputeRejectedByCourierCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Pending</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputePendingCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputeDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Discrepancy Accepted</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.discrepancyAcceptedCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>

                </div>
                <section className="content">
                    <div className="container-fluid">

                        <Card className="custum-shadow mt-3">
                            <DataGrid
                                componentsProps={{
                                    toolbar: {
                                        printOptions: {},
                                    },
                                }}
                                sx={{
                                    p: "1rem",
                                    "@media print": {
                                        ".table-ui": { border: "none" },
                                    },
                                }}
                                className="table-ui"
                                getRowId={(row) => row.id ?? 0}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                autoHeight={true}
                                rowHeight={75}
                                pagination
                                pageSize={rowsPerPage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onPageSizeChange={(newPageSize) => {
                                    setRowsPerPage(newPageSize);
                                }}
                            />
                        </Card>
                    </div>



                    {/* <Button className="ml-auto" data-toggle="modal" data-target="#acceptdiscrepancy" variant="contained">Accept</Button> */}
                    {/* <Button className="ml-auto" data-toggle="modal" data-target="#remarkspopup" variant="contained">Accept</Button> */}

                </section>
            </div>


            {/* <div className="modal fade" id="acceptdiscrepancy" tabindex="-1" role="dialog" aria-labelledby="acceptdiscrepancyLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5>Add Remarks</h5>

                            <div class="form-group mt-3">
                                <label>Remark</label>
                                <textarea
                                    className="form-control"
                                    style={{ minHeight: '120px' }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setRemarkText(value);
                                        debouncedOnChange(value);
                                    }}></textarea>
                            </div>

                            <div className="d-flex">
                                <button type="button" onClick={AddRemarks} className="btn btn-submit ml-auto">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="disputediscrepancy" tabindex="-1" role="dialog" aria-labelledby="disputediscrepancyLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5>Close Remarks</h5>

                            <div class="form-group mt-3">
                                <label>Remark</label>
                                <textarea className="form-control" style={{ minHeight: '120px' }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setRemarkCloseText(value);
                                        debouncedOnChangClose(value);
                                    }}></textarea>
                            </div>

                            <div className="d-flex">
                                <button type="button" onClick={CloseRemarks} className="btn btn-submit ml-auto">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className="modal fade" id="acceptdiscrepancy" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Accept Discrepancy</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <textarea className="form-control" style={{ minHeight: '120px' }}
                                value={remarkText}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setRemarkText(value);
                                    debouncedOnChange(value);
                                }}></textarea>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={AddRemarks}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="disputediscrepancy" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Dispute Discrepancy</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <textarea className="form-control" style={{ minHeight: '120px' }}
                                value={remarkCloseText}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setRemarkCloseText(value);
                                    debouncedOnChangClose(value);
                                }}></textarea>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={CloseRemarks}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div> */}



            <Dialog open={openAcceptDialog} onClose={handleCloseDialog} maxWidth="sm"
                fullWidth={true} >
                <DialogTitle>
                    Accept Remarks
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Please enter your remarks.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remark"
                        multiline
                        minRows={8}
                        label="Please enter your remarks."
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={remarks.remark}
                        sx={{ mt: 2 }}
                        InputProps={{
                            sx: {
                                minHeight: '120px',
                                padding: '10px'
                            }
                        }}
                        onChange={(e) => setRemarks({ remark: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={AddRemarks} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDisputeDialog}
                onClose={handleCloseDisputeDialog}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>
                    Dispute Remarks
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDisputeDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Please enter your remarks.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="disputeremark"
                        label="Please enter your remarks."
                        type="text"
                        fullWidth
                        multiline
                        minRows={8}
                        variant="outlined"
                        value={remarksClose.remarksClosedispute}
                        onChange={(e) => setRemarksClose({ remarksClosedispute: e.target.value })}
                        sx={{ mt: 2 }}
                        InputProps={{
                            sx: {
                                minHeight: '120px',
                                padding: '10px'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDisputeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={CloseRemarks} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="modal fade" id="remarkspopup" tabindex="-1" role="dialog" aria-labelledby="remarksLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5 className="mb-3">Remarks</h5>

                            {remarksListOther.map((message, index) => (
                                <div className="chat-data">
                                    <PersonPinIcon />
                                    <div className="text">
                                        <p>{message.remarks}</p>
                                        <div className="bottom-text">
                                            <span>{message.userName}</span>
                                            <span>{message.remarkDate}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                {remarksList.map((message, index) => (
                                    <div className="chat-data right-side" key={index}>
                                        <PersonPinIcon />
                                        <div className="text">
                                            <p>{message.remarks}</p>
                                            <div className="bottom-text">
                                                <span>{message.userName}</span>
                                                <span>{message.remarkDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default DiscrepancyView;
