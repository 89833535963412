import * as React from "react";
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Switch from '@mui/material/Switch';


export default function PickedList() {

  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  
  const columns = [
    { field: 'id', headerName: 'Sr. No.', width: 80 },
    { field: 'masternumber', headerName: 'Master Number', width: 150 },
    { field: 'childnumber', headerName: 'Child Number', width: 150 },
    { field: 'date', headerName: 'Picked Date', width: 150 },
    // { field: 'status', headerName: 'Status', width: 120 },
    {
      field: 'approved',
      headerName: 'Approved',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch defaultChecked />
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <span className="badge bg-success rounded-pill">Picked</span>
        )
      },
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton aria-label="download">
            <DownloadRoundedIcon />
          </IconButton>
        )
      },
    },

  ];


  const rows = [
    { id: 1, masternumber: '360036256532', childnumber: '1111222451', date: '05/02/2023'},
    { id: 2, masternumber: '360036256532', childnumber: '1111222452', date: '05/02/2023'},
    { id: 3, masternumber: '360036256532', childnumber: '1111222453', date: '05/02/2023'},
    { id: 4, masternumber: '360036256532', childnumber: '1111222454', date: '05/02/2023'},
    { id: 5, masternumber: '360036256532', childnumber: '1111222455', date: '05/02/2023' }
  ];


  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Total Order Picked</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Total Order Picked</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
              <DataGrid sx={{ p: "1rem" }} className='table-ui'
                rows={rows}
                columns={columns}
                components={{}}
                autoHeight={true}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => {
                  setRowsPerPage(newPageSize);
                }}
              />
          </Card>
        </div>
      </section>
    </div>
  );

}