import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import PickedList from "./PickedList";
import Footer from "./../Footer";
const PickedOrder = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PickedList />
      <Footer />
    </>
  );
};

export default PickedOrder;
