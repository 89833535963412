import React from "react";

const Location = (prop) => {
  return (
    <>

      {/* MAP START MAP START MAP START  */}
      <section className="about-section" id={prop.sectionId}>
        <div className="container">
          <div className="row">
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12 overflow-hidden">
              <div className="">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6999.762617118945!2d77.1451281!3d28.6931969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d023387aa8c5f%3A0x5308a30e53e4cefc!2sBIG%20JO'S%20TOWER!5e0!3m2!1sen!2sin!4v1641207187387!5m2!1sen!2sin"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  title="Google Maps Embed"
                />
              </div>
            </div>

            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <h5>Our Location</h5>
                </div>
                <div className="text">
                  <a >
                    <i className="fas fa-phone"></i> : <strong>Contact Person:</strong>: Mr.Rahil Nindra<br />
                    Mobile No: 9311712973 
                  </a>
                </div>
                <div className="text">
                  <a href="mailto:care@mvikas.in">
                    <i className="fas fa-envelope-open-text"></i> :
                    <strong>Email:</strong>: rahil.nindra@mvikas.in
                  </a>
                </div>
                <div className="text">
                  <a
                    href="https://www.google.com/maps/place/BIG+JO'S+TOWER/@28.6931969,77.1451281,16z/data=!4m9!1m2!2m1!1s11g+big+josh+tower+netaji+subhash+place+pitampura+delhi!3m5!1s0x390d023387aa8c5f:0x5308a30e53e4cefc!8m2!3d28.692013!4d77.1520021!15sCjYxMWcgYmlnIGpvcyB0b3dlciBuZXRhamkgc3ViaGFzaCBwbGFjZSBwaXRhbXB1cmEgZGVsaGmSAQ9idXNpbmVzc19jZW50ZXI"
                    target="_blank"
                  >
                    <i className="fas fa-street-view"></i> : Mvikas Super Logistics Private Limited, 11 G Big Jos Tower<br />
                    Netaji Subhash Place New Delhi – 110034
                  </a>
                </div>
                <div className="text">
                  <a href="Javascript:void">
                    <i className="far fa-clock"></i> : 10 am-6 pm <br /> Monday
                    to Saturday
                  </a>
                </div>
{/* 
                <div className="btn-box">
                  <a href="#" className="theme-btn btn-style-one">
                    Contact Us
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* MAP END MAP END MAP END */}
    </>
  );
};

export default Location;
