import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PinCode from "./pincode";
import Footer from "./Footer";
const PinCodeList = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PinCode />
      <Footer />
    </>
  );
};

export default PinCodeList;
