import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Getratebyservice } from "../Services/RateApi";
import { Getserviceshavingrate } from "../Services/ServiceApi";
import Loading from "./Loading";
import DynamicTable from "./DynamicTable";
import { GetUnitTypes, GetAdditionalMastersData } from "../../src/Services/RateApi";

const ViewUploadedRate = () => {
  const [servicenm, setServicenm] = useState("");
  const [inputdata, setInputdata] = useState("");
  const [data, setData] = useState("");
  const [rates, setRates] = useState("");
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState("");
  const [tabldata, setTabledata] = useState("");
  let handlingchargesHead = ["from", "to", "unit", "rate"];
  const [handlingchg, setHandlingchg] = useState([]);
  const [additionalchg, setAdditionalchg] = useState("");
  const [additionalchgData, setAdditionalchgData] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [masterdata, setMasterdata] = useState([]);


  useEffect(() => {
    let vendoridforservices = localStorage.getItem("user_id")
    Getserviceshavingrate(vendoridforservices).then((res) => {
      setServicenm(res);
      setLoading(false);
    });
    GetUnitTypes().then((res) => {
      setUnitOptions(res.data);
    });

    GetAdditionalCharges();
  }, []);
  const GetAdditionalCharges = (e) => {
    GetAdditionalMastersData().then((res) => {
      setMasterdata(res.data);
    });
  };
  const getUnitTypeLabel = (unitTypeValue) => {
    const unitTypetobind = unitOptions.find((unit) => unit.id === parseInt(unitTypeValue));
    return unitTypetobind ? unitTypetobind.name : "";
  };
  // const renderPreviewText = () => {
  //   return additionalData.calculationField.percentageValueData.calculationSteps
  //     .map((step) => {
  //       let stepText = "";
  //       if (step.previousOperator === "1") {
  //         stepText += " + ";
  //       } else if (step.previousOperator === "2") {
  //         stepText += " x ";
  //       } else if (step.previousOperator === "3") {
  //         stepText += " % ";
  //       } else if (step.previousOperator === "4") {
  //         stepText += " - ";
  //       }
  //       else if (step.previousOperator === "5") {
  //         stepText += " / ";
  //       }
  //       if (step.leftSideSelection) {
  //         stepText += getNameById(step.leftSideSelection);
  //       } else {
  //         stepText += step.leftSideValue || "";
  //       }

  //       if (step.operator === "1") {
  //         stepText += " + ";
  //       } else if (step.operator === "2") {
  //         stepText += " x ";
  //       } else if (step.operator === "3") {
  //         stepText += " % ";
  //       } else if (step.operator === "4") {
  //         stepText += " - ";
  //       }
  //       else if (step.operator === "5") {
  //         stepText += " / ";
  //       }
  //       if (step.rightSideSelection) {
  //         stepText += getNameById(step.rightSideSelection);
  //       } else {
  //         stepText += step.rightSideValue || "";
  //       }
  //       return stepText;
  //     })
  //     .join("");
  // };
  const renderPreviewOperator = (unitType) => {
    const selectedUnit = unitOptions.find((x) => x.id === parseInt(unitType, 10));
    return selectedUnit ? selectedUnit.name : "";
  };
  // const renderPercentageText = (calculationSteps) => {
  //   debugger
  //   return calculationSteps
  //     .map((step) => {
  //       console.log("step", step);
  //       let stepText = "";
  //       if (step.previousOperator === "1") {
  //         stepText += " + ";
  //       } else if (step.previousOperator === "2") {
  //         stepText += " x ";
  //       } else if (step.previousOperator === "3") {
  //         stepText += " % ";
  //       } else if (step.previousOperator === "4") {
  //         stepText += " - ";
  //       } else if (step.previousOperator === "5") {
  //         stepText += " / ";
  //       }
  //       if (step.leftSideSelection) {
  //         stepText += getNameById(step.leftSideSelection);
  //       } else {
  //         stepText += step.leftSideValue || "";
  //       }

  //       if (step.operator === "1") {
  //         stepText += " + ";
  //       } else if (step.operator === "2") {
  //         stepText += " x ";
  //       } else if (step.operator === "3") {
  //         stepText += " % ";
  //       } else if (step.operator === "4") {
  //         stepText += " - ";
  //       }
  //       else if (step.operator === "5") {
  //         stepText += " / ";
  //       }

  //       if (step.rightSideSelection) {
  //         stepText += getNameById(step.rightSideSelection);
  //       } else {
  //         stepText += step.rightSideValue || "";
  //       }
  //       return stepText;
  //     })
  //     .join("");
  // };
  const renderPercentageText = (calculationSteps) => {
    console.log('Calculation Steps:', calculationSteps);
    if (!calculationSteps || calculationSteps.length === 0) {
      return '';
    }
    let placeholderText = '';

    calculationSteps.forEach((step, index) => {
      console.log('Step:', step);
      if (index !== 0) {
        if (step.previousOperator === '1') {
          placeholderText += ' + ';
        } else if (step.previousOperator === '2') {
          placeholderText += ' x ';
        } else if (step.previousOperator === '3') {
          placeholderText += ' % ';
        } else if (step.previousOperator === '4') {
          placeholderText += ' - ';
        } else if (step.previousOperator === '5') {
          placeholderText += ' / ';
        }
      }

      if (step.leftSideSelection) {
        console.log('Left Side Selection:', step.leftSideSelection);
        placeholderText += getNameById(step.leftSideSelection);
      } else {
        console.log('Left Side Value:', step.leftSideValue);
        placeholderText += step.leftSideValue || '';
      }
      if (step.operator === '1') {
        placeholderText += ' + ';
      } else if (step.operator === '2') {
        placeholderText += ' x ';
      } else if (step.operator === '3') {
        placeholderText += ' % ';
      } else if (step.operator === '4') {
        placeholderText += ' - ';
      } else if (step.operator === '5') {
        placeholderText += ' / ';
      }

      if (step.rightSideSelection) {
        console.log('Right Side Selection:', step.rightSideSelection);
        placeholderText += getNameById(step.rightSideSelection);
      } else {
        console.log('Right Side Value:', step.rightSideValue);
        placeholderText += step.rightSideValue || '';
      }
    });

    console.log('Placeholder Text:', placeholderText);
    return placeholderText;
  };


  const getNameById = (id) => {
    const item = masterdata.find((item) => item.id === id);
    return item ? item.name : null;
  };
  const inputEvent = (e) => {
    setLoading(true);
    setInputdata(e.target.value);
    let vendorid = localStorage.getItem("user_id")
    Getratebyservice(e.target.value, vendorid).then((res) => {
      setData(res.data);
      setRates(res.data.rates);
      setHandlingchg(res.data.handlingcharges);
      setAdditionalchg(res.data.additionalcharges);
      if (res.data.additionalchargesData) {
        setAdditionalchgData(JSON.parse(res.data.additionalchargesData));
      }
      let list = [];
      let listlen = 1;

      for (var i in res.data.rates) {
        listlen++;
        list.push(res.data.rates[i].zoneidto);
        if (listlen > Math.sqrt(res.data.rates.length)) break;
      }
      // setAdditionalchg(
      //   <>
      //     <div className="col-md-6 shadow-lg spanStyle">
      //       <h5
      //         className="py-2 mb-0 px-2 text-white mt-4"
      //         style={{ backgroundColor: "#3f474e" }}
      //       >
      //         Additional Charges
      //       </h5>
      //       <div className="row p-3">
      //         <div className="col-6 border-end-style">
      //           <span className="">Min Chargable Wt. : </span>
      //           <span className=""></span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.min_Weight}
      //           </span>
      //         </div>{" "}
      //         <div className="col-6 border-end-style text-end">
      //           <span className="">Max Allowed Wt. : </span>
      //           <span className=""></span>
      //           <span className="">
      //             &#8377;
      //             {res.data.additionalcharges.max_Weight}
      //           </span>
      //         </div>
      //         <div className="col-6  ">
      //           <span className="">Min ODA1 : </span>
      //           <span className=""></span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.min_ODA}
      //           </span>
      //         </div>
      //         <div className="col-6  text-end">
      //           <span className="">Min ODA2: </span>
      //           <span className=""></span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.min_ODA2}
      //           </span>
      //         </div>
      //         <div className="col-6 ">
      //           <span>ODA1 Per/Kg : </span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.actual_ODA}
      //           </span>{" "}
      //         </div>
      //         <div className="col-6 text-end">
      //           <span>ODA2 Per/Kg : </span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.actual_ODA2}
      //           </span>{" "}
      //         </div>
      //         <div className="col-6 border-end-style">
      //           <span className="">Min Freight : </span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.min_Freight}
      //           </span>
      //         </div>
      //         <div className="col-6 text-end ">
      //           <span>COD : </span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.cod}
      //           </span>
      //         </div>
      //         <div className="col-6 border-end-style">
      //           <span className="">Min FOV : </span>
      //           <span className="">
      //             &#8377;{res.data.additionalcharges.actual_FOV}
      //           </span>
      //         </div>
      //         <div className="col-6 text-end">
      //           <span className="">Docket Charge : </span>
      //           <span className="">
      //             &#8377;
      //             {res.data.additionalcharges.docket_Charge}
      //           </span>
      //         </div>
      //         <div className="col-6 border-end-style">
      //           <span>FOV Percentage : </span>
      //           <span className="">
      //             {res.data.additionalcharges.actual_FOV}%
      //           </span>
      //         </div>
      //         <div className="col-6 text-end">
      //           <span className="">Min Green Tax : </span>
      //           <span className="">
      //             &#8377;
      //             {res.data.additionalcharges.minGreenTax}
      //           </span>
      //         </div>
      //         <div className="col-6 border-end-style">
      //           {" "}
      //           <span className="">FSC Percentage : </span>
      //           <span className="">{res.data.additionalcharges.fsc}%</span>
      //         </div>
      //         <div className="col-6 text-end">
      //           {" "}
      //           <span className="">Green Tax Per/Kg : </span>
      //           <span className="">
      //             &#8377;
      //             {res.data.additionalcharges.greenTaxperkg}
      //           </span>
      //         </div>
      //         <div className="col-6 border-end-style">
      //           <span>GST : </span>
      //           <span className="">{res.data.additionalcharges.gst}%</span>
      //         </div>
      //       </div>
      //     </div>
      //   </>
      // );
      // const list = res.data.rates.map((rate) => rate.zoneidto);
      // setColumns(list.slice(0, Math.sqrt(res.data.rates.length)));
      Bindtablecolumn(list);
      Bindtabledata(list, res.data.rates);
      setLoading(false);
    });
  };
  const Bindtablecolumn = (list) => {
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  const renderAdditionalCharges = () => {
    if (additionalchg) {
      const additionalChargesKeys = {
        min_Weight: "Min Chargable Wt.",
        max_Weight: "Max Allowed Wt.",
        min_ODA: "Min ODA1",
        min_ODA2: "Min ODA2",
        actual_ODA: "ODA1 Per/Kg",
        actual_ODA2: "ODA2 Per/Kg",
        min_Freight: "Min Freight",
        cod: "COD",
        min_FOV: "Min FOV",
        docket_Charge: "Docket Charge",
        actual_FOV: "FOV Percentage",
        minGreenTax: "Min Green Tax",
        fsc: "FSC Percentage",
        greenTaxperkg: "Green Tax Per/Kg",
        gst: "GST"
      };
      return Object.entries(additionalChargesKeys).map(([key, label]) => (
        <div className="col-6" key={key}>
          <span>{label} : </span>
          <span>&#8377;{additionalchg[key]}</span>
        </div>
      ));
    }
    else if (additionalchgData.length > 0) {
      return additionalchgData.map((item, index) => {
        const { name, masterData } = item;
        const { calculationField } = masterData;

        const renderField = (field) => {

          if (field.percentageValueData && field.percentageValueData.labelText) {
            return (
              <div className="width-100">
                <div className="row">
                  {masterData.minValue ? (
                    <div className="form-group col-sm-6">
                      <div class="input-group mr-sm-2 input-group-ui">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            {masterData.minValueData
                              .unitType == 1
                              ? "%"
                              : "$N"}
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder={`Minimum ${masterData.minValueData.label}`}
                        // value={
                        //   masterData.minValueData
                        //     .minValue
                        // }
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* {viewDropMasterdata[0].masterData.minValue ? (
                  <div className="form-group col-sm-6">
                    <div class="input-group mr-sm-2 input-group-ui">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          {viewDropMasterdata[0].masterData
                            .minValueData.unitType == 1
                            ? "%"
                            : "$N"}
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        placeholder={`Minimum ${viewDropMasterdata[0].masterData.minValueData.label}`}
                        value={
                          viewDropMasterdata[0].masterData
                            .minValueData.minValue
                        }
                      />
                    </div>
                  </div>
                ) : null} */}
                  {/* <div className="form-group col-sm-6">
                  <div class="input-group mr-sm-2 input-group-ui">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        {renderPreviewOperator(field.percentageValueData
                          .unitType)}
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      disabled={
                        field.percentageValueData
                          .isdependent
                          ? true
                          : false
                      }
                      placeholder={renderPercentageText(
                        field.percentageValueData
                          .calculationSteps
                      )}
                      value={
                        field.percentageValueData.amount
                      }
                    />
                  </div>
                </div> */}
                  <div className="width-100">

                    <div className="form-group">
                      <div class="input-group mr-sm-2 input-group-ui">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            {renderPreviewOperator(field.percentageValueData.unitType)}
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          disabled={field.percentageValueData.isdependent ? true : false}
                          placeholder={renderPercentageText(field.percentageValueData.calculationSteps)}
                        // value={field.percentageValueData.amount}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            );
          } else if (field.unitValueData && field.unitValueData.labelText) {

            const unitTypeLabel = getUnitTypeLabel(field.unitValueData.unitType);
            return (
              <div className="row">
                {masterData.minValue ? (
                  <div className="form-group col-sm-6">
                    <div class="input-group mr-sm-2 input-group-ui">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          {masterData.minValueData
                            .unitType == 1
                            ? "%"
                            : "$N"}
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        placeholder={`Minimum ${masterData.minValueData.label}`}
                      // value={
                      //   masterData.minValueData
                      //     .minValue
                      // }
                      />
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <div class="input-group mr-sm-2 input-group-ui">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        {unitTypeLabel}
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder={field.unitValueData.placeholderText}
                      value={field.unitValueData.unitAmount}
                    />
                  </div>
                </div>
              </div>
            );
          } else if (field.slabValueData && field.slabValueData.labelText) {
            return (
              <React.Fragment key={index}>
                {field.slabValueData.calculationSteps.map((step, idx) => (
                  <div className="row" key={idx}>
                    <div className="row">
                      {masterData.minValue ? (
                        <div className="form-group col-sm-6">
                          <div class="input-group mr-sm-2 input-group-ui">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                {masterData.minValueData
                                  .unitType == 1
                                  ? "%"
                                  : "$N"}
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              disabled
                              placeholder={`Minimum ${masterData.minValueData.label}`}
                            // value={
                            //   masterData.minValueData
                            //     .minValue
                            // }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">From</div>
                          </div>
                          <input type="text" className="form-control" value={step.from} readOnly />
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">To</div>
                          </div>
                          <input type="text" className="form-control" value={step.to} readOnly />
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <select
                          className="form-select"
                          name="unitType"
                          disabled
                          value={step.unitType}
                        >
                          {unitOptions.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">₹</div>
                          </div>
                          <input type="text" className="form-control" value={step.price} readOnly />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>

            );
          }
          return null;
        };

        return (
          <div className="col-12" key={index}>
            <h6>{name}</h6>
            {renderField(calculationField)}
          </div>
        );
      });
    }
    return null;
  };
  if (loading) return <Loading />;
  else {
    return (
      <div>
        <div className="content-wrapper">
          <div className="container  px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 mt-3">
              <h3>View Uploaded Rate</h3>
              <div>
                <Link to="/uploadrate" className="text-white btn btn-info">
                  Upload Rate
                </Link>
              </div>
            </div>
            <div
              className="row"
            // style={{
            //   borderBottom: "2px solid #dee2e6",
            //   paddingBottom: "7px",
            // }}
            >
              <div className="col-md-6 mb-4">
                <span>
                  Select Service Name<span style={{ color: "red" }}>*</span>
                </span>
                <select
                  className="form-control"
                  onChange={inputEvent}
                  name="inputdata"
                  value={inputdata}
                >
                  <option selected hidden>
                    Select
                  </option>
                  {!(servicenm.length > 0)
                    ? ""
                    : servicenm.map((val, i) => {
                      return (
                        <option key={i} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {/* {columns === "" ? (
              ""
            ) : (
              <>
                <h5
                  className="py-2 mb-0 px-2  text-white"
                  style={{ backgroundColor: "#3f474e" }}
                >
                  Uploaded Rates
                </h5>
                <div className="table-responsive">
                  <table className="table  text-nowrap">
                    <thead>
                      <tr>
                        <th>FROM / TO</th>
                        {columns}
                      </tr>
                    </thead>
                    <tbody>{tabldata}</tbody>
                  </table>
                </div>
              </>
            )}
            <div
              className="row justify-content-between"
              style={{
                paddingBottom: "7px",
              }}
            >
              {additionalchg}
              {handlingchg.length === 0 ? (
                ""
              ) : (
                <div className="col-md-6 shadow-lg">
                  <h5
                    className="py-2 mb-0 px-2 text-white mt-4"
                    style={{ backgroundColor: "#3f474e" }}
                  >
                    Handling Charges
                  </h5>
                  <DynamicTable
                    headers={handlingchargesHead}
                    data={handlingchg}
                  />
                </div>
              )}
            </div> */}
            {columns.length > 0 && (
              <>
                <h5
                  className="py-2 mb-0 px-2 text-white"
                  style={{ backgroundColor: "#3f474e" }}
                >
                  Uploaded Rates
                </h5>
                <div className="table-responsive">
                  <table className="table text-nowrap">
                    <thead>
                      <tr>
                        <th>FROM / TO</th>
                        {columns.map((col, index) => (
                          <th key={index}>{col}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{tabldata}</tbody>
                  </table>
                </div>
              </>
            )}
            <div className="row justify-content-between" style={{ paddingBottom: "7px" }}>
              {additionalchgData.length > 0 && (
                <div className="col-md-6 shadow-lg">
                  <h5 className="py-2 mb-0 px-2 text-white mt-4" style={{ backgroundColor: "#3f474e" }}>
                    Additional Charges
                  </h5>
                  <div className="row p-3">{renderAdditionalCharges()}</div>
                </div>
              )}
              {handlingchg.length > 0 && (
                <div className="col-md-6 shadow-lg">
                  <h5
                    className="py-2 mb-0 px-2 text-white mt-4"
                    style={{ backgroundColor: "#3f474e" }}
                  >
                    Handling Charges
                  </h5>
                  <DynamicTable
                    // headers={Object.keys(handlingchg[0])} 
                    headers={handlingchargesHead}
                    data={handlingchg} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ViewUploadedRate;
