import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BulkOrders from "./BulkOrders";
import Footer from "./Footer";
import BulkOrdersCustomer from "./BulkOrdersCustomer";
const UploadBulkOrdersCustomer = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <BulkOrdersCustomer />
      <Footer />
    </>
  );
};

export default UploadBulkOrdersCustomer;
