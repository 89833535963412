import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import List from "./List";
import Footer from "./../Footer";
const ScannedDetails = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <List />
      <Footer />
    </>
  );
};

export default ScannedDetails;
