import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import VenderDetails from "./venderdetails";
import Footer from "./Footer";
const VenderList = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <VenderDetails />
      <Footer />
    </>
  );
};

export default VenderList;
