import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    GetMetroName,
    GetpincodeByMetroId,
    DeleteMetro,
    GetpincodeByLpid
} from "../Services/MetroApi";
import { GetIntracityAndROIRatesByLpId,DeleteSpecialZone } from "../Services/SpecialZoneApi"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import { LinearProgress, Chip } from "@mui/material";
const SpecialZone = () => {
    const navigate = useNavigate();
    const [zoneData, setZoneData] = useState([]);
    const [zoneName, setZoneName] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        BindZone();
    }, []);
    let Head;
    const BindZone = () => {
        let mounted = true;
        let lpid = localStorage.getItem("user_id")
        GetIntracityAndROIRatesByLpId(lpid).then((items) => {
            if (mounted) {
                // setZoneName(items);
                setLoading(false);
                setZoneData(items);
            }
        });
        return () => (mounted = false);
    };
    const Togglespan = (id, e) => {
        if (!e.target.className.split(" ").includes("collapsed")) {
            setPincodes([]);
            let lpid = localStorage.getItem("user_id")
            GetpincodeByLpid(lpid).then((data) => {
                setPincodes(data);
            });
        }
    };
    const deleteEvent = (id) => {
        if (window.confirm("Do you want to delete")) {
            setLoading(true);
            DeleteSpecialZone(id).then((res) => {
                if (res.status === 200) {
                    BindZone();
                    toast.success("Zone deleted successfully");
                } else {
                    setLoading(false);
                    toast.warn("Techincal error");
                }
            });
        }
    };
    const EditEvent = (id) => {
        navigate(`/specialzone/${id}`);
    };
    Head = zoneName.map((head, i) => (
        <div className="accordion-item" key={i}>
            <h2 className="accordion-header" id={`heading${head.id}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                        className="accordion-button collapsed"
                        onClick={(e) => Togglespan(head.id, e)}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${head.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${head.id}`}
                    >
                        {head.zoneType}
                    </button>
                    <span
                        className="fa fa-edit"
                        style={{
                            fontSize: "15px",
                            padding: "10px",
                            color: "green",
                            margin: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => EditEvent(head.id)}
                    ></span>
                </div>
            </h2>
            <div
                id={`collapse${head.id}`}
                className="accordion-collapse collapse "
                aria-labelledby={`heading${head.id}`}
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                    {pincodes.length !== 0 ? (
                        pincodes.map((val, i) => {
                            return (
                                <Chip
                                    value={val}
                                    label={val}
                                    p={1}
                                    variant="outlined"
                                    sx={{
                                        marginBottom: "5px",
                                        marginRight: "5px",
                                    }}
                                />
                            );
                        })
                    ) : (
                        <LinearProgress />
                    )}
                </div>
            </div>
        </div>
    ));

    // if (loading) return <Loading />;
    // else {
    //     return (
    //         <>
    //             <div className="content-wrapper">
    //                 <div className="d-flex justify-content-between align-items-center px-5">
    //                     <h3>Special Zone Data</h3>

    //                     <Link to="/SpecialZone" className="text-white btn btn-info">
    //                         Add Special Zone
    //                     </Link>
    //                 </div>
    //                 <div className="accordion px-5 mt-4" id="accordionExample">
    //                     {Head == "" ? (
    //                         <div className="text-center">
    //                             <h4>No Records Found !!</h4>
    //                         </div>
    //                     ) : (
    //                         Head
    //                     )}
    //                 </div>
    //             </div>
    //             <ToastContainer />
    //         </>
    //     );
    // }
    if (loading) return <Loading />;
    else {
        return (
            <>
                <div className="content-wrapper">
                    <div className="d-flex justify-content-between align-items-center px-5">
                        <h3>Special Zone Data</h3>
                        <Link to="/SpecialZone" className="text-white btn btn-info">
                            Add Special Zone
                        </Link>
                    </div>
                    <div className="px-5 mt-4">
                        {zoneData.length === 0 ? (
                            <div className="text-center">
                                <h4>No Records Found !!</h4>
                            </div>
                        ) : (
                            <div className="row">
                                {zoneData.map((zone, i) => (
                                    <div className="col-md-4" key={i}>
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <h5 className="card-title">{zone.zoneType}</h5>
                                                <p className="card-text">
                                                    <strong>Service ID:</strong> {zone.serviceId}<br />
                                                    <strong>Zone Type:</strong> {zone.zoneType}<br />
                                                    <strong>ODA Status:</strong> {zone.isOda}<br />
                                                    <strong>Standard TAT:</strong> {zone.stdtat}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <span
                                                        className="fa fa-edit"
                                                        style={{
                                                            fontSize: "15px",
                                                            padding: "10px",
                                                            color: "green",
                                                            margin: "5px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => EditEvent(zone.id)}
                                                    ></span>
                                                    <span
                                                        className="fa fa-trash"
                                                        style={{
                                                            fontSize: "15px",
                                                            padding: "10px",
                                                            color: "red",
                                                            margin: "5px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => deleteEvent(zone.id)}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    };
}

export default SpecialZone;
