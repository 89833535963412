import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Getratebyservice } from "../Services/RateApi";
import { Getserviceshavingrate } from "../Services/ServiceApi";
import Loading from "./Loading";
import MaterialTable from "material-table";
import { GetOrderdata } from "../Services/OrderApi";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Spydy = () => {
  const [defaultHiddenColumnNames] = useState();
  const [servicenm, setServicenm] = useState("");
  const [inputdata, setInputdata] = useState("");
  // const [data, setData] = useState("");
  const [rates, setRates] = useState("");
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState("");
  const [tabldata, setTabledata] = useState("");
  let handlingchargesHead = ["from", "to", "unit", "rate"];
  const [handlingchg, setHandlingchg] = useState([]);
  var curr = new Date();
  curr.setDate(curr.getDate() - 7);
  var todaydate = curr.toISOString().substr(0, 10);
  curr.setDate(curr.getDate() + 7);
  var nextday = curr.toISOString().substr(0, 10);
  const [data, setData] = useState({
    fromdate: todaydate,
    todate: nextday,
  });
  useEffect(() => {
    Getserviceshavingrate().then((res) => {
      setServicenm(res);
      setLoading(false);
    });
    let list = [];
    GetOrderdata(localStorage.getItem("user_id"), data).then((resp) => {
      resp.data.map((res) => {
        console.log(res);
        let tbdata = {
          fov_amount: res.fov_amount,
          frompincode: res.frompincode,
          fsc_amount: res.fsc_amount,
          fsc_percent: res.fsc_percent,
          greentax: res.greentax,
          lpname: res.lpname,
        };
        list.push(tbdata);
      });
      setRowData(list);
      // var col = [];

      // var colnm = [
      //   "fov_amount",
      //   "frompincode",
      //   "fsc_amount",
      //   "fsc_percent",
      //   "greentax",
      //   "lpname",
      // ];
      // for (var key in colnm) {
      //   col.push({ field: colnm[key], title: colnm[key] });
      // }

      // console.log(col);
      setColumnName([
        { field: "fov_amount", title: "FOV Amt." },
        { field: "frompincode", title: "F Pincode" },
        { field: "fsc_amount", title: "FSC Amt." },
        { field: "fsc_percent", title: "FSC %" },
        { field: "greentax", title: "Green Tax" },
        { field: "lpname", title: "Name" },
      ]);
    });
  }, []);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [additionalchg, setAdditionalchg] = useState("");
  const [columnname, setColumnName] = useState([]);
  const [rowdata, setRowData] = useState([]);
  const inputEvent = (e) => {
    setLoading(true);
    setInputdata(e.target.value);
    Getratebyservice(e.target.value).then((res) => {
      setData(res.data);
      setRates(res.data.rates);
      let list = [];
      let listlen = 1;

      for (var i in res.data.rates) {
        listlen++;
        list.push(res.data.rates[i].zoneidto);
        if (listlen > Math.sqrt(res.data.rates.length)) break;
      }
      setHandlingchg(res.data.handlingcharges);
      setAdditionalchg(
        <>
          <div className="col-md-6 shadow-lg spanStyle">
            <h5
              className="py-2 mb-0 px-2 text-white mt-4"
              style={{ backgroundColor: "#3f474e" }}
            >
              Additional Charges
            </h5>
            <div className="row p-3">
              <div className="col-6 border-end-style">
                <span className="">Min Chargable Wt. : </span>
                <span className=""></span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_Weight}
                </span>
              </div>
              <div className="col-6 text-end ">
                <span className="">Min ODA : </span>
                <span className=""></span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_ODA}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span className="">Max Allowed Wt. : </span>
                <span className=""></span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.max_Weight}
                </span>
              </div>
              <div className="col-6 text-end">
                <span>ODA Per/Kg : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.actual_ODA}
                </span>{" "}
              </div>
              <div className="col-6 border-end-style">
                <span className="">Min Freight : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_Freight}
                </span>
              </div>
              <div className="col-6 text-end ">
                <span>COD : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.cod}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span className="">Min FOV : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.actual_FOV}
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="">Docket Charge : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.docket_Charge}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span>FOV Percentage : </span>
                <span className="">
                  {res.data.additionalcharges.actual_FOV}%
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="">Min Green Tax : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.minGreenTax}
                </span>
              </div>
              <div className="col-6 border-end-style">
                {" "}
                <span className="">FSC Percentage : </span>
                <span className="">{res.data.additionalcharges.fsc}%</span>
              </div>
              <div className="col-6 text-end">
                {" "}
                <span className="">Green Tax Per/Kg : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.greenTaxperkg}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span>GST : </span>
                <span className="">{res.data.additionalcharges.gst}%</span>
              </div>
            </div>
          </div>
        </>
      );

      Bindtablecolumn(list);
      Bindtabledata(list, res.data.rates);
      setLoading(false);
    });
  };
  const Bindtablecolumn = (list) => {
    var col = [];
    list.map((res) => {
      col.push({ field: res, title: res });
    });
    //console.log(col);
    setColumnName(col);
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePage = useCallback(
    (gridPageChangeParams) => setCurrentPage(gridPageChangeParams.page),
    []
  );
  const Bindtabledata = (list, ratesdata) => {
    setRowData(ratesdata);
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  if (loading) return <Loading />;
  else {
    return (
      <div>
        <div className="content-wrapper">
          <div className="container  px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 mt-3">
              <h3>View Uploaded Rate</h3>
              <div>
                <Link to="/uploadrate" className="text-white btn btn-info">
                  Upload Rate
                </Link>
              </div>
            </div>

            {columns !== "" ? (
              ""
            ) : (
              <>
                <h5
                  className="py-2 mb-0 px-2  text-white"
                  style={{ backgroundColor: "#3f474e" }}
                >
                  Uploaded Rates
                </h5>
                <div className="table-responsive">
                  <MaterialTable
                    title="Testing data"
                    icons={tableIcons}
                    columns={columnname}
                    data={rowdata}
                    options={{
                      exportButton: true,
                      columnsButton: true,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Spydy;
