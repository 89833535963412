import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Button from '@mui/material/Button';
import { CurrentUrl } from '../Services/UrlApi'
let Url = `${CurrentUrl}User/`;

export default function BulkOrdersCustomer() {

  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  const downloadSampleFile = () => {
    const sampleFilePath = '/SampleBulkOrder.xlsx';
    const sampleFileUrl = process.env.PUBLIC_URL + sampleFilePath;
    fetch(sampleFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'SampleBulkOrder.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };
  const uploadFile = () => {
    const token = localStorage.getItem("Logkey");
    const useremail = localStorage.getItem("user_email");
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${Url}UploadBulkOrdersByCustomer?email=${useremail}`, {
        method: 'POST',
        body: formData,
        headers: {  Authorization: `Bearer ${token}`}
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            const successItems = data.filter((item) => item.code === 200);
            const errorItems = data.filter((item) => item.code !== 200);           
            errorItems.forEach((item) => {
              showToastError(item);
            });
            successItems.forEach((item) => {
              showToast(item);
            });
          } else {
            console.error('Invalid response format:', data);
            showToastError({ message: 'Invalid response format' });
          }
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          showToastError({ message: 'Error uploading file. Please try again later.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('No file selected for upload.');
      showToastWarning({ message: 'Please select a file for upload.' });
    }
  };
  const showToastError = (errorItem) => {
    toast.error(`Data Item ${errorItem.data_item} Error: ${errorItem.message}`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const showToast = (item) => {
    toast.success(` Your record has been successfully placed and will reflect shortly. Data Item ${item.data_item}: ${item.message}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const showToastWarning = (warningItem) => {
    toast.warning(`Warning: ${warningItem.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Upload Bulk Orders</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Upload Bulk Orders</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-lg-6">
              <Card className="card-down-up" style={{ height: '100%' }}>
                <CardActionArea onClick={downloadSampleFile} style={{ height: '100%' }}>
                  <CardContent  >
                    <SimCardDownloadRoundedIcon sx={{ fontSize: 50 }} />
                    <h4>Download excel sample data file</h4>
                    <p>
                      Explore our data effortlessly - download our Excel sample file for a hands-on experience in just one click!
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div className="col-lg-6">
              <Card className="card-down-up">
                <CardActionArea>
                  <CardContent >
                    <UploadFileRoundedIcon sx={{ fontSize: 50 }} />
                    <h4>Upload Bulk Orders file</h4>

                    <p>
                      Effortlessly streamline your workflow - simply upload your bulk orders file for swift and efficient processing.
                    </p>

                    <input
                      type="file"
                      onChange={handleFileUpload}
                      accept=".xlsx, .xls"
                    />

                    <Button variant="contained" onClick={uploadFile}>
                      {loading ? <CircularProgress size={24} /> : null}
                      {!loading && 'Upload'}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

