import React, { useEffect, useState } from "react";
import Header from './Header';
import Sidebar from './Sidebar';
import DownloadRateSheet from "../Components/DownloadRateSheet";
import Footer from './Footer';
import { useNavigate } from "react-router-dom";


const DownloadRatesheetAdmin = () => {
    const navigate = useNavigate();
    const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));
  
    useEffect(() => {
      if (userlog === undefined || userlog === null) {
        navigate("/");
      }
    }, []);
    return (
        <>
        <Header />
        <Sidebar />
        <DownloadRateSheet /> 
        </>
    )
}

export default DownloadRatesheetAdmin
