import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GetAdminRole } from "../../src/Services/AdminApi";
import "./Admin.css";

const Sidebar = () => {

  const [roleaccessfeature, setroleaccessfeature] = useState({});
  useEffect(() => {
    const roleid = localStorage.getItem("roleid");
    GetAdminRole(roleid).then((response) => {
      setroleaccessfeature(response.data);
    });
  }, []);

  useEffect(() => {
  }, [roleaccessfeature]);

  const navigate = useNavigate();
  const locate = useLocation();
  const [activelink] = useState(locate.pathname.substring(1));
  const role = localStorage.getItem("user_role").split(",");

  const collapseActive = (id) => {
    document.body.classList.remove("sidebar-open");
    document.body.classList.add("sidebar-closed");
    switch (id) {
      case 1:
        navigate("/Dashboard");
        break;
      case 2:
        navigate("/Zone");
        break;
      case 3:
        navigate("/createService");
        break;
      case 4:
        navigate("/mapservicezone");
        break;
      case 5:
        navigate("/uploadoda");
        break;
      case 6:
        navigate("/uploadrate");
        break;
      case 7:
        navigate("/viewrate/1");
        break;
      case 8:
        navigate("/orderview");
        break;
      case 9:
        navigate("/adminhandler");
        break;
      case 10:
        navigate("/manageaddress");
        break;
      case 11:
        navigate("/settings");
        break;
      case 12:
        navigate("/uploadtat");
        break;
      case 13:
        navigate("/downloadratesheet");
        break;
      case 14:
        navigate("/setting");
        break;
      case 15:
        navigate("/invoices");
        break;
      case 16:
        navigate("/reports");
        break;
      case 17:
        navigate("/invoice");
        break;
      case 18:
        navigate("/vsetting");
        break;
      case 19:
        navigate("/venderlist");
        break;
      case 20:
        navigate("/userdetails");
        break;
      case 21:
        navigate("/myorder/1");
        break;
      case 22:
        navigate("/mypayments");
        break;
      case 23:
        navigate("/orderslist");
        break;
      case 24:
        navigate("/customersdetails");
        break;
      case 25:
        navigate("/pincode");
        break;
      // case 26:
      //   navigate("/admininvoice");
      //   break;
      case 27:
        navigate("/uploadbulkorders");
        break;
      case 28:
        navigate("/PurchaseReport");
        break;
      case 29:
        navigate("/SalesReport");
        break;
      case 26:
        navigate("/salesinvoice");
        break;
      case 30:
        navigate("/purchaseinvoice");
        break;
      case 31:
        navigate("/venderprofile");
        break;
      case 32:
        navigate("/vendorcustomerdetails");
        break;
      case 33:
        navigate("/SMSProvider");
        break;
      case 34:
        navigate("/Review");
        break;
      case 35:
        navigate("/ReviewList");
        break;
      case 36:
        navigate("/Notifications");
        break;
      case 37:
        navigate("/Onboarding");
        break;
      case 38:
        navigate("/CustomerOrderPlaced");
        break;
      case 39:
        navigate("/RolesAssigned");
        break;
      case 40:
        navigate("/Barcode");
        break;
      case 41:
        navigate("/UserRegistration");
        break;
      case 42:
        navigate("/ScannedDetails");
        break;
      case 43:
        navigate("/uploadcourierrate");
        break;
      case 44:
        navigate("/Metro");
        break;
      case 45:
        navigate("/SpecialZone");
        break;
      case 46:
        navigate("/CustomiseLabelParent");
        break;
      case 47:
        navigate("/weight");
        break;
      case 48:
        navigate("/api");
        break;
      case 51:
        navigate("/viewrate/2");
        break;
      case 52:
        navigate("/myorder/2");
        break;
      case 53:
        navigate("/uploadbulkordersb2c");
        break;
      case 54:
        navigate("/uploadbulkorderscustomer");
        break;
      case 55:
        navigate("/uploadbulkorderscustomerb2c");
        break;
    }
  };

  document.body.addEventListener("click", () => {
    collapseActive();
  });

  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActiveB2B, setIsActiveB2B] = useState(false);
  const [isActiveB2C, setIsActiveB2C] = useState(false);
  const [isActiveVR, setIsActiveVR] = useState(false);
  const [isActiveMO, setIsActiveMO] = useState(false);


  const handleClick = (event) => {
    setIsActive((current) => current);
  };

  const handleClick1 = (event) => {
    setIsActive1((current) => current);
  };

  const handleClickB2B = (event) => {
    setIsActiveB2B((current) => current);
  };
  const handleClickB2C = (event) => {
    setIsActiveB2C((current) => current);
  };
  const handleClickVR = (event) => {
    setIsActiveVR((current) => current);
  };
  const handleClickMO = (event) => {
    setIsActiveMO((current) => current);
  };
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary">
        {/* Brand Logo */}
        <Link
          to={"/Dashboard"}
          className="brand-link"
          style={{ display: "flex", width: "auto" }}
        >
          <img
            src="./images/logo.svg"
            alt="logo"
            className="brand-image"
            style={{ opacity: ".8" }}
          />
        </Link>
        {/* Sidebar */}
        <div className="sidebar-scroll">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li
                className={
                  activelink === "Dashboard" ? "nav-item menu-open" : "nav-item"
                }
                id="1"
              >
                <span onClick={(e) => collapseActive(1)} className="nav-link">
                  <i className="fas fa-layer-group "></i>
                  <p>Dashboard</p>
                </span>
              </li>
              {role.includes("Admin") ? (
                <>
                  {roleaccessfeature.customersList ? (
                    <li
                      className={
                        activelink === "customersdetails" ||
                          activelink === "nav-item"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="24"
                    >
                      <span
                        onClick={(e) => collapseActive(24)}
                        className="nav-link"
                      >
                        <i className="fas fa-user-friends"></i>
                        <p>Customers List</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.vendorList ? (
                    <li
                      className={
                        activelink === "venderlist"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="19"
                    >
                      <span
                        onClick={(e) => collapseActive(19)}
                        className="nav-link"
                      >
                        <i className="fas fa-users"></i>
                        <p>Vendor List</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.orderReport ? (
                    <li
                      className={
                        activelink === "PurchaseReport" ||
                          activelink === "SalesReport" ||
                          isActive
                          ? "nav-item menu-is-opening menu-open"
                          : "nav-item"
                      }
                      onClick={handleClick}
                    >
                      <span className="nav-link">
                        <i className="fas fa-file"></i>
                        <p>Order Report</p>

                        <i className="right fas fa-angle-right arrow-right"></i>
                      </span>
                      <ul className="nav nav-treeview">
                        <li
                          className={
                            activelink === "PurchaseReport" ||
                              activelink === "nav-item"
                              ? "nav-item menu-open"
                              : "nav-item"
                          }
                          id="28"
                        >
                          <span
                            onClick={(e) => collapseActive(28)}
                            className="nav-link"
                          >
                            <i className="fas fa-boxes"></i>
                            <p>Purchase</p>
                          </span>
                        </li>

                        <li
                          className={
                            activelink === "SalesReport" ||
                              activelink === "nav-item"
                              ? "nav-item menu-open"
                              : "nav-item"
                          }
                          id="29"
                        >
                          <span
                            onClick={(e) => collapseActive(29)}
                            className="nav-link"
                          >
                            <i className="fas fa-boxes"></i>
                            <p>Sales</p>
                          </span>
                        </li>
                      </ul>
                    </li>
                  ) : null}
                  {roleaccessfeature.invoice ? (
                    <li
                      className={
                        activelink === "purchaseinvoice" ||
                          activelink === "salesinvoice" ||
                          isActive1
                          ? "nav-item menu-is-opening menu-open"
                          : "nav-item"
                      }
                      onClick={handleClick1}
                    >
                      <span className="nav-link">
                        <i className="fas fa-file"></i>
                        <p>Invoice</p>

                        <i className="right fas fa-angle-right arrow-right"></i>
                      </span>
                      <ul className="nav nav-treeview">
                        <li
                          className={
                            activelink === "purchaseinvoice" ||
                              activelink === "nav-item"
                              ? "nav-item menu-open"
                              : "nav-item"
                          }
                          id="30"
                        >
                          <span
                            onClick={(e) => collapseActive(30)}
                            className="nav-link"
                          >
                            <i className="fas fa-boxes"></i>
                            <p>Purchase Invoice</p>
                          </span>
                        </li>

                        <li
                          className={
                            activelink === "salesinvoice" ||
                              activelink === "nav-item"
                              ? "nav-item menu-open"
                              : "nav-item"
                          }
                          id="26"
                        >
                          <span
                            onClick={(e) => collapseActive(26)}
                            className="nav-link"
                          >
                            <i className="fas fa-boxes"></i>
                            <p>Sales Invoice</p>
                          </span>
                        </li>
                      </ul>
                    </li>
                  ) : null}





                  <li
                    className={
                      activelink === "uploadbulkorders" ||
                        activelink === "uploadbulkordersb2c" ||
                        isActive
                        ? "nav-item menu-is-opening menu-open"
                        : "nav-item"
                    }
                    onClick={handleClick}
                  >
                    <span className="nav-link">
                      <i className="fas fa-file"></i>
                      <p>Upload Bulk Orders</p>
                      <i className="right fas fa-angle-right arrow-right"></i>
                    </span>
                    <ul className="nav nav-treeview">
                      <li
                        className={
                          activelink === "uploadbulkorders"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="27"
                      >
                        <span
                          onClick={(e) => collapseActive(27)}
                          className="nav-link"
                        >
                          <i className="fas fa-upload"></i>
                          <p>B2B</p>
                        </span>
                      </li>
                      <li
                        className={
                          activelink === "uploadbulkordersb2c"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="53"
                      >
                        <span
                          onClick={(e) => collapseActive(53)}
                          className="nav-link"
                        >
                          <i className="fas fa-upload"></i>
                          <p>B2C</p>
                        </span>
                      </li>
                    </ul>
                  </li>















                  {roleaccessfeature.smsProvidersView ? (
                    <li
                      className={
                        activelink === "SMSProvider"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="33"
                    >
                      <span
                        onClick={(e) => collapseActive(33)}
                        className="nav-link"
                      >
                        <i className="fas fa-comments"></i>
                        <p>SMS Provider</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.reviewLists ? (
                    <li
                      className={
                        activelink === "ReviewList"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="35"
                    >
                      <span
                        onClick={(e) => collapseActive(35)}
                        className="nav-link"
                      >
                        <i className="fas fa-star"></i>
                        <p>Review List</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.notifications ? (
                    <li
                      className={
                        activelink === "Notifications"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="36"
                    >
                      <span
                        onClick={(e) => collapseActive(36)}
                        className="nav-link"
                      >
                        <i className="fas fa-bell"></i>
                        <p>Notifications</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.adminHandlerView ? (
                    <li
                      className={
                        activelink === "adminhandler"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="9"
                    >
                      <span
                        onClick={(e) => collapseActive(9)}
                        className="nav-link"
                      >
                        {/* <i className="fas fa-rupee-sign "></i> */}
                        <i className="fas fa-user-lock "></i>
                        <p>Admin Handler</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.assignedRoleView ? (
                    <li
                      className={
                        activelink === "RolesAssigned"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="39"
                    >
                      <span
                        onClick={(e) => collapseActive(39)}
                        className="nav-link"
                      >
                        {/* <i className="fas fa-rupee-sign "></i> */}
                        <i className="fas fa-user-lock "></i>
                        <p>Roles Assigned</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.assignedRoleView ? (
                    <li
                      className={
                        activelink === "UserRegistration"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="41"
                    >
                      <span
                        onClick={(e) => collapseActive(41)}
                        className="nav-link"
                      >
                        <i className="fas fa-users "></i>
                        <p>User Registration</p>
                      </span>
                    </li>
                  ) : null}
                  {roleaccessfeature.viewScannerUser ? (
                    <li
                      className={
                        activelink === "ScannedDetails"
                          ? "nav-item menu-open"
                          : "nav-item"
                      }
                      id="42"
                    >
                      <span
                        onClick={(e) => collapseActive(42)}
                        className="nav-link"
                      >
                        <i className="fas fa-qrcode"></i>
                        <p>Scanned Details</p>
                      </span>
                    </li>
                  ) : null}
                  {/* <li
                    className={
                      activelink === "ScannedDetails"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="48"
                  >
                    <span
                      onClick={(e) => collapseActive(48)}
                      className="nav-link"
                    >
                      <i className="fas fa-book"></i>
                      <p>Api documentation</p>
                    </span>
                  </li> */}
                </>
              ) : (
                <>
                  {role.includes("Vendor") ? (
                    <>
                      <li
                        className={
                          activelink === "orderslist" ||
                            activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="23"
                      >
                        <span
                          onClick={(e) => collapseActive(23)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>My Orders</p>
                        </span>
                      </li>
                      <li
                        className={
                          activelink === "invoice"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="17"
                      >
                        <span
                          onClick={(e) => collapseActive(17)}
                          className="nav-link"
                        >
                          <i className="fas fa-receipt"></i>
                          <p>Invoices</p>
                        </span>
                      </li>
                      <li
                        className={
                          activelink === "pincode" || activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="25"
                      >
                        <span
                          onClick={(e) => collapseActive(25)}
                          className="nav-link"
                        >
                          <i className="fas fa-map-pin"></i>
                          <p>Pin Code</p>
                        </span>
                      </li>
                      <li
                        className={
                          activelink === "zonedata" ||
                            activelink === "metrodata" ||
                            activelink === "specialzonedata" ||
                            activelink === "createService" ||
                            activelink === "mapservicezone" ||
                            activelink === "uploadoda" ||
                            activelink === "uploadtat" ||
                            activelink === "uploadrate" ||
                            activelink === "uploadcourierrate" ||
                            activelink === "Zone" ||
                            activelink === "SpecialZone" ||
                            isActive
                            ? "nav-item menu-is-opening menu-open"
                            : "nav-item"
                        }
                        onClick={handleClick}
                      >
                        <span className="nav-link">
                          <i className="fas fa-calculator"></i>
                          <p>Rates</p>

                          <i className="right fas fa-angle-right arrow-right"></i>
                        </span>
                        <ul className="nav nav-treeview">


                          <li
                            // class="nav-item"
                            className={
                              activelink === "createService"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="3"
                          >
                            <span
                              onClick={(e) => collapseActive(3)}
                              className="nav-link"
                            >
                              <i className="fas fa-shapes"></i>
                              <p>Services</p>
                            </span>
                          </li>
                          <li
                            className={
                              activelink === "Zone" ||
                                activelink === "SpecialZone" ||
                                activelink === "mapservicezone" ||
                                activelink === "uploadoda" ||
                                activelink === "uploadtat" ||
                                activelink === "uploadrate" ||
                                activelink === "uploadcourierrate" ||
                                isActiveB2B
                                ? "nav-item menu-is-opening menu-open"
                                : "nav-item"
                            }
                            onClick={handleClickB2B}
                          >
                            <span
                              onClick={(e) => collapseActive(49)}
                              className="nav-link"
                            >
                              <i className="fas fa-globe-europe"></i>
                              <p>B2B</p>

                              <i className="right fas fa-angle-right arrow-right"></i>
                            </span>

                            <ul className="nav nav-treeview">


                              <li
                                // class="nav-item"
                                className={
                                  activelink === "Zone" || activelink === "zonedata"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="2"
                              >
                                <span
                                  onClick={(e) => collapseActive(2)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-globe-europe"></i>
                                  <p>Zone</p>
                                </span>
                              </li>



                              {/* <li
                                className={
                                  activelink === "SpecialZone" || activelink === "specialzonedata"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="2"
                              >
                                <span
                                  onClick={(e) => collapseActive(45)}
                                  className="nav-link"
                                >
                                  <i className="fa-solid fa-building"></i>
                                  <p>Other B2C rates</p>
                                </span>
                              </li> */}


                              <li
                                // class="nav-item"
                                className={
                                  activelink === "mapservicezone" ||
                                    activelink === "ViewMapService"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="4"
                              >
                                <span
                                  onClick={(e) => collapseActive(4)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-map-signs"></i>
                                  <p>Zone & Service Mapping</p>
                                </span>
                              </li>
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "uploadoda"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="5"
                              >
                                <span
                                  onClick={(e) => collapseActive(5)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>Upload ODA</p>
                                </span>
                              </li>
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "uploadtat"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="5"
                              >
                                <span
                                  onClick={(e) => collapseActive(12)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>Upload TAT</p>
                                </span>
                              </li>
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "uploadrate" ||
                                    activelink === "viewuploadedrate"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="6"
                              >
                                <span
                                  onClick={(e) => collapseActive(6)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>Upload Cargo Rates</p>
                                </span>
                              </li>
                            </ul>
                          </li>


                          <li
                            className={
                              activelink === "Metro" ||
                                activelink === "SpecialZone" ||
                                activelink === "uploadcourierrate" ||
                                isActiveB2C
                                ? "nav-item menu-is-opening menu-open"
                                : "nav-item"
                            }
                            onClick={handleClickB2C}
                          >
                            <span
                              onClick={(e) => collapseActive(50)}
                              className="nav-link"
                            >
                              <i className="fas fa-globe-europe"></i>
                              <p>B2C</p>

                              <i className="right fas fa-angle-right arrow-right"></i>
                            </span>

                            <ul className="nav nav-treeview">
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "Metro" || activelink === "metrodata"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="2"
                              >
                                <span
                                  onClick={(e) => collapseActive(44)}
                                  className="nav-link"
                                >
                                  {/* <i className="fas fa-globe-europe"></i> */}
                                  <i className="fa-solid fa-building"></i>
                                  <p>Metro</p>
                                </span>
                              </li>

                              <li
                                className={
                                  activelink === "SpecialZone" || activelink === "specialzonedata"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="2"
                              >
                                <span
                                  onClick={(e) => collapseActive(45)}
                                  className="nav-link"
                                >
                                  <i className="fa-solid fa-building"></i>
                                  <p>Other B2C rates</p>
                                </span>
                              </li>

                              <li
                                // class="nav-item"
                                className={
                                  activelink === "uploadcourierrate" ||
                                    activelink === "viewuploadedcourierrate"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="6"
                              >
                                <span
                                  onClick={(e) => collapseActive(43)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>Upload Courier Rates</p>
                                </span>
                              </li>
                            </ul>
                          </li>

                        </ul>
                      </li>


                      <li
                        className={
                          activelink === "venderprofile"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="31"
                      >
                        <span
                          onClick={(e) => collapseActive(31)}
                          className="nav-link"
                        >
                          <i className="fas fa-cog"></i>
                          <p>Settings</p>
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      {role.includes("User") ? (
                        <>
                          {/* <li
                            className={
                              activelink === "viewrate"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="7"
                          >
                            <span
                              onClick={(e) => collapseActive(7)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>Book Orders</p>
                            </span>
                          </li> */}
                          <li
                            className={
                              activelink === "viewrate/1" ||
                                activelink === "viewrate/2" ||
                                isActiveVR
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            onClick={handleClickVR}
                          >
                            <span
                              // onClick={(e) => collapseActive(7)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>Book Orders</p>

                              <i className="right fas fa-angle-right arrow-right"></i>
                            </span>

                            <ul className="nav nav-treeview">
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "viewrate" || activelink === "viewrate"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="7"
                              >
                                <span
                                  onClick={(e) => collapseActive(7)}
                                  className="nav-link"
                                >
                                  {/* <i className="fas fa-globe-europe"></i> */}
                                  <i className="fa-solid fa-building"></i>
                                  <p>B2B</p>
                                </span>
                              </li>

                              <li
                                className={
                                  activelink === "viewrate" || activelink === "viewrate"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="51"
                              >
                                <span
                                  onClick={(e) => collapseActive(51)}
                                  className="nav-link"
                                >
                                  <i className="fa-solid fa-building"></i>
                                  <p>B2C</p>
                                </span>
                              </li>

                            </ul>
                          </li>

                          {/* <li
                            className={
                              activelink === "downloadratesheet"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="13"
                          >
                            <span
                              onClick={(e) => collapseActive(13)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>Rate Card</p>
                            </span>
                          </li> */}

                          {/* <li
                            className={
                              activelink === "myorder"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="21"
                          >
                            <span
                              onClick={(e) => collapseActive(21)}
                              className="nav-link"
                            >
                              <i className="fas fa-boxes"></i>
                              <p>My Orders</p>
                            </span>
                          </li> */}

                          <li
                            className={
                              activelink === "myorder/1" ||
                                activelink === "myorder/2" ||
                                isActiveMO
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            onClick={handleClickMO}
                          >
                            <span
                              // onClick={(e) => collapseActive(7)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>My Orders</p>

                              <i className="right fas fa-angle-right arrow-right"></i>
                            </span>

                            <ul className="nav nav-treeview">
                              <li
                                // class="nav-item"
                                className={
                                  activelink === "myorder" || activelink === "myorder"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="21"
                              >
                                <span
                                  onClick={(e) => collapseActive(21)}
                                  className="nav-link"
                                >
                                  {/* <i className="fas fa-globe-europe"></i> */}
                                  <i className="fa-solid fa-building"></i>
                                  <p>B2B</p>
                                </span>
                              </li>

                              <li
                                className={
                                  activelink === "myorder" || activelink === "myorder"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="52"
                              >
                                <span
                                  onClick={(e) => collapseActive(52)}
                                  className="nav-link"
                                >
                                  <i className="fa-solid fa-building"></i>
                                  <p>B2C</p>
                                </span>
                              </li>

                            </ul>
                          </li>
                          <li
                            className={
                              activelink === "uploadbulkorderscustomer" ||
                                activelink === "uploadbulkorderscustomerb2c" ||
                                isActive
                                ? "nav-item menu-is-opening menu-open"
                                : "nav-item"
                            }
                            onClick={handleClick}
                          >
                            <span className="nav-link">
                              <i className="fas fa-file"></i>
                              <p>Upload Bulk Orders</p>
                              <i className="right fas fa-angle-right arrow-right"></i>
                            </span>
                            <ul className="nav nav-treeview">
                              <li
                                className={
                                  activelink === "uploadbulkorderscustomer" ||
                                    activelink === "nav-item"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="54"
                              >
                                <span
                                  onClick={(e) => collapseActive(54)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>B2B </p>
                                </span>
                              </li>
                              <li
                                className={
                                  activelink === "uploadbulkorderscustomerb2c" ||
                                    activelink === "nav-item"
                                    ? "nav-item menu-open"
                                    : "nav-item"
                                }
                                id="55"
                              >
                                <span
                                  onClick={(e) => collapseActive(55)}
                                  className="nav-link"
                                >
                                  <i className="fas fa-upload"></i>
                                  <p>B2C </p>
                                </span>
                              </li>
                            </ul>
                          </li>

                          <li
                            className={
                              activelink === "venderprofile"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="31"
                          >
                            <span
                              onClick={(e) => collapseActive(46)}
                              className="nav-link"
                            >
                              <i class="fa-solid fa-tags"></i>
                              <p>Customise Label</p>
                            </span>
                          </li>
                          <li
                            className={
                              activelink === "venderprofile"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="31"
                          >
                            <span
                              onClick={(e) => collapseActive(47)}
                              className="nav-link"
                            >
                              {/* <i className="fas fa-weight"></i> */}
                              <i class="fa-solid fa-scale-balanced"></i>
                              <p>Weight Discrepancy</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "mypayments"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="22"
                          >
                            <span
                              onClick={(e) => collapseActive(22)}
                              className="nav-link"
                            >
                              <i className="fas fa-money-check"></i>
                              <p>Payments</p>
                            </span>
                          </li>
                          <li
                            className={
                              activelink === "invoices"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="15"
                          >
                            <span
                              onClick={(e) => collapseActive(15)}
                              className="nav-link"
                            >
                              <i className="fas fa-receipt"></i>
                              <p>Invoices</p>
                            </span>
                          </li>

                      

                          <li
                            className={
                              activelink === "setting"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="14"
                          >
                            <span
                              onClick={(e) => collapseActive(14)}
                              className="nav-link"
                            >
                              <i className="fas fa-cog "></i>
                              <p>Settings</p>
                            </span>
                          </li>
                          <li
                            className={
                              activelink === "viewrate"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="7"
                          >
                            <div
                              onClick={(e) => collapseActive(7)}
                              className="nav-link book-order-ui"
                            >
                              {/* <i className="fas fa-box-open"></i> */}
                              <h6>Book Your Order Now!</h6>
                              <img src="images/img-2.svg" alt="" />
                            </div>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside >
    </div >
  );
};

export default Sidebar;
