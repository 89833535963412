import React, { useState, useEffect } from "react";
import logo from "../logo.png";
import qr from "../qr.png";
import { CurrentUrl } from "../../src/Services/UrlApi";
import { toast } from "react-toastify";
import axios from "axios";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const CustomiseLabel = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [templateSize, setTemplateSize] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetTemplateStatus();
  }, []);
  // const handleSelect = (template) => {
  //   setTemplateSize('');
  //   setSelectedTemplate(template);
  // };
  const handleSubmitTemplate = (selectedTemplate, templeteSize) => {
    setLoading(true);
    let body = {
      IsDefaultTemplate: selectedTemplate,
      Email: localStorage.getItem("user_email"),
      TemplateSize: templeteSize,
    };
    setSelectedTemplate(selectedTemplate);
    setTemplateSize(templeteSize);
    UpdateTemplateStatus(body).then((res) => {
      if (res.data.code === 200) {
        setSelectedTemplate(selectedTemplate);
        setTemplateSize(templeteSize);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    });
  };
  const UpdateTemplateStatus = async (Data) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .post(`${CurrentUrl}User/UpdateTemplate`, JSON.stringify(Data), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };
  const GetTemplateStatus = async () => {
    const token = localStorage.getItem("Logkey");
    const userId = localStorage.getItem("user_id");
    return await axios
      .get(`${CurrentUrl}User/Getcurrentuserdetail?Id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setSelectedTemplate(
          response.data.isDefaultTemplate == null
            ? 0
            : response.data.isDefaultTemplate
        );
        setTemplateSize(response.data.templateSize);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };
  const handleSelectChange = (event) => {
    setTemplateSize(event.target.value);
  };
  const handleSwitchToggle = (value, type) => {
    setSelectedTemplate(!selectedTemplate);
    if (value) {
      console.log(`selectedTemplate000`, value + " " + type);
      setSelectedTemplate(type);
      handleSubmitTemplate(type, "A4");

    }
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Customise Label</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Customise Label</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {loading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading...</p>
            </div>
          ) :
            <div className="row">
              <div className="col-sm-3 mb-3">
                <div
                  className={`card t-card ${selectedTemplate === 0 ? "active" : ""
                    }`}
                  style={{}}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h4 className="mt-0">Default Template</h4>
                      <div class="dropdown ml-auto select-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="select-d" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="size">{selectedTemplate === 0 ? templateSize : ""}</span> <MoreVertIcon />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="select-d">
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(0, "A3")}>A3</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(0, "A4")}>A4</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(0, "A5")}>A5</a>
                        </div>
                      </div>
                      {/* <select
                  className="form-select"
                  value={templateSize}
                  onChange={handleSelectChange}
                >
                  <option value="">Select</option>
                  <option value="A3">A3</option>
                  <option value="A4">A4</option>
                  <option value="A5">A5</option>
                </select> */}
                    </div>
                  </div>
                  <div className="card-footer">
                    <FormControlLabel control={<Switch color="warning" checked={selectedTemplate === 0}
                      onChange={() => handleSwitchToggle(true, 0)} />} label={selectedTemplate === 0 ? 'On' : 'Off'} />
                    {/* <button
                    className="btn btn-red"
                    data-toggle="modal"
                    data-target="#selectSizeModal"
                    onClick={() => handleSelect(0)}
                  >
                    Select
                  </button> */}
                    <IconButton className="ml-auto" data-toggle="modal"
                      data-target="#previewDefaultModal">
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 mb-3">
                <div
                  className={`card t-card ${selectedTemplate === 1 ? "active" : ""
                    }`}
                  style={{}}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h4 className="mt-0">Template A</h4>
                      <div class="dropdown ml-auto select-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="select-d" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="size">{selectedTemplate === 1 ? templateSize : ""}</span> <MoreVertIcon />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="select-d">
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(1, "A3")}>A3</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(1, "A4")}>A4</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(1, "A5")}>A5</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    {/* <button
                    className="btn btn-red"
                    data-toggle="modal"
                    data-target="#selectSizeModal"
                    onClick={() => handleSelect(1)}
                  >
                    Select
                  </button> */}

                    <FormControlLabel control={<Switch color="warning" checked={selectedTemplate === 1}
                      onChange={() => handleSwitchToggle(true, 1)} />} label={selectedTemplate === 1 ? 'On' : 'Off'} />

                    <IconButton className="ml-auto" data-toggle="modal"
                      data-target="#previewAModal">
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="col-sm-3 mb-3">
                <div
                  className={`card t-card ${selectedTemplate === 2 ? "active" : ""
                    }`}
                  style={{}}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h4 className="mt-0">Template B</h4>
                      <div class="dropdown ml-auto select-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="select-d" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="size">{selectedTemplate === 2 ? templateSize : ""}</span> <MoreVertIcon />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="select-d">
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(2, "A3")}>A3</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(2, "A4")}>A4</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(2, "A5")}>A5</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    {/* <button
                    className="btn btn-red"
                    data-toggle="modal"
                    data-target="#selectSizeModal"
                    onClick={() => handleSelect(2)}
                  >
                    Select
                  </button> */}
                    <FormControlLabel control={<Switch color="warning" checked={selectedTemplate === 2}
                      onChange={() => handleSwitchToggle(true, 2)} />} label={selectedTemplate === 2 ? 'On' : 'Off'} />
                    <IconButton className="ml-auto" data-toggle="modal"
                      data-target="#previewBModal">
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="col-sm-3 mb-3">
                <div
                  className={`card t-card ${selectedTemplate === 3 ? "active" : ""
                    }`}
                  style={{}}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h4 className="mt-0">Template C</h4>
                      <div class="dropdown ml-auto select-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="select-d" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="size">{selectedTemplate === 3 ? templateSize : ""}</span> <MoreVertIcon />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="select-d">
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(3, "A3")}>A3</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(3, "A4")}>A4</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(3, "A5")}>A5</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    {/* <button
                    className="btn btn-red"
                    data-toggle="modal"
                    data-target="#selectSizeModal"
                    onClick={() => handleSelect(3)}
                  >
                    Select
                  </button> */}
                    <FormControlLabel control={<Switch color="warning" checked={selectedTemplate === 3}
                      onChange={() => handleSwitchToggle(true, 3)} />} label={selectedTemplate === 3 ? 'On' : 'Off'} />
                    <IconButton className="ml-auto" data-toggle="modal"
                      data-target="#previewCModal">
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="col-sm-3 mb-3">
                <div
                  className={`card t-card ${selectedTemplate === 4 ? "active" : ""
                    }`}
                  style={{}}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h4 className="mt-0">Template D</h4>
                      <div class="dropdown ml-auto select-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="select-d" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="size">{selectedTemplate === 4 ? templateSize : ""}</span> <MoreVertIcon />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="select-d">
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(4, "A3")}>A3</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(4, "A4")}>A4</a>
                          <a class="dropdown-item" onClick={() => handleSubmitTemplate(4, "A5")}>A5</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    {/* <button
                    className="btn btn-red"
                    data-toggle="modal"
                    data-target="#selectSizeModal"
                    onClick={() => handleSelect(4)}
                  >
                    Select
                  </button> */}
                    <FormControlLabel control={<Switch color="warning" checked={selectedTemplate === 4}
                      onChange={() => handleSwitchToggle(true, 4)} />} label={selectedTemplate === 4 ? 'On' : 'Off'} />
                    <IconButton className="ml-auto" data-toggle="modal"
                      data-target="#previewDModal">
                      <VisibilityIcon />
                    </IconButton>

                  </div>
                </div>
              </div>
            </div>
          }</div>

        <div
          className="modal fade UIModal modal-a3 modal-a4 modal-a5"
          id="previewAModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewAModalLabel"
          aria-hidden="true"
        >
          <div
            // className="modal-dialog modal-dialog-centered modal-xl"
            className={`modal-dialog modal-dialog-centered ${templateSize === "A3"
              ? "modal-xl"
              : templateSize === "A4"
                ? "modal-lg"
                : templateSize === "A5"
                  ? "" // You can set any specific class for A5 here
                  : "modal-lg" // Default class if templateSize is not A3, A4, or A5
              }`}
            role="document"
          >
            <div class="modal-content p-3">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>

              <div class="modal-body">
                <div className="table-pdf">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr style={{ verticalAlign: "middle" }}>
                        <td style={{ textAlign: "center" }}>
                          <img src={logo} width="80px" alt="Logo" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderTop: "1px solid #ddd",
                      borderSpacing: "10px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ fontSize: "13px" }}>
                          {/* Box: <b>12/03/2024</b>
                          <br /> */}
                          Master No: <b>MV-8956</b>
                          <br />
                          Docket No: <b>8975465321321</b>
                          <br />
                          Box: <b>1/2</b>
                          <br />
                          Child No: <b>MV-8956-1</b>
                        </td>
                        <td style={{ fontSize: "13px" }}>
                          {/* Customer Id: <b>#5686</b> */}
                          {/* <br />
                          Dispatch Date: <b>12/03/2024</b>
                          <br />
                          Delivery Methods: <b>By Air</b> */}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <img src={qr} width="80px" alt="QR Code" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderTop: "1px solid #ddd",
                      borderSpacing: "0px 10px",
                    }}
                  >
                    <tbody style={{ verticalAlign: "top" }}>
                      <tr>
                        <td style={{ fontSize: "13px", width: "25%" }}>
                          <table
                            style={{ width: "100%", borderSpacing: "10px" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "10px",
                                  }}
                                >
                                  <b>Pickup address</b>
                                  <p style={{ margin: "0" }}>
                                    Mvikas Super Logistics Private Limited, 11 G
                                    Big Jos Tower Netaji Subhash Place New Delhi
                                    - 110034
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "10px",
                                  }}
                                >
                                  <b>Delivery address</b>
                                  <p style={{ margin: "0" }}>
                                    Mvikas Super Logistics Private Limited, 11 G
                                    Big Jos Tower Netaji Subhash Place New Delhi
                                    - 110034
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "10px",
                                  }}
                                >
                                  <b>Return address</b>
                                  <p style={{ margin: "0" }}>
                                    Mvikas Super Logistics Private Limited, 11 G
                                    Big Jos Tower Netaji Subhash Place New Delhi
                                    - 110034
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style={{ fontSize: "13px", width: "75%" }}>
                          <table
                            style={{ width: "100%", borderSpacing: "0px 10px" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    background: "#ffd7b4",
                                    fontSize: "13px",
                                    padding: "5px",
                                  }}
                                >
                                  Item
                                </th>
                                <th
                                  style={{
                                    background: "#ffd7b4",
                                    fontSize: "13px",
                                    padding: "5px",
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  style={{
                                    background: "#ffd7b4",
                                    fontSize: "13px",
                                    padding: "5px",
                                  }}
                                >
                                  Ordered
                                </th>
                                <th
                                  style={{
                                    background: "#ffd7b4",
                                    fontSize: "13px",
                                    padding: "5px",
                                  }}
                                >
                                  Delivered
                                </th>
                                <th
                                  style={{
                                    background: "#ffd7b4",
                                    fontSize: "13px",
                                    padding: "5px",
                                  }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Item
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Description
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Ordered
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Delivered
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Amount
                                </td>
                              </tr>

                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="3"></td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#ffd7b4",
                                    padding: "5px",
                                  }}
                                >
                                  <b>Sub Total</b>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <b>$0.00</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3"></td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#ffd7b4",
                                    padding: "5px",
                                  }}
                                >
                                  <b>Tax 5%</b>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <b>$0.00</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3"></td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#ffd7b4",
                                    padding: "5px",
                                  }}
                                >
                                  <b>Total</b>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <b>$0.00</b>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: "100%", fontSize: "13px" }}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Terms and Conditions</b>
                          <br />
                          <p>a. Consignee is responsible  for illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Thank you for your business!</b>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade  UIModal modal-a3 modal-a4 modal-a5"
          id="previewBModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewBModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${templateSize === "A3"
              ? "modal-xl"
              : templateSize === "A4"
                ? "modal-lg"
                : templateSize === "A5"
                  ? "" // You can set any specific class for A5 here
                  : "modal-lg" // Default class if templateSize is not A3, A4, or A5
              }`}
            role="document"
          >
            <div class="modal-content  p-3">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>

              <div class="modal-body">
                <div className="table-pdf">
                  <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                    <tbody>
                      <tr style={{ verticalAlign: "middle" }}>
                        <td>
                          <img src={logo} width="80px" alt="Logo" />
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <img src={qr} width="80px" alt="QR Code" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                    <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                      <tr>
                        <td>
                          <table
                            style={{ width: "100%", border: "1px solid #ddd" }}
                          >
                            <tbody>
                              {/* <tr>
                                <td>Customer No:</td>
                                <td>
                                  <b></b>
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping Date:</td>
                                <td>
                                  <b></b>
                                </td>
                              </tr> */}
                              <tr>
                                <td>Docket No:</td>
                                <td>
                                  <b>5465479864654</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Child No:</td>
                                <td>
                                  <b>MV-4670-1</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Box:</td>
                                <td>
                                  <b>1/2</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table style={{ width: "100%", textAlign: "right" }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: "5px" }}>Master No: </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "150px",
                                    padding: "5px",
                                  }}
                                >
                                  <b>MV-4670</b>
                                </td>
                              </tr>
                              {/* <tr>
                                <td style={{ padding: "5px" }}>Order Date:</td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "5px",
                                  }}
                                >
                                  <b>12/05/2025</b>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                    <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ddd",
                              borderSpacing: "0",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    background: "#ffd7b4",
                                    padding: "5px",
                                  }}
                                >
                                   Delivery Address Details
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px" }}>Name:</td>
                                <td style={{ padding: "5px" }}>
                                  <b></b>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px" }}>Address:</td>
                                <td style={{ padding: "5px" }}>
                                  <b></b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style={{ width: "50%" }}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ddd",
                              borderSpacing: "0",
                              marginLeft: "auto",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    background: "#ffd7b4",
                                    padding: "5px",
                                  }}
                                >
                                 Pickup Address Details
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px" }}>Name:</td>
                                <td style={{ padding: "5px" }}>
                                  <b></b>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px" }}>Address:</td>
                                <td style={{ padding: "5px" }}>
                                  <b></b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Item
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Item Description
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Quantity Ordered
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(1)
                        .fill()
                        .map((_, i) => (
                          <tr key={i}>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              -
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              -
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              -
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              -
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              -
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            padding: "5px",
                          }}
                        >
                          <b>Sub Total</b>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <b>$0.00</b>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            padding: "5px",
                          }}
                        >
                          <b>Tax 5%</b>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <b>$0.00</b>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          style={{
                            textAlign: "center",
                            background: "#ffd7b4",
                            padding: "5px",
                          }}
                        >
                          <b>Total</b>
                        </td>
                        <td
                          style={{ textAlign: "center", background: "#ffd7b4" }}
                        >
                          <b>$0.00</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <table style={{ width: "50%", fontSize: "13px" }}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Terms and Conditions</b>
                          <br />
                          <p>a. Consignee is responsible  for illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <b>Thank You</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade UIModal modal-a3 modal-a4 modal-a5"
          id="previewCModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewCModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${templateSize === "A3"
              ? "modal-xl"
              : templateSize === "A4"
                ? "modal-lg"
                : templateSize === "A5"
                  ? "" // You can set any specific class for A5 here
                  : "modal-lg" // Default class if templateSize is not A3, A4, or A5
              }`}
            role="document"
          >
            <div class="modal-content p-3">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>

              <div class="modal-body">
                <div className="table-pdf">
                  <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                    <tbody>
                      <tr style={{ verticalAlign: "middle" }}>
                        <td>
                          <img src={logo} width="80px" alt="logo" />
                        </td>
                        <td style={{ fontSize: "13px", textAlign: "center" }}>
                          {/* <b>Order ID:</b> #895689 */}
                          <br />
                          <b>Docket No.</b> 895692345
                          <br />
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <img src={qr} width="80px" alt="QR code" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      marginTop: "25px",
                    }}
                  >
                    <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                      <tr>
                        <td>
                          <b>Master No:</b>
                        </td>
                        <td>MV-4670</td>
                      </tr>
                      {/* <tr>
                        <td>
                          <b>Requested Date:</b>
                        </td>
                        <td></td>
                      </tr> */}
                      <tr>
                        <td>
                          <b> Pickup Address:</b>
                        </td>
                        <td>123, Parliament Street, Delhi - 110095 </td>
                      </tr>
                      <tr>
                        <td>
                          <b> Delivery Address:</b>
                        </td>
                        <td>456, Bombay House, Mumbai - 400001</td>
                      </tr>
                      <tr>
                      <td>
                        <b> Boxes:</b>
                      </td>
                      <td>1/2</td>
                    </tr>
                      {/* <tr>
                        <td>
                          <b>Email:</b>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <b>Date:</b>
                        </td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Quantity
                        </th>
                        
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            background: "#ffd7b4",
                            fontSize: "13px",
                            padding: "5px",
                          }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(1)].map((_, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            -
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                            padding: "5px",
                          }}
                        >
                          <b>Sub Total</b>
                        </td>
                        <td
                          colSpan="2"
                          style={{ borderBottom: "1px solid #ddd" }}
                        ></td>
                        <td
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <b>$0.00</b>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                            padding: "5px",
                          }}
                        >
                          <b>Tax 5%</b>
                        </td>
                        <td
                          colSpan="2"
                          style={{ borderBottom: "1px solid #ddd" }}
                        ></td>
                        <td
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <b>$0.00</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", padding: "5px" }}>
                          <b>Total</b>
                        </td>
                        <td colSpan="2"></td>
                        <td style={{ textAlign: "center" }}>
                          <b>$0.00</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      fontSize: "13px",
                      marginTop: "35px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>Terms and Conditions</b>
                          <br />
                          <ol
                            style={{ paddingLeft: "15px", lineHeight: "25px" }}
                          >
                           <li>
                          Consignee is responsible  for illegal goods
                          </li>
                          <li>
                          Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.
                          </li>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade UIModal modal-a3 modal-a4 modal-a5"
          id="previewDModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewDModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${templateSize === "A3"
              ? "modal-xl"
              : templateSize === "A4"
                ? "modal-lg"
                : templateSize === "A5"
                  ? "" // You can set any specific class for A5 here
                  : "modal-lg" // Default class if templateSize is not A3, A4, or A5
              }`}
            role="document"
          >
            <div class="modal-content p-3">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>
              <div class="modal-body">
                <div className="table-pdf">
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "20px 10px",
                      background: "#ddd",
                    }}
                  >
                    <tbody>
                      <tr style={{ verticalAlign: "middle" }}>
                        <td>
                          <img src={logo} width="80px" alt="Logo" />
                        </td>
                        <td style={{ fontSize: "13px", textAlign: "center" }}>
                          <b>Master No:</b> 895689
                          <br />
                          <b>Docket No.</b> 895692345
                          <br />
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <img
                            src={qr}
                            width="80px"
                            style={{ background: "#FFF", padding: "5px" }}
                            alt="QR Code"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      marginTop: "25px",
                    }}
                  >
                    <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                      {/* <tr>
                        <td
                          style={{
                            textTransform: "uppercase",
                            fontSize: "18px",
                          }}
                        >
                          <b>purchase order</b>
                        </td>
                        <td></td>
                      </tr> */}
                      {/* <tr>
                        <td>
                          <b>Order Date:</b>
                        </td>
                        <td></td>
                      </tr> */}
                      <tr>
                        <td>
                          <b>Item No:</b>
                        </td>
                        <td>MV-467-1</td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                      border: "1px solid #000",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderBottom: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Pickup Information</b>
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Shipping Information</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px" }}>
                          <b>Vendor Name:</b>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderLeft: "1px solid #000",
                          }}
                        >
                          <b>Vendor Name:</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px" }}>
                          <b>Address:</b>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderLeft: "1px solid #000",
                          }}
                        >
                          <b>Address:</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px" }}>
                          <b>Phone:</b>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderLeft: "1px solid #000",
                          }}
                        >
                          <b>Phone:</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Item Code
                        </th>
                        {/* <th
                          style={{
                            borderBottom: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          TyPE
                        </th> */}
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Unit Price
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(1)].map((_, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                          {/* <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            -
                          </td> */}
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                              borderLeft: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                              borderLeft: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                              borderLeft: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                              borderLeft: "1px solid #000",
                            }}
                          >
                            -
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: "5px 0" }}>
                          <b>Total Net:</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 0" }}>
                          <b>Tax:</b>
                        </td>
                      </tr>
                      {/* <tr>
                        <td style={{ padding: "5px 0" }}>
                          <b>Delivery:</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 0" }}>
                          <b>Other:</b>
                        </td>
                      </tr> */}
                      <tr style={{ textAlign: "right" }}>
                        <td style={{ padding: "5px 0" }}>
                          <b>Total:</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Terms and Conditions</b>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #000",
                            borderTop: "0",
                            padding: "10px",
                            textTransform: "uppercase",
                          }}
                        >
                         <p>a. Consignee is responsible  for  illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* <table
                    style={{
                      width: "100%",
                      borderSpacing: "0px",
                      fontSize: "13px",
                      marginTop: "25px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "10px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                          }}
                        >
                          <b>Notes:</b>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div
          className="modal fade UIModal modal-a3 modal-a4 modal-a5"
          id="previewDefaultModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewDefaultModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${templateSize === "A3"
              ? "modal-xl"
              : templateSize === "A4"
                ? "modal-lg"
                : templateSize === "A5"
                  ? "" // You can set any specific class for A5 here
                  : "modal-lg" // Default class if templateSize is not A3, A4, or A5
              }`}
            role="document"
          >
            <div class="modal-content p-3">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>

              <div class="modal-body">
                <div className="table-pdf">
                  <div
                    className="page"
                    style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  >
                    <table
                      style={{
                        width: "100%",
                        border: "2px solid #000",
                        borderSpacing: "0px",
                        fontFamily: "DM Sans, Arial, Helvetica, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      <tbody>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            style={{
                              borderRight: "0px solid #000",
                              padding: "10px",
                              width: "30%",
                              textAlign: "left",
                            }}
                          >
                            <img
                              src="images/logo-pdf.png"
                              style={{ height: "50px" }}
                            />
                          </td>

                          <td
                            style={{
                              padding: "10px",
                              width: "50%",
                              textAlign: "right",
                              color: "#333",
                            }}
                          >
                            <img
                              src={logo}
                              alt="LP"
                              style={{ height: "50px" }}
                            />{" "}
                            <br />
                            <b>Order ID</b>:
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            colSpan={2}
                            style={{
                              border: "2px solid #000",
                              borderLeft: "0px",
                              padding: "10px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              lineHeight: "22px",
                            }}
                          >
                            <b style={{ fontSize: "14px" }}>Master:</b> <br />
                            <b style={{ fontSize: "14px" }}>
                              Docket Number:
                            </b>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            style={{ padding: "10px", textAlign: "center" }}
                          >
                            <img src={qr} alt="LP" style={{ height: "50px" }} />
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            style={{
                              borderTop: "2px solid #000",
                              padding: "0",
                            }}
                          >
                            <table
                              style={{ width: "100%", borderSpacing: "0px" }}
                            >
                              <tr style={{ verticalAlign: "top" }}>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    fontSize: "16px",
                                  }}
                                >
                                  <b>Box: </b>
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Child Number</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Length</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Breadth</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "0px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Height</b>
                                  <br />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            style={{
                              borderTop: "2px solid #000",
                              padding: "0",
                            }}
                          >
                            <table
                              style={{ width: "100%", borderSpacing: "0px" }}
                            >
                              <tr style={{ verticalAlign: "top" }}>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Item Name</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Item Type</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Item Category</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "2px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Item Subcategory</b>
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderRight: "0px solid #000",
                                    color: "#333",
                                  }}
                                >
                                  <b>Packaging Type</b>
                                  <br />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            colSpan="2"
                            style={{
                              borderTop: "2px solid #000",
                              padding: "10px",
                              color: "#333",
                            }}
                          >
                            <b>Pickup address:</b>
                            <br />
                          </td>
                        </tr>

                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            colSpan="2"
                            style={{
                              borderTop: "2px solid #000",
                              padding: "10px",
                              color: "#333",
                            }}
                          >
                            <b>Delivery address:</b>
                            <br />
                          </td>
                        </tr>

                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            colSpan="2"
                            style={{
                              borderTop: "2px solid #000",
                              padding: "10px",
                              color: "#333",
                            }}
                          >
                            <b>Return address:</b>
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade UIModal"
          id="selectSizeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="selectSizeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div className="d-flex">
                <IconButton aria-label="CloseIcon" className="close ml-auto" data-dismiss="modal">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div class="modal-body">
              <select
                className="form-select"
                value={templateSize}
                onChange={handleSelectChange}
              >
                <option value="">Select</option>
                <option value="A3">A3</option>
                <option value="A4">A4</option>
                <option value="A5">A5</option>
              </select>
              <button data-dismiss="modal" class="btn btn-red mt-3" onClick={handleSubmitTemplate}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomiseLabel;
