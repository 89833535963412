import React, {  useState } from "react";
import Card from '@mui/material/Card';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const labels = {
  0.5: '0.5',
  1: '1',
  1.5: '1.2',
  2: '2',
  2.5: '2.5',
  3: '3',
  3.5: '3.5',
  4: '4',
  4.5: '4.5',
  5: '5',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
const SubmitReview = () => {
  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState("./images/logo.svg");

  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  const [selectedOption, setSelectedOption] = React.useState('option0'); // set initial value to 'option1'

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [ratingopen, setratingOpen] = React.useState(false);

  const ratingClickOpen = () => {
    setratingOpen(true);
  };

  const ratingClose = () => {
    setratingOpen(false);
  };

  return (
    <>
      <section className="content" style={{padding: "10px 0px"}}>
        <div className="container-fluid">
              <Card className='custum-shadow py-3 px-3 review-form-ui'>
                <div className="logo-box">
                  <img src={navbarLogo} className="img-fluid logo-img" alt="" />
                  </div>
                <div className='order-summery'>
                  <div className='serviceby'>
                    <img src="http://testing.ivikas.in//UserImage/65d1180d-d045-4482-a7aa-3830e4b3c71d_istockphoto-1284419710-612x612.jpg" alt="" />
                    <div className='servicename'>
                      <h4>Lorrigo Commercial</h4>
                      <div className="rating">
                        <span className="rate-text">4.7</span>
                        <Rating
                          name="text-feedback"
                          value={4.5}
                          readOnly
                          precision={0.5}
                          emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                        />
                        <span className="text" onClick={ratingClickOpen}>17 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='order-information'>
                    <table>
                      <tbody>
                        <tr>
                          <td>Order No.</td>
                          <td>MSL-115</td>
                        </tr>
                        <tr>
                          <td>Inserted Date & Time.</td>
                          <td>2023-04-06</td>
                        </tr>
                        <tr>
                          <td>From Pin</td>
                          <td>201001</td>
                        </tr>
                        <tr>
                          <td>To Pin</td>
                          <td>201009</td>
                        </tr>
                        <tr>
                          <td>Taxable Amount</td>
                          <td>660</td>
                        </tr>
                        <tr>
                          <td>GST Amount</td>
                          <td>118.8</td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>778.8</td>
                        </tr>
                        <tr>
                          <td>Order Status</td>
                          <td>Delivered</td>
                        </tr>
                        <tr>
                          <td>ETA</td>
                          <td>Apr 08, 2023</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='delivery-time'>
                  <AvTimerIcon />
                  <div className='text'>
                    <p>Order deliver earlier than the estimated Date.</p>
                    <h4>Apr 07, 2023</h4>
                  </div>
                </div>
                <div className='delivery-time delivery-late mt-3'>
                  <AvTimerIcon />
                  <div className='text'>
                    <p>Order not received by the estimated delivery Date.</p>
                    <h4>Apr 09, 2023</h4>
                  </div>
                </div>

                <div className='rating-side mt-3'>
                  <div className='head'>
                    <h3>
                      Describe Your Experience!
                    </h3>
                    <p>Rate your purchases and tell us what you thought about our service.</p>
                  </div>
                  <div className='rating-box'>
                    <Rating
                      name="hover-feedback"
                      value={value}
                      // For half star add 0.5 in precision={1}
                      precision={1}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {value !== null && (
                      <span className='count-review' sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</span>
                    )}
                  </div>

                  <FormControl fullWidth className='mt-3'>
                    <InputLabel shrink id="demo-simple-select-label">Review Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Review Type"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="option0" disabled>Please select review type</MenuItem>
                      <MenuItem value='option3'>Pickup</MenuItem>
                      <MenuItem value="option2">Service</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    className='mt-4'
                    id="outlined-textarea"
                    label="Describe your experience"
                    placeholder="Describe your experience"
                    multiline
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button variant="contained" className='custum-btn mt-3'>Submit</Button>
                </div>

                <p className="copyright-p">Copyright © 2023 MVIKAS Super Logistics. All rights reserved.</p>
              </Card>
        </div>
      </section>

      {/* View Rating modal */}
      < Dialog
        fullWidth={true} maxWidth="md"
        open={ratingopen}
        onClose={ratingClose}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="rating-header">
            <div className="total-rating">
              4.7
            </div>
            <div className="">
              {"Lorrigo Commercial"}
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={4.5}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                />
                <span className="text">17 Reviews</span>
              </div>
            </div>
            <div className="ml-auto">
              <FormControl fullWidth>
                <InputLabel id="Sort by-label">Sort by</InputLabel>
                <Select
                  labelId="Sort by-label"
                  id="Sort by"
                  value={10}
                  label="Sort by"
                  style={{ minWidth: '200px' }}
                >
                  <MenuItem value={10}>All</MenuItem>
                  <MenuItem value={20}>Newest</MenuItem>
                  <MenuItem value={30}>Highest</MenuItem>
                  <MenuItem value={30}>Lowest</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>

          <div className="user-review">
            <div className="user-icon">
              U
            </div>
            <div className="text">
              <h5>Uma Singh</h5>
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={4.5}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                />
                <span className="text" onClick={ratingClickOpen}>1 week ago</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt nibh at metus euismod, a tincidunt eros dictum. Ut finibus tellus neque, ac vehicula quam gravida id. Suspendisse potenti.
              </p>
            </div>
          </div>

          <div className="user-review">
            <div className="user-icon">
              S
            </div>
            <div className="text">
              <h5>Sonu Sharma</h5>
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={4.5}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                />
                <span className="text" onClick={ratingClickOpen}>2 days ago</span>
              </div>
              <p>
                Aenean tincidunt nibh at metus euismod, a tincidunt eros dictum. Ut finibus tellus neque, ac vehicula quam gravida id. Suspendisse potenti.
              </p>
            </div>
          </div>

          <div className="user-review">
            <div className="user-icon">
              M
            </div>
            <div className="text">
              <h5>Manish Pandey</h5>
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={3.5}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                />
                <span className="text" onClick={ratingClickOpen}>2 month ago</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt nibh at metus euismod, a tincidunt eros dictum.
              </p>
            </div>
          </div>

          <div className="user-review">
            <div className="user-icon">
              A
            </div>
            <div className="text">
              <h5>Ajay Rai</h5>
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={4}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
                />
                <span className="text" onClick={ratingClickOpen}>4 month ago</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>

        </DialogContent>

        <DialogActions>
          <Button onClick={ratingClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default SubmitReview;
