/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import { FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState, useEffect, useCallback } from "react";
import { GetRates, Getlpdatabylpid } from "../Services/RateApi";
import {
  Getformula,
  Getratesettings,
  GetVendorRules,
} from "../Services/UserApi";
import Loading from "./Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SaveOrderdata } from "../Services/OrderApi";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Chip } from '@mui/material';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Autocomplete,
  TextField,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material/";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableFooter } from "@material-ui/core";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CurrentUrl } from "../Services/UrlApi";
import { GetServiceType, CheckIsReviewed } from "../Services/UserApi";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import RateReviewRoundedIcon from "@mui/icons-material/RateReviewRounded";
import { GetReviewDetails } from "../../src/Services/UserApi";
import { useParams } from "react-router-dom";
function createData(charges, amount) {
  return { charges, amount };
}
// let Dialogheader = "";
// let Dialogamount = "";

function createsortedData(sortedcharges, sortedamount) {
  return { sortedcharges, sortedamount };
}
let filteredDialogheader = "";
let filteredDialogamount = "";

let rows = [
  createData("Rate", 8.45),
  createData("Chargeable Weight", 50),
  createData("ODA", 0),
  createData("Base Freight", 0),
  createData("Docket Charge", 100),
  createData("FOV Charges ", 100),
  createData("FSC Percentage", 124.5),
  createData("FSC Amount", 124.5),
  createData("Green Tax", 0),
  createData("Topay Charges", 100),
  createData("Handling Charges", 0),
  createData("Taxable Amount", 747),
  createData("GST Amount (18%)", 134.46),
];
let Initializerows = [
  createData("Rate", 8.45),
  createData("Chargeable Weight", 50),
  createData("ODA", 0),
  createData("Base Freight", 0),
  createData("Docket Charge", 100),
  createData("FOV Charges ", 100),
  createData("FSC Percentage", 124.5),
  createData("FSC Amount", 124.5),
  createData("Green Tax", 0),
  createData("Topay Charges", 100),
  createData("Handling Charges", 0),
  createData("Taxable Amount", 747),
  createData("GST Amount (18%)", 134.46),
];
const sortedrows = [
  createsortedData("Rate", 8.45),
  createsortedData("Chargeable Weight", 50),
  createsortedData("ODA", 0),
  createsortedData("Base Freight", 0),
  createsortedData("Docket Charge", 100),
  createsortedData("FOV Charges ", 100),
  createsortedData("FSC Percentage", 124.5),
  createsortedData("FSC Amount", 124.5),
  createsortedData("Green Tax", 0),
  createsortedData("Topay Charges", 100),

  createsortedData("Handling Charges", 0),
  createsortedData("Taxable Amount", 747),
  createsortedData("GST Amount (18%)", 134.46),
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ViewLowestrate = () => {
  const [searchDataTriggered, setSearchDataTriggered] = useState(false);
  const [chipLabel, setChipLabel] = useState('');
  const [rowdata, setRowData] = useState([]);
  const [Dialogheader, setDialogHeader] = useState("");
  const [Dialogamount, setDialogAmount] = useState("");
  let i = 0;
  const units = ["CM", "INCH", "FEET"];
  const [CFT, setCFT] = useState();
  const [Divisor, setDivisor] = useState();
  const role = localStorage.getItem("user_role").split(",");
  const [vendorrules, setvendorrules] = useState([]);
  const [ratedata, setRateData] = useState({
    frompin: "",
    topin: "",
    pieces: "",
    cod: false,
    shipmentvalue: "",
    totalweight: "",
    shipmentweight: "",
    deliveryaddress: 0,
    pickupaddress: 0,
    returnaddress: 0,
    itemname: "",
    pickuptime: "",
    paymentmode: "",
    weightinkg: 0
  });
  const [dimensions, setDimensions] = useState([
    {
      Lenght: "",
      breadth: "",
      height: "",
      boxes: "",
      totalweight: "",
      grossweight: "",
      units: "CM",
      unit: 'kg',
      actualWeight: ""

    },
  ]);
  const [dimensionsdata, setDimensionsData] = useState([
    {
      length: 0,
      height: 0,
      breadth: 0,
      boxes: 0,
      volume_weight: 0,
      units: "CM",
      actualWeight: 0
    },
  ]);
  const [indxvalue, setindxValue] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [lpdata, setLpData] = useState("");
  const [filteredlpdata, setfilteredlpdata] = useState("");
  const [lpdetails, setLpDetails] = useState("");
  const [dataId, setDataId] = useState("");
  const [address, setAddress] = useState("");
  const [vendorsetting, setVendorsetting] = useState({
    pickuptime: "",
    paymentmode: "",
  });
  const [tooltipmess, setTooltipmess] = useState("");
  const [servicetype, setservicetype] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState(0);
  const [lastorderopen, orderOpen] = useState(true);
  const [reviewopen, setReviewOpen] = useState(false);
  const [isorderreeviewed, setisorderreeviewed] = useState([]);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [isKYCApprovedfirst, setisKYCApprovedfirst] = useState(0)
  const mycustomerid = localStorage.getItem("user_id");
  const [topay, setTopay] = useState(false);
  const [showb2c, setshowb2c] = useState(false)
  const reviewClickOpen = () => {
    setReviewOpen(true);
  };
  const reviewClose = () => {
    setReviewOpen(false);
  };
  const handleSwitchChange = (event) => {
    setshowb2c(event.target.value === 'b2c');
  };
  const { viewType } = useParams();

  useEffect(() => {
    console.log(`viewType`, viewType);
    setshowb2c(viewType==1?false:true);
    const { frompin, topin, shipmentvalue, shipmentweight } = ratedata;
    if (viewType===1) {
      if (frompin === "" || topin === "" || shipmentvalue === "" || shipmentweight === "") {
        // toast.warning("Please fill all mandatories fields");
      } else {
        setRowData([]);
      }
    }else{
      if (frompin === "" || topin === "" || shipmentvalue === "" || shipmentweight === "") {
        // toast.warning("Please fill all mandatories fields");
      } else {
        setRowData([]);
      }
    }
    CheckIsReviewed(mycustomerid)
      .then((result) => {
        setisorderreeviewed(result);
        if (result.length > 0) {
          setReviewOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [mycustomerid,viewType]);

  useEffect(() => {
    loadservicetype();
  }, []);

  const loadservicetype = () => {
    setservicetype([]);

    GetServiceType(localStorage.getItem("user_email"))
      .then((result) => {
        // console.log("my service type", result);
        setservicetype(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  useEffect(() => {
    if (role.includes("Vendor")) {
      Getformula().then((res) => {
        setCFT(res.data.cft);
        setDivisor(res.data.divisor);
      });
    }
  }, []);

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (!isNaN(value)) {
      if (name === "frompin" || name === "topin") {
        if (value.length <= 6) {
          setRateData({ ...ratedata, [name]: value });
        }
      } else setRateData({ ...ratedata, [name]: value });
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (state, id) => {
    if (state) {
      if (
        inputValue.deliveryaddress === "" ||
        inputValue.pickupaddress === "" ||
        inputValue.returnaddress === ""
      ) {
        toast.warn("Plese enter the addresses");
      } else {
        if (window.confirm("Do you really want to book?")) submitOrder(id);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRemoveClick = (index) => {
    const list = [...dimensions];
    const list2 = [...dimensionsdata];
    const removedDimension = list[index];
    const totalw =
      ratedata.totalweight - parseFloat(removedDimension.totalweight || 0);
    const grossw =
      ratedata.shipmentweight - parseFloat(removedDimension.grossweight || 0);
    list.splice(index, 1);
    list2.splice(index, 1);
    setDimensions(list);
    setDimensionsData(list2);
    setRateData({
      ...ratedata,
      totalweight: totalw,
      shipmentweight: grossw,
    });
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    const list = [...dimensions];
    if (
      list[e]["Lenght"] !== "" &&
      list[e]["breadth"] !== "" &&
      list[e]["height"] !== "" &&
      list[e]["grossweight"] !== "" &&
      list[e]["boxes"] !== ""
    ) {
      setDimensions([
        ...dimensions,
        {
          Lenght: "",
          breadth: "",
          height: "",
          boxes: "",
          totalweight: "",
          grossweight: "",
          units: "CM",
        },
      ]);
      setDimensionsData([
        ...dimensionsdata,
        { length: 0, height: 0, breadth: 0, boxes: 0, volume_weight: 0, },
      ]);
    } else toast.warning("Please enter dimensions");
  };

  const [inputValue, setInputValue] = useState({
    deliveryaddress: "",
    pickupaddress: "",
    returnaddress: "",
    pickuptime: "",
    paymentmode: "",
  });

  const getServiceTypeLabel = (type) => {
    switch (type) {
      case 1:
        return "Surface";
      case 2:
        return "Water";
      case 3:
        return "Air";
      default:
        return "Unknown";
    }
  };



  // //OLD BREAKUP POP UP
  const steps = [
    {
      label: "Order Details",
      description:
        dataId === "" ? (
          ""
        ) : indxvalue !== 3 ? (
          <>
            <div
              className="row justify-content-between"
              style={{
                borderBottom: "2px solid #dee2e6",
                paddingBottom: "7px",
              }}
            >
              {" "}
              <div
                className="col-6"
                style={{
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan">LP Details </span>
              </div>{" "}
              <div
                className="col-6 text-end"
                style={{
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan"></span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">LP Name : </span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {lpdata[dataId]["lpName"]}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Contact Person Name : </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  Chandni Chauhan
                  {/* {lpdata[dataId].contact_person_name} */}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Email :</span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  chandni.chauhan@mvikas.in
                  {/* {lpdata[dataId].email} */}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Mobile No : </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  9319952223
                  {/* {lpdata[dataId].phoneNumber} */}
                </span>
              </div>
            </div>

            <div
              className="row justify-content-between"
              style={{
                borderBottom: "2px solid #dee2e6",
                paddingBottom: "7px",
              }}
            >
              <div
                className="col-6"
                style={{
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan">Charges </span>
              </div>{" "}
              <div
                className="col-6 text-end"
                style={{
                  borderTop: "1px solid grey",
                  borderBottom: "1px solid grey",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan">(&#8377;) Amount</span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Rate : </span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">{lpdata[dataId]["rate"]}</span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Chargeable Weight :</span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {lpdata[dataId]["chargeableWt"]}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Docket Charge : </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["docketCharge"]}{" "}
                </span>
              </div>{" "}
              <div className="col-6">
                <span className="UpdateRateSpan">
                  FOV Charges {` (${lpdata[dataId]["fovCharge"]}%)`} :
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {`${lpdata[dataId]["fovCharge"]}`}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">
                  FSC Amount {` (${lpdata[dataId]["fsc"]}%)`} :
                </span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {`${lpdata[dataId]["fscAmount"]}`}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">Green Tax :</span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {`${lpdata[dataId]["greenTaxAmount"]}`}
                </span>
              </div>
              <div className="col-6">
                <span className="UpdateRateSpan">ODA : </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["oda"]}{" "}
                </span>
              </div>
              <div className="col-6 ">
                <span className="UpdateRateSpan">Handling Charges :</span>
              </div>{" "}
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["handlingCharge"]}{" "}
                </span>
              </div>
              {ratedata.cod ? (
                <>
                  {" "}
                  <div className="col-6">
                    <span className="UpdateRateSpan">COD Amount : </span>
                  </div>{" "}
                  <div className="col-6 text-end">
                    <span className="UpdateRateSpan">
                      {" "}
                      {lpdata[dataId]["codAmount"]}{" "}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="col-6">
                <span className="UpdateRateSpan">Taxable Amount : </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["taxableAmount"]}{" "}
                </span>
              </div>{" "}
              <div className="col-6">
                <span className="UpdateRateSpan">
                  GST Amount {` (${lpdata[dataId]["gst"]}%)`} :{" "}
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["gstAmount"]}{" "}
                </span>
              </div>{" "}
              <div
                className="col-6"
                style={{
                  borderTop: "2px solid black",
                  borderBottom: "2px solid black",
                  marginTop: "8px",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan">Total Amount : </span>
              </div>
              <div
                className="col-6 text-end"
                style={{
                  borderTop: "2px solid black",
                  borderBottom: "2px solid black",
                  marginTop: "8px",
                  padding: "5px",
                }}
              >
                <span className="UpdateRateSpan">
                  {" "}
                  {lpdata[dataId]["totalAmount"]}{" "}
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        ),
    },
    {
      label: (
        <div>
          <span>Address</span>
        </div>
      ),
      description: (
        <>
          <div>
            <Autocomplete
              value={inputValue.pickupaddress}
              onChange={(event, newValue) => {
                setRateData({
                  ...ratedata,
                  pickupaddress: newValue.id,
                  returnaddress: newValue.id,
                });
              }}
              inputValue={inputValue.pickupaddress}
              onInputChange={(event, newInputValue) => {
                setInputValue({
                  ...inputValue,
                  pickupaddress: newInputValue,
                  returnaddress: newInputValue,
                });
              }}
              id="controllable-states-demo"
              options={address}
              sx={{ marginBottom: "10px" }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ borderBottom: "1px solid #ebe9e9" }}
                >
                  <Box component="div">
                    {" "}
                    {option.label} |
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      &nbsp; {option.full_add}
                    </Box>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Pickup Address" />
              )}
            />
            <Autocomplete
              value={inputValue.deliveryaddress}
              onChange={(event, newValue) => {
                setRateData({
                  ...ratedata,
                  deliveryaddress: newValue.id,
                });
              }}
              inputValue={inputValue.deliveryaddress}
              onInputChange={(event, newInputValue) => {
                setInputValue({
                  ...inputValue,
                  deliveryaddress: newInputValue,
                });
              }}
              id="controllable-states-demo"
              options={address}
              sx={{ marginBottom: "10px" }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ borderBottom: "1px solid #ebe9e9" }}
                >
                  <Box component="div">
                    {" "}
                    {option.label} |
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      &nbsp; {option.full_add}
                    </Box>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Delievery Address" />
              )}
            />
            <Autocomplete
              value={inputValue.returnaddress}
              onChange={(event, newValue) => {
                setRateData({
                  ...ratedata,
                  returnaddress: newValue.id,
                });
              }}
              inputValue={inputValue.returnaddress}
              onInputChange={(event, newInputValue) => {
                setInputValue({ ...inputValue, returnaddress: newInputValue });
              }}
              id="controllable-states-demo"
              options={address}
              sx={{ marginBottom: "10px" }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ borderBottom: "1px solid #ebe9e9" }}
                >
                  <Box component="div">
                    {" "}
                    {option.label} |
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      &nbsp; {option.full_add}
                    </Box>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Return Address" />
              )}
            />{" "}
            <input
              type="text"
              placeholder="Item Type"
              value={ratedata.itemname}
              style={{ padding: "25px", marginBottom: "5px" }}
              className="form-control"
              onChange={(event) => {
                setRateData({
                  ...ratedata,
                  itemname: event.target.value,
                });
              }}
            />
            <Autocomplete
              value={inputValue.pickuptime}
              onChange={(event, newValue) => {
                setRateData({
                  ...ratedata,
                  pickuptime: newValue.value,
                });
              }}
              inputValue={inputValue.pickuptime}
              id="controllable-states-demo"
              options={vendorsetting.pickuptime}
              sx={{ marginBottom: "10px" }}
              onInputChange={(event, newInputValue) => {
                setInputValue({ ...inputValue, pickuptime: newInputValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Pickup Slot" />
              )}
            />
            <Autocomplete
              value={inputValue.paymentmode}
              onChange={(event, newValue) => {
                setRateData({
                  ...ratedata,
                  paymentmode: newValue.value,
                });
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue({ ...inputValue, paymentmode: newInputValue });
              }}
              inputValue={inputValue.paymentmode}
              id="controllable-states-demo"
              options={vendorsetting.paymentmode}
              sx={{ marginBottom: "10px" }}
              renderInput={(params) => (
                <TextField {...params} label="Payment Mode" />
              )}
            />
          </div>
        </>
      ),
    },
  ];

  const dimensionsChange = (e, indx) => {
    const { name, value } = e.target;
    const list = [...dimensions];
    const dimlist = [...dimensionsdata];
    list[indx][name] = value;
    if (!isNaN(value)) {
      if (name === "Lenght") dimlist[indx]["length"] = value;
      else dimlist[indx][name] = value;

      if (name === "grossweight") {
        let grosswt = 0;
        for (var i in list) {
          grosswt += parseFloat(list[i]["grossweight"]);
        }

        setRateData({
          ...ratedata,

          shipmentweight: grosswt,
        });
      } else if (
        list[indx]["Lenght"] !== "" &&
        list[indx]["breadth"] !== "" &&
        list[indx]["height"] !== "" &&
        list[indx]["boxes"] !== ""
      ) {
        list[indx]["totalweight"] = parseFloat(
          (+list[indx]["Lenght"] *
            +list[indx]["breadth"] *
            +list[indx]["height"] *
            +list[indx]["boxes"] *
            CFT) /
          Divisor
        );

        dimlist[indx]["volume_weight"] = parseFloat(
          +dimlist[indx]["length"] *
          +dimlist[indx]["breadth"] *
          +dimlist[indx]["height"] *
          +dimlist[indx]["boxes"]
        );
        let totalw = 0;

        for (var i in list) {
          totalw += parseFloat(list[i]["totalweight"]);
        }

        setRateData({
          ...ratedata,
          ["totalweight"]: totalw,
        });
      }
      setDimensionsData(dimlist);
      setDimensions(list);
    } else if (name === "units") {
      list.forEach((element) => {
        element.units = value;
      });
      dimlist.forEach((element) => {
        element.units = value;
      });

      setDimensionsData(dimlist);
      setDimensions(list);
    }
  };
  const unitChange = (e, i) => {
    const { value } = e.target;
    const newDimensions = [...dimensions];
    newDimensions[i].unit = value;
    setDimensions(newDimensions);
  };

  const getInformation = (lpid, id, indxval) => {
    setindxValue(indxval);
    setOpen(true);
    setDataId(id);
    Getratesettings("Rules", lpid).then((res) => {
      setTooltipmess(
        res.data.map((val) => {
          return <li>{val.value}</li>;
        })
      );
    });
    document.getElementById("ratemodal").click();
  };

  const columnname = [
    { field: "info", width: 100 },
    {
      field: "lpName",
      title: "LP Name",
      width: 150,
      cellStyle: {
        textAlign: "left",
      },
    },
    { field: "serviceName", title: "Service Name", width: 150 },
    { field: "taxableAmount", title: "Taxable Amount", width: 150 },
    { field: "gstAmount", title: "GST Amount", width: 150 },
    {
      field: "totalAmount",
      title: "Rate",
      width: 150,
      customFilterAndSearch: (term, rowData) => {
        if (rowData.totalAmount >= term) return rowData.totalAmount;
      },
    },
    { field: "expectedDelivery", title: "ETA", width: 150 },
    {
      field: "action",
      title: "Action",
      width: 150,
    },
  ];


  // useEffect(() => {
  //   if (selectedServiceType !== "" && searchDataTriggered) {
  //     searchData();
  //   }
  // }, [selectedServiceType, searchDataTriggered]);


  const searchData = (e) => {
    // e.preventDefault();
    if (e) {
      e.preventDefault();
    } else {
      console.error("Event object is undefined in searchData");
      return;
    }
    let c = 0;
    for (let i = 0; i < dimensions.length; i++) {
      if (
        dimensions[i].Lenght === "" ||
        dimensions[i].boxes === "" ||
        dimensions[i].breadth === "" ||
        dimensions[i].grossweight === "" ||
        dimensions[i].height === ""
      ) {
        c = 1;
        break;
      } else c = 0;
    }
    const { frompin, topin, shipmentvalue, shipmentweight } = ratedata;
    if (frompin === "" || topin === "" || shipmentvalue === "" || shipmentweight === "" || c === 1) {
      toast.warning("Please fill all mandatories fields");
    } else {
      setSearchDataTriggered(true);
      if (shipmentvalue >= 50000) {
        document.getElementById("infomess").click();
      }
      setLoading(true);
      let grosswt = "";
      let totalwt = "";
      let units = "";
      let boxesValue = '';
      var searchdata = {};
      let searchedorderdimensions = [];
      searchdata.ratedata = ratedata;
      var totalvolume = 0;
      var b2c;
      dimensions.map((val) => {
        let total = "";
        if (val.Lenght !== "" && val.breadth && val.height) {
          total = val.Lenght * val.breadth * val.height * val.boxes;
          totalwt = totalwt + total + ",";
          totalvolume = totalvolume + total;
        }
        // grosswt = grosswt + val.grossweight / val.boxes + ",";
        const weightInKg = val.unit === 'g' ? val.grossweight / 1000 : val.grossweight;
        ratedata.weightinkg = weightInKg;
        grosswt = grosswt + weightInKg / val.boxes + ",";
        units = val.units;
        searchdata.b2c = showb2c
        searchedorderdimensions.push({
          length: val.Lenght,
          height: val.height,
          breadth: val.breadth,
          volume_weight: total,
          noOfArticles: val.boxes,
          actualWeight: weightInKg
        });
        if (boxesValue === '') {
          boxesValue = val.boxes.toString();
        } else {
          boxesValue += `,${val.boxes},`;
        }
        searchdata.searchedorderdimensions = searchedorderdimensions;
      });
      console.log(`searchdata550`, searchdata);

      searchdata.ratedata.boxes = boxesValue;
      localStorage.setItem("searchdata", JSON.stringify(searchdata));
      const cust_Email = localStorage.getItem("user_email");
      GetRates(ratedata, grosswt, totalwt, units, cust_Email, topay, showb2c , boxesValue).then(
        (res) => {
          setLoading(false);
          setData("");
          setChipLabel(showb2c ? 'B2C' : 'B2B');
          const list = [];
          var data = Array.from(res.data);
          data.map((val, i) => {
            list.push({
              info: (
                <InfoIcon
                  onClick={() => getInformation(val.lpId, i, 0)}
                  sx={{ color: "blue", marginLeft: "10px" }}
                />
              ),
              lpName: val.lpName,
              rateRequestId: val.rateRequestId,
              avgRating: val.avgRating,
              totalReview: val.totalReview,
              serviceType: val.serviceType,
              serviceName: val.serviceName,
              taxableAmount: val.taxableAmount,
              gstAmount: val.gstAmount,
              totalAmount: val.totalAmount,
              expectedDelivery: val?.expectedDelivery,
              id: val.lpId,
              imagePath: val.imagePath,
              v_GSTin: shipmentvalue >= 50000 ? val.v_GSTin : "",
              descriptionV: val.descriptionV,
              isKYCApproved: val.isKYCApproved,
              action: (
                <>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => ViewPopup(i, e, "", 1)}
                    id="breakups"
                  >
                    View Breakups
                  </button>
                  {val.isKYCApproved === 1 && !role.includes("Vendor") ? (
                    <>
                      <button
                        className="btn btn-primary ms-3"
                        onClick={(e) => ViewPopup(i, e, val.lpId, 2)}
                        id="book"
                      >
                        Book
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ),
              isb2c: val.isb2c
            });
            //  }
          });
          setRowData(list);
          if (res.data.length !== 0) {
            setLpData(res.data);
            setmylpid(res.data[0].lpId);
          } else {
            setData("Data Not Found!!");
          }
        }
      );
    }
  };


  const [sortOrder, setSortOrder] = useState("");

  const [sortedData, setSortedData] = useState([]);

  const [sortchargeableOrder, setSortchargeableOrder] = useState("");
  const handleLowToHighchargeableamount = () => {
    setSortchargeableOrder("asc");
    sortchargeableData("asc");
  };
  const handleHighToLowchargeableamount = () => {
    setSortchargeableOrder("desc");
    sortchargeableData("desc");
  };
  const sortchargeableData = (order) => {
    const sortedArray = [...lpdata].sort((a, b) => {
      if (order === "asc") {
        return new Date(a.totalAmount) - new Date(b.totalAmount);
      } else {
        return new Date(b.totalAmount) - new Date(a.totalAmount); // Change this line for descending order
      }
    });
    setSortedData(sortedArray);
  };

  const [sortTATOrder, setSortTATOrder] = useState("");
  const handleLowToHighTAT = () => {
    setSortTATOrder("asc");
    sortTATData("asc");
  };
  const handleHighToLowTAT = () => {
    setSortTATOrder("desc");
    sortTATData("desc");
  };
  const sortTATData = (order) => {
    const sortedArray = [...lpdata].sort((a, b) => {
      if (order === "asc") {
        return new Date(a.expectedDelivery) - new Date(b.expectedDelivery);
      } else {
        return new Date(b.expectedDelivery) - new Date(a.expectedDelivery);
      }
    });
    setSortedData(sortedArray);
  };

  const [selectedRating, setSelectedRating] = useState(0);
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);

  const filterDataByRating = (selectedRating) => {
    console.log("passed rating type", selectedRating);
    if (!lpdata || lpdata.length === 0) {
      console.log('Empty or undefined lpdata');
      return;
    }
    if (selectedRating === 0) {
      setSortedData(lpdata);
      setShowNoDataMessage(false);
    } else {
      const filteredArray = lpdata.filter((item) => {
        return (
          item.avgRating !== undefined && item.avgRating >= selectedRating
        );
      });
      console.log('Filtered Array:', filteredArray);
      setSortedData(filteredArray);
      setShowNoDataMessage(filteredArray.length === 0);
    }
  };


  const filterDataByServiceType = (selectedServiceType) => {
    console.log("passed service type", selectedServiceType);

    if (!lpdata || lpdata.length === 0) {
      console.log('Empty or undefined lpdata');
      return;
    }
    if (selectedServiceType === 0) {
      setSortedData(lpdata);
      setShowNoDataMessage(false);
    } else {
      const filteredArray = lpdata.filter((item) => {
        return (
          item.serviceType !== undefined && item.serviceType >= selectedServiceType
        );
      });
      console.log('Filtered Array:', filteredArray);
      setSortedData(filteredArray);
      setShowNoDataMessage(filteredArray.length === 0);
    }
  }


  const ViewPopup = (id, e, lpid, indxval) => {
    document.getElementById("ratemodal").click();
    setindxValue(indxval);
    setDataId(id);
    setLpDetails(lpdata);
    if (e.target.id === "book") {
      Getlpdatabylpid(lpid).then((res) => {
        setActiveStep(0);
      });
      Getratesettings("Paymentmode", "Admin").then((res) => {
        const list = [];
        const list2 = [];
        Getratesettings("Pickslot", lpid).then((resp) => {
          if (res.data.length === 0) {
            list.push({ label: "", value: "" });
          } else {
            var data = Array.from(res.data);
            data.map((val) => {
              list.push({ label: val.value, value: val.value });
            });
          }
          if (resp.data.length === 0) {
            list2.push({ label: "", value: "" });
          } else {
            var ndata = Array.from(res.data);
            ndata.map((val) => {
              list2.push({ label: val.value, value: val.value });
            });
          }
          setVendorsetting({ paymentmode: list, pickuptime: list2 });
        });
      });

      setInputValue({
        deliveryaddress: "",
        pickupaddress: "",
        returnaddress: "",
      });
    }
  };

  const fetchVendorRules = (id) => {
    GetVendorRules(id)
      .then((result) => {
        setvendorrules(result.data);
        rulesetOpen(true);
      })
      .catch((error) => {
        // console.error(error);
        setvendorrules([]);
      });
  };

  const submitOrder = (id) => {
    setLoading(true);
    document.getElementById("ratemodal").click();
    let Orderdetails = {
      lpid: lpdata[id]["lpId"],
      serviceid: lpdata[id]["serviceId"],
      frompincode: ratedata.frompin,
      topincode: ratedata.topin,
      deliveryaddress: ratedata.deliveryaddress,
      pickupaddress: ratedata.pickupaddress,
      returnaddress: ratedata.returnaddress,
      shipment_weight: ratedata.shipmentweight,
      chargable_weight: lpdata[id]["chargeableWt"],
      shipment_value: ratedata.shipmentvalue,
      cft: lpdata[id]["cft"],
      divisor: lpdata[id]["divisor"],
      isCod: ratedata.cod,
      rate: lpdata[id]["rate"],
      docketcharge: lpdata[id]["docketCharge"],
      fsc_percent: lpdata[id]["fsc"],
      fsc_amount: lpdata[id]["fscAmount"],
      greentax: lpdata[id]["greenTax"],
      greentax_amount: lpdata[id]["greenTaxAmount"],
      min_Chargable_weight: lpdata[id]["minWt"],
      minFreight: lpdata[id]["minFreight"],
      minFOV: lpdata[id]["minFOV"],
      fovPercentage: lpdata[id]["fovPercentage"],
      fov_amount: lpdata[id]["fovCharge"],
      minODA: lpdata[id]["minODA"],
      odaPerKG: lpdata[id]["odaPerKG"],
      oda_amount: lpdata[id]["oda"],
      handlingCharge: lpdata[id]["handlingCharge"],
      codAmount: lpdata[id]["codAmount"],
      taxableAmount: lpdata[id]["taxableAmount"],
      gst: lpdata[id]["gst"],
      gst_Amount: lpdata[id]["gstAmount"],
      totalAmount: lpdata[id]["totalAmount"],
      ratesheetid: lpdata[id]["rateSheetId"],
      expectedDelivery: lpdata[id]["expectedDelivery"],
      orderdimensions: dimensionsdata,
      itemname: ratedata.itemname,
      paymentmode: ratedata.paymentmode,
      pickuptime: ratedata.pickuptime,
    };
    SaveOrderdata(Orderdetails).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setRateData({
          frompin: "",
          topin: "",
          pieces: 0,
          cod: false,
          shipmentvalue: "",
          totalweight: "",
          shipmentweight: "",
          deliveryaddress: 0,
          pickupaddress: 0,
          returnaddress: 0,
          itemname: "",
          paymentmode: "",
          pickuptime: "",
        });
        setData("");
        setDimensionsData([
          {
            length: 0,
            height: 0,
            breadth: 0,
            boxes: 0,
            volume_weight: 0,
          },
        ]);
        setVendorsetting({ pickuptime: "", paymentmode: "" });
        setDimensions([
          {
            Lenght: "",
            breadth: "",
            height: "",
            boxes: "",
            totalweight: "",
            grossweight: "",
          },
        ]);
        toast.success("Order submit successfully");
      } else {
        toast.error(res.data);
      }
    });
  };
  const [opensortedbreak, sortedbreakups] = useState(false);
  const ViewFilteredBreakupsOpen = (item, i) => {
    rows = Initializerows;
    console.log("my passed filtered item", item);
    console.log("my passed filtered i", i);
    filteredDialogamount = sortedData[i]["totalAmount"];
    filteredDialogheader = sortedData[i]["lpName"];
    console.log("my sorteddata", sortedData);
    console.log("filteredDialogheader", filteredDialogheader);
    console.log("filteredDialogamount", filteredDialogamount);
    sortedrows.forEach((e) => {
      if (e.sortedcharges == "Rate") {
        e.sortedamount = sortedData[i]["rate"];
      }
      if (e.sortedcharges == "Chargeable Weight") {
        e.sortedamount = sortedData[i]["chargeableWt"].toFixed(2);
      }
      if (e.sortedcharges == "ODA") {
        e.sortedamount = sortedData[i]["oda"].toFixed(2);
      }
      if (e.charges == "Base Freight") {
        e.amount = lpdata[i]["base_Freight"].toFixed(2);
      }
      if (e.sortedcharges == "Docket Charge") {
        e.sortedamount = sortedData[i]["docketCharge"].toFixed(2);
      }

      if (e.sortedcharges == "FOV Charges ") {
        e.sortedamount = sortedData[i]["fovCharge"].toFixed(2);
      }
      if (e.sortedcharges == "FSC Percentage") {
        e.sortedamount = sortedData[i]["fsc"];
      }
      if (e.sortedcharges == "FSC Amount") {
        e.sortedamount = sortedData[i]["fscAmount"].toFixed(2);
      }
      if (e.sortedcharges == "Green Tax") {
        e.sortedamount = sortedData[i]["greenTaxAmount"].toFixed(2);
      }

      if (e.sortedcharges == "Handling Charges") {
        e.sortedamount = sortedData[i]["handlingCharge"].toFixed(2);
      }
      if (e.sortedcharges == "Taxable Amount") {
        e.sortedamount = sortedData[i]["taxableAmount"].toFixed(2);
      }
      if (e.sortedcharges == "GST Amount (18%)") {
        e.sortedamount = sortedData[i]["gstAmount"].toFixed(2);
      }
      if (e.sortedcharges == "Topay Charges") {
        e.sortedamount =
          sortedData[i]["toPayAmount"] == null
            ? 0
            : sortedData[i]["toPayAmount"].toFixed(2);
      }
    });
    if (lpdata[i].additionalCharges) {
      try {
        const additionalChargesArray = Object.entries(lpdata[i].additionalCharges);
        rows = rows.slice(0, 4).concat(rows.slice(11));
        const additionalChargesRows = additionalChargesArray.map(([name, value]) =>
          createData(name, value)
        );
        const insertIndex = rows.length - 2;
        rows.splice(insertIndex, 0, ...additionalChargesRows);

      } catch (e) {
        console.log('error', e);
      }

    }
    sortedbreakups(true);
  };
  const ViewfilteredBreakupsClose = () => {
    sortedbreakups(false);
  };

  const [openbreak, breakups] = useState(false);
  const ViewBreakupsOpen = (item, i) => {
    rows = Initializerows;
    // Dialogheader = lpdata[i]["lpName"];
    // Dialogamount = lpdata[i]["totalAmount"];
    setDialogHeader(lpdata[i]["lpName"]);
    setDialogAmount(lpdata[i]["totalAmount"]);
    rows.forEach((e) => {
      var row = 1;
      if (e.charges == "Rate") {
        e.amount = lpdata[i]["rate"];
      }
      if (e.charges == "Chargeable Weight") {
        e.amount = lpdata[i]["chargeableWt"].toFixed(2);
      }
      if (e.charges == "ODA") {
        e.amount = lpdata[i]["oda"].toFixed(2);
      }
      if (e.charges == "Base Freight") {
        e.amount = lpdata[i]["base_Freight"].toFixed(2);
      }
      if (e.charges == "Docket Charge") {
        e.amount = lpdata[i]["docketCharge"].toFixed(2);
      }
      if (e.charges == "FOV Charges ") {
        e.amount = lpdata[i]["fovCharge"].toFixed(2);
      }
      if (e.charges == "FSC Percentage") {
        e.amount = lpdata[i]["fsc"];
      }
      if (e.charges == "FSC Amount") {
        e.amount = lpdata[i]["fscAmount"].toFixed(2);
      }
      if (e.charges == "Green Tax") {
        e.amount = lpdata[i]["greenTaxAmount"].toFixed(2);
      }

      if (e.charges == "Handling Charges") {
        e.amount = lpdata[i]["handlingCharge"].toFixed(2);
      }
      if (e.charges == "Taxable Amount") {
        e.amount = lpdata[i]["taxableAmount"].toFixed(2);
      }

      if (e.charges == "GST Amount (18%)") {
        e.amount = lpdata[i]["gstAmount"].toFixed(2);
      }
      if (e.charges == "Topay Charges") {
        e.amount =
          lpdata[i]["toPayAmount"] == null
            ? 0
            : lpdata[i]["toPayAmount"].toFixed(2);
      }
    });
    if (lpdata[i].additionalCharges) {
      try {
        const additionalChargesArray = Object.entries(lpdata[i].additionalCharges);
        rows = rows.slice(0, 4).concat(rows.slice(11));
        const additionalChargesRows = additionalChargesArray.map(([name, value]) =>
          createData(name, value)
        );
        const insertIndex = rows.length - 2;
        rows.splice(insertIndex, 0, ...additionalChargesRows);
      } catch (e) {
        console.log('error', e);
      }
    }
    breakups(true);
  };
  const ViewBreakupsClose = () => {
    breakups(false);
  };

  const [ratingopen, setratingOpen] = useState(false);

  const ratingClickOpen = (lpId) => {
    GetReviewDetails(lpId)
      .then((result) => {
        setreviewlistdata(result);
        setcustomersreview(result._custReview);
        setratingOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ratingClose = () => {
    setratingOpen(false);
  };
  const [ruleopen, rulesetOpen] = React.useState(false);
  const rulehandleClickOpen = (id) => {
    fetchVendorRules(id);
  };
  const rulehandleClose = () => {
    rulesetOpen(false);
  };

  let navigate = useNavigate();
  function orderDetails(i) {
    var response = lpdata[i];
    localStorage.setItem("lpdata", JSON.stringify(response));
    localStorage.setItem("topay", JSON.stringify(topay));
    localStorage.setItem("isb2c", JSON.stringify(response.isb2c));
    navigate("/OrderBooking");
  }

  function sortedorderDetails(i) {
    var response = sortedData[i];
    localStorage.setItem("lpdata", JSON.stringify(response));
    localStorage.setItem("topay", JSON.stringify(topay));
    localStorage.setItem("isb2c", JSON.stringify(response.isb2c));
    navigate("/OrderBooking");
  }
  const [mylpid, setmylpid] = useState("");
  const [reviewlistdata, setreviewlistdata] = useState({});

  const [selectedSortOption, setSelectedSortOption] = useState(1);
  const [customersreview, setcustomersreview] = useState([]);
  const sortReviews = () => {
    const sortedReviews = [...customersreview];

    if (selectedSortOption === 2) {
      sortedReviews.sort((a, b) => {
        const dateA = new Date(a.reviewDuration);
        const dateB = new Date(b.reviewDuration);
        return dateB - dateA;
      });
    } else if (selectedSortOption === 3) {
      sortedReviews.sort((a, b) => b.cust_Rating - a.cust_Rating);
    } else if (selectedSortOption === 4) {
      sortedReviews.sort((a, b) => a.cust_Rating - b.cust_Rating);
    }

    return sortedReviews;
  };

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;




  if (loading)
    return (
      <>
        <div className="content-wrapper">
          <Loading />
        </div>
      </>
    );
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <h1 className="main-title">Book Order</h1>
              </div>
            </div>
          </div>
          {/* //BOOK ORDER CARD SECTION */}
          <section className="content">
            <div className="container-fluid">
              <Card className="custum-shadow order-search-box">
                <div className="row">
                  <div className="col">
                    <TextField
                      className="pd-input"
                      required
                      fullWidth
                      label="Source"
                      onChange={inputEvent}
                      name="frompin"
                      defaultValue={"110034"}
                      value={ratedata.frompin}
                      placeholder="Pincode"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PlaceOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      className="pd-input"
                      required
                      fullWidth
                      label="Destination"
                      placeholder="Pincode"
                      onChange={inputEvent}
                      name="topin"
                      defaultValue={"110085"}
                      value={ratedata.topin}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PlaceOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      className="pd-input"
                      required
                      fullWidth
                      label="Shipment Amount"
                      placeholder="Value"
                      onChange={inputEvent}
                      name="shipmentvalue"
                      value={ratedata.shipmentvalue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col" style={{ maxWidth: "155px" }}>
                    <div className="topay-box">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={topay}
                              onChange={(e) => setTopay(e.target.checked)}
                            />
                          }
                          label="Topay"
                        />
                      </FormGroup>
                      <Tooltip title="To pay option.">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col" style={{ maxWidth: "180px" }}>
                    <div className="topay-box">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          value={showb2c ? 'b2c' : 'b2b'}
                          onChange={handleSwitchChange}
                        >
                          {viewType==1?<FormControlLabel value="b2b" control={<Radio />} label="B2B" />: <FormControlLabel value="b2c" control={<Radio />} label="B2C" />}
                          {/* <FormControlLabel value="b2c" control={<Radio />} label="B2C" /> */}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-10">
                    <div className="row">
                      {dimensions.map((val, i) => {
                        return (
                          <>
                            {" "}
                            <div className="col-sm-7">
                              <div className="row">
                                <label className="lable-title">
                                  Dimensions
                                </label>
                                <div className="col">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Unit
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="units"
                                      value={val.units}
                                      onChange={(e) => dimensionsChange(e, i)}
                                    >
                                      {units.map((val) => {
                                        return (
                                          <MenuItem value={val}>{val}</MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <TextField
                                    className="pd-input"
                                    required
                                    fullWidth
                                    label="L"
                                    name="Lenght"
                                    value={val.Lenght}
                                    onChange={(e) => dimensionsChange(e, i)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    className="pd-input"
                                    required
                                    fullWidth
                                    label="B"
                                    name="breadth"
                                    value={val.breadth}
                                    onChange={(e) => dimensionsChange(e, i)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    className="pd-input"
                                    required
                                    fullWidth
                                    label="H"
                                    aria-label="Height"
                                    name="height"
                                    value={val.height}
                                    onChange={(e) => dimensionsChange(e, i)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    className="pd-input"
                                    required
                                    fullWidth
                                    label="Boxes"
                                    aria-label="boxes"
                                    name="boxes"
                                    value={val.boxes}
                                    onChange={(e) => dimensionsChange(e, i)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-5">
                              <label className="lable-title">Box Wt.</label>
                              {/* <Tooltip title="To pay option.">
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip> */}
                              <div className="row">
                                <div className="col-sm-4">
                                  <Tooltip
                                    title={`Weight for ${val.boxes} boxes`}
                                    placement="top"
                                  >
                                    <TextField
                                      className="pd-input"
                                      required
                                      fullWidth
                                      label="Weight"
                                      name="grossweight"
                                      value={val.grossweight}
                                      onChange={(e) => dimensionsChange(e, i)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <div className="col-sm-4">
                                  <FormControl fullWidth>
                                    <InputLabel id={`unit-label-${i}`}>Weight Unit</InputLabel>
                                    <Select
                                      labelId={`unit-label-${i}`}
                                      id={`unit-${i}`}
                                      value={val.unit}
                                      onChange={(e) => unitChange(e, i)}
                                      label="Weight Unit"
                                    >
                                      <MenuItem value="kg">Kg</MenuItem>
                                      <MenuItem value="g">Gram</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col-sm-4">
                                  <div className="d-flex">
                                    {dimensions.length !== 1 && (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => handleRemoveClick(i)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                    {dimensions.length - 1 === i && (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => handleAddClick(i)}
                                      >
                                        <AddCircleIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-sm-2 ml-auto">
                    <label className="lable-title" style={{ visibility: "hidden" }}>
                      Shipment Amount
                    </label>
                    <TextField
                      className="pd-input"
                      required
                      fullWidth
                      label="Shipment Weight"
                      onChange={inputEvent}
                      placeholder="Weight"
                      value={ratedata.shipmentweight}
                      name="shipmentweight"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ScaleOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    className="search-btn"
                    onClick={searchData}
                  >
                    Search
                  </Button>
                </div>
              </Card>
            </div>
          </section>

          {/* //NO DATA FOUND FACTS SECTION */}
          <section className="content mt-4">
            <div className="container-fluid">
              {" "}
              {rowdata.length === 0 ? (
                <>
                  <div className="no-data">
                    <LocalShippingTwoToneIcon sx={{ fontSize: 60 }} />
                    <h4>No Data Found</h4>
                  </div>
                  <div className="facts-boxs">
                    <h4>
                      Did you know that the largest ship in the world that is
                      used for logistics can carry and transport the Eiffel
                      tower with no problem?
                    </h4>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="card">
                          <span>01</span>
                          <p className="scroll-p">
                            According to the World Bank Logistics Performance
                            Index of 2018, India, despite being a large economy,
                            ranked 44th on the list with a rating of 3.18.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>02</span>
                          <p className="scroll-p">
                            In totality, an approximate 8.5 million trucks of
                            all shapes and sizes ply on the winding roads and
                            highways of India.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>03</span>
                          <p className="scroll-p">
                            Indian Railways operates more than 9,200 trains a
                            day and transports 1,110 million tons of freight
                            during the fiscal year.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>04</span>
                          <p className="scroll-p">
                            High-capacity parcel vans also commonly known as
                            Green Parcel Vans have been used in rakes designed
                            to transport fruit and vegetables for special
                            purposes. The high-capacity parcel van carries 23 t
                            against the regular parcel van carrying 18 t of
                            goods.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                //VENDOR RATE CARD ON SEARCH BUTTON CLICK
                <>
                  <Card
                    sx={{ p: "1rem" }}
                    className="custum-shadow search-box mb-3 sort-by"
                  >







                    <div className="category-option">
                      <FormControl fullWidth variant="standard">
                        <InputLabel
                          id="category"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          Filter By Service
                        </InputLabel>
                        <Select
                          labelId="category"
                          id="category"
                          fullWidth
                          label="Filter by service"
                          value={selectedServiceType}
                          // onChange={(e) => setSelectedServiceType(e.target.value)}
                          onChange={(event, newValue) => {
                            const selectedValue = newValue.props.value;
                            console.log('Selected Value:', selectedValue);
                            setSelectedServiceType(selectedValue);
                            filterDataByServiceType(selectedValue);
                          }}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          <MenuItem value={3}>Air</MenuItem>
                          <MenuItem value={2}>Water</MenuItem>
                          <MenuItem value={1}>Surface</MenuItem>



                        </Select>
                      </FormControl>
                    </div>

















                    <div className="sort-items">
                      <h5>Sort By</h5>
                      <div className="sort-by-inner">
                        <div className="chargeableamount sort-option">
                          Chargeable Amount :
                          <span
                            className={
                              sortchargeableOrder === "asc" ? "active" : ""
                            }
                            onClick={handleLowToHighchargeableamount}
                          >
                            Low to High
                          </span>
                          <span
                            className={
                              sortchargeableOrder === "desc" ? "active" : ""
                            }
                            onClick={handleHighToLowchargeableamount}
                          >
                            High to Low
                          </span>
                        </div>

                        <div className="tat sort-option">
                          TAT :
                          <span
                            className={sortTATOrder === "asc" ? "active" : ""}
                            onClick={handleLowToHighTAT}
                          >
                            Low to High
                          </span>
                          <span
                            className={sortTATOrder === "desc" ? "active" : ""}
                            onClick={handleHighToLowTAT}
                          >
                            High to Low
                          </span>
                        </div>

                        <div className="recommended-option sort-option">
                          <span>Recommended</span>
                        </div>

                        <div className="rating-option sort-option">
                          Rating :{" "}
                          <Rating
                            className="sort-rating"
                            precision={0.5}
                            name="size-small"
                            size="small"
                            value={selectedRating}
                            onChange={(event, newValue) => {
                              setSelectedRating(newValue);
                              filterDataByRating(newValue);
                            }}
                          />
                        </div>
                        <div className="text-center">
                          <div
                            className={`recommended-option sort-option ${isDefaultSelected ? "active" : ""
                              }`}
                          >
                            <span onClick={() => filterDataByRating(0)}>
                              Default
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  {sortchargeableOrder
                    ? sortedData.map((item, i) => (
                      <Card
                        sx={{ p: 2 }}
                        className="custum-shadow mb-3 order-list"
                        key={i}
                      >
                        <div className="row align-items-center">
                          <div className="col-lg-3">
                            <img
                              src={`${CurrentUrl}${item.imagePath}`}
                              className="lp-img"
                              alt=""
                            />
                          </div>
                          <div className="col-lg-9 lp-details">
                            {i === 0 && (
                              <p className="recommended">
                                Recommended by MVikas
                              </p>
                            )}
                            <div className="title-orderbox">
                              <h4>{item.lpName}</h4>
                              <IconButton
                                onClick={() => {
                                  rulehandleClickOpen(item.id);
                                }}
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div className="rating">
                              <span className="rate-text">
                                {item.avgRating}
                              </span>
                              <Rating
                                name="text-feedback"
                                value={item.avgRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.5 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              <span
                                className="text"
                                onClick={() => ratingClickOpen(item.id)}
                              >
                                {item.totalReview}
                              </span>
                            </div>
                            <p>{item.descriptionV}</p>
                            <div className="price-box">
                              <div className="text">
                                <label>Service Name</label>
                                <p>{item.serviceName}</p>
                              </div>
                              <div className="text">
                                <label>Service Type</label>
                                <p>{getServiceTypeLabel(item.serviceType)}</p>
                              </div>
                              <div className="text">
                                <label>Taxable Amount</label>
                                <p>{item.taxableAmount}</p>
                              </div>
                              <div className="text">
                                <label>GST Amount</label>
                                <p>{item.gstAmount}</p>
                              </div>
                              <div className="text">
                                <label>Total Amount</label>
                                <p>{item.totalAmount}</p>
                              </div>
                              <div className="text">
                                <label>ETA</label>
                                <p>{item.expectedDelivery}</p>
                              </div>
                            </div>
                            {item.v_GSTin && (
                              <div className="text">
                                <label>Vendor GST</label>
                                <p>{item.v_GSTin}</p>
                              </div>
                            )}
                            <div className="mt-4">
                              <Button
                                variant="contained"
                                className="lp-btn"
                                onClick={(e) =>
                                  ViewFilteredBreakupsOpen(item, i)
                                }
                              >
                                View Breakups
                              </Button>
                              <Button
                                onClick={(e) => sortedorderDetails(i)}
                                variant="contained"
                                className="lp-btn"
                              >
                                Book
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))
                    : sortedData.length > 0
                      ? sortedData.map((item, i) => (
                        <Card
                          sx={{ p: 2 }}
                          className="custum-shadow mb-3 order-list"
                          key={i}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-3">
                              <img
                                src={`${CurrentUrl}${item.imagePath}`}
                                className="lp-img"
                                alt=""
                              />
                            </div>
                            <div className="col-lg-9 lp-details">
                              {i === 0 && (
                                <p className="recommended">
                                  Recommended by MVikas
                                </p>
                              )}
                              <div className="title-orderbox">
                                <h4>{item.lpName}</h4>
                                <IconButton
                                  onClick={() => {
                                    rulehandleClickOpen(item.id);
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </div>
                              <div className="rating">
                                <span className="rate-text">
                                  {item.avgRating}
                                </span>
                                <Rating
                                  name="text-feedback"
                                  value={item.avgRating}
                                  readOnly
                                  precision={0.5}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.5 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <span
                                  className="text"
                                  onClick={() => ratingClickOpen(item.id)}
                                >
                                  {item.totalReview}
                                </span>
                              </div>
                              <p>{item.descriptionV}</p>
                              <div className="price-box">
                                <div className="text">
                                  <label>Service Name</label>
                                  <p>{item.serviceName}</p>
                                </div>
                                <div className="text">
                                  <label>Service Type</label>
                                  <p>{getServiceTypeLabel(item.serviceType)}</p>
                                </div>
                                <div className="text">
                                  <label>Taxable Amount</label>
                                  <p>{item.taxableAmount}</p>
                                </div>
                                <div className="text">
                                  <label>GST Amount</label>
                                  <p>{item.gstAmount}</p>
                                </div>
                                <div className="text">
                                  <label>Total Amount</label>
                                  <p>{item.totalAmount}</p>
                                </div>
                                <div className="text">
                                  <label>ETA</label>
                                  <p>{item.expectedDelivery}</p>
                                </div>
                              </div>
                              {item.v_GSTin && (
                                <div className="text">
                                  <label>Vendor GST</label>
                                  <p>{item.v_GSTin}</p>
                                </div>
                              )}
                              <div className="mt-4">
                                <Button
                                  variant="contained"
                                  className="lp-btn"
                                  onClick={(e) =>
                                    ViewFilteredBreakupsOpen(item, i)
                                  }
                                >
                                  View Breakups
                                </Button>
                                <Button
                                  onClick={(e) => sortedorderDetails(i)}
                                  variant="contained"
                                  className="lp-btn"
                                >
                                  Book
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))
                      : rowdata.map((item, i) => {
                        {
                          return (
                            <Card
                              sx={{ p: 2 }}
                              className="custum-shadow mb-3 order-list"
                            >
                              <div className="row align-items-center">
                                <div className="col-lg-3">
                                  <img
                                    src={`${CurrentUrl}${item.imagePath}`}
                                    className="lp-img"
                                    alt=""
                                  />
                                </div>
                                <div className="col-lg-9 lp-details">
                                  {i === 0 && (
                                    <p className="recommended">
                                      Recommended by MVikas
                                    </p>
                                  )}
                                  <div className="title-orderbox">
                                    <h4>{item.lpName}</h4>
                                    <IconButton
                                      onClick={() => {
                                        rulehandleClickOpen(item.id);
                                      }}
                                    >
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                    <Chip label={chipLabel} sx={{ color: '#830C20', borderStyle: 'solid' }} />
                                  </div>
                                  <div className="rating">
                                    <span className="rate-text">
                                      {item.avgRating}
                                    </span>
                                    <Rating
                                      name="text-feedback"
                                      value={item.avgRating}
                                      readOnly
                                      precision={0.5}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.5 }}
                                          fontSize="inherit"
                                        />
                                      }
                                    />
                                    <span
                                      className="text"
                                      onClick={() => ratingClickOpen(item.id)}
                                    >
                                      {item.totalReview}
                                    </span>
                                  </div>
                                  <p>{item.descriptionV}</p>
                                  <div className="price-box">
                                    <div className="text">
                                      <label>Service Name</label>
                                      <p>{item.serviceName}</p>
                                    </div>
                                    <div className="text">
                                      <label>Service Type</label>
                                      {/* <p>{item.serviceType}</p> */}
                                      <p>{getServiceTypeLabel(item.serviceType)}</p>
                                    </div>
                                    <div className="text">
                                      <label>Taxable Amount</label>
                                      <p>{item.taxableAmount}</p>
                                    </div>
                                    <div className="text">
                                      <label>GST Amount</label>
                                      <p>{item.gstAmount}</p>
                                    </div>
                                    <div className="text">
                                      <label>Total Amount</label>
                                      <p>{item.totalAmount}</p>
                                    </div>
                                    <div className="text">
                                      <label>ETA</label>
                                      <p>{item.expectedDelivery}</p>
                                    </div>
                                  </div>
                                  {item.v_GSTin && (
                                    <div className="text">
                                      <label>Vendor GST</label>
                                      <p>{item.v_GSTin}</p>
                                    </div>
                                  )}
                                  <div className="mt-4">
                                   {viewType==1?<Button
                                      variant="contained"
                                      className="lp-btn"
                                      onClick={(e) => ViewBreakupsOpen(item, i)}
                                    >
                                      View Breakups
                                    </Button>:null}
                                    {/* {item.isKYCApproved === 1 && ( */}
                                      <Button
                                        onClick={(e) => orderDetails(i)}
                                        variant="contained"
                                        className="lp-btn"
                                      >
                                        Book
                                      </Button>
                                    {/* )} */}

                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                </>
              )}
            </div>

            {/* View Breakups modal */}
            <Dialog
              open={openbreak}
              TransitionComponent={Transition}
              keepMounted
              onClose={ViewBreakupsClose}
              aria-describedby="alert-dialog-slide-description"
              className="breakpoint-popup"
            >
              <DialogTitle>
                {Dialogheader}
                <HighlightOffRoundedIcon
                  onClick={ViewBreakupsClose}
                  className="close-popup-icon"
                />
              </DialogTitle>
              <DialogContent>
                <TableContainer sx={{ mt: 1 }} component={Paper}>
                  <Table
                    sx={{ minWidth: 300 }}
                    aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderRight: "1px solid #e0e0e0" }}>
                          Charges
                        </TableCell>
                        <TableCell align="right">(₹) Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {rows.map(

                        (row) =>
                          (topay || row.charges !== "Topay Charges") && (
                            <TableRow key={row.name}>
                              <TableCell
                                sx={{ borderRight: "1px solid #e0e0e0" }}
                              >
                                {row.charges}
                              </TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow sx={{ backgroundColor: "#5d0000" }}>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #873737",
                            borderBottom: "0px",
                            color: "#FFF",
                          }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#FFF",
                            borderBottom: "0px",
                          }}
                          align="right"
                        >
                          {Dialogamount}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
            {/*sorted view Breakups modal */}
            <Dialog
              open={opensortedbreak}
              TransitionComponent={Transition}
              keepMounted
              onClose={ViewfilteredBreakupsClose}
              aria-describedby="alert-dialog-slide-description"
              className="breakpoint-popup"
            >
              <DialogTitle>
                {filteredDialogheader}
                <HighlightOffRoundedIcon
                  onClick={ViewfilteredBreakupsClose}
                  className="close-popup-icon"
                />
              </DialogTitle>
              <DialogContent>
                <TableContainer sx={{ mt: 1 }} component={Paper}>
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #e0e0e0",
                          }}
                        >
                          Charges
                        </TableCell>
                        <TableCell align="right">(₹) Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedrows.map(
                        (row) =>
                          (topay || row.sortedcharges !== "Topay Charges") && (
                            <TableRow key={row.name}>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {row.sortedcharges}
                              </TableCell>
                              <TableCell align="right">
                                {row.sortedamount}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow sx={{ backgroundColor: "#5d0000" }}>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #873737",
                            borderBottom: "0px",
                            color: "#FFF",
                          }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#FFF",
                            borderBottom: "0px",
                          }}
                          align="right"
                        >
                          {filteredDialogamount}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
            {/* View Rating modal */}
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={ratingopen}
              onClose={ratingClose}
            >
              <DialogTitle id="alert-dialog-title">
                <div className="rating-header">
                  <div className="total-rating">{reviewlistdata.avgRating}</div>
                  <div className="">
                    {reviewlistdata.lp_Name}
                    <div className="rating">
                      <Rating
                        name="text-feedback"
                        value={reviewlistdata.avgRating}
                        readOnly
                        precision={0.5}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.5 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <span className="text">
                        {" "}
                        {reviewlistdata.totalReview}
                      </span>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <FormControl fullWidth>
                      <InputLabel id="Sort by-label">Sort by</InputLabel>
                      <Select
                        labelId="Sort by-label"
                        id="Sort by"
                        value={selectedSortOption}
                        onChange={(e) => setSelectedSortOption(e.target.value)}
                        label="Sort by"
                        style={{ minWidth: "200px" }}
                      >
                        <MenuItem value={1}>All</MenuItem>
                        <MenuItem value={2}>Newest</MenuItem>
                        <MenuItem value={3}>Highest</MenuItem>
                        <MenuItem value={4}>Lowest</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </DialogTitle>

              <DialogContent>
                {Array.isArray(customersreview) &&
                  customersreview.length > 0 ? (
                  sortReviews().map((item) => (
                    <div className="user-review" key={item.cust_name}>
                      <div className="user-icon">{item.name_Key}</div>
                      <div className="text">
                        <h5>{item.cust_name}</h5>
                        <div className="rating">
                          <Rating
                            name="text-feedback"
                            value={item.cust_Rating}
                            readOnly
                            precision={0.5}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.5 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <span className="text" onClick={ratingClickOpen}>
                            {item.reviewDuration}
                          </span>
                        </div>
                        <p>{item.cust_Review}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No customer reviews found.</p>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={ratingClose} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </section>
        </div>{" "}
        <ToastContainer />{" "}
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          id="ratemodal"
          style={{ display: "none" }}
        >
          Launch static backdrop modal
        </button>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {dataId === ""
                    ? ""
                    : indxvalue !== 3
                      ? lpdata[dataId]["lpName"]
                      : "Information"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setLpDetails("");
                    setDataId("");
                    setTooltipmess("");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {dataId === "" ? (
                  ""
                ) : indxvalue === 0 ? (
                  <ul>{tooltipmess}</ul>
                ) : (
                  <>
                    {indxvalue === 1 ? (
                      <div
                        className="row justify-content-between"
                        style={{
                          borderBottom: "2px solid #dee2e6",
                          paddingBottom: "7px",
                        }}
                      >
                        <div
                          className="col-6"
                          style={{
                            borderTop: "1px solid grey",
                            borderBottom: "1px solid grey",
                            padding: "5px",
                          }}
                        >
                          <span className="UpdateRateSpan">Charges </span>
                        </div>{" "}
                        <div
                          className="col-6 text-end"
                          style={{
                            borderTop: "1px solid grey",
                            borderBottom: "1px solid grey",
                            padding: "5px",
                          }}
                        >
                          <span className="UpdateRateSpan">
                            (&#8377;) Amount
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">Rate : </span>
                        </div>{" "}
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {lpdata[dataId]["rate"]}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            Chargeable Weight :
                          </span>
                        </div>{" "}
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {lpdata[dataId]["chargeableWt"]}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            Docket Charge :{" "}
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["docketCharge"]}{" "}
                          </span>
                        </div>{" "}
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            FOV Charges {` (${lpdata[dataId]["fovCharge"]}%)`} :
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {`${lpdata[dataId]["fovCharge"]}`}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            FSC Amount {` (${lpdata[dataId]["fsc"]}%)`} :
                          </span>
                        </div>{" "}
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {`${lpdata[dataId]["fscAmount"]}`}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">Green Tax :</span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {`${lpdata[dataId]["greenTaxAmount"]}`}
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="UpdateRateSpan">ODA : </span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["oda"]}{" "}
                          </span>
                        </div>
                        <div className="col-6 ">
                          <span className="UpdateRateSpan">
                            Handling Charges :
                          </span>
                        </div>{" "}
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["handlingCharge"]}{" "}
                          </span>
                        </div>
                        {ratedata.cod ? (
                          <>
                            {" "}
                            <div className="col-6">
                              <span className="UpdateRateSpan">
                                COD Amount :{" "}
                              </span>
                            </div>{" "}
                            <div className="col-6 text-end">
                              <span className="UpdateRateSpan">
                                {" "}
                                {lpdata[dataId]["codAmount"]}{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            Taxable Amount :{" "}
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["taxableAmount"]}{" "}
                          </span>
                        </div>{" "}
                        <div className="col-6">
                          <span className="UpdateRateSpan">
                            GST Amount {` (${lpdata[dataId]["gst"]}%)`} :{" "}
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["gstAmount"]}{" "}
                          </span>
                        </div>{" "}
                        <div
                          className="col-6"
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            marginTop: "8px",
                            padding: "5px",
                          }}
                        >
                          <span className="UpdateRateSpan">
                            Total Amount :{" "}
                          </span>
                        </div>
                        <div
                          className="col-6 text-end"
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                            marginTop: "8px",
                            padding: "5px",
                          }}
                        >
                          <span className="UpdateRateSpan">
                            {" "}
                            {lpdata[dataId]["totalAmount"]}{" "}
                          </span>
                        </div>
                      </div>
                    ) : indxvalue === 3 ? (
                      <>
                        <span>
                          {" "}
                          For this shipment 'E-way Bill' is required as the
                          shipment value is greater than 50,000/-
                        </span>
                      </>
                    ) : (
                      <Box>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel
                                optional={
                                  index === 2 ? (
                                    <Typography variant="caption">
                                      Last step
                                    </Typography>
                                  ) : null
                                }
                              >
                                {step.label}
                              </StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                                <Grid container spacing={3}>
                                  <Grid item md={5}>
                                    {index === steps.length - 1 ? (
                                      <></>
                                    ) : (
                                      // <AddAddress refreshTable={refreshTable} />
                                      <></>
                                    )}{" "}
                                  </Grid>

                                  <Grid item md={7}>
                                    <diu>
                                      <button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        style={{ margin: "10px" }}
                                        className="btn btn-primary"
                                      >
                                        Back
                                      </button>{" "}
                                      <button
                                        onClick={() =>
                                          handleNext(
                                            activeStep === steps.length - 1,
                                            dataId
                                          )
                                        }
                                        className="btn btn-primary"
                                      >
                                        {index === steps.length - 1
                                          ? "Book Now"
                                          : "Continue"}
                                      </button>
                                    </diu>
                                  </Grid>
                                </Grid>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          id="infomess"
          type="button"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal1"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Information
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {" "}
                <span>
                  {" "}
                  For this shipment 'E-way Bill' is required as the shipment
                  value is greater than 50,000/-
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* //VENDOR RULES ON I BUTTON  */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={ruleopen}
          onClose={rulehandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Rules"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ol className="rules-list">
                {vendorrules.map((item, i) => (
                  <li key={i}>{item.rules}</li>
                ))}
              </ol>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={rulehandleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Submit Review */}
        <Dialog
          maxWidth="md"
          open={reviewopen}
          onClose={reviewClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="text-center"
        >
          <DialogContent className="review-ui pb-0">
            <IconButton
              aria-label="close"
              onClick={reviewClose}
              sx={{
                position: "absolute",
                right: 20,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ fontSize: 25 }} />
            </IconButton>

            <RateReviewRoundedIcon sx={{ fontSize: 25 }} />
            <h6>Submit your order experience</h6>

            <div className="row mt-3">
              {isorderreeviewed.map((item) => {
                return (
                  <div className="col-sm-3">
                    <p>{item.orderid}</p>
                    <DialogActions className="justify-content-center pb-3">
                      <Button
                        href={`#/Review?orderid=${item.orderid}&lpid=${item.lpId}`}
                        variant="contained"
                        autoFocus
                        size="small"
                        className="ml-2"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </div>
                );
              })}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
};

export default ViewLowestrate;
