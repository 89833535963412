import React from 'react'
import Header from './Header'
import Sidebar from "./Sidebar";
import Footer from './Footer'
import CustomerKyc from './kycVerification';

const Kycverification = () => {
  return (
    <>
    <Header/>
    <Sidebar />
    <CustomerKyc/>
    <Footer/>
    </>
  )
}

export default Kycverification


