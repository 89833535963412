import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ViewUploadedCourierRate from "../Components/ViewUploadedCourierRate";

const ViewUploadedCourierRateAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <ViewUploadedCourierRate />
    </>
  );
};

export default ViewUploadedCourierRateAdmin;
