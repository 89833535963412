import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import SubmitReview from "./SubmitReview";
import TrackYourOrder from "./TrackYourOrder";
import CreateZoneAdmin from "../Admin/CreateZoneAdmin";
import Location from "./Location";
import SearchResults from "./SearchResults";
import HomeAdmin from "../Admin/HomeAdmin";
import UserSetting from "../Admin/UserSetting";
import AdminSetting from "../Admin/AdminSetting";
import UserInvoices from "../Admin/UserInvoices";
import UserReports from "../Admin/UserReports";
import CreateSerivce from "./CreateService";
import MapServiceAdmin from "../Admin/MapServiceAdmin";
import CreateServiceAdmin from "../Admin/CreateServiceAdmin";
import ZoneDataAdmin from "../Admin/ZoneDataAdmin";
import AdminExcel from "../Admin/AdminExcel";
import Error from "../Components/Error";
import ViewMapServiceAdmin from "../Admin/ViewMapServiceAdmin";
import ViewlowestRateAdmin from "../Admin/ViewlowestRateAdmin";
import AccountAdmin from "../Admin/AccountAdmin";
import VerifyEmail from "./VerifyEmail";
import Forgetpassword from "./Forgetpassword";
import ViewUploadedRateAdmin from "../Admin/ViewUploadedRateAdmin";
import UploadODAAdmin from "../Admin/UploadODAAdmin";
import OrderViewAdmin from "../Admin/OrderViewAdmin";
import AdminHandler from "../Admin/AdminHandler";
import ViewMapiingData from "../Components/ViewMapingData";
import ManageAddressAdmin from "../Admin/ManageAddreaaAdmin";
import Kycverification from "../Admin/Kyc-verification";
import Spydy from "./Spydy";
import Message from "./Message";
import VendorSettingsAdmin from "../Admin/VendorSettingsAdmin";
import UploadTATAdmin from "../Admin/UploadTATAdmin";
import DownloadRatesheetAdmin from "../Admin/DownloadRatesheetAdmin";
import UserDetailAdmin from "../Admin/UserDetailAdmin";
import VenderInvoice from "../Admin/VenderInvoice";
import VenderSetting from "../Admin/VenderSetting";
import VenderProfileSetting from "../Admin/VenderProfileSetting";
import VenderList from "../Admin/VenderList";
import UserDetails from "../Admin/UserDetails";
import UserOrder from "../Admin/UserOrder";
import UserPayments from "../Admin/UserPayments";
import CustomerOrder from "../Admin/CustomerOrder";
import CustomerList from "../Admin/CustomerList";
import VendorCustomerList from "../Admin/VendorCustomerList";
import PinCodeList from "../Admin/PinCodeList";
import AdminInvoice from "../Admin/AdminInvoice";
import PurchaseInvoice from "../Admin/PurchaseInvoice";
import SalesInvoice from "../Admin/SalesInvoice";
import OrderBooking from "../Admin/OrderBooking";
import UploadBulkOrders from "../Admin/UploadBulkOrders";
import SMSProvider from "../Admin/SMSProvider";
import PurchaseReport from "../Admin/PurchaseReport";
import SalesReport from "../Admin/SalesReport";
import Review from "../Admin/Review";
import ReviewList from "../Admin/ReviewList";
import Notifications from "../Admin/Notifications";
import Onboarding from "../Admin/Template/Onboarding";
import CustomerOrderPlaced from "../Admin/Template/CustomerOrderPlaced";
import VendorOrderPlaced from "../Admin/Template/VendorOrderPlaced";
import OrderStatus from "../Admin/Template/OrderStatus";
import OutstandingReminder from "../Admin/Template/OutstandingReminder";
import PaymentConfirmation from "../Admin/Template/PaymentConfirmation";
import RolesAssigned from "../Admin/Roles/RolesAssigned";
import UserRegistration from "../Admin/UserRegistration/UserRegistration";
import Barcode from "../Admin/Barcode/Barcode";
import ScannedDetails from "../Admin/ScannedDetails/ScannedDetails";
import PickedOrder from "../Admin/ScannedDetails/PickedOrder";
import BarcodeAdmin from "../Admin/BarcodeAdmin/Barcode"
import PaymentSuccess from "../Admin/PaymentSuccess/PaymentSuccessful";
import PaymentFailed from "../Admin/PaymentFailed/PaymentFailure";
import TeamPage from "./TeamPage";
import DailyMis from "../Admin/Template/Mis";
import DailyRequirement from "../Admin/Template/DailyRequirement";
import { Helmet } from "react-helmet";
import Blogs from "./Blogs";
import Faqs from "./Faqs";
import AdminCourierExcel from "../Admin/AdminCourierExcel";
import ViewUploadedCourierRateAdmin from "../Admin/ViewUploadedCourierRateAdmin";
import TeamAdvisor from "./TeamAdvisor";
import CustomerKyc from "../Admin/kycVerification";
import CreateMetroAdmin from "../Admin/CreateMetroAdmin";
import MetroDataAdmin from "../Admin/MetroDataAdmin";
import SpecialZoneDataAdmin from "../Admin/SpecialZoneDataAdmin";
import CreateSpecialZoneAdmin from "../Admin/CreateSpecialZoneAdmin";
import CustomiseLabel from "./CustomiseLabel";
import CustomiseLabelParent from "./CustomiseLabelParent";
import WeightReconcillation from "./WeightReconcillation";
import WeightReconcillationParent from "./WeightReconcillationParent";
import ApiDocumentation from "./ApiDocumentation";
import ApiDoc from "./ApiDoc";
import DiscrepancyAdd from "../Admin/DiscrepancyAdd";
import DiscrepancyLabel from "../Admin/DiscrepancyLabel";
import DisputeDiscrepancyLabel from "../Admin/DisputeDiscrepancyLabel";
import UploadBulkOrdersB2C from "../Admin/UploadBulkOrdersB2C";
import UploadBulkOrdersCustomer from "../Admin/UploadBulkOrdersCustomer";
import UploadBulkOrdersCustomerB2C from "../Admin/UploadBulkOrderCustomerB2C";
import MetroNonServiceableAdmin from "../Admin/MetroNonServiceableAdmin";
import CreateMetroNonServiceableAdmin from "../Admin/CreateMetroNonServiceableAdmin";
const Routing = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://mvload.in/" />
      </Helmet>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/TrackYourOrder" exact element={<TrackYourOrder />} /> */}
        <Route path="/faqs" exact element={<Faqs />} />
        <Route path="/SubmitReview" exact element={<SubmitReview />} />
        <Route path="/Home" exact element={<Home />} />

        <Route path="/Discrepancy/:customerId/:order_id/:lpid/:paymentMode/:chargable_weight/:total_Amount/:discrepancyId/:isB2C/:noOfArticles" exact element={<DiscrepancyLabel />} />
        <Route path="/ViewDiscrepancy/:customerId/:order_id/:lpid/:discrepancyId" exact element={<DisputeDiscrepancyLabel />} />

        <Route path="/CustomiseLabel" exact element={<CustomiseLabel />} />
        <Route path="/CustomiseLabelParent" exact element={<CustomiseLabelParent />} />

        <Route path="/kycverification" exact element={<Kycverification />} />
        <Route path="/customerkyc" exact element={<CustomerKyc />} />


        <Route path="/weightreconcilliation" exact element={<WeightReconcillation />} />
        <Route path="/weight" exact element={<WeightReconcillationParent />} />


        <Route path="/zone/:id" exact element={<CreateZoneAdmin />} />
        <Route path="/zone" exact element={<CreateZoneAdmin />} />
        <Route path="/zonedata" exact element={<ZoneDataAdmin />} />


        <Route path="/metro/:id" exact element={<CreateMetroAdmin />} />
        <Route path="/metro" exact element={<CreateMetroAdmin />} />
        <Route path="/metrodata" exact element={<MetroDataAdmin />} />

        <Route path="/metrononserviceable/:id" exact element={<CreateMetroNonServiceableAdmin />} />
        <Route path="/metrononserviceable" exact element={<CreateMetroNonServiceableAdmin />} />
        <Route path="/metrononserviceabledata" exact element={<MetroNonServiceableAdmin />} />



        <Route path="/specialzone/:id" exact element={<CreateSpecialZoneAdmin />} />
        <Route path="/specialzone" exact element={<CreateSpecialZoneAdmin />} />
        <Route path="/specialzonedata" exact element={<SpecialZoneDataAdmin />} />

        <Route path="/api" exact element={<ApiDocumentation />} />
        <Route path="/api-doc" exact element={<ApiDoc />} />



        <Route path="/dashboard" exact element={<HomeAdmin />} />
        <Route path="/profile" exact element={<AdminSetting />} />
        <Route path="/setting" exact element={<UserSetting />} />
        <Route path="/invoices" exact element={<UserInvoices />} />
        <Route path="/reports" exact element={<UserReports />} />
        <Route path="/location" exact element={<Location />} />
        <Route path="/blogs" exact element={<Blogs />} />
        <Route path="/ourteam" exact element={<TeamPage />} />
        <Route path="/ourteamadvisor" exact element={<TeamAdvisor />} />
        <Route path="/searchresults" exact element={<SearchResults />} />
        <Route path="/service" exact element={<CreateSerivce />} />
        <Route path="/mapservicezone/:id" exact element={<MapServiceAdmin />} />
        <Route path="/mapservicezone" exact element={<MapServiceAdmin />} />
        <Route path="/createService" exact element={<CreateServiceAdmin />} />
        <Route path="/uploadrate" exact element={<AdminExcel />} />
        <Route path="/uploadcourierrate" exact element={<AdminCourierExcel />} />
        <Route path="/viewMapService" exact element={<ViewMapServiceAdmin />} />
        <Route path="/viewrate/:viewType" exact element={<ViewlowestRateAdmin />} />
        <Route path="/account" exact element={<AccountAdmin />} />
        <Route path="/verifyemail" exact element={<VerifyEmail />} />
        <Route path="/forgetpassword" exact element={<Forgetpassword />} />
        <Route path="/uploadoda" exact element={<UploadODAAdmin />} />
        <Route path="/orderview" exact element={<OrderViewAdmin />} />
        <Route path="/adminhandler" exact element={<AdminHandler />} />
        <Route path="/viewmapingdata" exact element={<ViewMapiingData />} />
        <Route path="/invoice" exact element={<VenderInvoice />} />
        <Route path="/vsetting" exact element={<VenderSetting />} />
        <Route path="/venderprofile" exact element={<VenderProfileSetting />} />
        <Route path="/VenderList" exact element={<VenderList />} />
        <Route path="/userdetails" exact element={<UserDetails />} />
        <Route path="/viewuploadedrate" exact element={<ViewUploadedRateAdmin />} />
        <Route path="/viewuploadedcourierrate" exact element={<ViewUploadedCourierRateAdmin />} />
        <Route path="/manageaddress" exact element={<ManageAddressAdmin />} />
        <Route path="/Spydy" exact element={<Spydy />} />
        <Route path="/message" exact element={<Message />} />
        <Route path="/settings" exact element={<VendorSettingsAdmin />} />
        <Route path="/uploadtat" exact element={<UploadTATAdmin />} />{" "}
        <Route path="/downloadratesheet" exact element={<DownloadRatesheetAdmin />} />
        <Route path="/alluserdetails/:type" exact element={<UserDetailAdmin />} />
        <Route path="/myorder/:orderType" exact element={<UserOrder />} />
        <Route path="/mypayments" exact element={<UserPayments />} />
        <Route path="/orderslist" exact element={<CustomerOrder />} />
        <Route path="/PurchaseReport" exact element={<PurchaseReport />} />
        <Route path="/SalesReport" exact element={<SalesReport />} />
        <Route path="/customersdetails" exact element={<CustomerList />} />
        <Route path="/vendorcustomerdetails" exact element={<VendorCustomerList />} />
        <Route path="/pincode" exact element={<PinCodeList />} />
        <Route path="/OrderBooking" exact element={<OrderBooking />} />

        <Route path="/UploadBulkOrders" exact element={<UploadBulkOrders />} />
        <Route path="/UploadBulkOrdersB2C" exact element={<UploadBulkOrdersB2C />} />

        <Route path="/UploadBulkOrdersCustomer" exact element={<UploadBulkOrdersCustomer />} />
        <Route path="/UploadBulkOrdersCustomerB2C" exact element={<UploadBulkOrdersCustomerB2C />} />

        <Route path="/SMSProvider" exact element={<SMSProvider />} />
        <Route path="/admininvoice" exact element={<AdminInvoice />} />
        <Route path="/salesinvoice" exact element={<SalesInvoice />} />
        <Route path="/purchaseinvoice" exact element={<PurchaseInvoice />} />
        <Route path="/Review" exact element={<Review />} />
        <Route path="/ReviewList" exact element={<ReviewList />} />
        <Route path="/Notifications" exact element={<Notifications />} />
        <Route path="/RolesAssigned" exact element={<RolesAssigned />} />
        <Route path="/Barcode" exact element={<Barcode />} />
        <Route path="/UserRegistration" exact element={<UserRegistration />} />
        <Route path="/ScannedDetails" exact element={<ScannedDetails />} />
        <Route path="/PickedOrder" exact element={<PickedOrder />} />
        <Route path="/BarcodeAdmin" exact element={<BarcodeAdmin />} />

        <Route path="/payment-successful" exact element={<PaymentSuccess />} />
        <Route path="/payment-cancelled" exact element={<PaymentFailed />} />

        <Route path="/VendorOrderPlaced" exact element={<VendorOrderPlaced />} />

        <Route path="/Notifications:notificationRoute" exact element={<Notifications />} />
        {/* <Route path="/:notification" exact element={<Notifications />} /> */}

        <Route path="/Onboarding" exact element={<Onboarding />} />
        <Route path="/CustomerOrderPlaced" exact element={<CustomerOrderPlaced />} />
        <Route path="/OrderStatus" exact element={<OrderStatus />} />
        <Route path="/OutstandingReminder" exact element={<OutstandingReminder />} />
        <Route path="/PaymentConfirmation" exact element={<PaymentConfirmation />} />
        <Route path="/DailyMis" exact element={<DailyMis />} />
        <Route path="/DailyRequirement" exact element={<DailyRequirement />} />


      </Routes>
    </>
  );
};
export default Routing;
