import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Mistemplate from "./Mistemplate";
import Footer from "./../Footer";
const DailyMis = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Mistemplate />
      <Footer />
    </>
  );
};

export default DailyMis;