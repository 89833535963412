import axios from "axios";
import { CurrentUrl} from "./UrlApi";
let Url = `${CurrentUrl}api/Vendor/`;

export const getStateList = async () => {
  return await fetch(`${CurrentUrl}Pincode/State`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetpincodeByState = async (statename, cityname) => {
  const token = localStorage.getItem("Logkey");
  if (cityname === "") {
    // return await fetch(`${CurrentUrl}Pincode/Getpincode`, {
      return await fetch(`${CurrentUrl}Pincode/GetB2CPincode`, {
      method: "POST",
      body: JSON.stringify({ state: statename }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }).then((data) => data.json());
  } else {
    // return await fetch(`${CurrentUrl}Pincode/Getpincode`, {
      return await fetch(`${CurrentUrl}Pincode/GetB2CPincode`, {
      method: "POST",
      body: JSON.stringify({ state: statename, cityname: cityname }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }).then((data) => data.json());
  }
};

export const GetcityByState = async (Statenm) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${CurrentUrl}Pincode/Getcitynames/${Statenm}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
// export const SaveZone=async (zonename,pincodes)=>{
export const Saveotherb2cmetro = async (zonedata) => {
  const token = localStorage.getItem("Logkey");
    return await axios
      .post(
        `${Url}AddROIRates`,  
        JSON.stringify({
          isOda: zonedata["isoda"],
          tat: zonedata["tat"],
          stdTat: zonedata["stdTat"],
          LpId: zonedata["lpId"],
          zoneType: zonedata["zoneType"],
          serviceId : zonedata["serviceid"]
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `*`,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
};
export const UpdateB2Crates = async (zonedata) => {
  const token = localStorage.getItem("Logkey");
    return await axios
      .post(
        `${Url}UpdateIntracityAndROIRates`,  
        JSON.stringify({
          isOda: zonedata["isoda"],
          tat: zonedata["tat"],
          stdTat: zonedata["stdTat"],
          LpId: zonedata["lpId"],
          zoneType: zonedata["zoneType"],
          serviceId : zonedata["serviceid"]
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `*`,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
	  }
export const GetIntracityAndROIRatesByLpId = async (lpid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetIntracityAndROIRatesByLpId?Lpid=${lpid}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
// export const GetpincodeByLpid = async (zoneId) => {
//   const token = localStorage.getItem("Logkey");
//   return await fetch(`${Url}GetMetroByLp/${zoneId}`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": `*`,
//     },
//   }).then((data) => data.json());
// };
// export const CheckSpecialZonenm = async (zonenm) => {
//   const token = localStorage.getItem("Logkey");
//   return await fetch(`${Url}checkmetro/${zonenm}`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": `*`,
//     },
//   }).then((data) => data.json());
// };
export const DeleteSpecialZone = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios(`${Url}DeleteIntracityAndROIRates?Id=${id}`, {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetIntracityAndROIRatesById = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetIntracityAndROIRatesById?Id=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const autocompletepins = async (pins) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${CurrentUrl}Pincode/autocomplete/${pins}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};