import React from "react";
import { useEffect, useState } from "react";
import LogReg from "./LogReg";
import { Link, animateScroll as scroll } from "react-scroll";
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Box from "@mui/material/Box";
import { CurrentUrl } from "../Services/UrlApi";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
let Url = `${CurrentUrl}Orders/`;

const LogOut = () => {
  window.localStorage.clear();
};

const Navbar = () => {

  const location = useLocation();

  const handleClickroute = () => {
    if (location.pathname === '/searchresults') {
      window.location.href = '/';
    }
  };

  const handleAboutClick = (e) => {
    e.preventDefault();
    window.open("/faqs", "_blank");
  };


  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [User_name, setUname] = useState("");
  // const [trackorder, setTrackorder] = useState("");
  const openDropdown = (e) => { };
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);
  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState("./images/logo-white.png");
  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo("./images/logo.svg");
    } else {
      setNavbarLogo("./images/logo.svg");
    }
  };
  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo);
  });
  function preventDefault(e) {
    e.preventDefault();
  }
  const [open, setOpen] = React.useState(false);
  const handleClickOpenTrack = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    settrackitemstatus([]);
    setSelectedItemId(null);
    setOpen(false);
  };
  const [openfaq, setOpenFQA] = React.useState(false);
  const handleClickOpenFAQ = () => {
    setOpenFQA(true);
  };
  const FAQClose = () => {
    setOpenFQA(false)
  }
  const [trackorder, setTrackorder] = useState("");
  const [orderkey, setOrderkey] = useState("");
  const [trackitemstatus, settrackitemstatus] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null);
  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };
  const handleTrackOrder = async () => {
    const token = localStorage.getItem("Logkey");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(
      `${Url}GetOrderedItemStatuss?orderId=${orderkey}`, requestOptions
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    setSelectedItemId(null);
    if (!trackorder.orderItems || trackorder.orderItems.length === 0) {
      toast("No record found");
      settrackitemstatus([]);
    } else {
      settrackitemstatus([]);
      setOpen(true);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleTrackOrder();
    }
  };
  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
    handleTrackOrderItem(itemId);
  };
  const handleTrackOrderItem = async (itemId) => {
    const token = localStorage.getItem("Logkey");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(
      `${Url}TrackingOrderedItemStatuss?ItemId=${itemId}`, requestOptions
    );
    const itemresponse = await response.json();
    settrackitemstatus(itemresponse);
    if (!itemresponse.status || itemresponse.status.length === 0) {
      toast("No record found");
    } else {
      setOpen(true);
    }
  };
  const formatEta = (eta) => {
    let dateParts;
    if (eta.includes('-')) {
      // Format: DD-MM-YYYY
      dateParts = eta.split(/[- :]/);
    } else {
      // Format: MM/DD/YYYY
      dateParts = eta.split(/[\/ :]/);
      // Swap day and month positions
      [dateParts[0], dateParts[1]] = [dateParts[1], dateParts[0]];
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);
    const date = new Date(year, month, day);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };


  return (
    <>
      <div
        className={click ? "main-container-nav" : ""}
        onClick={() => Close()}
      />
      <nav
        className={navbar ? "navbar-nav-main active" : "navbar-nav-main active"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="container navbar-box">
          <Link style={{ cursor: 'pointer' }} exact to="/" className="nav-logo-nav">
            <img onClick={handleClickroute} src={navbarLogo} className="img-fluid logo-img" alt="" />
          </Link>
          <ul className={click ? "nav-menu-nav active" : "nav-menu-nav"}>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="home"
                className="nav-links-nav"
                onClick={handleClickroute}
              >
                Home
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="about-us"
                className="nav-links-nav"
                onClick={handleClickroute}

              >
                About
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="our-services"
                className="nav-links-nav"
                onClick={handleClickroute}
              >
                Our Services
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="team"
                className="nav-links-nav"
                onClick={handleClickroute}
              >
                Team
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="contact-us"
                className="nav-links-nav"
                onClick={handleClickroute}
              >
                Contact Us
              </Link>

            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="blogs"
                className="nav-links-nav"
                onClick={handleClickroute}
              >
                Blogs
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link className="nav-links-nav" onClick={handleClickOpenFAQ}>
                FAQs
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link className="nav-links-nav" onClick={handleClickOpenTrack}>
                Track
              </Link>
            </li>
            <li className="nav-links-nav-btn">
              {User_name ? (
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="dropdown">
                    <Link
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {
                        <>
                          {" "}
                          <i className="fas fa-user-circle"></i> {User_name}{" "}
                        </>
                      }
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li onClick={LogOut}>
                        <Link className="dropdown-item" id="rangKala">
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary login-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Login/Signup
                </button>
              )}
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
      {/* Modal */}
      <div
        className="modal fade "
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-md modal-dialog-centered ">
          <div className="modal-content modal-ui">
            <div className="modal-header modal-header-border"></div>
            <div className="modal-body">
              <LogReg />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <div className="track-title">
          <h2>Track Order</h2>
          <span>Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket</span>
          <CancelOutlinedIcon className="close-icon" sx={{ fontSize: 27 }} onClick={handleClose} />
        </div>
        <DialogContent className="dialogcontent-ui" style={{ minWidth: "600px" }}>
          <div className="track-input">
            <input
              required
              value={orderkey}
              onChange={handleorderChange}
              onKeyPress={handleEnterKeyPress}
              placeholder="eg MV-896"
            />
            <button onClick={handleTrackOrder}>
              Track
            </button>
          </div>



          <Box >
            <div className="order-info">
              <div className="row order-head">
                {trackorder.orderId && (
                  <div className="col-sm-6">
                    <h4>Order ID: {trackorder.orderId}</h4>
                    <span >Total Box: {trackorder.noOfBoxes}</span>
                    <span>Booked on : {formatEta(trackorder.bookedAt)}</span>
                  </div>
                )}
                {trackorder.eta && (
                  <div className="col-sm-6">
                    <h4 className="text-right">Current status : {trackorder.status}</h4>
                    <span className="text-right">Last Updated : {formatEta(trackorder.statusDate)}</span>
                    <span className="text-right">ETA : {formatEta(trackorder.eta)}</span>
                  </div>
                )}
              </div>
              <div className="order-tabs">
                <ul className="">
                  {trackorder?.orderItems && trackorder.orderItems.map((status, index) => (
                    <li
                      className={selectedItemId === status.itemId ? 'selected-item' : ''}
                      key={status.itemId}
                      onClick={() => handleItemClick(status.itemId)}
                    >{status.itemId}</li>

                  ))}
                </ul>
              </div>
            </div>


            <ul className={`order-status-ui mt-0 ${trackitemstatus.status ? '' : 'hide-box'}`}>
              {trackitemstatus.status && trackitemstatus.status.map((item, statusIndex) => (
                <li key={statusIndex}
                  className={`outside-li ${item.orderItemRemarks.length === 0 ? 'inactive' : 'active'}`}
                >
                  <div className="status-head">
                    <img src={`${process.env.PUBLIC_URL}/images/${item.orderItemStatus.toLowerCase()}.png`}
                      alt="" />

                    <div className="text">
                      <h4>Your order is {item.orderItemStatus}</h4>
                      <span>{item.orderItemRemarks[0]?.statusDate}</span>
                    </div>
                  </div>

                  {item.orderItemRemarks && item.orderItemRemarks.length > 0 && (
                    <>
                      <div className="remark">Remarks</div>
                      <ol className="remarks-ui">
                        {item.orderItemRemarks.map((remark, remarkIndex) => (
                          <li key={remarkIndex}>{remark.remarks}</li>
                        ))}
                      </ol>
                    </>
                  )}
                </li>
              ))}
            </ul>

          </Box>

        </DialogContent>
      </Dialog>

      <Dialog open={openfaq} onClose={FAQClose} maxWidth="md">
        <div className="track-title">
          <h2>FAQ's</h2>
          <CancelOutlinedIcon className="close-icon" sx={{ fontSize: 27 }} onClick={FAQClose} />
        </div>
        <DialogContent>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Why MVIKAS Super Logistics is considered the best logistics company in India?
            </AccordionSummary>
            <AccordionDetails>
              MVIKAS Super Logistics is one of the best logistic service provider companies, offering exceptional reliability, wide networks across India and the globe, and commitment to your satisfaction. As a leading logistics company in India, we ensure you the best freight forwarding services and comprehensive logistics solutions to meet your needs.

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              How do I ensure the timely delivery of my shipments?
            </AccordionSummary>
            <AccordionDetails>
              We ensure you on-time delivery of all our logistics services across India and internationally with our advanced tracking system. To have an accurate shipping track, open our website, click "Track Order," enter the order ID/Invoice Number/ Mvikas Docket / LSP Docket, and click "Track."

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              What is the refund and cancellation policy?
            </AccordionSummary>
            <AccordionDetails>
              If the order isn’t picked then there won’t be any deductions as all of the freight will be refunded completely. If you want to cancel the order after pickup then the forward & reverse charges would be applicable in that case.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              Does MVIKAS Super Logistics offer a payment option for Cash on Delivery (COD)?
            </AccordionSummary>
            <AccordionDetails>
              No, currently we don’t but we’re working on it to provide this payment option in the near future.
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Navbar;
