// apiData.js
const apiEndpoints = [
    {
        method: 'POST',
        title: '/Admin/ActivateNotification',
        description: 'Activate a notification.',
        request: `
POST /Admin/ActivateNotification HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "id": 0,
    "notificationId": 0,
    "notification": "string",
    "notificationRoute": "string",
    "isActive": true
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetNotificationType',
        description: 'Get notification types.',
        request: `
GET /Admin/GetNotificationType HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetUserList',
        description: 'Get a list of users.',
        request: `
GET /Admin/GetUserList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/TrackingOrderStatus',
        description: 'Track order status.',
        request: `
GET /Admin/TrackingOrderStatus HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AddUserNotification',
        description: 'Add notification for a user.',
        request: `
POST /Admin/AddUserNotification HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "notificationId": 0,
    "userId": "string",
    "isActive": true
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AddAndUpdateWeightDiscrepancy',
        description: 'Add or update weight discrepancy.',
        request: `
POST /Admin/AddAndUpdateWeightDiscrepancy HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "custId": "string",
    "lpId": "string",
    "orderId": "string",
    "chargeWeight": "string",
    "totalAmount": "string",
    "additionalWeight": 0,
    "unitType": 0,
    "remarks": "string",
    "isB2C": true,
    "isToPay": true,
    "userId": "string",
    "status": 0
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/ViewDiscrepancy',
        description: 'View discrepancies.',
        request: `
GET /Admin/ViewDiscrepancy HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetWeightDiscrepancy',
        description: 'Get weight discrepancies.',
        request: `
GET /Admin/GetWeightDiscrepancy HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetDiscrepancyRemarks',
        description: 'Get discrepancy remarks.',
        request: `
GET /Admin/GetDiscrepancyRemarks HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AddRemarksAndStatusClosedByAdmin',
        description: 'Add remarks and close discrepancy.',
        request: `
POST /Admin/AddRemarksAndStatusClosedByAdmin HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "remarks": "string",
    "remarksbyId": "string",
    "isClosed": true,
    "discrepencyId": 0,
    "disputeId": 0
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Createrole/{role}',
        description: 'Create a new role.',
        request: `
POST /Admin/Createrole/{role} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "role": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/Getexistingrole',
        description: 'Get existing roles.',
        request: `
GET /Admin/Getexistingrole HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Assignrole',
        description: 'Assign a role to a user.',
        request: `
POST /Admin/Assignrole HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "Email": "string",
    "rolename": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Activeuser/{email}',
        description: 'Activate a user.',
        request: `
POST /Admin/Activeuser/{email} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Revokerole',
        description: 'Revoke a role from a user.',
        request: `
POST /Admin/Revokerole HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string",
    "role": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }
    , {
        method: 'GET',
        title: '/Admin/Getuserrole',
        description: 'Get roles for a user.',
        request: `
GET /Admin/Getuserrole HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetAllUserDetails',
        description: 'Get details of all users.',
        request: `
GET /Admin/GetAllUserDetails HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Deactiveuser',
        description: 'Deactivate a user.',
        request: `
POST /Admin/Deactiveuser HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'DELETE',
        title: '/Admin/Deleterole',
        description: 'Delete a role.',
        request: `
DELETE /Admin/Deleterole HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "role": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/CustomerList',
        description: 'Get a list of customers.',
        request: `
GET /Admin/CustomerList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/OutstandingCustomerList',
        description: 'Get a list of outstanding customers.',
        request: `
GET /Admin/OutstandingCustomerList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/VendorList',
        description: 'Get a list of vendors.',
        request: `
GET /Admin/VendorList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PUT',
        title: '/Admin/DeleteUserVendor',
        description: 'Delete a user vendor.',
        request: `
PUT /Admin/DeleteUserVendor HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetUserBasicDetailsByEmail',
        description: 'Get user basic details by email.',
        request: `
GET /Admin/GetUserBasicDetailsByEmail HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetAllOrderList',
        description: 'Get a list of all orders.',
        request: `
GET /Admin/GetAllOrderList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetSaleOrderList',
        description: 'Get a list of sale orders with pagination and filters.',
        request: `
GET /Admin/GetSaleOrderList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'pageNumber', type: 'integer($int32)', location: 'query' },
            { name: 'pageSize', type: 'integer($int32)', location: 'query' },
            { name: 'search', type: 'string', location: 'query' },
            { name: 'status', type: 'integer($int32)', location: 'query' },
            { name: 'fromdate', type: 'string($date-time)', location: 'query' },
            { name: 'todate', type: 'string($date-time)', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetSaleOrderLists',
        description: 'Get a list of sale orders without parameters.',
        request: `
GET /Admin/GetSaleOrderLists HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetOrdersItemList',
        description: 'Get the list of items in an order.',
        request: `
GET /Admin/GetOrdersItemList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'orderid', type: 'string', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/DeleteOrder',
        description: 'Delete an order.',
        request: `
POST /Admin/DeleteOrder HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderid": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'orderid', type: 'string', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UploadPODFile',
        description: 'Upload a POD file for an order.',
        request: `
POST /Admin/UploadPODFile HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderId": "string",
    "podURL": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'orderId', type: 'string', location: 'query' },
            { name: 'podURL', type: 'string', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetPurchaseOrderList',
        description: 'Get a list of purchase orders.',
        request: `
GET /Admin/GetPurchaseOrderList HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'search', type: 'string', location: 'query' },
            { name: 'status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string($date-time)', location: 'query' },
            { name: 'todate', type: 'string($date-time)', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateCustomerDetails',
        description: 'Update details of a customer.',
        request: `
POST /Admin/UpdateCustomerDetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "id": "string",
  "email": "string",
  "lpName": "string",
  "personNames": "string",
  "mobileNumber": "string",
  "gst": "string",
  "pan": "string",
  "monthlyShipping": "string",
  "dealItem": "string",
  "averageShipmentWeight": "string",
  "cAddressLine1": "string",
  "cAddressLine2": "string",
  "cCity": "string",
  "cState": "string",
  "cZipCode": 0,
  "cCountry": "string",
  "cCompanyDescriptions": "string",
  "custSecondaryEmail": "string",
  "cRemarks": "string",
  "bAddressLine1": "string",
  "bAddressLine2": "string",
  "bCity": "string",
  "bState": "string",
  "bZipCode": 0,
  "bCountry": "string",
  "bRemarks": "string",
  "image": "string",
  "imagePath": "string",
  "companydesc": "string",
  "isToPay": true,
  "toPayCharges": 0
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/ChangePassword',
        description: 'Change password for a user.',
        request: `
POST /Admin/ChangePassword HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "email": "string",
  "currentPassword": "string",
  "newPassword": "string",
  "confirmNewPassword": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AssignBilling',
        description: 'Assign billing details to a customer.',
        request: `
POST /Admin/AssignBilling HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "billing_Type": "string",
  "credit_Limit": 0,
  "billing_Cycle": "string",
  "billing_Day": "string",
  "payment_Cycle": "string",
  "payment_Day": "string",
  "email": "string",
  "remarks": "string",
  "markup": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetBillingDetails',
        description: 'Get billing details for a customer.',
        request: `
GET /Admin/GetBillingDetails HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetInvoice',
        description: 'Get a list of invoices.',
        request: `
GET /Admin/GetInvoice HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string($date-time)', location: 'query' },
            { name: 'todate', type: 'string($date-time)', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetVendorInvoice',
        description: 'Get a list of vendor invoices.',
        request: `
GET /Admin/GetVendorInvoice HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string($date-time)', location: 'query' },
            { name: 'todate', type: 'string($date-time)', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetOfflinePayment',
        description: 'Get offline payment details.',
        request: `
GET /Admin/GetOfflinePayment HTTP/1.1
Host: api.example.com
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'search', type: 'string', location: 'query' }
        ],
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateOfflinePayment',
        description: 'Update offline payment details.',
        request: `
POST /Admin/UpdateOfflinePayment HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "amount": 0,
  "remarks": "string",
  "cust_Email": "string",
  "order_list": [
    {
      "sno": 0,
      "paymentId": 0,
      "orderId": "string",
      "itemName": "string",
      "lpName": "string",
      "taxableAmt": 0,
      "gstAmt": 0,
      "totalAmt": 0
    }
  ]
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateOrderItemStatus',
        description: 'Update the status of an order item.',
        request: `
POST /Admin/UpdateOrderItemStatus HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "itemId": "string",
  "status": [
    {
      "status": 0,
      "remarks": "string"
    }
  ],
  "email": "string",
  "delayReason": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/DeleteAdminBankAccount',
        description: 'Delete a bank account for an admin.',
        request: `
POST /Admin/DeleteAdminBankAccount HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "AccountNo": "string"
}
        `,
        parameters: [
            { name: 'AccountNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetCurrentAdminAccountList',
        description: 'Get the current bank account list for an admin.',
        request: `
GET /Admin/GetCurrentAdminAccountList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'AccountNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/setAdminIsDefault',
        description: 'Set a bank account as default for an admin.',
        request: `
POST /Admin/setAdminIsDefault HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "Email": "string",
    "Accountnumber": "string"
}
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Accountnumber', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetPieChart',
        description: 'Get data for a pie chart.',
        request: `
GET /Admin/GetPieChart HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetOrderStatusList',
        description: 'Get the status list for an order.',
        request: `
GET /Admin/GetOrderStatusList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetStatusTypeList',
        description: 'Get the status type list for an item.',
        request: `
GET /Admin/GetStatusTypeList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'ItemId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetOrderItemStatus',
        description: 'Get the status of an order item.',
        request: `
GET /Admin/GetOrderItemStatus HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'ItemId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetDocketnumber',
        description: 'Get the docket number for an order.',
        request: `
GET /Admin/GetDocketnumber HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateDocketNumber',
        description: 'Update the docket number for an order.',
        request: `
POST /Admin/UpdateDocketNumber HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderid": "string",
    "docketno": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/SendEmailDailyToAdmin',
        description: 'Send a daily email to admin.',
        request: `
GET /Admin/SendEmailDailyToAdmin HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Admin/UnMovedOrdersFrom24Hours',
        description: 'Get orders that have not moved in the last 24 hours.',
        request: `
GET /Admin/UnMovedOrdersFrom24Hours HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/CompleteOrderDetail',
        description: 'Get the complete detail of an order.',
        request: `
GET /Admin/CompleteOrderDetail HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AddNewReviewType',
        description: 'Add a new review type.',
        request: `
POST /Admin/AddNewReviewType HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "ReviewType": "string"
}
        `,
        parameters: [
            { name: 'ReviewType', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateReviewType',
        description: 'Update an existing review type.',
        request: `
POST /Admin/UpdateReviewType HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "rid": 0,
    "ReviewType": "string"
}
        `,
        parameters: [
            { name: 'rid', type: 'integer', location: 'query' },
            { name: 'ReviewType', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetReviewTypeList',
        description: 'Get the list of review types.',
        request: `
GET /Admin/GetReviewTypeList HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/DeleteReviewType',
        description: 'Delete a review type.',
        request: `
POST /Admin/DeleteReviewType HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "id": 0
}
        `,
        parameters: [
            { name: 'id', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/EditReviewType',
        description: 'Edit a review type.',
        request: `
GET /Admin/EditReviewType HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/Add_UpdateSMSProvider',
        description: 'Add or update an SMS provider.',
        request: `
POST /Admin/Add_UpdateSMSProvider HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "smsId": 0,
    "providerName": "string",
    "displayText": "string",
    "smsurl": "string",
    "isActive": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetSMSProviders',
        description: 'Get the list of SMS providers.',
        request: `
GET /Admin/GetSMSProviders HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/ActivateSmsProvider',
        description: 'Activate an SMS provider.',
        request: `
POST /Admin/ActivateSmsProvider HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "smsId": 0,
    "isActive": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/DeleteSMSProviders',
        description: 'Delete an SMS provider.',
        request: `
POST /Admin/DeleteSMSProviders HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "SMSid": 0
}
        `,
        parameters: [
            { name: 'SMSid', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Admin/GetOrderReviews',
        description: 'Get order reviews based on search parameters.',
        request: `
GET /Admin/GetOrderReviews HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'search', type: 'string', location: 'query' },
            { name: 'IsApproved', type: 'boolean', location: 'query' },
            { name: 'fromdate', type: 'string', location: 'query' },
            { name: 'todate', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/ApproveOrderReview',
        description: 'Approve an order review.',
        request: `
POST /Admin/ApproveOrderReview HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "rid": 0
}
        `,
        parameters: [
            { name: 'rid', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/DeleteOrderReview',
        description: 'Delete an order review.',
        request: `
POST /Admin/DeleteOrderReview HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "rid": 0
}
        `,
        parameters: [
            { name: 'rid', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetAssignRoles',
        description: 'Get assigned roles.',
        request: `
GET /Admin/GetAssignRoles HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetAdminRole',
        description: 'Get details of an admin role by role ID.',
        request: `
GET /Admin/GetAdminRole HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'roleid', type: 'integer', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/ActiveAndDeactiveUser',
        description: 'Activate or deactivate a user.',
        request: `
POST /Admin/ActiveAndDeactiveUser HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userid": 0,
    "isActivate": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AdminUserRole',
        description: 'Define a new admin user role with various permissions.',
        request: `
POST /Admin/AdminUserRole HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "roleid": 0,
  "roleName": "string",
  "dashboardView": true,
  "totalNoOfCustomer": true,
  "totalOutstandingPayments": true,
  "totalNoOfVendors": true,
  "totalNoOfOrders": true,
  "totalOrderValue": true,
  "totalCustomerInvoices": true,
  "totalVendorInvoices": true,
  "lineChart": true,
  "pieChart": true,
  "customersList": true,
  "userAccountActiveDeactive": true,
  "userAccountAction": true,
  "vendorList": true,
  "vendorAccountActiveDeactive": true,
  "vendorAccountAction": true,
  "orderReport": true,
  "purchaseOrderReport": true,
  "salesOrderReport": true,
  "salesReportStatus": true,
  "salesReportViewInvoice": true,
  "invoice": true,
  "purchaseInvoice": true,
  "salesInvoice": true,
  "viewUploadedBulkOrders": true,
  "downloadBulkOrders": true,
  "uploadBulkOrders": true,
  "smsProvidersView": true,
  "smsProviderActiveDeactive": true,
  "reviewLists": true,
  "approveReview": true,
  "deleteReview": true,
  "notifications": true,
  "onboardingNotification": true,
  "newOrderPlacedCustomerNotification": true,
  "newOrderPlacedVendorNotification": true,
  "orderStatusNotification": true,
  "outstandingReminderNotification": true,
  "paymentConfirmationNotification": true,
  "adminHandlerView": true,
  "createRole": true,
  "assignRoleView": true,
  "assignRoleAssign": true,
  "roleAssignedView": true,
  "createNewRoleAdmin": true,
  "updateCreatedRoles": true,
  "assignedRoleView": true,
  "assignedRoleToAdmin": true,
  "changeRoleToAdmin": true,
  "activeAssignedRole": true,
  "createAdminUser": true,
  "addAdminUser": true,
  "viewAdminUser": true,
  "updateAdminUserDetails": true,
  "activeAdminUser": true,
  "viewScannerUser": true,
  "approveScannerUser": true,
  "approveScannerUserItems": true,
  "downloadBarcode": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AssignAdminRole',
        description: 'Assign a role to an admin user.',
        request: `
POST /Admin/AssignAdminRole HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string",
    "role": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UserRegistration',
        description: 'Register a new user.',
        request: `
POST /Admin/UserRegistration HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "fname": "string",
    "lname": "string",
    "email": "string",
    "phone": "string",
    "password": "string",
    "role": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Admin/GetRegisteredUsers',
        description: 'Get registered users list.',
        request: `
GET /Admin/GetRegisteredUsers HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetAdminRolesList',
        description: 'Get list of admin roles.',
        request: `
GET /Admin/GetAdminRolesList HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/CustomerLPActivation',
        description: 'Activate or deactivate a customer LP.',
        request: `
POST /Admin/CustomerLPActivation HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "lp": [
        {
            "isActive": true,
            "lpid": "string"
        }
    ],
    "userId": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateCustomerLP',
        description: 'Update details of a customer LP.',
        request: `
POST /Admin/UpdateCustomerLP HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderId": "string",
    "lpId": "string",
    "newLpId": "string",
    "pickuptime": "string",
    "totalAmount": 0,
    "expectedDelivery": "2024-06-19T07:09:28.013Z",
    "vTotalAmount": 0,
    "rate": 0,
    "gst": 0,
    "serviceid": 0,
    "shipment_weight": 0,
    "chargable_weight": 0,
    "shipment_value": 0,
    "cft": 0,
    "divisor": 0,
    "isCod": true,
    "docketcharge": 0,
    "fsc_percent": 0,
    "fsc_amount": 0,
    "v_Fsc_amount": 0,
    "greentax": 0,
    "greentax_amount": 0,
    "min_Chargable_weight": 0,
    "minFreight": 0,
    "minFOV": 0,
    "fovPercentage": 0,
    "fov_amount": 0,
    "minODA": 0,
    "odaPerKG": 0,
    "oda_amount": 0,
    "handlingCharge": 0,
    "codAmount": 0,
    "taxableAmount": 0,
    "v_taxableAmount": 0,
    "gst_Amount": 0,
    "v_gst_Amount": 0,
    "deliveryslot": "string",
    "ratesheetid": 0,
    "vFscpercent": 0,
    "vGreenTax": 0,
    "vGreentaxAmount": 0,
    "vMinChargableWeight": 0,
    "vMinFreight": 0,
    "vMinFOV": 0,
    "vFovPercentage": 0,
    "vFovAmount": 0,
    "vMinODA": 0,
    "vOdaPerKG": 0,
    "vOdaAmount": 0,
    "vHandlingCharge": 0,
    "vCodAmount": 0,
    "vGst": 0,
    "vDocketCharge": 0,
    "vRate": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GenerateAdminDocket',
        description: 'Generate admin docket for an order.',
        request: `
GET /Admin/GenerateAdminDocket?OrderId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetDeliverySlot',
        description: 'Get delivery slot details for an LP.',
        request: `
GET /Admin/GetDeliverySlot?Lpid=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/UpdateBulkOrderItemStatus',
        description: 'Update status of bulk order item.',
        request: `
POST /Admin/UpdateBulkOrderItemStatus HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderId": "string",
    "statusTypeId": 0,
    "statusDate": "2024-06-19T07:09:28.017Z",
    "email": "string",
    "remarks": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetBulkOrderWithStatus',
        description: 'Get bulk order details with status.',
        request: `
GET /Admin/GetBulkOrderWithStatus?OrderId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetPickupslot',
        description: 'Get pickup slot details for an LP.',
        request: `
GET /Admin/GetPickupslot?Lpid=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetLpList',
        description: 'Get list of LPs for a customer ID.',
        request: `
GET /Admin/GetLpList?custId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'custId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Admin/GetLpDetailsTat',
        description: 'Get LP details and Tat information.',
        request: `
GET /Admin/GetLpDetailsTat?Lpid=string&ServiceId=integer&OrderId=string&CustEmail=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' },
            { name: 'ServiceId', type: 'integer', location: 'query' },
            { name: 'OrderId', type: 'string', location: 'query' },
            { name: 'CustEmail', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Admin/GetRateRequest',
        description: 'Get rate request details.',
        request: `
GET /Admin/GetRateRequest?CustId=string&OrderId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'CustId', type: 'string', location: 'query' },
            { name: 'OrderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Admin/AdminDownloadDocket',
        description: 'Download admin docket for an order.',
        request: `
POST /Admin/AdminDownloadDocket HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "OrderNo": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/KYCVerification/UpdateBussinessType',
        description: 'Update business type for KYC verification.',
        request: `
POST /api/KYCVerification/UpdateBussinessType HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userId": "string",
    "bussinessType": "string",
    "customerName": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/UpdateKYCDocuments',
        description: 'Update KYC documents for verification.',
        request: `
POST /api/KYCVerification/UpdateKYCDocuments HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userId": "string",
    "bussinessType": "string",
    "customerName": "string",
    "photoIdentification": "string",
    "documentNumber1": "string",
    "document1Type": "string",
    "document1FrontSide": "string",
    "document1BackSide": "string",
    "document2Type": "string",
    "document2FrontSide": "string",
    "document2BackSide": "string",
    "documentNumber2": "string",
    "companyType": "string",
    "gstno": "string",
    "tanNo": "string",
    "aadharOtpClientId": "string",
    "aadharNo": "string",
    "isValidAadhar": true,
    "note": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/UploadKycDocument',
        description: 'Upload KYC document for verification.',
        request: `
POST /api/KYCVerification/UploadKycDocument HTTP/1.1
Host: api.example.com
Content-Type: multipart/form-data

file: binary
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/SendOTPToAadharNo',
        description: 'Send OTP to Aadhar number for verification.',
        request: `
POST /api/KYCVerification/SendOTPToAadharNo HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userId": "string",
    "aadharNo": "string",
    "aadharOtpClientId": "string",
    "otp": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/SubmitAadharNoOTP',
        description: 'Submit OTP for Aadhar number verification.',
        request: `
POST /api/KYCVerification/SubmitAadharNoOTP HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userId": "string",
    "aadharNo": "string",
    "aadharOtpClientId": "string",
    "otp": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/GetUserKYCDetails',
        description: 'Get KYC details for a specific user.',
        request: `
POST /api/KYCVerification/GetUserKYCDetails?UserId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'UserId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/ApprovedorRejectKYCDetails',
        description: 'Approve or reject KYC details by admin.',
        request: `
POST /api/KYCVerification/ApprovedorRejectKYCDetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "userId": "string",
    "isAdminApproved": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/CheckPanCard',
        description: 'Check PAN card details.',
        request: `
POST /api/KYCVerification/CheckPanCard HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "idNumber": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/CheckDrivingLicense',
        description: 'Check driving license details.',
        request: `
POST /api/KYCVerification/CheckDrivingLicense HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "idNumber": "string",
    "dob": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/CheckPassport',
        description: 'Check passport details.',
        request: `
POST /api/KYCVerification/CheckPassport HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "idNumber": "string",
    "dob": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/CheckTAN',
        description: 'Check TAN details.',
        request: `
POST /api/KYCVerification/CheckTAN HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "idNumber": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/KYCVerification/Checkgstin',
        description: 'Check GSTIN details.',
        request: `
POST /api/KYCVerification/Checkgstin HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "idNumber": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetAllOpenedOrder',
        description: 'Get all opened orders.',
        request: `
GET /GetAllOpenedOrder HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetAllOrdersBookedToday',
        description: 'Get all orders booked today.',
        request: `
GET /GetAllOrdersBookedToday HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetAllOrdersPickedUptoday',
        description: 'Get all orders picked up today.',
        request: `
GET /GetAllOrdersPickedUptoday HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetDeliveredAfterEDD',
        description: 'Get all orders delivered after the estimated delivery date.',
        request: `
GET /GetDeliveredAfterEDD HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetEDDLessThan2DaysOrder',
        description: 'Get all orders with an estimated delivery date less than 2 days.',
        request: `
GET /GetEDDLessThan2DaysOrder HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetIntransitOrdersBookedToday',
        description: 'Get all in-transit orders booked today.',
        request: `
GET /GetIntransitOrdersBookedToday HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetRevenueDailyBasis',
        description: 'Get revenue on a daily basis.',
        request: `
GET /GetRevenueDailyBasis HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetUnassignedOriginalLP',
        description: 'Get all unassigned original LPs.',
        request: `
GET /GetUnassignedOriginalLP HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetUNMOVEDLAST24HOURS',
        description: 'Get all orders unmoved in the last 24 hours.',
        request: `
GET /GetUNMOVEDLAST24HOURS HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/MovinAPIs/GetAccessToken',
        description: 'Get access token for API authentication.',
        request: `
POST /api/MovinAPIs/GetAccessToken HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/CreateShipmentAsync',
        description: 'Create a new shipment asynchronously.',
        request: `
POST /api/MovinAPIs/CreateShipmentAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "communication_email": "string",
    "payload": [
        {
            "shipment": {
                "shipment_unique_id": "string",
                "shipment_type": "string",
                "ship_from_account": "string",
                "parent_shipment_number": "string",
                "ship_from_company": "string",
                "ship_from_first_name": "string",
                "ship_from_last_name": "string",
                "ship_from_address_line1": "string",
                "ship_from_address_line2": "string",
                "ship_from_address_line3": "string",
                "ship_from_zipcode": "string",
                "ship_from_email": "string",
                "ship_from_phone": "string",
                "shipment_date": "string",
                "shipment_priority": "string",
                "ship_to_first_name": "string",
                "ship_to_last_name": "string",
                "ship_to_company": "string",
                "ship_to_address_line1": "string",
                "ship_to_address_line2": "string",
                "ship_to_address_line3": "string",
                "ship_to_zipcode": "string",
                "ship_to_email": "string",
                "ship_to_phone": "string",
                "invoice_number": "string",
                "reference_number_1": "string",
                "reference_number_2": "string",
                "package_type": "string",
                "goods_general_description": "string",
                "special_instructions": "string",
                "goods_value": "string",
                "bill_to": "string",
                "billing_account_number": "string",
                "billing_account_zipcode": "string",
                "gst_id": "string",
                "include_insurance": "string",
                "email_notification": "string",
                "mobile_notification": "string",
                "add_adult_signature": "string",
                "cash_on_delivery": "string"
            },
            "package": [
                {
                    "package_unique_id": "string",
                    "length": 0,
                    "width": 0,
                    "height": 0,
                    "weight_actual": 0,
                    "reference_number_1": "string",
                    "reference_number_2": "string",
                    "invoice_number": "string",
                    "identical_package_count": 0
                }
            ]
        }
    ]
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/CreatePickupAsync',
        description: 'Create a new pickup request asynchronously.',
        request: `
POST /api/MovinAPIs/CreatePickupAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "account": "string",
    "pickup_date": "string",
    "pickup_time_start": "string",
    "service_type": "string",
    "address_first_name": "string",
    "address_last_name": "string",
    "address_email": "string",
    "address_phone": "string",
    "address_address_line1": "string",
    "address_address_line2": "string",
    "address_address_line3": "string",
    "address_zipcode": "string",
    "address_city": "string",
    "address_state": "string",
    "pickup_reason": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/PickupUpdateAsync',
        description: 'Update an existing pickup request asynchronously.',
        request: `
POST /api/MovinAPIs/PickupUpdateAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "pickup_request_number": "string",
    "account": "string",
    "pickup_date": "string",
    "pickup_time_start": "string",
    "service_type": "string",
    "address_first_name": "string",
    "address_last_name": "string",
    "address_email": "string",
    "address_phone": "string",
    "address_address_line1": "string",
    "address_address_line2": "string",
    "address_address_line3": "string",
    "address_zipcode": "string",
    "address_city": "string",
    "address_state": "string",
    "pickup_reason": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/PickupCancellationAsync',
        description: 'Cancel an existing pickup request asynchronously.',
        request: `
POST /api/MovinAPIs/PickupCancellationAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "pickup_request_number": "string",
    "account": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/ShipmentTrackingAsync',
        description: 'Track shipment status asynchronously.',
        request: `
POST /api/MovinAPIs/ShipmentTrackingAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "tracking_numbers": [
        "string"
    ]
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/ShipmentLabelAsync',
        description: 'Generate shipment label asynchronously.',
        request: `
POST /api/MovinAPIs/ShipmentLabelAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "shipment_number": "string",
    "account_number": "string",
    "scope": "string",
    "label_type": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/MovinAPIs/ShipmentEPODAsync',
        description: 'Generate electronic proof of delivery asynchronously.',
        request: `
POST /api/MovinAPIs/ShipmentEPODAsync?accessToken=string HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "shipment_number": "string",
    "account_number": "string",
    "type": "string",
    "format": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/Orders/Insertorder',
        description: 'Insert a new order.',
        request: `
POST /Orders/Insertorder HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "paymentId": 0,
  "order_id": "string",
  "lpid": "string",
  "serviceid": 0,
  "frompincode": "string",
  "topincode": "string",
  "shipment_weight": 0,
  "chargable_weight": 0,
  "shipment_value": 0,
  "cft": 0,
  "divisor": 0,
  "isCod": true,
  "rate": 0,
  "docketcharge": 0,
  "fsc_percent": 0,
  "fsc_amount": 0,
  "greentax": 0,
  "greentax_amount": 0,
  "min_Chargable_weight": 0,
  "minFreight": 0,
  "minFOV": 0,
  "fovPercentage": 0,
  "fov_amount": 0,
  "minODA": 0,
  "odaPerKG": 0,
  "oda_amount": 0,
  "handlingCharge": 0,
  "codAmount": 0,
  "taxableAmount": 0,
  "gst": 0,
  "gst_Amount": 0,
  "totalAmount": 0,
  "ratesheetid": 0,
  "orderdimensions": [
    {
      "id": 0,
      "orderid": "string",
      "length": 0,
      "height": 0,
      "breadth": 0,
      "volume_weight": 0,
      "noOfArticles": "string"
    }
  ],
  "customerid": "string",
  "customername": "string",
  "paymentMode": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/Getorders',
        description: 'Get all orders.',
        request: `
GET /Orders/Getorders?Lpid={Lpid}&Fromdate={Fromdate}&Todate={Todate} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' },
            { name: 'Fromdate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'Todate', type: 'string', format: 'date-time', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/Getorderdetails/{orderid}',
        description: 'Get order details by order ID.',
        request: `
GET /Orders/Getorderdetails/{orderid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PATCH',
        title: '/Orders/markasread/{orderid}',
        description: 'Mark an order as read by order ID.',
        request: `
PATCH /Orders/markasread/{orderid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/GetOrderedItemStatuss',
        description: 'Get status of ordered items by order ID.',
        request: `
GET /Orders/GetOrderedItemStatuss?orderId={orderId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/TrackingOrderedItemStatuss',
        description: 'Track status of ordered items by item ID.',
        request: `
GET /Orders/TrackingOrderedItemStatuss?ItemId={ItemId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'ItemId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/TrackingOrderStatus',
        description: 'Track order status by order key.',
        request: `
GET /Orders/TrackingOrderStatus?Orderkey={Orderkey} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Orderkey', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Orders/InsertAdditionalChargesIntoMasters',
        description: 'Insert additional charges into masters.',
        request: `
POST /Orders/InsertAdditionalChargesIntoMasters HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "lpId": "string",
  "details": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Orders/UpdateAdditionalChargesIntoMasters',
        description: 'Update additional charges into masters.',
        request: `
POST /Orders/UpdateAdditionalChargesIntoMasters HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "lpId": "string",
  "details": "string",
  "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Orders/GetAllMasterAdditionalChargers',
        description: 'Get all master additional chargers by LP ID.',
        request: `
GET /Orders/GetAllMasterAdditionalChargers?LpId={LpId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Pincode/State',
        description: 'Get list of states.',
        request: `
GET /Pincode/State HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
[
    "string"
]
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Pincode/Getpincode',
        description: 'Get pincode based on city name and state.',
        request: `
POST /Pincode/Getpincode HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "cityname": "string",
  "state": "string"
}
        `,
        parameters: [],
        response: `
[
    0
]
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Pincode/Getallpin',
        description: 'Get all pincodes.',
        request: `
GET /Pincode/Getallpin HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Pincode/Getcitynames/{statename}',
        description: 'Get city names based on state name.',
        request: `
GET /Pincode/Getcitynames/{statename} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'statename', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Pincode/autocomplete/{pin}',
        description: 'Autocomplete based on pincode.',
        request: `
GET /Pincode/autocomplete/{pin} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'pin', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Pincode/pincodedetail/{pincode}',
        description: 'Get pincode details by pincode.',
        request: `
GET /Pincode/pincodedetail/{pincode} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'pincode', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Pincode/Getpincodelist',
        description: 'Get list of all pincodes.',
        request: `
GET /Pincode/Getpincodelist HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Pincode/GetB2CPincode',
        description: 'Get B2C pincode based on city name and state.',
        request: `
POST /Pincode/GetB2CPincode HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "cityname": "string",
  "state": "string"
}
        `,
        parameters: [],
        response: `
[
    0
]
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/Rate/uploadrate/{ratesheetid}',
        description: 'Upload rate sheet for a specific rate sheet ID.',
        request: `
POST /Rate/uploadrate/{ratesheetid} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "min_Weight": 0,
  "max_Weight": 0,
  "min_Freight": 0,
  "docket_Charge": 0,
  "min_FOV": 0,
  "actual_FOV": 0,
  "minGreenTax": 0,
  "greenTaxperkg": 0,
  "fsc": 0,
  "min_ODA": 0,
  "actual_ODA": 0,
  "min_ODA2": 0,
  "actual_ODA2": 0,
  "handlingChrg": [
    {
      "id": 0,
      "ratesheetid": 0,
      "from": 0,
      "to": 0,
      "unit": "string",
      "rate": 0
    }
  ],
  "gst": 0,
  "cod": 0,
  "cft": 0,
  "divisor": 0,
  "serviceId": 0,
  "fromDate": "string",
  "toDate": "string",
  "rateList": [
    "string"
  ],
  "additionalCharges": "string",
  "minimumODA1": 0,
  "minimumODA2": 0,
  "minimumODA3": 0,
  "odA1PerKg": 0,
  "odA2PerKg": 0,
  "odA3PerKg": 0
}
        `,
        parameters: [
            { name: 'ratesheetid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Rate/UploadB2CRates/{ratesheetid}',
        description: 'Upload B2C rate sheet for a specific rate sheet ID.',
        request: `
POST /Rate/UploadB2CRates/{ratesheetid} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "min_Weight": 0,
  "max_Weight": 0,
  "min_Freight": 0,
  "docket_Charge": 0,
  "min_FOV": 0,
  "actual_FOV": 0,
  "minGreenTax": 0,
  "greenTaxperkg": 0,
  "fsc": 0,
  "min_ODA": 0,
  "actual_ODA": 0,
  "min_ODA2": 0,
  "actual_ODA2": 0,
  "handlingChrg": [
    {
      "id": 0,
      "ratesheetid": 0,
      "from": 0,
      "to": 0,
      "unit": "string",
      "rate": 0
    }
  ],
  "gst": 0,
  "cod": 0,
  "cft": 0,
  "divisor": 0,
  "serviceId": 0,
  "fromDate": "string",
  "toDate": "string",
  "rateListSheet1": [
    {
      "additionalProp1": "string",
      "additionalProp2": "string",
      "additionalProp3": "string"
    }
  ],
  "rateListSheet2": [
    {
      "additionalProp1": "string",
      "additionalProp2": "string",
      "additionalProp3": "string"
    }
  ],
  "additionalCharges": "string",
  "minimumODA1": 0,
  "minimumODA2": 0,
  "minimumODA3": 0,
  "odA1PerKg": 0,
  "odA2PerKg": 0,
  "odA3PerKg": 0
}
        `,
        parameters: [
            { name: 'ratesheetid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Rate/GetrateNew',
        description: 'Get new rate based on various parameters.',
        request: `
POST /Rate/GetrateNew HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "frompin": 0,
  "topin": 0,
  "numberofpiece": 0,
  "chargableweight": 0,
  "shipvalue": 0,
  "cod": true,
  "grosswt": "string",
  "dtotalwt": "string",
  "units": "string",
  "cust_Email": "string",
  "toPay": true,
  "lpId": "string",
  "isChangeLp": true,
  "isB2C": true,
  "rateRquestId": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Rate/Getrates',
        description: 'Get rates based on various parameters.',
        request: `
POST /Rate/Getrates HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "frompin": 0,
  "topin": 0,
  "numberofpiece": 0,
  "chargableweight": 0,
  "shipvalue": 0,
  "cod": true,
  "grosswt": "string",
  "dtotalwt": "string",
  "units": "string",
  "cust_Email": "string",
  "toPay": true,
  "lpId": "string",
  "isChangeLp": true,
  "isB2C": true,
  "rateRquestId": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Rate/Getoutrates',
        description: 'Get outbound rates based on pincode and weight.',
        request: `
POST /Rate/Getoutrates HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "frompin": 0,
  "topin": 0,
  "chargableweight": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Rate/Getratebyservice/{serviceid}',
        description: 'Get rate details by service ID and LP ID.',
        request: `
GET /Rate/Getratebyservice/{serviceid}?LpId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'serviceid', type: 'integer', format: 'int32', location: 'path' },
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Rate/GetB2CRateByService/{serviceid}',
        description: 'Get B2C rate details by service ID and LP ID.',
        request: `
GET /Rate/GetB2CRateByService/{serviceid}?LpId=string HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'serviceid', type: 'integer', format: 'int32', location: 'path' },
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Rate/Getlpdetails/{lpid}',
        description: 'Get LP details by LP ID.',
        request: `
GET /Rate/Getlpdetails/{lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'lpid', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Rate/getratepincodewise',
        description: 'Get rate based on pin code wise parameters.',
        request: `
POST /Rate/getratepincodewise HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "fromPinCode": 0,
  "toPincode": "string",
  "shipmentWt": 0,
  "shipmentValue": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Rate/GetUnitTypes',
        description: 'Get list of unit types for rates.',
        request: `
GET /Rate/GetUnitTypes HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/Service/CreateMap',
        description: 'Create mapping between service ID and list of pincodes.',
        request: `
POST /Service/CreateMap HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "serviceid": 0,
  "pincodes": [
    0
  ]
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getallservices',
        description: 'Get all services.',
        request: `
GET /Service/Getallservices HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Service/CreateService',
        description: 'Create a new service.',
        request: `
POST /Service/CreateService?servicetypeid={servicetypeid} HTTP/1.1
Host: api.example.com
Content-Type: application/json

"string"
        `,
        parameters: [
            { name: 'servicetypeid', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getzonesbyservice/{serviceid}',
        description: 'Get zones by service ID.',
        request: `
GET /Service/Getzonesbyservice/{serviceid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'serviceid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'DELETE',
        title: '/Service/{id}',
        description: 'Delete a service by ID.',
        request: `
DELETE /Service/{id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Servicenameexist/{name}',
        description: 'Check if a service name exists.',
        request: `
GET /Service/Servicenameexist/{name} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'name', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PUT',
        title: '/Service/Activeservice/{id}',
        description: 'Activate a service by ID.',
        request: `
PUT /Service/Activeservice/{id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getpincodebyservices/{serviceid}/{zoneid}',
        description: 'Get pincodes by service ID and zone ID.',
        request: `
GET /Service/Getpincodebyservices/{serviceid}/{zoneid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'serviceid', type: 'integer', format: 'int32', location: 'path' },
            { name: 'zoneid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PUT',
        title: '/Service/Editservice/{id}',
        description: 'Edit a service by ID.',
        request: `
PUT /Service/Editservice/{id} HTTP/1.1
Host: api.example.com
Content-Type: application/json

"string"
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getserviceshavingzone',
        description: 'Get services having zones.',
        request: `
GET /Service/Getserviceshavingzone HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getservicepincodeswithoda/{serviceid}',
        description: 'Get service pincodes with ODA by service ID.',
        request: `
GET /Service/Getservicepincodeswithoda/{serviceid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'serviceid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Service/Updateoda',
        description: 'Update ODA details.',
        request: `
POST /Service/Updateoda HTTP/1.1
Host: api.example.com
Content-Type: application/json

[
  {
    "id": 0,
    "isODA": "string",
    "pincode": 0
  }
]
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Getserviceshavingrate',
        description: 'Get services having rates.',
        request: `
GET /Service/Getserviceshavingrate HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/GetserviceshavingB2Crate',
        description: 'Get services having B2C rates.',
        request: `
GET /Service/GetserviceshavingB2Crate HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Service/Deletemappedpincode',
        description: 'Delete mapped pincode.',
        request: `
POST /Service/Deletemappedpincode HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "serviceid": 0,
  "zoneid": 0,
  "pincode": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Service/Uploadtat',
        description: 'Upload TAT (Transit Time) details.',
        request: `
POST /Service/Uploadtat?oda={oda} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "lpserviceid": 0,
  "ratelist": [
    "string"
  ]
}
        `,
        parameters: [
            { name: 'oda', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Service/Downloadtat/{sid}',
        description: 'Download TAT details by service ID.',
        request: `
GET /Service/Downloadtat/{sid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'sid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/Whatsapp/WhatsApp',
        description: 'Send WhatsApp message.',
        request: `
POST /api/Whatsapp/WhatsApp HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "contactNo": "string",
  "name": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Whatsapp/WhatsAppPayment',
        description: 'Send WhatsApp payment notification.',
        request: `
POST /api/Whatsapp/WhatsAppPayment HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "contactNo": "string",
  "orderId": "string",
  "amount": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Whatsapp/WhatsAppOrderStatusChange',
        description: 'Send WhatsApp order status change notification.',
        request: `
POST /api/Whatsapp/WhatsAppOrderStatusChange HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "contactNo": "string",
  "orderId": "string",
  "orderStatus": "string",
  "bookingDate": "string",
  "noofBoxes": "string",
  "destination": "string",
  "updatedRemark": "string",
  "edd": "string",
  "mVikasLRNO": "string",
  "invoiceNo": "string",
  "consigneeName": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Whatsapp/WhatsAppOrderBooking',
        description: 'Send WhatsApp order booking confirmation.',
        request: `
POST /api/Whatsapp/WhatsAppOrderBooking HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "contactNo": "string",
  "orderId": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Whatsapp/DailyWhatsAppTOCustomer',
        description: 'Send daily WhatsApp message to customer.',
        request: `
POST /api/Whatsapp/DailyWhatsAppTOCustomer HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "contactNo": "string",
  "name": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Whatsapp/SendWhatsAppOrderStatusTest',
        description: 'Send WhatsApp test message for order status change.',
        request: `
POST /api/Whatsapp/SendWhatsAppOrderStatusTest HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "contactNo": "string",
  "orderId": "string",
  "orderStatus": "string",
  "bookingDate": "string",
  "noofBoxes": "string",
  "destination": "string",
  "updatedRemark": "string",
  "edd": "string",
  "mVikasLRNO": "string",
  "invoiceNo": "string",
  "consigneeName": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/Getzonebylp/{Lpid}',
        description: 'Get zone by LP ID.',
        request: `
GET /Getzonebylp/{Lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Getzonebyid/{Zoneid}',
        description: 'Get zone by zone ID.',
        request: `
GET /Getzonebyid/{Zoneid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Zoneid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/Createzone',
        description: 'Create a new zone.',
        request: `
POST /Createzone HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "pincodes": [
    0
  ],
  "zonename": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Getpincodebyzone/{Zoneid}',
        description: 'Get pincodes by zone ID.',
        request: `
GET /Getpincodebyzone/{Zoneid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Zoneid', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PUT',
        title: '/Updatezone',
        description: 'Update zone details.',
        request: `
PUT /Updatezone HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "zoneId": 0,
  "pincodes": [
    0
  ],
  "zonename": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'DELETE',
        title: '/Deletezone/{id}',
        description: 'Delete a zone by ID.',
        request: `
DELETE /Deletezone/{id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/Getcurrentlpzone',
        description: 'Get current LP zones.',
        request: `
GET /Getcurrentlpzone HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/GetB2CZoneByLp',
        description: 'Get B2C zones by LP.',
        request: `
POST /GetB2CZoneByLp HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "lpId": "string",
  "slabs": [
    {
      "id": 0,
      "value": "string",
      "unit": "string"
    }
  ],
  "additionalRanges": [
    {
      "value": "string",
      "unit": "string"
    }
  ],
  "additionalFlatRate": {
    "value": "string",
    "unit": "string"
  }
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/checkname/{name}',
        description: 'Check if a zone name exists.',
        request: `
GET /checkname/{name} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'name', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/api/Vendor/GetVendorOrderlist',
        description: 'Get vendor order list.',
        request: `
GET /api/Vendor/GetVendorOrderlist HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'V_Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'todate', type: 'string', format: 'date-time', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetCustomerList',
        description: 'Get vendor customer list.',
        request: `
GET /api/Vendor/GetCustomerList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'V_Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'todate', type: 'string', format: 'date-time', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/DeleteCustomer',
        description: 'Delete a vendor customer.',
        request: `
POST /api/Vendor/DeleteCustomer HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "Email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetVendorInvoiceList',
        description: 'Get vendor invoice list.',
        request: `
GET /api/Vendor/GetVendorInvoiceList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'todate', type: 'string', format: 'date-time', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetPinCodeList',
        description: 'Get vendor pin code list.',
        request: `
GET /api/Vendor/GetPinCodeList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'todate', type: 'string', format: 'date-time', location: 'query' },
            { name: 'skip', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetDashboardDetails',
        description: 'Get vendor dashboard details.',
        request: `
GET /api/Vendor/GetDashboardDetails HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetVendorBasicDetails',
        description: 'Get vendor basic details.',
        request: `
GET /api/Vendor/GetVendorBasicDetails HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/VendorChangePassword',
        description: 'Change vendor password.',
        request: `
POST /api/Vendor/VendorChangePassword HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "email": "string",
  "currentPassword": "string",
  "newPassword": "string",
  "confirmNewPassword": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetBankList',
        description: 'Get vendor bank list.',
        request: `
GET /api/Vendor/GetBankList HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/Vendor/AddBankDetail',
        description: 'Add bank details for a vendor.',
        request: `
POST /api/Vendor/AddBankDetail HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "accountNo": "string",
  "ifscCode": "string",
  "bankName": "string",
  "beneficiaryName": "string",
  "email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/UpdateBankDetails',
        description: 'Update bank details for a vendor.',
        request: `
POST /api/Vendor/UpdateBankDetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "accountNo": "string",
  "ifscCode": "string",
  "bankName": "string",
  "beneficiaryName": "string",
  "email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/DeleteBankDetails',
        description: 'Delete bank details for a vendor.',
        request: `
POST /api/Vendor/DeleteBankDetails?AccountNo={AccountNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'AccountNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetCurrentAccount',
        description: 'Get current account details for a vendor.',
        request: `
GET /api/Vendor/GetCurrentAccount?AccountNo={AccountNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'AccountNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/setIsDefault',
        description: 'Set bank account as default for a vendor.',
        request: `
POST /api/Vendor/setIsDefault?Email={Email}&Accountnumber={Accountnumber} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Accountnumber', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/PickupSlot',
        description: 'Get pickup slots for a vendor by LP email.',
        request: `
GET /api/Vendor/PickupSlot?LpEmail={LpEmail} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LpEmail', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/PickupslotbyId',
        description: 'Get pickup slot details by ID.',
        request: `
GET /api/Vendor/PickupslotbyId?Id={Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/AddPickupslot',
        description: 'Add a new pickup slot for a vendor.',
        request: `
POST /api/Vendor/AddPickupslot HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "id": 0,
  "slotvalue": "string",
  "lP_Email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/UpdatePickupslot',
        description: 'Update an existing pickup slot for a vendor.',
        request: `
POST /api/Vendor/UpdatePickupslot HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "slotvalue": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/DeletePickupslot',
        description: 'Delete a pickup slot for a vendor.',
        request: `
POST /api/Vendor/DeletePickupslot?id={id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/api/Vendor/Deliveryslot',
        description: 'Get delivery slots for a vendor by LP email.',
        request: `
GET /api/Vendor/Deliveryslot?LpEmail={LpEmail} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LpEmail', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/DeliveryslotbyId',
        description: 'Get delivery slot details by ID.',
        request: `
GET /api/Vendor/DeliveryslotbyId?Id={Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/AddDeliveryslot',
        description: 'Add a new delivery slot for a vendor.',
        request: `
POST /api/Vendor/AddDeliveryslot HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "id": 0,
  "slotvalue": "string",
  "lP_Email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/UpdateDeliveryslot',
        description: 'Update an existing delivery slot for a vendor.',
        request: `
POST /api/Vendor/UpdateDeliveryslot HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "slotvalue": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/DeleteDeliveryslot',
        description: 'Delete a delivery slot for a vendor.',
        request: `
POST /api/Vendor/DeleteDeliveryslot?id={id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/AddRules',
        description: 'Add a new rule for a vendor.',
        request: `
POST /api/Vendor/AddRules HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "rule": "string",
  "lp_Email": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/UpdateRules',
        description: 'Update an existing rule for a vendor.',
        request: `
POST /api/Vendor/UpdateRules HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "id": 0,
  "rule": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetRules',
        description: 'Get all rules for a vendor by LP email.',
        request: `
GET /api/Vendor/GetRules?LP_Email={LP_Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LP_Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetRulesById',
        description: 'Get rule details by ID.',
        request: `
GET /api/Vendor/GetRulesById?id={id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/DeleteRules',
        description: 'Delete a rule for a vendor.',
        request: `
POST /api/Vendor/DeleteRules?id={id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/Vendor/UpdateVendorDetails',
        description: 'Update vendor details.',
        request: `
POST /api/Vendor/UpdateVendorDetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "id": "string",
  "email": "string",
  "lpName": "string",
  "personNames": "string",
  "mobileNumber": "string",
  "gst": "string",
  "pan": "string",
  "monthlyShipping": "string",
  "dealItem": "string",
  "averageShipmentWeight": "string",
  "cAddressLine1": "string",
  "cAddressLine2": "string",
  "cCity": "string",
  "cState": "string",
  "cZipCode": 0,
  "cCountry": "string",
  "cCompanyDescriptions": "string",
  "custSecondaryEmail": "string",
  "cRemarks": "string",
  "bAddressLine1": "string",
  "bAddressLine2": "string",
  "bCity": "string",
  "bState": "string",
  "bZipCode": 0,
  "bCountry": "string",
  "bRemarks": "string",
  "image": "string",
  "imagePath": "string",
  "companydesc": "string",
  "isToPay": true,
  "toPayCharges": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/UploadImagebyVendor',
        description: 'Upload an image for the vendor.',
        request: `
POST /api/Vendor/UploadImagebyVendor HTTP/1.1
Host: api.example.com
Content-Type: multipart/form-data

--boundary
Content-Disposition: form-data; name="imgfile"; filename="example.jpg"
Content-Type: image/jpeg

(binary data)
--boundary--
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/VendorPiechart',
        description: 'Get vendor pie chart data by email.',
        request: `
GET /api/Vendor/VendorPiechart?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetServiceType',
        description: 'Get all service types.',
        request: `
GET /api/Vendor/GetServiceType HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/api/Vendor/CreateMetro',
        description: 'Create a new metro for a vendor.',
        request: `
POST /api/Vendor/CreateMetro HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "lpId": "string",
  "pincodes": [
    0
  ],
  "zoneType": 0,
  "metroName": "string",
  "serviceIds": "string",
  "isOda": "string",
  "odaTat": 0,
  "stdTat": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetMetroByLp/{Lpid}',
        description: 'Get metros by LP ID.',
        request: `
GET /api/Vendor/GetMetroByLp/{Lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetMetroById',
        description: 'Get metro details by name and LP ID.',
        request: `
GET /api/Vendor/GetMetroById?Name={Name}&LpId={LpId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Name', type: 'string', location: 'query' },
            { name: 'LpId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'DELETE',
        title: '/api/Vendor/DeleteMetro/{id}',
        description: 'Delete a metro by ID.',
        request: `
DELETE /api/Vendor/DeleteMetro/{id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'id', type: 'integer', format: 'int32', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/GetcurrentlpMetro',
        description: 'Get current LP metros.',
        request: `
GET /api/Vendor/GetcurrentlpMetro?Lpid={Lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/api/Vendor/checkmetro/{name}',
        description: 'Check if metro name exists.',
        request: `
GET /api/Vendor/checkmetro/{name} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'name', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'PUT',
        title: '/api/Vendor/UpdateMetro',
        description: 'Update metro details.',
        request: `
    PUT /api/Vendor/UpdateMetro HTTP/1.1
    Host: api.example.com
    Content-Type: application/json
    
    {
      "lpId": "string",
      "pincodes": [
        0
      ],
      "zoneType": 0,
      "metroName": "string",
      "serviceIds": "string",
      "isOda": "string",
      "odaTat": 0,
      "stdTat": 0
    }
        `,
        parameters: [],
        response: `
    {
        "code": 200,
        "message": "Success"
    }
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/Vendor/AddROIRates',
        description: 'Add ROI rates.',
        request: `
    POST /api/Vendor/AddROIRates HTTP/1.1
    Host: api.example.com
    Content-Type: application/json
    
    {
      "zoneType": 0,
      "lpId": "string",
      "serviceId": 0,
      "isOda": "string",
      "odaTat": 0,
      "stdtat": 0
    }
        `,
        parameters: [],
        response: `
    {
        "code": 200,
        "message": "Success"
    }
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/api/Vendor/UpdateIntracityAndROIRates',
        description: 'Update intracity and ROI rates.',
        request: `
    POST /api/Vendor/UpdateIntracityAndROIRates HTTP/1.1
    Host: api.example.com
    Content-Type: application/json
    
    {
      "zoneType": 0,
      "lpId": "string",
      "serviceId": 0,
      "isOda": "string",
      "odaTat": 0,
      "stdtat": 0
    }
        `,
        parameters: [],
        response: `
    {
        "code": 200,
        "message": "Success"
    }
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }
    , {
        method: 'POST',
        title: '/api/Vendor/DeleteIntracityAndROIRates',
        description: 'Delete intracity and ROI rates by ID.',
        request: `
POST /api/Vendor/DeleteIntracityAndROIRates?Id={Id} HTTP/1.1
Host: api.example.com
    `,
        parameters: [
            { name: 'Id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
    `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }
    , {
        method: 'GET',
        title: '/api/Vendor/GetIntracityAndROIRatesById',
        description: 'Get intracity and ROI rates by ID.',
        request: `
GET /api/Vendor/GetIntracityAndROIRatesById?Id={Id} HTTP/1.1
Host: api.example.com
    `,
        parameters: [
            { name: 'Id', type: 'integer', format: 'int32', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
    `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }
    , {
        method: 'POST',
        title: '/api/Vendor/GetIntracityAndROIRatesByLpId',
        description: 'Get intracity and ROI rates by LP ID.',
        request: `
POST /api/Vendor/GetIntracityAndROIRatesByLpId HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "Lpid": "string"
}
    `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
    `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'GET',
        title: '/User/Regotp/{number}',
        description: 'Generate OTP for registration.',
        request: `
GET /User/Regotp/{number} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'number', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Resendotp/{number}',
        description: 'Resend OTP for registration.',
        request: `
GET /User/Resendotp/{number} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'number', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Signup',
        description: 'Register a new user.',
        request: `
POST /User/Signup HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "email": "string",
  "mobileno": "string",
  "password": "string",
  "usertype": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Login',
        description: 'Login with username and password.',
        request: `
POST /User/Login HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "username": "string",
  "password": "string",
  "rememberme": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Otplogin/{number}',
        description: 'Login using OTP.',
        request: `
GET /User/Otplogin/{number} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'number', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Checknumber/{number}',
        description: 'Check if a number is registered.',
        request: `
GET /User/Checknumber/{number} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'number', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Getcurrentuserdetail',
        description: 'Get details of the current logged-in user.',
        request: `
GET /User/Getcurrentuserdetail?Id={Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Id', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "name": "string",
        "email": "string",
        "mobileno": "string",
        "usertype": "string"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/SendConfirmEmail',
        description: 'Send confirmation email.',
        request: `
GET /User/SendConfirmEmail HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/ConfirmEmail',
        description: 'Confirm email verification.',
        request: `
POST /User/ConfirmEmail HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": "string",
  "token": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Signout',
        description: 'Sign out the current user.',
        request: `
POST /User/Signout HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }, {
        method: 'POST',
        title: '/User/Forgetpasswordtoken/{email}',
        description: 'Generate token for resetting password.',
        request: `
POST /User/Forgetpasswordtoken/{email} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "email": "string"
}
        `,
        parameters: [
            { name: 'email', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Forgetpassword',
        description: 'Reset password using token.',
        request: `
POST /User/Forgetpassword HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "token": "string",
  "email": "string",
  "password": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Updateprofile',
        description: 'Update user profile details.',
        request: `
POST /User/Updateprofile HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "id": "string",
  "name": "string",
  "c_Address": "string",
  "contact_person_name": "string",
  "mobile_no": "string",
  "business_Address": "string",
  "account_no": "string",
  "ifsc_code": "string",
  "bank_name": "string",
  "benifesory_name": "string",
  "pan": "string",
  "gst": "string",
  "deleted": true,
  "inserttime": "2024-06-19T13:12:51.877Z",
  "updatetime": "2024-06-19T13:12:51.877Z",
  "email": "string",
  "username": "string",
  "emailverified": true,
  "role": [
    "string"
  ],
  "isactive": true,
  "bilingType": "string",
  "updateDate": "string",
  "kycStatus": 0,
  "isDefaultTemplate": 0,
  "templateSize": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Verifyemail/{email}',
        description: 'Verify user email.',
        request: `
GET /User/Verifyemail/{email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'email', type: 'string', location: 'path' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Sendequiry',
        description: 'Send user enquiry.',
        request: `
POST /User/Sendequiry HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "name": "string",
  "mobileno": "string",
  "querytype": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/saveratesettings',
        description: 'Save user rate settings.',
        request: `
POST /User/saveratesettings HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "name": "string",
  "value": "string",
  "lpid": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/Updateratesettings',
        description: 'Update user rate settings.',
        request: `
POST /User/Updateratesettings HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "id": 0,
  "name": "string",
  "value": "string",
  "lpid": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Getratesettings',
        description: 'Get user rate settings.',
        request: `
GET /User/Getratesettings?name={name} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'name', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "id": 0,
        "name": "string",
        "value": "string",
        "lpid": "string"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Getformula',
        description: 'Get user formula details.',
        request: `
GET /User/Getformula HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetAccountType',
        description: 'Get user account type details.',
        request: `
GET /User/GetAccountType?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "accountType": "string"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },  {
        method: 'POST',
        title: '/User/AddBankAccountdetails',
        description: 'Add user bank account details.',
        request: `
POST /User/AddBankAccountdetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "accountNo": "string",
  "ifscCode": "string",
  "bankName": "string",
  "beneficiaryName": "string",
  "email": "string",
  "isdefault": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/UpdateBankAccountdetails',
        description: 'Update user bank account details.',
        request: `
POST /User/UpdateBankAccountdetails HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "code": 0,
  "message": "string",
  "data": "string",
  "data_item": "string",
  "accountNo": "string",
  "ifscCode": "string",
  "bankName": "string",
  "beneficiaryName": "string",
  "email": "string",
  "isdefault": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetBankAccountList',
        description: 'Get user bank account list.',
        request: `
GET /User/GetBankAccountList?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "accountNo": "string",
            "ifscCode": "string",
            "bankName": "string",
            "beneficiaryName": "string",
            "email": "string",
            "isdefault": true
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/DeleteBankAccount',
        description: 'Delete user bank account.',
        request: `
POST /User/DeleteBankAccount HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "AccountNo": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCurrentAccountList',
        description: 'Get current user account list.',
        request: `
GET /User/GetCurrentAccountList?AccountNo={AccountNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'AccountNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "accountNo": "string",
            "ifscCode": "string",
            "bankName": "string",
            "beneficiaryName": "string",
            "email": "string",
            "isdefault": true
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCustomerOrderlist',
        description: 'Get customer order list.',
        request: `
GET /User/GetCustomerOrderlist?Email={Email}&Search={Search}&Status={Status}&fromdate={fromdate}&todate={todate} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', location: 'query' },
            { name: 'todate', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "order": "details"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetOrderItemslist',
        description: 'Get order items list.',
        request: `
GET /User/GetOrderItemslist?orderid={orderid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "orderItem": "details"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetInvoiceList',
        description: 'Get invoice list.',
        request: `
GET /User/GetInvoiceList?Email={Email}&Search={Search}&Status={Status}&fromdate={fromdate}&todate={todate} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Search', type: 'string', location: 'query' },
            { name: 'Status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string', location: 'query' },
            { name: 'todate', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "invoice": "details"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCustomerBasicDetails',
        description: 'Get customer basic details.',
        request: `
GET /User/GetCustomerBasicDetails?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "name": "string",
        "email": "string",
        "phone": "string",
        "address": "string"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/User/CustomerChangePassword',
        description: 'Change customer password.',
        request: `
POST /User/CustomerChangePassword HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "email": "string",
  "currentPassword": "string",
  "newPassword": "string",
  "confirmNewPassword": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetAddressList',
        description: 'Get list of addresses for a customer.',
        request: `
GET /User/GetAddressList?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "sno": 0,
            "address_Id": 0,
            "name": "string",
            "full_address": "string",
            "pincode": "string",
            "state": "string",
            "city": "string",
            "country": "string",
            "phone": "string",
            "email": "string",
            "customerid": "string",
            "isactive": true
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/AddNewAddress',
        description: 'Add a new address for a customer.',
        request: `
POST /User/AddNewAddress HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "address_Id": 0,
  "name": "string",
  "full_address": "string",
  "pincode": "string",
  "state": "string",
  "city": "string",
  "country": "string",
  "phone": "string",
  "email": "string",
  "customerid": "string",
  "isactive": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/UpdateNewAddress',
        description: 'Update an existing address for a customer.',
        request: `
POST /User/UpdateNewAddress HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "sno": 0,
  "address_Id": 0,
  "name": "string",
  "full_address": "string",
  "pincode": "string",
  "state": "string",
  "city": "string",
  "country": "string",
  "phone": "string",
  "email": "string",
  "customerid": "string",
  "isactive": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/User/UpdateCustomerDetails',
        description: 'Update customer details.',
        request: `
    POST /User/UpdateCustomerDetails HTTP/1.1
    Host: api.example.com
    Content-Type: application/json
    
    {
      "code": 0,
      "message": "string",
      "data": "string",
      "data_item": "string",
      "id": "string",
      "email": "string",
      "lpName": "string",
      "personNames": "string",
      "mobileNumber": "string",
      "gst": "string",
      "pan": "string",
      "monthlyShipping": "string",
      "dealItem": "string",
      "averageShipmentWeight": "string",
      "cAddressLine1": "string",
      "cAddressLine2": "string",
      "cCity": "string",
      "cState": "string",
      "cZipCode": 0,
      "cCountry": "string",
      "cCompanyDescriptions": "string",
      "custSecondaryEmail": "string",
      "cRemarks": "string",
      "bAddressLine1": "string",
      "bAddressLine2": "string",
      "bCity": "string",
      "bState": "string",
      "bZipCode": 0,
      "bCountry": "string",
      "bRemarks": "string",
      "image": "string",
      "imagePath": "string",
      "companydesc": "string",
      "isToPay": true,
      "toPayCharges": 0
    }
        `,
        parameters: [],
        response: `
    {
        "code": 200,
        "message": "Success"
    }
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/User/DeleteAddress',
        description: 'Delete a user address by Address_Id.',
        request: `
POST /User/DeleteAddress?Address_Id={Address_Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Address_Id', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCurrentAddress',
        description: 'Get current user address details by Address_Id.',
        request: `
GET /User/GetCurrentAddress?Address_Id={Address_Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Address_Id', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "address": "string",
        "city": "string",
        "state": "string",
        "zip": "string"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetDashboardDetails',
        description: 'Get dashboard details for the user by email.',
        request: `
GET /User/GetDashboardDetails?email={email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "totalOrders": 10,
        "totalPayments": 5,
        "lastLogin": "2024-06-19T12:00:00Z"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetPayments',
        description: 'Get user payments filtered by Email, search parameters, and date range.',
        request: `
GET /User/GetPayments?Email={Email}&search={search}&status={status}&fromdate={fromdate}&todate={todate} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'search', type: 'string', location: 'query' },
            { name: 'status', type: 'string', location: 'query' },
            { name: 'fromdate', type: 'string($date-time)', location: 'query' },
            { name: 'todate', type: 'string($date-time)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "paymentId": 1,
            "amount": 50.0,
            "date": "2024-06-18T12:00:00Z",
            "status": "completed"
        },
        {
            "paymentId": 2,
            "amount": 30.0,
            "date": "2024-06-15T12:00:00Z",
            "status": "pending"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetMVikasAccount',
        description: 'Get M-Vikas account details for the user by Email and Amount.',
        request: `
GET /User/GetMVikasAccount?Email={Email}&Amount={Amount} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Amount', type: 'number($double)', location: 'query', defaultValue: 0 }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "balance": 500.0,
        "transactions": [
            {
                "transactionId": 1,
                "amount": 100.0,
                "type": "credit",
                "date": "2024-06-19T12:00:00Z"
            },
            {
                "transactionId": 2,
                "amount": 50.0,
                "type": "debit",
                "date": "2024-06-18T12:00:00Z"
            }
        ]
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/MVikasAccountSetting',
        description: 'Get M-Vikas account setting details for the user by Email.',
        request: `
GET /User/MVikasAccountSetting?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "dailyLimit": 500.0,
        "transactionLimit": 2000.0
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetPieChart',
        description: 'Get pie chart data for the user by Email.',
        request: `
GET /User/GetPieChart?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "label": "Food",
            "value": 50
        },
        {
            "label": "Clothing",
            "value": 30
        },
        {
            "label": "Electronics",
            "value": 20
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/PickupSlot',
        description: 'Get pickup slots for the user by Lpid.',
        request: `
GET /User/PickupSlot?Lpid={Lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "slotId": 1,
            "slotTime": "09:00 AM - 10:00 AM"
        },
        {
            "slotId": 2,
            "slotTime": "02:00 PM - 03:00 PM"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetDeliverySlot',
        description: 'Get delivery slots for the user by Lpid.',
        request: `
GET /User/GetDeliverySlot?Lpid={Lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "slotId": 1,
            "slotTime": "10:00 AM - 12:00 PM"
        },
        {
            "slotId": 2,
            "slotTime": "03:00 PM - 05:00 PM"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/GenerateInvoice',
        description: 'Generate invoice for an order by OrderNo.',
        request: `
POST /User/GenerateInvoice?OrderNo={OrderNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "invoiceId": 123,
        "amount": 100.0,
        "generatedAt": "2024-06-19T12:00:00Z"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'GET',
        title: '/User/GetGenerateInvoice',
        description: 'Get details of a generated invoice by InvoiceNo.',
        request: `
GET /User/GetGenerateInvoice?InvoiceNo={InvoiceNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'InvoiceNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "invoiceNo": "INV123",
        "amount": 100.0,
        "generatedAt": "2024-06-19T12:00:00Z",
        "status": "paid"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetPaylater',
        description: 'Get pay later details for the user by Email.',
        request: `
GET /User/GetPaylater?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "amount": 50.0,
        "dueDate": "2024-07-01",
        "status": "pending"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/UserLastlogin',
        description: 'Update last login timestamp for the user by Email.',
        request: `
POST /User/UserLastlogin?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetUserLastlogin',
        description: 'Get last login timestamp for the user by Email.',
        request: `
GET /User/GetUserLastlogin?Email={Email} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "lastLogin": "2024-06-19T09:30:00Z"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/setIsDefault',
        description: 'Set an account as default for the user by Email and Accountnumber.',
        request: `
POST /User/setIsDefault?Email={Email}&Accountnumber={Accountnumber} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Email', type: 'string', location: 'query' },
            { name: 'Accountnumber', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetVendorRules',
        description: 'Get vendor rules details by Lpid.',
        request: `
GET /User/GetVendorRules?lpid={lpid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'lpid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "ruleId": 1,
        "ruleName": "Standard Delivery",
        "description": "Standard delivery rules apply."
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetServiceType',
        description: 'Get service types available.',
        request: `
GET /User/GetServiceType HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "serviceTypeId": 1,
            "serviceName": "Express",
            "description": "Express service for quick delivery."
        },
        {
            "serviceTypeId": 2,
            "serviceName": "Standard",
            "description": "Standard service with regular delivery."
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/CheckIsReviewed',
        description: 'Check if a customer has reviewed by customerid.',
        request: `
GET /User/CheckIsReviewed?customerid={customerid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'customerid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "isReviewed": true
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/AddReview',
        description: 'Add a review for an order.',
        request: `
POST /User/AddReview HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "orderid": "ORD123",
    "rating": 5,
    "reviewDesc": "Excellent service!",
    "reviewType": 1,
    "cust_id": "CUST456",
    "lp_id": "LP789"
}
        `,
        parameters: [],
        requestBody: `
{
    "orderid": "string",
    "rating": 0,
    "reviewDesc": "string",
    "reviewType": 0,
    "cust_id": "string",
    "lp_id": "string"
}
        `,
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "reviewId": "REV789"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetOrderReview',
        description: 'Get review details for an order by Orderid.',
        request: `
GET /User/GetOrderReview?Orderid={Orderid} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Orderid', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "reviewId": "REV789",
        "rating": 5,
        "reviewDesc": "Excellent service!",
        "reviewType": "positive",
        "customer": {
            "customerId": "CUST456",
            "name": "John Doe"
        },
        "lp": {
            "lpId": "LP789",
            "name": "Jane Smith"
        }
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'GET',
        title: '/User/GetReviewDetailList',
        description: 'Get list of review details.',
        request: `
GET /User/GetReviewDetailList?lpid={lpid}&sort={sort} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'lpid', type: 'string', location: 'query' },
            { name: 'sort', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": []
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetReviewDetails',
        description: 'Get details of reviews.',
        request: `
GET /User/GetReviewDetails?lpid={lpid}&sort={sort} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'lpid', type: 'string', location: 'query' },
            { name: 'sort', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": []
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/UpdateReview',
        description: 'Update a review.',
        request: `
POST /User/UpdateReview HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "orderid": "string",
  "rating": 0,
  "reviewDesc": "string",
  "reviewType": 0,
  "cust_id": "string",
  "lp_id": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetItemCategories',
        description: 'Get list of item categories.',
        request: `
GET /User/GetItemCategories HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": []
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetItemSubCategories',
        description: 'Get list of item sub-categories.',
        request: `
GET /User/GetItemSubCategories?Id={Id} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Id', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": []
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetItemPackagingType',
        description: 'Get list of item packaging types.',
        request: `
GET /User/GetItemPackagingType?Type={Type} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Type', type: 'integer($int32)', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": []
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/TrackUserLoggingActivity',
        description: 'Track user logging activity.',
        request: `
POST /User/TrackUserLoggingActivity HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "pageName": "string",
  "whatToDO": "string",
  "userId": "string",
  "visitedAt": "string",
  "visitedOutAt": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GenerateDocket',
        description: 'Generate docket for an order.',
        request: `
GET /User/GenerateDocket?OrderId={OrderId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetMvikasDocketNumber',
        description: 'Get Mvikas docket number.',
        request: `
GET /User/GetMvikasDocketNumber HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": "string"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/DownloadLabelAPI',
        description: 'Download label API.',
        request: `
POST /User/DownloadLabelAPI HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "OrderNo": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/User/GenerateMasterDocket',
        description: 'Generate master docket for an order.',
        request: `
POST /User/GenerateMasterDocket?OrderNo={OrderNo} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'OrderNo', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/InitializeWalletMoney',
        description: 'Initialize wallet money for a customer.',
        request: `
POST /User/InitializeWalletMoney HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "customerId": "string",
  "orderId": "string",
  "paymentMethod": 0,
  "transactionAmount": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/AddWalletMoney',
        description: 'Add money to the wallet.',
        request: `
POST /User/AddWalletMoney HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "razorpay_payment_id": "string",
  "razorpay_order_id": "string",
  "razorpay_signature": "string",
  "paymentMethod": 0,
  "customerId": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetWalletPaymentMethod',
        description: 'Retrieve available payment methods for wallet.',
        request: `
GET /User/GetWalletPaymentMethod HTTP/1.1
Host: api.example.com
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetWalletBalance',
        description: 'Get the wallet balance for a user.',
        request: `
GET /User/GetWalletBalance?userId={userId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'userId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "balance": 100.00
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Encrypt',
        description: 'Encrypt a value.',
        request: `
GET /User/Encrypt?value={value} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'value', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": "encrypted_value"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/Decrypt',
        description: 'Decrypt a value.',
        request: `
GET /User/Decrypt?value={value} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'value', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": "decrypted_value"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCustomerOrderedItemStatuss',
        description: 'Get status of ordered items by customer.',
        request: `
GET /User/GetCustomerOrderedItemStatuss?orderId={orderId}&userId={userId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderId', type: 'string', location: 'query' },
            { name: 'userId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "itemId": "item_id_1",
            "status": "shipped"
        },
        {
            "itemId": "item_id_2",
            "status": "delivered"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/CustomerTrackingOrderedItemStatuss',
        description: 'Track status of ordered items by customer.',
        request: `
GET /User/CustomerTrackingOrderedItemStatuss?ItemId={ItemId}&userId={userId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'ItemId', type: 'string', location: 'query' },
            { name: 'userId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": {
        "status": "shipped",
        "estimatedDeliveryDate": "2024-06-25"
    }
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/UpdateTemplate',
        description: 'Update user email template settings.',
        request: `
POST /User/UpdateTemplate HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "email": "string",
  "isDefaultTemplate": 0,
  "templateSize": "string"
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'GET',
        title: '/User/GetCustomerDisputeDiscrepancy',
        description: 'Retrieve customer dispute discrepancies.',
        request: `
GET /User/GetCustomerDisputeDiscrepancy?CustomerId={CustomerId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'CustomerId', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "discrepancyId": 1,
            "orderId": "order_id_1",
            "description": "Discrepancy description",
            "status": "pending"
        },
        {
            "discrepancyId": 2,
            "orderId": "order_id_2",
            "description": "Another discrepancy",
            "status": "resolved"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/User/GetCustomerDisputeRemarks',
        description: 'Retrieve remarks for a specific customer dispute.',
        request: `
GET /User/GetCustomerDisputeRemarks?DiscrepencyId={DiscrepencyId}&DisputeId={DisputeId} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'DiscrepencyId', type: 'integer', location: 'query' },
            { name: 'DisputeId', type: 'integer', location: 'query', defaultValue: 0 }
        ],
        response: `
{
    "code": 200,
    "message": "Success",
    "data": [
        {
            "remarkId": 1,
            "remarks": "Customer accepted the discrepancy.",
            "remarksbyId": "user_id_1",
            "timestamp": "2024-06-19T12:00:00Z"
        },
        {
            "remarkId": 2,
            "remarks": "Dispute resolved after investigation.",
            "remarksbyId": "user_id_2",
            "timestamp": "2024-06-20T10:30:00Z"
        }
    ]
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/User/AcceptDiscrepancyAndDisputeDiscrepancy',
        description: 'Accept or dispute a discrepancy.',
        request: `
POST /User/AcceptDiscrepancyAndDisputeDiscrepancy HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
  "remarks": "string",
  "remarksbyId": "string",
  "isAccept": true,
  "discrepencyId": 0,
  "disputeId": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/LoginAPIAsync',
        description: 'Authenticate user and obtain access token.',
        request: `
POST /LoginAPIAsync HTTP/1.1
Host: api.example.com
Content-Type: application/json

{}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CreateWarehouseAsync',
        description: 'Create a new warehouse.',
        request: `
POST /CreateWarehouseAsync?accessToken={accessToken} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "phone": "string",
    "city": "string",
    "name": "string",
    "pin": "string",
    "address": "string",
    "country": "string",
    "email": "string",
    "registered_name": "string",
    "return_address": "string",
    "return_pin": "string",
    "return_city": "string",
    "return_state": "string",
    "return_country": "string"
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CreateManifestAsync',
        description: 'Create a new manifest.',
        request: `
POST /CreateManifestAsync?accessToken={accessToken} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "ident": "string",
    "pickup_location": "string",
    "dropoff_location": {
        "consignee": "string",
        "address": "string",
        "city": "string",
        "region": "string",
        "zip": "string",
        "phone": "string"
    },
    "d_mode": "string",
    "amount": 0,
    "rov_insurance": true,
    "invoices": [
        {
            "ident": "string",
            "n_value": 0,
            "ewaybill": "string"
        }
    ],
    "weight": 0,
    "suborders": [
        {
            "ident": "string",
            "count": 0,
            "description": "string",
            "waybills": [
                "string"
            ]
        }
    ],
    "dimensions": [
        {
            "length": 0,
            "width": 0,
            "height": 0,
            "count": 0
        }
    ],
    "consignee_gst_tin": "string",
    "seller_gst_tin": "string",
    "cb": {
        "uri": "string",
        "method": "string",
        "authorization": "string",
        "headers": {
            "ocpApimSubscriptionKey": "string"
        }
    }
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/GetManifestAsync',
        description: 'Retrieve details of a manifest by Job ID.',
        request: `
GET /GetManifestAsync?JobId={JobId}&accessToken={accessToken} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'JobId', type: 'string', location: 'query' },
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'PUT',
        title: '/InvoiceAPIAsync',
        description: 'Update invoice details.',
        request: `
PUT /InvoiceAPIAsync?Dcn={Dcn}&Rs={Rs}&Ewbn={Ewbn}&LRNumber={LRNumber}&accessToken={accessToken} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'Dcn', type: 'string', location: 'query' },
            { name: 'Rs', type: 'number', format: 'double', location: 'query' },
            { name: 'Ewbn', type: 'string', location: 'query' },
            { name: 'LRNumber', type: 'string', location: 'query' },
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CancellationShipmentAsync',
        description: 'Cancel a shipment.',
        request: `
POST /CancellationShipmentAsync?accessToken={accessToken} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "waybill": "string",
    "cancellation": true
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CreatePickupRequestAsync',
        description: 'Create a new pickup request.',
        request: `
POST /CreatePickupRequestAsync?accessToken={accessToken} HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "pickupTime": "string",
    "pickupDate": "string",
    "pickupLocation": "string",
    "expectedPackageCount": 0
}
        `,
        parameters: [
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/LabelPDFAsync',
        description: 'Generate label PDF for LRNumber.',
        request: `
POST /LabelPDFAsync?LRNumber={LRNumber}&accessToken={accessToken} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LRNumber', type: 'string', location: 'query' },
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/GenerateLabelPDFAsync',
        description: 'Generate label PDF for LRNumber at specified ApiUrl.',
        request: `
POST /GenerateLabelPDFAsync?LRNumber={LRNumber}&ApiUrl={ApiUrl} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LRNumber', type: 'string', location: 'query' },
            { name: 'ApiUrl', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/ShipmentTrackingAsync',
        description: 'Track shipment details by LRnumber.',
        request: `
GET /ShipmentTrackingAsync?LRnumber={LRnumber}&accessToken={accessToken} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LRnumber', type: 'string', location: 'query' },
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/PODAPIAsync',
        description: 'Retrieve proof of delivery for LRNumber.',
        request: `
GET /PODAPIAsync?LRNumber={LRNumber}&accessToken={accessToken} HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'LRNumber', type: 'string', location: 'query' },
            { name: 'accessToken', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },{
        method: 'POST',
        title: '/CargoPDFAsync',
        description: 'Generate Cargo PDF asynchronously.',
        request: `
    POST /CargoPDFAsync HTTP/1.1
    Host: api.example.com
    Content-Type: application/json
    
    {
        "status": "string",
        "lrn": "string",
        "job_id": "string",
        "success": true,
        "pdf_url": "string"
    }
        `,
        parameters: [
            { name: 'status', description: 'Status of the cargo', type: 'string', location: 'query' },
            { name: 'lrn', description: 'LRN (Logistics Reference Number)', type: 'string', location: 'query' },
            { name: 'job_id', description: 'Job ID related to the cargo', type: 'string', location: 'query' },
            { name: 'success', description: 'Indicator of success for cargo PDF generation', type: 'boolean', location: 'query' },
            { name: 'pdf_url', description: 'URL to access the generated PDF', type: 'string', location: 'query' }
        ],
        response: `
    {
        "code": 200,
        "message": "Success"
    }
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },  {
        method: 'POST',
        title: '/CustomerAPI/LoginQNT',
        description: 'Customer login using username and password.',
        request: `
POST /CustomerAPI/LoginQNT HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "username": "string",
    "password": "string",
    "rememberme": true
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CustomerAPI/BookOrder',
        description: 'Book an order with details including pickup, delivery, and item information.',
        request: `
POST /CustomerAPI/BookOrder HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "email": "string",
    "frompincode": "string",
    "orderNo": "string",
    "invoiceNo": "string",
    "topincode": "string",
    "isCod": true,
    "ewayNo": "string",
    "orderDimensions": [
        {
            "length": "string",
            "height": "string",
            "breadth": "string",
            "volume_weight": 0,
            "noOfBoxes": "string",
            "unit": "string"
        }
    ],
    "itemName": "string",
    "deliveryAddressLine1": "string",
    "deliveryAddressLine2": "string",
    "deliveryCity": "string",
    "deliveryState": "string",
    "deliveryPincode": "string",
    "deliveryPersonName": "string",
    "deliveryPersonContact": "string",
    "pickupPersonName": "string",
    "pickupPersonContact": "string",
    "pickupAddressLine1": "string",
    "pickupAddressLine2": "string",
    "pickupCity": "string",
    "pickupState": "string",
    "pickupPincode": "string",
    "returnCity": "string",
    "returnState": "string",
    "returnPincode": "string",
    "itemInvoice": "string",
    "itemType": "string",
    "itemSubcategory": "string",
    "itemCategory": "string",
    "packagingRequired": "string",
    "shipvalue": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CustomerAPI/DownloadOrderLabel',
        description: 'Download order label for a specific order.',
        request: `
POST /CustomerAPI/DownloadOrderLabel HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "OrderNo": "string"
}
        `,
        parameters: [
            { name: 'OrderNo', type: 'string', location: 'query' }
        ],
        response: `
(Binary data not shown)
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CustomerAPI/RetrieveETA',
        description: 'Retrieve estimated time of arrival (ETA) based on pickup and delivery pin codes.',
        request: `
POST /CustomerAPI/RetrieveETA HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "fromPinCode": 0,
    "toPincode": 0
}
        `,
        parameters: [],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/CustomerAPI/RetrieveOrderStatus',
        description: 'Retrieve order status based on order ID and customer email.',
        request: `
GET /CustomerAPI/RetrieveOrderStatus HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'orderId', type: 'string', location: 'query' },
            { name: 'email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'GET',
        title: '/CustomerAPI/GetOrderedItemStatusForCustomer',
        description: 'Retrieve ordered item status for a customer based on item ID and customer email.',
        request: `
GET /CustomerAPI/GetOrderedItemStatusForCustomer HTTP/1.1
Host: api.example.com
        `,
        parameters: [
            { name: 'ItemId', type: 'string', location: 'query' },
            { name: 'email', type: 'string', location: 'query' }
        ],
        response: `
{
    "code": 200,
    "message": "Success"
}
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    },
    {
        method: 'POST',
        title: '/CustomerAPI/DownloadDocketQNT',
        description: 'Download docket for a specific order.',
        request: `
POST /CustomerAPI/DownloadDocketQNT HTTP/1.1
Host: api.example.com
Content-Type: application/json

{
    "OrderNo": "string"
}
        `,
        parameters: [
            { name: 'OrderNo', type: 'string', location: 'query' }
        ],
        response: `
(Binary data not shown)
        `,
        statusCodes: [
            { code: 200, message: 'Success' }
        ]
    }
    



];

export default apiEndpoints;
