import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import CreateSpecialZone from '../Components/CreateSpecialZone';

const CreateSpecialZoneAdmin = () => {
    const navigate = useNavigate();
    const [userlog, setUserlog] = useState(localStorage.getItem('Logkey'));
    useEffect(() => {
        if (userlog === undefined || userlog === null) {
            navigate("/")
        }
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <CreateSpecialZone />
            {/* <Footer/>   */}
        </>
    )
}

export default CreateSpecialZoneAdmin
