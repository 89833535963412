import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { GetNotificationsUserlist, ActiveorDeactivateUserNotification } from '../../Services/AdminApi'
import { useParams, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

export default function OrderStatusTemplate() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [assignrolesopen, setOpenRole] = useState(false);

  const [userList, setUserList] = useState([]);
  const { notificationid } = useParams();

  // Use useLocation to get the location object, which includes search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationId = searchParams.get('notificationid');

  // Log the notificationId to check if it's correctly retrieved
  console.log('NotificationId:', notificationId || notificationid);

  //  useEffect(() => {
  //    // Fetch user data based on notificationId
  //    GetNotificationsUserlist(notificationId)
  //      .then((response) => setUserList(response))
  //      .catch((error) => console.error('Error fetching user list:', error));
  //  }, [notificationId]);


  useEffect(() => {
    GetNotificationsUserlist(notificationId || notificationid)
      .then((response) => setUserList(response))
      .catch((error) => console.error('Error fetching user list:', error));
  }, [notificationId, notificationid]);

  const handleSwitchChange = async (userId, currentStatus) => {
    console.log(userId, currentStatus);
    const newStatus = !currentStatus;

    try {
      // Update user status via API
      let response = await ActiveorDeactivateUserNotification(notificationId, userId, newStatus);
      if (response.status === 200) {
        toast.success(`User notification updated successfully!`);

        setUserList((prevUsers) =>
          prevUsers.map((user) =>
            user.userId === userId ? { ...user, isActive: newStatus } : user
          )
        );
      }
      else {
        toast.error("An error occurred while updating the user notification status.");
      }
    } catch (error) {
      console.error("Switch change error:", error);
      toast.error(`Error updating user status: ${error.message}`);
    }
  };

  const columnsdata = [
    { field: 'sno', headerName: 'Sr. No.', width: 50, },
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileNo', headerName: 'Mobile No.', width: 150 },
    { field: 'role', headerName: 'Roles', width: 120 },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            onChange={() => handleSwitchChange(params.row.userId, params.row.isActive)}
          />
        )
      },
    },
  ];

  const assignrolesClickOpen = () => {
    setOpenRole(true);
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Order Status</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Order Status</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="content">
        <div className="container-fluid">
          <Card className='custum-shadow py-3 px-3'>
            <CardContent>
              <div style={{ border: "1px solid #ddd", maxWidth: "600px", margin: "auto" }}>
                <table style={{ background: "#f8fbff", width: "100%", maxWidth: "600px", margin: "auto" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center", padding: "40px" }}>
                        <img src='https://i.ibb.co/BrMBCMz/order-status.png' width={350} alt='' />
                        <p style={{ fontSize: "20px", color: "#5d0000", fontWeight: "500", margin: "20px 0 0" }}>Hi, Sonu Sharma</p>
                        <p style={{ fontSize: "28px", margin: "0px 0px 0", color: "#5d0000" }}>Your Order Id #9868</p>
                        <span style={{ display: "block", fontWeight: "600", color: "#000", margin: "5px 0px" }}>Order Status Updated</span>
                        <p style={{ fontSize: "15px", margin: "0px", background: "rgb(93, 0, 0)", borderRadius: "30px", color: "#fff", display: "inline-block", padding: "10px 30px", fontWeight: "600" }}>Booked</p>

                        <p style={{ fontWeight: "500", margin: "20px 0 0", textTransform: "uppercase", letterSpacing: "1px", color: "#000" }}>Note: Your order status is changed.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ background: "#f8fbff", width: "100%", maxWidth: "600px", margin: "auto", borderTop: "1px solid #ddd" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center", padding: "15px 30px" }}>
                        <img src='https://mvload.in/images/logo-pdf.png' alt='' width={80} />
                        <p style={{ fontSize: "13px", color: "#000", fontWeight: "500", margin: "10px 0 0" }}>
                          Mvikas Super Logistic
                          <br />
                          11g, Big Jo's Tower Netaji Subhash Place, Rohini 110034</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </div>
      </section> */}

      <section className="content mt-4">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={userList}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              getRowId={(user) => user.userId}
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>
      <ToastContainer />
      {/*  */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className='delete-alert'>
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to inactive this user?</p>
            <Button onClick={assignrolesClose} variant="contained">Yes</Button>
            <Button onClick={assignrolesClose} autoFocus variant="outlined" className='ml-2'>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}