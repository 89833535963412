import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Uploadtat from "../Components/Uploadtat";
import { useNavigate } from "react-router-dom";

const UploadTATAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header />
      <Sidebar />
      <Uploadtat />
    </>
  );
};

export default UploadTATAdmin;
