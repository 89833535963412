import React from "react";
import Sidebar from "./Sidebar";
import Setting from "./Setting";
import Footer from "./Footer";
const UserSetting = () => {
  return (
    <>
      {/* <Header /> */}
      <Sidebar />
      <Setting />
      <Footer />
    </>
  );
};

export default UserSetting;
