import React, { useState } from "react";
import { Modal, Box,  TextField, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SaveAddress } from "../Services/UserApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddAddress = ({ refreshTable }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [address, setAddress] = useState({
    name: "",
    full_address: "",
    pincode: "",
    state: "",
    country: "",
    phone: "",
    city: "",
  });
  const SaveData = (e) => {
    e.preventDefault();
    if (
      address.name != "" ||
      address.full_address != "" ||
      address.pincode != "" ||
      address.state != "" ||
      address.country != "" ||
      address.phone != "" ||
      address.city != ""
    ) {
      SaveAddress(address).then((res) => {
        if (res.status == 200) {
          setAddress({
            name: "",
            full_address: "",
            pincode: "",
            state: "",
            country: "",
            phone: "",
            city: "",
          });
          setOpen(false);

          toast.success("Address saved successfully");
          refreshTable();
        }
      });
    } else {
      toast.warn("Please enter all mandatory fields");
    }
  };
  const inputFields = (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <TextField
          id="name"
          label="Address Name"
          variant="standard"
          value={address.name}
          name="name"
          onChange={(e) => {
            setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          id="city"
          label="City"
          variant="standard"
          value={address.city}
          name="city"
          onChange={(e) => {
            setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          id="state"
          label="state"
          variant="standard"
          value={address.state}
          name="state"
          onChange={(e) => {
            setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>{" "}
      <Grid item md={6} sm={12}>
        <TextField
          id="country"
          label="Country"
          variant="standard"
          value={address.country}
          name="country"
          onChange={(e) => {
            setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          id="pincode"
          label="Pincode"
          variant="standard"
          value={address.pincode}
          name="pincode"
          onChange={(e) => {
            if (!isNaN(e.target.value))
              setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <TextField
          id="phone"
          label="Mobile No"
          variant="standard"
          value={address.phone}
          name="phone"
          onChange={(e) => {
            if (!isNaN(e.target.value))
              setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          id="full_address"
          label="Full Address"
          multiline
          rows={4}
          fullWidth
          variant="standard"
          value={address.full_address}
          name="full_address"
          onChange={(e) => {
            setAddress({ ...address, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );

  const closeModal = () => {
    setOpen(false);
  };
  return (
    <>
      <button onClick={handleOpen} className="btn btn-outline-primary">
        Add Address
      </button>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalWidth"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <CloseIcon
              onClick={() => closeModal()}
              sx={{ cursor: "pointer", fontSize: "28px", margin: "5px" }}
            />
          </div>

          <Box style={{ width: "100%" }}>
            {inputFields}
            <div>
              <button onClick={SaveData} className="btn btn-primary mt-3">
                {"Save & Submit"}
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddAddress;
