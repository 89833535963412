import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ApiDoc from './ApiDoc';
import Header from "../Admin/Header";
import Sidebar from "../Admin/Sidebar";

const ApiDocumentation = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    // if (userlog === undefined || userlog === null) {
    //   navigate("/");
    // }
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <ApiDoc />
    </>
  );
};

export default ApiDocumentation;
