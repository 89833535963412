import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { CurrentUrl } from "../Services/UrlApi";
import { useNavigate } from "react-router-dom";

const Banner = (prop) => {
  const navigate = useNavigate();
  const [outrate, setoutrate] = useState({
    frompin: "",
    topin: "",
    chargableweight: "",
  });

  const GetOutRates = async (obj) => {
    return await fetch(`${CurrentUrl}Rate/Getoutrates`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  return (
    <div id={prop.sectionId}>
      <section className="banner">
        {/* <img className="video-bg" src="/images/banner-img.jpg" alt="banner" width="100%" height="500px"/> */}

        <div className="container banner-text">
          <div className="row align-items-center">
            <div className="col-lg-6">

              <div className="text">Get Hassle-Free Logistics Solutions Today!</div>

              <div className="info"></div>
            </div>

            <div className="col-lg-4 m-auto">
              <div className="search-form">
                <div className="mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    label="Source"
                    name="frompin"
                    placeholder="Pincode"
                    onChange={(e) => {
                      setoutrate((prevState) => ({
                        ...prevState,
                        frompin: e.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FmdGoodOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    label="Destination"
                    placeholder="Pincode"
                    name="topin"
                    onChange={(e) => {
                      setoutrate((prevState) => ({
                        ...prevState,
                        topin: e.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FmdGoodOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    label="Shipment Weight"
                    placeholder="Weight"
                    name="shipmentweight"
                    onChange={(e) => {
                      setoutrate((prevState) => ({
                        ...prevState,
                        chargableweight: e.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="">
                  <Button
                    onClick={() => {
                      GetOutRates(outrate);
                      navigate("/searchresults", { state: { outrate } });
                    }}
                    href="#/searchresults"
                    variant="contained"
                    className="btn-ui"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
