import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from 'react-select';
import Card from '@mui/material/Card';
import {
  getStateList,
  GetpincodeByState,
  Savemetro,
  Checkmetronm,
  GetmetroByid,
  GetcityByState,
  Updatemetrorates
} from "../Services/MetroApi";
import {
  GetAllService,
} from "../Services/ServiceApi"
import {CreateNonServiceableMetro} from "../Services/VendorApi";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loading from "./Loading";

const CreateMetroNonServiceable = () => {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [metroType, setMetroType] = useState("metro");
  const [statelist, setStatelist] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [metropincode, setmetropincode] = useState([]);
  const [pincodeQuery, setPincodeQuery] = useState("");
  const [selectedpincodeQuery, setselectedpincodeQuery] = useState("");
  const [ddldata, setDdlData] = useState({
    statename: "",
    cityname: "",
  });
  const [data, setmetroData] = useState({
    lpId: localStorage.getItem("user_id"),
    pincodes: [],
  });
  let ddlData;
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    metroname: false,
    pincode: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const isEditMode = id !== undefined && id !== "0";
  const [inputLabel, setInputLabel] = useState('metro');
  const navigate = useNavigate();
  useEffect(() => {
    if (isEditMode) {
      let lpid = localStorage.getItem("user_id")
      GetmetroByid(lpid, id).then((res) => {
        setLoading(false);
        setmetroData({
          ...data,
          ["metroname"]: res.metroName,
          ["isoda"]: res.isOda,
          ["odaTat"]: res.odaTat || 0,
          ["stdTat"]: res.stdTat || 0,
          ["pincodes"]: res.pincodes
          //["zoneType"]: res.zoneType == "Metro" ? 2 : res.zoneType == "Intercity" ? 1 : res.zoneType == "specialzone" ? 3 : 2,
        });
        setDdlData({
          statename: res.stateName, // Set the state name in the dropdown
          // cityname: res.cityName // Set the city name if applicable
        });
        setMetroType(
          res.zoneType === "Metro"
            ? "metro"
            : res.zoneType === "Intercity"
              ? "region"
              : res.zoneType === "SpecialZone"
                ? "specialzone"
                : "metro" // Default to "metro" if the zoneType doesn't match expected values
        );

        GetAllService().then((serviceItems) => {
          setLoading(false);
          // setmetroData.serviceid(items)
          const defaultService = serviceItems.find(service => service.name === res.serviceIds);
          if (defaultService) {
            setSelectedServices([{ value: defaultService.id, label: defaultService.name }]);
            setmetroData(prevData => ({
              ...prevData,
              serviceids: defaultService.id.toString(),
              zoneType:res.zoneType === "Metro"
              ? 2
              : res.zoneType === "Intercity"
                ? 1
                : res.zoneType === "SpecialZone"
                  ? "specialzone"
                  : 3,
                  odaTat:res.odaTat
            }));
          }
        });

        document.getElementById("allPincode").style.display = "block";
        setmetropincode(res.pincodes);
        setPincode(res.pincodes)
      });
      getStateList().then((items) => {
        setStatelist(items);
      });
    } else {
      getStateList().then((items) => {
        setLoading(false);
        setStatelist(items);
      });
      GetAllService().then((serviceItems) => {
        setLoading(false);
        const activeServices = serviceItems.filter(service => service.isactive);
        setServices(activeServices.map(service => ({ value: service.id, label: service.name })));

      });
    }
  }, []);
  ddlData = statelist.sort().map((val, i) => {
    return <option key={i}>{val}</option>;
  });
  const RemovecheckBox = (i) => {
    document.getElementsByName(i).checked = false;
    const newPincode = metropincode.filter((val, indx) => {
      return val == i;
    });
    const newPincode2 = metropincode.filter((val, indx) => {
      return val !== i;
    });
    setmetropincode(newPincode2);

    setPincode([...pincode, newPincode[0]]);
    const name = "pincodes";
    setmetroData({
      ...data,
      [name]: [
        ...data.pincodes.filter((val) => {
          return val !== i;
        }),
      ],
    });
  };
  const onSubmitmetro = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      data.pincodes.length === 0 ||
      ddlData.statename === ""
    ) {
      setLoading(false);
      toast.warning("All fileds are mandatory");
    } else {
      CreateNonServiceableMetro(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success("Record saved successfully");
          navigate("/metrononserviceabledata");
          setmetroData({
            metroid: 0,
            pincodes: [],
          });
          setPincode([]);
          setmetropincode([]);
          setDdlData({
            statename: "",
            cityname: "",
          });
          document.getElementById("allPincode").style.display = "none";
        } else {
          setLoading(false);
          toast.error(res.data);
        }
      });
    }
  };
  const onSubmitUpdatemetro = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      data.pincodes.length === 0 ||
      ddlData.statename === ""
    ) {
      setLoading(false);
      toast.warning("All fileds are mandatory");
    } else {
      Updatemetrorates(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success("Record updated successfully");
          navigate("/metrononserviceabledata");
          setmetroData({
            pincodes: []
          });
          setPincode([]);
          setmetropincode([]);
          setDdlData({
            statename: "",
            cityname: "",
          });
          document.getElementById("allPincode").style.display = "none";
        } else {
          toast.error(res.data);
        }
      });
    }
  };
  const inputEvent = (e) => {
    const { name, value } = e.target;
    setmetroData({ ...data, [name]: value });
   
    if (name === "statename") {
      setPincode([]);
      setDdlData({ statename: value, cityname: "" });
      if (value !== "Select") {
        document.getElementById("allPincode").style.display = "block";
      }
      GetcityByState(value).then((items) => {
        setCitylist(items);
      });
      GetpincodeByState(value, "").then((items) => {
        if (items.length === 0)
          setErrorMessage({
            message: "This state's all pincodes are already mapped with other metro!!",
            metroname: false,
            pincode: true,
          });
        else {
          setErrorMessage({
            message: "",
            pincode: false,
          });
          const newpin = items.filter(function (item) {
            return metropincode.indexOf(item) === -1;
          });
          setPincode(newpin);
        }
      });
    } else if (name === "cityname") {
      setPincode([]);
      setDdlData({ ...ddldata, cityname: value });
      if (value === "All Cities") {
        GetpincodeByState(ddldata.statename, "").then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message: "This state's all pincodes are already mapped with other metro!!",
              metroname: false,
              pincode: true,
            });
          else {
            setErrorMessage({
              message: "",
              pincode: false,
            });
            const newpin = items.filter(function (item) {
              return metropincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      } else {
        GetpincodeByState(ddldata.statename, value).then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message: "This state's all pincodes are already mapped with other metro!!",
              pincode: true,
            });
          else {
            setErrorMessage({
              message: "",
              pincode: false,
            });
            const newpin = items.filter(function (item) {
              return metropincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      }
    }
  };
  const inputCheckEvent = (e) => {
    const newPincode = pincode.filter((val, indx) => {
      return val !== e;
    });
    const name = "pincodes";
    setmetropincode([...metropincode, e]);
    setPincode(newPincode);
    setmetroData({ ...data, [name]: [...data.pincodes, e] });
  };
  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions);
    const serviceIds = selectedOptions.map(option => option.value).join(',');
    setmetroData(prevData => ({
      ...prevData,
      serviceids: serviceIds
    }));
  };
  const handleMetroTypeChange = (event) => {
    const value = event.target.value;
    const zoneTypeMap = {
      metro: 2,
      region: 1,
      specialzone: 3
    };
    setMetroType(value);
    setmetroData(prevData => ({
      ...prevData,
      zoneType: zoneTypeMap[value]
    }));
    setInputLabel(value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setmetroData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <Card className="custum-shadow p-3">
            <div className="d-flex justify-content-between align-items-center wrap-flex">
              <h3>Metro - Non serviceable Pincodes</h3>
              <Link to="/metrononserviceabledata" className="text-white btn btn-info">
                Non serviceable pincodes
              </Link>
              <Link to="/metrodata" className="text-white btn btn-info">
                View metro
              </Link>
            </div>
            <form onSubmit={isEditMode ? onSubmitUpdatemetro : onSubmitmetro}>
              <div className="row">
                <div className="col-md-4 col-sm-6 col-lg-4 mb-3">
                  <span>
                    Select State Name:<span style={{ color: "red" }}>*</span>
                  </span>

                  <select
                    name="statename"
                    className="form-control mb-3 mb-lg-0"
                    onChange={inputEvent}
                    value={ddldata.statename}
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {ddlData}
                  </select>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4 mb-3">
                  <span>
                    Select City Name:<span style={{ color: "red" }}>*</span>
                  </span>

                  <select
                    name="cityname"
                    className="form-control mb-3 mb-lg-0"
                    onChange={inputEvent}
                  >
                    <option value="All Cities">All Cities</option>
                    {citylist.sort().map((val, i) => {
                      return <option key={i}>{val}</option>;
                    })}
                  </select>
                </div>
                {/* PINCODE PINCODE FETCH */}
                <div className="row justify-content-center">
                  <div
                    className="col-12 mt-5 p-0"
                    id="allPincode"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2  ">
                        <h5 className="pl-2">Select Pincode </h5>
                      </div>
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2 ">
                        <h5 className="pl-2">Selected Pincode </h5>
                      </div>
                      <div
                        className="col-md-4 col-6 col-lg-5 p-lg-3 p-0 overflow-scroll mr-0 mr-lg-4"
                        id="pincodeChkbox"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={pincodeQuery}
                            name="pincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setPincodeQuery(e.target.value);
                            }}
                          />
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Move all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  setmetropincode([
                                    ...metropincode.concat(pincode),
                                  ]);
                                  setPincode([]);
                                  setmetroData({
                                    ...data,
                                    pincodes: [
                                      ...data.pincodes.concat(pincode),
                                    ],
                                  });
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>{" "}
                          </IconButton>
                        </span>
                        {pincode
                          .filter((post) => {
                            if (pincodeQuery === "") {
                              return post;
                            } else if (
                              post
                                .toString()
                                .toLowerCase()
                                .includes(pincodeQuery.toString())
                            ) {
                              return post;
                            }
                          })
                          .map((val, i) => (
                            <div
                              className="SelectPin"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1234",
                                padding: "5px 10px",
                                margin: "4px",
                                borderRadius: "15px",
                              }}
                              onClick={(e) => inputCheckEvent(val)}
                              value={data.pincodes}
                              key={i}
                            >
                              {val}
                              <br />
                            </div>
                          ))}
                        {errorMessage.pincode ? errorMessage.message : ""}
                      </div>
                      <div
                        className="col-md-4 col-6 col-lg-5  p-lg-3 p-0 overflow-scroll"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={selectedpincodeQuery}
                            name="selectedpincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setselectedpincodeQuery(e.target.value);
                            }}
                          />
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Remove all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  setPincode([...pincode.concat(metropincode)]);
                                  setmetropincode([]);
                                  setmetroData({ ...data, pincodes: [] });
                                }}
                                style={{
                                  transform: "rotate(180deg)",
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </span>
                        {metropincode
                          .filter((post) => {
                            if (selectedpincodeQuery === "") {
                              return post;
                            } else if (
                              post
                                .toString()
                                .toLowerCase()
                                .includes(selectedpincodeQuery.toString())
                            ) {
                              return post;
                            }
                          })
                          .map((val, i) => (
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1234",
                                padding: "5px 10px",
                                margin: "4px",
                                borderRadius: "15px",
                              }}
                              onClick={(e) => RemovecheckBox(val)}
                              key={i}
                              value={data.pincodes}
                            >
                              <span>{val}</span>
                              <i
                                className="fas fa-times-circle"
                                style={{
                                  color: "red",
                                  float: "right",
                                  paddingTop: "5px",
                                }}
                              ></i>
                              <br />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className=" col-12  col-md-8 col-lg-11 d-flex justify-content-end p-0">
                      <input
                        type="submit"
                        className="btn btn-success  mt-3 mt-lg-3 mb-5 mr-lg-5 ml-2"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
          </Card>
        </div>
      </>
    );
  }
};
export default CreateMetroNonServiceable;
