import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import Loading from "../";
import axios from "axios";
import { CurrentUrl } from "../Services/UrlApi";
import { GetDiscrepencyById, PreviewCalculatedAmount } from "../Services/AdminApi";
let Url = `${CurrentUrl}Admin/`;
const DiscrepancyAdd = () => {
    const navigate = useNavigate()
    const [adminunitdropdown, setadminunitdropdown] = useState([]);
    const [unit, setunit] = useState({
        unit: 0,
    });
    const { customerId, order_id, lpid, paymentMode, chargable_weight, total_Amount, discrepancyId, noOfArticles } = useParams();
    const { isB2C } = useParams();
    const [discrepancy, setAdddiscrepancy] = useState({
        chargeWeight: 0,
        totalAMount: 0,
        additionalWeight: "",
        unit: "1",
        additionalAmountCharged: "",
        remark: ""
    });
    const [loading, setLoading] = useState(false);
    const [discrepancyStatus, setDiscrepancyStatus] = useState("");
    useEffect(() => {
        setadminunitdropdown([{ id: "1", unit: "kg" }, { id: "2", unit: "gram" }]);

        if (discrepancyId != 0) {
            loadDiscrepancyHistory();
        } else {
            setAdddiscrepancy({
                chargeWeight: chargable_weight,
                totalAMount: total_Amount,
                additionalWeight: "",
                unit: "1",
                additionalAmountCharged: "",
                remark: ""
            });
        }
    }, [customerId, order_id, lpid, paymentMode, chargable_weight, total_Amount, discrepancyId, isB2C, noOfArticles]);

    const loadDiscrepancyHistory = () => {
        const userid = localStorage.getItem("user_id");
        var params = {
            "discrepencyId": discrepancyId,
            "orderId": order_id,
            "customerId": customerId,
            "adminId": userid
        }
        GetDiscrepencyById(params)
            .then((result) => {

                setAdddiscrepancy({
                    chargeWeight: result.chargeWeight,
                    totalAMount: result.totalAmount,
                    additionalWeight: result.additionalWeight,
                    remark: result.remarks,
                    additionalAmountCharged: result.additionlaAmountToBeCharged

                });
                const selectedUnit = result.unitType == 1 ? '1' : '2'; // Adjust this according to your API response
                setunit(selectedUnit);

                const status = result.status == 1 ? '1' : result.status == 2 ? '2' : result.status == 3 ? "3" : result.status == 4 ? "4" :
                    result.status == 5 ? "5" : result.status == 6 ? "6" : ""; // Adjust this according to your API response
                setDiscrepancyStatus(status);

            })
            .catch((r) => {
                console.log(r);
            });
    };
    const SaveData = (e) => {
        console.log(`discrepancy`, discrepancy);
        if (discrepancy.chargeWeight !== "") {
            Savediscrepancy(discrepancy).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        toast.success("Record saved successfully");
                        // setAdddiscrepancy.additionalAmountCharged(res.data.data)
                        navigate('/SalesReport')
                    } else {
                        toast.error("Something went wrong");
                    }
                } else {
                    toast.error(res.data);
                }
            })
        } else {
        }
    };
    const Savediscrepancy = async (discrepancy) => {
        const userid = localStorage.getItem("user_id");
        const token = localStorage.getItem("Logkey");
        return await axios
            .post(
                `${Url}AddAndUpdateWeightDiscrepancy`,
                // JSON.stringify({
                //     ChargeWeight: discrepancyId == 0 ? discrepancy["chargeWeight"] + " KG" : discrepancy["chargeWeight"],
                //     TotalAmount: discrepancyId == 0 ? discrepancy["totalAMount"] + " Rs" : discrepancy["totalAMount"],
                //     AdditionalWeight: discrepancy["additionalWeight"],
                //     UnitType: discrepancy["unit"] ? discrepancy["unit"] : unit,
                //     additionalAmountCharged: discrepancy["additionalAmountCharged"],
                //     Remarks: discrepancy["remark"],
                //     UserId: userid,
                //     CustId: customerId,
                //     LpId: lpid,
                //     OrderId: order_id,
                //     IsB2C: (isB2C == "false" ? false : true),
                //     IsToPay: paymentMode == "ToPay" ? true : false,
                //     Status: discrepancyStatus ? discrepancyStatus : null,
                //     discrepancyId: discrepancyId == 0 ? 0 : discrepancyId,
                //     boxes: noOfArticles + ",",
                // }),
                JSON.stringify({
                    chargeWeight: discrepancyId == 0 ? discrepancy["chargeWeight"] + " KG" : discrepancy["chargeWeight"],
                    totalAmount: discrepancyId == 0 ? discrepancy["totalAMount"] + " Rs" : discrepancy["totalAMount"],
                    additionalWeight: discrepancy["additionalWeight"],
                    unitType: discrepancy["unit"] ? discrepancy["unit"] : unit,
                    additionalCharge: discrepancy["additionalAmountCharged"],
                    remarks: discrepancy["remark"],
                    userId: userid,
                    custId: customerId,
                    lpId: lpid,
                    orderId: order_id,
                    isB2C: (isB2C == "false" ? false : true),
                    isToPay: paymentMode == "ToPay" ? true : false,
                    status: discrepancyStatus ? discrepancyStatus : null,
                }),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": `*`,
                    },
                }
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    const PreviewCalculatedAmounts = async () => {
        setLoading(true)
        const userid = localStorage.getItem("user_id");
        var params = {
            custId: customerId,
            lpId: lpid,
            orderId: order_id,
            chargeWeight: discrepancyId == 0 ? discrepancy["chargeWeight"] + " KG" : discrepancy["chargeWeight"],
            totalAmount: discrepancyId == 0 ? discrepancy["totalAMount"] + " Rs" : discrepancy["totalAMount"],
            additionalWeight: discrepancy.additionalWeight,
            unitType: discrepancy["unit"] ? discrepancy["unit"] : unit,
            isB2C: (isB2C == "false" ? false : true),
            isToPay: paymentMode == "ToPay" ? true : false,
            boxes: noOfArticles + ",",
        }
        PreviewCalculatedAmount(params).then((res) => {
            // debugger;
            // const data = res.json()
            if (res.status === 200) {
                // if (res.data.code === 200) {

                setLoading(false)
                setAdddiscrepancy({
                    chargeWeight: chargable_weight,
                    totalAMount: total_Amount,
                    additionalWeight: discrepancy.additionalWeight,
                    unit: discrepancy["unit"] ? discrepancy["unit"] : unit,
                    additionalAmountCharged: res.data > 0 ? res.data : "0",
                    remark: discrepancy["remark"],
                });
                console.log(`params ress`, discrepancy);
                // } else {
                //     toast.error("Something went wrong");
                // }
            } else {
                toast.error(res.data);
            }
        })
    };
    const handleUnitChange = (event) => {
        const selectedUnit = event.target.value;
        console.log(`selectedUnit`, selectedUnit);
        setunit({ ...unit, unit: selectedUnit });
        setAdddiscrepancy({ ...discrepancy, unit: selectedUnit });
    };
    const isReadOnly = discrepancyId != 0;
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="main-title">Discrepancy Label</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <span>Home</span>
                                    </li>
                                    <li className="breadcrumb-item active">Discrepancy Label</li>

                                </ol>
                            </div>
                            <section className="content p-2">
                                <div className="container-fluid">
                                    <Card sx={{ padding: "2rem 1.5rem" }} className="custum-shadow">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-required"
                                                    label="Charge Weight"
                                                    value={discrepancyId == 0 ? discrepancy.chargeWeight + " Kg" : discrepancy.chargeWeight}
                                                    onChange={(e) =>
                                                        setAdddiscrepancy({ ...discrepancy, chargeWeight: e.target.value })
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-required"
                                                    label="Total Amount"
                                                    value={discrepancyId == 0 ? discrepancy.totalAMount + " Rs" : discrepancy.totalAMount}
                                                    onChange={(e) =>
                                                        setAdddiscrepancy({ ...discrepancy, totalAMount: e.target.value })
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <TextField
                                                    fullWidth
                                                    disabled={isReadOnly}
                                                    id="outlined-required"
                                                    type="number"
                                                    label="Additional Weight"
                                                    value={discrepancy.additionalWeight}
                                                    required
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        // if (inputValue) {
                                                        //     console.log('Input value length:', inputValue);
                                                        //     setAdddiscrepancy({ ...discrepancy, additionalWeight: inputValue })
                                                        // }

                                                        if (inputValue === '' || /^[0-9\b]+$/.test(inputValue)) {
                                                            setAdddiscrepancy({ ...discrepancy, additionalWeight: inputValue });
                                                        }

                                                    }}
                                                    InputProps={{
                                                        readOnly: isReadOnly,
                                                    }}
                                                />

                                            </div>


                                            <div className="col-sm-6 mb-4">
                                                <FormControl fullWidth disabled={isReadOnly}>
                                                    <InputLabel id="role-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="role-select-label"
                                                        id="role-select"
                                                        label="Unit"
                                                        value={discrepancyId == 0 ? unit.id : unit}
                                                        onChange={handleUnitChange}
                                                    >
                                                        <MenuItem disabled>Select unit</MenuItem>
                                                        {adminunitdropdown.map((unit) => (
                                                            <MenuItem key={unit.id} value={unit.id}>
                                                                {unit.unit}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-sm-6 mb-4">
                                                <TextField
                                                    fullWidth
                                                    disabled
                                                    id="outlined-required"
                                                    label="Additional Amount to be charged"
                                                    required
                                                    value={discrepancy.additionalAmountCharged}
                                                    // value= "asdasd"
                                                    onChange={(e) =>
                                                        setAdddiscrepancy({ ...discrepancy, additionalAmountCharged: e.target.value })

                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>

                                            {/* {isReadOnly ? (
                                                <div className="col-sm-6 mb-4">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="role-select-label">Status</InputLabel>
                                                        <Select
                                                            labelId="account-type-select-label"
                                                            id="account-type-select"
                                                            label="Account Type"
                                                            value={discrepancyStatus}
                                                            onChange={(event) =>
                                                                setDiscrepancyStatus(event.target.value)
                                                            }
                                                        >
                                                            <MenuItem key="NewWeightDiscrepancy" value="1">
                                                                NewWeightDiscrepancy
                                                            </MenuItem>
                                                            <MenuItem key="DiscrepancyAccepted" value="2">
                                                                DiscrepancyAccepted
                                                            </MenuItem>
                                                            <MenuItem key="DisputeDiscrepancy" value="3">
                                                                DisputeDiscrepancy
                                                            </MenuItem>
                                                            <MenuItem key="DisputeRejectedByCourier" value="4">
                                                                DisputeRejectedByCourier
                                                            </MenuItem>
                                                            <MenuItem key="DisputePending" value="5">
                                                                DisputePending
                                                            </MenuItem>
                                                            <MenuItem key="Closed" value="6">
                                                                Closed
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            ) : null} */}

                                            <div className="col-sm-6 mb-4">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-multiline-flexible"
                                                    label="Remark"
                                                    multiline
                                                    disabled={isReadOnly}
                                                    rows={4}
                                                    value={discrepancy.remark}
                                                    onChange={(e) =>
                                                        setAdddiscrepancy({ ...discrepancy, remark: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {discrepancyId == 0 ?
                                                <Button className="ml-auto" onClick={PreviewCalculatedAmounts} variant="contained">
                                                    {loading ? 'Loading...' : 'Preview'}</Button>
                                                : null}
                                            {/* <Button className="ml-auto" onClick={SaveData} variant="contained">{isReadOnly ? "Update" : "Save"}</Button> */}
                                            {/* {discrepancyId === 0 && (
                                                <Button className="ml-auto" onClick={SaveData} variant="contained">
                                                    Save
                                                </Button>
                                            )} */}
                                            <Button
                                                className="ml-auto"
                                                onClick={SaveData}
                                                variant="contained"
                                                disabled={isReadOnly}
                                            >
                                                {isReadOnly ? "Save" : "Save"}
                                            </Button>

                                        </div>
                                    </Card>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DiscrepancyAdd;
