import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import {
  DeliverySlot,
  GetAddressList,
  AddNewAddress,
  Pickupslot,
  GetcityByState,
  GetStateList,
  walletPayment,
  GetItemCategories,
  GenerateMvikasDocketNumber,
  getAvailableWalletBalance,
  GetFloorData,
  GetFloorDataCharge
} from "../Services/UserApi";
import { useEffect } from "react";
import { red } from "@mui/material/colors";
import axios from "axios";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";
import { PayLater, GetMvikasAccountType, ToPayPayment } from "../Services/UserApi";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import { CurrentUrl } from "../Services/UrlApi";
import { green } from "@mui/material/colors";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import InfoIcon from '@mui/icons-material/Info';
let Url = `${CurrentUrl}User/`;
let paymentUrl = `${CurrentUrl}`
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const styles = {
  title: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
    fontSize: "24px",
    fontWeight: "700",
    letterSpacing: "1px",
    padding: "5px 0",
    color: "#5d0000",
  },
  modalp: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    color: "rgb(0, 0, 0)",
    fontSize: "13px",
  },
  cardui: {
    padding: "10px",
    textAlign: "left",
    boxShadow: "rgb(255 193 7 / 50%) 0px 0px 5px 0px",
    marginBottom: "10px",
    background: "#fff6db",
  },
  CardContent: {
    padding: "0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  fontfamily: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    texttransform: "uppercase",
    fontweight: "600",
    fontSize: "11px",
    color: "rgb(93 0 0)",
    letterSpacing: "0.5px",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  rightside: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    marginLeft: "auto",
    display: "flex",
    fontWeight: "700",
    color: "#000",
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function createRow(rate, cha, doc, fov, fsc, gre, oda, hand) {
  return {
    rate,
    cha,
    doc,
    fov,
    fsc,
    gre,
    oda,
    hand,
  };
}
const rows = [createRow(1, 2, 3, 4, 0, 0, 0, 0)];

export default function Booking() {
  const [otherInformation, setotherInformation] = useState("")
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [usertype, setSelected] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedpickupslot, setSelectedpickupslot] = useState({});
  const [selecteddeliveryslot, setselecteddeliveryslot] = useState({});
  const [pickupslotList, setPickupslotList] = useState([]);
  const [deliveryslotlist, setdeliveryslotlist] = useState([]);
  const [selectedpickupaddress, setSelectedPickupAddress] = useState({});
  const [selecteddeliveryaddress, setSelectedDeliveryAddress] = useState({});
  const [selectedreturnaddress, setSelectedReturnAddress] = useState({
    address1: "",
  });
  const [itemname, setItemname] = useState("");
  const [paylatercomplete, setPaylatercomplete] = useState("");
  const [payLaterDisabled, setPayLaterDisabled] = useState(false);
  const [paynowdata, setPaynowdata] = useState("");
  const [walletpaycomplete, setwalletpaycomplete] = useState("");
  const [topaynowdata, settopaynowdata] = useState({})
  const [invoicenumber, setInvoicenumber] = useState("");
  const [invoicefile, setInvoicefile] = useState("");
  const [remarks, setremarks] = useState("");
  const [itemdesc, setitemdesc] = useState("");
  const [eWayBillNo, seteWayBillNo] = useState("");
  const [transporterName, settransporterName] = useState("");
  const [eWayBillExpDate, seteWayBillExpDate] = useState("");
  const [pickuppersonname, setpickuppersonname] = useState("");
  const [pickupcompanyname, setpickupcompanyname] = useState("")
  const [pickuppersoncontact, setpickuppersoncontact] = useState("");
  const [deliverypersonname, setdeliverypersonname] = useState("");
  const [deliverypersonemail, setdeliverypersonemail] = useState("")
  const [deliverypersoncontact, setdeliverypersoncontact] = useState("");
  const [returnremarks, setreturnremarks] = useState("");
  const [returnpersonname, setreturnpersonname] = useState("");
  const [returnpersoncontact, setreturnpersoncontact] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [type_, setType_] = useState("");
  const [dimensionsdata, setDimensionsData] = useState([
    {
      length: "",
      height: "",
      breadth: "",
      boxes: "",
      volume_weight: "",
      units: "",
    },
  ]);
  const [newaddress, setNewAddress] = useState({
    name: "",
    full_address: "",
    pincode: "",
    state: "",
    city: "",
    country: "India",
    phone: "",
    email: localStorage.getItem("user_email"),
  });
  const [isb2c, setIsb2c] = useState(false);
  const [selectedfloor, setSelectedFloor] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [floorCharge, setFloorCharge] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [floordata, setfloordata] = useState([])
  const [floorcharges, setfloorcharges] = useState("")
  const [paymentpreviewdialogOpen, setpaymentpreviewDialogOpen] = useState(false);
  const [paymentpreviewdialogContent, setpaymentpreviewDialogContent] = useState("");
  const [detailsCompleted, setDetailsCompleted] = useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    if (!detailsCompleted && activeStep === 6) {
      handleInfoClick();
      setDetailsCompleted(true);
    }
  }, [activeStep, detailsCompleted]);

  useEffect(() => {
    getfloordata();
  }, []);

  const getfloordata = () => {
    const lpdata = JSON.parse(localStorage.getItem('lpdata'));
    GetFloorData(lpdata.lpId, lpdata.isb2c)
      .then((result) => {
        setfloordata(result);

      })
      .catch((r) => {
        console.log(r);
      });
  };

  // const getcalculatedfloorcharges = () => {
  //   const boxes = localStorage.getItem('searchdata')
  //   console.log("boxes: " , boxes);
  //   const passedtoboxes = boxes.ratedata.boxes
  //   console.log("passedtoboxes", passedtoboxes);
  //   if (selectedfloor !== "") {
  //     GetFloorDataCharge(selectedfloor, boxes.boxes, isb2c)
  //       .then((result) => {
  //         setfloorcharges(result.appointmentCharge)
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching floor charges:', error);
  //       });
  //   } else {
  //     console.warn('No floor selected.');
  //   }
  // };

  const handleFloorChange = (event) => {
    const selectedFloorId = event.target.value;
    setSelectedFloor(selectedFloorId);
    getcalculatedfloorcharges(selectedFloorId);
  };
  const getcalculatedfloorcharges = (selectedFloorId) => {
    const boxesData = localStorage.getItem('searchdata');
    if (!boxesData) {
      console.warn('No search data found in localStorage.');
      return;
    }
    const searchData = JSON.parse(boxesData);
    const boxes = searchData.ratedata.boxes;
    const isb2c = searchData.b2c;
    const lpdata = JSON.parse(localStorage.getItem('lpdata'));
    // if (selectedfloor !== "") {
    if (selectedFloorId !== "") {
      GetFloorDataCharge(selectedFloorId, boxes, isb2c, lpdata.lpId)
        .then((result) => {
          setfloorcharges(result.floorCharge);
        })
        .catch((error) => {
          console.error('Error fetching floor charges:', error);
        });
    } else {
      console.warn('No floor selected.');
    }
  };



  useEffect(() => {
    const storedTopay = JSON.parse(localStorage.getItem("topay"));
    setTopay(storedTopay);

    const storedIsb2c = JSON.parse(localStorage.getItem("isb2c"));
    console.log("B2C value:", storedIsb2c);
    setIsb2c(storedIsb2c);
  }, []);
  const [topay, setTopay] = useState(false);

  const [generatedmvikasdocketno, setgeneratedmvikasdocketno] = useState("")

  const generatemvikasdocketnumber = () => {
    GenerateMvikasDocketNumber().then((response) => {
      setgeneratedmvikasdocketno(response);
    });

  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setotherInformation(inputValue);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const paylaterdata = {
    email: "",
    totalAmount: "",
    lpName: "",
    taxamount: "",
    rate: "",
    handlingcharge: "",
    gst: "",
  };

  const handleChange1 = (e) => {
    //console.log(e.target.value);
    setSelected(e.target.value);
    //  setUser({ ...user, [e.target.utype]: e.target.value });
  };

  const handlepickupcontactBlur = (e) => {
    const pickupcontact = e.target.value;
    if (pickupcontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (pickupcontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setpickuppersoncontact(pickupcontact);
    }
  };

  const handledeliverycontactBlur = (e) => {
    const deliverycontact = e.target.value;
    if (deliverycontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (deliverycontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setdeliverypersoncontact(deliverycontact);
    }
  };

  const handlereturncontactBlur = (e) => {
    const returncontact = e.target.value;
    if (returncontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (returncontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setreturnpersoncontact(returncontact);
    }
  };

  const [messopen, meassageOpen] = React.useState(false);
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    meassageOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleZipCodeBlur = (e) => {
    const zipvalue = e.target.value;
    if (zipvalue.length < 6) {
      toast.warn("Enter 6 digit zipcode");
    }
  };

  // STEPPER VALIDATIONS
  const handleNext = () => {
    if (activeStep == 2) {
      if (
        selectedpickupaddress.hasOwnProperty("address1") &&
        // selectedpickupaddress.address1 !== selecteddeliveryaddress.address1 &&
        (selectedpickupslot.hasOwnProperty("slotvalue1") ||
          (Array.isArray(pickupslotList) && pickupslotList.length > 0)) &&
        pickuppersonname &&
        pickuppersoncontact
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.warn("Pickup address and Delivery address can not be same");
        if (!Array.isArray(pickupslotList) || pickupslotList.length === 0) {
          toast.warn("Pickup slot list is empty");
        } else {
          toast.warn("Please validate all fields");
        }
      }
    } else if (activeStep == 3) {
      if (
        selecteddeliveryaddress.hasOwnProperty("address1") &&
        selectedpickupaddress.address1 !== selecteddeliveryaddress.address1 &&
        (selecteddeliveryslot.hasOwnProperty("slotvalue2") ||
          (Array.isArray(deliveryslotlist) && deliveryslotlist.length > 0)) &&
        deliverypersonname &&
        deliverypersoncontact
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.warn("Pickup address and Delivery address can not be same");
        if (!Array.isArray(deliveryslotlist) || deliveryslotlist.length === 0) {
          toast.warn("Delivery slot list is empty");
        } else {
          toast.warn("Please validate all fields");
        }
      }
    } else if (activeStep == 4) {
      if (
        selectedreturnaddress.hasOwnProperty("address1") &&
        returnpersonname &&
        returnpersoncontact
        // returnremarks
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        generatemvikasdocketnumber();
      } else {
        toast.warn("Please validate all fields");
      }
    }
    // else if (activeStep == 5) {
    //   if (itemname) {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   } else {
    //     toast.warn("Please validate all fields");
    //   }
    // } else {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }

    else if (activeStep == 5) {
      if (itemname && eWayBillNo && (eWayBillNo === "NA" || eWayBillNo.trim() !== "")) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.warn("Please validate all fields");
        if (!eWayBillNo || eWayBillNo.trim() === "") {
          toast.warn("E-way Bill Number is required. Enter 'NA' if not available.");
        }
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  useEffect(() => {
    if (selectedState) {
      GetcityByState(selectedState).then((result) => {
        setCities(result);
      });
    } else {
      setCities([]);
    }
  }, [selectedState]);

  const handleStatechange = (e) => {
    setSelectedState(e.target.value);
  };
  const handlecitychange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    loadAddressList();
  }, []);

  const loadAddressList = () => {
    setAddressList([]);

    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {

        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const RefreshAddress = (emailVal) => {
    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        console.log("my address list ", result.data);
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addaddress = async (newadd, emailVal) => {
    let addvalidation = true;
    if (
      newadd.name == null ||
      newadd.name == "" ||
      newadd.full_address == null ||
      newadd.full_address == "" ||
      newadd.pincode == null ||
      newadd.pincode == "" ||
      newadd.phone == null ||
      newadd.phone == "" ||
      newadd.state == null ||
      newadd.state == "" ||
      newadd.city == null ||
      newadd.city == ""
    ) {
      toast.warn("Please validate address fields");
      addvalidation = false;
    }
    if (addvalidation) {
      // setLoading(true);

      let respone = await AddNewAddress(newadd);
      setLoading(false);
      if ("status" in respone) {
        if (respone.status == 200) {
          setOpen(false);
          setSuccess(true);
          meassageOpen(true);
          timer.current = window.setTimeout(() => {
            setSuccess(false);
            meassageOpen(false);
            RefreshAddress(emailVal);
          }, 2000);
        }
      }
    }
  };

  const handleitemtypeDownload = () => {
    const link = document.createElement("a");
    link.href = "images/file.pdf";
    link.download = "ItemType.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const [paynow, setpaynowopen] = useState(false);
  const PaynowOpen = () => {
    setpaynowopen(true);
  };

  const paynowClose = () => {
    setpaynowopen(false);
  };


  const [walletpay, setwalletpayopen] = useState(false);
  const walletpayOpen = () => {
    setwalletpayopen(true);
  };
  const walletpaydialogClose = () => {
    setwalletpayopen(false);
  };





  const [topaynow, settopaynowopen] = useState(false);
  const toPaynowOpen = () => {
    settopaynowopen(true);
  };

  const topaynowClose = () => {
    settopaynowopen(false);
  };

  const [paylater, setpaylateropen] = useState(false);
  const PaylaterOpen = () => {
    setpaylateropen(true);
  };
  const paylaterClose = () => {
    setpaylateropen(false);
  };


  var lpdata = localStorage.getItem("lpdata");
  lpdata = JSON.parse(lpdata);
  const [Data, setData] = useState({
    email: localStorage.getItem("user_email"),
    name: localStorage.getItem("user_name"),
    mobile: localStorage.getItem("user_mobile"),
    CFT: lpdata.cft,
    rate: lpdata.rate,
    chargeweight: lpdata.chargeableWt,
    dockcharge: lpdata.docketCharge,
    fov: lpdata.fovCharge,
    fsc: lpdata.fscAmount,
    green: lpdata.greenTaxAmount,
    oda: lpdata.oda,
    handling: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    total: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });

  const InvoiceImage = (e) => {
    const token = localStorage.getItem("Logkey");
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);
    axios
      .post(`${CurrentUrl}api/Payment/InvoiceImage`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("my invoice response", response);
        setInvoicefile(response.data.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const Getmypaylaterdata = async () => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(
        `${CurrentUrl}User/GetPaylater?Email=${localStorage.getItem(
          "user_email"
        )}`,
        {
          headers: {
            "access-control-allow-credentials": "true",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPaylatercomplete(response.data);
      })
      .catch((error) => {
        return error.response;
      });
  };

  const [useSameAddress, setUseSameAddress] = useState(false);
  useEffect(() => {
    if (useSameAddress) {
    }
  }, [useSameAddress]);

  const returnClickhandler = (isChecked) => {
    if (isChecked) {
      setSelectedReturnAddress((val) => ({
        ...val,
        address1: selectedpickupaddress.address1,
      }));
      setreturnpersonname(pickuppersonname);
      setreturnpersoncontact(pickuppersoncontact);
    } else {
      setSelectedReturnAddress((val) => ({
        ...val,
        address1: "",
      }));
      setreturnpersonname("");
      setreturnpersoncontact("");
    }
    setUseSameAddress(isChecked);
  };
  useEffect(() => { }, [selectedreturnaddress]);
  useEffect(() => {
    Pickupslot(lpdata.lpId)
      .then((result) => {
        setPickupslotList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });

    DeliverySlot(lpdata.lpId)
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });

    let mylptotalamount = localStorage.getItem("lpdata");
    var mydata1 = JSON.parse(mylptotalamount);
    let mytotalamount = mydata1.totalAmount;
    GetMvikasAccountType(localStorage.getItem("user_email"), mytotalamount)
      .then((result) => {
        setType_(result.data.billing_Type);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  var lpdata = localStorage.getItem("lpdata");
  lpdata = JSON.parse(lpdata);
  const [paymentdata, setPaymentdata] = useState({
    email: localStorage.getItem("user_email"),
    name: localStorage.getItem("user_name"),
    mobile: localStorage.getItem("user_mobile"),
    rate: lpdata.rate,
    handlingcharge: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    total: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });

  async function handleRazorpayPayment() {
    const paymenturl = `${paymentUrl}api/Payment/initialize`
    const confirmedpay = `${paymentUrl}api/Payment/confirm`
    const token = localStorage.getItem("Logkey");

    setwpOpen(false);
    const response = await axios.post(paymenturl, paymentdata, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const order_id = response.data.id;
    const options = {
      // key: `rzp_test_7J1g2JqQnwPok8`,
      key: `rzp_live_sIm1xpJqoAao6x`,
      amount: 1000,
      name: "MVLoad",
      image: "",
      order_id: order_id,
      handler: (response) => {
        let mysearchdata = localStorage.getItem("searchdata");
        mysearchdata = JSON.parse(mysearchdata);
        setDimensionsData(mysearchdata);
        let mylpdata = localStorage.getItem("lpdata");
        mylpdata = JSON.parse(mylpdata);
        let orderdimensions = mysearchdata.searchedorderdimensions;
        let body = {
          isB2C: isb2c,

          otherInformation: otherInformation,
          orderdimensions: orderdimensions,
          mvikasDocketNo: generatedmvikasdocketno,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          Cust_Email: localStorage.getItem("user_email"),
          lpid: mylpdata.lpId,
          rateRequestId: mylpdata.rateRequestId,
          serviceid: mylpdata.serviceId,
          frompincode: mysearchdata.ratedata.frompin,
          topincode: mysearchdata.ratedata.topin,
          shipment_weight: mysearchdata.ratedata.shipmentweight,
          chargable_weight: mylpdata.chargeableWt,
          shipment_value: mysearchdata.ratedata.shipmentvalue,
          cft: mylpdata.cft,
          divisor: mylpdata.divisor,
          isCod: mysearchdata.ratedata.cod,
          rate: mylpdata.rate,
          docketcharge: mylpdata.docketCharge,
          fsc_percent: mylpdata.fsc,
          fsc_amount: mylpdata.fscAmount,
          greentax: mylpdata.greenTax,
          greentax_amount: mylpdata.greenTaxAmount,
          min_Chargable_weight: mylpdata.minWt,
          minFreight: mylpdata.minFreight,
          minFOV: mylpdata.minFOV,
          fovPercentage: mylpdata.fovPercentage,
          fov_amount: mylpdata.fovCharge,
          minODA: mylpdata.minODA,
          odaPerKG: mylpdata.odaPerKG,
          oda_amount: mylpdata.oda,
          handlingCharge: mylpdata.handlingCharge,
          codAmount: mylpdata.codAmount,
          taxableAmount: mylpdata.taxableAmount,
          gst: mylpdata.gst,
          gst_Amount: mylpdata.gstAmount,
          totalAmount: mylpdata.totalAmount + mylpdata.appointmentCharge + floorcharges,
          ratesheetid: mylpdata.rateSheetId,
          v_Fsc_amount: lpdata.fscAmountV,
          v_gst_Amount: lpdata.gstAmountV,
          v_totalAmount: lpdata.totalAmountV,
          v_taxableAmount: lpdata.taxableAmountV,
          orderdimensions: orderdimensions,
          itemname: itemname,
          itemremarks: remarks,
          iteminvoice: invoicenumber,
          image: "",
          imagepath: invoicefile,
          expectedDelivery:
            mylpdata.expectedDelivery === "NA"
              ? null
              : moment(mylpdata.expectedDelivery).format(
                "YYYY-MM-DDTHH:mm:ss.SSSZ"
              ),
          tat: mylpdata.tat,
          paymentMode: 1,
          eWayBillNo: eWayBillNo,
          lspDocketNo: itemdesc,
          transporterName: transporterName,
          eWayBillExpDate: eWayBillExpDate,
          categoryId: selecteditemcategory,
          subcategoryId: selecteditemsubcategory,
          itemTypeId: selecteditemtype,
          packagingRequiredId: selectedpackagingtype,
          deliveryslot:
            selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
          pickuptime:
            selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
          deliveryaddress: selecteddeliveryaddress.address1,
          pickupaddress: selectedpickupaddress.address1,
          returnaddress: selectedreturnaddress.address1,
          deliverypersonname: deliverypersonname,
          deliverypersonEmail: deliverypersonemail,
          deliverypersoncontact: deliverypersoncontact,
          pickuppersonname: pickuppersonname,
          pickUpCompanyNames: pickupcompanyname,
          pickuppersoncontact: pickuppersoncontact,
          returnpersonname: returnpersonname,
          returnpersoncontact: returnpersoncontact,
          returnremarks: returnremarks,
          VFscpercent: mylpdata.vFscpercent,
          VGreenTax: mylpdata.vGreenTax,
          VGreentaxAmount: mylpdata.vGreentaxAmount,
          VMinChargableWeight: mylpdata.vMinChargableWeight,
          VMinFreight: mylpdata.vMinFreight,
          VMinFOV: mylpdata.vMinFOV,
          VFovPercentage: mylpdata.vFovPercentage,
          VFovAmount: mylpdata.vFovAmount,
          VMinODA: mylpdata.vMinODA,
          VOdaPerKG: mylpdata.vOdaPerKG,
          VOdaAmount: mylpdata.vOdaAmount,
          VHandlingCharge: mylpdata.vHandlingCharge,
          VCodAmount: mylpdata.vCodAmount,
          VGst: mylpdata.vGst,
          VDocketCharge: mylpdata.VDocketCharge,
          VRate: mylpdata.vRate,
          appointmentDate: appointmentDate,
          // deliveryType : deliveryType
          appointmentCharge: mylpdata.appointmentCharge,
          floorCharge: parseInt(floorcharges)
        };
        setShowProgress(true);
        axios
          .post(confirmedpay, body, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then((response1) => {
            setShowProgress(true);
            setShowProgress(true);
            setPaynowdata(response1.data);
            setShowProgress(true);
            setShowProgress(false);
            setpaynowopen(true);
          })
          .catch((err) => console.log(err));
      },
      prefill: {
        name: "MVLoad",
        email: "testuser@mail.com",
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }
  function payitlater() {
    let mysearchdata = localStorage.getItem("searchdata");
    mysearchdata = JSON.parse(mysearchdata);
    console.log("my searchdata", mysearchdata);
    setDimensionsData(mysearchdata);
    let mylpdata = localStorage.getItem("lpdata");
    mylpdata = JSON.parse(mylpdata);
    // let orderdimensions = [
    //   {
    //     length: mysearchdata.Lenght,
    //     height: mysearchdata.height,
    //     breadth: mysearchdata.breadth,
    //     volume_weight: mysearchdata.volume_weight,
    //     noOfArticles: mysearchdata.pieces,
    //   },
    // ];
    let orderdimensions = mysearchdata.searchedorderdimensions;
    let body = {
      isB2C: mysearchdata.b2c,
      otherInformation: otherInformation,
      email: localStorage.getItem("user_email"),
      cust_Email: localStorage.getItem("user_email"),
      lpid: mylpdata.lpId,
      serviceid: mylpdata.serviceId,
      frompincode: mysearchdata.ratedata.frompin,
      topincode: mysearchdata.ratedata.topin,
      shipment_weight: mysearchdata.ratedata.shipmentweight,
      chargable_weight: mylpdata.chargeableWt,
      shipment_value: mysearchdata.ratedata.shipmentvalue,
      cft: mylpdata.cft,
      rateRequestId: mylpdata.rateRequestId,
      divisor: mylpdata.divisor,
      isCod: mysearchdata.ratedata.cod,
      rate: mylpdata.rate,
      docketcharge: mylpdata.docketCharge,
      fsc_percent: mylpdata.fsc,
      pickUpCompanyNames: pickupcompanyname,
      fsc_amount: mylpdata.fscAmount,
      greentax: mylpdata.greenTax,
      greentax_amount: mylpdata.greenTaxAmount,
      min_Chargable_weight: mylpdata.minWt,
      minFreight: mylpdata.minFreight,
      minFOV: mylpdata.minFOV,
      fovPercentage: mylpdata.fovPercentage,
      fov_amount: mylpdata.fovCharge,
      minODA: mylpdata.minODA,
      odaPerKG: mylpdata.odaPerKG,
      oda_amount: mylpdata.oda,
      handlingCharge: mylpdata.handlingCharge,
      codAmount: mylpdata.codAmount,
      taxableAmount: mylpdata.taxableAmount,
      gst: mylpdata.gst,
      gst_Amount: mylpdata.gstAmount,
      totalAmount: mylpdata.totalAmount + mylpdata.appointmentCharge + floorcharges,
      ratesheetid: mylpdata.rateSheetId,
      orderdimensions: orderdimensions,
      itemname: itemname,
      deliverypersonEmail: deliverypersonemail,
      iteminvoice: invoicenumber,
      image: "",
      imagepath: invoicefile,
      expectedDelivery:
        mylpdata.expectedDelivery === "NA"
          ? null
          : moment(mylpdata.expectedDelivery).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
      deliveryslot: selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
      pickuptime: selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
      deliveryaddress: selecteddeliveryaddress.address1,
      pickupaddress: selectedpickupaddress.address1,
      returnaddress: selectedreturnaddress.address1,
      v_Fsc_amount: lpdata.fscAmountV,
      v_gst_Amount: lpdata.gstAmountV,
      vTotalAmount: lpdata.totalAmountV,
      v_taxableAmount: lpdata.taxableAmountV,
      deliverypersonname: deliverypersonname,
      deliverypersoncontact: deliverypersoncontact,
      pickuppersonname: pickuppersonname,
      pickuppersoncontact: pickuppersoncontact,
      returnpersonname: returnpersonname,
      returnpersoncontact: returnpersoncontact,
      returnremarks: returnremarks,
      lspDocketNo: itemdesc,
      mvikasDocketNo: generatedmvikasdocketno,
      eWayBillNo: eWayBillNo,
      transporterName: transporterName,
      eWayBillExpDate: eWayBillExpDate,
      paymentMode: 2,
      categoryId: selecteditemcategory,
      subcategoryId: selecteditemsubcategory,
      itemTypeId: selecteditemtype,
      packagingRequiredId: selectedpackagingtype,
      VFscpercent: mylpdata.vFscpercent,
      VGreenTax: mylpdata.vGreenTax,
      VGreentaxAmount: mylpdata.vGreentaxAmount,
      VMinChargableWeight: mylpdata.vMinChargableWeight,
      VMinFreight: mylpdata.vMinFreight,
      VMinFOV: mylpdata.vMinFOV,
      VFovPercentage: mylpdata.vFovPercentage,
      VFovAmount: mylpdata.vFovAmount,
      VMinODA: mylpdata.vMinODA,
      VOdaPerKG: mylpdata.vOdaPerKG,
      VOdaAmount: mylpdata.vOdaAmount,
      VHandlingCharge: mylpdata.vHandlingCharge,
      VCodAmount: mylpdata.vCodAmount,
      VGst: mylpdata.vGst,
      VDocketCharge: mylpdata.VDocketCharge,
      VRate: mylpdata.vRate,
      isB2C: isb2c,
      appointmentDate: appointmentDate,
      // deliveryType : deliveryType
      appointmentCharge: mylpdata.appointmentCharge,
      floorCharge: parseInt(floorcharges)
    };
    var paylpdata = localStorage.getItem("lpdata");
    paylpdata = JSON.parse(paylpdata);
    setPaylaterdata(paylpdata);
    setPayLaterDisabled(true);
    setShowProgress(true);
    PayLater(body, paylaterdata)
      .then((result) => {
        console.log("my paylater response", result);
        if (result.status === 200) {
          Getmypaylaterdata(localStorage.getItem("user_email"));
          setpaylateropen(true);
        } else {
          console.error("PayLater failed with status code:", result.status);
          toast.error("Failed to process payment. Please try again.");
        }

        setShowProgress(false);
      })
      .catch((error) => {
        console.error("PayLater failed with an error:", error);
        toast.error("An error occurred. Please try again.");
        setShowProgress(false);
      });
  }
  function topaypayment() {
    let mysearchdata = localStorage.getItem("searchdata");
    mysearchdata = JSON.parse(mysearchdata);
    console.log("my searchdata", mysearchdata);
    setDimensionsData(mysearchdata);
    let mylpdata = localStorage.getItem("lpdata");
    mylpdata = JSON.parse(mylpdata);

    let orderdimensions = mysearchdata.searchedorderdimensions;

    let body = {
      isB2C: isb2c,
      otherInformation: otherInformation,
      email: localStorage.getItem("user_email"),
      cust_Email: localStorage.getItem("user_email"),
      lpid: mylpdata.lpId,
      serviceid: mylpdata.serviceId,
      frompincode: mysearchdata.ratedata.frompin,
      topincode: mysearchdata.ratedata.topin,
      shipment_weight: mysearchdata.ratedata.shipmentweight,
      chargable_weight: mylpdata.chargeableWt,
      shipment_value: mysearchdata.ratedata.shipmentvalue,
      cft: mylpdata.cft,
      divisor: mylpdata.divisor,
      isCod: mysearchdata.ratedata.cod,
      rate: mylpdata.rate,
      docketcharge: mylpdata.docketCharge,
      fsc_percent: mylpdata.fsc,
      fsc_amount: mylpdata.fscAmount,
      greentax: mylpdata.greenTax,
      greentax_amount: mylpdata.greenTaxAmount,
      min_Chargable_weight: mylpdata.minWt,
      minFreight: mylpdata.minFreight,
      minFOV: mylpdata.minFOV,
      fovPercentage: mylpdata.fovPercentage,
      fov_amount: mylpdata.fovCharge,
      minODA: mylpdata.minODA,
      odaPerKG: mylpdata.odaPerKG,
      oda_amount: mylpdata.oda,
      handlingCharge: mylpdata.handlingCharge,
      codAmount: mylpdata.codAmount,
      taxableAmount: mylpdata.taxableAmount,
      gst: mylpdata.gst,
      gst_Amount: mylpdata.gstAmount,
      totalAmount: mylpdata.totalAmount + mylpdata.appointmentCharge + floorcharges,
      ratesheetid: mylpdata.rateSheetId,
      orderdimensions: orderdimensions,
      itemname: itemname,
      pickUpCompanyNames: pickupcompanyname,
      iteminvoice: invoicenumber,
      image: "",
      imagepath: invoicefile,
      expectedDelivery:
        mylpdata.expectedDelivery === "NA"
          ? null
          : moment(mylpdata.expectedDelivery).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
      deliverypersonEmail: deliverypersonemail,
      deliveryslot:
        selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
      pickuptime: selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
      deliveryaddress: selecteddeliveryaddress.address1,
      pickupaddress: selectedpickupaddress.address1,
      returnaddress: selectedreturnaddress.address1,
      v_Fsc_amount: lpdata.fscAmountV,
      rateRequestId: lpdata.rateRequestId,
      v_gst_Amount: lpdata.gstAmountV,
      vTotalAmount: lpdata.totalAmountV,
      v_taxableAmount: lpdata.taxableAmountV,
      deliverypersonname: deliverypersonname,
      deliverypersoncontact: deliverypersoncontact,
      pickuppersonname: pickuppersonname,
      pickuppersoncontact: pickuppersoncontact,
      returnpersonname: returnpersonname,
      returnpersoncontact: returnpersoncontact,
      returnremarks: returnremarks,
      lspDocketNo: itemdesc,
      mvikasDocketNo: generatedmvikasdocketno,
      eWayBillNo: eWayBillNo,
      transporterName: transporterName,
      eWayBillExpDate: eWayBillExpDate,
      paymentMode: 3,
      categoryId: selecteditemcategory,
      subcategoryId: selecteditemsubcategory,
      itemTypeId: selecteditemtype,
      packagingRequiredId: selectedpackagingtype,
      VFscpercent: mylpdata.vFscpercent,
      VGreenTax: mylpdata.vGreenTax,
      VGreentaxAmount: mylpdata.vGreentaxAmount,
      VMinChargableWeight: mylpdata.vMinChargableWeight,
      VMinFreight: mylpdata.vMinFreight,
      VMinFOV: mylpdata.vMinFOV,
      VFovPercentage: mylpdata.vFovPercentage,
      VFovAmount: mylpdata.vFovAmount,
      VMinODA: mylpdata.vMinODA,
      VOdaPerKG: mylpdata.vOdaPerKG,
      VOdaAmount: mylpdata.vOdaAmount,
      VHandlingCharge: mylpdata.vHandlingCharge,
      VCodAmount: mylpdata.vCodAmount,
      VGst: mylpdata.vGst,
      VDocketCharge: mylpdata.VDocketCharge,
      VRate: mylpdata.vRate,
      appointmentDate: appointmentDate,
      // deliveryType : deliveryType
      appointmentCharge: mylpdata.appointmentCharge,
      floorCharge: parseInt(floorcharges)
      // rateRequestId: 
    };
    // var paylpdata = localStorage.getItem("lpdata");
    // paylpdata = JSON.parse(paylpdata);
    // setPaylaterdata(paylpdata);
    // setPayLaterDisabled(true);
    setShowProgress(true);
    ToPayPayment(body)
      .then((r) => r.json())
      .then((data) => {
        console.log("my topay payment response", data);
        settopaynowdata(data);
        setShowProgress(true);
        setShowProgress(false);
        settopaynowopen(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .catch((r) => {
        console.log(r);
      });
  }
  function handlewalletpay() {
    let mysearchdata = localStorage.getItem("searchdata");
    mysearchdata = JSON.parse(mysearchdata);
    setDimensionsData(mysearchdata);
    let mylpdata = localStorage.getItem("lpdata");
    mylpdata = JSON.parse(mylpdata);
    let orderdimensions = mysearchdata.searchedorderdimensions;
    let body = {
      isB2C: isb2c,
      walletId: availablewalletbalance.walletId,
      otherInformation: otherInformation,
      email: localStorage.getItem("user_email"),
      cust_Email: localStorage.getItem("user_email"),
      lpid: mylpdata.lpId,
      serviceid: mylpdata.serviceId,
      frompincode: mysearchdata.ratedata.frompin,
      topincode: mysearchdata.ratedata.topin,
      shipment_weight: mysearchdata.ratedata.shipmentweight,
      chargable_weight: mylpdata.chargeableWt,
      shipment_value: mysearchdata.ratedata.shipmentvalue,
      cft: mylpdata.cft,
      rateRequestId: mylpdata.rateRequestId,
      divisor: mylpdata.divisor,
      isCod: mysearchdata.ratedata.cod,
      rate: mylpdata.rate,
      docketcharge: mylpdata.docketCharge,
      fsc_percent: mylpdata.fsc,
      pickUpCompanyNames: pickupcompanyname,
      fsc_amount: mylpdata.fscAmount,
      greentax: mylpdata.greenTax,
      greentax_amount: mylpdata.greenTaxAmount,
      min_Chargable_weight: mylpdata.minWt,
      minFreight: mylpdata.minFreight,
      minFOV: mylpdata.minFOV,
      fovPercentage: mylpdata.fovPercentage,
      fov_amount: mylpdata.fovCharge,
      minODA: mylpdata.minODA,
      odaPerKG: mylpdata.odaPerKG,
      oda_amount: mylpdata.oda,
      handlingCharge: mylpdata.handlingCharge,
      codAmount: mylpdata.codAmount,
      taxableAmount: mylpdata.taxableAmount,
      gst: mylpdata.gst,
      gst_Amount: mylpdata.gstAmount,
      totalAmount: mylpdata.totalAmount + mylpdata.appointmentCharge + floorcharges,
      ratesheetid: mylpdata.rateSheetId,
      orderdimensions: orderdimensions,
      itemname: itemname,
      deliverypersonEmail: deliverypersonemail,
      iteminvoice: invoicenumber,
      image: "",
      imagepath: invoicefile,
      expectedDelivery:
        mylpdata.expectedDelivery === "NA"
          ? null
          : moment(mylpdata.expectedDelivery).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
      deliveryslot: selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
      pickuptime: selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
      deliveryaddress: selecteddeliveryaddress.address1,
      pickupaddress: selectedpickupaddress.address1,
      returnaddress: selectedreturnaddress.address1,
      v_Fsc_amount: lpdata.fscAmountV,
      v_gst_Amount: lpdata.gstAmountV,
      vTotalAmount: lpdata.totalAmountV,
      v_taxableAmount: lpdata.taxableAmountV,
      deliverypersonname: deliverypersonname,
      deliverypersoncontact: deliverypersoncontact,
      pickuppersonname: pickuppersonname,
      pickuppersoncontact: pickuppersoncontact,
      returnpersonname: returnpersonname,
      returnpersoncontact: returnpersoncontact,
      returnremarks: returnremarks,
      lspDocketNo: itemdesc,
      mvikasDocketNo: generatedmvikasdocketno,
      eWayBillNo: eWayBillNo,
      transporterName: transporterName,
      eWayBillExpDate: eWayBillExpDate,
      paymentMode: 2,
      categoryId: selecteditemcategory,
      subcategoryId: selecteditemsubcategory,
      itemTypeId: selecteditemtype,
      packagingRequiredId: selectedpackagingtype,
      VFscpercent: mylpdata.vFscpercent,
      VGreenTax: mylpdata.vGreenTax,
      VGreentaxAmount: mylpdata.vGreentaxAmount,
      VMinChargableWeight: mylpdata.vMinChargableWeight,
      VMinFreight: mylpdata.vMinFreight,
      VMinFOV: mylpdata.vMinFOV,
      VFovPercentage: mylpdata.vFovPercentage,
      VFovAmount: mylpdata.vFovAmount,
      VMinODA: mylpdata.vMinODA,
      VOdaPerKG: mylpdata.vOdaPerKG,
      VOdaAmount: mylpdata.vOdaAmount,
      VHandlingCharge: mylpdata.vHandlingCharge,
      VCodAmount: mylpdata.vCodAmount,
      VGst: mylpdata.vGst,
      VDocketCharge: mylpdata.VDocketCharge,
      VRate: mylpdata.vRate,
      appointmentDate: appointmentDate,
      // deliveryType : deliveryType
      appointmentCharge: mylpdata.appointmentCharge,
      floorCharge: parseInt(floorcharges)
    };
    // var paylpdata = localStorage.getItem("lpdata");
    // paylpdata = JSON.parse(paylpdata);
    // setPaylaterdata(paylpdata);
    // setPayLaterDisabled(true);
    setShowProgress(true);
    walletPayment(body, paylaterdata)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          // Getmypaylaterdata(localStorage.getItem("user_email"));
          // setpaylateropen(true);
          setwalletpaycomplete(result)
          setwpOpen(false);
          toast.success("Successfully processed payment.");
          setwalletpayopen(true);
          //settopaynowdata(result);
        } else {
          console.error("Payment failed with status code:", result.message);
          toast.error("Failed to process payment. Please try again.");
        }
        setShowProgress(false);
      })
      .catch((error) => {
        console.error("PayLater failed with an error:", error);
        toast.error("An error occurred. Please try again.");
        setShowProgress(false);
      });
  }

  const [accesscode, setaccesscode] = useState("")
  const [checkouturl, setcheckouturl] = useState("")
  const [encryptrqst, setencryptrqst] = useState("")

  // async function handleCCAvenPayment() {
  //   const paymenturl = `${CurrentUrl}PaymentCC/InitializePayment`;
  //   const confirmedpay = `${CurrentUrl}PaymentCC/ConfirmPayment  `;


  //   const response = await axios.post(paymenturl, paymentdata);
  //   console.log("my initial cc response" , response);
  //   setaccesscode(response.data.access_code)
  //   setcheckouturl(response.data.checkout_url)
  //   setencryptrqst(response.data.encRequest)
  //   setShowProgress(true);


  //   // let mysearchdata = localStorage.getItem("searchdata");
  //   // mysearchdata = JSON.parse(mysearchdata);
  //   // setDimensionsData(mysearchdata);
  //   // let mylpdata = localStorage.getItem("lpdata");
  //   // mylpdata = JSON.parse(mylpdata);
  //   // let orderdimensions = [
  //   //   {
  //   //     length: mysearchdata.Lenght,
  //   //     height: mysearchdata.height,
  //   //     breadth: mysearchdata.breadth,
  //   //     volume_weight: mysearchdata.volume_weight,
  //   //   },
  //   // ];
  //   // let body = {
  //   //   //encResp:encryptrqst,
  //   //   Cust_Email: localStorage.getItem("user_email"),
  //   //   lpid: mylpdata.lpId,
  //   //   serviceid: mylpdata.serviceId,
  //   //   frompincode: mysearchdata.ratedata.frompin,
  //   //   topincode: mysearchdata.ratedata.topin,
  //   //   shipment_weight: mysearchdata.ratedata.shipmentweight,
  //   //   chargable_weight: mylpdata.chargeableWt,
  //   //   shipment_value: mysearchdata.ratedata.shipmentvalue,
  //   //   cft: mylpdata.cft,
  //   //   divisor: mylpdata.divisor,
  //   //   isCod: mysearchdata.ratedata.cod,
  //   //   rate: mylpdata.rate,
  //   //   docketcharge: mylpdata.docketCharge,
  //   //   fsc_percent: mylpdata.fsc,
  //   //   fsc_amount: mylpdata.fscAmount,
  //   //   greentax: mylpdata.greenTax,
  //   //   greentax_amount: mylpdata.greenTaxAmount,
  //   //   min_Chargable_weight: mylpdata.minWt,
  //   //   minFreight: mylpdata.minFreight,
  //   //   minFOV: mylpdata.minFOV,
  //   //   fovPercentage: mylpdata.fovPercentage,
  //   //   fov_amount: mylpdata.fovCharge,
  //rateRequestId:mylpdata.rateRequestId,
  //   //   minODA: mylpdata.minODA,
  //   //   odaPerKG: mylpdata.odaPerKG,
  //   //   oda_amount: mylpdata.oda,
  //   //   handlingCharge: mylpdata.handlingCharge,
  //   //   codAmount: mylpdata.codAmount,
  //   //   taxableAmount: mylpdata.taxableAmount,
  //   //   gst: mylpdata.gst,
  //   //   gst_Amount: mylpdata.gstAmount,
  //   //   totalAmount: mylpdata.totalAmount,
  //   //   ratesheetid: mylpdata.rateSheetId,
  //   //   v_Fsc_amount: lpdata.fscAmountV,
  //   //   v_gst_Amount: lpdata.gstAmountV,
  //   //   v_totalAmount: lpdata.totalAmountV,
  //   //   v_taxableAmount: lpdata.taxableAmountV,
  //   //   orderdimensions: orderdimensions,
  //   //   itemname: itemname,
  //   //   itemremarks: remarks,
  //   //   iteminvoice: invoicenumber,
  //   //   image: "",
  //   //   imagepath: invoicefile,
  //   //   expectedDelivery:
  //   //     mylpdata.expectedDelivery === "NA"
  //   //       ? null
  //   //       : moment(mylpdata.expectedDelivery).format(
  //   //         "YYYY-MM-DDTHH:mm:ss.SSSZ"
  //   //       ),
  //   //   tat: mylpdata.tat,
  //   //   deliveryslot:
  //   //     selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
  //   //   pickuptime:
  //   //     selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
  //   //   deliveryaddress: selecteddeliveryaddress.address1,
  //   //   pickupaddress: selectedpickupaddress.address1,
  //   //   returnaddress: selectedreturnaddress.address1,
  //   //   deliverypersonname: deliverypersonname,
  //   //   deliverypersoncontact: deliverypersoncontact,
  //   //   pickuppersonname: pickuppersonname,
  //   //   pickuppersoncontact: pickuppersoncontact,
  //   //   returnpersonname: returnpersonname,
  //   //   returnpersoncontact: returnpersoncontact,
  //   //   returnremarks: returnremarks,
  //   // };
  //   // setShowProgress(true);
  //   // axios
  //   //   .post(confirmedpay, {...body, encResp: response.data.encRequest})
  //   //   .then((response1) => {
  //   //     setShowProgress(true);
  //   //     setShowProgress(true);
  //   //     setPaynowdata(response1.data);
  //   //     setShowProgress(true);
  //   //     setShowProgress(false);
  //   //     setpaynowopen(true);
  //   //   })
  //   //   .catch((err) => console.log(err));
  // }

  // async function handleCCAvenPayment() {
  //   const paymenturl = `${CurrentUrl}PaymentCC/InitializePayment`;

  //   try {
  //     const response = await axios.post(paymenturl, paymentdata);
  //     const { access_code, checkout_url, encRequest } = response.data;

  //     const form = document.createElement('form');
  //     form.method = 'post';
  //     form.action = checkout_url;
  //     const accessCodeInput = document.createElement('input');
  //     accessCodeInput.type = 'hidden';
  //     accessCodeInput.name = 'access_code';
  //     accessCodeInput.value = access_code;
  //     form.appendChild(accessCodeInput);

  //     const encRequestInput = document.createElement('input');
  //     encRequestInput.type = 'hidden';
  //     encRequestInput.name = 'encRequest';
  //     encRequestInput.value = encRequest;
  //     form.appendChild(encRequestInput);
  //     document.body.appendChild(form);
  //     form.submit();

  //   } catch (error) {
  //     console.error('Error initiating CCAvenue payment:', error);
  //   }
  // }


  const [payitlaterdata, setPaylaterdata] = useState({
    email: localStorage.getItem("user_email"),
    rate: lpdata.rate,
    handlingcharge: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    totalAmount: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });



  const [walletpayopen, setwpOpen] = React.useState(false);
  const walletpayOpenm = () => {
    setwpOpen(true);
  };
  const walletpayClose = () => {
    setwpOpen(false);
  };



  const [itemcategory, setitemcategory] = useState([]);
  const [selecteditemcategory, setselecteditemcategory] = useState("");

  const [itemsubcategory, setItemSubcategory] = useState([]);
  const [selecteditemsubcategory, setSelectedItemSubcategory] = useState("");

  const [packagingtype, setPackagingType] = useState([]);
  const [selectedpackagingtype, setselectedpackagingtype] = useState("")

  const [itemtype, setitemtype] = useState([]);
  const [selecteditemtype, setselecteditemtype] = useState("")

  const handleitemcategorychange = (e) => {
    setselecteditemcategory(e.target.value);
    setSelectedItemSubcategory("");
    setItemSubcategory([]);
    // setPackagingType([]);
    // setitemtype([]);
    loadItemSubCategories(e.target.value);
  };

  const handleItemSubcategoryChange = (e) => {
    setSelectedItemSubcategory(e.target.value);
    // loadPackagingTypes(e.target.value);
  };

  const handlepackagintypeChange = (e) => {
    setselectedpackagingtype(e.target.value);
    // loadPackagingTypes(e.target.value);
    // loaditemtypes(e.target.value);
  };

  const handleitemtypechange = (e) => {
    setselecteditemtype(e.target.value);
    // loaditemtypes(e.target.value);
  };

  useEffect(() => {
    loadItemCategories();
    loadPackagingTypes();
    loaditemtypes();
  }, []);

  const loadItemCategories = () => {
    setitemcategory([]);
    GetItemCategories()
      .then((response) => {
        setitemcategory(response);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const loadItemSubCategories = (categoryId) => {
    const token = localStorage.getItem("Logkey");
    axios
      .get(
        `${Url}GetItemSubCategories?Id=${categoryId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setItemSubcategory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadPackagingTypes = () => {
    const token = localStorage.getItem("Logkey");
    axios
      .get(
        `${Url}GetItemPackagingType?Type=1`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setPackagingType(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loaditemtypes = () => {
    const token = localStorage.getItem("Logkey");
    axios
      .get(
        `${Url}GetItemPackagingType?Type=2`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setitemtype(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    setEmailError("");
  }, []);

  const handleEmailBlur = () => {
    setEmailError("");
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(deliverypersonemail)) {
      setEmailError("Invalid email format");
    }
  };

  const handleDeliveryTypeChange = (e) => {
    const value = e.target.value;
    setDeliveryType(value);
    setShowAppointmentDetails(value === "Appointment delivery");
  };

  const handleInfoClick = () => {
    const total = Number(Data["total"] || 0);
    const floorchargesAmount = Number(floorcharges || 0);
    // const deliveryCharge = Number(lpdata.appointmentCharge) * Number(lpdata.chargeableWt) || 0;
    const deliveryCharge = deliveryType === "Appointment delivery" ? (Number(lpdata.appointmentCharge) || 0) : 0;
    const finaltotalAmount = total + floorchargesAmount + deliveryCharge;

    const content = (
      <div className="p_summary">
        <p>Amount: <span>₹{total}</span></p>
        <p>Floor Charge: <span>₹{floorchargesAmount}</span></p>
        {/* <p>Appointment Delivery Charge: <span>₹{deliveryCharge}</span></p> */}
        {deliveryType === "Appointment delivery" && (
          <p>Appointment Delivery Charge: <span>₹{deliveryCharge}</span></p>
        )}
        <p>Total Amount: <span>₹{finaltotalAmount}</span></p>
      </div>
    );

    setpaymentpreviewDialogContent(content);
    setpaymentpreviewDialogOpen(true);
  };

  const handlepaymentpreviewCloseDialog = () => {
    setpaymentpreviewDialogOpen(false);
  };

  // const handleDetailsCompletion = () => {
  //   // Call this function when the user completes filling details
  //   setDetailsCompleted(true);
  // };

  const steps = [
    {
      label: "Order Details",
      description: (
        <>
          <h6 className="mt-2 mb-4 h6-title">LP Details</h6>
          <Box>
            <div className="row">
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="LP Name"
                  value={Data["lpname"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </Box>

          <h6 className="mt-2 h6-title">Charges</h6>
          <TableContainer component={Paper} sx={{ boxShadow: 0, mb: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>Rate</TableCell>
                  <TableCell align="right">Chargeable Weight</TableCell>
                  <TableCell align="right">Docket Charge </TableCell>
                  <TableCell align="right">FOV Charge</TableCell>
                  <TableCell align="right">FSC Amount (20%)</TableCell>
                  <TableCell align="right">Green Tax</TableCell>
                  <TableCell align="right">ODA</TableCell>
                  <TableCell align="right">Handling Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.desc}>
                    <TableCell>{Data["rate"]}</TableCell>
                    <TableCell align="right">
                      {Data["chargeweight"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["dockcharge"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["fov"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["fsc"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["green"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["oda"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["handling"].toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={3} colSpan={6} />
                  <TableCell>Taxable Amount</TableCell>
                  <TableCell align="right">
                    {Data["taxamount"]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>GST Amount (18%)</TableCell>
                  <TableCell align="right">{Data["gst"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Amount</TableCell>
                  <TableCell align="right">
                    {Data["total"]}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ),
    },
    {
      label: "Customer Details",
      description: (
        <>
          <Box>
            <div className="row">
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Person Name"
                  value={Data["name"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Email"
                  value={Data["email"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Mobile No"
                  value={Data["mobile"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: " Pickup Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Pickup Person Name"
                  value={pickuppersonname}
                  onChange={(e) => setpickuppersonname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Pickup Company Name"
                  value={pickupcompanyname}
                  onChange={(e) => setpickupcompanyname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Pickup Person Contact"
                  value={pickuppersoncontact}
                  onBlur={handlepickupcontactBlur}
                  onChange={(e) => {
                    const pickupcontact = e.target.value;
                    if (/^\d{0,10}$/.test(pickupcontact)) {
                      setpickuppersoncontact(pickupcontact);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="pickup-slot-label">
                    Pickup Slot
                  </InputLabel>

                  <Select
                    labelId="pickup-slot-label"
                    id="pickup-slot"
                    required
                    label="Pickup Slot"
                    value={selectedpickupslot["slotvalue1"]}
                    onChange={(e) => {
                      setSelectedpickupslot((val) => ({
                        ...val,
                        slotvalue1: e.target.value,
                      }));
                    }}
                    defaultValue={
                      pickupslotList && pickupslotList[0]
                        ? pickupslotList[0].slotvalue
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {pickupslotList.map((option, index) => (
                      <MenuItem key={index} value={option.slotvalue}>
                        {option.slotvalue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="pickup-address-label">
                    Pickup Address
                  </InputLabel>
                  <Select
                    labelId="pickup-address-label"
                    id="pickup-address"
                    required
                    label="Pickup Address"
                    value={selectedpickupaddress["address1"]}
                    onChange={(e) => {
                      setSelectedPickupAddress((val) => ({
                        ...val,
                        address1: e.target.value,
                      }));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {/* <MenuItem value="0">
                      <p className="default-p">Please Choose Pickup Address</p>
                    </MenuItem> */}

                    {addressList.map((add, index) => (
                      <MenuItem key={add.address_Id} value={add.address_Id}>
                        <span
                          title={`${add.full_address}, ${add.state}, ${add.pincode}`}
                        >
                          {add.name}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-2 mb-4">
                <IconButton
                  className="add-icon"
                  aria-label="add"
                  size="large"
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    // {
    //   label: " Delivery Details",
    //   description: (
    //     <>
    //       <Box sx={{ mt: 2 }}>
    //         <div className="row">
    //           <div className="col-sm-4 mb-4">
    //             <TextField
    //               fullWidth
    //               required
    //               label="Delivery Person Name"
    //               value={deliverypersonname}
    //               onChange={(e) => setdeliverypersonname(e.target.value)}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}

    //             />
    //           </div>
    //           <div className="col-sm-4 mb-4">
    //             <TextField
    //               fullWidth
    //               label="Delivery Person Email"
    //               onBlur={handleEmailBlur}
    //               value={deliverypersonemail}
    //               onChange={(e) => setdeliverypersonemail(e.target.value)}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //               error={emailError !== ""}
    //               helperText={emailError}
    //             />
    //           </div>
    //           <div className="col-sm-4 mb-4">
    //             <TextField
    //               fullWidth
    //               required
    //               label="Delivery Person Contact"
    //               value={deliverypersoncontact}
    //               onBlur={handledeliverycontactBlur}
    //               onChange={(e) => {
    //                 const deliverycontact = e.target.value;
    //                 if (/^\d{0,10}$/.test(deliverycontact)) {
    //                   setdeliverypersoncontact(deliverycontact);
    //                 }
    //               }}
    //               onKeyPress={(event) => {
    //                 if (!/[0-9]/.test(event.key)) {
    //                   event.preventDefault();
    //                 }
    //               }}
    //               inputProps={{ maxLength: 10, pattern: "[0-9]" }}
    //               InputLabelProps={{
    //                 shrink: true,
    //               }}
    //             />
    //           </div>
    //           <div className="col-sm-4 mb-4">
    //             <FormControl fullWidth>
    //               <InputLabel required id="delivery-slot-label">
    //                 Delivery Slot
    //               </InputLabel>
    //               <Select
    //                 labelId="delivery-slot-label"
    //                 required
    //                 id="delivery-slot"
    //                 label="Delivery Slot"
    //                 value={selecteddeliveryslot["slotvalue2"]}
    //                 onChange={(e) => {
    //                   setselecteddeliveryslot((val) => ({
    //                     ...val,
    //                     slotvalue2: e.target.value,
    //                   }));
    //                 }}
    //                 defaultValue={
    //                   deliveryslotlist && deliveryslotlist[0]
    //                     ? deliveryslotlist[0].slotvalue
    //                     : null
    //                 }
    //                 InputLabelProps={{
    //                   shrink: true,
    //                 }}
    //               >
    //                 {deliveryslotlist.map((option, index) => (
    //                   <MenuItem key={index} value={option.slotvalue}>
    //                     {option.slotvalue}
    //                   </MenuItem>
    //                 ))}
    //               </Select>
    //             </FormControl>
    //           </div>
    //           <div className="col-sm-4 mb-4">
    //             <FormControl fullWidth>
    //               <InputLabel required id="delievery-address-label">
    //                 Delivery Address
    //               </InputLabel>
    //               <Select
    //                 labelId="delievery-address-label"
    //                 required
    //                 id="delievery-address"
    //                 label="Delivery Address"
    //                 value={selecteddeliveryaddress["address1"]}
    //                 onChange={(e) => {
    //                   setSelectedDeliveryAddress((val) => ({
    //                     ...val,
    //                     address1: e.target.value,
    //                   }));
    //                 }}
    //                 InputLabelProps={{
    //                   shrink: true,
    //                 }}
    //               >
    //                 <MenuItem value="0">
    //                   <p className="default-p">
    //                     Please Choose Delivery Address
    //                   </p>
    //                 </MenuItem>

    //                 {addressList.map((add, index) => (
    //                   <MenuItem key={add.address_Id} value={add.address_Id}>
    //                     <span title={add.full_address}>{add.name}</span>
    //                   </MenuItem>
    //                 ))}
    //               </Select>
    //             </FormControl>
    //           </div>
    //           <div className="col-sm-2 mb-4">
    //             <IconButton
    //               className="add-icon"
    //               aria-label="add"
    //               size="large"
    //               onClick={handleClickOpen}
    //             >
    //               <AddRoundedIcon />
    //             </IconButton>
    //           </div>
    //         </div>
    //       </Box>
    //     </>
    //   ),
    // },
    {
      label: " Delivery Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Delivery Person Name"
                  value={deliverypersonname}
                  onChange={(e) => setdeliverypersonname(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Delivery Person Email"
                  onBlur={handleEmailBlur}
                  value={deliverypersonemail}
                  onChange={(e) => setdeliverypersonemail(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  error={emailError !== ""}
                  helperText={emailError}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Delivery Person Contact"
                  value={deliverypersoncontact}
                  onBlur={handledeliverycontactBlur}
                  onChange={(e) => {
                    const deliverycontact = e.target.value;
                    if (/^\d{0,10}$/.test(deliverycontact)) {
                      setdeliverypersoncontact(deliverycontact);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <div className="d-flex">
                  <FormControl fullWidth>
                    <InputLabel required id="floor-label">Choose your floor</InputLabel>
                    <Select
                      labelId="floor-label"
                      id="floor"
                      label="Choose your floor"
                      value={selectedfloor}
                      onChange={handleFloorChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      {floordata.map((floor) => (
                        <MenuItem key={floor.floorId} value={floor.floorId}>
                          {floor.floor === 0 ? 'Ground' : floor.floor}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <Tooltip title={floorcharges ? `Floor charges: ${floorcharges}` : 'Select a floor to see charges'}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="delivery-type-label">Delivery Type</InputLabel>
                  <Select
                    labelId="delivery-type-label"
                    id="delivery-type"
                    label="Delivery Type"
                    value={deliveryType}
                    onChange={handleDeliveryTypeChange}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="Standard delivery">Standard delivery</MenuItem>
                    <MenuItem value="Appointment delivery">Appointment delivery</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {showAppointmentDetails && (
                <>
                  <div className="col-sm-4 mb-4">
                    <TextField
                      fullWidth
                      required
                      label="Appointment Date"
                      type="date"
                      value={appointmentDate}
                      onChange={(e) => setAppointmentDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="col-sm-4 mb-4">
                    <FormControl fullWidth>
                      <InputLabel required id="delivery-slot-label">Delivery Slot</InputLabel>
                      <Select
                        labelId="delivery-slot-label"
                        required
                        id="delivery-slot"
                        label="Delivery Slot"
                        value={selecteddeliveryslot["slotvalue2"]}
                        onChange={(e) => {
                          setselecteddeliveryslot((val) => ({
                            ...val,
                            slotvalue2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        {deliveryslotlist.map((option, index) => (
                          <MenuItem key={index} value={option.slotvalue}>
                            {option.slotvalue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
              <div className="col-sm-4 mb-4">
                <div className="d-flex gap-2">
                  <FormControl fullWidth>
                    <InputLabel required id="delievery-address-label">Delivery Address</InputLabel>
                    <Select
                      labelId="delievery-address-label"
                      required
                      id="delievery-address"
                      label="Delivery Address"
                      value={selecteddeliveryaddress["address1"]}
                      onChange={(e) => {
                        setSelectedDeliveryAddress((val) => ({
                          ...val,
                          address1: e.target.value,
                        }));
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="0">
                        <p className="default-p">Please Choose Delivery Address</p>
                      </MenuItem>
                      {addressList.map((add) => (
                        <MenuItem key={add.address_Id} value={add.address_Id}>
                          <span title={add.full_address}>{add.name}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <IconButton
                    className="add-icon"
                    aria-label="add"
                    size="large"
                    onClick={handleClickOpen}
                  >
                    <AddRoundedIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: " Return Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useSameAddress}
                    onChange={(e) => returnClickhandler(e.target.checked)}
                    name="useSameAddress"
                  />
                }
                label="Same as Pickup Address"
              />
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Return Person Name"
                  required
                  value={returnpersonname}
                  onChange={(e) => {
                    if (useSameAddress) {
                      setpickuppersonname(e.target.value);
                    } else {
                      setreturnpersonname(e.target.value);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Return Person Contact"
                  required
                  value={returnpersoncontact}
                  onBlur={handlereturncontactBlur}
                  onChange={(e) => {
                    // const returncontact = e.target.value;
                    // if (/^\d{0,10}$/.test(returncontact)) {
                    //   setreturnpersoncontact(returncontact);
                    // }
                    if (useSameAddress) {
                      setpickuppersoncontact(e.target.value);
                    } else {
                      setreturnpersoncontact(e.target.value);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Remark"
                  value={returnremarks}
                  onChange={(e) => setreturnremarks(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="return-address-label">
                    Return Address
                  </InputLabel>
                  <Select
                    labelId="return-address-label"
                    id="return-address"
                    required
                    label="Return Address"
                    value={selectedreturnaddress.address1}
                    onChange={(e) => {
                      if (useSameAddress) {
                        setSelectedPickupAddress((val) => ({
                          ...val,
                          address1: e.target.value,
                        }));
                      } else {
                        setSelectedReturnAddress((val) => ({
                          ...val,
                          address1: e.target.value,
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {addressList.map((add, index) => (
                      <MenuItem key={add.address_Id} value={add.address_Id}>
                        <span title={add.full_address}>{add.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-2 mb-4">
                <IconButton
                  className="add-icon"
                  aria-label="add"
                  size="large"
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: "Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="item-category-label">
                    Item Category
                  </InputLabel>

                  <Select
                    labelId="item-category-label"
                    id="item-category"
                    label="Item Category"
                    value={selecteditemcategory}
                    onChange={handleitemcategorychange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {itemcategory.map((category) => (
                      <MenuItem
                        key={category.categoryId}
                        value={category.categoryId}
                      >
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="item-subcategory-label">
                    Item Sub-Category
                  </InputLabel>

                  <Select
                    labelId="item-subcategory-label"
                    id="item-subcategory"
                    label="Item Sub-Category"
                    value={selecteditemsubcategory}
                    onChange={handleItemSubcategoryChange}
                    disabled={!selecteditemcategory}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {itemsubcategory.map((subcategory) => (
                      <MenuItem
                        key={subcategory.subCategoryId}
                        value={subcategory.subCategoryId}
                      >
                        {subcategory.subCategoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Item Name"
                  value={itemname}
                  required
                  onChange={(e) => setItemname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Info">
                          <IconButton onClick={handleitemtypeDownload}>
                            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="item-packagingtype-label">
                    Item Type
                  </InputLabel>
                  <Select
                    labelId="item-packagingtype-label"
                    id="item-packagingtype"
                    label="Item Packaging Type"
                    value={selecteditemtype}
                    onChange={handleitemtypechange}
                    // disabled={!selecteditemsubcategory}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {itemtype.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.itemValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="item-packagingtype-label">
                    Item Packaging Type
                  </InputLabel>
                  <Select
                    labelId="item-packagingtype-label"
                    id="item-packagingtype"
                    label="Item Packaging Type"
                    // value={packagingtype.id}
                    value={selectedpackagingtype}
                    onChange={handlepackagintypeChange}
                    // disabled={!selecteditemsubcategory}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {packagingtype.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.itemValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Invoice No."
                  value={invoicenumber}
                  onChange={(e) => setInvoicenumber(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  type="file"
                  value={invoicefile.data}
                  onChange={InvoiceImage}
                  label="Upload Invoice"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  value={itemdesc}
                  onChange={(e) => setitemdesc(e.target.value)}
                  label="LSP Docket number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  disabled
                  label="MVikas Docket Number"
                  value={generatedmvikasdocketno}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <Button onClick={generatemvikasdocketnumber}>
                  Generate docket number
                </Button> */}
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="E-way Bill Number"
                  value={eWayBillNo}
                  required
                  onChange={(e) => seteWayBillNo(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  type="date"
                  label="E-way Bill Expiry Date"
                  value={eWayBillExpDate}
                  onChange={(e) => seteWayBillExpDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Transporter Name"
                  value={transporterName}
                  onChange={(e) => settransporterName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  // required
                  label="Other Information"
                  type="number"
                  value={otherInformation}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 50, pattern: "[0-9]" }}
                  //inputProps={{ min: 0, max: 50 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </>
      ),
    },
    // {
    //   label: "Payment Options",
    //   description: (
    //     <>
    //       <Stack sx={{ my: 2 }} spacing={2} direction="row">
    //         {topay ? (
    //           <Button
    //             size="large"
    //             variant="contained"
    //             onClick={topaypayment}
    //             startIcon={<AccountBalanceWalletRoundedIcon />}
    //           >
    //             To Pay
    //           </Button>
    //         ) : null}
    //         {/* {topay ? null : (
    //           <Button
    //             variant="outlined"
    //             size="large"
    //             onClick={handleRazorpayPayment}
    //           >
    //             Pay Now
    //           </Button>
    //         )} */}
    //         {topay ? null : (
    //           <Button
    //             variant="outlined"
    //             size="large"
    //             onClick={walletpayOpenm}
    //           // startIcon={<AccountBalanceWalletRoundedIcon />}
    //           >
    //             Pay Now
    //           </Button>
    //         )}
    //         {/* {topay ? null : (
    //           <Button
    //             disabled={
    //               payLaterDisabled || type_ === "Prepaid" || type_ === null
    //             }
    //             variant="contained"
    //             size="large"
    //             onClick={payitlater}
    //           >
    //             Pay Later
    //           </Button>
    //         )} */}

    //         {!topay && type_ !== "Prepaid" && type_ !== null && (
    //           <Button
    //             variant="contained"
    //             // disabled={
    //             //   payLaterDisabled
    //             // }
    //             size="large"
    //             onClick={payitlater}
    //           >
    //             Pay Later
    //           </Button>
    //         )}


    //       </Stack>
    //     </>
    //   ),
    // },

    {
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Preview and Pay
          {detailsCompleted && (
            <IconButton size="small" onClick={handleInfoClick}>
              <InfoIcon />
            </IconButton>
          )}
        </div>
      ),
      description: (
        <>
          <Stack sx={{ my: 2 }} spacing={2} direction="row">
            {topay ? (
              <Button
                size="large"
                variant="contained"
                onClick={topaypayment}
                startIcon={<AccountBalanceWalletRoundedIcon />}
              >
                To Pay
              </Button>
            ) : null}
            {/* {topay ? null : (
              <Button
                variant="outlined"
                size="large"
                onClick={handleRazorpayPayment}
              >
                Pay Now
              </Button>
            )} */}
            {topay ? null : (
              <Button
                variant="outlined"
                size="large"
                onClick={walletpayOpenm}
              // startIcon={<AccountBalanceWalletRoundedIcon />}
              >
                Pay Now
              </Button>
            )}
            {/* {topay ? null : (
              <Button
                disabled={
                  payLaterDisabled || type_ === "Prepaid" || type_ === null
                }
                variant="contained"
                size="large"
                onClick={payitlater}
              >
                Pay Later
              </Button>
            )} */}

            {!topay && type_ !== "Prepaid" && type_ !== null && (
              <Button
                variant="contained"
                // disabled={
                //   payLaterDisabled
                // }
                size="large"
                onClick={payitlater}
              >
                Pay Later
              </Button>
            )}


          </Stack>
        </>
      ),
    },
  ];

  useEffect(() => {
    getcustomerwalletbalance();
  }, [])


  const [availablewalletbalance, setavailablewalletbalance] = useState("")
  const getcustomerwalletbalance = async () => {
    let userid = localStorage.getItem("user_id")
      ; try {
        const result = await getAvailableWalletBalance(userid)

        // const response = result.json()
        console.log("my getcustomerwalletbalance response ", result);
        setavailablewalletbalance(result)

      } catch (error) {
        console.log("Error", error);
      }
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <Button
                  href="#/viewrate"
                  startIcon={<KeyboardBackspaceRoundedIcon />}
                >
                  Back
                </Button>
                <h1 className="main-title">Order Summary</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Order Summary</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <ToastContainer />
            <Box className="payment-ui">
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            className={
                              index === steps.length - 1
                                ? "btn-hide"
                                : "" || index === steps.length - 4
                                  ? "btn-hide"
                                  : ""
                            }
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Continue
                          </Button>
                          <Button
                            className={
                              index === steps.length - 4 ? "" : "btn-hide"
                            }
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Continue
                          </Button>
                          <Button
                            href="#/viewrate"
                            className={index === 0 ? "dis-none" : "btn-none"}
                          >
                            Back
                          </Button>

                          <Button
                            className={index === 0 ? "btn-none" : "dis-block"}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>

          {/* //PAYMENT COMPLETION DIALOG BOX */}
          <Dialog
            open={paynow}
            // open={paylater}
            TransitionComponent={Transition}
            keepMounted
            onClose={paynowClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent>
              <DoneAllIcon sx={{ fontSize: 60 }} color="success" />
              <DialogTitle>{"Your payment is successfully done!"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                Your order summary is:
                <TableContainer sx={{ mt: 2 }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Order ID</TableCell>
                        {/* <TableCell>Payment ID</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{paynowdata.orderdate}</TableCell>
                          <TableCell>{paynowdata.orderid}</TableCell>
                          {/* <TableCell>{paynowdata.paymentid}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              <Button href="#/Dashboard" className="ml-auto">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          {/* //PAY LATER DIALOG  BOX       */}
          <Dialog
            open={paylater}
            // open= {setpaylateropen(true)}
            TransitionComponent={Transition}
            keepMounted
            onClose={paylaterClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent style={{ maxWidth: "300px" }}>
              <img src="./images/wallet.svg" alt="" />
              <DialogTitle style={styles.title}>{"Pay Later!"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                <p style={styles.modalp}>Payment has been successfully done.</p>
                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}>Credit Limit</span>{" "}
                    <span style={styles.rightside}>
                      <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      {paylatercomplete.creditLimit}
                    </span>
                  </CardContent>
                </Card>
                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}> Total Amount </span>{" "}
                    <span style={styles.rightside}>
                      <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      {paylatercomplete.totalAmount}
                    </span>
                  </CardContent>
                </Card>


                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}>Order ID </span>{" "}
                    <span style={styles.rightside}>
                      {/* <CurrencyRupeeIcon sx={{ fontSize: 15 }} /> */}
                      {paylatercomplete.orderId}
                    </span>
                  </CardContent>
                </Card>

                {/* <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}> Payment ID </span>{" "}
                    <span style={styles.rightside}>
                     
                      {paylatercomplete.paymentId}
                    </span>
                  </CardContent>
                </Card> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              {/* <Button href="#" className="mr-auto">
              Back
            </Button> */}
              <Button href="#/Dashboard" variant="contained">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          {/* //TOPAY PAYMENT COMPLETION DIALOG BOX */}
          <Dialog
            open={topaynow}
            // open={paylater}
            TransitionComponent={Transition}
            keepMounted
            onClose={topaynowClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent>
              <DoneAllIcon sx={{ fontSize: 60 }} color="success" />
              <DialogTitle>{"Your order summary is:"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                Your order summary is:
                <TableContainer sx={{ mt: 2 }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Order ID</TableCell>
                        {/* <TableCell>Payment ID</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{topaynowdata.orderdate}</TableCell>
                          <TableCell>{topaynowdata.orderid}</TableCell>
                          {/* <TableCell>{topaynowdata.paymentid}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              <Button href="#/Dashboard" className="ml-auto">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          {/* //WALLETPAY COMPLETION DIALOG  BOX       */}
          <Dialog
            open={walletpay}
            // open= {setpaylateropen(true)}
            TransitionComponent={Transition}
            keepMounted
            onClose={walletpaydialogClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent>
              <DoneAllIcon sx={{ fontSize: 60 }} color="success" />
              <DialogTitle>{"Your payment is successfully done!"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                Your order summary is:
                <TableContainer sx={{ mt: 2 }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Order ID</TableCell>
                        {/* <TableCell>Payment ID</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{walletpaycomplete.orderdate}</TableCell>
                          <TableCell>{walletpaycomplete.orderid}</TableCell>
                          {/* <TableCell>{paynowdata.paymentid}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              {/* <Button href="#" className="mr-auto">
              Back
            </Button> */}
              <Button href="#/Dashboard" variant="contained">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </section>
      </div>

      {showProgress && (
        <div className="circular-progress-ui">
          <CircularProgress />
        </div>
      )}

      {/* //ADD ADDRESS DIALOG BOX */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        className="address-dialog"
      >
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Address Name"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Full Address"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    full_address: e.target.value,
                  }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="state-select-label">State</InputLabel>
                <Select
                  labelId="state-select-label"
                  id="state-select"
                  label="State"
                  onChange={handleStatechange}
                >
                  {state.map((state, index) => (
                    <MenuItem key={state} value={state}>
                      {" "}
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="state-select-label">City</InputLabel>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  label="City"
                  onChange={handlecitychange}
                >
                  {cities.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Pincode"
                type="text"
                onBlur={handleZipCodeBlur}
                onChange={(e) => {
                  const zipvalue = e.target.value;
                  if (/^\d{0,6}$/.test(zipvalue)) {
                    setNewAddress((val) => ({
                      ...val,
                      pincode: zipvalue,
                    }));
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                required
                fullWidth
                type="text"
                label="Mobile No"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }));
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                required
                label="Country"
                value="India"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={loading}
              onClick={() => {
                Addaddress(
                  newaddress,
                  (newaddress.state = selectedState),
                  (newaddress.city = selectedCity),
                  localStorage.getItem("user_email")
                );
              }}
            >
              Submit
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: red[50],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>

          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={messopen}
        autoHideDuration={2000}
        onOpen={messageopen}
        onClose={messageClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success" sx={{ width: "250px" }}>
          Changes saved successfully
        </Alert>
      </Snackbar>

      <Dialog
        open={walletpayopen}
        onClose={walletpayClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ maxWidth: "500px", minWidth: "500px" }}>
          <div className="add-money wallet-pay">
            <div className="wallet-header">
              <AccountBalanceWalletRoundedIcon sx={{ fontSize: 35 }} />
              <div className="text">
                <h2>₹{availablewalletbalance.balance}</h2>
                <p>Your wallet balance</p>
              </div>
              <IconButton className="ml-auto" onClick={walletpayClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            {/* <div className="wallet-body">
              <div className="text">
                <h5>₹{Data["total"]}</h5>
                <p>Order payment amount.</p>
              </div>
              <Button variant="contained" onClick={handlewalletpay} autoFocus>
                Pay
              </Button>
            </div>
            <div className="sufficient mt-2">
              <div className="wallet-body">
                <div className="text">
                  <h5>₹940.54</h5>
                  <p>Balance is not sufficient.</p>
                </div>
                <Button variant="contained" onClick={walletpayClose} autoFocus>
                  Add
                </Button>
              </div>
              <p className="sufficient-info">
                You need add ₹400.00 in your wallet to pay for this order.
              </p>
            </div> */}
            {availablewalletbalance.balance >= Data["total"] ? (
              // If wallet balance is sufficient, show pay view
              <div className="wallet-body">
                <div className="text">
                  <h5>₹{Data["total"]}</h5>
                  <p >Amount will be deducted from Wallet</p>
                </div>
                <Button variant="contained" onClick={handlewalletpay} autoFocus>
                  Continue
                </Button>
              </div>
            ) : (
              <div className="sufficient mt-2">
                <div className="wallet-body">
                  <div className="text">
                    <h5>₹{Number((Data["total"] - availablewalletbalance.balance).toFixed(2))}</h5>
                    <p>Balance is not sufficient.</p>
                  </div>
                  <Button variant="contained" onClick={handleRazorpayPayment} autoFocus>
                    Add
                  </Button>
                </div>
                <p className="sufficient-info">
                  You need to add ₹{Number((Data["total"] - availablewalletbalance.balance).toFixed(2))} in your wallet to pay for this order.
                </p>
              </div>
            )}
            <div className="wallet-body paynow mt-3">
              <div className="text">
                <h5>₹{Data["total"]}</h5>
                <p>More ways to make payment.</p>
              </div>
              <Button
                variant="contained"
                onClick={handleRazorpayPayment}
                autoFocus
              >
                Continue
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={paymentpreviewdialogOpen}
        onClose={handlepaymentpreviewCloseDialog}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">Payment Summary</DialogTitle>
        <DialogContent>
          {paymentpreviewdialogContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlepaymentpreviewCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
