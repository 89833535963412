import React, { useEffect, useState } from "react";
import Header from './Header';
import Sidebar from './Sidebar';
import ViewLowestrate from '../Components/ViewLowestrate';
import { useNavigate } from "react-router-dom";


const ViewlowestRateAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header />
      <Sidebar />
      <ViewLowestrate />
    </>
  )
}

export default ViewlowestRateAdmin
