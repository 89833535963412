import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import {
  GetOrderItemsList,
  downloadDocketcustomer,
  downloadMasterDocketcustomer,
} from "../../Services/UserApi";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import QRCode from "qrcode.react";
import "./pdfdocket.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CurrentUrl } from "../../Services/UrlApi";
import Button from "@mui/material/Button";
import { saveAs } from "file-saver";
import axios from "axios";
import logo from "./../../logo.png";

export default function BarcodeInner() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[3]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const [downloadLink, setDownloadLink] = useState(null);
  const [itemorderlist, setitemorderlist] = useState([]);
  const [docketpdfdata, setdocketpdfdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [apiloadingtext, setapiloadingtext] = useState(false);
  const [masterdocketloadingtext, setmasterdocketloadingtext] = useState(false);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [templateSize, setTemplateSize] = useState(0);
  useEffect(() => {}, [selectedRows]);

  useEffect(() => {
    loadcustomeritemorders();
    GetTemplateStatus();
  }, []);
  const GetTemplateStatus = async () => {
    const token = localStorage.getItem("Logkey");
    const userId = localStorage.getItem("user_id");
    return await axios
      .get(`${CurrentUrl}User/Getcurrentuserdetail?Id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setSelectedTemplate(
          response.data.isDefaultTemplate == null
            ? 0
            : response.data.isDefaultTemplate
        );
        setTemplateSize(response.data.templateSize);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };
  const loadcustomeritemorders = () => {
    setitemorderlist([]);
    setLoading(true);

    GetOrderItemsList(order_id)
      .then((response) => {
        setitemorderlist(response);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadDocket = async () => {
    setapiloadingtext(true);
    const response = await downloadDocketcustomer(order_id);

    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, "customerdocket.pdf");
      setapiloadingtext(false);
    } else {
      console.error("Failed to download docket");
    }
  };

  const handleDownloadMasterDocket = async () => {
    setmasterdocketloadingtext(true);
    const response = await downloadMasterDocketcustomer(order_id);

    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, "customermasterdocket.pdf");
      setmasterdocketloadingtext(false);
    } else {
      console.error("Failed to download docket");
    }
  };

  function pdfhandle(params) {
    console.log("pdf ", params.row);
    if (params.row && params.row.itemId) {
      setQRCodeURL(params.row.itemId);
      setdocketpdfdata(params.row);
      setTimeout(() => {
        GeneratePdf();
      }, 2000);
    } else {
      console.error("Item ID is missing or undefined for the selected row.");
    }
  }

  // function GeneratePdf() {
  //   try {
  //     console.log("");
  //     html2canvas(document.querySelector("#pdf-content"), {
  //       useCORS: true,
  //     }).then(function (canvas) {
  //       let img = new Image();
  //       img.src = canvas.toDataURL("image/png");
  //       img.onload = function () {
  //         let pdf = new jsPDF("p", "mm", [297, 210]);
  //         pdf.addImage(img, 0, 0);
  //         pdf.save("Mydocket.pdf");
  //       };
  //     });
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  //   setdocketpdfdata({});
  //   setQRCodeURL("");
  // }
  function GeneratePdf() {
    try {
      html2canvas(document.querySelector("#pdf-content"), {
        useCORS: true,
      }).then(function (canvas) {
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = function () {
          let pdf;
          let width, height;
          // Set PDF size based on templateSize
          switch (templateSize) {
            case 'A3':
              width = 420;
              height = 297;
              pdf = new jsPDF("p", "mm", [width, height]);
              break;
            case 'A4':
              width = 297;
              height = 210;
              pdf = new jsPDF("p", "mm", [width, height]);
              break;
            case 'A5':
              width = 210;
              height = 148;
              pdf = new jsPDF("p", "mm", [width, height]);
              break;
            default:
              width = 297;
              height = 210;
              pdf = new jsPDF("p", "mm", [width, height]); // Default to A4 if no valid page size is provided
          }
  
          // Get the image dimensions and scale them to fit the PDF page size
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
  
          // Calculate the scaling factor to fit the image within the PDF dimensions
          const scaleFactor = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  
          // Calculate the scaled dimensions
          const scaledWidth = imgWidth * scaleFactor;
          const scaledHeight = imgHeight * scaleFactor;
  
          // Center the image on the PDF page
          const x = (pdfWidth - scaledWidth) / 2;
          const y = (pdfHeight - scaledHeight) / 2;
  
          pdf.addImage(img, 'PNG', 0, 0, scaledWidth, scaledHeight);
          pdf.save("Mydocket.pdf");
        };
      });
    } catch (e) {
      console.log("error", e);
    }
    setdocketpdfdata({});
    setQRCodeURL("");
  }
  
  
  function LoadServerImage(partialpath) {
    let serverUrl = CurrentUrl.slice(0, -1);
    return serverUrl + partialpath;
  }

  const columns = [
    { field: "orderid", headerName: "Order Id", width: 80 },
    { field: "itemId", headerName: "Item ID ", width: 100 },
    { field: "length", headerName: "Length", width: 80 },
    { field: "breadth", headerName: "Breadth ", width: 80 },
    { field: "height", headerName: "Height", width: 80 },
    {
      field: "download",
      headerName: "Download Label",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <FileDownloadOutlinedIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Barcode</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Barcode</li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="ms-auto">
                <Button
                  onClick={handleDownloadDocket}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  {apiloadingtext ? "Downloading..." : "Download Docket"}
                </Button>

                <Button
                  onClick={handleDownloadMasterDocket}
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                >
                  {masterdocketloadingtext ? "Downloading..." : "Master Docket"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow mt-3">
            {loading ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
              </div>
            ) : (
              <div>
                <DataGrid
                  sx={{
                    p: "1rem",
                    "@media print": {
                      ".table-ui": { border: "none" },
                    },
                  }}
                  className="table-ui"
                  rows={itemorderlist}
                  columns={columns}
                  // checkboxSelection={true}
                  // onSelectionModelChange={handleSelectionChange}
                  getRowId={(row) => row.id ?? row.itemId}
                  autoHeight={true}
                  height={200}
                  pagination
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPageSizeChange={(newPageSize) => {
                    setRowsPerPage(newPageSize);
                  }}
                />
                {/* <Button
                  variant="outline"
                  color="primary"
                  onClick={downloadAllPDFs}
                  startIcon={<PictureAsPdfIcon />}
                >
                  Download All
                </Button> */}
              </div>
            )}
          </Card>
        </div>
      </section>

      {docketpdfdata && docketpdfdata.itemId ? (
        <>
          {selectedTemplate === 0 && (
            <div id="pdf-content">
              <div
                className="page"
                style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
              >
                <table
                  style={{
                    width: "100%",
                    border: "2px solid #000",
                    borderSpacing: "0px",
                    fontFamily: "DM Sans, Arial, Helvetica, sans-serif",
                    fontSize: "14px",
                  }}
                >
                  <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td
                        style={{
                          borderRight: "0px solid #000",
                          padding: "10px",
                          width: "30%",
                          textAlign: "left",
                        }}
                      >
                        <img
                          src="images/logo-pdf.png"
                          style={{ height: "50px" }}
                        />
                      </td>

                      <td
                        style={{
                          padding: "10px",
                          width: "50%",
                          textAlign: "right",
                          color: "#333",
                        }}
                      >
                        <img
                          src={LoadServerImage(docketpdfdata.lpImg)}
                          alt="LP"
                          style={{ height: "50px" }}
                        />{" "}
                        <br />
                        <b>Order ID</b>: {docketpdfdata.orderid}
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "top" }}>
                      <td
                        colSpan={2}
                        style={{
                          border: "2px solid #000",
                          borderLeft: "0px",
                          padding: "10px",
                          fontSize: "16px",
                          fontWeight: "bold",
                          lineHeight: "22px",
                        }}
                      >
                        <b style={{ fontSize: "14px" }}>Master:</b>{" "}
                        {docketpdfdata.orderid}
                        <br />
                        {/* <b style={{ fontSize: "14px" }}>LRN:</b> {docketpdfdata.orderId} */}
                        <b style={{ fontSize: "14px" }}>Docket Number:</b>{" "}
                        {docketpdfdata.mvikasDocketNumber}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ padding: "10px", textAlign: "center" }}
                      >
                        <QRCode value={docketpdfdata.itemId} size={100} />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ borderTop: "2px solid #000", padding: "0" }}
                      >
                        <table style={{ width: "100%", borderSpacing: "0px" }}>
                          <tr style={{ verticalAlign: "top" }}>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                fontSize: "16px",
                              }}
                            >
                              <b>Box: {docketpdfdata.boxes} </b>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Child Number</b>
                              <br />
                              {docketpdfdata.itemId}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Length</b>
                              <br />
                              {docketpdfdata.length}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Breadth</b>
                              <br />
                              {docketpdfdata.breadth}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "0px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Height</b>
                              <br />
                              {docketpdfdata.height}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ borderTop: "2px solid #000", padding: "0" }}
                      >
                        <table style={{ width: "100%", borderSpacing: "0px" }}>
                          <tr style={{ verticalAlign: "top" }}>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Item Name</b>
                              <br />
                              {docketpdfdata.itemName}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Item Type</b>
                              <br />
                              {docketpdfdata.itemType}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Item Category</b>
                              <br />
                              {docketpdfdata.itemCategory}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "2px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Item Subcategory</b>
                              <br />
                              {docketpdfdata.itemSubcategory}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                borderRight: "0px solid #000",
                                color: "#333",
                              }}
                            >
                              <b>Packaging Type</b>
                              <br />
                              {docketpdfdata.packagingRequired}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "top" }}>
                      <td
                        colSpan="2"
                        style={{
                          borderTop: "2px solid #000",
                          padding: "10px",
                          color: "#333",
                        }}
                      >
                        <b>Pickup address:</b>
                        <br />
                        {docketpdfdata.pickupAddress}
                      </td>
                    </tr>

                    <tr style={{ verticalAlign: "top" }}>
                      <td
                        colSpan="2"
                        style={{
                          borderTop: "2px solid #000",
                          padding: "10px",
                          color: "#333",
                        }}
                      >
                        <b>Delivery address:</b>
                        <br />
                        {docketpdfdata.deliveryAddress}
                      </td>
                    </tr>

                    <tr style={{ verticalAlign: "top" }}>
                      <td
                        colSpan="2"
                        style={{
                          borderTop: "2px solid #000",
                          padding: "10px",
                          color: "#333",
                        }}
                      >
                        <b>Return address:</b>
                        <br />
                        {docketpdfdata.returnAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTemplate === 1 && (
            <div id="pdf-content">
              <div className="table-pdf">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td style={{ textAlign: "center" }}>
                        <img src={logo} width="80px" alt="Logo" />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderTop: "1px solid #ddd",
                    borderSpacing: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "13px" }}>
                        {/* Order Date: <b>{docketpdfdata.orderDate}</b>
                        <br /> */}
                        Master No: <b>{docketpdfdata.orderid}</b>
                        <br />
                        Docket No: <b>{docketpdfdata.mvikasDocketNumber}</b>
                        <br />
                        Box: <b>{docketpdfdata.boxes}</b>
                        <br />
                        Child No: <b>{docketpdfdata.itemId}</b>
                      </td>
                      <td style={{ fontSize: "13px" }}>
                        {/* Customer Id: <b>{docketpdfdata.invoiceNo}</b> */}
                        {/* <br />
                        Dispatch Date: <b>{docketpdfdata.dispatchDate}</b>
                        <br />
                        Delivery Methods: <b>{docketpdfdata.deliveryMethod}</b> */}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <QRCode value={docketpdfdata.itemId} size={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderTop: "1px solid #ddd",
                    borderSpacing: "0px 10px",
                  }}
                >
                  <tbody style={{ verticalAlign: "top" }}>
                    <tr>
                      <td style={{ fontSize: "13px", width: "25%" }}>
                        <table style={{ width: "100%", borderSpacing: "10px" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  background: "#f5f5f5",
                                  padding: "10px",
                                }}
                              >
                                <b>Pickup address</b>
                                <p style={{ margin: "0" }}>
                                  {docketpdfdata.pickupAddress}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  background: "#f5f5f5",
                                  padding: "10px",
                                }}
                              >
                                <b>Delivery address</b>
                                <p style={{ margin: "0" }}>
                                  {docketpdfdata.deliveryAddress}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  background: "#f5f5f5",
                                  padding: "10px",
                                }}
                              >
                                <b>Return address</b>
                                <p style={{ margin: "0" }}>
                                  {docketpdfdata.returnAddress}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ fontSize: "13px", width: "75%" }}>
                        <table
                          style={{ width: "100%", borderSpacing: "0px 10px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  background: "#ffd7b4",
                                  fontSize: "13px",
                                  padding: "5px",
                                }}
                              >
                                Item
                              </th>
                              <th
                                style={{
                                  background: "#ffd7b4",
                                  fontSize: "13px",
                                  padding: "5px",
                                }}
                              >
                                Description
                              </th>
                              <th
                                style={{
                                  background: "#ffd7b4",
                                  fontSize: "13px",
                                  padding: "5px",
                                }}
                              >
                                Ordered
                              </th>
                              <th
                                style={{
                                  background: "#ffd7b4",
                                  fontSize: "13px",
                                  padding: "5px",
                                }}
                              >
                                Delivered
                              </th>
                              <th
                                style={{
                                  background: "#ffd7b4",
                                  fontSize: "13px",
                                  padding: "5px",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {docketpdfdata.itemName}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                             Box Dimension (L*B*H)   {docketpdfdata.length} * {docketpdfdata.breadth} * {docketpdfdata.height}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Ordered
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Delivered
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {docketpdfdata.taxableAmount}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="3"></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "#ffd7b4",
                                  padding: "5px",
                                }}
                              >
                                <b>Sub Total</b>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <b> {docketpdfdata.taxableAmount}</b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3"></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "#ffd7b4",
                                  padding: "5px",
                                }}
                              >
                                <b>Tax {docketpdfdata.gst}%</b>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <b> {docketpdfdata.gstAmount}</b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3"></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "#ffd7b4",
                                  padding: "5px",
                                }}
                              >
                                <b>Total</b>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <b> {docketpdfdata.totalAmount}</b>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ width: "100%", fontSize: "13px" }}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Terms and Conditions</b>
                        <br />
                      <p>a. Consignee is responsible  for illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Thank you for your business!</b>
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTemplate === 2 && (
            <div id="pdf-content">
              <div className="table-pdf">
                <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                  <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td>
                        <img src={logo} width="80px" alt="Logo" />
                      </td>
                      <td style={{ textAlign: "right" }}>
                      <QRCode value={docketpdfdata.itemId} size={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                  <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                    <tr>
                      <td>
                        <table
                          style={{ width: "100%", border: "1px solid #ddd" }}
                        >
                          <tbody>
                            {/* <tr>
                              <td>Invoice No:</td>
                              <td>
                                <b>{docketpdfdata.invoiceNo}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Shipping Date:</td>
                              <td>
                                <b>{docketpdfdata.dispatchDate}</b>
                              </td>
                            </tr> */}
                            <tr>
                              <td>Docket No:</td>
                              <td>
                                <b>{docketpdfdata.mvikasDocketNumber}</b>
                              </td>
                            </tr>
                            <tr>
                                <td>Child No:</td>
                                <td>
                                  <b>{docketpdfdata.itemId}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Box:</td>
                                <td>
                                  <b>{docketpdfdata.boxes}</b>
                                </td>
                              </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table style={{ width: "100%", textAlign: "right" }}>
                          <tbody>
                            <tr>
                              <td style={{ padding: "5px" }}>Master No: </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  width: "150px",
                                  padding: "5px",
                                }}
                              >
                                <b>{docketpdfdata.orderid}</b>
                              </td>
                            </tr>
                            {/* <tr>
                              <td style={{ padding: "5px" }}>Order Date:</td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "5px",
                                }}
                              >
                                <b>{docketpdfdata.orderDate}</b>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                  <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <table
                          style={{
                            width: "100%",
                            border: "1px solid #ddd",
                            borderSpacing: "0",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  background: "#ffd7b4",
                                  padding: "5px",
                                }}
                              >
                               Delivery Address Details
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "5px" }}>Name:</td>
                              <td style={{ padding: "5px" }}>
                                <b>{docketpdfdata.deliveryPersonName}</b>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "5px" }}>Address:</td>
                              <td style={{ padding: "5px" }}>
                                <b>{docketpdfdata.deliveryAddress}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "50%" }}>
                        <table
                          style={{
                            width: "100%",
                            border: "1px solid #ddd",
                            borderSpacing: "0",
                            marginLeft: "auto",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  background: "#ffd7b4",
                                  padding: "5px",
                                }}
                              >
                               Pickup Address Details
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "5px" }}>Name:</td>
                              <td style={{ padding: "5px" }}>
                                <b>{docketpdfdata.pickupPersonName}</b>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "5px" }}>Address:</td>
                              <td style={{ padding: "5px" }}>
                                <b>{docketpdfdata.pickupAddress}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Item
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Item Description
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Quantity Ordered
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {docketpdfdata.itemName}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                                 Box Dimension (L*B*H)   {docketpdfdata.length} * {docketpdfdata.breadth} * {docketpdfdata.height}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                             {docketpdfdata.taxableAmount}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                              {docketpdfdata.boxes}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {docketpdfdata.taxableAmount}
                          </td>
                        </tr>
                    
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3"></td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          padding: "5px",
                        }}
                      >
                        <b>Sub Total</b>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        <b>{docketpdfdata.taxableAmount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          padding: "5px",
                        }}
                      >
                        <b>Tax    {docketpdfdata.gst}%</b>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        <b>   {docketpdfdata.gstAmount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td
                        style={{
                          textAlign: "center",
                          background: "#ffd7b4",
                          padding: "5px",
                        }}
                      >
                        <b>Total</b>
                      </td>
                      <td
                        style={{ textAlign: "center", background: "#ffd7b4" }}
                      >
                        <b>{docketpdfdata.totalAmount}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <table style={{ width: "50%", fontSize: "13px" }}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Terms and Conditions</b>
                        <br />
                        <p>a. Consignee is responsible  for illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        <b>Thank You</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTemplate === 3 && (
            <div id="pdf-content">
              <div className="table-pdf">
                <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                  <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td>
                        <img src={logo} width="80px" alt="logo" />
                      </td>
                      <td style={{ fontSize: "13px", textAlign: "center" }}>
                        {/* <b>Order ID:</b> {docketpdfdata.orderid} */}
                        <br />
                        <b>Docket No.</b> {docketpdfdata.mvikasDocketNumber}
                        <br />
                      </td>
                      <td style={{ textAlign: "right" }}>
                      <QRCode value={docketpdfdata.itemId} size={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    marginTop: "25px",
                  }}
                >
                  <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                    <tr>
                      <td>
                        <b>Master No:</b>
                      </td>
                      <td>{docketpdfdata.orderid}</td>
                    </tr>
                    {/* <tr>
                      <td>
                        <b>Requested Date:</b>
                      </td>
                      <td>{docketpdfdata.orderDate}</td>
                    </tr> */}
                    <tr>
                      <td>
                        <b> Pickup Address:</b>
                      </td>
                      <td>{docketpdfdata.pickupAddress}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Delivery Address:</b>
                      </td>
                      <td>{docketpdfdata.deliveryAddress}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Boxes:</b>
                      </td>
                      <td>{docketpdfdata.boxes}</td>
                    </tr>
                    {/* <tr>
                      <td>
                        <b>Email:</b>
                      </td>
                      <td>{docketpdfdata.customerEmail}</td>
                    </tr> */}
                    {/* <tr>
                      <td>
                        <b>Date:</b>
                      </td>
                      <td>{docketpdfdata.dispatchDate}</td>
                    </tr> */}
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                    marginTop: "25px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Quantity
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          background: "#ffd7b4",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
              
                      <tr>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                                 Box Dimension (L*B*H)   {docketpdfdata.length} * {docketpdfdata.breadth} * {docketpdfdata.height}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                                {docketpdfdata.boxes}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                            {docketpdfdata.taxableAmount}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                            {docketpdfdata.taxableAmount}
                        </td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #ddd",
                          padding: "5px",
                        }}
                      >
                        <b>Sub Total</b>
                      </td>
                      <td
                        colSpan="2"
                        style={{ borderBottom: "1px solid #ddd" }}
                      ></td>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <b>{docketpdfdata.taxableAmount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #ddd",
                          padding: "5px",
                        }}
                      >
                        <b>Tax {docketpdfdata.gst}%</b>
                      </td>
                      <td
                        colSpan="2"
                        style={{ borderBottom: "1px solid #ddd" }}
                      ></td>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <b>{docketpdfdata.gstAmount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", padding: "5px" }}>
                        <b>Total</b>
                      </td>
                      <td colSpan="2"></td>
                      <td style={{ textAlign: "center" }}>
                        <b>{docketpdfdata.totalAmount}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <table
                  style={{
                    width: "100%",
                    fontSize: "13px",
                    marginTop: "35px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>Terms and Conditions</b>
                        <br />
                        <ol style={{ paddingLeft: "15px", lineHeight: "25px" }}>
                          <li>
                          Consignee is responsible  for illegal goods
                          </li>
                          <li>
                          Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTemplate === 4 && (
            <div id="pdf-content">
              <div className="table-pdf">
                <table
                  style={{
                    width: "100%",
                    borderSpacing: "20px 10px",
                    background: "#ddd",
                  }}
                >
                  <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td>
                        <img src={logo} width="80px" alt="Logo" />
                      </td>
                      <td style={{ fontSize: "13px", textAlign: "center" }}>
                        <b>Master No:</b> {docketpdfdata.orderid}
                        <br />
                        <b>Docket No.</b> {docketpdfdata.mvikasDocketNumber}
                        <br />
                      </td>
                      <td style={{ textAlign: "right" }}>
                      <QRCode value={docketpdfdata.itemId} size={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    marginTop: "25px",
                  }}
                >
                  <tbody style={{ verticalAlign: "top", fontSize: "13px" }}>
                    {/* <tr>
                      <td
                        style={{
                          textTransform: "uppercase",
                          fontSize: "18px",
                        }}
                      >
                        <b>purchase order</b>
                      </td>
                      <td>
                      {docketpdfdata.mvikasDocketNumber}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>
                        <b>Order Date:</b>
                      </td>
                      <td>
                      {docketpdfdata.orderDate}
                      </td>
                    </tr> */}
                    <tr>
                      <td>
                        <b>Item No:</b>
                      </td>
                      <td>
                      {docketpdfdata.itemId}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                    marginTop: "25px",
                    border: "1px solid #000",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderBottom: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>Pickup Information</b>
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #000",
                          borderLeft: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>Shipping Information</b>
                      </td>
                    </tr>
                  
                    <tr>
                      <td style={{ padding: "10px" }}>
                        <b>Address: {docketpdfdata.pickupAddress}</b>
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderLeft: "1px solid #000",
                        }}
                      >
                        <b>Address: {docketpdfdata.deliveryAddress}</b>
                      </td>
                    </tr>
                  
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                    marginTop: "25px",
                    border: "1px solid #000",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        Item Code
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #000",
                          borderLeft: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #000",
                          borderLeft: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        Quantity
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #000",
                          borderLeft: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        Unit Price
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #000",
                          borderLeft: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
            
                      <tr>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                          }}
                        >
                      {docketpdfdata.itemId}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                          }}
                        >
                      Box Dimension (L*B*H)   {docketpdfdata.length} * {docketpdfdata.breadth} * {docketpdfdata.height}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                          }}
                        >
                         {docketpdfdata.boxes}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                          }}
                        >
                          {docketpdfdata.taxableAmount}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                            borderLeft: "1px solid #000",
                          }}
                        >
                         {docketpdfdata.taxableAmount}
                        </td>
                      </tr>
                 
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                    marginTop: "25px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: "5px 0" }}>
                        <b>Total Net:  {docketpdfdata.taxableAmount}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "5px 0" }}>
                        <b>Tax:  {docketpdfdata.gstAmount}</b>
                      </td>
                    </tr>
                    
                    <tr style={{ textAlign: "right" }}>
                      <td style={{ padding: "5px 0" }}>
                        <b>Total: {docketpdfdata.totalAmount}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0px",
                    fontSize: "13px",
                    marginTop: "25px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "10px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>Terms and Conditions</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #000",
                          borderTop: "0",
                          padding: "10px",
                          textTransform: "uppercase",
                        }}
                      >
                       
                        <p>a. Consignee is responsible  for  illegal goods.</p> 
                      <p>b. Carrier  is not responsible  for any Damage, Breakage,
                        Fire, Leakage & Accident.</p> 
                      </td>
                    </tr>
                  </tbody>
                </table>

             
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}

      {downloadLink && (
        <a
          href={downloadLink}
          download="QR_Code.png"
          style={{ display: "none" }}
        />
      )}
    </div>
  );
}
