import * as React from 'react';
import Card from '@mui/material/Card';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const columns = [
  { field: 'id', headerName: 'ID'},
  { field: 'issuedate', headerName: 'Issue Date', type: 'date', flex: 1},
  { field: 'title', headerName: 'Title', flex: 1},
  {
    field: 'totalamount',
    headerName: 'Total Amount',
    flex: 1
  },
  {
    field: 'pdf',
    headerName: 'PDF',
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return <Link href="#"><Avatar sx={{ width: 30, height: 30, bgcolor: grey[300] }}><DownloadOutlinedIcon fontSize="small" sx={{ color: grey[900] }} /></Avatar></Link>;
    },
  },
];

const rows = [
  { id: 1, title: 'Rave Transit', issuedate: '01-01-2022', totalamount: 1500 },
  { id: 2, title: 'Globelink Star Shipping', issuedate: '05-01-2022', totalamount: 500 },
  { id: 3, title: 'Carefree Moving Company', issuedate: '10-02-2022', totalamount: 2500 },
  { id: 4, title: 'Transport Right', issuedate: '05-03-2022', totalamount: 250 },
  { id: 5, title: 'Morrex Cargo', issuedate: '15-03-2022', totalamount: 1550 },
  { id: 6, title: 'Finofit Cargo', issuedate: '13-04-2022', totalamount: 1450 },
  { id: 7, title: 'White Oak Transport', issuedate: '18-04-2022', totalamount: 454 },
  { id: 8, title: 'Red Star Express', issuedate: '25-04-2022', totalamount: 360 },
  { id: 9, title: 'Amazing Trucking & Logistics', issuedate: '17-05-2022', totalamount: 650 },
];

export default function Reports() {
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Reports</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Reports</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className='custum-shadow'>
            <div className='row'>
              <div className='col-sm-3'>
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value="allreport"
                    label="Report Type"
                  >
                    <MenuItem value="allreport">All Reports</MenuItem>
                    <MenuItem value="ordersreport">Orders Report</MenuItem>
                    <MenuItem value="paymentreport">Payment Report</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className='col'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className='col'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

            </div>
          </Card>

          <Card className='custum-shadow mt-3'>
            <div style={{ height: '80vh', width: '100%' }}>
              <DataGrid sx={{ p: "1rem" }} disableColumnSelector className='table-ui'
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </Card>
        </div>
      </section>
    </div>
  );
}