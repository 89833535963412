import * as React from "react";
import axios from "axios";
import Header from "./Header";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import DialogContentText from "@mui/material/DialogContentText";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  CustomerChangePassword,
  AddCustomerBankDetails,
  UpdateUserDetails,
  GetMvikasAccountSettings,
  GetAddressList,
  AddNewAddress,
  GetBankList,
  GetcityByState,
  GetCompanyCity,
  GetBusinessCity,
  GetStateList,
  Viewaddress,
  Viewbankdetails,
} from "../Services/UserApi";
import { useState, useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingIndicator from "react-loading-indicator";
import { WindowSharp } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { CurrentUrl } from "../Services/UrlApi";

import Autocomplete from "@mui/material/Autocomplete";

let Url = `${CurrentUrl}User/`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

var passData = {
  email: localStorage.getItem("user_email"),
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

var banking = {
  customerid: "",
  accountNo: "",
  ifscCode: "",
  bankName: "",
  beneficiaryName: "",
  email: localStorage.getItem("user_email"),
};

var editaddress = {
  name: "",
  full_address: "",
  pincode: "",
  state: "",
  city: "",
  country: "",
  phone: "",
  email: localStorage.getItem("user_email"),
};

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [Data, setData] = useState({});
  const [accounttype, setAccountType] = useState({});
  const [currentaddress, setCurrentaddress] = useState([]);
  const [mydata, setMydata] = useState(null);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [companycities, setCompanycities] = useState([]);
  const [businesscities, setBusinesscities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [editselectedState, seteditSelectedState] = useState("");
  const [editselectedCity, seteditSelectedCity] = useState("");
  const [selectedcompanystate, setSelectedcompanystate] = useState("");
  const [selectedcompanycity, setSelectedcompanycity] = useState("");
  const [selectedbusinessstate, setSelectedbusinessstate] = useState("");
  const [selectedbusinesscity, setSelectedbusinesscity] = useState("");
  const [editopen, setEditAddressOpen] = useState(false);
  const [selectedbankdetails, setSelectedbankdetails] = useState([]);
  const [editableAddressId, setEditableAddress] = useState("");
  const [passmessageOpen, setpassMessageOpen] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [newaddress, setNewAddress] = useState({
    name: "",
    full_address: "",
    pincode: "",
    state: "",
    city: "",
    country: "India",
    phone: "",
    email: localStorage.getItem("user_email"),
  });

  var formData = {
    id: "",
    email: "",
    lpName: "",
    personNames: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    monthlyShipping: "",
    dealItem: "",
    averageShipmentWeight: "",
    cAddressLine1: "",
    cAddressLine2: "",
    cCity: "",
    cState: "",
    cZipCode: "",
    cCountry: "",
    cCompanyDescriptions: "",
    cRemarks: "",
    bAddressLine1: "",
    bAddressLine2: "",
    bCity: "",
    bState: "",
    bZipCode: "",
    bCountry: "",
    bRemarks: "",
  };

  // function getuserdetaildata() {
  //   let localuserdetail = localStorage.getItem("local_userdetail_data");
  //   if (localuserdetail !== null) {
  //     let userdetail = JSON.parse(localuserdetail);
  //     setMydata(userdetail);
  //     console.log("my name data", userdetail)
  //   }
  // }

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleStatechange = (e) => {
    setSelectedState(e.target.value);
  };
  const handlecitychange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleeditStatechange = (e) => {
    seteditSelectedState(e.target.value);
  };
  const handleeditcitychange = (e) => {
    seteditSelectedCity(e.target.value);
  };

  const handlecompanyStatechange = (e) => {
    setSelectedcompanystate(e.target.value);
  };
  const handlecompanycitychange = (e) => {
    setSelectedcompanycity(e.target.value);
  };

  const handlebusinessStatechange = (e) => {
    setSelectedbusinessstate(e.target.value);
  };
  const handlebusinesscitychange = (e) => {
    setSelectedbusinesscity(e.target.value);
  };

  useEffect(() => {
    if (selectedState) {
      GetcityByState(selectedState).then((result) => {
        setCities(result);
      });
    } else {
      setCities([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (editselectedState) {
      GetcityByState(editselectedState).then((result) => {
        setCities(result);
      });
    } else {
      setCities([]);
    }
  }, [editselectedState]);

  useEffect(() => {
    if (selectedcompanystate) {
      GetCompanyCity(selectedcompanystate).then((result) => {
        setCompanycities(result);
      });
    } else {
      setCompanycities([]);
    }
  }, [selectedcompanystate]);

  useEffect(() => {
    if (selectedbusinessstate) {
      GetBusinessCity(selectedbusinessstate).then((result) => {
        setBusinesscities(result);
      });
    } else {
      setBusinesscities([]);
    }
  }, [selectedbusinessstate]);

  useEffect(() => {
    // getuserdetaildata();
    GetCustomerBasicDetails(localStorage.getItem("user_email"));

    GetMvikasAccountSettings(localStorage.getItem("user_email"))
      .then((result) => {
        setAccountType(result.data);
      })
      .catch((r) => {
        console.log(r);
      });

    GetBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setBankData(result.data);
        var defaultdata = result.data.filter((a) => a.isDefault == true);
        setSelectedBank(defaultdata[0].accountNo);
      })
      .catch((r) => {
        console.log(r);
      });

    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const GetCustomerBasicDetails = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetCustomerBasicDetails?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((r) => {
        var formData = {
          id: "",
          email: "",
          lpName: "",
          personNames: "",
          mobileNumber: "",
          gst: "",
          pan: "",
          custSecondaryEmail: "",
          monthlyShipping: "",
          dealItem: "",
          averageShipmentWeight: "",
          cAddressLine1: "",
          cAddressLine2: "",
          cCity: "",
          cState: "",
          cZipCode: "",
          cCountry: "",
          cCompanyDescriptions: "",
          cRemarks: "",
          bAddressLine1: "",
          bAddressLine2: "",
          bCity: "",
          bState: "",
          bZipCode: "",
          bCountry: "",
          bRemarks: "",
        };
        Object.keys(r.data).forEach((e) => {
          if (r.data[e] != null && formData[e] == "") {
            formData[e] = r.data[e];
          }
          if (e == "cState") {
            setSelectedcompanystate(r.data[e]);
          }
          if (e == "cCity") {
            setSelectedcompanycity(r.data[e]);
          }
          if (e == "bState") {
            setSelectedbusinessstate(r.data[e]);
          }
          if (e == "bCity") {
            setSelectedbusinesscity(r.data[e]);
          }
        });
        setData(formData);

        setTimeout(() => {
          setData(formData);
        }, 100);
      })
      .catch((error) => {
        return error.response;
      });
  };

  const Refreshadddelete = () => {
    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Deleteaddress = async (emailVal) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${Url}DeleteAddress?Address_Id=${emailVal}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        Refreshadddelete(emailVal);

        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 100 },
    // { field: "address_Id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Address Name", minWidth: 150 },
    { field: "city", headerName: "City", minWidth: 50 },
    { field: "state", headerName: "State", minWidth: 200 },
    { field: "country", headerName: "Country", minWidth: 50 },
    { field: "pincode", headerName: "Pincode", minWidth: 50 },
    { field: "phone", headerName: "Mobile No.", minWidth: 80 },
    { field: "full_address", headerName: "Full Address", minWidth: 300 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 150,

      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <Button onClick={() => Openpopwithdetails(params.row.address_Id)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>

            <Button onClick={() => Deleteaddress(params.row.address_Id)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
  ];

  const handlvalditmodalClose = () => {
    setEditAddressOpen(false);
  };
  const handleSubmit = () => {
    setOpen(false);
  };

  const CallAddressUpdateOperation = () => {
    let editselectedaddress = {
      address_Id: currentaddress.address_Id,
      name: currentaddress.name,
      full_address: currentaddress.full_address,
      pincode: currentaddress.pincode,
      state: currentaddress.state,
      city: currentaddress.city,
      country: currentaddress.country,
      phone: currentaddress.phone,
      email: localStorage.getItem("user_email"),
    };

    UpdateAddress(
      editselectedaddress,
      (editselectedaddress.state = editselectedState),
      (editselectedaddress.city = editselectedCity),
      localStorage.getItem("user_email")
    );
  };

  const UpdateAddress = async (editselectedaddress, emailVal) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${Url}UpdateNewAddress`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editselectedaddress),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            handlvalditmodalClose();
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              Refreshafterupdateaddress(emailVal);
            }, 2000);
          }
        }
        // setSelectedbankdetails(res.data);
        // return res;
      })
      .catch((e) => {
        console.log("update bank error", e);
        return e;
      });
  };

  const Refreshafterupdateaddress = () => {
    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  // seteditSelectedState(respone.data.state[0])

  const Openpopwithdetails = async (id) => {
    let respone = await Viewaddress(id).then((r) => {
      seteditSelectedState(r.data[0].state);
      seteditSelectedCity(r.data[0].city);
      return r;
    });

    if (respone != null) {
      setCurrentaddress(respone.data[0]);
      setEditAddressOpen(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //add new address dialog box function
  const BankClickOpen = () => {
    setOpen(true);
  };
  //close new address dialog box function
  const BankClose = () => {
    setOpen(false);
  };

  const updateBankClickOpen = () => {
    setOpen(true);
  };
  const updateBankClose = () => {
    setOpen(false);
  };

  const [messopen, meassageOpen] = React.useState(false);
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    GetCustomerBasicDetails(localStorage.getItem("user_email"));
    meassageOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const RefreshAddress = (emailVal) => {
    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addaddress = async (newadd, emailVal) => {
    let addvalidation = true;
    if (
      newadd.name == null ||
      newadd.name == "" ||
      newadd.full_address == null ||
      newadd.full_address == "" ||
      newadd.pincode == null ||
      newadd.pincode == "" ||
      newadd.phone == null ||
      newadd.phone == "" ||
      newadd.state == null ||
      newadd.state == "" ||
      newadd.city == null ||
      newadd.city == ""
    ) {
      toast.warn("Please validate all address fields");
      addvalidation = false;
    }
    if (addvalidation) {
      // setLoading(true);
      let respone = await AddNewAddress(newadd);
      setLoading(false);
      if ("status" in respone) {
        if (respone.status == 200) {
          setOpen(false);
          setSuccess(true);
          meassageOpen(true);
          timer.current = window.setTimeout(() => {
            setSuccess(false);
            meassageOpen(false);
            RefreshAddress(emailVal);
          }, 2000);
        }
      }
    }
  };

  //BANK SECTION
  const AddBanks = async (bank, emailVal) => {
    setLoading(true);
    let respone = await AddCustomerBankDetails(bank);
    setLoading(false);
    if ("status" in respone) {
      if (respone.status == 200) {
        setOpenBank(false);
        setSuccess(true);
        meassageOpen(true);
        timer.current = window.setTimeout(() => {
          setSuccess(false);
          meassageOpen(false);
          RefreshBankData(emailVal);
        }, 2000);
      }
    }
  };

  const RefreshBankData = (emailVal) => {
    GetBankList(emailVal)
      .then((result) => {
        setBankData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Deletebank = async (accountNo, emailVal) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${Url}DeleteBankAccount?AccountNo=${accountNo}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        // if ("status" in res) {
        //   if (res.status == 200) {
        //     setOpen(false);
        //     setSuccess(true);
        //     meassageOpen(true);
        //     timer.current = window.setTimeout(() => {
        //       setSuccess(false);
        //       meassageOpen(false);
        //       Refreshafterdelete(emailVal);
        //     }, 2000);
        //   }
        // }

        if (res.status === 200) {
          return res.json().then((data) => {
            if (data.code === 200) {
              setOpen(false);
              setSuccess(true);
              meassageOpen(true);
              timer.current = window.setTimeout(() => {
                setSuccess(false);
                meassageOpen(false);
                Refreshafterdelete(emailVal);
              }, 2000);
            } else if (
              data.code === 400 &&
              data.message === "Can't delete ISDefault bank!!"
            ) {
              toast.warning(
                "Before deleting the default bank, set another bank as default"
              );
              // setdeletedefaultbank(true);
            } else {
              throw new Error("DeleteVendorbank API failed.");
            }
          });
        } else {
          throw new Error("DeleteVendorbank API failed.");
        }
      })

      .catch((e) => {
        console.log(e);
        return e;
      });
  };
  const Refreshafterdelete = () => {
    GetBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setBankData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const [selectedBank, setSelectedBank] = useState("");
  const handleSwitchChange = (data) => {
    const token = localStorage.getItem("Logkey");
    setSelectedBank(data.accountNo);
    axios
      .post(
        `${Url}setIsDefault?Email=${localStorage.getItem(
          "user_email"
        )}&Accountnumber=${data.accountNo}`,
        JSON.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              Refreshaftersettingdefault(localStorage.getItem("user_email"));
            }, 2000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Refreshaftersettingdefault = () => {
    GetBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setBankData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Viewbankdetail = async (Accountno) => {
    var result = await Viewbankdetails(Accountno);

    setSelectedbankdetails(result.data[0]);
  };

  let editbank = {
    // customerid: "",
    accountNo: selectedbankdetails.accountNo,
    ifscCode: selectedbankdetails.ifscCode,
    bankName: selectedbankdetails.bankName,
    beneficiaryName: selectedbankdetails.beneficiaryName,
    email: localStorage.getItem("user_email"),
  };

  const UpdateBank = async (editbank, emailVal) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${Url}UpdateBankAccountdetails`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editbank),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              Refreshafterupdate(emailVal);
            }, 2000);
          }
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const Refreshafterupdate = () => {
    GetBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setBankData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const [addbankopen, setOpenBank] = React.useState(false);
  const AddbankClose = () => {
    setOpenBank(false);
  };

  const AddbankOpen = () => {
    setOpenBank(true);
  };

  const [submitted, setSubmitted] = useState(true);

  const updateUserbasicDetails = async (detail) => {
    var validate = true;
    if (
      (detail.personNames == null ||
        detail.personNames == "" ||
        detail.cAddressLine1 == null ||
        detail.cAddressLine1 == "",
        detail.cZipCode == null || detail.cZipCode == "",
        detail.cCompanyDescriptions == null || detail.cCompanyDescriptions == "",
        detail.bAddressLine1 == null || detail.bAddressLine1 == "")
    ) {
      toast.warn("Please validate all fields");
      validate = false;
    }
    if (validate) {
      await UpdateUserDetails(detail, formData.personNames, formData.name);
      meassageOpen(true);
      setSubmitted(true);
      setSubmitted(false);
    }
  };

  const [bankdefaultopen, setopenBank] = React.useState(false);

  const bankdefaultClickOpen = () => {
    setopenBank(true);
  };

  const bankdefaultClose = () => {
    setopenBank(false);
  };

  const handleZipCodeBlur = (e) => {
    const zipvalue = e.target.value;
    if (zipvalue.length < 6) {
      toast.warn("Enter 6 digit zipcode");
    }
  };
  //KYC

  const [businessType, setBusinessType] = useState("");
  const [uploadedPhotoIdentification, setPhotoIdentification] = useState("");
  const [uploadPhotoIdentification, setUploadPhotoIdentification] =
    useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharClientId, setAadharClientId] = useState("");
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const onBackFromCapturePhoto = (event) => {
    setBusinessType("");
    setPhotoIdentification("");
    setUploadPhotoIdentification("");
    setAadharNumber("");
    setAadharClientId("");
    setOTP(["", "", "", "", "", ""]);
    setImageSrc(null);
    setCameraStart(false);
  };

  const handleAadharChange = (event) => {
    setAadharNumber(event.target.value);
  };
  const handleBusinessTypeChange = async (type) => {
    setBusinessType(type);
    await UpdateBussinessType(type);
  };
  const UpdateBussinessType = async (bussinessType) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const userName = localStorage.getItem("user_name")
    let bodyStructure = {
      userId: userid,
      bussinessType: bussinessType,
      customerName: userName
    };
    await fetch(`${CurrentUrl}api/KYCVerification/UpdateBussinessType`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(bodyStructure),
    })
      .then((res) => { })
      .catch((e) => {
        return e;
      });
  };
  const submitPhotoIdentification = async (e) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("file", uploadPhotoIdentification);
    return await axios
      .post(`${CurrentUrl}api/KYCVerification/UploadKycDocument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Uploaded file successfully")
        } else {
          toast.error('Something went wrong!');
        }
        if (e === 1) {
          setPhotoIdentification(response.data.data);
          let body = {
            userId: userid,
            photoIdentification: response.data.data,
          };
          UpdateKycDetails(body, token);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const sendOtpToAadharNo = async () => {
    try {
      if (!aadharNumber) {
        toast.warning('Document No is required!');
        return;
      }
      const token = localStorage.getItem("Logkey");
      const userid = localStorage.getItem("user_id");
      let bodyStructure = {
        userId: userid,
        aadharNo: aadharNumber,
      };
      const response = await fetch(
        `${CurrentUrl}api/KYCVerification/SendOTPToAadharNo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(bodyStructure),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.code == 200) {
        setAadharClientId(data.data.aadharOtpClientId);
      } else {
        toast.warn(data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const handleOTPChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
  };
  const SubmitOTP = async () => {
    try {
      const concatenatedOTP = otp.join("");
      if (!concatenatedOTP) {
        toast.warning('OTP is required!');
        return;
      }
      const token = localStorage.getItem("Logkey");
      const userid = localStorage.getItem("user_id");
      let bodyStructure = {
        userId: userid,
        aadharOtpClientId: aadharClientId,
        otp: concatenatedOTP,
      };
      const response = await fetch(
        `${CurrentUrl}api/KYCVerification/SubmitAadharNoOTP`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(bodyStructure),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.code == 200) {
        toast.success(data.message);
        setBusinessType("");
        setPhotoIdentification("");
        setUploadPhotoIdentification("");
        setAadharNumber("");
        setAadharClientId("");
        setOTP(["", "", "", "", "", ""]);
        setImageSrc(null);
        setCameraStart(false);
      } else {
        toast.warn(data.message);
      }
    } catch (error) { }
  };

  const UpdateKycDetails = async (data, token) => {
    await fetch(`${CurrentUrl}api/KYCVerification/UpdateKYCDocuments`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => { })
      .catch((e) => {
        return e;
      });
  };
  const videoRef = React.useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cameraStart, setCameraStart] = useState(false);
  const handleCapture = async () => {
    try {
      setCameraStart(true);
      const video = videoRef.current;
      if (!video) return;
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      video.srcObject = stream;
      video.play();
    } catch (e) {
      console.log("errorrr", e);
    }
  };

  const handleCaptureImage = () => {
    const video = videoRef.current;
    if (!video) return;

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      if (!blob) return;
      const file = new File([blob], "captured-image.png", {
        type: "image/png",
      });
      setUploadPhotoIdentification(file);
      const imgSrc = URL.createObjectURL(blob);
      setImageSrc(imgSrc);

       // Stop the video stream after capturing the image
       const stream = video.srcObject;
       const tracks = stream.getTracks();
       tracks.forEach((track) => track.stop());
       setCameraStart(false);
    }, "image/png");
  };
  //Document 1 Submit
  const [document1Type, setDocumentType] = useState("");
  const [documentNo, setdocumentNo] = useState("");
  const [frontImage1, setDocument1FrontImage] = useState(null);
  const [backImage1, setDocument1BackImage] = useState(null);

  const [document2Type, setDocumentType2] = useState("");
  const [documentNo2, setdocumentNo2] = useState("");
  const [frontImage2, setDocument2FrontImage] = useState(null);
  const [backImage2, setDocument2BackImage] = useState(null);
  const [companyType, setCompanyType] = useState("");
  const [gstin, setGstin] = useState("");
  const [tan, setTan] = useState("");
  const handleCompanyTypeChange = (event) => {
    setCompanyType(event.target.value);
    let body = {
      userId: localStorage.getItem("user_id"),
      CompanyType: event.target.value,
    };
    UpdateKycDetails(body, localStorage.getItem("Logkey"));
  };
  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleDocumentNameChange = (event) => {
    setdocumentNo(event.target.value);
  };
  const handleFrontImageChange = async (event) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    return await axios
      .post(`${CurrentUrl}api/KYCVerification/UploadKycDocument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setDocument1FrontImage(response.data.data);
      })
      .catch((error) => { });
  };

  const handleBackImageChange = async (event) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    return await axios
      .post(`${CurrentUrl}api/KYCVerification/UploadKycDocument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setDocument1BackImage(response.data.data);
      })
      .catch((error) => { });
  };
  const handleDocumentType2Change = (event) => {
    setDocumentType2(event.target.value);
  };
  const handleDocumentName2Change = (event) => {
    setdocumentNo2(event.target.value);
  };
  const handleFrontImage2Change = async (event) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    return await axios
      .post(`${CurrentUrl}api/KYCVerification/UploadKycDocument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setDocument2FrontImage(response.data.data);
      })
      .catch((error) => { });
  };

  const handleBackImage2Change = async (event) => {
    const token = localStorage.getItem("Logkey");
    const userid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    return await axios
      .post(`${CurrentUrl}api/KYCVerification/UploadKycDocument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setDocument2BackImage(response.data.data);
      })
      .catch((error) => { });
  };
  const document1Submit = () => {
    if (!document1Type && !documentNo && !frontImage1 && !backImage1) {
      toast.warning("All feilds are required!");
      return;
    }
    let body = {
      userId: localStorage.getItem("user_id"),
      document1Type: document1Type,
      documentNumber1: documentNo,
      document1FrontSide: frontImage1,
      document1BackSide: backImage1,
    };
    UpdateKycDetails(body, localStorage.getItem("Logkey"));
    toast.success("Document successfully uploaded");
  };
  const document2Submit = () => {
    if (!document2Type && !documentNo2 && !frontImage2 && !backImage2) {
      toast.warning("All feilds are required!");
      return;
    }
    let body = {
      userId: localStorage.getItem("user_id"),
      document2Type: document2Type,
      documentNumber2: documentNo2,
      document2FrontSide: frontImage2,
      document2BackSide: backImage2,
    };
    UpdateKycDetails(body, localStorage.getItem("Logkey"));
    toast.success("Document successfully uploaded");
  };
  const handleGSTINChange = (event) => {
    setGstin(event.target.value);
  };

  const handleTANChange = (event) => {
    setTan(event.target.value);
  };
  const onBackFromCompanyKYC = (event) => {
    setPhotoIdentification("");
    setUploadPhotoIdentification("");
    setGstin("");
    setTan("");
    setImageSrc(null);
    setCameraStart(false);
    setDocumentType("");
    setdocumentNo("");
    setDocument1FrontImage(null);
    setDocument1BackImage(null);
    setDocumentType2("");
    setdocumentNo2("");
    setDocument2FrontImage(null);
    setDocument2BackImage(null);
    setGstin("");
    setTan("");
    setCompanyType("");
  };
  const onBackFromIndividualKYC = (event) => {
    setPhotoIdentification("");
    setUploadPhotoIdentification("");
    setAadharNumber("");
    setAadharClientId("");
    setOTP(["", "", "", "", "", ""]);
    setImageSrc(null);
    setCameraStart(false);
    setDocumentType("");
    setdocumentNo("");
    setDocument1FrontImage(null);
    setDocument1BackImage(null);
    setDocumentType2("");
    setdocumentNo2("");
    setDocument2FrontImage(null);
    setDocument2BackImage(null);
  };
  const submitGSTkyc = (e) => {
    if (!document2Type && !documentNo2) {
      toast.warning("All feilds are required!");
      return;
    }
    let body = {
      userId: localStorage.getItem("user_id"),
      gSTNO: document2Type,
      tanNo: documentNo2,
    };
    UpdateKycDetails(body, localStorage.getItem("Logkey"));
    toast.success("KYC successfully Done");
  };

  const onDone = () => {
    setDocumentType("");
    setdocumentNo("");
    setDocument1FrontImage(null);
    setDocument1BackImage(null);
    setDocumentType2("");
    setdocumentNo2("");
    setDocument2FrontImage(null);
    setDocument2BackImage(null);
    setBusinessType("");
    setPhotoIdentification("");
    setUploadPhotoIdentification("");
    setAadharNumber("");
    setAadharClientId("");
    setOTP(["", "", "", "", "", ""]);
    setImageSrc(null);
    setCameraStart(false);
    setGstin("");
    setTan("");
  };
  return (
    <>
      <Header submitted={submitted} />
      <div className="content-wrapper">
        {/* {mydata ? (
        <div> */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Settings</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
        </div>
        <section className="content">
          <ToastContainer />
          <div className="container-fluid">
            <Card className="custum-shadow">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    TabIndicatorProps={{ style: { background: "#7b3131" } }}
                    textColor="Mahogany"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Profile" {...a11yProps(0)} />
                    <Tab label="MVIKAS Account Type" {...a11yProps(1)} />
                    <Tab label="Change Password" {...a11yProps(2)} />
                    <Tab label="Bank Details" {...a11yProps(3)} />
                    <Tab label="Address Management" {...a11yProps(4)} />
                    <Tab label="KYC" {...a11yProps(6)} />
                  </Tabs>
                </Box>

                {/* //USER PROFILE PAGE */}
                <TabPanel value={value} index={0} className="tabPanel-ui">
                  <h4 className="mb-4">Basic Details</h4>
                  <Box component="form" autoComplete="off" noValidate>
                    <div className="row">
                      <div className="col-sm-4 mb-4">
                        {/* <TextField
                          fullWidth
                          required
                          label="Person Name/Trade Name"
                          value={Data.personNames}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              personNames: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                        <TextField
                          fullWidth
                          required
                          label="Person Name"
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              personNames: e.target.value,
                            }));
                          }}
                          value={Data["personNames"]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        {/* <TextField
                          fullWidth
                          required
                          label="Email"
                          value={mydata?.email}
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        /> */}
                        <TextField
                          fullWidth
                          required
                          label="Email"
                          disabled
                          // onChange={(e) => {
                          //   setData((val) => ({ ...val, email: e.target.value }));
                          // }}
                          value={Data["email"]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          disabled
                          label="Mobile No."
                          value={Data["mobileNumber"]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Secondary Email"
                          value={Data["custSecondaryEmail"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              custSecondaryEmail: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="GST"
                          value={Data["gst"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              gst: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="PAN"
                          value={Data["pan"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              pan: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Deal Item"
                          value={Data["dealItem"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              dealItem: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Average Shipment Weight"
                          value={Data["averageShipmentWeight"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              averageShipmentWeight: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <Typography
                        variant="overline"
                        display="block"
                        className="mb-3"
                      >
                        Company Address
                      </Typography>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Address Line 1"
                          value={Data["cAddressLine1"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              cAddressLine1: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Address Line 2"
                          value={Data["cAddressLine2"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              cAddressLine2: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>{" "}
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Zip Code"
                          value={Data["cZipCode"]}
                          onBlur={handleZipCodeBlur}
                          // onChange={(e) => {
                          //   if (/^\d{0,6}$/.test(value))
                          //     setData((val) => ({
                          //       ...val,
                          //       cZipCode: e.target.value,
                          //     }));
                          // }}
                          onChange={(e) => {
                            const zipvalue = e.target.value;
                            if (/^\d{0,6}$/.test(zipvalue)) {
                              setData((val) => ({
                                ...val,
                                cZipCode: zipvalue,
                              }));
                            }
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <FormControl fullWidth>
                          <InputLabel required id="country-select-label">
                            State
                          </InputLabel>
                          <Select
                            labelId="state-select-label"
                            id="state-select"
                            label="State"
                            value={selectedcompanystate}
                            onChange={handlecompanyStatechange}
                          >
                            {state.map((state, index) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* <Autocomplete
                          disablePortal
                          id="state-select"
                          value={selectedcompanystate}
                          onChange={handlecompanyStatechange}
                          options={state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="State"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        /> */}
                      </div>
                      <div className="col-sm-4 mb-4">
                        <FormControl fullWidth>
                          <InputLabel required id="state-select-label">
                            City
                          </InputLabel>
                          <Select
                            labelId="city-select-label"
                            id="city-select"
                            label="City"
                            value={selectedcompanycity}
                            onChange={handlecompanycitychange}
                          >
                            {companycities.map((city) => (
                              <MenuItem key={city} value={city}>
                                {city}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Country"
                          value="India"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-12 mb-4">
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          label="Company Description"
                          value={Data["cCompanyDescriptions"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              cCompanyDescriptions: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <Typography
                        variant="overline"
                        display="block"
                        className="mb-3"
                      >
                        Billing Address
                      </Typography>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Address Line 1"
                          value={Data["bAddressLine1"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              bAddressLine1: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          label="Address Line 2"
                          value={Data["bAddressLine2"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              bAddressLine2: e.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Zip Code"
                          value={Data["bZipCode"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              bZipCode: e.target.value,
                            }));
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <FormControl fullWidth>
                          <InputLabel required id="country-select-label">
                            State
                          </InputLabel>
                          <Select
                            labelId="state-select-label"
                            id="state-select"
                            label="State"
                            value={selectedbusinessstate}
                            onChange={handlebusinessStatechange}
                          >
                            {state.map((state, index) => (
                              <MenuItem key={state} value={state}>
                                {" "}
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-sm-4 mb-4">
                        <FormControl fullWidth>
                          <InputLabel required id="state-select-label">
                            City
                          </InputLabel>
                          <Select
                            labelId="city-select-label"
                            id="city-select"
                            label="City"
                            value={selectedbusinesscity}
                            onChange={handlebusinesscitychange}
                          >
                            {businesscities.map((city) => (
                              <MenuItem key={city} value={city}>
                                {city}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="col-sm-4 mb-4">
                        <FormControl fullWidth>
                          <InputLabel id="country-select-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="country-select-label"
                            id="country-select"
                            label="Country"
                            value={Data["bCountry"]}
                            // onChange={(e) => {
                            //   setData((val) => ({
                            //     ...val,
                            //     bCountry: e.target.value,
                            //   }));
                            // }}
                          >
                            <MenuItem value="IN">INDIA</MenuItem>
                          </Select>
                        </FormControl>
                      </div> */}
                      <div className="col-sm-4 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Country"
                          value="India"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        updateUserbasicDetails(
                          Data,
                          formData,
                          (Data.cState = selectedcompanystate),
                          (Data.cCity = selectedcompanycity),
                          (Data.bState = selectedbusinessstate),
                          (Data.bCity = selectedbusinesscity)
                        );
                      }}
                      variant="contained"
                      size="large"
                      className="custum-btn"
                    >
                      Save Changes
                    </Button>
                  </Box>
                </TabPanel>
                {/* /MVIKAS/USER ACCOUNT TYPE PAGE */}
                <TabPanel value={value} index={1}>
                  <h4 className="mb-4">MVIKAS Account Details</h4>
                  <Box component="form" autoComplete="off" noValidate>
                    <div className="row">
                      <div className="col-lg-4 col-sm-12 mb-5">
                        <TextField
                          fullWidth
                          disabled
                          label="Account Type"
                          value={accounttype["billing_Type"]}
                          // defaultValue="Post Paid"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 mb-5">
                        <TextField
                          fullWidth
                          disabled
                          value={accounttype["credit_Limit"]}
                          label="Credit Limit"
                          // defaultValue="10,000"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-12 mb-5">
                        <TextField
                          fullWidth
                          disabled
                          label="Billing Cycle"
                          value={accounttype["billing_Cycle"]}
                          // defaultValue="Post Paid"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 mb-5">
                        <TextField
                          fullWidth
                          disabled
                          value={accounttype["payment_Cycle"]}
                          label="Payment Cycle"
                          // defaultValue="10,000"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row">
                    <div className="col-lg-4 col-sm-12 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="billing-cycle-select-label">
                          Billing Cycle
                        </InputLabel>
                        <Select
                          labelId="billing-cycle-select-label"
                          id="billing-cycle-select"
                          label="Billing Cycle"
                          defaultValue="weekly"
                        >
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly </MenuItem>
                          <MenuItem value="fortnightly">Fortnightly </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-4 col-sm-12 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="payment-cycle-select-label">
                          Payment Cycle
                        </InputLabel>
                        <Select
                          labelId="payment-cycle-select-label"
                          id="payment-cycle-select"
                          label="Payment Cycle"
                          defaultValue="monthly"
                        >
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly </MenuItem>
                          <MenuItem value="fortnightly">Fortnightly </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div> */}

                    {/* <div className="row">
                    <div className="col-lg-4 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        disabled
                        label="Markup"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div> */}

                    {/* <Button
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Update
                  </Button> */}
                  </Box>
                </TabPanel>
                {/* //USER PSWORD PAGE */}
                <TabPanel value={value} index={2}>
                  <ToastContainer />
                  <h4 className="mb-2">Change Password</h4>
                  <Typography
                    variant="subtitle1"
                    className="mb-2"
                    style={{ opacity: "0.7" }}
                  >
                    Please use letters, numbers and accepted symbols only, with
                    no spaces, minimum 8, maximum 32 characters.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="mb-5"
                    style={{ opacity: "0.7" }}
                  >
                    Your password should not contain your username, name, email
                    address or year of birth. You can increase the strength of
                    your password by using a mixture of letters, numbers and
                    symbols. Please remember that passwords are case sensitive.
                  </Typography>

                  <Box component="form" autoComplete="off" noValidate>
                    <div className="row">
                      <div className="col-lg-5 col-sm-12 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Current Password"
                          onChange={(e) => {
                            passData["currentPassword"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-5 col-sm-12 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="New Password"
                          onChange={(e) => {
                            passData["newPassword"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-5 col-sm-12 mb-4">
                        <TextField
                          fullWidth
                          required
                          label="Confirm New Password"
                          onChange={(e) => {
                            passData["confirmNewPassword"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <Button
                      onClick={() => {
                        if (
                          !passData.currentPassword ||
                          !passData.newPassword ||
                          !passData.confirmNewPassword
                        ) {
                          toast.warn("Please fill out all fields");
                        } else if (
                          passData.newPassword !== passData.confirmNewPassword
                        ) {
                          toast.warn(
                            "New password and confirm password do not match"
                          );
                        } else {
                          CustomerChangePassword(passData)
                            .then((r) => {
                              if (r.code === 200) {
                                setSuccessMessage("Changes saved successfully");
                                setpassMessageOpen(true);
                              } else if (r.code === 400) {
                                setIncorrectPassword(true);
                              }
                            })
                            .catch((e) => {
                              console.log("error", e);
                            });
                        }
                      }}
                      variant="contained"
                      size="large"
                      className="custum-btn"
                    >
                      Update
                    </Button>

                    {incorrectPassword && (
                      <Snackbar
                        open={incorrectPassword}
                        onClose={() => setIncorrectPassword(false)}
                        message="Incorrect current password"
                      />
                    )}

                    <Snackbar
                      open={passmessageOpen}
                      autoHideDuration={2000}
                      onClose={() => setpassMessageOpen(false)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Alert severity="success" sx={{ width: "250px" }}>
                        {successMessage}
                      </Alert>
                    </Snackbar>
                  </Box>
                </TabPanel>
                {/* //USER BANKING PAGE */}
                <TabPanel value={value} index={3}>
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="mb-0">Bank Details</h4>
                    <Button
                      variant="contained"
                      size="large"
                      className="ml-auto custum-btn"
                      onClick={AddbankOpen}
                    >
                      Add Bank
                    </Button>
                  </div>

                  {/* //BANK CARD */}
                  <Box>
                    <Grid container gap={2}>
                      {bankData.map((item, i) => {
                        return (
                          <Grid md={4} xs={12}>
                            <Card className="bank-card-ui">
                              <CardContent>
                                <Typography
                                  sx={{ fontSize: 13 }}
                                  color="text.secondary"
                                >
                                  Bank Name
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                  {item.bankName}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 13 }}
                                  color="text.secondary"
                                >
                                  IFSC
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                  {item.ifscCode}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 13 }}
                                  color="text.secondary"
                                >
                                  Beneficiary name
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                  {item.beneficiaryName}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 13 }}
                                  color="text.secondary"
                                >
                                  Account No.
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                  {item.accountNo}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <FormControlLabel
                                  control={
                                    // DEFAULT BANK SWITCH
                                    <Switch
                                      checked={
                                        item.isDefault === true
                                        // ||
                                        // item.accountNo === selectedBank
                                      }
                                      size="small"
                                      onChange={() => handleSwitchChange(item)}
                                    />
                                  }
                                  label="Default"
                                />
                                <ButtonGroup>
                                  <IconButton aria-label="delete">
                                    <DriveFileRenameOutlineTwoToneIcon
                                      fontSize="small"
                                      onClick={() => {
                                        updateBankClickOpen();
                                        Viewbankdetail(item.accountNo);
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      Deletebank(item.accountNo);
                                    }}
                                    aria-label="delete"
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </ButtonGroup>
                              </CardActions>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>

                  {/* ADD BANK DIALOG BOX */}
                  <Dialog
                    open={addbankopen}
                    onClose={AddbankClose}
                    fullWidth
                    maxWidth="xs"
                  >
                    <DialogTitle>Add Bank</DialogTitle>
                    <DialogContent>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Account No."
                          // value={bnkno}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ attern: "[0-9]" }}
                          onChange={(e) => {
                            banking["accountNo"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="IFSC Code"
                          onChange={(e) => {
                            banking["ifscCode"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Bank Name"
                          // value={bank}
                          onChange={(e) => {
                            banking["bankName"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Beneficiary Name"
                          onChange={(e) => {
                            banking["beneficiaryName"] = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-betwvaln" }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          variant="contained"
                          sx={buttonSx}
                          disabled={loading}
                          onClick={() => {
                            if (
                              !banking.accountNo ||
                              !banking.ifscCode ||
                              !banking.bankName ||
                              !banking.beneficiaryName
                            ) {
                              toast.warn("Please fill out all fields");
                            } else {
                              AddBanks(
                                banking,
                                localStorage.getItem("user_email")
                              );
                            }
                          }}
                        >
                          Submit
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: red[50],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                      <Button onClick={AddbankClose}>Cancel</Button>
                    </DialogActions>
                  </Dialog>
                  {/* EDIT BANK DIALOG BOX */}
                  <Dialog
                    open={open}
                    onClose={updateBankClose}
                    fullWidth
                    maxWidth="xs"
                  >
                    <DialogTitle>Update Bank</DialogTitle>
                    <DialogContent>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Account No."
                          value={selectedbankdetails["accountNo"]}
                          // onChange={(e) => {
                          //   setSelectedbankdetails((val) => ({
                          //     ...val,
                          //     accountNo: e.target.value,
                          //   }));
                          // }}

                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="IFSC Code"
                          value={selectedbankdetails.ifscCode}
                          onChange={(e) => {
                            setSelectedbankdetails((val) => ({
                              ...val,
                              ifscCode: e.target.value,
                            }));
                          }}
                          // onChange={(e) => setIfsc(e.target.value)}
                          // onChange={(e) => {
                          //   editbank["accountNo"] = e.target.value;
                          // }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Bank Name"
                          value={selectedbankdetails.bankName}
                          onChange={(e) => {
                            setSelectedbankdetails((val) => ({
                              ...val,
                              bankName: e.target.value,
                            }));
                          }}
                          // onChange={(e) => {
                          //   editbank["accountNo"] = e.target.value;
                          // }}
                          // onChange={(e) => setBank(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          fullWidth
                          label="Beneficiary Name"
                          // onChange={(e) => {
                          //   editbank["accountNo"] = e.target.value;
                          // }}
                          value={selectedbankdetails.beneficiaryName}
                          onChange={(e) => {
                            setSelectedbankdetails((val) => ({
                              ...val,
                              beneficiaryName: e.target.value,
                            }));
                          }}
                          // onChange={(e) => setBeneficiary(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-between" }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          onClick={() => {
                            UpdateBank(editbank);
                          }}
                          variant="contained"
                          sx={buttonSx}
                          disabled={loading}
                        >
                          Submit
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: red[50],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                      <Button onClick={updateBankClose}>Cancel</Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={bankdefaultopen}
                    keepMounted
                    onClose={bankdefaultClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>{"Test Alert"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Test
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={bankdefaultClose}>Ok</Button>
                      <Button onClick={bankdefaultClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </TabPanel>
                {/* //USER ADDRESS PAGE */}
                <TabPanel value={value} index={4}>
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="mb-0">Address</h4>
                    <Button
                      variant="contained"
                      className="ml-auto custum-btn"
                      onClick={handleClickOpen}
                    >
                      Add Address
                    </Button>
                  </div>
                  <Box>
                    <DataGrid
                      sx={{ p: "1rem" }}
                      className="table-ui"
                      disableColumnMenu
                      getRowId={(row) => row.id ?? row.address_Id}
                      rows={addressList}
                      columns={columns}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      autoHeight={true}
                      pagination
                      pageSize={rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      onPageSizeChange={(newPageSize) => {
                        setRowsPerPage(newPageSize);
                      }}
                    />
                  </Box>

                  {/* //ADD ADDRESS DIALOG BOX */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                    className="address-dialog"
                  >
                    <DialogTitle>Add New Address</DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Address Name"
                            onChange={(e) => {
                              setNewAddress((prevState) => ({
                                ...prevState,
                                name: e.target.value,
                              }));
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Full Address"
                            // onChange={(e) => {
                            //   newaddress["full_address"] = e.target.value;
                            // }}
                            onChange={(e) => {
                              setNewAddress((prevState) => ({
                                ...prevState,
                                full_address: e.target.value,
                              }));
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="state-select-label">
                              State
                            </InputLabel>
                            <Select
                              labelId="state-select-label"
                              id="state-select"
                              label="State"
                              value={selectedState}
                              onChange={handleStatechange}
                            >
                              {state.map((state, index) => (
                                <MenuItem key={state} value={state}>
                                  {" "}
                                  {state}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="state-select-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="city-select-label"
                              id="city-select"
                              label="City"
                              value={selectedCity}
                              onChange={handlecitychange}
                            >
                              {cities.map((city) => (
                                <MenuItem key={city} value={city}>
                                  {city}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Pincode"
                            type="text"
                            // onChange={(e) => {
                            //   newaddress["pincode"] = e.target.value;
                            // }}
                            // onChange={(e) => {
                            //   setNewAddress((prevState) => ({
                            //     ...prevState,
                            //     pincode: e.target.value,
                            //   }));
                            // }}
                            onBlur={handleZipCodeBlur}
                            onChange={(e) => {
                              const zipvalue = e.target.value;
                              if (/^\d{0,6}$/.test(zipvalue)) {
                                setNewAddress((val) => ({
                                  ...val,
                                  pincode: zipvalue,
                                }));
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            required
                            fullWidth
                            // type="number"
                            type="text"
                            label="Mobile No"
                            //  onInput=  {onlyNumberKey}
                            onChange={(e) => {
                              setNewAddress((prevState) => ({
                                ...prevState,
                                phone: e.target.value,
                              }));
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            required
                            label="Country"
                            value="India"
                            // onChange={(e) => {
                            //   newaddress["country"] = e.target.value;
                            // }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-between" }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          variant="contained"
                          sx={buttonSx}
                          disabled={loading}
                          onClick={() => {
                            Addaddress(
                              newaddress,
                              (newaddress.state = selectedState),
                              (newaddress.city = selectedCity),
                              localStorage.getItem("user_email")
                            );
                          }}
                        >
                          Submit
                        </Button>

                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: red[50],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>

                      {/* <Button
                          onClick={() => {
                            console.log("my new added address", newaddress);
                            AddNewAddress(newaddress);
                          }}
                        >
                          Submit
                        </Button> */}
                      <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                  </Dialog>

                  {/* //Edit ADDRESS DIALOG BOX */}
                  <Dialog
                    // value={value}
                    index={0}
                    open={editopen}
                    onSubmit={handleSubmit}
                    onClose={handlvalditmodalClose}
                    fullWidth
                    maxWidth="md"
                    className="address-dialog"
                  >
                    <DialogTitle> </DialogTitle>

                    <DialogContent>
                      <Grid container>
                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Address Name"
                            value={currentaddress["name"]}
                            onChange={(e) => {
                              setCurrentaddress((val) => ({
                                ...val,
                                name: e.target.value,
                              }));
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="state-select-label">
                              State
                            </InputLabel>
                            <Select
                              labelId="state-select-label"
                              id="state-select"
                              label="State"
                              value={editselectedState}
                              onChange={handleeditStatechange}
                            >
                              {state.map((state, index) => (
                                <MenuItem key={state} value={state}>
                                  {" "}
                                  {state}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="state-select-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="city-select-label"
                              id="city-select"
                              label="City"
                              value={editselectedCity}
                              onChange={handleeditcitychange}
                            >
                              {cities.map((city) => (
                                <MenuItem key={city} value={city}>
                                  {city}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Country"
                            value="India"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Pincode"
                            value={currentaddress["pincode"]}
                            // onChange={(e) => {
                            //   setCurrentaddress((val) => ({
                            //     ...val,
                            //     pincode: e.target.value,
                            //   }));
                            // }}
                            onBlur={handleZipCodeBlur}
                            onChange={(e) => {
                              const zipvalue = e.target.value;
                              if (/^\d{0,6}$/.test(zipvalue)) {
                                setCurrentaddress((val) => ({
                                  ...val,
                                  pincode: zipvalue,
                                }));
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid md={6} xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Mobile No"
                            value={currentaddress["phone"]}
                            onChange={(e) => {
                              setCurrentaddress((val) => ({
                                ...val,
                                phone: e.target.value,
                              }));
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid xs={12} sx={{ p: 2 }}>
                          <TextField
                            fullWidth
                            label="Full Address"
                            value={currentaddress["full_address"]}
                            onChange={(e) => {
                              setCurrentaddress((val) => ({
                                ...val,
                                full_address: e.target.value,
                              }));
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>

                    <DialogActions style={{ justifyContent: "space-between" }}>
                      <Button
                        sx={{ m: 1, position: "relative" }}
                        variant="contained"
                        // sx={buttonSx}
                        onClick={() => {
                          CallAddressUpdateOperation();
                        }}
                      >
                        Submit
                      </Button>
                      <Button onClick={handlvalditmodalClose}>Cancel</Button>
                    </DialogActions>
                  </Dialog>
                </TabPanel>
                {/* //KYC PAGE */}
                <TabPanel value={value} index={5}>
                  <ToastContainer />
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="mb-0">KYC</h4>
                  </div>
                  {!businessType && (
                    <Box className="form-kyc">
                      <h3 className="mb-3">
                        Please confirm your business type
                      </h3>

                      {/* Individual Type Radio Button starts */}
                      <div
                        className={`form-check-ui mb-3 ${businessType === "Individual" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="bussinessType"
                          onChange={() =>
                            handleBusinessTypeChange("Individual")
                          }
                          checked={businessType === "Individual"}
                        />
                        <div className="text">
                          <h4>Individual</h4>

                        </div>
                      </div>
                      {/* Individual Type Radio Button ends */}


                      {/* Company Type Radio Button starts */}
                      <div
                        className={`form-check-ui mb-3 ${businessType === "Company" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="bussinessType"
                          onChange={() => handleBusinessTypeChange("Company")}
                          checked={businessType === "Company"}
                        />
                        <div className="text">
                          <h4>Company</h4>

                        </div>
                      </div>
                      {/* Company Type Radio Button ends */}

                      {/* <Button
                      variant="contained"
                      size="large"
                      className="ml-auto custum-btn"
                    >
                      Next
                    </Button> */}
                    </Box>
                  )}
                  {businessType && !uploadedPhotoIdentification && (
                    <div>
                      <h3 className="title-forkyc">{businessType}</h3>
                      <Box className="form-kyc">
                        <h3 className="mb-3">Photo Identification</h3>
                        <div className="white-card">
                          <div className="photo-upload">
                            {/* Show CameraAltIcon when camera is not started */}
                            {!cameraStart ? (
                              <CameraAltIcon onClick={handleCapture} />
                            ) : null}

                            <video
                              ref={videoRef}
                              className={
                                cameraStart && !imageSrc ? "visible" : "hidden"
                              }
                              style={{ width: "100%", height: "auto" }}
                              autoPlay
                            />
                            {imageSrc ? (
                              <img src={imageSrc} alt="Captured" />
                            ) : null}
                          </div>
                          <Button
                            variant="contained"
                            size="large"
                            className="custum-btn mb-3"
                            onClick={handleCaptureImage}
                          >
                            Capture Image
                          </Button>
                        </div>
                        <div className="d-flex">
                          <Button
                            variant="contained"
                            type="button"
                            size="large"
                            className="custum-btn  mt-3"
                            onClick={onBackFromCapturePhoto}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            type="button"
                            size="large"
                            onClick={() => submitPhotoIdentification(1)}
                            className="custum-btn ms-auto mt-3"
                          >
                            Next
                          </Button>
                        </div>
                      </Box>
                    </div>
                  )}
                  {businessType === "Individual" &&
                    uploadedPhotoIdentification && (
                      <div>
                        <h3
                          className="mb-3"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Please select any of the 2 option below to verify your
                          KYC
                        </h3>
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Aadhar OTP
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              ID & Address Proofs
                            </button>
                          </li>
                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            <Box className="form-kyc">
                              <div className="c-card">
                                <div className="c-header">
                                  <PermPhoneMsgIcon />
                                  <div className="text">
                                    <h4>
                                      Express KYC using Aadhar OTP
                                      <small>No Document Upload Requied</small>
                                    </h4>
                                    <span>
                                      Get KYC verified within 30 seconds
                                    </span>
                                  </div>
                                </div>

                                <div className="c-body">
                                  <div className="row align-items-center">
                                    <div className="col-lg-4">
                                      <TextField
                                        fullWidth
                                        required
                                        label="Aadhar Number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={aadharNumber}
                                        onChange={handleAadharChange}
                                      />
                                    </div>
                                    <div className="col-lg-4">
                                      <Button
                                        variant="contained"
                                        size="large"
                                        className="custum-btn"
                                        onClick={sendOtpToAadharNo}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </div>
                                  {aadharClientId ? (
                                    <div>
                                      <p className="sub-text">
                                        OTP has been generated successfully.
                                        Please check your registered mobile
                                        number for OTP.
                                      </p>
                                      <div class="mb-3">
                                        <label class="form-label">
                                          Enter OTP
                                        </label>
                                        <div className="otp-inputs">
                                          {otp.map((value, index) => (
                                            <input
                                              key={index}
                                              type="text"
                                              className="form-control"
                                              maxLength={1}
                                              value={value}
                                              onChange={(e) =>
                                                handleOTPChange(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          ))}
                                        </div>
                                      </div>
                                      <div className="m-t3">
                                        <Button
                                          variant="contained"
                                          size="large"
                                          className="custum-btn mr-3"
                                          onClick={SubmitOTP}
                                        >
                                          Complete KYC
                                        </Button>

                                        <Button
                                          variant="contained"
                                          size="large"
                                          className="custum-btn"
                                          onClick={sendOtpToAadharNo}
                                        >
                                          Resend OTP
                                        </Button>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex">
                                <Button
                                  variant="contained"
                                  size="large"
                                  className="custum-btn  mt-3"
                                  onClick={onBackFromIndividualKYC}
                                >
                                  Back
                                </Button>

                                <Button
                                  variant="contained"
                                  size="large"
                                  className="custum-btn ms-auto mt-3"
                                >
                                  Done
                                </Button>
                              </div>
                            </Box>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            <Box className="form-kyc">
                              <div className="c-card">
                                <div className="c-header">
                                  <PermPhoneMsgIcon />
                                  <div className="text">
                                    <h4>
                                      KYC uploading ID & Address Proofs
                                      <small>(Document Upload Requied)</small>
                                    </h4>
                                    <span>
                                      KYC verified might take 2-3 business days
                                    </span>
                                  </div>
                                </div>

                                <div
                                  className="c-body"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="row align-items-center document-upload">
                                    <div className="col-lg-6">
                                      <h6>Document 1</h6>

                                      <FormControl fullWidth>
                                        <InputLabel
                                          required
                                          id="state-select-label"
                                        >
                                          Document Type
                                        </InputLabel>
                                        <Select
                                          labelId="city-select-label"
                                          id="city-select"
                                          label="Document Type"
                                          value={document1Type}
                                          onChange={handleDocumentTypeChange}
                                        >
                                          <MenuItem value={"Pan"}>
                                            Pan Card
                                          </MenuItem>
                                          <MenuItem value={"DL"}>
                                            Driving License
                                          </MenuItem>
                                          <MenuItem value={"Passport"}>
                                            Valid Passport
                                          </MenuItem>
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        className="mt-4"
                                        fullWidth
                                        required
                                        label="Document No"
                                        placeholder="Enter Document No"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={documentNo}
                                        onChange={handleDocumentNameChange}
                                      />

                                      <lable className="lable-text">
                                        Document Images
                                      </lable>

                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="photo-upload">
                                            <input
                                              type="file"
                                              onChange={handleFrontImageChange}
                                            />
                                            <FileUploadOutlinedIcon />
                                            <span>Upload Front Side</span>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="photo-upload">
                                            <input
                                              type="file"
                                              onChange={handleBackImageChange}
                                            />
                                            <FileUploadOutlinedIcon />
                                            <span>Upload Back Side</span>
                                          </div>
                                        </div>
                                      </div>

                                      <Button
                                        variant="contained"
                                        size="large"
                                        className="custum-btn mr-3"
                                        fullWidth
                                        onClick={document1Submit}
                                      >
                                        Submit Document 1
                                      </Button>
                                    </div>

                                    <div className="col-lg-6">
                                      <h6>Document 2</h6>

                                      <FormControl fullWidth>
                                        <InputLabel
                                          required
                                          id="state-select-label"
                                        >
                                          Document Type
                                        </InputLabel>
                                        <Select
                                          labelId="city-select-label"
                                          id="city-select"
                                          label="Document Type"
                                          value={document2Type}
                                          onChange={handleDocumentType2Change}
                                        >
                                          <MenuItem value={"Pan"}>
                                            Pan Card
                                          </MenuItem>
                                          <MenuItem value={"DrivingLicense"}>
                                            Driving License
                                          </MenuItem>
                                          <MenuItem value={"Passport"}>
                                            Valid Passport
                                          </MenuItem>
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        className="mt-4"
                                        fullWidth
                                        required
                                        label="Document No"
                                        placeholder="Enter Document No"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={documentNo2}
                                        onChange={handleDocumentName2Change}
                                      />

                                      <lable className="lable-text">
                                        Document Images
                                      </lable>

                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="photo-upload">
                                            <input
                                              type="file"
                                              onChange={handleFrontImage2Change}
                                            />
                                            <FileUploadOutlinedIcon />
                                            <span>Upload Front Side</span>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="photo-upload">
                                            <input
                                              type="file"
                                              onChange={handleBackImage2Change}
                                            />
                                            <FileUploadOutlinedIcon />
                                            <span>Upload Back Side</span>
                                          </div>
                                        </div>
                                      </div>

                                      <Button
                                        variant="contained"
                                        size="large"
                                        className="custum-btn mr-3"
                                        fullWidth
                                        onClick={document2Submit}
                                      >
                                        Submit Document 2
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex">
                                <Button
                                  variant="contained"
                                  size="large"
                                  className="custum-btn  mt-3"
                                  onClick={onBackFromIndividualKYC}
                                >
                                  Back
                                </Button>

                                <Button
                                  variant="contained"
                                  size="large"
                                  className="custum-btn ms-auto mt-3"
                                  onClick={onDone}
                                >
                                  Done
                                </Button>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    )}
                  {businessType === "Company" &&
                    uploadedPhotoIdentification && (
                      <div>
                        {!companyType ? (
                          <div>
                            <Box className="form-kyc">
                              <div className="c-card">
                                <div
                                  className="c-body"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <FormControl fullWidth>
                                        <InputLabel
                                          required
                                          id="state-select-label"
                                        >
                                          Company type
                                        </InputLabel>
                                        <Select
                                          labelId="city-select-label"
                                          id="city-select"
                                          label="Company type"
                                          value={companyType}
                                          onChange={handleCompanyTypeChange}
                                        >
                                          <MenuItem value={""}>
                                            Select Company Type
                                          </MenuItem>
                                          <MenuItem value={"Partnership"}>
                                            Partnership
                                          </MenuItem>
                                          <MenuItem
                                            value={"Limited Liability Partnership"}
                                          >
                                            Limited Liability Partnership
                                          </MenuItem>
                                          <MenuItem
                                            value={"Public Limited Company"}
                                          >
                                            Public Limited Company
                                          </MenuItem>
                                          <MenuItem
                                            value={"Private Limited Company"}
                                          >
                                            Private Limited Company
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Button
                                variant="contained"
                                size="large"
                                className="custum-btn ml-auto mt-3"
                              >
                                Next
                              </Button>
                            </Box>
                          </div>
                        ) : (
                          <div>
                            <h3
                              className="mb-3"
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Please select any of the 2 option below to verify
                              your KYC
                            </h3>
                            <ul
                              className="nav nav-pills mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  GSTIN
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-profile-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                >
                                  ID & Address Proofs
                                </button>
                              </li>
                            </ul>

                            <div className="tab-content" id="pills-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                              >
                                <Box className="form-kyc">
                                  <div className="c-card">
                                    <div className="c-header">
                                      <PermPhoneMsgIcon />
                                      <div className="text">
                                        <h4>
                                          Express KYC using GSTIN
                                          <small>
                                            (No Document Upload Requied)
                                          </small>
                                        </h4>
                                      </div>
                                    </div>

                                    <div className="c-body">
                                      <div className="row align-items-center">
                                        <div className="col-lg-4">
                                          <TextField
                                            fullWidth
                                            required
                                            label="Enter GSTIN"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={gstin}
                                            onChange={handleGSTINChange}
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <TextField
                                            fullWidth
                                            required
                                            label="Enter TAN N0."
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={tan}
                                            onChange={handleTANChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="m-t3">
                                        <Button
                                          variant="contained"
                                          size="large"
                                          className="custum-btn mr-3"
                                          onClick={submitGSTkyc}
                                        >
                                          Complete KYC
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <Button
                                      variant="contained"
                                      size="large"
                                      className="custum-btn  mt-3"
                                      onClick={onBackFromCompanyKYC}
                                    >
                                      Back
                                    </Button>

                                    <Button
                                      variant="contained"
                                      size="large"
                                      className="custum-btn ms-auto mt-3"
                                      onClick={onDone}
                                    >
                                      Done
                                    </Button>
                                  </div>
                                </Box>
                              </div>

                              <div
                                class="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                              >
                                <Box className="form-kyc">
                                  <div className="c-card">
                                    <div className="c-header">
                                      <PermPhoneMsgIcon />
                                      <div className="text">
                                        <h4>
                                          KYC uploading ID & Address Proofs
                                          <small>(Document Upload Requied)</small>
                                        </h4>
                                        <span>
                                          KYC verified might take 2-3 business
                                          days
                                        </span>
                                      </div>
                                    </div>

                                    <div
                                      className="c-body"
                                      style={{ paddingTop: "20px" }}
                                    >
                                      <div className="row align-items-center document-upload">
                                        <div className="col-lg-6">
                                          <h6>Document 1</h6>

                                          <FormControl fullWidth>
                                            <InputLabel
                                              required
                                              id="state-select-label"
                                            >
                                              Document Type
                                            </InputLabel>
                                            <Select
                                              labelId="city-select-label"
                                              id="city-select"
                                              label="Document Type"
                                              value={document1Type}
                                              onChange={handleDocumentTypeChange}
                                            >
                                              <MenuItem value={"Pan"}>
                                                Pan Card
                                              </MenuItem>
                                              <MenuItem value={"DL"}>
                                                Driving License
                                              </MenuItem>
                                              <MenuItem value={"Passport"}>
                                                Valid Passport
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <TextField
                                            className="mt-4"
                                            fullWidth
                                            required
                                            label="Document No"
                                            placeholder="Enter Document No"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={documentNo}
                                            onChange={handleDocumentNameChange}
                                          />

                                          <lable className="lable-text">
                                            Document Images
                                          </lable>

                                          <div className="row">
                                            <div className="col-lg-6">
                                              <div className="photo-upload">
                                                <input
                                                  type="file"
                                                  onChange={
                                                    handleFrontImageChange
                                                  }
                                                />
                                                <FileUploadOutlinedIcon />
                                                <span>Upload Front Side</span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="photo-upload">
                                                <input
                                                  type="file"
                                                  onChange={handleBackImageChange}
                                                />
                                                <FileUploadOutlinedIcon />
                                                <span>Upload Back Side</span>
                                              </div>
                                            </div>
                                          </div>

                                          <Button
                                            variant="contained"
                                            size="large"
                                            className="custum-btn mr-3"
                                            fullWidth
                                            onClick={document1Submit}
                                          >
                                            Submit Document 1
                                          </Button>
                                        </div>

                                        <div className="col-lg-6">
                                          <h6>Document 2</h6>

                                          <FormControl fullWidth>
                                            <InputLabel
                                              required
                                              id="state-select-label"
                                            >
                                              Document Type
                                            </InputLabel>
                                            <Select
                                              labelId="city-select-label"
                                              id="city-select"
                                              label="Document Type"
                                              value={document2Type}
                                              onChange={handleDocumentType2Change}
                                            >
                                              <MenuItem value={"Pan"}>
                                                Pan Card
                                              </MenuItem>
                                              <MenuItem value={"DL"}>
                                                Driving License
                                              </MenuItem>
                                              <MenuItem value={"Passport"}>
                                                Valid Passport
                                              </MenuItem>
                                            </Select>
                                          </FormControl>

                                          <TextField
                                            className="mt-4"
                                            fullWidth
                                            required
                                            label="Document No"
                                            placeholder="Enter Document No"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={documentNo2}
                                            onChange={handleDocumentName2Change}
                                          />

                                          <lable className="lable-text">
                                            Document Images
                                          </lable>

                                          <div className="row">
                                            <div className="col-lg-6">
                                              <div className="photo-upload">
                                                <input
                                                  type="file"
                                                  onChange={
                                                    handleFrontImage2Change
                                                  }
                                                />
                                                <FileUploadOutlinedIcon />
                                                <span>Upload Front Side</span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="photo-upload">
                                                <input
                                                  type="file"
                                                  onChange={
                                                    handleBackImage2Change
                                                  }
                                                />
                                                <FileUploadOutlinedIcon />
                                                <span>Upload Back Side</span>
                                              </div>
                                            </div>
                                          </div>

                                          <Button
                                            variant="contained"
                                            size="large"
                                            className="custum-btn mr-3"
                                            fullWidth
                                            onClick={document2Submit}
                                          >
                                            Submit Document 2
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <Button
                                      variant="contained"
                                      size="large"
                                      className="custum-btn  mt-3"
                                      onClick={onBackFromCompanyKYC}
                                    >
                                      Back
                                    </Button>

                                    <Button
                                      variant="contained"
                                      size="large"
                                      className="custum-btn ms-auto mt-3"
                                      onClick={onDone}
                                    >
                                      Done
                                    </Button>
                                  </div>
                                </Box>
                              </div>
                            </div>
                          </div>
                        )}


                      </div>
                    )}
                </TabPanel>
              </Box>
            </Card>

            <Snackbar
              open={messopen}
              autoHideDuration={2000}
              onOpen={messageopen}
              onClose={messageClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert severity="success" sx={{ width: "250px" }}>
                Changes saved successfully
              </Alert>
            </Snackbar>
          </div>
        </section>{" "}
        {/* </div>
      ) : (
        <></>
      )} */}
      </div>
    </>
  );
}
