import React from "react";

const DynamicTable = ({ headers, data }) => {
  let tableData = "";
  let tableHead = "";
  let srno = 1;
  let emptyMess;

  if (data != null && headers != null) {
    tableData = data.map((row) => (
      <tr>
        {headers.map((head, i) => (
          <>
            <td key={i}>{row[head]}</td>
          </>
        ))}
      </tr>
    ));

    tableHead = headers.map((head, i) => <th key={i}>{head.toUpperCase()}</th>);
    emptyMess = null;
  } else {
    emptyMess = "No Data Found!!";
  }
  return (
    <div className="table-responsive">
      <table className="table " id="mytable">
        <thead>{tableHead}</thead>
        <tbody>{tableData}</tbody>
      </table>
      <span>{emptyMess}</span>
    </div>
  );
};

export default DynamicTable;
