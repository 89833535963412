import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Loading from "./Loading";
import { VerifyEmailPost } from "../Services/UserApi";
const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const locate = useLocation();
  let id;
  let token;

  useEffect(() => {
    id = locate.search.slice(
      locate.search.indexOf("id=") + 3,
      locate.search.indexOf("&")
    );
    token = locate.search.slice(locate.search.indexOf("token=") + 6);
    VerifyEmailPost(id, token).then((res) => {
      if (res.status === 200) {
        console.log("data", localStorage.getItem("user_id"));
        if (localStorage.getItem("user_id")) navigate("/account");
        else navigate("/");
      }
    });
  }, []);
  if (loading) return <Loading />;
  else {
    return <></>;
  }
};
export default VerifyEmail;
