import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    GetMetroName,
    GetpincodeByMetroId,
    DeleteMetro,
    GetpincodeByLpid,
    GetmetroByid
} from "../Services/MetroApi";
import { GetNonServiceablesPincodes } from "../Services/VendorApi"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import { LinearProgress, Chip } from "@mui/material";
const MetroNonServiceable = () => {
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const navigate = useNavigate();
    const [zoneName, setZoneName] = useState([]);
    const [zones, setZones] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        BindZone();
    }, []);

    let Head;

    const BindZone = () => {
        let mounted = true;
        let lpid = localStorage.getItem("user_id")
        GetNonServiceablesPincodes(lpid)
        .then((res) => res.json())
            .then((data) => {
                if (mounted) {
                    setZones(data);
                    setLoading(false);
                    // setPincodes(data.pincodes)
                }
            })
    };
    const toggleAccordion = (id) => {
        setSelectedAccordion(selectedAccordion === id ? null : id);
    };

    Head = zones.map((zone, i) => (
        <div className="accordion-item" key={i}>
            {/* <h2 className="accordion-header" id={`heading${head.id}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                        className="accordion-button collapsed"
                        // onClick={(e) => Togglespan(head.id, e, head.metroName)}
                        onClick={(e) => toggleAccordion()}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${head.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${head.id}`}
                    >Non Serviceable Pincodes
                    </button>

                </div>
            </h2> */}
               <h2 className="accordion-header" id={`heading${zone.lpId}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                        className="accordion-button collapsed"
                        onClick={() => toggleAccordion(zone.lpId)}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${zone.lpId}`}
                        aria-expanded="true"
                        aria-controls={`collapse${zone.lpId}`}
                    >
                        Non Serviceable Pincodes
                    </button>
                </div>
            </h2>
            <div
                id={`collapse${zone.lpId}`}
                className={selectedAccordion === zone.lpId
                     ? "accordion-collapse collapse show" 
                     : "accordion-collapse collapse"}
                aria-labelledby={`heading${zone.lpId}`}
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                    {zone.pincodes.length !== 0 ? (
                        zone.pincodes.map((val, i) => {
                            return (
                                <Chip
                                    value={val}
                                    label={val}
                                    p={1}
                                    variant="outlined"
                                    sx={{
                                        marginBottom: "5px",
                                        marginRight: "5px",
                                    }}
                                />
                            );
                        })
                    ) : (
                        <LinearProgress />
                    )}
                </div>
            </div>
        </div>
    ));

    if (loading) return <Loading />;
    else {
        return (
            <>
                <div className="content-wrapper">
                    <div className="d-flex justify-content-between align-items-center px-5">
                        <h3>Metro Non Serviceable Data</h3>
                        <Link to="/metrononserviceable" className="text-white btn btn-info">
                            Add Pincodes
                        </Link>
                    </div>
                    <div className="accordion px-5 mt-4" id="accordionExample">
                        {Head == "" ? (
                            <div className="text-center">
                                <h4>No Records Found !!</h4>
                            </div>
                        ) : (
                            Head
                        )}
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    }
};

export default MetroNonServiceable;
