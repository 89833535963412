import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import DynamicTable from "../DynamicTable";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Getserviceshavingzone,
  Getzonesbyservice,
  GetAllService,
} from "../../Services/ServiceApi";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import { GetZoneName } from "../../Services/ZoneApi";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { UploadRate, Getratebyservice } from "../../Services/RateApi";
import "../My.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  InsertAdditionalCharges,
  GetAdditionalMastersData,
  UpdateAdditionalCharges,
  GetUnitTypes,
} from "../../Services/RateApi";
import { GetFloorCount, AddFloors } from '../../Services/VendorApi'
import { Dialog, Grid, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem } from '@mui/material';

const ExcelUpload = () => {
  var curr = new Date();
  const [items, setItems] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [allservice, SetAllService] = useState([]);
  const [allzonenm, setAllzonenm] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tabldata, setTabledata] = useState([]);
  const [inputList, setInputList] = useState([
    {
      from: "",
      to: "",
      unit: "KG",
      rate: "",
    },
  ]);
  const [ratesheetid, setRatesheetid] = useState(0);
  const unit = ["KG"];
  curr.setDate(curr.getDate());
  var todaydate = curr.toISOString().substr(0, 10);
  curr.setDate(curr.getDate() + 30);
  var nextday = curr.toISOString().substr(0, 10);
  // const [ratelistData, setRatelistData] = useState({
  //   serviceid: "",
  //   fromdate: todaydate,
  //   todate: nextday,
  //   ratelist: [],
  //   min_Weight: "",
  //   max_Weight: "",
  //   min_Freight: "",
  //   docket_Charge: "",
  //   min_FOV: "",
  //   actual_FOV: "",
  //   minGreenTax: "",
  //   greenTaxperkg: "",
  //   fsc: "",
  //   min_ODA: "",
  //   actual_ODA: "",
  //   handlingChrg: "",
  //   gst: "",
  //   cod: "",
  //   cft: "",
  //   divisor: "",
  //   min_ODA2: "",
  //   actual_ODA2: "",
  // });
  const [ratelistData, setRatelistData] = useState({
    ServiceId: "",
    FromDate: todaydate,
    ToDate: nextday,
    RateList: [],
    CFT: "",
    Divisor: "",
    Min_Weight: "",
    Max_Weight: "",
    GST: "",
    MinimumODA1: "",
    appointmentCharges: "",
    MinimumODA2: "",
    MinimumODA3: "",
    ODA1PerKg: "",
    ODA2PerKg: "",
    ODA3PerKg: "",
    Min_Freight: "",
    floorCharges: [],
    appointmentPerKg: ""
  });
  const [loading, setLoading] = useState(true);
  const [masterdata, setMasterdata] = useState([]);
  const [dropMasterdata, setDropMasterdata] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState("");
  const [ratesAvailable, setRatesAvailable] = useState(false);
  // useEffect(() => {
  // }, [dropMasterdata]);
  useEffect(() => {
    // Getserviceshavingzone().then((res) => {
    GetAllService().then((res) => {
      SetAllService(res);
    });

    GetZoneName().then((res) => {
      setAllzonenm(React.Children.toArray(res.map((val) => val.name)));
      setLoading(false);
    });
    GetUnitTypes().then((res) => {
      setUnitOptions(res.data);
    });
    GetAdditionalCharges();
  }, []);

  const GetAdditionalCharges = (e) => {
    GetAdditionalMastersData().then((res) => {
      setMasterdata(res.data);
    });
  };
  let zoneTHead;
  let zoneTbody;
  zoneTHead = allzonenm.map((val, i) => {
    return (
      <>
        <th key={i}>{val}</th>
      </>
    );
  });
  zoneTbody = allzonenm.map((val, i) => {
    return (
      <>
        <tr>
          <td>{val}</td>
        </tr>
      </>
    );
  });
  const readExcel = (e) => {
    const file = e.target.files[0];
    const val = file.name.toLowerCase(),
      regex = new RegExp("(.*?).(xlsx)$");
    if (!regex.test(val)) {
      toast.warn("Please select .XLSX file format");
      e.target.value = "";
    } else {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { defval: 0 });
          const wsname1 = wb.SheetNames[1];
          const ws1 = wb.Sheets[wsname1];
          const data1 = XLSX.utils.sheet_to_json(ws1, { defval: 0 });
          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setHeaders(Object.keys(d[0]));
        d.map((val) => { });
        setRatelistData({
          ...ratelistData,
          ["RateList"]: d.map((val) => {
            return JSON.stringify(val);
          }),
        });

        setItems(d);
      });
    }
  };

  const inputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "Min_Weight" ||
      name === "Max_Weight" ||
      name === "min_FOV" ||
      name === "min_Freight" ||
      name === "min_ODA" ||
      name === "docket_Charge" ||
      name === "actual_ODA" ||
      name === "actual_FOV" ||
      name === "greenTaxperkg" ||
      name === "minGreenTax" ||
      name === "fsc" ||
      name === "GST" ||
      name === "cod" ||
      name === "handlingChrg" ||
      name === "CFT" ||
      name === "Divisor" ||
      name === "actual_ODA2" ||
      name === "min_ODA2" ||
      name === "MinimumODA1" ||
      name === "MinimumODA2" ||
      name === "MinimumODA3" ||
      name === "ODA1PerKg" ||
      name === "ODA2PerKg" ||
      name === "ODA3PerKg" ||
      name === "Min_Freight" ||
      name === "appointmentCharges" ||
      name === "appointmentPerKg"
    ) {
      if (!isNaN(value)) {
        setRatelistData({ ...ratelistData, [name]: value });
      }
    } else if (name === "FromDate" || name === "ToDate") {
      setRatelistData({ ...ratelistData, [name]: value });
    } else if (name === "ServiceId") {
      setLoading(true);
      let vendorid = localStorage.getItem("user_id");
      Getratebyservice(e.target.value, vendorid).then((res) => {
        // if (res.data.rates && res.data.rates.length > 0) {
        //   setRatesAvailable(true)
        // }
        if (res.status === 200) {
          let list = [];
          let listlen = 1;

          for (var i in res.data.rates) {
            listlen++;
            list.push(res.data.rates[i].zoneidto);
            if (listlen > Math.sqrt(res.data.rates.length)) break;
          }
          setRatesheetid(res.data.ratesheetId);
          Bindtablecolumn(list);
          Bindtabledata(list, res.data.rates);
          setLoading(false);
          document.getElementById("test-table-xls-button").click();
          document.getElementById("messagemodal").click();
          setColumns([]);
          setTabledata([]);
        } else {
          Getzonesbyservice(value).then((res) => {
            setAllzonenm(res.data.map((val) => val.name));
            setRatesheetid(0);
            setLoading(false);
            document.getElementById("test-table-xls-button").click();
            document.getElementById("messagemodal").click();
          });
        }
      });

      setRatelistData({ ...ratelistData, [name]: value });
    }
  };
  const Bindtablecolumn = (list) => {
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  const saveData = (e) => {
    e.preventDefault();
    const { ServiceId,
      floorCharges,
      appointmentCharges,
      FromDate,
      ToDate,
      RateList,
      CFT,
      Divisor,
      MinimumODA1,
      MinimumODA2,
      MinimumODA3,
      ODA1PerKg,
      ODA2PerKg,
      ODA3PerKg,
      Min_Freight,
      appointmentPerKg
    } = ratelistData;
    setLoading(true);
    if (
      ServiceId === "" ||
      FromDate === "" ||
      ToDate === "" ||
      RateList.length === 0 ||
      CFT === "" ||
      Divisor === "" ||
      MinimumODA1 === "" ||
      MinimumODA2 === "" ||
      MinimumODA3 === "" ||
      ODA1PerKg === "" ||
      ODA2PerKg === "" ||
      ODA3PerKg === "" ||
      Min_Freight === "" ||
      appointmentPerKg === ""
    ) {
      setLoading(false);
      toast.warning("Please fill all mandatories fields");
    } else {
      if (floorCharges.length === 0) {
        setLoading(false);
        toast.warning("Please add floor charges");
        return;
      }
      if (ratelistData.CFT === "") ratelistData.CFT = 0;
      if (ratelistData.Divisor === "") ratelistData.Divisor = 0;
      if (ratelistData.MinimumODA1 === "") ratelistData.MinimumODA1 = 0;
      if (ratelistData.MinimumODA2 === "") ratelistData.MinimumODA2 = 0;
      if (ratelistData.MinimumODA3 === "") ratelistData.MinimumODA3 = 0;
      if (ratelistData.ODA1PerKg === "") ratelistData.ODA1PerKg = 0;
      if (ratelistData.ODA2PerKg === "") ratelistData.ODA2PerKg = 0;
      if (ratelistData.ODA3PerKg === "") ratelistData.ODA3PerKg = 0;
      if (ratelistData.Min_Freight === "") ratelistData.Min_Freight = 0;
      if (ratelistData.appointmentPerKg === "") ratelistData.appointmentPerKg = 0;
      ratelistData.AdditionalCharges = JSON.stringify(dropMasterdata);
      UploadRate(ratelistData, ratesheetid).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          curr = new Date();
          curr.setDate(curr.getDate());
          todaydate = curr.toISOString().substr(0, 10);
          curr.setDate(curr.getDate() + 30);
          nextday = curr.toISOString().substr(0, 10);
          setRatelistData({
            ServiceId: "",
            FromDate: todaydate,
            ToDate: nextday,
            RateList: [],
            CFT: "",
            Divisor: "",
            Min_Weight: "",
            Max_Weight: "",
            GST: "",
            MinimumODA1: "",
            MinimumODA2: "",
            MinimumODA3: "",
            ODA1PerKg: "",
            ODA2PerKg: "",
            ODA3PerKg: "",
            Min_Freight: "",
            appointmentCharges: "",
            appointmentPerKg: "",
          });
          // setDropMasterdata([])
          setInputList([{ from: "", to: "", unit: "KG", rate: "" }]);
          setHeaders([]);
          setItems([]);
          setRatesheetid(0);
          setFloorChargesAdded(false);
          toast.success("Data saved successfully");
        } else {
          setLoading(false);
          toast.error(res.data);
        }
      });
    }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setRatelistData({ ...ratelistData, ["handlingChrg"]: inputList });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setRatelistData({
      ...ratelistData,
      ["handlingChrg"]: inputList.filter((val, i) => {
        return i !== index;
      }),
    });
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    const list = [...inputList];

    if (
      list[e]["from"] !== "" &&
      list[e]["to"] !== "" &&
      list[e]["rate"] !== ""
    ) {
      setInputList([...inputList, { from: "", to: "", unit: "KG", rate: "" }]);
    } else toast.warning("Please enter handling charges");
  };

  const [selectedOption, setSelectedOption] = useState("option0");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const section1Visible = selectedOption === "option1";
  const section2Visible = selectedOption === "option2";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    minValue: false,
    isDynamic: true,
    unitType: 0,
    minValueData: { label: "", unitType: 2, minValue: "" },
    calculationField: {
      percentageValueData: {
        labelText: "",
        unitType: 0,
        placeholderText: "",
        isdependent: false,
        amount: 0,
        calculationSteps: [
          {
            previousOperator: 0,
            leftSideValue: "",
            leftSideSelection: "",
            operator: "",
            rightSideValue: "",
            rightSideSelection: "",
          },
        ],
      },
      unitValueData: {
        labelText: "",
        unitType: "",
        placeholderText: "",
        unitAmount: 0,
      },
      slabValueData: {
        labelText: "",
        calculationSteps: [{ from: "", to: "", unitType: "", price: 0 }],
      },
    },
  });

  //PERCENTAGE DATA
  const lastStepIndex =
    additionalData.calculationField.percentageValueData.calculationSteps
      .length - 1;
  const lastStep =
    additionalData.calculationField.percentageValueData.calculationSteps[
    lastStepIndex
    ];

  const handleMinValueChange = (event) => {
    const newValue = event.target.checked;
    setAdditionalData((prev) => ({
      ...prev,
      minValue: newValue,
    }));
  };
  // Update minValueData
  const handleMinValueDataChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData((prevState) => ({
      ...prevState,
      minValueData: {
        ...prevState.minValueData,
        [name]: value,
      },
    }));
  };
  const handleBlankCalculationField = () => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        percentageValueData: {
          labelText: "",
          unitType: 0,
          placeholderText: "",
          isdependent: false,
          amount: 0,
          calculationSteps: [
            {
              previousOperator: 0,
              leftSideValue: "",
              leftSideSelection: "",
              operator: "",
              rightSideValue: "",
              rightSideSelection: "",
            },
          ],
        },
        unitValueData: {
          label: "",
          unitType: "",
          labelText: "",
          unitAmount: 0,
        },
        slabValueData: {
          labelText: "",
          calculationSteps: [{ from: "", to: "", unitType: "", price: 0 }],
        },
      },
    }));
  };
  const handleUnitTypeChange = (event) => {
    handleBlankCalculationField();
    const newValue = event.target.value;
    setAdditionalData((prev) => ({
      ...prev,
      unitType: parseFloat(newValue),
    }));
    console.log("additionalData", additionalData);
  };
  const handlepercentageValueDataLabelTextChange = (event) => {
    const newValue = event.target.value;
    setAdditionalData((prev) => ({
      ...prev,
      calculationField: {
        ...prev.calculationField,
        percentageValueData: {
          ...prev.calculationField.percentageValueData,
          labelText: newValue,
        },
      },
    }));
  };
  const handlepercentageValueDataUnitTypeChange = (event) => {
    console.log("percentageValueData unitType change", event.target.value);
    const newValue = event.target.value;
    setAdditionalData((prev) => ({
      ...prev,
      calculationField: {
        ...prev.calculationField,
        percentageValueData: {
          ...prev.calculationField.percentageValueData,
          unitType: newValue,
        },
      },
    }));
  };
  const handlepercentageValueDataPlaceholderTextChange = (event) => {
    const newValue = event.target.value;
    setAdditionalData((prev) => ({
      ...prev,
      calculationField: {
        ...prev.calculationField,
        percentageValueData: {
          ...prev.calculationField.percentageValueData,
          placeholderText: newValue,
        },
      },
    }));
  };
  const handlepercentageValueDataHasDependancyChange = (event) => {
    const newValue = event.target.checked;
    setAdditionalData((prev) => ({
      ...prev,
      calculationField: {
        ...prev.calculationField,
        percentageValueData: {
          ...prev.calculationField.percentageValueData,
          isdependent: newValue,
        },
      },
    }));
  };
  const handlepercentageValueCalculationStepsLeftValueChange = (event) => {
    const value = event.target.value;
    handleUpdateCalculationStep("leftSideValue", value);
  };
  const handlepercentageValueCalculationStepsLeftSelectionChange = (event) => {
    const value = event.target.value;
    handleUpdateCalculationStep("leftSideSelection", value);
  };
  const handlepercentageValueCalculationStepsLeftOperatorChange = (event) => {
    const value = event.target.value;
    handleUpdateCalculationStep("operator", value);
  };
  const handlepercentageValueCalculationStepsRightSideValueChange = (event) => {
    const value = event.target.value;
    handleUpdateCalculationStep("rightSideValue", value);
  };
  const handlepercentageValueCalculationStepsRightSideSelectionChange = (
    event
  ) => {
    const value = event.target.value;
    handleUpdateCalculationStep("rightSideSelection", value);
  };
  const handleUpdateCalculationStep = (fieldToUpdate, value) => {
    setAdditionalData((prevState) => {
      const updatedCalculationField = { ...prevState.calculationField };
      const lastStepIndex =
        updatedCalculationField.percentageValueData.calculationSteps.length - 1;

      if (lastStepIndex >= 0) {
        updatedCalculationField.percentageValueData.calculationSteps[
          lastStepIndex
        ] = {
          ...updatedCalculationField.percentageValueData.calculationSteps[
          lastStepIndex
          ],
          [fieldToUpdate]: value,
        };
      }

      return {
        ...prevState,
        calculationField: updatedCalculationField,
      };
    });
  };
  const handleAddStep = () => {
    const lastStepIndex =
      additionalData.calculationField.percentageValueData.calculationSteps
        .length - 1;
    const lastStep =
      additionalData.calculationField.percentageValueData.calculationSteps[
      lastStepIndex
      ];
    console.log("lastStep", lastStep);
    if (
      (lastStep.leftSideValue || lastStep.leftSideSelection) &&
      (lastStep.rightSideValue || lastStep.rightSideSelection) &&
      lastStep.operator
    ) {
      setIsModalOpen(true);
      setAdditionalData((prevState) => {
        const updatedCalculationField = { ...prevState.calculationField };
        updatedCalculationField.percentageValueData.calculationSteps.push({
          previousOperator: 0,
          leftSideValue: "",
          leftSideSelection: "",
          operator: "",
          rightSideValue: "",
          rightSideSelection: "",
        });
        console.log("isModalOpen", isModalOpen);
        return {
          ...prevState,
          calculationField: updatedCalculationField,
        };
      });
    } else {
      window.alert("Please complete your first operation");
    }
  };
  const handlepercentageValueCalculationStepspreviousOperatorChange = (
    event
  ) => {
    const value = event.target.value;
    handleUpdateCalculationStep("previousOperator", value);
  };
  const handleSubmitAddStep = () => {
    const lastStepIndex =
      additionalData.calculationField.percentageValueData.calculationSteps
        .length - 1;
    const lastStep =
      additionalData.calculationField.percentageValueData.calculationSteps[
      lastStepIndex
      ];

    if (lastStep && lastStep.previousOperator) {
      setIsModalOpen(false);
    } else {
      window.alert("Please select operator value");
    }
    console.log(
      "additionalData",
      additionalData.calculationField.percentageValueData
    );
  };
  const renderPreviewText = () => {
    return additionalData.calculationField.percentageValueData.calculationSteps
      .map((step) => {
        let stepText = "";
        if (step.previousOperator === "1") {
          stepText += " + ";
        } else if (step.previousOperator === "2") {
          stepText += " x ";
        } else if (step.previousOperator === "3") {
          stepText += " % ";
        } else if (step.previousOperator === "4") {
          stepText += " - ";
        }
        else if (step.previousOperator === "5") {
          stepText += " / ";
        }
        if (step.leftSideSelection) {
          stepText += getNameById(step.leftSideSelection);
        } else {
          stepText += step.leftSideValue || "";
        }

        if (step.operator === "1") {
          stepText += " + ";
        } else if (step.operator === "2") {
          stepText += " x ";
        } else if (step.operator === "3") {
          stepText += " % ";
        } else if (step.operator === "4") {
          stepText += " - ";
        }
        else if (step.operator === "5") {
          stepText += " / ";
        }
        if (step.rightSideSelection) {
          stepText += getNameById(step.rightSideSelection);
        } else {
          stepText += step.rightSideValue || "";
        }
        return stepText;
      })
      .join("");
  };
  const renderPreviewOperator = (unitType) => {
    const selectedUnit = unitOptions.find((x) => x.id === parseInt(unitType, 10));
    return selectedUnit ? selectedUnit.name : "";
  };
  const getNameById = (id) => {
    const item = masterdata.find((item) => item.id === id);
    return item ? item.name : null;
  };
  const clearPercentageCalculation = () => {
    setAdditionalData((prevState) => {
      const updatedCalculationField = { ...prevState.calculationField };
      updatedCalculationField.percentageValueData.calculationSteps = [
        {
          previousOperator: 0,
          leftSideValue: "",
          leftSideSelection: "",
          operator: "",
          rightSideValue: "",
          rightSideSelection: "",
        },
      ];
      return {
        ...prevState,
        calculationField: updatedCalculationField,
      };
    });
  };
  const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false);
  useEffect(() => { }, [additionalData]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  //UNIT DATA
  const handlePerUnitLabelChange = (e) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        unitValueData: {
          ...prevState.calculationField.unitValueData,
          labelText: e.target.value,
        },
      },
    }));
  };
  const handlePerUnitTypeChange = (e) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        unitValueData: {
          ...prevState.calculationField.unitValueData,
          unitType: e.target.value,
        },
      },
    }));
  };
  const handlePerUnitPlaceholderChange = (e) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        unitValueData: {
          ...prevState.calculationField.unitValueData,
          placeholderText: e.target.value,
        },
      },
    }));
  };
  const handlePerUnitAmountChange = (e) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        unitValueData: {
          ...prevState.calculationField.unitValueData,
          unitAmount: e.target.value,
        },
      },
    }));
  };
  //Slab Based
  const handleSlabLabelChange = (e) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        slabValueData: {
          ...prevState.calculationField.slabValueData,
          labelText: e.target.value,
        },
      },
    }));
  };
  const handleAddSlab = () => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        slabValueData: {
          ...prevState.calculationField.slabValueData,
          calculationSteps: [
            ...prevState.calculationField.slabValueData.calculationSteps,
            { from: "", to: "", unitType: "", price: 0 },
          ],
        },
      },
    }));
  };
  const handleSlabChange = (index, field, value) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        slabValueData: {
          ...prevState.calculationField.slabValueData,
          calculationSteps:
            prevState.calculationField.slabValueData.calculationSteps.map(
              (step, i) => {
                if (i === index) {
                  return {
                    ...step,
                    [field]: value,
                  };
                }
                return step;
              }
            ),
        },
      },
    }));
  };
  const handleRemoveSlab = (index) => {
    setAdditionalData((prevState) => ({
      ...prevState,
      calculationField: {
        ...prevState.calculationField,
        slabValueData: {
          ...prevState.calculationField.slabValueData,
          calculationSteps:
            prevState.calculationField.slabValueData.calculationSteps.filter(
              (_, i) => i !== index
            ),
        },
      },
    }));
  };
  //Submit Additional Charges
  const handleOpenAdditionalModal = () => {
    resetAdditionalCharges();
    setIsAdditionalModalOpen(true);
  };
  const handleCloseAdditionalModal = () => {
    setIsAdditionalModalOpen(false);
    resetAdditionalCharges();
  };
  const handleSubmitAdditionalCharges = () => {
    // setLoading(true)

    let body = {
      name: "",
      lpId: localStorage.getItem("user_id"),
      details: JSON.stringify(additionalData),
    };
    if (additionalData.unitType == 1) {
      body.name = additionalData.calculationField.percentageValueData.labelText;
    } else if (additionalData.unitType == 2) {
      body.name = additionalData.calculationField.unitValueData.labelText;
    } else if (additionalData.unitType == 3) {
      body.name = additionalData.calculationField.slabValueData.labelText;
    }
    InsertAdditionalCharges(body).then((res) => {
      if (res.data.code === 200) {
        GetAdditionalCharges();
        setIsAdditionalModalOpen(false);
        resetAdditionalCharges();
        // setLoading(false)
      } else {
        toast.error(res.data.message)
      }
    });
  };
  const resetAdditionalCharges = () => {
    setAdditionalData((prevState) => ({
      ...prevState,
      minValue: false,
      isDynamic: true,
      unitType: 0,
      minValueData: { label: "", unitType: "", minValue: "" },
      calculationField: {
        percentageValueData: {
          labelText: "",
          unitType: 0,
          placeholderText: "",
          isdependent: false,
          amount: 0,
          calculationSteps: [
            {
              previousOperator: 0,
              leftSideValue: "",
              leftSideSelection: "",
              operator: "",
              rightSideValue: "",
              rightSideSelection: "",
            },
          ],
        },
        unitValueData: {
          label: "",
          unitType: "",
          labelText: "",
          unitAmount: 0,
        },
        slabValueData: {
          labelText: "",
          calculationSteps: [{ from: "", to: "", unitType: "", price: 0 }],
        },
      },
    }));
  };
  //Drag and Drop
  function allowDrop(event) {
    event.preventDefault();
  }
  function drag(event, data) {
    event.dataTransfer.setData("text", JSON.stringify(data));
  }
  function drop(event) {
    event.preventDefault();
    const data = event.dataTransfer.getData("text");
    const getDetails = JSON.parse(data);
    const masterData = JSON.parse(getDetails.details);
    const name = getDetails.name;
    const newData = { masterData, name };
    const nameExists = dropMasterdata.some((item) => item.name === name);
    if (!nameExists) {
      if (dropMasterdata.length === 0) {
        setDropMasterdata([newData]);
      } else {
        setDropMasterdata((prevState) => [...prevState, newData]);
      }
    }
  }
  const removedragDataByIndex = (index) => {
    const newdropMasterdata = [...dropMasterdata];
    newdropMasterdata.splice(index, 1);
    setDropMasterdata(newdropMasterdata);
  };
  function updateCalculationSteps(dataIndex, updatedField, updatedValue) {
    const updatedData = dropMasterdata.map((dataItem, index) => {
      if (index === dataIndex) {
        const updatedSteps =
          dataItem.masterData.calculationField.slabValueData.calculationSteps.map(
            (step, stepIndex) => {
              if (stepIndex === dataIndex) {
                return {
                  ...step,
                  [updatedField]: updatedValue,
                };
              }
              return step;
            }
          );

        return {
          ...dataItem,
          masterData: {
            ...dataItem.masterData,
            calculationField: {
              ...dataItem.masterData.calculationField,
              slabValueData: {
                ...dataItem.masterData.calculationField.slabValueData,
                calculationSteps: updatedSteps,
              },
            },
          },
        };
      }
      return dataItem;
    });

    setDropMasterdata(updatedData);
  }
  const renderPercentageText = (calculationSteps) => {
    return calculationSteps.map((step) => {
      console.log("step", step);
      let stepText = "";
      if (step.previousOperator === "1") {
        stepText += " + ";
      } else if (step.previousOperator === "2") {
        stepText += " x ";
      } else if (step.previousOperator === "3") {
        stepText += " % ";
      } else if (step.previousOperator === "4") {
        stepText += " - ";
      } else if (step.previousOperator === "5") {
        stepText += " / ";
      }
      if (step.leftSideSelection) {
        stepText += getNameById(step.leftSideSelection);
      } else {
        stepText += step.leftSideValue || "";
      }
      if (step.operator === "1") {
        stepText += " + ";
      } else if (step.operator === "2") {
        stepText += " x ";
      } else if (step.operator === "3") {
        stepText += " % ";
      } else if (step.operator === "4") {
        stepText += " - ";
      } else if (step.operator === "5") {
        stepText += " / ";
      }
      if (step.rightSideSelection) {
        stepText += getNameById(step.rightSideSelection);
      } else {
        stepText += step.rightSideValue || "";
      }
      return stepText;
    })
      .join("");
  };
  const handleUnitAmountChange = (e, index) => {
    const { value } = e.target;
    setDropMasterdata((prevState) => {
      const updatedData = [...prevState];
      updatedData[index].masterData.calculationField.unitValueData.unitAmount =
        value;
      return updatedData;
    });
  };
  const handlePercentageAmountChange = (e, index) => {
    const { value } = e.target;
    setDropMasterdata((prevState) => {
      const updatedData = [...prevState];
      updatedData[
        index
      ].masterData.calculationField.percentageValueData.amount = value;
      return updatedData;
    });
  };
  //View Additional Data
  const [viewDropMasterdata, setViewDropMasterdata] = useState([]);
  const [isAdditionalViewModalOpen, setIsAdditionalViewModalOpen] =
    useState(false);
  function viewAdditionalData(data) {
    const masterData = JSON.parse(data.details);
    const name = data.name;
    const newData = { masterData, name };
    if (viewDropMasterdata.length === 0) {
      setViewDropMasterdata([newData]);
    } else {
      setViewDropMasterdata((prevState) => [...prevState, newData]);
    }
    setIsAdditionalViewModalOpen(true);
  }
  function onCloseViewModal() {
    setIsAdditionalViewModalOpen(false);
    setViewDropMasterdata([]);
  }
  //Edit Additional Data
  const [isEditAdditionalModalOpen, setIsAdditionalEditModalOpen] =
    useState(false);
  const [additionalId, setAdditionalId] = useState(false);
  function editAdditionalData(data) {
    console.log("data", data);
    const masterData = JSON.parse(data.details);
    setAdditionalData(masterData);
    setAdditionalId(data.id);
    setIsAdditionalEditModalOpen(true);
  }
  function onCloseEditModal() {
    setIsAdditionalEditModalOpen(false);
    resetAdditionalCharges();
  }
  const handleSubmitEditAdditionalCharges = () => {
    setLoading(true);
    let body = {
      name: "",
      lpId: localStorage.getItem("user_id"),
      details: JSON.stringify(additionalData),
      id: additionalId,
    };
    if (additionalData.unitType == 1) {
      body.name = additionalData.calculationField.percentageValueData.labelText;
    } else if (additionalData.unitType == 2) {
      body.name = additionalData.calculationField.unitValueData.labelText;
    } else if (additionalData.unitType == 3) {
      body.name = additionalData.calculationField.slabValueData.labelText;
    }
    UpdateAdditionalCharges(body).then((res) => {
      if (res.data.code === 200) {
        GetAdditionalCharges();
        setIsAdditionalEditModalOpen(false);
        resetAdditionalCharges();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res.data.message)
      }
    });
  };
  useEffect(() => { }, [dropMasterdata]);
  const [floorChargesAdded, setFloorChargesAdded] = useState(false);
  const initialRowFloorDialog = [{ floor: '', price: '' }];
  const [openFloorDialog, setOpenFloorDialog] = useState(false);
  const [rowsFloorDialog, setRowsFloorDialog] = useState([{ floor: '', price: '' }]);
  const [floorCount, setfloorCount] = useState([])
  useEffect(() => {
    if (openFloorDialog) {
      GetFloorCount(localStorage.getItem('user_id') , false).then(res => {
        setfloorCount(res.data);
      });
    }
  }, [openFloorDialog]);
  const handleOpenFloorDialog = () => {
    setOpenFloorDialog(true);
    GetFloorCount(localStorage.getItem('user_id'), false).then(res => {
      setfloorCount(res.data)
    })
  }

  const handleCloseFloorDialog = () => {
    // setRowsFloorDialog(initialRowFloorDialog)
    setRowsFloorDialog(ratelistData.floorCharges.length ? ratelistData.floorCharges : [{ floor: '', price: '' }]);
    setOpenFloorDialog(false);
  }
  const handleAddRowFloorDialog = () => {
    setRowsFloorDialog([...rowsFloorDialog, { floor: '', price: '' }]);
  };

  const handleRemoveRowFloorDialog = (index) => {
    const newRows = rowsFloorDialog.filter((row, i) => i !== index);
    setRowsFloorDialog(newRows.length ? newRows : initialRowFloorDialog);
  };
  const validatePrice = (price) => {
    const regex = /^\d{0,10}$/;
    return regex.test(price);
  };
  const handleInputChangeFloorDialog = (index, event) => {
    const { name, value } = event.target;
    if (name === 'price' && !validatePrice(value)) {
      toast.warning('Price must be numeric and up to 10 digits long');
      return;
    }
    const newRows = [...rowsFloorDialog];
    newRows[index][name] = value;
    setRowsFloorDialog(newRows);
  };

  const handleSubmitFloorDialog = () => {
    setRatelistData((prevData) => ({
      ...prevData,
      floorCharges: rowsFloorDialog,
    }));
    setFloorChargesAdded(true);
    console.log(rowsFloorDialog);
    handleCloseFloorDialog();
  };

  const [floorName, setfloorName] = useState("")

  const handleAddFloors = () => {
    if (floorName === "") {
      toast.error("Please enter a floor name");
    } else {
      let body = {
        lpId: localStorage.getItem('user_id'),
        floor: floorName,
        isB2C: false
      };
      AddFloors(body)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            toast.error(res.message);
            throw new Error(res.message);
          } else {
            throw new Error('Unexpected response status');
          }
        })
        .then((data) => {
          if (data.code === 200) {
            toast.success("Floor added successfully");
            setfloorName("");
            GetFloorCount(localStorage.getItem('user_id'), false)
            .then((res) => {
              setfloorCount(res.data);
            })
          } else {
            toast.error(data.message);
            throw new Error(data.message);
          }
        })
        .catch((err) => {
          toast.error(err.message || "Failed to add floor");
          setfloorName("")
        });
    }
  };



  if (loading) return <Loading />;
  else {
    return (
      <>
        <button
          id="messagemodal"
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          style={{ display: "none" }}
        ></button>

        {/* After downloding excel file information popup start */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Courier Rate Upload Instructions
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Sample format has been downloaded for the selected service.
                Please fill in rate in given format and save file as "Excel
                Workbook". Then upload the same file.
                <br />
                <span style={{ color: "red" }}>
                  Please do not change any predefined columns or rows.
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* After downloding excel file information popup ends */}

        <div className="content-wrapper ">
          {/* Route header section starts */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="main-title">Upload Cargo Rates</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <span>Home</span>
                    </li>
                    <li className="breadcrumb-item active">
                      Upload Cargo Rates
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* Route header section ends */}

          <section className="content">
            <div className="container-fluid">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-shine btn-success d-none"
                  table="table-to-xls"
                  filename="RateList"
                  sheet="RateList"
                  buttonText={<i className="fas fa-download"> Sample Format</i>}
                />
              </div>

              <div className="row">
                {/*Left Master additional data card start */}
                <div className="col-md-4">
                  <Card className="side-card-ui custum-shadow p-3 left-side-v">
                    <div className="c-header">Additional Charges</div>

                    {!masterdata || masterdata.length === 0 ? (
                      <div className="c-body text-center width-100 mt-auto mb-auto">
                        <img
                          src="images/hand.png"
                          className="icon-img"
                          alt="Hand Icon"
                        />
                      </div>
                    ) : (
                      <div className="c-body text-center width-100 ui-scroll">
                        <div className="drag-list">
                          {masterdata
                            .filter((add) => !add.isDeleted)
                            .map((add, index) => (
                              <div
                                className="option"
                                draggable="true"
                                onDragStart={(event) => drag(event, add)}
                              >
                                <a>{add.name}</a>
                                <div className="action-btn">
                                  <RemoveRedEyeRoundedIcon
                                    className="mr-2"
                                    onClick={() => viewAdditionalData(add)}
                                  />
                                  <DriveFileRenameOutlineRoundedIcon
                                    onClick={() => editAdditionalData(add)}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    <div className="c-footer">
                      <a
                        onClick={handleOpenAdditionalModal}
                        className="btn btn-red"
                      >
                        Add additional Charges
                      </a>
                    </div>
                  </Card>
                </div>
                {/*Left Master additional data card end */}

                {/* Right section additional rates start */}
                <div className="col-md-8 form-ui">
                  <Card className="custum-shadow right-side-v">
                    <div className="c-body ui-scroll">
                      {/* Select service and upload excel fields start */}
                      <div className="width-100">
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label className="label-ui">
                              Select Service Name
                            </label>
                            <select
                              className="form-select"
                              onChange={inputEvent}
                              name="ServiceId"
                              value={ratelistData.ServiceId}
                            >
                              <option selected hidden>
                                Select
                              </option>
                              {!(allservice.length > 0) ? (
                                <option>No Data Found</option>
                              ) : (
                                allservice.map((val, i) => {
                                  return (
                                    <option key={i} value={val.id}>
                                      {val.name}
                                    </option>
                                  );
                                })
                              )}
                            </select>
                          </div>
                          <div className="form-group col-sm-6">
                            <label className="label-ui">
                              Upload Excel file*
                            </label>
                            <input
                              type="file"
                              className="form-control-file"
                              accept=".xlsx"
                              onChange={(e) => {
                                readExcel(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Select service and upload excel fields end */}

                      {/* Rate CFT and Divisor fields start */}
                      <div className="width-100">
                        <label className="label-ui">Rate Formula</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">NO.</div>
                              </div>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="CFT"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="CFT"
                                value={ratelistData.CFT}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="LBH*CFT Divide By"
                              aria-label="Maximum"
                              onChange={inputEvent}
                              name="Divisor"
                              value={ratelistData.Divisor}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Rate CFT and Divisor fields end */}

                      {/* Minimum and max chargeable weight fields start */}
                      <div className="width-100">
                        <label className="label-ui">Chargeable Weight</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">KG</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Minimum"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="Min_Weight"
                                value={ratelistData.Min_Weight}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Maximum"
                              aria-label="Maximum"
                              onChange={inputEvent}
                              name="Max_Weight"
                              value={ratelistData.Max_Weight}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Minimum and max chargeable weight fields end */}

                      {/* Gst fields start */}
                      <div className="width-100">
                        <label className="label-ui">GST</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="GST"
                                aria-label="GST"
                                onChange={inputEvent}
                                name="GST"
                                value={ratelistData.GST}
                              />
                            </div>

                          </div>
                          <div className="col-sm-6 form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Min Freight"
                              aria-label="Maximum"
                              onChange={inputEvent}
                              name="Min_Freight"
                              value={ratelistData.Min_Freight}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="width-100">
                        <label className="label-ui">Outer Delivery Area (ODA1)</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Minimum ODA1"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="MinimumODA1"
                                value={ratelistData.MinimumODA1}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">Per KG</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="ODA1 PER KG"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="ODA1PerKg"
                                value={ratelistData.ODA1PerKg}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="width-100">
                        <label className="label-ui">Outer Delivery Area (ODA2)</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Minimum ODA2"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="MinimumODA2"
                                value={ratelistData.MinimumODA2}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">Per KG</div>
                              </div>
                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="ODA2 PER KG"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="ODA2PerKg"
                                value={ratelistData.ODA2PerKg}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="width-100">
                        <label className="label-ui">Outer Delivery Area (ODA3)</label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Minimum ODA3"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="MinimumODA3"
                                value={ratelistData.MinimumODA3}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">Per KG</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="ODA3 PER KG"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="ODA3PerKg"
                                value={ratelistData.ODA3PerKg}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Gst fields ends */}

                      {/* Appointment Delivery Charges starts */}
                      <div className="width-100">
                        <label className="label-ui">
                          Appointment Delivery Charges
                        </label>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Minimum Charges"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="appointmentCharges"
                                value={ratelistData.appointmentCharges}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">₹</div>
                              </div>

                              <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Rate per KG"
                                aria-label="Minimum"
                                onChange={inputEvent}
                                name="appointmentPerKg"
                                value={ratelistData.appointmentPerKg}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Appointment Delivery Charges end */}


                      {!floorChargesAdded ? (
                        <Button variant="contained" onClick={handleOpenFloorDialog}>Add Floor Charges</Button>
                      ) : (

                        <div className="width-100">
                          <div className="d-flex align-items-center">
                            <label className="label-ui">
                              Floor Charges
                            </label>
                            <IconButton className="ml-auto p-0" onClick={handleOpenFloorDialog}>
                              <EditIcon className="btn-action-gray" />
                            </IconButton>
                          </div>
                          <div className="row">
                            {/* {ratelistData.floorCharges.map((charge, index) => {
                              const floorName = floorCount.find(floor => floor.floorId === charge.floor)?.floor;
                              return (
                                <div key={index} className="col-sm-4 form-group">
                                  <div className="input-group mr-sm-2 input-group-ui">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">{floorName === 0 ? 'Ground' : floorName}</div>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={charge.price}
                                      disabled
                                    />
                                  </div>
                                </div>
                              );
                            })} */}
                            <div className="row">
                              {Array.isArray(ratelistData.floorCharges) && ratelistData.floorCharges.length > 0 ? (
                                ratelistData.floorCharges.map((charge, index) => {
                                  const floorName = floorCount.find(floor => floor.floorId === charge.floor)?.floor;
                                  return (
                                    <div key={index} className="col-sm-4 form-group">
                                      <div className="input-group mr-sm-2 input-group-ui">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text">{floorName === 0 ? 'Ground' : floorName}</div>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={charge.price}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div>No floor charges added</div>
                              )}
                            </div>

                          </div>
                        </div>
                      )}


                      {/* From date and to date fields started */}
                      <div className="width-100">
                        <label className="label-ui">Date</label>
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">From</div>
                              </div>
                              <input
                                type="date"
                                className="form-control"
                                onChange={inputEvent}
                                name="FromDate"
                                value={ratelistData.FromDate}
                              />
                            </div>
                          </div>
                          <div className="form-group col-sm-6">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">To</div>
                              </div>
                              <input
                                type="date"
                                className="form-control"
                                onChange={inputEvent}
                                name="ToDate"
                                value={ratelistData.ToDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* From date and to date fields end */}

                      {/* Dynamic Additional rate fields started */}
                      <h4 className="title-text">Additional Charges</h4>
                      {dropMasterdata.map((dataItem, index) => (
                        <React.Fragment key={index}>
                          {dataItem?.masterData.unitType === 1 && (
                            <div className="width-100 p-relative">
                              <label className="label-ui">
                                {dataItem?.name}
                              </label>
                              <div className="row">
                                {dataItem.masterData.minValue ? (
                                  <div className="form-group col-sm-6">
                                    <div class="input-group mr-sm-2 input-group-ui">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">
                                          {dataItem.masterData.minValueData
                                            .unitType == 1
                                            ? "%"
                                            : "$N"}
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control"
                                        disabled
                                        placeholder={`Minimum ${dataItem.masterData.minValueData.label}`}
                                        value={
                                          dataItem.masterData.minValueData
                                            .minValue
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="form-group col-sm-6">
                                  <div class="input-group mr-sm-2 input-group-ui">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {renderPreviewOperator(
                                          dataItem.masterData.calculationField
                                            .percentageValueData.unitType
                                        )}
                                      </div>
                                    </div>

                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled={
                                        dataItem.masterData.calculationField
                                          .percentageValueData.isdependent
                                          ? true
                                          : false
                                      }
                                      placeholder={
                                        dataItem.masterData.calculationField
                                          .percentageValueData.isdependent
                                          ? renderPercentageText(
                                            dataItem.masterData
                                              .calculationField
                                              .percentageValueData
                                              .calculationSteps
                                          )
                                          : ""
                                      }
                                      value={
                                        !dataItem.masterData.calculationField
                                          .percentageValueData.isdependent
                                          ? dataItem.masterData.calculationField
                                            .percentageValueData.amount
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handlePercentageAmountChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <DeleteOutlineRoundedIcon
                                className="delete-icon"
                                onClick={() => removedragDataByIndex(index)}
                              />
                            </div>
                          )}

                          {dataItem?.masterData.unitType === 2 && (
                            <div className="width-100 p-relative">
                              <label className="label-ui">
                                {dataItem?.name}
                              </label>
                              <div className="row">
                                {dataItem.masterData.minValue ? (
                                  <div className="form-group col-sm-6">
                                    <div class="input-group mr-sm-2 input-group-ui">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">
                                          {dataItem.masterData.minValueData
                                            .unitType == 1
                                            ? "%"
                                            : "$N"}
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control"
                                        disabled
                                        placeholder={`Minimum ${dataItem.masterData.minValueData.label}`}
                                        value={
                                          dataItem.masterData.minValueData
                                            .minValue
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="form-group col-sm-6">
                                  <div class="input-group mr-sm-2 input-group-ui">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">
                                        {renderPreviewOperator(
                                          dataItem.masterData.calculationField
                                            .unitValueData.unitType
                                        )}
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) =>
                                        handleUnitAmountChange(e, index)
                                      }
                                      placeholder={
                                        dataItem.masterData.calculationField
                                          .unitValueData.placeholderText
                                      }
                                      value={
                                        dataItem.masterData.calculationField
                                          .unitValueData.unitAmount
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <DeleteOutlineRoundedIcon
                                className="delete-icon"
                                onClick={() => removedragDataByIndex(index)}
                              />
                            </div>
                          )}

                          {dataItem?.masterData.unitType === 3 && (
                            <div className="width-100 p-relative">
                              <label className="label-ui">
                                {dataItem?.name}
                              </label>
                              {dataItem.masterData.calculationField.slabValueData.calculationSteps.map(
                                (unit, i) => (
                                  <React.Fragment key={i}>
                                    <div className="row">
                                      <div className="form-group col-sm-3">
                                        <div class="input-group mr-sm-2 input-group-ui">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">
                                              From
                                            </div>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control"
                                            value={unit.from}
                                            onChange={(e) =>
                                              updateCalculationSteps(
                                                i,
                                                "from",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group col-sm-3">
                                        <div class="input-group mr-sm-2 input-group-ui">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">
                                              To
                                            </div>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control"
                                            value={unit.to}
                                            onChange={(e) =>
                                              updateCalculationSteps(
                                                i,
                                                "to",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      {/* <div className="form-group col-sm-3">
                                        <select
                                          className="form-select"
                                          name="unitType"
                                          //value={unit.unitType}
                                          value={selectedUnitType}
                                          // onChange={(e) => updateCalculationSteps(i, "unitType", e.target.value)}
                                          onChange={(e) => {
                                            debugger;
                                            const selectedValue = e.target.value;
                                            setSelectedUnitType(selectedValue);
                                            updateCalculationSteps(i, "unitType", selectedValue);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {unitOptions.map((unit) => (
                                            <option key={unit.id} value={unit.id}>
                                              {unit.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div> */}

                                      <div className="form-group col-sm-3">
                                        <select
                                          className="form-select"
                                          name="unitType"
                                          value={
                                            unit.unitType
                                          }
                                          onChange={(e) =>
                                            handleSlabChange(
                                              index,
                                              "unitType",
                                              e.target.value
                                            )
                                          }
                                        >
                                          {unitOptions.map((un) => (
                                            <option
                                              key={un.id}
                                              value={un.id}
                                            >
                                              {un.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className="form-group col-sm-3">
                                        <div class="input-group mr-sm-2 input-group-ui">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">
                                              ₹
                                            </div>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control"
                                            value={unit.price}
                                            onChange={(e) =>
                                              updateCalculationSteps(
                                                i,
                                                "price",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              )}

                              <DeleteOutlineRoundedIcon
                                className="delete-icon"
                                onClick={() => removedragDataByIndex(index)}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      ))}

                      {/* Drag card started */}
                      <div
                        className="drag-box mt-3"
                        onDrop={(event) => drop(event)}
                        onDragOver={(event) => allowDrop(event)}
                      >
                        <img src="images/gesture.png" className="icon-img" />
                        <i>Drag the additional charges</i>
                      </div>
                      {/* Drag card ends */}
                    </div>

                    {/* Uploaded dynamic excel view started */}
                    <table
                      className="table table-responsive d-none mb-3"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr>
                          <th>From/To</th>
                          {tabldata.length === 0 ? zoneTHead : columns}
                        </tr>
                      </thead>
                      <tbody>
                        {tabldata.length === 0 ? zoneTbody : tabldata}
                      </tbody>
                    </table>
                    <div className="row  mb-3">
                      <div className="col">
                        <DynamicTable headers={headers} data={items} />
                      </div>
                    </div>
                    {/* Uploaded dynamic excel view ends */}

                    {/* View rate and Submit button starts */}
                    <div className="c-footer">
                      <a href="#/viewuploadedrate"
                        //  disabled={!ratesAvailable} 
                        className="btn btn-red mr-2">
                        View Rate
                      </a>
                      {/* <button
                        disabled={!ratesAvailable}
                        className={`btn btn-red mr-2 ${!ratesAvailable ? 'disabled-button' : ''}`}
                        onClick={() => {
                          if (ratesAvailable) {
                            window.location.href = "#/viewuploadedrate";
                          }
                        }}
                      >
                        View Rate
                      </button> */}
                      <Button
                        variant="contained"
                        className="btn btn-red-bg"
                        onClick={saveData}
                      >
                        Save & Submit
                      </Button>
                    </div>
                    {/* View rate and Submit button ends */}
                  </Card>
                </div>
                {/* Right section additional rates ends */}
              </div>
            </div>
          </section>

          {/* add additional-charges dialog box started  */}
          <div
            id="additional-charges"
            className={`modal fade modal-ui-forms ${isAdditionalModalOpen ? "show" : ""
              }`}
            tabindex="-1"
            aria-labelledby="additional-chargesLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            style={{
              display: isAdditionalModalOpen ? "block" : "none",
            }}
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Additional Fields</h5>
                  <button
                    type="button"
                    class="close"
                    onClick={handleCloseAdditionalModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="top-form">
                    <div class="form-check form-switch switch-ui">
                      <label class="form-check-label" for="min-value">
                        Min Value
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="min-value"
                        value={additionalData.minValue}
                        onChange={handleMinValueChange}
                      />
                    </div>

                    <div class="inline-input">
                      <label className="label-ui mb-0 mr-3">Type</label>
                      <select
                        class="form-select"
                        value={additionalData.unitType}
                        onChange={handleUnitTypeChange}
                      >
                        <option value={0}>Select</option>
                        <option value={1}>Calculative/Fixed</option>
                        <option value={2}>Per Unit</option>
                        <option value={3}>Slab Based</option>
                      </select>
                    </div>
                  </div>
                  {additionalData.minValue ? (
                    <div className="min-value">
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            className="form-control"
                            name="label"
                            value={additionalData.minValueData.label}
                            onChange={handleMinValueDataChange}
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label className="label-ui">Unit type</label>
                          <select
                            className="form-select"
                            name="unitType"
                            value={additionalData.minValueData.unitType}
                            onChange={handleMinValueDataChange}
                          >
                            <option value={2} selected>
                              $N
                            </option>
                            {/* <option value={1}>%</option> */}
                          </select>
                        </div>

                        <div className="form-group col-sm-3">
                          <label className="label-ui">Min value</label>
                          <input
                            type="text"
                            className="form-control"
                            name="minValue"
                            value={additionalData.minValueData.minValue}
                            onChange={handleMinValueDataChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {additionalData.unitType == 1 ? (
                    <div className="percentage">
                      <h6 className="small-title">Percentage</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text"
                            class="form-control"
                            onChange={handlepercentageValueDataLabelTextChange}
                            value={
                              additionalData.calculationField
                                .percentageValueData.labelText
                            }
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Unit type</label>
                          <select
                            class="form-select"
                            onChange={handlepercentageValueDataUnitTypeChange}
                            value={
                              additionalData.calculationField
                                .percentageValueData.unitType
                            }
                          >
                            {/* <option value={0}>Select</option>
                            <option value={1}> Gram</option>
                            <option value={2}> KG</option> */}
                            <option value="">Select</option>
                            {unitOptions.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            class="form-control"
                            onChange={
                              handlepercentageValueDataPlaceholderTextChange
                            }
                            value={
                              additionalData.calculationField
                                .percentageValueData.placeholderText
                            }
                          />
                        </div>

                        <div className="form-group col-sm-2">
                          <label className="label-ui">Has Dependancy</label>
                          <div class="form-check form-switch switch-ui">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={
                                additionalData.calculationField
                                  .percentageValueData.isdependent
                              }
                              onChange={
                                handlepercentageValueDataHasDependancyChange
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {additionalData.calculationField.percentageValueData
                        .isdependent ? (
                        <div>
                          <div className="calculate-charges">
                            <div className="row">
                              <div className="form-group col-sm-4">
                                <label className="label-ui">
                                  Calculate charges
                                </label>
                                <div class="input-group mr-sm-2 input-group-ui">
                                  <input
                                    type="text"
                                    onChange={
                                      handlepercentageValueCalculationStepsLeftValueChange
                                    }
                                    class="form-control"
                                    value={lastStep.leftSideValue}
                                    disabled={lastStep.leftSideSelection !== ""}
                                  />
                                  <div class="input-group-prepend">
                                    <div class="input-group-text p-0 group-select">
                                      <select
                                        className="form-select"
                                        onChange={
                                          handlepercentageValueCalculationStepsLeftSelectionChange
                                        }
                                        value={lastStep.leftSideSelection}
                                        disabled={lastStep.leftSideValue !== ""}
                                      >
                                        <option value={""}>Select</option>
                                        {masterdata.map((add, index) => (
                                          <option value={add.id}>
                                            {add.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-sm-4">
                                <label className="label-ui">Operator</label>
                                <select
                                  className="form-select"
                                  onChange={
                                    handlepercentageValueCalculationStepsLeftOperatorChange
                                  }
                                  class="form-control"
                                  value={lastStep.operator}
                                >
                                  <option value={0}>Select</option>
                                  <option value={1}>Plus(+)</option>
                                  <option value={2}>Multiply(x)</option>
                                  <option value={3}>Percentage(%)</option>
                                  <option value={4}>Subtraction(-)</option>
                                  <option value={5}>Division(/)</option>
                                </select>
                              </div>

                              <div className="form-group col-sm-4">
                                <label className="label-ui">
                                  Calculate charges
                                </label>
                                <div class="input-group mr-sm-2 input-group-ui">
                                  <input
                                    type="text"
                                    class="form-control"
                                    onChange={
                                      handlepercentageValueCalculationStepsRightSideValueChange
                                    }
                                    value={lastStep.rightSideValue}
                                    disabled={
                                      lastStep.rightSideSelection !== ""
                                    }
                                  />
                                  <div class="input-group-prepend">
                                    <div class="input-group-text p-0 group-select">
                                      <select
                                        className="form-select"
                                        onChange={
                                          handlepercentageValueCalculationStepsRightSideSelectionChange
                                        }
                                        value={lastStep.rightSideSelection}
                                        disabled={
                                          lastStep.rightSideValue !== ""
                                        }
                                      >
                                        <option value={""}>Select</option>
                                        {masterdata.map((add, index) => (
                                          <option value={add.id}>
                                            {add.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex">
                              <button
                                type="button"
                                class="btn btn-red-bg small-btn ms-auto"
                                onClick={handleAddStep}
                              >
                                Add
                              </button>
                            </div>
                          </div>

                          <div className="calculate-preview">
                            <h5>Calculate Preview</h5>
                            <div className="d-flex">
                              <span className="preview">
                                {renderPreviewText()}
                              </span>
                              <AddIcon className="plus-icon ms-3" />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {additionalData.unitType == 2 ? (
                    <div className="per-unit">
                      <h6 className="small-title">Per Unit</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text"
                            class="form-control"
                            value={
                              additionalData.calculationField.unitValueData
                                .labelText
                            }
                            onChange={handlePerUnitLabelChange}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Unit type</label>
                          <select
                            class="form-select"
                            value={
                              additionalData.calculationField.unitValueData
                                .unitType
                            }
                            onChange={handlePerUnitTypeChange}
                          >
                            {/* <option value={0}>Select</option>
                            <option value={1}> Gram</option>
                            <option value={2}> KG</option> */}
                            <option value="">Select</option>
                            {unitOptions.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            class="form-control"
                            value={
                              additionalData.calculationField.unitValueData
                                .placeholderText
                            }
                            onChange={handlePerUnitPlaceholderChange}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Per Unit Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              additionalData.calculationField.unitValueData
                                .unitAmount
                            }
                            onChange={handlePerUnitAmountChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {additionalData.unitType == 3 ? (
                    <div className="slab-based">
                      <h6 className="small-title">Slab Based</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              additionalData.calculationField.slabValueData
                                .labelText
                            }
                            onChange={handleSlabLabelChange}
                          />
                        </div>
                      </div>
                      {additionalData.calculationField.slabValueData.calculationSteps.map(
                        (step, index) => (
                          <div className="row" key={index}>
                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="From"
                                value={step.from}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "from",
                                    e.target.value
                                  )
                                }
                              />
                            </div>

                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="To"
                                value={step.to}
                                onChange={(e) =>
                                  handleSlabChange(index, "to", e.target.value)
                                }
                              />
                            </div>

                            <div className="form-group col">
                              <select
                                className="form-select"
                                value={step.unitType}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "unitType",
                                    e.target.value
                                  )
                                }
                              >
                                {/* <option value={0}>Select</option>
                                <option value={1}> Gram</option>
                                <option value={2}> KG</option> */}
                                <option value="">Select</option>
                                {unitOptions.map((unit) => (
                                  <option key={unit.id} value={unit.id}>
                                    {unit.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Price"
                                value={step.price}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            {index === 0 ? (
                              <div className="form-group col width-40">
                                <AddIcon
                                  className="btn-action-gray"
                                  onClick={handleAddSlab}
                                />
                              </div>
                            ) : (
                              <div className="form-group col width-40">
                                <CloseIcon
                                  className="btn-action-red"
                                  onClick={() => handleRemoveSlab(index)}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-red-bg"
                    onClick={handleSubmitAdditionalCharges}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* add additional-charges dialog box ends  */}

          {/* view additional charges dialog box starts */}
          <div
            className={`modal fade ${isAdditionalViewModalOpen ? "show" : ""}`}
            id="view-modal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            style={{
              display: isAdditionalViewModalOpen ? "block" : "none",
            }}
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                {viewDropMasterdata.length > 0 ? (
                  <div class="modal-body">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn-close ml-auto"
                        onClick={onCloseViewModal}
                      ></button>
                    </div>
                    {viewDropMasterdata[0].masterData.unitType === 1 && (
                      <div className="width-100">
                        <label className="label-ui">
                          {viewDropMasterdata[0]?.name}
                        </label>
                        <div className="row">
                          {viewDropMasterdata[0].masterData.minValue ? (
                            <div className="form-group col-sm-6">
                              <div class="input-group mr-sm-2 input-group-ui">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {viewDropMasterdata[0].masterData
                                      .minValueData.unitType == 1
                                      ? "%"
                                      : "$N"}
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder={`Minimum ${viewDropMasterdata[0].masterData.minValueData.label}`}
                                  value={
                                    viewDropMasterdata[0].masterData
                                      .minValueData.minValue
                                  }
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group col-sm-6">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  {renderPreviewOperator(viewDropMasterdata[0].masterData
                                    .calculationField.percentageValueData
                                    .unitType)}
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                disabled={
                                  viewDropMasterdata[0].masterData
                                    .calculationField.percentageValueData
                                    .isdependent
                                    ? true
                                    : false
                                }
                                placeholder={renderPercentageText(
                                  viewDropMasterdata[0].masterData
                                    .calculationField.percentageValueData
                                    .calculationSteps
                                )}
                                value={
                                  viewDropMasterdata[0].masterData
                                    .calculationField.percentageValueData.amount
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {viewDropMasterdata[0].masterData.unitType === 2 && (
                      <div className="width-100">
                        <label className="label-ui">
                          {viewDropMasterdata[0]?.name}
                        </label>
                        <div className="row">
                          {viewDropMasterdata[0].masterData.minValue ? (
                            <div className="form-group col-sm-6">
                              <div class="input-group mr-sm-2 input-group-ui">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">
                                    {viewDropMasterdata[0].masterData
                                      .minValueData.unitType == 1
                                      ? "%"
                                      : "$N"}
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder={`Minimum ${viewDropMasterdata[0].masterData.minValueData.label}`}
                                  value={
                                    viewDropMasterdata[0].masterData
                                      .minValueData.minValue
                                  }
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group col-sm-6">
                            <div class="input-group mr-sm-2 input-group-ui">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  {renderPreviewOperator(viewDropMasterdata[0].masterData
                                    .calculationField.unitValueData.unitType)}
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                placeholder={
                                  viewDropMasterdata[0].masterData
                                    .calculationField.unitValueData
                                    .placeholderText
                                }
                                value={
                                  viewDropMasterdata[0].masterData
                                    .calculationField.unitValueData.unitAmount
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {viewDropMasterdata[0].masterData.unitType === 3 && (
                      <div className="width-100">
                        <label className="label-ui">
                          {viewDropMasterdata[0]?.name}
                        </label>
                        {viewDropMasterdata[0].masterData.calculationField.slabValueData.calculationSteps.map(
                          (unit, i) => (
                            <React.Fragment key={i}>
                              <div className="row">
                                <div className="form-group col-sm-3">
                                  <div class="input-group mr-sm-2 input-group-ui">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">From</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={unit.from}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-sm-3">
                                  <div class="input-group mr-sm-2 input-group-ui">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">To</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={unit.to}
                                    />
                                  </div>
                                </div>

                                <div className="form-group col-sm-3">
                                  <select
                                    className="form-select"
                                    name="unitType"
                                    value={unit.unitType}
                                  >
                                    {/* <option value={0}>Select</option>
                                    <option value={1}>Gram</option>
                                    <option value={2}>KG</option> */}
                                    <option value="">Select</option>
                                    {unitOptions.map((unit) => (
                                      <option key={unit.id} value={unit.id}>
                                        {unit.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="form-group col-sm-3">
                                  <div class="input-group mr-sm-2 input-group-ui">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">₹</div>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={unit.price}
                                    />
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* view additional charges dialog box ends */}

          {/* Edit/Update additional charges dialog popup started */}
          <div
            id="additional-charges"
            className={`modal fade modal-ui-forms ${isEditAdditionalModalOpen ? "show" : ""
              }`}
            tabindex="-1"
            aria-labelledby="additional-chargesLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            style={{
              display: isEditAdditionalModalOpen ? "block" : "none",
            }}
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Edit Additional Fields</h5>
                  <button
                    type="button"
                    class="close"
                    onClick={onCloseEditModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="top-form">
                    <div class="form-check form-switch switch-ui">
                      <label class="form-check-label" for="min-value">
                        Min Value
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="min-value"
                        checked={additionalData.minValue}
                        value={additionalData.minValue}
                        onChange={handleMinValueChange}
                      />
                    </div>

                    <div class="inline-input">
                      <label className="label-ui mb-0 mr-3">Type</label>
                      <select
                        class="form-select"
                        value={additionalData.unitType}
                        onChange={handleUnitTypeChange}
                      >
                        <option value={0}>Select</option>
                        <option value={1}>Calculative/Fixed</option>
                        <option value={2}>Per Unit</option>
                        <option value={3}>Slab Based</option>
                      </select>
                    </div>
                  </div>
                  {additionalData.minValue ? (
                    <div className="min-value">
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            className="form-control"
                            name="label"
                            value={additionalData.minValueData.label}
                            onChange={handleMinValueDataChange}
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label className="label-ui">Unit type</label>
                          <select
                            className="form-select"
                            name="unitType"
                            value={additionalData.minValueData.unitType}
                            onChange={handleMinValueDataChange}
                          >
                            <option value={2} selected>
                              $N
                            </option>
                            {/* <option value={1}>%</option> */}
                          </select>
                        </div>

                        <div className="form-group col-sm-3">
                          <label className="label-ui">Min value</label>
                          <input
                            type="text"
                            className="form-control"
                            name="minValue"
                            value={additionalData.minValueData.minValue}
                            onChange={handleMinValueDataChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {additionalData.unitType == 1 ? (
                    <div className="percentage">
                      <h6 className="small-title">Percentage</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text" disabled
                            class="form-control"
                            onChange={handlepercentageValueDataLabelTextChange}
                            value={
                              additionalData.calculationField
                                .percentageValueData.labelText
                            }
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Unit type</label>
                          <select
                            class="form-select"
                            onChange={handlepercentageValueDataUnitTypeChange}
                            value={
                              additionalData.calculationField
                                .percentageValueData.unitType
                            }
                          >
                            {/* <option value={0}>Select</option>
                            <option value={1}> Gram</option>
                            <option value={2}> KG</option> */}
                            <option value="">Select</option>
                            {unitOptions.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            class="form-control"
                            onChange={
                              handlepercentageValueDataPlaceholderTextChange
                            }
                            value={
                              additionalData.calculationField
                                .percentageValueData.placeholderText
                            }
                          />
                        </div>

                        <div className="form-group col-sm-2">
                          <label className="label-ui">Has Dependancy</label>
                          <div class="form-check form-switch switch-ui">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={
                                additionalData.calculationField
                                  .percentageValueData.isdependent
                              }
                              value={
                                additionalData.calculationField
                                  .percentageValueData.isdependent
                              }
                              onChange={
                                handlepercentageValueDataHasDependancyChange
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {additionalData.calculationField.percentageValueData
                        .isdependent ? (
                        <div>
                          <div className="calculate-charges">
                            <div className="row">
                              <div className="form-group col-sm-4">
                                <label className="label-ui">
                                  Calculate charges
                                </label>
                                <div class="input-group mr-sm-2 input-group-ui">
                                  <input
                                    type="text"
                                    onChange={
                                      handlepercentageValueCalculationStepsLeftValueChange
                                    }
                                    class="form-control"
                                    value={lastStep.leftSideValue}
                                    disabled={lastStep.leftSideSelection !== ""}
                                  />
                                  <div class="input-group-prepend">
                                    <div class="input-group-text p-0 group-select">
                                      <select
                                        className="form-select"
                                        onChange={
                                          handlepercentageValueCalculationStepsLeftSelectionChange
                                        }
                                        value={lastStep.leftSideSelection}
                                        disabled={lastStep.leftSideValue !== ""}
                                      >
                                        <option value={""}>Select</option>
                                        {masterdata.map((add, index) => (
                                          <option value={add.id}>
                                            {add.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-sm-4">
                                <label className="label-ui">Operator</label>
                                <select
                                  className="form-select"
                                  onChange={
                                    handlepercentageValueCalculationStepsLeftOperatorChange
                                  }
                                  class="form-control"
                                  value={lastStep.operator}
                                >
                                  <option value={0}>Select</option>
                                  <option value={1}>Plus(+)</option>
                                  <option value={2}>Multiply(x)</option>
                                  <option value={3}>Percentage(%)</option>
                                  <option value={4}>Subtraction(-)</option>
                                  <option value={5}>Division(/)</option>
                                </select>
                              </div>

                              <div className="form-group col-sm-4">
                                <label className="label-ui">
                                  Calculate charges
                                </label>
                                <div class="input-group mr-sm-2 input-group-ui">
                                  <input
                                    type="text"
                                    class="form-control"
                                    onChange={
                                      handlepercentageValueCalculationStepsRightSideValueChange
                                    }
                                    value={lastStep.rightSideValue}
                                    disabled={
                                      lastStep.rightSideSelection !== ""
                                    }
                                  />
                                  <div class="input-group-prepend">
                                    <div class="input-group-text p-0 group-select">
                                      <select
                                        className="form-select"
                                        onChange={
                                          handlepercentageValueCalculationStepsRightSideSelectionChange
                                        }
                                        value={lastStep.rightSideSelection}
                                        disabled={
                                          lastStep.rightSideValue !== ""
                                        }
                                      >
                                        <option value={""}>Select</option>
                                        {masterdata.map((add, index) => (
                                          <option value={add.id}>
                                            {add.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex">
                              <button
                                type="button"
                                class="btn btn-red-bg small-btn ms-auto"
                                onClick={handleAddStep}
                              >
                                Add
                              </button>
                            </div>
                          </div>

                          <div className="calculate-preview">
                            <h5>Calculate Preview</h5>
                            <div className="d-flex align-items-center">
                              <span className="preview">
                                {renderPreviewText()}
                              </span>
                              <CloseRoundedIcon
                                className="remove-icon ms-2"
                                onClick={clearPercentageCalculation}
                              />
                              {/* <AddIcon className="plus-icon ms-2" /> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {additionalData.unitType == 2 ? (
                    <div className="per-unit">
                      <h6 className="small-title">Per Unit</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text"
                            class="form-control" disabled
                            value={
                              additionalData.calculationField.unitValueData
                                .labelText
                            }
                            onChange={handlePerUnitLabelChange}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Unit type</label>
                          <select
                            class="form-select"
                            value={
                              additionalData.calculationField.unitValueData
                                .unitType
                            }
                            onChange={handlePerUnitTypeChange}
                          >
                            {/* <option value={0}>Select</option>
                            <option value={1}> Gram</option>
                            <option value={2}> KG</option> */}
                            <option value="">Select</option>
                            {unitOptions.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Placeholder text</label>
                          <input
                            type="text"
                            class="form-control"
                            value={
                              additionalData.calculationField.unitValueData
                                .placeholderText
                            }
                            onChange={handlePerUnitPlaceholderChange}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="label-ui">Per Unit Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              additionalData.calculationField.unitValueData
                                .unitAmount
                            }
                            onChange={handlePerUnitAmountChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {additionalData.unitType == 3 ? (
                    <div className="slab-based">
                      <h6 className="small-title">Slab Based</h6>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label className="label-ui">Label text</label>
                          <input
                            type="text"
                            className="form-control" disabled
                            value={
                              additionalData.calculationField.slabValueData
                                .labelText
                            }
                            onChange={handleSlabLabelChange}
                          />
                        </div>
                      </div>
                      {additionalData.calculationField.slabValueData.calculationSteps.map(
                        (step, index) => (
                          <div className="row" key={index}>
                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="From"
                                value={step.from}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "from",
                                    e.target.value
                                  )
                                }
                              />
                            </div>

                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="To"
                                value={step.to}
                                onChange={(e) =>
                                  handleSlabChange(index, "to", e.target.value)
                                }
                              />
                            </div>

                            <div className="form-group col">
                              <select
                                className="form-select"
                                value={step.unitType}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "unitType",
                                    e.target.value
                                  )
                                }
                              >
                                {/* <option value={0}>Select</option>
                                <option value={1}> Gram</option>
                                <option value={2}> KG</option> */}
                                <option value="">Select</option>
                                {unitOptions.map((unit) => (
                                  <option key={unit.id} value={unit.id}>
                                    {unit.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Price"
                                value={step.price}
                                onChange={(e) =>
                                  handleSlabChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            {index === 0 ? (
                              <div className="form-group col width-40">
                                <AddIcon
                                  className="btn-action-gray"
                                  onClick={handleAddSlab}
                                />
                              </div>
                            ) : (
                              <div className="form-group col width-40">
                                <CloseIcon
                                  className="btn-action-red"
                                  onClick={() => handleRemoveSlab(index)}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-red-bg"
                    onClick={handleSubmitEditAdditionalCharges}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Edit/Update additional charges dialog popup ends */}

          {/* Operator dialog popup starts */}
          <div
            className={`modal ${isModalOpen ? "show" : ""}`}
            tabIndex="-1"
            role="dialog"
            id="OperatorSelect"
            style={{
              display: isModalOpen ? "block" : "none",
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-sm"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title">
                    Choose Operator to Add Next Statement
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div class="mb-3">
                    <label class="label-ui">Operator</label>

                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={
                        handlepercentageValueCalculationStepspreviousOperatorChange
                      }
                      value={lastStep.previousOperator}
                    >
                      <option value={""}>Select</option>
                      <option value={0}>Select</option>
                      <option value={1}>Plus(+)</option>
                      <option value={2}>Multiply(x)</option>
                      <option value={3}>Percentage(%)</option>
                      <option value={4}>Subtraction(-)</option>
                      <option value={5}>Division(/)</option>
                    </select>
                  </div>
                  <div class="d-flex">
                    <button
                      type="button"
                      class="btn btn-red-bg small-btn ms-auto"
                      onClick={handleSubmitAddStep}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Operator dialog popup ends */}
        </div>

        <ToastContainer />
        <Dialog open={openFloorDialog} onClose={handleCloseFloorDialog}>
          <DialogContent>
            <h5 className="small-title">Add Floor</h5>
            <div className="row mt-3 align-items-center">
              <div className="col-sm-9 mb-4">
                <TextField
                  label="Floor Name"
                  name="floorName"
                  fullWidth
                  value={floorName}
                  onChange={(e) => setfloorName(e.target.value)}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <Button variant="contained" onClick={handleAddFloors}>Add</Button>
              </div>
            </div>
            {rowsFloorDialog.map((row, index) => (
              <>
                <h5 className="small-title">Add Floor Charges</h5>
                <div className="row mt-3 align-items-center" key={index}>
                  <div className="form-group col">
                    <TextField
                      select
                      label="Floor"
                      name="floor"
                      value={row.floor}
                      onChange={(event) => handleInputChangeFloorDialog(index, event)}
                      fullWidth
                    >
                      {floorCount.map((floor) => (
                        <MenuItem key={floor.floorId} value={floor.floorId}>
                          {floor.floor === 0 ? 'Ground' : floor.floor}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="form-group col">
                    <TextField
                      label="Price"
                      name="price"
                      value={row.price}
                      onChange={(event) => handleInputChangeFloorDialog(index, event)}
                      fullWidth
                    />
                  </div>
                  {/* <div className="form-group col width-40">
                    {index === 0 ? (
                      <AddIcon className="btn-action-gray" onClick={handleAddRowFloorDialog} />
                    ) : (
                      <CloseIcon className="btn-action-red" onClick={() => handleRemoveRowFloorDialog(index)}
                      />
                    )}
                  </div> */}
                  <div className="form-group col width-40">
                    {index === rowsFloorDialog.length - 1 ? (
                      <AddIcon className="btn-action-gray" onClick={handleAddRowFloorDialog} />
                    ) : (
                      <CloseIcon className="btn-action-red" onClick={() => handleRemoveRowFloorDialog(index)} />
                    )}
                  </div>
                </div>
              </>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFloorDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleSubmitFloorDialog}>Submit</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default ExcelUpload;
