import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Details from "./Details";
import Footer from "./Footer";
const UserDetails = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Details />
      <Footer />
    </>
  );
};

export default UserDetails;
