import * as React from "react";
import Routing from "./Components/Routing";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
 