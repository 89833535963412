import React from 'react';
import './BlogsPage.css'; 

const Blogs = (prop) => {
  const blogs = [
    {
      id: 1,
      title: "How to Optimize Last-Mile Delivery Routes for Efficiency",
      content: "Last-mile delivery is one of the most crucial aspects of logistics for any courier company. In this blog post, we explore strategies and best practices for optimizing last-mile delivery routes to improve efficiency and reduce costs.",
      author: "Retansh Gautam",
      date: "February 13, 2024",
      imageUrl: "02.jpg"
    },
    {
      id: 2,
      title: "The Impact of Technology on Modern Logistics Operations",
      content: "Technology plays a significant role in shaping the modern logistics industry. From route optimization algorithms to real-time tracking systems, this blog post delves into how technology is revolutionizing logistics operations and enhancing customer experiences.",
      author: "Chirag Mudgil",
      imageUrl: "01.jpg"
    },
    {
      id: 3,
      title: "Sustainable Logistics: Reducing Environmental Footprint in Courier Services",
      content: "As environmental concerns continue to grow, courier companies are under pressure to adopt sustainable practices. This blog post discusses innovative approaches to reducing the environmental footprint of logistics operations and promoting eco-friendly courier services.",
      author: "Deepak Verma",
      imageUrl: "skill.png"
    }
  ];

  return (
    <div className='blogs-container' id={prop.sectionId}>
      <h6 className='blogs-heading'>Blogs</h6>
      <div className='blog-tagline'>Our latest blogs</div>
      <div className='blog-cards'>
        {blogs.map(blog => (
          <div key={blog.id} className='blog-card'>
            <img src={`/images/${blog.imageUrl}`} alt={blog.title} className='blog-image' />
            <div>
              <h6 className='blog-title'>{blog.title}</h6>
              <p className='blog-content'>{blog.content}</p>
              <div className='blog-meta'>
                <p className='blog-author'>By {blog.author}</p>
                <p className='blog-date'>{blog.date}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
