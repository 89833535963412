import React, { useState } from 'react';
import './ApiDoc.css';
import apiEndpoints from './ApiEndpoints.js';

const ApiDoc = () => {
    const [openAccordion, setOpenAccordion] = useState(null);

    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };



    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="main-title">API Documentation</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <span>Home</span>
                                </li>
                                <li className="breadcrumb-item active">API Documentation</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="api-doc-content">
                <h2
                 className="h2-size"
                 >API Endpoints</h2>
                {apiEndpoints.map((endpoint, index) => (
                    <div key={index} className={`endpoint ${endpoint.method.toLowerCase()}-bg`}>
                        <h3 onClick={() => toggleAccordion(index)}>
                            <span className={`btn-span ${endpoint.method.toLowerCase()}`}>{endpoint.method}</span>
                            {endpoint.title}
                            <i className={`fas ${openAccordion === index ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                        </h3>
                        {openAccordion === index && (
                            <div className="accordion-content">
                                <p><strong>Description:</strong> {endpoint.description}</p>
                                <div className="request">
                                    <h4>Request</h4>
                                    <pre>
                                        <code>{endpoint.request}</code>
                                    </pre>
                                </div>
                                <div className="response">
                                    <h4>Response</h4>
                                    <pre>
                                        <code>{endpoint.response}</code>
                                    </pre>
                                </div>
                                <div className="status-codes">
                                    <h4>Status Codes</h4>
                                    <ul>
                                        {endpoint.statusCodes.map((status, idx) => (
                                            <li key={idx}><strong>{status.code}:</strong> {status.message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ApiDoc;
