import React from "react";
import "./My.css";

const SupSec = (prop) => {
  return (
    <>
      <div className="mainSup">
        <div className="d-flex align-items-center">
          {" "}
          <i className={prop.icon} />
          <h4>
            <span>{prop.heading}</span>
          </h4>
        </div>
        <div className="SubSup">
            <p>{prop.text1}</p>
            <p>{prop.text2}</p>
        </div>
      </div>
    </>
  );
};

export default SupSec;
