import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  GetAssignRolesdetails,
  AssignAdminRole,
  AdminUserRole,
  GetAdminRolesList,
  ActivateandDeactivateAdminRoles,
  GetAdminRole,
} from "../../Services/AdminApi";
import { toast, ToastContainer } from "react-toastify";
export default function Rolespage(props) {
  const [open, setOpen] = useState(false);
  const [assignrolesopen, setOpenRole] = useState(false);
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [rolesdetails, setrolesdetails] = useState([]);
  const [roleslist, setroleslist] = useState([]);
  const [assignadmin, setassignadmin] = useState({
    email: "",
    role: "",
  });
  const [adminrolesdropdown, setadminrolesdropdown] = useState([]);

  const [selectedRoleId, setSelectedRoleId] = useState(null); // State to store selected roleid

  const columnsdata = [
    { field: "sn", headerName: "No.", width: 50 },
    { field: "name", headerName: "User Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "mobile", headerName: "Mobile No.", width: 120 },
    {
      field: "assignedRole",
      headerName: "Assigned Roles",
      width: 150,
      // renderCell: (params) => {
      //   return <Chip label="assignedRole" />;
      // },
    },
    {
      field: "Active/Deactivate",
      headerName: "Active",
      sortable: false,
      renderCell: (params) => {
        const isActive = params.row.isActive;
        return (
          <Switch
            checked={isActive}
            onChange={() => handleSwitchToggle(params.row.userId, !isActive)}
          />
        );
      },
    },
    // {
    //   field: "Action",
    //   headerName: "Edit",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton aria-label="edit" onClick={assignrolesClickOpen}>
    //         <DriveFileRenameOutlineRoundedIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  const assignrolesClickOpen = () => {
    setOpenRole(true);
    handlerolecreation();
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };

  useEffect(() => {
    loadroledetails();

    GetAdminRolesList()
      .then((result) => {
        setadminrolesdropdown(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const loadroledetails = () => {
    setrolesdetails([]);
    setroleslist([]);

    GetAssignRolesdetails()
      .then((result) => {
        const sortedUserLists = result.data.userLists.sort(
          (a, b) => b.userId - a.userId
        );
        const updatedUserLists = sortedUserLists.map((user, index) => ({
          ...user,
          sn: index + 1,
        }));
        setrolesdetails(result.data.roles);
        setroleslist(updatedUserLists);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleButtonClick = () => {
    AssignAdminRole(assignadmin)
      .then((result) => {
        if (result.status === 200) {
          toast.success("Changes saved", { autoClose: 2000 });
          loadroledetails();
          setassignadmin({ email: '', role: '' });
        } else if (result.code === 400) {
          toast.error("Error activating/deactivating user ", { autoClose: 2000 });
          setassignadmin({ email: '', role: '' });
        } else {
          setassignadmin({ email: '', role: '' });
        }
      })
      .catch((r) => {
        console.log("assign admin api error", r);
      });
  };

  const handleEmailChange = (event) => {
    setassignadmin({ ...assignadmin, email: event.target.value });
  };

  const handleRoleChange = (event) => {
    setassignadmin({ ...assignadmin, role: event.target.value });
  };

  const handleSwitchToggle = (userId, isActive) => {
    const requestBody = {
      userid: userId,
      isActivate: isActive,
    };
    ActivateandDeactivateAdminRoles(requestBody)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Changes saved", { autoClose: 2000 });
          loadroledetails();
        } else if (response.code === 400) {
          toast.error("Error activating/deactivating user ", { autoClose: 2000 });
        } else {
        }

      })
      .catch((error) => {
        console.error("Error activating/deactivating user:", error);
      });
  };



  const [roleaccessdata, setroleaccessdata] = useState({});
  const defaultPermission = {
    roleid: 0,
    roleName: "",
    dashboardView: true,
    totalNoOfCustomer: true,
    totalOutstandingPayments: true,
    totalNoOfVendors: true,
    totalNoOfOrders: true,
    totalOrderValue: true,
    totalCustomerInvoices: true,
    totalVendorInvoices: true,
    lineChart: true,
    pieChart: true,
    customersList: true,
    userAccountActiveDeactive: true,
    userAccountAction: true,
    vendorList: true,
    vendorAccountActiveDeactive: true,
    vendorAccountAction: true,
    orderReport: true,
    purchaseOrderReport: true,
    salesOrderReport: true,
    salesReportStatus: true,
    salesReportViewInvoice: true,
    invoice: true,
    purchaseInvoice: true,
    salesInvoice: true,
    viewUploadedBulkOrders: true,
    downloadBulkOrders: true,
    uploadBulkOrders: true,
    smsProvidersView: true,
    smsProviderActiveDeactive: true,
    reviewLists: true,
    approveReview: true,
    deleteReview: true,
    notifications: true,
    onboardingNotification: true,
    newOrderPlacedCustomerNotification: true,
    newOrderPlacedVendorNotification: true,
    orderStatusNotification: true,
    outstandingReminderNotification: true,
    paymentConfirmationNotification: true,
    adminHandlerView: false,
    createRole: false,
    assignRoleView: false,
    assignRoleAssign: false,
    roleAssignedView: false,
    createNewRoleAdmin: false,
    updateCreatedRoles: false,
    assignedRoleView: false,
    assignedRoleToAdmin: false,
    changeRoleToAdmin: false,
    activeAssignedRole: false,
    createAdminUser: false,
    addAdminUser: false,
    viewAdminUser: false,
    updateAdminUserDetails: false,
    activeAdminUser: false,
    viewScannerUser: false,
    approveScannerUser: false,
    approveScannerUserItems: false,
    downloadBarcode: false,
  };
  const [newuserrolecreation, setnewuserrolecreation] = useState(defaultPermission);

  const rolesClickOpen = (roleid) => {
    setSelectedRoleId(roleid);
    setOpen(true);
    GetAdminRole(roleid).then((response) => {
      // console.log("get permission", response.data);
      var tmp = {
        ...response.data,
        roleid: response.data.roleid,
        roleName: response.data.roleName,
      };
      setnewuserrolecreation(tmp);
      setroleaccessdata(response.data);
      //console.log("my roleaccess", roleaccessdata);
      //console.log("my rolename",tmp.roleName );
    });
  };

  useEffect(() => {
  }, [roleaccessdata]);


  const rolesClose = () => {
    setOpen(false);
  };
  //const [loading, setLoading] = useState(true);

  // {loading ? ( 
  //   <div className="loading-container">
  //   <div className="loading-spinner"></div>
  //   <p>Loading...</p>
  // </div>
  // ) : (null

  const handleadminrolepermissions = (event, key) => {
    const updatedNewUserRoleCreation = { ...newuserrolecreation };
    updatedNewUserRoleCreation[key] = event.target.checked;
    setnewuserrolecreation(updatedNewUserRoleCreation);
  };

  const handleAdminRoleSubmit = () => {
    try {
      AdminUserRole(newuserrolecreation)
        .then((response) => {
          const data = response.json();
          //console.log("data", data);
          if (response.status == 200) {
            rolesClose()
            toast.success("Permissions has been updated successfully");
            // setLoading(true);
            loadroledetails();
          } else {
            toast.error("Error updating user role permissions");
          }
        })
        .catch((error) => {
          console.error("Error updating user role permissions", error);
        });
    } catch (error) {
      console.error("Error serializing object to JSON", error);
    }
  };

  const handlerolecreation = () => {
    setroleaccessdata({});
    setnewuserrolecreation(defaultPermission);
    setOpen(true);
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Assign Role</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Assign Role</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {rolesdetails.map((item, roleid) => (
              <div className="col-lg-3 mb-3" key={roleid}>
                <Card className="sms-box roles-box">
                  <CardContent className="roles-content">
                    <h2>{item.userCount}</h2>
                  </CardContent>
                  <CardActions className="card-footer">
                    <span>{item.role}</span>
                    <IconButton
                      className="ml-auto"
                      aria-label="edit"
                      onClick={() => rolesClickOpen(item.roleid)}
                    >
                      <AppRegistrationRoundedIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </div>
            ))}
            <div className="col-lg-3 mb-3">
              <Card className="sms-box add-box">
                <div className="add" onClick={handlerolecreation}>
                  <IconButton aria-label="add">
                    <AddRoundedIcon />
                  </IconButton>
                  <p>Create New Role</p>
                </div>
              </Card>
            </div>
          </div>

          <div>
            <h2 className="main-title">Update Role</h2>
          </div>

          <Card sx={{ p: "1rem" }} className="custum-shadow search-box mt-2">
            <div className="col-sm-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Email"
                value={assignadmin.email}
                onChange={handleEmailChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-4">
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  label="Role"
                  value={assignadmin.role}
                  onChange={handleRoleChange}
                >
                  <MenuItem disabled value="">
                    Select role
                  </MenuItem>
                  {adminrolesdropdown.map((role) => (
                    <MenuItem key={role.roleid} value={role.roleid}>
                      {role.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-sm-4">
              <Button
                onClick={handleButtonClick}
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
            <DataGrid
              sx={{ p: "1rem" }}
              className="table-ui"
              rows={roleslist}
              columns={columnsdata}
              getRowId={(row) => row.id ?? row.userId}
              components={{}}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

      {/* Add New Roles */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={rolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Account Permission"}
        </DialogTitle>
        <DialogContent>
          {/* <TextField
            className="mt-4"
            id="outlined-textarea"
            label="Rolsfsde Name"
            // defaultValue={newuserrolecreation.roleName}
            defaultValue={roleaccessdata.roleName}
            onChange= {(event) => {
              debugger;
              var tmp = newuserrolecreation;
              tmp['roleName']=event.target.value;
              setnewuserrolecreation(tmp);
              // handleadminrolepermissions(event, "roleName"),
            }}
            placeholder="Role Name"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          <TextField
            className="mt-4"
            id="outlined-textarea"
            label="Role Name"
            value={newuserrolecreation.roleName}
            onChange={(event) => {
              //  debugger;
              const newValue = event.target.value;
              setnewuserrolecreation((prevState) => ({
                ...prevState,
                roleName: newValue,
              }));
            }}
            placeholder="Role Name"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="mt-3">
            <table className="table table-roles">
              <thead>
                <tr>
                  <th>01. Dashboard</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "dashboardView")
                          }
                          // checked={roleaccessdata?.dashboardView == true}
                          checked={newuserrolecreation.dashboardView}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total No. Of Customer</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "totalNoOfCustomer"
                            )
                          }
                          // checked={roleaccessdata?.totalNoOfCustomer || false}
                          checked={newuserrolecreation.totalNoOfCustomer}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total Outstanding Payments</td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "totalOutstandingPayments"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.totalOutstandingPayments || false
                          // }
                          checked={newuserrolecreation.totalOutstandingPayments}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total No. Of Vendors</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "totalNoOfVendors"
                            )
                          }
                          // checked={roleaccessdata?.totalNoOfVendors || false}
                          checked={newuserrolecreation.totalNoOfVendors}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total No. Of Orders</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "totalNoOfOrders")
                          }
                          // checked={roleaccessdata?.totalNoOfOrders || false}
                          checked={newuserrolecreation.totalNoOfOrders}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total Order Value</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "totalOrderValue")
                          }
                          // checked={roleaccessdata?.totalOrderValue || false}
                          checked={newuserrolecreation.totalOrderValue}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total Customer Invoices</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "totalCustomerInvoices"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.totalCustomerInvoices || false
                          // }
                          checked={newuserrolecreation.totalCustomerInvoices}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total Vendor Invoices</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "totalVendorInvoices"
                            )
                          }
                          // checked={roleaccessdata?.totalVendorInvoices || false}
                          checked={newuserrolecreation.totalVendorInvoices}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Line Chart</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "lineChart")
                          }
                          // checked={roleaccessdata?.lineChart || false}
                          checked={newuserrolecreation.lineChart}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pie Chart</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(event) =>
                            handleadminrolepermissions(event, "pieChart")
                          }
                          size="small"
                          // checked={roleaccessdata?.pieChart || false}
                          checked={newuserrolecreation.pieChart}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>02. Customers List</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "customersList")
                          }
                          // checked={roleaccessdata?.customersList || false}
                          checked={newuserrolecreation.customersList}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>User Account Active/Deactive</td>
                  <td colSpan="2">
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "userAccountActiveDeactive"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.userAccountActiveDeactive || false
                          // }
                          checked={
                            newuserrolecreation.userAccountActiveDeactive
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>User Account Action</td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "userAccountAction"
                            )
                          }
                          // checked={roleaccessdata?.userAccountAction || false}
                          checked={newuserrolecreation.userAccountAction}
                        />
                      }
                      label="Edit"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          // checked={roleaccessdata?.userAccountAction || false}
                          checked={newuserrolecreation.userAccountAction}
                        />
                      }
                      label="Delete"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>03. Vendor List</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "vendorList")
                          }
                          // checked={roleaccessdata?.vendorList || false}
                          checked={newuserrolecreation.vendorList}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>User Account Active/Deactive</td>
                  <td colSpan="2">
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "vendorAccountActiveDeactive"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.vendorAccountActiveDeactive || false
                          // }
                          checked={
                            newuserrolecreation.vendorAccountActiveDeactive
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>User Account Action</td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "vendorAccountAction"
                            )
                          }
                          // checked={roleaccessdata?.vendorAccountAction || false}
                          checked={newuserrolecreation.vendorAccountAction}
                        />
                      }
                      label="Edit"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          // checked={roleaccessdata?.vendorAccountAction || false}
                          checked={newuserrolecreation.vendorAccountAction}
                        />
                      }
                      label="Delete"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>04. Order Report</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "orderReport")
                          }
                          // checked={roleaccessdata?.orderReport || false}
                          checked={newuserrolecreation.orderReport}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Purchase</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "purchaseOrderReport"
                            )
                          }
                          // checked={roleaccessdata?.purchaseOrderReport || false}
                          checked={newuserrolecreation.purchaseOrderReport}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sales</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "salesOrderReport"
                            )
                          }
                          // checked={roleaccessdata?.totalNoOfCustomer || false}
                          checked={newuserrolecreation.totalNoOfCustomer}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>

                <tr>
                  <td>Sales Report Table</td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "salesReportStatus"
                            )
                          }
                          // checked={roleaccessdata?.salesOrderReport || false}
                          checked={newuserrolecreation.salesOrderReport}
                        />
                      }
                      label="Status"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          // checked={roleaccessdata?.salesReportStatus || false}
                          checked={newuserrolecreation.salesReportStatus}
                        />
                      }
                      label="View Invoice"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>05. Invoice</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "invoice")
                          }
                          // checked={roleaccessdata?.invoice || false}
                          checked={newuserrolecreation.invoice}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Purchase Invoice</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "purchaseInvoice")
                          }
                          // checked={roleaccessdata?.purchaseInvoice || false}
                          checked={newuserrolecreation.purchaseInvoice}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sales Invoice</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "salesInvoice")
                          }
                          // checked={roleaccessdata?.salesInvoice || false}
                          checked={newuserrolecreation.salesInvoice}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>06. Upload Bulk Orders</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "uploadBulkOrders"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.uploadBulkOrders || false
                          // }
                          checked={newuserrolecreation.uploadBulkOrders}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Download</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "downloadBulkOrders"
                            )
                          }
                          // checked={roleaccessdata?.downloadBulkOrders || false}
                          checked={newuserrolecreation.downloadBulkOrders}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Upload</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "uploadBulkOrders"
                            )
                          }
                          // checked={roleaccessdata?.uploadBulkOrders || false}
                          checked={newuserrolecreation.uploadBulkOrders}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>07. SMS Provider</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "smsProvidersView"
                            )
                          }
                          // checked={roleaccessdata?.smsProvidersView || false}
                          checked={newuserrolecreation.smsProvidersView}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Active / Deactive</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "smsProviderActiveDeactive"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.smsProviderActiveDeactive || false
                          // }
                          checked={
                            newuserrolecreation.smsProviderActiveDeactive
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>08. Review Lists</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "reviewLists")
                          }
                          // checked={roleaccessdata?.reviewLists || false}
                          checked={newuserrolecreation.reviewLists}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Approve Review</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "approveReview")
                          }
                          // checked={roleaccessdata?.approveReview || false}
                          checked={newuserrolecreation.approveReview}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Delete</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "deleteReview")
                          }
                          // checked={roleaccessdata?.deleteReview || false}
                          checked={newuserrolecreation.deleteReview}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-roles">
              <thead>
                <tr>
                  <th>09. Notifications</th>
                  <th colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(event, "notifications")
                          }
                          // checked={roleaccessdata?.notifications || false}
                          checked={newuserrolecreation.notifications}
                        />
                      }
                      label=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Onboarding</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "onboardingNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.onboardingNotification || false
                          // }
                          checked={newuserrolecreation.onboardingNotification}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>New Order Placed Customer</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "newOrderPlacedCustomerNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.newOrderPlacedCustomerNotification ||
                          //   false
                          // }
                          checked={
                            newuserrolecreation.newOrderPlacedCustomerNotification
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>New Order Placed Vendor</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "newOrderPlacedVendorNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.newOrderPlacedVendorNotification ||
                          //   false
                          // }
                          checked={
                            newuserrolecreation.newOrderPlacedVendorNotification
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "orderStatusNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.orderStatusNotification || false
                          // }
                          checked={newuserrolecreation.orderStatusNotification}
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Outstanding Reminder</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "outstandingReminderNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.outstandingReminderNotification ||
                          //   false
                          // }
                          checked={
                            newuserrolecreation.outstandingReminderNotification
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>Payment Confirmation</td>
                  <td colSpan={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={(event) =>
                            handleadminrolepermissions(
                              event,
                              "paymentConfirmationNotification"
                            )
                          }
                          // checked={
                          //   roleaccessdata?.paymentConfirmationNotification ||
                          //   false
                          // }
                          checked={
                            newuserrolecreation.paymentConfirmationNotification
                          }
                        />
                      }
                      label=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={rolesClose}>Close</Button>
          <Button onClick={handleAdminRoleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {/* Add New Roles */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Role"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-3">
            <InputLabel id="demo-simple-select-label">
              Assigned Roles
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Assigned Roles"
              value={10}
            >
              <MenuItem disabled>Select role</MenuItem>
              {adminrolesdropdown.map((role) => (
                <MenuItem key={role.roleid} value={role.roleid}>
                  {role.role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={assignrolesClose}>Close</Button>
          <Button onClick={assignrolesClose} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
