import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { GetInvoice} from "../Services/AdminApi";
import { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./pdf.css";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />

      {/* <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Export
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Download CSV</MenuItem>
          <MenuItem onClick={handleClose}>Download PDF</MenuItem>
        </Menu>
      </div> */}
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "invoiceNo", headerName: "Invoices No.", flex: 1 },
  { field: "customer_Name", headerName: "Customer name ", flex: 1 },
  { field: "issueDate", headerName: "Issue Date", flex: 1, type: "date" },
  { field: "vendor_Name", headerName: "Payment To", width: "300" },
  { field: "total_Amount",headerName: "Total Amount",flex: 1,  },
  { field: "status",headerName: "Status",flex: 1, renderCell: (params) => {
    return <Chip label="Paid" color="success" size="small" />;   },
  },
  {
    field: "download",
    headerName: "Get Invoice",
    flex: 1,
    renderCell: (params) => {
      return (
        <IconButton  onClick={() => Pdfhandle(params)} aria-label="delete">
          {" "}
          <SimCardDownloadIcon />
        </IconButton>
      );
    },
  },
];

function Pdfhandle(data) {
  
  const [downloaddata, setDownloaddata] = useState("");
  setDownloaddata(data.row);


    window.html2canvas = html2canvas;
    var doc = new jsPDF("p", "pt", "a4");
    var content = document.getElementById("pdf-content");
    document.getElementById("pdf-content").style.display = "block";
    doc.html(content, {
      callback: function (doc) {
        doc.save();
        document.getElementById("pdf-content").style.display = "none";
      },
    });
  }

export default function InvoicesList() {

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("");
  
  useEffect(() => {
    loadInvoice();
  }, []);

  const loadInvoice = () => {
    setData([]);

    var params = "";
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (type != null && type != undefined && type != "") {
      params += `type=${type}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetInvoice(params)
      .then((result) => {
        setData(result.data); })
 

  };

  return (
    <div classNameName="content-wrapper" id="content-wrapper">
      <div classNameName="content-header">
        <div classNameName="container-fluid">
          <div classNameName="row mb-2">
            <div classNameName="col-sm-6">
              <h1 classNameName="main-title">Invoices</h1>
            </div>
            <div classNameName="col-sm-6">
              <ol classNameName="breadcrumb float-sm-right">
                <li classNameName="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li classNameName="breadcrumb-item active">Invoices</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section classNameName="content">
        <div classNameName="container-fluid">
          <Card sx={{ p: "1rem" }} classNameName="custum-shadow search-box">
            <div classNameName="col">
              <TextField
                required
                fullWidth
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-required"
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div classNameName="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select labelId="Status" defaultValue={"all"} id="Status" fullWidth label="Status">
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"Disputed"}>Disputed</MenuItem>
                  <MenuItem value={"Paid"}>Paid</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div classNameName="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div classNameName="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div classNameName="">
              <IconButton
                size="small"
                classNameName="search-icon"
                onClick={() => {
                  loadInvoice();
                }}
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>
          <Card classNameName="custum-shadow mt-3">
            <div style={{ height: "80vh", width: "100%" }}>
              <DataGrid
                // sx={{ p: "1rem" }}
                // disableDensitySelector
                // componentsProps={{
                //   toolbar: {
                //     printOptions: {},
                //   },
                // }}
                sx={{
                  p: "1rem",
                  "@media print": {
                    // '.MuiDataGrid-cell:nth-child(6)':{display:'none'},
                    // '.MuiDataGrid-columnHeaderTitle:nth-child(6)':{display:'none'},
                    ".table-ui": { border: "none" },
                  },
                }}
                classNameName="table-ui"
                getRowId={(row) => row.id ?? row.invoiceNo}
                rows={data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </Card>
        </div>
      </section>
      
      <div id="pdf-content" style={{ display: 'none'}}>

        <div className="c33 doc-content">
          <div>
            <p className="c13">
              <span className="c31">
                INVOICE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </p>
          </div>
          <p className="c18 c11">
            <span className="c5"></span>
          </p>
          <p className="c11 c18">
            <span className="c5"></span>
          </p>
          <p className="c18">
            <span className="c5">Your name/business name</span>
          </p>
          <p className="c18">
            <span className="c5">Your address</span>
          </p>
          <p className="c18">
            <span className="c5">Your phone number</span>
          </p>
          <p className="c18">
            <span className="c5">Your email</span>
          </p>
          <p className="c18 c11">
            <span className="c5"></span>
          </p>
          <a id="t.7908554cf67ad0de3c4847bbd2d4dcbfd8c58a36"></a>
          <a id="t.0"></a>
          <table className="c23">
            <tr className="c12">
              <td className="c32" colspan="1" rowspan="1">
                <p className="c14">
                  <span className="c3">Bill to:</span>
                </p>
                <p className="c14">
                  <span className="c35">Buyer</span>
                  <span className="c5">&nbsp;name/business name</span>
                </p>
                <p className="c14">
                  <span className="c5">Buyer address</span>
                </p>
                <p className="c14">
                  <span className="c5">Buyer phone number</span>
                </p>
                <p className="c13 c29">
                  <span className="c5">Buyer email</span>
                </p>
              </td>
              <td className="c32" colspan="1" rowspan="1">
                <p className="c17">
                  <span className="c36">Invoice number:</span>
                  <span className="c5">&nbsp; #</span>
                </p>
                <p className="c17">
                  <span className="c36">Invoice date:</span>
                  <span className="c5">&nbsp;MM/DD/YYYY</span>
                </p>
                <p className="c17">
                  <span className="c36">Payment due:</span>
                  <span className="c5">&nbsp;MM/DD/YYYY</span>
                </p>
              </td>
            </tr>
          </table>
          <p className="c18 c11">
            <span className="c5"></span>
          </p>
          <a id="t.223a95dd57751c627306fcf12815c3c801296966"></a>
          <a id="t.1"></a>
          <table className="c23">
            <tr className="c12">
              <td className="c20 c19" colspan="1" rowspan="1">
                <p className="c13">
                  <span className="c3">Item </span>
                </p>
              </td>
              <td className="c22 c19" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c3">Quantity</span>
                </p>
              </td>
              <td className="c2 c19" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c3">Price per unit</span>
                </p>
              </td>
              <td className="c19 c21" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c3">Amount</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c20" colspan="1" rowspan="1">
                <p className="c13">
                  <span className="c5">Item 1</span>
                </p>
              </td>
              <td className="c22" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">#</span>
                </p>
              </td>
              <td className="c2" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
              <td className="c21" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c20" colspan="1" rowspan="1">
                <p className="c13">
                  <span className="c5">Item 2</span>
                </p>
              </td>
              <td className="c22" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">#</span>
                </p>
              </td>
              <td className="c2" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
              <td className="c21" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c12">
              <td className="c20" colspan="1" rowspan="1">
                <p className="c13">
                  <span className="c5">Item 3</span>
                </p>
              </td>
              <td className="c22" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">#</span>
                </p>
              </td>
              <td className="c2" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
              <td className="c21" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c27">
              <td className="c26" colspan="2" rowspan="4">
                <p className="c0">
                  <span className="c5"></span>
                </p>
              </td>
              <td className="c28" colspan="1" rowspan="1">
                <p className="c16">
                  <span className="c3">Subtotal</span>
                </p>
              </td>
              <td className="c37" colspan="1" rowspan="1">
                <p className="c16">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c27">
              <td className="c10" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c3">Tax 0.00%</span>
                </p>
              </td>
              <td className="c6" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c27">
              <td className="c10" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c3">Fees/discounts</span>
                </p>
              </td>
              <td className="c6" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c5">$0.00</span>
                </p>
              </td>
            </tr>
            <tr className="c27">
              <td className="c10 c19" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c9">TOTAL</span>
                </p>
              </td>
              <td className="c6 c19" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c9">$0.00</span>
                </p>
              </td>
            </tr>
          </table>
          <p className="c18 c11">
            <span className="c5"></span>
          </p>
          <h3 className="c30" id="h.m20qujar28oy">
            <span className="c24">Terms and conditions</span>
          </h3>
          <p className="c25">
            <span className="c34">Terms and conditions go here</span>
          </p>
        </div>

      </div>
    </div>
  );
}
