import React, { useState, useEffect } from "react";
import {
  GenerateCurrentUserlogin,
  UserAccount,
  VerifyEmail,
} from "../Services/UserApi";
import VerifiedIcon from "@mui/icons-material/Verified";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";

const MyAccount = () => {
  const inputHide = (e) => {
    setState({ [e.target.id]: true });
    setmessage("");
  };
  
  const token = localStorage.getItem("Logkey");
  const user_id= localStorage.getItem("user_id")
  useEffect(() => {
   // debugger;
    GenerateCurrentUserlogin(token,user_id).then((res) => {
      setUserData(res);
      setLoading(false);
    });
  }, []);

  const [userdata, setUserData] = useState({
    account_no: "",
    bank_name: "",
    benifesory_name: "",
    business_Address: "",
    c_Address: "",
    contact_person_name: "",
    email: "",
    emailverified: "",
    gst: "",
    ifsc_code: "",
    mobile_no: "",
    name: "",
    pan: "",
  });
  const [state, setState] = useState({
    account_no: false,
    bank_name: false,
    benifesory_name: false,
    business_Address: false,
    c_Address: false,
    contact_person_name: false,
    email: false,
    emailverified: false,
    gst: false,
    ifsc_code: false,
    mobile_no: false,
    name: false,
    pan: false,
  });

  const [loading, setLoading] = useState(true);

  const [message, setmessage] = useState("");

  const InputEventChange = (e) => {


    //ACCOUNT NO  //ACCOUNT NO
    if (e.target.name === "account_no") {
      if (!isNaN(e.target.value)) {
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
      }
      if (e.target.value.length <= 10)
        setmessage("Please enter valid account no");
      else setmessage("");
    }

    //IFSC CODE  //IFSC CODE
    else if (e.target.name === "ifsc_code") {
      const strongRegex = new RegExp("^[A-Z]{4}[0][A-Z0-9]{11}$");

      if (strongRegex.test(e.target.value)) {
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
        setmessage("");
      } else {
        setmessage("Please enter valid IFSC Code");
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
      }

      //PAN NO   //PAN NP
    } else if (e.target.name === "pan") {
      const strongRegex = new RegExp(
        "/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/"
      );

      if (strongRegex.test(e.target.value)) {
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
        setmessage("");
      } else {
        setmessage("Please enter valid Pan");
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
      }
    }

    //GST NO   //GST NO
    else if (e.target.name === "gst") {
      const strongRegex = new RegExp(
        "/d{2}[A-Z]{5}d{4}[A-Z]{1}[A-Zd]{1}[Z]{1}[A-Zd]{1}/"
      );

      if (strongRegex.test(e.target.value)) {
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
        setmessage("");
      } else {
        setmessage("Please enter valid Gst");
        setUserData({
          ...userdata,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  //SubmitAccount SubmitAccount
  const SubmitAccount = () => {
    setLoading(true);
    UserAccount(userdata)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          toast.success("Profile has been updated successfully");
        } else {
          toast.success("Technical error");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn(`Technical ${error}`);
      });
  };

  //VerifyEmail  //VerifyEmail

  const VerifyEmailBtn = () => {
    toast.success("Verification mail has been sent on you email");
    VerifyEmail().then((res) => {
      if (res.status === 200) {
      }
    });
  };

  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container-fluid" style={{ zIndex: 999 }}>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                <h3>User Profile</h3>
              </div>
              <div className="row p-3 justify-content-between userProfileForm">
                <div className="col-lg-5 col-sm-12 bg-light shadow p-3 p-lg-5">
                  <h4 className="mb-4">Basic Details</h4>
                  <p>
                    <b> Email : </b>
                    <span
                      className="ml-3"
                      style={
                        state.email
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.email}
                    </span>
                    &nbsp;
                    {/* <i
                      className={
                        userdata.emailverified
                          ? "fas fa-user-check"
                          : "fas fa-exclamation-circle"
                      }
                      style={{ fontSize: "20px" }}
                      id="email"
                    ></i> */}{" "}
                    <Tooltip
                      title={
                        userdata.emailverified ? "Verified" : "Not Verified"
                      }
                      followCursor
                    >
                      {userdata.emailverified ? (
                        <VerifiedIcon style={{ color: "green" }} />
                      ) : (
                        <GppMaybeIcon style={{ color: "orange" }} />
                      )}
                    </Tooltip>
                    {userdata.emailverified ? (
                      " "
                    ) : (
                      <button
                        className="btn btn-primary ml-2"
                        onClick={VerifyEmailBtn}
                        type="submit"
                        style={{ fontSize: "11px", padding: "2px 6px" }}
                      >
                        Verify
                      </button>
                    )}
                  </p>
                  <p>
                    <b> LP Name : </b>

                    <span
                      className="ml-3"
                      style={
                        state.name
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.name}
                    </span>
                    <input
                      type="text"
                      name="name"
                      value={userdata.name}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      style={
                        state.name
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    />
                    <i
                      id="name"
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                    ></i>
                  </p>

                  <p className="d-flex flex-wrap">
                    <b> Company Address : </b>
                    <span
                      className="ml-3"
                      style={
                        state.c_Address
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {state.c_Address}
                    </span>
                    <textarea
                      className="ml-2"
                      name="c_Address"
                      value={userdata.c_Address}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      style={
                        state.c_Address
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    ></textarea>
                    <i
                      id="c_Address"
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                    ></i>
                  </p>

                  <p>
                    <b> Person Name : </b>
                    <span
                      className="ml-3"
                      style={
                        state.contact_person_name
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.contact_person_name}
                    </span>
                    <input
                      type="text"
                      name="contact_person_name"
                      value={userdata.contact_person_name}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      style={
                        state.contact_person_name
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      id="contact_person_name"
                      onClick={inputHide}
                    ></i>
                  </p>

                  <p>
                    <b> Mobile No. : </b>
                    <span
                      className="ml-3"
                      style={
                        state.mobile_no
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.mobile_no}
                    </span>

                    <input
                      type="text"
                      value={userdata.mobile_no}
                      name="mobile_no"
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      style={
                        state.mobile_no
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    />
                    {/* <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="mobile_no"
                    ></i> */}
                  </p>
                  <p className="d-flex flex-wrap">
                    <b> Bussiness Address : </b>
                    <span
                      className="ml-3"
                      style={
                        state.business_Address
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.business_Address}
                    </span>
                    <textarea
                      className="ml-2"
                      style={
                        state.business_Address
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="business_Address"
                      value={userdata.business_Address}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    ></textarea>

                    <i
                      className="fas fa-edit ml-2"
                      id="business_Address"
                      style={{ color: "green" }}
                      onClick={inputHide}
                    ></i>
                  </p>

                  <p>
                    <b> PAN : </b>
                    <span
                      className="ml-3"
                      style={
                        state.pan
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.pan}
                    </span>
                    <input
                      style={
                        state.pan
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="pan"
                      value={userdata.pan}
                      onChange={InputEventChange}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="pan"
                    ></i>
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {state.pan ? message : ""}
                    </span>
                  </p>
                  <p>
                  
                    <b> GST : </b>
                    <span
                      className="ml-3"
                      style={
                        state.gst
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.gst}
                    </span>
                    <input
                      style={
                        state.gst
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="gst"
                      value={userdata.gst}
                      onChange={InputEventChange}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="gst"
                    ></i>
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {state.gst ? message : ""}
                    </span>
                  </p>
                  {localStorage.getItem("user_role").includes("Vendor") ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <p>
                        <b> Monthly Shipping : </b>
                        <span className="ml-3">{userdata.monthlyshipping}</span>
                      </p>
                      <p>
                        <b> Company Description : </b>
                        <span className="ml-3">{userdata.company_desc}</span>
                      </p>
                      <p>
                        <b> Deal Item : </b>
                        <span className="ml-3">{userdata.deal_item}</span>
                      </p>
                      <p>
                        <b> Average Shipment Weight : </b>
                        <span className="ml-3">
                          {userdata.avg_wt_of_shipment}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="col-lg-5 col-sm-12 bg-light shadow p-3 p-lg-5 mt-lg-0 mt-3">
                  <h4 className="mb-4">Bank Details</h4>
                  <p>
                    <b> Account No. : </b>
                    <span
                      className="ml-3"
                      style={
                        state.account_no
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.account_no}
                    </span>
                    <input
                      style={
                        state.account_no
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      id="account_no"
                      type="text"
                      name="account_no"
                      value={userdata.account_no}
                      onChange={InputEventChange}
                      maxLength={16}
                      minLength={11}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="account_no"
                    ></i>
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {state.account_no ? message : ""}
                    </span>
                  </p>
                  <p>
                    <b> IFCS Code : </b>
                    <span
                      className="ml-3"
                      style={
                        state.ifsc_code
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.ifsc_code}
                    </span>
                    <input
                      style={
                        state.ifsc_code
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="ifsc_code"
                      value={userdata.ifsc_code}
                      onChange={InputEventChange}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="ifsc_code"
                    ></i>
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {state.ifsc_code ? message : ""}
                    </span>
                  </p>
                  <p>
                    <b> Bank Name : </b>
                    <span
                      className="ml-3"
                      style={
                        state.ifsc_code
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.bank_name}
                    </span>
                    <input
                      style={
                        state.bank_name
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="bank_name"
                      value={userdata.bank_name}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="bank_name"
                    ></i>
                  </p>

                  <p>
                    <b> Beneficiary Name </b>:
                    <span
                      className="ml-3"
                      style={
                        state.benifesory_name
                          ? { display: "none" }
                          : { display: "inline-block" }
                      }
                    >
                      {userdata.benifesory_name}
                    </span>
                    <input
                      style={
                        state.benifesory_name
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                      type="text"
                      name="benifesory_name"
                      value={userdata.benifesory_name}
                      onChange={(e) => {
                        setUserData({
                          ...userdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <i
                      className="fas fa-edit ml-2"
                      style={{ color: "green" }}
                      onClick={inputHide}
                      id="benifesory_name"
                    ></i>
                  </p>
                </div>

                <div className="col-12 text-end mt-3 p-0">
                  <button
                    className="btn btn-success shadow"
                    onClick={SubmitAccount}
                    type="submit"
                  >
                    Submit form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};
export default MyAccount;
