import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { GetNotificationsUserlist, ActiveorDeactivateUserNotification } from '../../Services/AdminApi'
import { useParams, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

export default function BoardingTemplate() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [assignrolesopen, setOpenRole] = useState(false);
  const [userList, setUserList] = useState([]);
  const { notificationid } = useParams();

  // Use useLocation to get the location object, which includes search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationId = searchParams.get('notificationid');

  // Log the notificationId to check if it's correctly retrieved
  console.log('NotificationId:', notificationId || notificationid);

  //  useEffect(() => {
  //    // Fetch user data based on notificationId
  //    GetNotificationsUserlist(notificationId)
  //      .then((response) => setUserList(response))
  //      .catch((error) => console.error('Error fetching user list:', error));
  //  }, [notificationId]);


  useEffect(() => {
    GetNotificationsUserlist(notificationId || notificationid)
      .then((response) => setUserList(response))
      .catch((error) => console.error('Error fetching user list:', error));
  }, [notificationId, notificationid]);

  const handleSwitchChange = async (userId, currentStatus) => {
    console.log(userId, currentStatus);
    const newStatus = !currentStatus;

    try {
      // Update user status via API
      let response = await ActiveorDeactivateUserNotification(notificationId, userId, newStatus);
      if (response.status === 200) {
        toast.success(`User notification updated successfully!`);

        setUserList((prevUsers) =>
          prevUsers.map((user) =>
            user.userId === userId ? { ...user, isActive: newStatus } : user
          )
        );
      }
      else {
        toast.error("An error occurred while updating the user notification status.");
      }
    } catch (error) {
      console.error("Switch change error:", error);
      toast.error(`Error updating user status: ${error.message}`);
    }
  };

  const columnsdata = [
    { field: 'sno', headerName: 'Sr. No.', width: 50, },
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileNo', headerName: 'Mobile No.', width: 150 },
    { field: 'role', headerName: 'Roles', width: 120 },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            onChange={() => handleSwitchChange(params.row.userId, params.row.isActive)}
          />
        )
      },
    },
  ];


  const assignrolesClickOpen = () => {
    setOpenRole(true);
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">On Boarding</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">On Boarding</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="content">
        <div className="container-fluid">
          <Card className='custum-shadow py-3 px-3'>
            <CardContent>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}><img src='https://mvload.in/images/logo-pdf.png' alt='Logo' width={100} /></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <h3 style={{ marginTop: "20px", fontWeight: "600", fontSize: "20px" }}>Dear Sonu,</h3>

                      <p style={{ fontSize: "16px", fontWeight: "600", color: "#f58220", marginBottom: "10px" }}>Welcome to MV Load, an app for seamless and affordable cargo B2B shipping!</p>

                      <b style={{ fontSize: "14px", marginBottom: "5px", display: "block", textTransform: "uppercase", color: "#000" }}>Using MV Load you can:</b>
                      <ul style={{ fontSize: "14px", fontWeight: "500", lineHeight: "30px", color: "#383838" }}>
                        <li>Save costs using our compare and book feature</li>
                        <li>Work with multiple carriers on a single portal</li>
                        <li><b>Increase Customer Loyalty:</b> Deliver to more than 29,000 serviceable pin codes in India and delight your customers with customized shipping labels and real-time WhatsApp and SMS updates.</li>
                        <li>Get pickup and delivery support</li>
                        <li>Transparently view your rates of all carriers and get daily shipment MIS.</li>
                        <li><b>Improve Shipping:</b> Data Driven carrier selection to improve shipping performance.</li>
                        <li><b>Reduced Customer Calls:</b> Unified Tracking to reduce customer calls & improved visibility.</li>
                        <li><b>Reduce exceptions:</b> Proactive resolution of shipment delays & SLA Breaches.</li>
                        <li><b>Increase Serviceability:</b> Single API for creating orders across 20 + Carriers.</li>
                        <li>Get To Pay/COD remittance every Tuesday and Thursdays within delivery +7 days</li>
                      </ul>

                      <p style={{ fontSize: "15px", fontWeight: "500", color: "#000", lineHeight: "25px" }}>For any queries or support you can reach out to us at "<b style={{ color: "#f79b4d" }}>011-46039993</b>" or <b style={{ color: "#f79b4d" }}>care@mvload.in</b>. Our team will help you with timely pickups and deliveries and answer all your queries. For any escalations you can write to <b style={{ color: "#f79b4d" }}>escalations@mvload.in</b>.</p>

                      <b style={{ fontSize: "14px", margin: "20px 0 5px", display: "block", textTransform: "uppercase", color: "#000", borderTop: "2px solid #ddd", padding: "10px 0 10px" }}>Here are some common problems faced by users and how to overcome them:</b>

                      <ul style={{ listStyle: "auto", fontWeight: "600", color: "#7d3232", fontSize: "14px" }}>
                        <li>Carrier losing documents while shipping and shipment gets stuck
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>Keep scans of invoice and other supporting docs with you. In case documents are lost by the carrier you can quickly pass them the scans.</p>
                        </li>
                        <li>Carrier charges more weight for the shipment
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>Take pics and videos while taking dimensions and weighing shipment or keep other proofs to submit to carrier in case of any dispute</p>
                        </li>
                        <li>Shipment is partially lost or damaged in transit
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>While accepting delivery, check the shipment properly. Sign the POD with remarks or take a photo of the package with the delivery boy or refuse to accept the damaged shipment.</p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style={{ background: "#4f4c4d", padding: "20px", margin: "0px", borderRadius: "4px", fontSize: "14px", fontWeight: "500", textTransform: "uppercase", color: "#ffffff", letterSpacing: "0.5px" }}>Mvikas Super Logistic <br />11g, Big Jo's Tower Netaji Subhash Place, Rohini 110034</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </div>
      </section> */}

      <section className="content mt-4">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={userList}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              getRowId={(user) => user.userId}
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>
      <ToastContainer />
      {/*  */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className='delete-alert'>
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to inactive this user?</p>
            <Button onClick={assignrolesClose} variant="contained">Yes</Button>
            <Button onClick={assignrolesClose} autoFocus variant="outlined" className='ml-2'>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}