import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BulkOrders from "./BulkOrders";
import Footer from "./Footer";
import BulkOrdersB2C from "./BulkOrdersB2C";
const UploadBulkOrdersB2C = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <BulkOrdersB2C />
      <Footer />
    </>
  );
};

export default UploadBulkOrdersB2C;
