import React from "react";
const styles = {
  aboutSection: {
    padding: "70px 0 20px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "#000",
    marginBottom: "10px",
  },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  },
  heading: {
    fontSize: "40px",
    fontWeight: "700",
    color: "#5a0000"
  }
}




const InnovativeSolution = () => {
  return (
    <div>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="sec-title text-center">
                <div className="h2 heading after-none">Your Logistics Problem, Our Innovative Solution</div>
                <p>Get in touch with us to resolve all your logistics challenges with our industrial-standard solutions tailored to your needs.</p>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Instant Quotes</div>
                <p>With just a few taps, feel the ease of obtaining accurate instant quotes on the products and delivery costs for all your shipping needs</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Compare the Prices</div>
                <p>Make informed decisions on all your shipments by comparing the prices from different delivery agents and logistics partners.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Reach </div>
                <p>Enjoy a fast delivery experience across PAN India with us! Be sure of efficient and reliable delivery at every corner of our country.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Delivery Standards</div>
                <p>We prioritize your logistics needs with speedy and efficient delivery across PAN India in just 12 hours and 48 hours across the globe.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div  className="h4">Competitive Prices</div>
                <p>Get budget-friendly PAN India delivery without having to compromise on the quality at just INR 39* and at INR 450* for international delivery.

                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Support Centre
                </div>
                <p>Contact our experts 24/7 for any queries or assistance for a seamless shipping experience. Feel free to dial us at  011-46039993

                </p>
              </div>
            </div>
          </div>


        </div>
      </section>
    </div>
  );
};

export default InnovativeSolution;
