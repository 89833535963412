import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
  getStateList,
  GetpincodeByState,
  Savemetro,
  Checkmetronm,
  GetmetroByid,
  GetcityByState,
} from "../Services/MetroApi";
import {
  GetAllService,
} from "../Services/ServiceApi"
import { Saveotherb2cmetro, GetIntracityAndROIRatesById, UpdateB2Crates } from "../Services/SpecialZoneApi"
import Select from 'react-select';
import Card from '@mui/material/Card';
import { Link, useParams } from "react-router-dom";
import Loading from "./Loading";

const CreateSpecialZone = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [statelist, setStatelist] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [metropincode, setmetropincode] = useState([]);
  const [pincodeQuery, setPincodeQuery] = useState("");
  const [selectedpincodeQuery, setselectedpincodeQuery] = useState("");
  const [ddldata, setDdlData] = useState({
    statename: "",
    cityname: "",
  });
  const [metroType, setMetroType] = useState("intracity");
  const [data, setmetroData] = useState({
    zoneid: 0,
    serviceid: 0,
    metroname: "",
    lpId: localStorage.getItem("user_id"),
    pincodes: [],
    isoda: "",
    tat: 0,
    stdTat: 0,
    zoneType: 4
  });
  let ddlData;
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    metroname: false,
    pincode: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const isEditMode = id !== undefined && id !== "0";
  // useEffect(() => {
  //   if (id !== undefined && id !== 0) {
  //     GetIntracityAndROIRatesById(id).then((res) => {
  //       setLoading(false);
  //       setmetroData({
  //         ...data,
  //         ["metroname"]: res.metroname,
  //         ["isoda"]: res.isOda,
  //         ["tat"]: res.odaTat || 0,
  //         ["stdTat"]: res.stdtat || 0,
  //         ["serviceid"]: res.id,
  //       });
  //       document.getElementById("allPincode").style.display = "block";
  //       setmetropincode(res.pincodes);
  //     });
  //     getStateList().then((items) => {
  //       setStatelist(items);
  //     });
  //   } else {
  //     getStateList().then((items) => {
  //       setLoading(false);
  //       setStatelist(items);
  //     });
  //     GetAllService().then((serviceItems) => {
  //       setLoading(false);
  //       const activeServices = serviceItems.filter(service => service.isactive);
  //       setServices(activeServices.map(service => ({ value: service.id, label: service.name })));

  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (isEditMode) {
      GetIntracityAndROIRatesById(id).then((res) => {
        setLoading(false);
        setmetroData({
          ...data,
          ["metroname"]: res.metroname,
          ["isoda"]: res.isOda,
          ["tat"]: res.odaTat || 0,
          ["stdTat"]: res.stdtat || 0,
          ["serviceid"]: res.id,
        });
      });
    } else {
      GetAllService().then((serviceItems) => {
        setLoading(false);
        const activeServices = serviceItems.filter(service => service.isactive);
        setServices(activeServices.map(service => ({ value: service.id, label: service.name })));
      });
    }
  }, []);
  ddlData = statelist.sort().map((val, i) => {
    return <option key={i}>{val}</option>;
  });
  const RemovecheckBox = (i) => {
    document.getElementsByName(i).checked = false;
    const newPincode = metropincode.filter((val, indx) => {
      return val == i;
    });
    const newPincode2 = metropincode.filter((val, indx) => {
      return val !== i;
    });
    setmetropincode(newPincode2);

    setPincode([...pincode, newPincode[0]]);
    const name = "pincodes";
    setmetroData({
      ...data,
      [name]: [
        ...data.pincodes.filter((val) => {
          return val !== i;
        }),
      ],
    });
  };
  const onSubmitmetro = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      // data.metroname === "" ||
      data.isoda === "" ||
      data.tat === "" ||
      data.stdTat === "" ||
      data.serviceid === "" ||
      data.zoneType === 0
    ) {
      setLoading(false);
      toast.warning("All fileds are mandatory");
    } else {
      Saveotherb2cmetro(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success("Record saved successfully");
          setmetroData({
            metroid: 0,
            metroname: "",
            isoda: "",
            tat: "",
            stdTat: "",
            pincodes: [],
            serviceid: "",
            zoneType: 2,
          });
          // setPincode([]);
          // setmetropincode([]);
          setDdlData({
            statename: "",
            cityname: "",
          });
          document.getElementById("allPincode").style.display = "none";
        } else {
          toast.error(res.data);
        }
      });
    }
  };
  const onSubmitUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      data.isoda === "" ||
      data.tat === "" ||
      data.stdTat === "" ||
      data.serviceid === "" ||
      data.zoneType === 0
    ) {
      setLoading(false);
      toast.warning("All fields are mandatory");
    } else {
      UpdateB2Crates(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success("Record updated successfully");
          setmetroData({
            ...data,
            metroname: "",
            isoda: "",
            tat: "",
            stdTat: "",
            serviceid: "",
            zoneType: 4,
          });
        } else {
          toast.error(res.data);
        }
      });
    }
  };
  const inputEvent = (e) => {
    const { name, value } = e.target;
    setmetroData({ ...data, [name]: value });

    if (name === "metroname") {
      if (value !== "") {
        Checkmetronm(value).then((res) => {
          if (!res) {
            setErrorMessage({ message: "", metroname: false, pincode: false });
          } else {
            setErrorMessage({
              message: "metro name already exist",
              metroname: true,
              pincode: false,
            });
          }
        });
      }
    }

    if (name === "statename") {
      setPincode([]);
      setDdlData({ statename: value, cityname: "" });
      if (value !== "Select") {
        document.getElementById("allPincode").style.display = "block";
      }

      GetcityByState(value).then((items) => {
        setCitylist(items);
      });

      GetpincodeByState(value, "").then((items) => {
        if (items.length === 0)
          setErrorMessage({
            message: "This state's all pincodes are already mapped with other metro!!",
            metroname: false,
            pincode: true,
          });
        else {
          setErrorMessage({
            message: "",
            metroname: false,
            pincode: false,
          });
          const newpin = items.filter(function (item) {
            return metropincode.indexOf(item) === -1;
          });
          setPincode(newpin);
        }
      });
    } else if (name === "cityname") {
      setPincode([]);
      setDdlData({ ...ddldata, cityname: value });
      if (value === "All Cities") {
        GetpincodeByState(ddldata.statename, "").then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message: "This state's all pincodes are already mapped with other metro!!",
              metroname: false,
              pincode: true,
            });
          else {
            setErrorMessage({
              message: "",
              metroname: false,
              pincode: false,
            });
            const newpin = items.filter(function (item) {
              return metropincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      } else {
        GetpincodeByState(ddldata.statename, value).then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message: "This state's all pincodes are already mapped with other metro!!",
              metroname: false,
              pincode: true,
            });
          else {
            setErrorMessage({
              message: "",
              metroname: false,
              pincode: false,
            });
            const newpin = items.filter(function (item) {
              return metropincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      }
    }
  };
  const inputCheckEvent = (e) => {
    const newPincode = pincode.filter((val, indx) => {
      return val !== e;
    });
    const name = "pincodes";
    setmetropincode([...metropincode, e]);
    setPincode(newPincode);

    setmetroData({ ...data, [name]: [...data.pincodes, e] });
  };
  const handleServiceChange = (selectedOption) => {
    setSelectedService(selectedOption);
    const serviceId = selectedOption ? selectedOption.value : '';
    setmetroData(prevData => ({
      ...prevData,
      serviceid: serviceId
    }));
  };
  const handleMetroTypeChange = (event) => {
    const value = event.target.value;
    const zoneTypeMap = {
      intracity: 4,
      restofindia: 5
    };
    setMetroType(value);
    setmetroData(prevData => ({
      ...prevData,
      zoneType: zoneTypeMap[value]
    }));
  };


  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center px-5 wrap-flex">
            <h3>Other B2C Rates</h3>
            <Link to="/specialzonedata" className="text-white btn btn-info">
              View Other B2C Rates
            </Link>
          </div>
          <form onSubmit={isEditMode ? onSubmitUpdate : onSubmitmetro}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="serviceSelection" className="form-label">Service Selection:</label>
                    <Select
                      id="serviceSelection"
                      name="services"
                      options={services}
                      className="basic-single-select"
                      classNamePrefix="select"
                      value={selectedService}
                      onChange={handleServiceChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Metro Type:</label>
                    <div>
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="metroType"
                          value="intracity"
                          checked={metroType === "intracity"}
                          onChange={handleMetroTypeChange}
                        />
                        Intracity
                      </label>
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="metroType"
                          value="restofindia"
                          checked={metroType === "restofindia"}
                          onChange={handleMetroTypeChange}
                          className="ms-2"
                        />
                        Rest of India
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="odaSelection" className="form-label">
                     Choose Serviceability:<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="odaSelection"
                      name="isoda"
                      value={data.isoda}
                      className="form-control"
                      onChange={inputEvent}
                    >
                      <option value="">Select ODA</option>
                      <option value="serviceable">SERVICEABLE</option>
                      <option value="nonserviceable">NONSERVICEABLE</option>
                      <option value="ODA 1">ODA 1</option>
                      <option value="ODA 2">ODA 2</option>
                      <option value="ODA 3">ODA 3</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="odaTat" className="form-label">
                      Enter ODA Tat:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="odaTat"
                      type="number"
                      placeholder="Enter ODA Tat"
                      name="tat"
                      value={data.tat}
                      className="form-control"
                      onChange={inputEvent}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="stdTat" className="form-label">
                      Enter TAT:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="stdTat"
                      type="number"
                      placeholder="Enter STD Tat"
                      name="stdTat"
                      value={data.stdTat}
                      className="form-control"
                      onChange={inputEvent}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {isEditMode ? (
                    <button type="submit" className="btn btn-info mt-3" disabled>Update</button>
                  ) : (
                    <button type="submit" className="btn btn-success mt-3">Submit</button>
                  )}
                </div>
              </div>
            </div>
          </form>
          <ToastContainer />
        </div>
      </>
    );
  }
};
export default CreateSpecialZone;
