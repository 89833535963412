import * as React from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useState, useEffect } from "react";
import { GetCustomerOrderlist } from "../Services/UserApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Link } from 'react-router-dom';
import { CurrentUrl } from "../Services/UrlApi";
import { saveAs } from "file-saver";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import { useParams } from "react-router-dom";
function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}
export default function MyOrder() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const rowsPerPageOptions = [5,10, 20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  // const ReviewButton = ({ orderid, lpid}) => {
  //   const navigate = useNavigate();
  //   const handleReviewClick = () => {
  //     navigate(`/Review?orderid=${orderid}&lpid=${lpid}`);
  //   };

  //   return (
  //     <Button className="review-btn" size="small" onClick={handleReviewClick}>
  //       {isReviewSubmitted ? "View Review" : "Submit Review"}
  //     </Button>
  //   );
  // };
  const ReviewButton = ({ orderid, lpid, isReviewSubmitted }) => {
    const navigate = useNavigate();
    const handleReviewClick = () => {
      navigate(`/Review?orderid=${orderid}&lpid=${lpid}`);
    };

    return (
      <Button className="review-btn" size="small" onClick={handleReviewClick}>
        {isReviewSubmitted ? "Submit Review" : "View Review"}
      </Button>
    );
  };

  const [columns, setColumns] = useState([
  // const columns = [
    // { field: "sno", headerName: "S.No.", width: 80 },
    { field: "order_id", headerName: "Order No.", width: 100, headerAlign: 'center',
    align: 'center'},
    { field: "orderDate", headerName: "Booking Date", width: 150, headerAlign: 'center',
    align: 'center'},
    {
      field: "barcode",
      headerName: "View Details",
      width: 120,headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const order_id = params.row.order_id;
        return (
          <Link to={{ pathname: "/Barcode", search: `?order_id=${order_id}` }}>
            <IconButton aria-label="barcode">
              {/* <QrCode2Icon /> */}
              <PreviewIcon />
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "review",
      headerName: "Review",
      width: 180,headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const isReviewSubmitted = params.row.isSubmitted === null;
        return (
          <ReviewButton
            orderid={params.row.order_id}
            lpid={params.row.lpid}
            isReviewSubmitted={isReviewSubmitted} 
          />
        );
      },

    },
    { field: "paymentMode", headerName: "Payment Mode", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "frompincode", headerName: "From Pin", width: 100 ,headerAlign: 'center',
    align: 'center'},
    { field: "topincode", headerName: "To Pin", width: 100,headerAlign: 'center',
    align: 'center' },
    { field: "order_Status", headerName: "Order Status", width: 150, headerAlign: 'center',
    align: 'center' },
    
    {
      field: "etd", headerName: "ETA", width: 200, headerAlign: 'center',
      align: 'center'
    },
    { field: "lspDocketNo", headerName: "LSP Docket No.", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "chargable_weight", headerName: "Chargeable Weight", width: 180,headerAlign: 'center',
    align: 'center'},
    { field: "docketcharge", headerName: "Docket Charge", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "handlingCharge", headerName: "Handling Charge", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "fov_amount", headerName: "FOV Amount", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "fsc_amount", headerName: "FSC Amount", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "fsc_percent", headerName: "FSC Percentage", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "greentax", headerName: "Green Tax", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "oda_amount", headerName: "ODA", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "rate", headerName: "Rate", width: 150 ,headerAlign: 'center',
    align: 'center'},




    { field: "taxableAmount", headerName: "Taxable Amount", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "gst_Amount", headerName: "GST Amount", width: 150,headerAlign: 'center',
    align: 'center' },
    
    { field: "descripenacyAmt", headerName: "Discrepancy Amount", width: 200, headerAlign: 'center', align: 'center' },
    { field: "total_Amount", headerName: "Total Amount", width: 150, headerAlign: 'center', align: 'center',
      valueGetter: (params) => {
        // Calculate total_Amount based on descripenacyAmt
        return params.row.total_Amount + params.row.descripenacyAmt;
      }
    },
    // { field: "orderRemarks", headerName: "Order Remarks", width: 300 },
    { field: "pickup_Address", headerName: "Pickup Address", width: 300,headerAlign: 'center',
    align: 'center' },
    { field: "pickup_name", headerName: "Pickup Name", width: 150,headerAlign: 'center',
    align: 'center' },
    { field: "pickupCompany", headerName: "Pickup Company name", width: 200,headerAlign: 'center',
    align: 'center' },
    { field: "pickup_mobile", headerName: "Pickup Contact No.", width: 180,headerAlign: 'center',
    align: 'center' },
    {
      field: "cust_Deliveryaddress",
      headerName: "Delivery Address",
      width: 300,headerAlign: 'center',
      align: 'center'
    },
    { field: "delivery_name", headerName: "Delivery Person name", width: 200,headerAlign: 'center',
    align: 'center' },
    { field: "delivery_mobile", headerName: "Delivery Contact", width: 150 ,headerAlign: 'center',
    align: 'center'},
    { field: "otherInfo", headerName: "Other information", width: 150,headerAlign: 'center',
    align: 'center' },

    // {
    //   field: "barcode",
    //   headerName: "Barcode",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <Link to="/Barcode">
    //         <IconButton aria-label="barcode">
    //           <QrCode2Icon />
    //         </IconButton>
    //       </Link>
    //     );
    //   },
    // },
    //{ field: "delayReason", headerName: "Delay Reason", width: 150 },
    { field: "itemInvoice", headerName: " Customer Invoice No.", width: 200,headerAlign: 'center',
    align: 'center' },
    {
      field: "invoicePath",
      headerName: " Customer Item Invoice",
      width: 200,headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <InvoiceItems invoicePath={params.row.invoicePath} />
          </Box>
        );
      },
    },
    {
      field: "podUrl",
      headerName: "POD",
      width: 100,headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <ProofOfDelivery podUrl={params.row.podUrl} />
          </Box>
        );
      },
    },
  // ];
]);

  const InvoiceItems = (props) => {
    const [invoiceopen, setInvoiceOpen] = React.useState(false);
    const handleInvoiceOpen = () => {
      setInvoiceOpen(true);
    };

    const handleInvoiceClose = () => {
      setInvoiceOpen(false);
    };

    const imagedown = (params) => {
      let url = `${CurrentUrl}${props.invoicePath}`;
      saveAs(url, "Order invoice");
    };



    return (
      <Box>
        <IconButton onClick={handleInvoiceOpen}>
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog className="fullScreen-box" fullScreen open={invoiceopen} onClose={handleInvoiceClose}>
          <DialogContent style={{ textAlign: "center" }}>
            <iframe title="Invoice" className="iframe-data" width={'100%'} height={'100%'} src={`${CurrentUrl}${props.invoicePath}`}> </iframe>

            {/* <iframe width={'100%'} height={'800px'} 
            src="http://mvikasapi.adequateshop.com//UploadImage/c436e7ac-7bc8-49c2-9827-4601a1aef6c5_Hubtel%20Direct%20Receive%20Money%20(v1.5).pdf"></iframe> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={imagedown}>Download</Button>
            <Button onClick={handleInvoiceClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };



  const ProofOfDelivery = (props) => {
    const [proofofdeliverypop, setproofofdeliverypop] = useState("");
    const handleproofofdeliverypopopen = () => {
      setproofofdeliverypop(true);
    };

    const handleproofofdeliverypopclose = () => {
      setproofofdeliverypop(false);
    };

    const proofofdeliverydown = (params) => {
      let url = props.podUrl;
      saveAs(url, "Proof Of Delivery");
    };



    return (
      <Box>
        <IconButton onClick={handleproofofdeliverypopopen}>
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen open={proofofdeliverypop}
          onClose={handleproofofdeliverypopclose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <iframe
              title="Proof Of Delivery"
              className="iframe-data"
              width={'100%'}
              height={'100%'}
              src={props.podUrl}>
            </iframe>

          </DialogContent>
          <DialogActions>
            <Button onClick={proofofdeliverydown}>Download</Button>
            <Button onClick={handleproofofdeliverypopclose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const myParam = useLocation().search;
  const order_status = new URLSearchParams(myParam).get("order_status");
  const { orderType } = useParams();    // 1 for B2B and 2 for B2C

  useEffect(() => {
    loadCustomerOrders();

   
  }, [orderType]);

  useEffect(() => {
    // Adjust columns based on orderType
    let updatedColumns = [...columns]; // Use let instead of const
    if (orderType === '2') {
      // Hide columns for B2C (orderType 2)
      const columnsToHide = ['docketcharge', 'handlingCharge', 'fov_amount', 'fsc_amount', 'fsc_percent', 'greentax', 'oda_amount', 'rate'];
      updatedColumns = updatedColumns.filter(col => !columnsToHide.includes(col.field));
    }
    setColumns(updatedColumns);
  }, [orderType]);

  const loadCustomerOrders = () => {
    setData([]);
    setLoading(true);
    var params = "";
    if (order_status) {
      setStatus(order_status);
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (order_status != null && order_status != undefined && order_status != "") {
      params += `status=${order_status}&`;
    }
    // if (search != null && search != undefined && search != "") {
    //   params += `search=${search}&`;
    // }
    // if (fromDate != null && fromDate != undefined && fromDate != "") {
    //   params += `fromDate=${fromDate}&`;
    // }
    // if (toDate != null && toDate != undefined && toDate != "") {
    //   params += `todate=${toDate}`;
    // }
    GetCustomerOrderlist(localStorage.getItem("user_email"),orderType==1?false:true, params)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.order_Date) - new Date(a.order_Date)
        );
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredData = data.filter((row) => {
    // const isSearchMatch =
    // row.customername.toLowerCase().includes(search.toLowerCase()) ||
    // (row.pickupCompany && row.pickupCompany.toLowerCase().includes(search.toLowerCase())) ||
    // row.order_id.toLowerCase().includes(search.toLowerCase()) ||
    // row.customerphone.includes(search) ||
    // (row.otherInfo && row.otherInfo.includes(search)) ||
    // row.customeremail.toLowerCase().includes(search.toLowerCase());

    const isSearchMatch = Object.keys(row).some((key) => {
      const value = row[key];

      if (value !== null && value !== undefined) {
        if (typeof value === 'string' || value instanceof String) {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return value.toString().includes(search);
      }

      return false;
    });


    const orderDate = parseDate(row.orderDate, 'DD-MM-YYYY');
    let isDateInRange = true;

    if (fromDate) {
      const fromDateTime = new Date(fromDate);
      fromDateTime.setHours(0, 0, 0, 0);
      isDateInRange = isDateInRange && orderDate >= fromDateTime;
    }

    if (toDate) {
      const toDateTime = new Date(toDate);
      toDateTime.setHours(23, 59, 59, 999);
      isDateInRange = isDateInRange && orderDate <= toDateTime;
    }

    return isSearchMatch && isDateInRange;
  })


  function parseDate(dateString, format) {
    const parts = dateString.split('-').map(Number);
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">My Orders</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">My Orders</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
              <div className="col">
                <TextField
                  // required
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    id="Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    labelId="Status"
                    id="Status"
                    fullWidth
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={"Booked"}>Booked</MenuItem>
                    <MenuItem value={"Intransit"}>In-Transit</MenuItem>
                    <MenuItem value={"RTO"}>RTO</MenuItem>
                    <MenuItem value={"Delivered"}>Delievered</MenuItem>
                    <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={(e) => {
                      if (e) {
                        setfromDate(e.toString());
                      } else {
                        setfromDate("");
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={(e) => {
                      if (e) {
                        setToDate(e.toString());
                      } else {
                        setToDate("");
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="">
                <IconButton
                  onClick={() => {
                    loadCustomerOrders();
                  }}
                  size="small"
                  className="search-icon"
                >
                  <ManageSearchIcon />
                </IconButton>
              </div>
            </Card>

            <Card className="custum-shadow mt-3">
              {loading ? (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Loading...</p>
                </div>
              ) : (
                <DataGrid
                  sx={{
                    p: "1rem",
                    "@media print": {
                      ".table-ui": { border: "none" },
                    },
                  }}
                  //     sx={{
                  //       '& .MuiDataGrid-virtualScroller': {
                  //         transform: 'rotateX(180deg)',
                  //       },
                  //       '& .MuiDataGrid-virtualScrollerContent': {
                  //         transform: 'rotateX(180deg)',
                  //       },
                  // }}
                  className="table-ui"
                  disableColumnMenu
                  getRowId={(row) => row.order_id}
                  disableDensitySelector
                  // rows={data}
                  rows={filteredData}
                  columns={columns}
                  autoHeight={true}

                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  pagination
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPageSizeChange={(newPageSize) => {
                    setRowsPerPage(newPageSize);
                  }}
                  initialState={{ pinnedColumns: { left: [''], right: ['review'] } }}
                />
              )}
            </Card>
          </div>
        </section>

        <div id="img-content" style={{ display: "none" }}>
          {/* <img src= {`http://mvikasapi.adequateshop.com${props.invoicePath}`}
      /> */}
        </div>
      </div>
    </>
  );
}
