import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    GetMetroName,
    GetpincodeByMetroId,
    DeleteMetro,
    GetpincodeByLpid,
    GetmetroByid
} from "../Services/MetroApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import { LinearProgress, Chip } from "@mui/material";
const Metro = () => {
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const navigate = useNavigate();
    const [zoneName, setZoneName] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        BindZone();
    }, []);
    let Head;

    const BindZone = () => {
        let mounted = true;
        let lpid = localStorage.getItem("user_id")
        GetMetroName(lpid).then((items) => {
            if (mounted) {
                setZoneName(items);
                setLoading(false);
            }
        });
        return () => (mounted = false);
    };
    // const Togglespan = (id, e, metroName) => {
    //     if (!e.target.className.split(" ").includes("collapsed")) {
    //         setPincodes([]);
    //         let lpid = localStorage.getItem("user_id")
    //         // GetpincodeByLpid(lpid).then((data) => {
    //         //     setPincodes(data);
    //         // });            
    //         GetmetroByid(lpid, metroName).then((data) => {
    //             setPincodes(data.pincodes);
    //         });
    //     }
    // };
    const Togglespan = (id, e, metroName) => {
        if (!e.target.className.split(" ").includes("collapsed")) {
          setSelectedAccordion(metroName); 
          setLoading(true);
          let lpid = localStorage.getItem("user_id")
          GetmetroByid(lpid, metroName).then((data) => {
            setPincodes(data.pincodes);
            setLoading(false);
          });
        }
      };

    const deleteEvent = (id) => {
        if (window.confirm("Do you want to delete")) {
            setLoading(true);
            DeleteMetro(id).then((res) => {
                if (res.status === 200) {
                    BindZone();
                    toast.success("Zone deleted successfully");
                } else {
                    setLoading(false);
                    toast.warn("Techincal error");
                }
            });
        }
    };
    const EditEvent = (id) => {
        navigate(`/metro/${id}`);
    };
    Head = zoneName.map((head, i) => (
        <div className="accordion-item" key={i}>
            <h2 className="accordion-header" id={`heading${head.id}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                        className="accordion-button collapsed"
                        onClick={(e) => Togglespan(head.id, e , head.metroName)}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${head.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${head.id}`}
                    >
                        {head.metroName}
                    </button>
                    <Chip
                        label={head.zoneType}
                        color="primary"
                        size="small"
                        style={{ marginLeft: "10px" }}
                    />
                    <span
                        className="fa fa-edit"
                        style={{
                            fontSize: "15px",
                            padding: "10px",
                            color: "green",
                            margin: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => EditEvent(head.metroName)}
                    ></span>
                    {/* <span
            className="fa fa-trash"
            style={{
              fontSize: "15px",
              padding: "10px",
              color: "red",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={() => deleteEvent(head.id)}
          ></span> */}
                </div>
            </h2>
            <div
                id={`collapse${head.id}`}
                // className="accordion-collapse collapse "
                className={selectedAccordion === head.metroName ? "accordion-collapse collapse show" : "accordion-collapse collapse"}
                aria-labelledby={`heading${head.id}`}
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                    {pincodes.length !== 0 ? (
                        pincodes.map((val, i) => {
                            return (
                                <Chip
                                    value={val}
                                    label={val}
                                    p={1}
                                    variant="outlined"
                                    sx={{
                                        marginBottom: "5px",
                                        marginRight: "5px",
                                    }}
                                />
                            );
                        })
                    ) : (
                        <LinearProgress />
                    )}
                </div>
            </div>
        </div>
    ));

    if (loading) return <Loading />;
    else {
        return (
            <>
                <div className="content-wrapper">
                    <div className="d-flex justify-content-between align-items-center px-5">
                        <h3>Metro Data</h3>

                        <Link to="/Metro" className="text-white btn btn-info">
                            Add Metro
                        </Link>
                    </div>
                    <div className="accordion px-5 mt-4" id="accordionExample">
                        {Head == "" ? (
                            <div className="text-center">
                                <h4>No Records Found !!</h4>
                            </div>
                        ) : (
                            Head
                        )}
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    }
};

export default Metro;
