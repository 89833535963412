import React from "react";

const About = (prop) => {
  return (
    <div id={prop.sectionId}>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">
          <div className="row align-items-center">
          <div className="image-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img src="./images/about.jpg" alt="about" />
                  </a>
                </figure>
              </div>
            </div>
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About Us</span>
                  <div className="h2">Move with the Best Logistics Company in India</div>
                </div>
                <div className="text"
                  style={styles.text}>
                  We pride ourselves as one of India's leading logistics services providers in India, specializing in a wide range of logistics services.

                  We understand that every minute counts, so at MVIKAS Super Logistics, we ensure top-notch deliveries across PAN India within 12 hrs and globally within just 48 hrs. We guarantee timely delivery for all your shipments at an affordable price.

                  Our commitment to excellence and innovative logistics services sets us apart in this ever-evolving logistics landscape. We bridge the gap with our shipping services, whether air, sea, or land.

                  Expect to make your shipping simpler with us!

                </div>
              </div>
            </div>            
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;

const styles = {
  aboutSection: {
    padding: "70px 0 20px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "#000",
  },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  }
}