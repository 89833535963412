import * as React from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  GetSaleOrderList,
  UpdateOrderStatus,
  GetOrderStatusList,
  UpdateDocketNumber,
  GetLpDetailsTat,
  GetPickupslotforlpchange,
  GetDeliverySlotforlpchange,
  UpdateCustomerLPinAdmin,
  GetRateRequest,
  GetPageWiseSalesOrder,
} from "../Services/AdminApi";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUrl } from "../Services/UrlApi";
import { useLocation } from "react-router";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { saveAs } from "file-saver";
import { grey } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import { Link, useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import { GetRatesforlpupdate } from "../Services/RateApi";
import moment from "moment";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
let Url = `${CurrentUrl}Admin/`;

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}
function NoRowsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-boxes"></i>
      <p>No order data available</p>
    </Stack>
  );
}
function NoResultsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fal fa-list"></i>
      <p>No results in DataGrid</p>
    </Stack>
  );
}

export default function VendorSales() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [docketnumber, setdocketnumber] = useState("");
  const [mylplistdata, setmylplistdata] = useState({});
  const [getratedata, setgetratedata] = useState([]);
  const [selectedLp, setSelectedLp] = useState("");
  const [pickupSlots, setPickupSlots] = useState([]);
  const [deliverySlots, setDeliverySlots] = useState([]);
  const [selectedPickupSlot, setSelectedPickupSlot] = useState("");
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState("");
  const [myorderid, setmyorderid] = useState("");
  const [myoldlpid, setmyoldlpid] = useState("");
  const [myshipmentvalue, setmyshipmentvalue] = useState("");
  const [loadingSecondApi, setloadingSecondApi] = useState(false);
  const [selectedLpId, setSelectedLpId] = useState('');
  const [selectedServiceId, setSelectedServiceId] = useState('');

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const [customerEmail, setCustomerEmail] = useState("");

  const handleUpdateLP = async (customerid, order_id, lpid, customerEmail) => {
    //debugger;
    await GetRateRequest(customerid, order_id).then((response) => {
      setOpenupdate(true);
      setmylplistdata(response);
      setmyorderid(response.orderId);
      setmyoldlpid(response.oldLpId);
      setmyshipmentvalue(response.shipvalue);
      setCustomerEmail(customerEmail);
      setOrderId(order_id);
      setLpId(lpid);
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (mylplistdata) {
      handlegetrate(mylplistdata);
    }
  }, [mylplistdata]);

  const handlegetrate = async (mylplistdata) => {
    try {
      let requestBody = {
        frompin: mylplistdata.frompin,
        topin: mylplistdata.topin,
        numberofpiece: mylplistdata.numberofpiece,
        chargableweight: mylplistdata.chargableweight,
        shipvalue: mylplistdata.shipvalue,
        cod: mylplistdata.cod,
        grosswt: mylplistdata.grosswt,
        dtotalwt: mylplistdata.dtotalwt,
        units: mylplistdata.units,
        custId: mylplistdata.custId,
        toPay: mylplistdata.toPay,
        isChangeLp: mylplistdata.isChangeLp,
        orderId: mylplistdata.orderId,
        cust_Email: mylplistdata.cust_Email,
      };
      const response = await GetRatesforlpupdate(requestBody);
      if (response.status === 200) {
        const data = await response.json();
        setgetratedata(data);
        // setLoading(false);
      } else {
        console.error("API request failed:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setloadingSecondApi(false);
    }
  };

  const [lptatadata, setlptatadata] = useState({});
  const [orderId, setOrderId] = useState("");
  const [lpId, setLpId] = useState("");
  const [loadingTat, setLoadingTat] = useState(false);
  const handleLpSelection = (event) => {
    const selectedLpId = event.target.value;
    const lastDashIndex = selectedLpId.lastIndexOf('-');
    const lpId = selectedLpId.substring(0, lastDashIndex);
    const serviceIdStr = selectedLpId.substring(lastDashIndex + 1);
    const serviceId = parseInt(serviceIdStr, 10);

    setSelectedLpId(lpId);
    setSelectedServiceId(serviceId);
    setLoadingTat(true);
    GetLpDetailsTat(lpId, orderId, customerEmail, serviceId).then((response) => {
      setlptatadata(response.data);
      setLoadingTat(false);
    });
    GetPickupslotforlpchange(lpId).then((response) => {
      setPickupSlots(response);
    });
    GetDeliverySlotforlpchange(lpId).then((response) => {
      setDeliverySlots(response);
    });
    setSelectedPickupSlot("");
    setSelectedDeliverySlot("");
  };

  const handleupdatelpfromorders = async () => {
    if (!selectedLpId) {
      console.error("Please select a LP.");
      return;
    }
    if (!selectedPickupSlot || !selectedDeliverySlot) {
      console.error("Please select both pickup and delivery slots.");
      return;
    }
    const selectedLpData = getratedata.find((lp) => lp.lpId === selectedLpId);
    let body = {
      serviceid: selectedServiceId,
      divisor: selectedLpData.divisor,
      fsc_percent: selectedLpData.fsc,
      min_Chargable_weight: selectedLpData.minWt,
      fov_amount: selectedLpData.fovCharge,

      orderId: myorderid,
      lpId: myoldlpid,
      shipment_value: myshipmentvalue,
      newLpId: selectedLpId,
      pickuptime: selectedPickupSlot,
      totalAmount: selectedLpData.totalAmount,
      expectedDelivery:
        selectedLpData.expectedDelivery === "NA"
          ? null
          : moment(selectedLpData.expectedDelivery).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
      vTotalAmount: selectedLpData.totalAmountV,
      rate: selectedLpData.rate,
      gst: selectedLpData.gst,
      chargable_weight: selectedLpData.chargeableWt,
      cft: selectedLpData.cft,
      isCod: false,
      docketcharge: selectedLpData.docketCharge,
      fsc_amount: selectedLpData.fscAmount,
      v_Fsc_amount: selectedLpData.fscAmountV,
      greentax: selectedLpData.greenTax,
      greentax_amount: selectedLpData.greenTaxAmount,
      minFreight: selectedLpData.minFreight,
      minFOV: selectedLpData.minFOV,
      fovPercentage: selectedLpData.fovPercentage,
      minODA: selectedLpData.minODA,
      odaPerKG: selectedLpData.odaPerKG,
      oda_amount: selectedLpData.oda,
      handlingCharge: selectedLpData.handlingCharge,
      codAmount: selectedLpData.codAmount,
      taxableAmount: selectedLpData.taxableAmount,
      v_taxableAmount: selectedLpData.taxableAmountV,
      gst_Amount: selectedLpData.gstAmount,
      v_gst_Amount: selectedLpData.gstAmountV,
      deliveryslot: selectedDeliverySlot,
      ratesheetid: selectedLpData.rateSheetId,
    };
    UpdateCustomerLPinAdmin(body).then((res) => {
      if (res.status == 200) {
        toast.success("LP Updated Successfully");
      } else {
        toast.error("Error Occured while updating LP");
      }
      Refreshafterlpdocketupdate();
      setSelectedLpId("");
      setSelectedPickupSlot("");
      setSelectedDeliverySlot("");
      setOpenupdate(false);
    });
  };

  const [updateOpen, setOpenupdate] = React.useState(false);

  const updateClickOpen = () => {
    setOpenupdate(true);
  };

  const updateClose = () => {
    setOpenupdate(false);
    setSelectedLpId("");
    setSelectedPickupSlot("");
    setSelectedDeliverySlot("");
  };

  const AddDiscrepancy = (customerid, order_id, lpid, paymentMode, chargable_weight, total_Amount, discrepancyId, isB2C, noOfArticles) => {
    navigate(`/Discrepancy/${customerid}/${order_id}/${lpid}/${paymentMode}/${chargable_weight}/${total_Amount}/${discrepancyId}/${isB2C}/${noOfArticles}`);
  };
  const ViewDiscrepancy = (customerid, order_id, lpid, discrepancyId) => {
    navigate(`/ViewDiscrepancy/${customerid}/${order_id}/${lpid}/${discrepancyId}`);
  };
  const [orderstatus, setOrderstatus] = useState({
    1: { remarks: "" }, // BOOKED
    2: { remarks: "" }, // IN-TRANSIT
    4: { remarks: "" }, // DELIVERED
    5: { remarks: "" }, // CANCELLED
  });
  const [remarks, setRemarks] = useState("No remarks");
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [status, setStatus] = useState("");
  const [openorder, setOpenOrder] = useState(false);
  const handleClickOpenOrder = () => {
    setOpenOrder(true);
  };
  const handleCloseOrder = () => {
    setOpenOrder(false);
  };
  const [openreassign, setOpenReassign] = React.useState(false);
  const handleClickOpenReassign = () => {
    setOpenReassign(true);
  };
  const handleCloseReassign = () => {
    setOpenReassign(false);
  };

  useEffect(() => {
    if (!showProgress) {
      return;
    }
    const timer = setTimeout(() => {
      setShowProgress(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [showProgress]);

  const [deleteopen, setOpenDelete] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  // const deleteClickOpen = () => {
  //   setOpenDelete(true);
  // };
  // const deleteClose = () => {
  //   setOpenDelete(false);
  // };
  const deleteClickOpen = (orderId) => {
    setOrderToDelete(orderId);
    setOpenDelete(true);
  };

  const deleteClose = () => {
    setOrderToDelete(null);
    setOpenDelete(false);
  };
  const [docketupdatepopupopen, setDocketupdatepopupopen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenDialog = (params) => {
    setSelectedRow(params.row);
    setdocketnumber(params.row.lspDocketNo);
    setDocketupdatepopupopen(true);
  };

  const handleCloseDialog = () => {
    setDocketupdatepopupopen(false);
  };

  const handleUpdateDocketNumber = async () => {
    try {
      const updatedData = {
        orderid: selectedRow.order_id,
        docketno: docketnumber,
      };
      const response = await UpdateDocketNumber(updatedData);

      if (response.ok) {
        setDocketupdatepopupopen(false);
        toast.success("LSP Docket No. Updated Successfully");
        Refreshafterlpdocketupdate();
      } else {
        console.error("Failed to update docket number:", response);
      }
    } catch (error) {
      console.error("Failed to update docket number:", error);
    }
  };
  const Refreshafterlpdocketupdate = () => {
    loadSalesOrder();
  };

  const handleDeleteConfirmation = async () => {
    if (orderToDelete) {
      try {
        const response = await DeleteOrder(orderToDelete);
        if (response.status === 200) {
          toast.success("Order Deleted Successfully");
          Refreshaafterdelete();
          deleteClose();
        } else {
          toast.error("Failed to delete order. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the order.");
        console.error(error);
      }
    }
  };

  const columns = [
    { field: "order_id", headerName: "Order ID ", width: 100 },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 150,
    },
    {
      field: "barcode",
      headerName: "View Details",
      width: 120,
      renderCell: (params) => {
        const order_id = params.row.order_id;
        const customeremail = params.row.customeremail;
        return (
          <Link
            to={{
              pathname: "/BarcodeAdmin",
              search: `?order_id=${order_id}&email=${customeremail}`,
            }}
          >
            <IconButton aria-label="barcode">
              {/* <QrCode2Icon /> */}
              <PreviewIcon />
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "iteminvoice",
      headerName: "Item Invoice",
      width: 120,
      renderCell: (params) => {
        return <InvoiceItems invoicePath={params.row.invoicePath} />;
      },
    },
    {
      field: "podUrl",
      headerName: "POD",
      width: 100,
      renderCell: (params) => {
        return <ProofOfDelivery podUrl={params.row.podUrl} />;
      },
    },
    { field: "invoiceNumber", headerName: "Invoice Number", width: 150 },
    // {
    //   field: "status",
    //   width: 150,
    //   headerName: "Status",
    //   sortable: false,
    //   renderCell: (params) => {
    //    const role = localStorage.getItem("user_role").split(",");
    //     return (
    //       <Button onClick={() => Getsalesorderstatus(params)}>
    //         <Avatar sx={{ width: 40, height: 40, bgcolor: blueGrey[100] }}>
    //           <LocalShippingTwoToneIcon
    //             fontSize="small"
    //             sx={{ color: blueGrey[900] }}
    //           />
    //         </Avatar>
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "update",
      width: 150,
      headerName: "Update",
      sortable: false,
      renderCell: (params) => {
        // const { customerid, order_id, lpid } = params.row;
        // return (
        //   <>
        //     <Button
        //       variant="contained"
        //       size="small"
        //       onClick={() => handleUpdateLP(customerid, order_id, lpid)}
        //     >
        //       Update LP
        //     </Button>
        //   </>
        // );
        const { customerid, order_id, lpid, customeremail } = params.row;
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                handleUpdateLP(customerid, order_id, lpid, customeremail)
              }
            >
              Update LP
            </Button>
          </>
        );
      },
    },
    {
      field: "discrepancy",
      width: 200,
      headerName: "Discrepancy",
      sortable: false,
      renderCell: (params) => {
        const { customerid, order_id, lpid, paymentMode, chargable_weight, total_Amount, discrepancyId, isB2C, noOfArticles } = params.row;
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                AddDiscrepancy(customerid, order_id, lpid, paymentMode, chargable_weight, total_Amount, discrepancyId, isB2C, noOfArticles)
              }
            >
              {discrepancyId != 0 ? "View" : "Raise"}
            </Button>
          </>
        );
      },
    },
    {
      field: "Dispute Discrepancy",
      width: 150,
      headerName: "Dispute Discrepancy",
      sortable: false,
      renderCell: (params) => {
        const { customerid, order_id, lpid, discrepancyId } = params.row;
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                ViewDiscrepancy(customerid, order_id, lpid, discrepancyId)
              }
            >
              View
            </Button>
          </>
        );
      },
    },
    { field: "customername", headerName: "Customer Name", width: 150 },
    { field: "isPickupCreated", headerName: "Pickup Created", width: 100 },
    { field: "isShipmentCreated", headerName: "Shipment Created", width: 100 },
    { field: "customerphone", headerName: "Customer Phone", width: 150 },
    { field: "customeremail", headerName: "Customer Email", width: 150 },
    { field: "vendor_Name", headerName: "LP Name", width: 150 },
    { field: "noOfArticles", headerName: "Articles", width: 150 },
    { field: "itemType", headerName: "Item Type", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "subcategory", headerName: "Item Subcategory", width: 150 },
    { field: "packagingRequired", headerName: "Packaging Type", width: 150 },
    { field: "itemInvoice", headerName: "Customer Invoice No", width: 200 },
    { field: "lspDocketNo", headerName: "LSP Docket Number", width: 200 },
    { field: "prNumber", headerName: "Pickup Request Number", width: 200 },
    {
      field: "action",
      headerName: "Edit Docket Number",
      sortable: false,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <Button onClick={() => handleOpenDialog(params)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
    { field: "itemname", headerName: "Item Name", width: 150 },
    { field: "transporterName", headerName: "Transporter Name", width: 150 },
    { field: "mvikasDocketNo", headerName: "MVikas Docket Number", width: 300 },
    { field: "eWayBillNo", headerName: "E-way Bill Number", width: 300 },
    {
      field: "eWayBillExpDate",
      headerName: "E-way Bill Expiry Date",
      width: 300,
    },
    { field: "transporterName", headerName: "Transporter Name", width: 300 },
    { field: "order_Status", headerName: "Order Status", width: 150 },
    { field: "delivery_Address", headerName: "Delivery Address", width: 300 },
    { field: "delivery_name", headerName: "Delivery Person Name", width: 150 },
    {
      field: "delivery_mobile",
      headerName: "Delivery Person Contact",
      width: 150,
    },
    { field: "pickup_Address", headerName: "Pickup Address", width: 400 },
    { field: "pickup_name", headerName: "Pickup Person Name", width: 300 },
    { field: "pickup_mobile", headerName: "Pickup Person Contact", width: 300 },
    { field: "frompincode", headerName: "From Pin", width: 150 },
    { field: "topincode", headerName: "To Pin", width: 150 },
    { field: "chargable_weight", headerName: "Chargable Wt.", width: 150 },
    { field: "taxableAmount", headerName: "Taxable Amt.", width: 150 },
    { field: "gst_Amount", headerName: "GST Amt.", width: 150 },
    // { field: "total_Amount", headerName: "Total Amount", width: 150 },


    {
      field: "discrepancyCharge",
      headerName: "Discrepancy Amount",
      width: 200,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        return params.value ?? 0; // Display 0 if the value is null or undefined
      }
    },
    {
      field: "total_Amount", headerName: "Total Amount", width: 150, headerAlign: 'center', align: 'center',
      valueGetter: (params) => {
        const discrepancyCharge = params.row.discrepancyCharge ?? 0; // Default to 0 if null or undefined
        return params.row.total_Amount + discrepancyCharge;
      }
    },

    {
      field: "orderassigned",
      headerName: "Order Assigned",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={handleClickOpenOrder}
          >
            Assigned To
          </Button>
        );
      },
    },
    {
      field: "Assignedto",
      headerName: "Assigned To",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            alex@reportgmail.com
            <IconButton onClick={handleClickOpenReassign}>
              <DriveFileRenameOutlineIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "Action",
      width: 150,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          // <Button onClick={deleteClickOpen}>
          // <Button
          //   onClick={() => {
          //     DeleteOrder(params.row.order_id);
          //   }}
          // >
          <Button onClick={() => deleteClickOpen(params.row.order_id)}>
            <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
              <DeleteRoundedIcon fontSize="small" sx={{ color: red[900] }} />
            </Avatar>
          </Button>
        );
      },
    },
  ];


  const DeleteOrder = async (orderId) => {
    const token = localStorage.getItem("Logkey");
    try {
      const response = await fetch(`${Url}DeleteOrder?orderid=${orderId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(orderId),
      });
      const data = await response.json();
      return { status: response.status, data };
    } catch (error) {
      throw error;
    }
  };

  const Refreshaafterdelete = () => {
    loadSalesOrder();
  };

  const InvoiceItems = (props) => {
    const [invoiceopen, setInvoiceOpen] = useState("");
    const handleInvoiceOpen = () => {
      setInvoiceOpen(true);
    };

    const handleInvoiceClose = () => {
      setInvoiceOpen(false);
    };

    const imagedown = (params) => {
      let url = `${CurrentUrl}${props.invoicePath}`;
      saveAs(url, "Order invoice");
    };

    return (
      <Box>
        <IconButton onClick={handleInvoiceOpen}>
          {" "}
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen
          open={invoiceopen}
          onClose={handleInvoiceClose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <iframe
              title=" Item Invoice"
              className="iframe-data"
              width={"100%"}
              height={"100%"}
              src={`${CurrentUrl}${props.invoicePath}`}
            >
              {" "}
            </iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={imagedown}>Download</Button>
            <Button onClick={handleInvoiceClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const ProofOfDelivery = (props) => {
    const [podfileopen, setpodfile] = useState("");
    const handlepoddownOpen = () => {
      setpodfile(true);
    };

    const handlepoddownClose = () => {
      setpodfile(false);
    };

    const podownload = (params) => {
      let url = props.podUrl;
      saveAs(url, "Proof Of Delivery");
    };
    return (
      <Box>
        <IconButton onClick={handlepoddownOpen}>
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen
          open={podfileopen}
          onClose={handlepoddownClose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <iframe
              title="Proof Of Delivery"
              className="iframe-data"
              width={"100%"}
              height={"100%"}
              src={props.podUrl}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={podownload}>Download</Button>
            <Button onClick={handlepoddownClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const Getsalesorderstatus = async (order) => {
    let response = await GetOrderStatusList(order.id).then((r) => {
      return r;
    });

    if (response != null) {
      let orderDetails = {
        isBooked: response.data[0].isBooked,
        isCancelled: response.data[0].isCancelled,
        isDelivered: response.data[0].isDelivered,
        isIntransist: response.data[0].isIntransist,
        isRTO: response.data[0].isRTO,
        orderId: response.data[0].orderId,
        remarks: "",
        email: order.row.customeremail,
        status: status,
      };
      localStorage.setItem("OrderId", orderDetails.orderId);
      setOrderstatus(orderDetails);
      setOpen(true);
    }
  };

  const openStatusPopup = async (status) => {
    const myorderId = localStorage.getItem("OrderId");
    setSelectedStatus(status);
    let response = await GetOrderStatusList(myorderId);
    if (response && response.data && response.data.length > 0) {
      if (status == 1) {
        setRemarks(response.data[0].bookedRemarks);
      } else if (status == 2) {
        setRemarks(response.data[0].intransitRemarks);
      } else if (status == 4) {
        setRemarks(response.data[0].deliveredRemarks);
      } else {
        setRemarks(response.data[0].cancelledRemarks);
      }
    }
    setOpenStatusDialog(true);
  };

  const closeStatusPopup = () => {
    setOpenStatusDialog(false);
    closeStatusPopup();
  };

  const updateStatusRemarks = (status) => {
    callUpdateOrderStatus();
    closeStatusPopup();
  };

  const getOrderStatusRemarksKey = (status) => {
    switch (status) {
      case 1:
        return "bookedRemarks";
      case 2:
        return "intransitRemarks";
      case 4:
        return "deliveredRemarks";
      case 5:
        return "cancelledRemarks";
      default:
        return "";
    }
  };
  const Updatesalesorderstatus = () => {
    setOpen(true);
    toast.success("Updated Successfully");
    callUpdateOrderStatus();
    setOrderstatus({});
  };
  const callUpdateOrderStatus = () => {
    let statuschange = {
      orderId: orderstatus.orderId,
      status: selectedStatus,
      remarks: remarks,
      email: orderstatus.email,
    };
    try {
      var result = UpdateOrderStatus(statuschange);
      toast.success("Updated Successfully");
      setOpen(false);
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const updateOrderStatusFromUI = (OStatus) => {
    if (OStatus === 1) {
      setOrderstatus((val) => ({ ...val, isBooked: true }));
    } else if (OStatus === 2) {
      setOrderstatus((val) => ({ ...val, isIntransist: true }));
    } else if (OStatus === 4) {
      setOrderstatus((val) => ({ ...val, isDelivered: true }));
    } else if (OStatus === 5) {
      setOrderstatus((val) => ({ ...val, isCancelled: true }));
    } else {
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowProgress(true);
  };

  const myParam = useLocation().search;
  const order_status = new URLSearchParams(myParam).get("order_status");
  const customeremail = new URLSearchParams(myParam).get("customeremail");

  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    //loadData();
    loadSalesOrder();
  }, [pageState.page, pageState.pageSize, status, fromDate, toDate]);


  const loadSalesOrder = () => {
    //setData([]);
    setPageState((old) => ({ ...old, isLoading: true }));
    setLoading(true);
    var params = "";
    //pageState.page, pageState.pageSize
    params += "pageNumber=" + pageState.page;
    params += "&pageSize=" + pageState.pageSize;
    params += "&search=" + search;
    params += "&status=" + status;
    params += "&fromdate=" + fromDate;
    params += "&todate=" + toDate;

    GetPageWiseSalesOrder(params)
      .then((result) => {
        setPageState(
          (old) => ({
            ...old,
            isLoading: false,
            data: result.data.customerOrders,
            total: result.data.totalRecords,
          }),
          () => {
            this.props.updateItem(this.state);
          }
        );
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const SearchSalesOrder = () => {
    //setData([]);
    setPageState((old) => ({ ...old, isLoading: true }));
    setLoading(true);
    var params = "";
    params += "pageNumber=" + pageState.page;
    params += "&pageSize=" + pageState.pageSize;
    params += "&search=" + search;
    params += "&status=" + status;
    params += "&fromdate=" + fromDate;
    params += "&todate=" + toDate;

    GetPageWiseSalesOrder(params)
      .then((result) => {
        setPageState(
          (old) => ({
            ...old,
            isLoading: false,
            data: result.data.customerOrders ? result.data.customerOrders : [],
            total: result.data.totalRecords,
          }),
          () => {
            this.props.updateItem(this.state);
          }
        );
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    // SearchSalesOrder();
  };
  const handleBlur = () => {
    SearchSalesOrder();
  };
  const handleStatusChange = (e) => {
    const value = e.target.value;
    setStatus(value);
    SearchSalesOrder();
  };
  const handleFromDateChange = (e) => {
    setfromDate(e.toString());
    SearchSalesOrder();
  };
  const handleToDateChange = (e) => {
    setToDate(e.toString());
    SearchSalesOrder();
  };

  // useEffect(() => {
  //   console.log(" useeffect status", status);
  //     }, [status]);
  // const filteredData = data.filter((row) => {

  //   const isSearchMatch = Object.keys(row).some((key) => {
  //     const value = row[key];

  //     if (value !== null && value !== undefined) {
  //       if (typeof value === 'string' || value instanceof String) {
  //         return value.toLowerCase().includes(search.toLowerCase());
  //       }
  //       return value.toString().includes(search);
  //     }

  //     return false;
  //   });

  //     const orderDate = parseDate(row.orderDate, 'DD-MM-YYYY');
  //     let isDateInRange = true;

  //     if (fromDate) {
  //       const fromDateTime = new Date(fromDate);
  //       fromDateTime.setHours(0, 0, 0, 0);
  //       isDateInRange = isDateInRange && orderDate >= fromDateTime;
  //     }

  //     if (toDate) {
  //       const toDateTime = new Date(toDate);
  //       toDateTime.setHours(23, 59, 59, 999);
  //       isDateInRange = isDateInRange && orderDate <= toDateTime;
  //     }

  //     return isSearchMatch && isDateInRange;
  //   })

  // function parseDate(dateString, format) {
  //   const parts = dateString.split('-').map(Number);
  //   return new Date(parts[2], parts[1] - 1, parts[0]);
  // }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Sales Report</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Sales Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
              <div className="col">
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  value={search}
                  onChange={handleSearchChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    id="Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    onChange={handleStatusChange}
                    labelId="Status"
                    id="Status"
                    fullWidth
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>Status</em>
                    </MenuItem>
                    <MenuItem value={1}>Booked</MenuItem>
                    <MenuItem value={2}>Picked Up</MenuItem>
                    <MenuItem value={3}>In-Transit</MenuItem>
                    <MenuItem value={4}>Delievered</MenuItem>
                    <MenuItem value={5}>RTO</MenuItem>
                    <MenuItem value={6}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={toDate}
                    inputFormat="DD/MM/YYYY"
                    onChange={handleToDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className="">
                <IconButton
                  onClick={SearchSalesOrder}
                  size="small"
                  className="search-icon"
                >
                  <ManageSearchIcon />
                </IconButton>
              </div>

            </Card>
            <Card className="custum-shadow mt-3">
              {loading ? (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p>Loading...</p>
                </div>
              ) : (
                <DataGrid
                  sx={{
                    p: "1rem",
                    "@media print": {
                      ".table-ui": { border: "none" },
                    },
                  }}
                  className="table-ui"
                  pagination
                  disableColumnMenu
                  disableDensitySelector
                  getRowId={(row) => row.sno ?? row.order_id}
                  components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay,
                    NoResultsOverlay,
                  }}
                  autoHeight={true}
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[10, 25, 50, 70, 100]}
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPageState(
                      (old) => ({ ...old, page: newPage + 1 }),
                      () => {
                        this.props.updateItem(this.state);
                      }
                    );
                  }}
                  onPageSizeChange={(newPageSize) =>
                    setPageState(
                      (old) => ({ ...old, pageSize: newPageSize }),
                      () => {
                        this.props.updateItem(this.state);
                      }
                    )
                  }
                  columns={columns}
                />
              )}
            </Card>

            {/* Dialog for updating docket number */}
            <Dialog open={docketupdatepopupopen} onClose={handleCloseDialog}>
              {/* <DialogTitle>Update Docket Number</DialogTitle> */}
              <DialogContent>
                <TextField
                  fullWidth
                  label="LSP Docket Number"
                  value={docketnumber}
                  onChange={(e) => setdocketnumber(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleUpdateDocketNumber}>Update</Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for updating status */}
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="xs"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Order Status"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="1"
                            defaultChecked={
                              orderstatus?.isBooked ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(1);
                            }}
                          />
                        }
                        label="Booked"
                      />
                      {orderstatus?.isBooked == true && (
                        <Button
                          onClick={() => openStatusPopup(1)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="2"
                            defaultChecked={
                              orderstatus?.isIntransist ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(2);
                            }}
                          />
                        }
                        label="In-Transit"
                      />
                      {orderstatus?.isIntransist == true && (
                        <Button
                          onClick={() => openStatusPopup(2)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="4"
                            defaultChecked={
                              orderstatus?.isDelivered ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(4);
                            }}
                          />
                        }
                        label="Delivered"
                      />
                      {/* {orderstatus?.isDelivered == true &&
                        orderstatus?.isBooked == true &&
                        orderstatus?.isIntransist == true && (
                          <Button
                            onClick={() => openStatusPopup(4)}
                            className="ml-auto"
                            variant="text"
                          >
                            Remarks
                          </Button>
                        )} */}
                      {orderstatus?.isDelivered == true && (
                        <Button
                          onClick={() => openStatusPopup(4)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="5"
                            defaultChecked={
                              orderstatus?.isCancelled == true ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(5);
                            }}
                          />
                        }
                        label="Cancelled"
                      />
                      {orderstatus?.isCancelled == true && (
                        <Button
                          onClick={() => openStatusPopup(5)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                    {/* <Button onClick={() => {Updatesalesorderstatus();}} variant="contained">
          Update
        </Button> */}
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>

            {/* Order Assigned */}
            <Dialog
              maxWidth="xs"
              open={openorder}
              onClose={handleCloseOrder}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Order Assigned to"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ minWidth: "250px" }}
                >
                  <FormControl fullWidth className="mt-2">
                    <InputLabel id="email-select-label">Email</InputLabel>
                    <Select labelId="email-select-label" label="Email">
                      <MenuItem value={10}>name1@gmail.com</MenuItem>
                      <MenuItem value={20}>name2@gmail.com</MenuItem>
                      <MenuItem value={30}>name3@gmail.com</MenuItem>
                    </Select>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseOrder}>Close</Button>
                <Button onClick={handleCloseOrder} autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            {/* Order Assigned */}
            <Dialog
              maxWidth="xs"
              open={openreassign}
              onClose={handleCloseReassign}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Order Reassign"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ minWidth: "250px" }}
                >
                  <FormControl fullWidth className="mt-2">
                    <InputLabel id="email-select-label">Email</InputLabel>
                    <Select
                      labelId="email-select-label"
                      label="Email"
                      defaultValue={30}
                    >
                      <MenuItem value={10}>name1@gmail.com</MenuItem>
                      <MenuItem value={20}>name2@gmail.com</MenuItem>
                      <MenuItem value={30}>name3@gmail.com</MenuItem>
                    </Select>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseReassign}>Close</Button>
                <Button onClick={handleCloseReassign} autoFocus>
                  Update
                </Button>
              </DialogActions>
            </Dialog>
            {/*Add remarks dialog */}
            <Dialog
              open={openStatusDialog}
              onClose={closeStatusPopup}
              maxWidth="xs"
              aria-describedby="status-dialog-description"
            >
              <DialogTitle>Remarks</DialogTitle>
              <DialogContent>
                <DialogContentText id="status-dialog-description">
                  <TextField
                    fullWidth
                    label="Remarks"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    multiline
                    style={{
                      margin: "15px 0",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* <TextField
              fullWidth
              label="Remarks"
              value={savedRemarks}
              multiline
              style={{ margin: "15px 0" }}
              InputLabelProps={{ shrink: true }}
            /> */}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeStatusPopup}>Close</Button>
                <Button onClick={updateStatusRemarks} color="primary">
                  Save
                </Button>
                {/* <Button
            onClick={() => {
              Updatesalesorderstatus();
            }}
            variant="contained"
          >
            Update
          </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        </section>
      </div>
      {showProgress && (
        <div className="circular-progress-ui">
          <CircularProgress />
        </div>
      )}

      {/*  */}
      <ToastContainer />
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={deleteopen}
        onClose={deleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="delete-alert">
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to delete the order?</p>
            <Button onClick={handleDeleteConfirmation} variant="contained">
              Yes
            </Button>
            <Button
              onClick={deleteClose}
              autoFocus
              variant="outlined"
              className="ml-2"
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth open={updateOpen} onClose={updateClose}>
        <DialogTitle>{"Select Logistics Partner and Slots"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lp-select-label">
              Select Logistics Partner
            </InputLabel>
            <Select
              id="lp-select"
              label="Select Logistics Partner"
              value={`${selectedLpId}-${selectedServiceId}`}
              onChange={handleLpSelection}
            >
              {getratedata.map((lp) => (
                <MenuItem key={`${lp.lpId}-${lp.serviceId}`} value={`${lp.lpId}-${lp.serviceId}`}>
                  {`${lp.lpName} - ${lp.serviceName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl fullWidth className="mt-2">
          <InputLabel id="lp-select-label">Select Logistics Partner</InputLabel>
          {loading ? (
            // Render a loading message while data is being fetched
            <Select
              labelId="lp-select-label"
              id="lp-select"
              label="Select Logistics Partner"
              value=""
              onChange={handleLpSelection}
              disabled
            >
              <MenuItem value="">
                Please wait while loading...
              </MenuItem>
            </Select>
          ) : (
            // Render the actual Select component with data
            <Select
              labelId="lp-select-label"
              id="lp-select"
              label="Select Logistics Partner"
              value={selectedLp}
              onChange={handleLpSelection}
            >
              {getratedata.map((lp) => (
                <MenuItem key={lp.lpId} value={lp.lpId}>
                  {lp.lpName}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl> */}
          {/* )} */}
          {selectedLpId && (
            <>
              {loadingTat && (
                <div className="circular_progress">
                  <CircularProgress />
                </div>
              )}
              {lptatadata && !loadingTat && (
                <p className="data-text">
                  <span>
                    Sales Price: <b>{lptatadata.priceSale}</b>
                  </span>
                  <span>
                    Purchase Price: <b>{lptatadata.pricePurchase}</b>
                  </span>
                  <span>
                    TAT: <b>{lptatadata.tat}</b>
                  </span>
                </p>
              )}
              <FormControl fullWidth className="mt-3">
                <InputLabel id="pickup-slot-label">
                  Select Pickup Slot
                </InputLabel>
                <Select
                  labelId="pickup-slot-label"
                  id="pickup-slot-select"
                  value={selectedPickupSlot}
                  onChange={(event) =>
                    setSelectedPickupSlot(event.target.value)
                  }
                >
                  {pickupSlots.map((pickupSlot) => (
                    <MenuItem key={pickupSlot.sno} value={pickupSlot.slotvalue}>
                      {pickupSlot.slotvalue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth className="mt-3">
                <InputLabel id="delivery-slot-label">
                  Select Delivery Slot
                </InputLabel>
                <Select
                  labelId="delivery-slot-label"
                  id="delivery-slot-select"
                  value={selectedDeliverySlot}
                  onChange={(event) =>
                    setSelectedDeliverySlot(event.target.value)
                  }
                >
                  {deliverySlots.map((deliverySlot) => (
                    <MenuItem
                      key={deliverySlot.sno}
                      value={deliverySlot.slotvalue}
                    >
                      {deliverySlot.slotvalue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={updateClose}>Close</Button>
          <Button variant="contained" onClick={handleupdatelpfromorders}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
