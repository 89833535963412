import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import {
  GetOrderItemsListofAdmin,
  UpdateOrderItemStatus,
  downloadDocketcustomerinAdmin,
  GetSaleOrderList,
  GenerateAdminDocket,
  UploadPODFile,
  GetStatusTypeList,
  GetBulkOrderStatus,
  UpdateBulkStatus,
} from "../../Services/AdminApi";
import { saveAs } from 'file-saver';
import { useLocation } from "react-router";
import QRCode from "qrcode.react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { toast, ToastContainer } from "react-toastify";
import "./pdfdocketadmin.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { CurrentUrl } from "../../Services/UrlApi";

import axios from "axios";

export default function BarcodeAdmin() {
  const [status, setStatus] = useState("");
  const [apiloadingtext, setapiloadingtext] = useState(false)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [docketdata, setdocketdata] = useState([]);
  const [docketpdfdata, setdocketpdfdata] = useState([]);
  const [itemorderlist, setitemorderlist] = useState([]);
  const [itemid, setitemid] = useState("");
  const [qrCodeText, setQRCodeText] = useState("");
  const [isCheckbocChecked, setisCheckbocChecked] = useState(false);
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [invoicefile, setInvoicefile] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [Uploadopen, UploadOpenset] = React.useState(false);
  const [proofOfDelivery, setProofOfDelivery] = useState('');
  const [delayReason, setDelayReason] = useState('');
  const [showDelayReasonInput, setShowDelayReasonInput] = useState(false);
  const [delayReasonEntered, setDelayReasonEntered] = useState(false);
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[3]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const customeremail = searchParams.get("email");
  const myParam = useLocation().search;
  const order_Status = new URLSearchParams(myParam).get("order_Status");
  const [downloadLink, setDownloadLink] = useState(null);

  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [myetadata, setmyetadata] = useState("")
  const [delayHours, setDelayHours] = useState(null);
  const [statusTypes, setStatusTypes] = useState([]);
  const [loadingspinner, setLoadingspinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedStatusRemarks, setSelectedStatusRemarks] = useState([]);

  const mystatustypes = async (itemid) => {
    try {
      const response = await GetStatusTypeList(itemid);
      setStatusTypes(response.statusTypes);
      setmyetadata(response.eta)
      setitemid(itemid);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching status types:', error);
    }
  };


  // "2/2/2024 12:00:00 AM" format.
  // const parseApiDate = (apiDate) => {
  //   if (!apiDate) {
  //     console.error('Invalid or missing date string:', apiDate);
  //     return null; // Handle invalid or missing date string
  //   }

  //   // Split the API date string into date and time parts
  //   const [datePart, timePart] = apiDate.split(' ');

  //   if (!datePart || !timePart) {
  //     console.error('Invalid date format in API response:', apiDate);
  //     return null; // Handle invalid date format
  //   }

  //   // Split the date part into month, day, and year
  //   const [month, day, year] = datePart.split('/');

  //   // Split the time part into hour, minute, second, and AM/PM
  //   const [time, ampm] = timePart.split(' ');

  //   // Split the time into hour, minute, and second
  //   const [hour, minute, second] = time.split(':');

  //   // Convert 12-hour time to 24-hour time if necessary
  //   const hour24 = ampm === 'PM' ? (parseInt(hour, 10) + 12) % 24 : parseInt(hour, 10);

  //   // Create a new date object
  //   const formattedDate = new Date(year, month - 1, day, hour24, minute, second);

  //   // Return the parsed Date object
  //   return formattedDate;
  // };
  //format "27-01-2024 00:00:00"
  // const parseApiDate = (apiDate) => {
  //   if (!apiDate) {
  //     console.error('Invalid or missing date string:', apiDate);
  //     return null; // Handle invalid or missing date string
  //   }

  //   // Split the API date string into date and time parts
  //   const [datePart, timePart] = apiDate.split(' ');

  //   if (!datePart || !timePart) {
  //     console.error('Invalid date format in API response:', apiDate);
  //     return null; // Handle invalid date format
  //   }

  //   // Split the date part into day, month, and year
  //   const [day, month, year] = datePart.split('-');

  //   // Split the time part into hour, minute, and second
  //   const [hour, minute, second] = timePart.split(':');

  //   // Create a new date object
  //   const formattedDate = new Date(year, month - 1, day, hour, minute, second);

  //   // Return the parsed Date object
  //   return formattedDate;
  // };
  //for both formats
  const parseApiDate = (apiDate) => {
    if (!apiDate) {
      console.error('Invalid or missing date string:', apiDate);
      return null; // Handle invalid or missing date string
    }

    // Check if the date string matches the first format "MM/DD/YYYY HH:MM:SS AM/PM"
    const matchMDYFormat = apiDate.match(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)$/);
    if (matchMDYFormat) {
      const [, month, day, year, hour, minute, second, ampm] = matchMDYFormat;
      const hour24 = ampm === 'PM' ? (parseInt(hour, 10) + 12) % 24 : parseInt(hour, 10);
      return new Date(year, month - 1, day, hour24, minute, second);
    }

    // Check if the date string matches the second format "DD-MM-YYYY HH:MM:SS"
    const matchDMYFormat = apiDate.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/);
    if (matchDMYFormat) {
      const [, day, month, year, hour, minute, second] = matchDMYFormat;
      return new Date(year, month - 1, day, hour, minute, second);
    }

    // Log an error for an unrecognized format
    console.error('Unrecognized date format in API response:', apiDate);
    return null;
  };


  const handleCheckboxChange = (checkedStatus) => {
    setSelectedStatus(checkedStatus);
    setSelectedStatusRemarks((prev) => {
      const existingStatusIndex = prev.findIndex(
        (status) => status.statusTypeId === checkedStatus.statusTypeId
      );
      if (existingStatusIndex !== -1) {
        const updatedStatuses = [...prev];
        updatedStatuses[existingStatusIndex] = {
          ...checkedStatus,
          remarks: remarks || "No remarks",
        };
        // const updatedStatus = {
        //   ...checkedStatus,
        //   remarks: remarks || (updatedStatuses[existingStatusIndex].remarks || "No remarks"),
        // };

        if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
          setDelayReason('');
          setShowDelayReasonInput(false);
          setDelayHours(null);
        }
        // else {
        //   setShowDelayReasonInput(false); // Hide delay reason input for other statuses
        //   setDelayHours(null); // Reset delay hours for other statuses
        // }
        // updatedStatuses[existingStatusIndex] = updatedStatus;
        return updatedStatuses;
      } else {

        if (checkedStatus.statusTypeName === 'Delivered') {
          const etaDate = parseApiDate(myetadata).getTime();
          const currentDate = new Date().getTime();
          if (!isNaN(etaDate)) {
            if (etaDate < currentDate) {
              setShowDelayReasonInput(true);
              const delayMilliseconds = currentDate - etaDate;
              const delayHours = delayMilliseconds / (1000 * 60 * 60);
              setDelayHours(delayHours);
            } 
            // else {
            //   setDelayReason('');
            //   setShowDelayReasonInput(false);
            //   setDelayHours(null);
            // }
          } else {
            console.error('Invalid date format in API response:', myetadata);
          }
        }
        else {
          setShowDelayReasonInput(false);
          setDelayHours(null);
        }
        return [
          ...prev,
          {
            ...checkedStatus,
            remarks: remarks || "No remarks",
          },
        ];
      }
    });
  };

  const openStatusPopup = (statusTypeId) => {
    const myselectedStatus = statusTypes.find((status) => status.statusTypeId === statusTypeId);
   const existingStatusIndex = selectedStatusRemarks.findIndex(
      (status) => status.statusTypeId === statusTypeId
    );

    if (existingStatusIndex === -1) {
      // If not, add the status to selectedStatusRemarks with default remarks
      setSelectedStatusRemarks((prev) => [
        ...prev,
        {
          ...myselectedStatus,
          remarks: "No remarks",
        },
      ]);
    }
    
    setSelectedStatus(myselectedStatus);
    setOpenStatusDialog(true);
   
  };
  const closeStatusPopup = () => {
    setOpenStatusDialog(false);
    setRemarks("");
    setSelectedStatus(null);
    setSelectedStatusRemarks([]);
    setShowDelayReasonInput(false);
    setDelayHours(null);
  };
  const updateStatusRemarks = (status) => {
    callUpdateOrderStatus();
    setSelectedStatus(null);
    closeStatusPopup();
  };
  const callUpdateOrderStatus = async () => {
    try {
      if (selectedStatusRemarks.length === 0) {
        toast.error("Please select an order status to update");
        console.error("No status selected");
        return;
      }
      setLoadingspinner(true)
      const statusChanges = {
        itemId: itemid,
        // status: selectedStatusRemarks.map((status) => ({
        //   status: status.statusTypeId,
        //  // remarks: remarks || "No remarks",
        //  remarks: remarks || "No remarks",
        // })),
        status: [{
          status: selectedStatus.statusTypeId,
          remarks: remarks || "No remarks",
        }],
        email: customeremail,
        delayReason: delayReason,
      };
      const result = await UpdateOrderItemStatus(statusChanges);
      console.log("Result", result);
      if (result.code === 200) {
        toast.success("Updated Successfully");
        GetOrderItemsListofAdmin(order_id).then((response) => {
          setitemorderlist(response);
          setitemid(response[0].itemId);          
        });
        setOpen(false);
        setSelectedStatus(null);
        setRemarks("");
        setDelayReason("")
        setShowDelayReasonInput(false);
        setDelayHours(null);
      } else {
        console.error("Update failed:", result.message);
        toast.error("Update Failed");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Error");
    }
    finally {
      setLoadingspinner(false)
      setShowDelayReasonInput(false);
      setDelayHours(null);
    }
  };


  const [bulkstatusopen, setbulkstatusopen] = useState(false);
  const [bulkstatusdata, setbulkstatusdata] = useState([])
  const [bulkstatusTypes, setbulkStatusTypes] = useState([]);
  const [openbulkStatusDialog, setOpenBulkStatusDialog] = useState(false);
  const [bulkorderremarks, setBulkOrderRemarks] = useState("");
  const [selectedbulkStatusRemarks, setSelectedbulkStatusRemarks] = useState([]);
  const [loadingbulkspinner, setLoadingbulkspinner] = useState(false);
  const [selectedbulkStatus, setSelectedbulkStatus] = useState();
  const handleClickbulkstatusopen = () => {
    setbulkstatusopen(true);
  };
  const handlebulkstatusClose = () => {
    setbulkstatusopen(false);
  };
  const handlebulkstatustypes = async () => {
    try {
      const response = await GetBulkOrderStatus(order_id);
      console.log("Response", response);

      if (response.code === 200) {
        // Successful response
        setbulkstatusdata(response);
        setbulkStatusTypes(response.statusTypes);
        setbulkstatusopen(true);
      } else {
        // Handle error response
        console.error('Error fetching status types:', response.message);
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error fetching status types:', error);
      toast.error('Error fetching status types. Please try again.');
    }
  };


  const handlebulkCheckboxChange = (checkedStatus) => {
    console.log("Checking status", checkedStatus);
    setSelectedbulkStatus(checkedStatus);
    setSelectedbulkStatusRemarks((prev) => {
      const existingbulkStatusIndex = prev.findIndex(
        (status) => status.statusTypeId === checkedStatus.statusTypeId
      );
      if (existingbulkStatusIndex !== -1) {
        const updatedbulkStatuses = [...prev];
        updatedbulkStatuses[existingbulkStatusIndex] = {
          ...checkedStatus,
          remarks: bulkorderremarks || "No remarks",
        };
        return updatedbulkStatuses;
      } else {
        return [
          ...prev,
          {
            ...checkedStatus,
            remarks: bulkorderremarks || "No remarks",
          },
        ];
      }
    });
  };


  const openbulkStatusPopup = (statusTypeId) => {
    console.log("passed status type id: " + statusTypeId);
    const myselectedbulkStatus = bulkstatusTypes.find((status) => status.statusTypeId === statusTypeId);
    // Check if the status is already in selectedStatusRemarks
    const existingbulkStatusIndex = selectedbulkStatusRemarks.findIndex(
      (status) => status.statusTypeId === statusTypeId
    );

    if (existingbulkStatusIndex === -1) {
      // If not, add the status to selectedStatusRemarks with default remarks
      setSelectedbulkStatusRemarks((prev) => [
        ...prev,
        {
          ...myselectedbulkStatus,
          remarks: "No remarks",
        },
      ]);
    }
    setSelectedbulkStatus(myselectedbulkStatus);
    setOpenBulkStatusDialog(true);
  };
  // const closebulkStatusPopup = () => {
  //   setOpenBulkStatusDialog(false);
  //   setBulkOrderRemarks("");
  // };

  const closebulkStatusPopup = () => {
    setOpenBulkStatusDialog(false);
    setBulkOrderRemarks("");
    // Clear selectedStatus when the dialog is closed
    setSelectedbulkStatus(null);
    // Clear selectedStatusRemarks when the dialog is closed
    setSelectedbulkStatusRemarks([]);
  };
  const updatebulkStatusRemarks = (status) => {
    callUpdatebulkOrderStatus();
    setSelectedbulkStatus(null)
    closebulkStatusPopup();
  };
  // const callUpdatebulkOrderStatus = async () => {
  //   debugger;
  //   try {
  //     if (selectedbulkStatusRemarks.length === 0) {
  //       console.error("No status selected");
  //       return;
  //     }
  //     setLoadingbulkspinner(true)
  //    let mybulkstatusbody = selectedbulkStatusRemarks[0]; // Assuming you want the first object in the array
  //     console.log("mybulkstatusbody", mybulkstatusbody);
  //  let mystatustypeid = mybulkstatusbody.statusTypeId;
  //     console.log("mystatustypeid", mystatustypeid);

  //     let mystatusname = mybulkstatusbody.statusTypeName;
  //     console.log("mystatusname", mystatusname);
  //     const statusChanges = {
  //       orderId: order_id,
  //       email: customeremail,
  //       remarks: bulkorderremarks || "No remarks",
  //       //statusDate: 2024-01-05T16:13:12.860Z,
  //       statusTypeId: mystatustypeid,
  //       status: mystatusname,

  //     }
  //     console.log("my remarks added body", statusChanges);
  //     const result = await UpdateBulkStatus(statusChanges);
  //     console.log("Result", result);
  //     if (result.code === 200) {
  //       toast.success("Updated Successfully");
  //       GetOrderItemsListofAdmin(order_id).then((response) => {
  //         setitemorderlist(response);
  //         setitemid(response[0].itemId);
  //       });
  //       setbulkstatusopen(false);
  //       setBulkOrderRemarks("");
  //     } else {
  //       console.error("Update failed:", result.message);
  //       toast.error("Update Failed");
  //     }
  //   } catch (error) {
  //     console.error("API error:", error);
  //     toast.error("API Error");
  //   }
  //   finally {
  //     setLoadingbulkspinner(false)
  //   }
  // };

  const callUpdatebulkOrderStatus = async () => {
    try {
      if (selectedbulkStatusRemarks.length === 0) {
        toast.error("Please select an order status to update");
        console.error("No status selected");
        return;
      }
      setLoadingbulkspinner(true)
      const lastSelectedStatusTypeId = selectedbulkStatusRemarks[selectedbulkStatusRemarks.length - 1].statusTypeId;
      const statusChanges = {
        orderId: order_id,
        statusDate: new Date(),
        email: customeremail,
        remarks: bulkorderremarks || "No remarks",
        statusTypeId: lastSelectedStatusTypeId,
      };
      console.log("Request body", statusChanges);
      const result = await UpdateBulkStatus(statusChanges);
      console.log("Result", result);
      if (result.status == 200) {
        toast.success(" Bulk Order Updated Successfully");
        GetOrderItemsListofAdmin(order_id).then((response) => {
          setitemorderlist(response);
          setitemid(response[0].itemId);
        });
        setbulkstatusopen(false);
        setBulkOrderRemarks("");
      } else {
        console.error("Update failed:", result.message);
        toast.error("Update Failed");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("API Error");
    }
    finally {
      setLoadingbulkspinner(false)
    }
  };











  function pdfhandle(params) {
    if (params.row && params.row.itemId) {
      setQRCodeURL(params.row.itemId);
      setdocketpdfdata(params.row);
      setTimeout(() => {
        GeneratePdf();
      }, 2000);
    } else {
      console.error('Item ID is missing or undefined for the selected row.');
    }
  }
  function GeneratePdf() {
    try {
      html2canvas(document.querySelector("#pdf-content"), {
        useCORS: true,
      }).then(function (canvas) {
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = function () {
          let pdf = new jsPDF("p", "mm", [297, 210]);
          pdf.addImage(
            img,
            0,
            0,
          );
          pdf.save("Mydocket.pdf");
        };
      });
    } catch (e) {
      console.log(e);
    }
    setdocketpdfdata({});
    setQRCodeURL("");
  }
  const UploadOpenclick = () => {
    UploadOpenset(true);
  };
  const UploadCloseclick = () => {
    UploadOpenset(false);
  };
  const handleUploadProofOfDelivery = async () => {
    let podUrl = proofOfDelivery
    try {
      const responseofpodfile = await UploadPODFile(order_id, podUrl)
      if (responseofpodfile.status === 200) {
        toast.success("Uploaded Successfully");
        UploadOpenset(false);
      }
      console.log("responseofpodfile", responseofpodfile);
    } catch (error) {
      console.error('Error uploading proof of delivery:', error);
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRemarks("");
    setDelayReason("")
    // Clear selectedStatus when the dialog is closed
    setSelectedStatus(null);
    // Clear selectedStatusRemarks when the dialog is closed
    setSelectedStatusRemarks([]);
    setShowDelayReasonInput(false);
    setDelayHours(null);
  };
  const generateQRCode = async (barcode) => {
    await setQRCodeText(barcode);
  };
  const downloadQRCode = (elementId) => {
    const qrCodeCanvas = document.getElementById(elementId);

    if (qrCodeCanvas) {
      const qrCodeURL = qrCodeCanvas.toDataURL("image/png");
      //console.log("my qrcodeurl", qrCodeURL);
      // const qrCodeDataURL = qrCodeCanvas.toDataURL("image/png");
      setDownloadLink(qrCodeURL);
      setQRCodeURL(qrCodeURL);
      setTimeout(() => {
        const downloadEl = document.createElement("a");
        downloadEl.href = qrCodeURL;
        downloadEl.download = "QR_Code.png";
        downloadEl.click();
        setDownloadLink(null);
      }, 100);
    }
  };
  // const [openreassign, setOpenReassign] = React.useState(false);
  // const handleClickOpenReassign = () => {
  //   setOpenReassign(true);
  // };
  useEffect(() => {
    // loadData();
    GenerateAdminDocket(order_id).then((response) => {
      setdocketdata(response);
    });
  }, []);
  const loadData = () => {
    setData([]);
    setLoading(true);
    if (order_Status) {
      setStatus(order_Status);
    }

    GetSaleOrderList()
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    { field: "orderid", headerName: "Order Id", width: 100 },
    { field: "itemId", headerName: "Item ID ", width: 110 },
    { field: "length", headerName: "Length", width: 80 },
    { field: "breadth", headerName: "Breadth ", width: 80 },
    { field: "height", headerName: "Height", width: 80 },
    { field: "itemStatus", headerName: "Item Status", width: 100 },

    {
      field: "Assignedto",
      headerName: "Order Status",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => mystatustypes(params.row.itemId)}>
              <ShareLocationIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "download",
      width: 80,
      headerName: "Label",
      // flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <SimCardDownloadIcon />
          </IconButton>
        );
      },
    },
    // { field: "podUrl", headerName: "Proof of Delivery", width: 200 },
    { field: "delayReason", headerName: "Delay Reason", width: 300 },
  ];
  useEffect(() => {
    GetOrderItemsListofAdmin(order_id).then((response) => {
      setitemorderlist(response);
      setitemid(response[0].itemId);
    });
  }, []);
  const handleDownloadDocket = async () => {
    setapiloadingtext(true)
    //console.log("my order id", order_id);
    const response = await downloadDocketcustomerinAdmin(order_id);

    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, 'docket.pdf');
      setapiloadingtext(false)

    } else {
      console.error('Failed to download docket');
    }
  };
  function LoadServerImage(partialpath) {
    let serverUrl = CurrentUrl.slice(0, -1);
    return serverUrl + partialpath;
  }
  const proofofdeliveryfile = (e) => {
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);
    axios
      .post(`${CurrentUrl}api/Payment/InvoiceImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("my invoice response", response);
        setProofOfDelivery(response.data.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Barcode</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Barcode</li>
              </ol>

            </div>
            <div className="d-flex">
              {/* <div>
                <TextField
                  label="Proof of Delivery"
                  type="file"
                  value={proofOfDelivery.data}
                  fullWidth
                  margin="normal"
                  onChange={proofofdeliveryfile}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div> */}


              <div className="ms-auto" >
                <Button variant="contained"
                  className="mr-2"
                  size="small"
                  color="primary"
                  onClick={handlebulkstatustypes} >
                  Upload Bulk Status
                </Button>
                <Button variant="contained"
                  className="mr-2"
                  size="small"
                  color="primary" onClick={UploadOpenclick} >
                  Upload POD
                </Button>
                <Button
                  onClick={handleDownloadDocket}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  {apiloadingtext ? 'Downloading...' : 'Download Master Docket'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow mt-3">
            <DataGrid
              sx={{ p: "1rem" }}
              className="table-ui"
              rows={itemorderlist}
              columns={columns}
              components={{}}
              getRowId={(row) => row.id ?? row.itemId}
              autoHeight={true}
              height={200}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

      <ToastContainer />

      {/* Proof of Delivery file upload dialog */}
      <Dialog
        open={Uploadopen}
        onClose={UploadCloseclick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Proof of Delivery"}
        </DialogTitle>
        <DialogContent>
          <input type="file"
            value={proofOfDelivery.data}
            onChange={proofofdeliveryfile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={UploadCloseclick}>Close</Button>
          <Button autoFocus variant="contained"
            size="small"
            onClick={handleUploadProofOfDelivery}
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Item status update dialog box */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Order Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {statusTypes.map((status) => (
              <div key={status.statusTypeId} style={{ minWidth: '400px' }}>
                <div className="order-list">
                  <div className="checkbox-date d-flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            console.log('Checkbox changed:', e.target.checked);
                            handleCheckboxChange(status);
                          }}
                          defaultChecked={status.isChecked}
                          disabled={status.isChecked === true}
                        />
                      }
                      label={status.statusTypeName}
                    />
                    {status.statusTypeName === 'Delivered' && status.statusTypeId === 4 && delayHours !== null && (
                      <div style={{
                        color: 'red',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                        Delayed by: {delayHours.toFixed(2)} hours
                      </div>
                    )}

                    <Button
                      className="ml-auto"
                      variant="text"
                      onClick={() => {

                        openStatusPopup(status.statusTypeId);

                      }}
                    >
                      Remarks
                    </Button>

                  </div>

                  {status.statusTypeName === 'Delivered' && status.isChecked === false &&
                    showDelayReasonInput &&
                    !delayReasonEntered && (
                      <div>
                        <TextField
                          label="Delay Reason"
                          value={delayReason}
                          disabled={status.statusTypeId === 4 && status.isChecked === true || delayReasonEntered}
                          onChange={(e) => setDelayReason(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    )}
                </div>
              </div>


            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button disabled={loadingspinner} onClick={callUpdateOrderStatus} color="primary">
            {loadingspinner ? (
              <CircularProgress size={20} thickness={5} color="inherit" />
            ) : (
              'Save'
            )}
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/*     Add remarks dialog */}
      <Dialog
        open={openStatusDialog}
        onClose={closeStatusPopup}
        maxWidth="sm"
        aria-describedby="status-dialog-description"
      >
        <DialogTitle>Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText id="status-dialog-description">
            <div style={{ minWidth: '400px' }}>
              <ul style={{ paddingLeft: "18px" }}>
                {selectedStatus && selectedStatus.remarks.map((remark, index) => (
                  <li className="mb-2"
                    key={index}
                    style={{ wordWrap: 'break-word' }}
                  >
                    {remark.remarks}
                  </li>
                ))}
              </ul>
              <TextField
                fullWidth
                placeholder="Enter your remarks"
                label="Remarks"
                //value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                multiline
                style={{
                  margin: "15px 0",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStatusPopup}>Close</Button>
          <Button onClick={updateStatusRemarks} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Order Update Status Dialog box */}
      <Dialog
        open={bulkstatusopen}
        onClose={handlebulkstatusClose}
        // maxWidth="sm"
        maxWidth="xs" 
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Bulk Order Status"}</DialogTitle>
        <DialogContent >
          <DialogContentText   id="alert-dialog-slide-description">
            {bulkstatusTypes.map((status) => (
              // <React.Fragment key={status.statusTypeId}>

              <div  key={status.statusTypeId} style={{ minWidth: '200px' }}>
                <div className="order-list">
                  <div className="checkbox-date d-flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            console.log('Checkbox changed:', e.target.checked);
                            handlebulkCheckboxChange(status);
                          }}
                          defaultChecked={status.isChecked}
                        />

                      }
                      label={status.statusTypeName}
                    />
                    <Button
                      className="ml-auto"
                      variant="text"
                      onClick={() => openbulkStatusPopup(status.statusTypeId)}
                    >
                      Remarks
                    </Button>
                  </div>
                </div>
              </div>
              // </React.Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingbulkspinner} onClick={callUpdatebulkOrderStatus} color="primary">
            {loadingbulkspinner ? (
              <CircularProgress size={20} thickness={5} color="inherit" />
            ) : (
              'Save'
            )}
          </Button>
          <Button onClick={handlebulkstatusClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* Bulk Status Remarks Dialog */}
      <Dialog
        open={openbulkStatusDialog}
        onClose={closebulkStatusPopup}
        maxWidth="sm"
        aria-describedby="status-dialog-description"
      >
        <DialogTitle>Bulk Order Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText id="status-dialog-description">
            <div style={{ minWidth: '400px' }}>
              <ul style={{ paddingLeft: "18px" }}>
                {selectedbulkStatus && selectedbulkStatus.remarks.map((remark, index) => (
                  <li className="mb-2"
                    key={index}
                    style={{ wordWrap: 'break-word' }}
                  >
                    {remark.remarks}
                  </li>
                ))}
              </ul>
              <TextField
                fullWidth
                placeholder="Enter your remarks"
                label="Remarks"
                onChange={(e) => setBulkOrderRemarks(e.target.value)}
                multiline
                style={{
                  margin: "15px 0",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closebulkStatusPopup}>Close</Button>
          <Button onClick={updatebulkStatusRemarks} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {
        docketpdfdata && docketpdfdata.itemId ? (
          <div id="pdf-content">
            <div
              className="page"
              style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
            >
              <table
                style={{
                  width: "100%",
                  border: "2px solid #000",
                  borderSpacing: "0px",
                  fontFamily: "DM Sans, Arial, Helvetica, sans-serif",
                  fontSize: "14px",
                }}
              >
                <tbody>
                  <tr style={{ verticalAlign: "middle" }}>
                    <td
                      style={{
                        borderRight: "0px solid #000",
                        padding: "10px",
                        width: "30%",
                        textAlign: "left",
                      }}
                    >
                      <img src="images/logo-pdf.png" style={{ height: "50px" }} alt="" />
                    </td>

                    <td
                      style={{
                        padding: "10px",
                        width: "50%",
                        textAlign: "right",
                        color: "#333",
                      }}
                    >
                      <img
                        src={LoadServerImage(docketpdfdata.lpImg)}
                        alt=""
                        style={{ height: "50px" }}
                      />{" "}
                      <br />
                      <b>Order ID</b>: {docketpdfdata.orderid}
                    </td>
                  </tr>
                  <tr style={{ verticalAlign: "top" }}>
                    <td
                      colSpan={2}
                      style={{
                        border: "2px solid #000",
                        borderLeft: "0px",
                        padding: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "22px",
                      }}
                    >
                      <b style={{ fontSize: "14px" }}>Master:</b>{" "}
                      {docketpdfdata.orderid}
                      <br />
                      {/* <b style={{ fontSize: "14px" }}>LRN:</b> {docketpdfdata.orderId} */}
                      <b style={{ fontSize: "14px" }}>Docket Number:</b>{" "}
                      {docketpdfdata.mvikasDocketNumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      <QRCode value={docketpdfdata.itemId} size={100} />
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ borderTop: "2px solid #000", padding: "0" }}
                    >
                      <table style={{ width: "100%", borderSpacing: "0px" }}>
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              fontSize: "16px",
                            }}
                          >
                            <b>Box: {docketpdfdata.boxes} </b>
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Child Number</b>
                            <br />
                            {docketpdfdata.itemId}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Length</b>
                            <br />
                            {docketpdfdata.length}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Breadth</b>
                            <br />
                            {docketpdfdata.breadth}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "0px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Height</b>
                            <br />
                            {docketpdfdata.height}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ borderTop: "2px solid #000", padding: "0" }}
                    >
                      <table style={{ width: "100%", borderSpacing: "0px" }}>
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Item Name</b>
                            <br />
                            {docketpdfdata.itemName}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Item Type</b>
                            <br />
                            {docketpdfdata.itemType}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Item Category</b>
                            <br />
                            {docketpdfdata.itemCategory}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "2px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Item Subcategory</b>
                            <br />
                            {docketpdfdata.itemSubcategory}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderRight: "0px solid #000",
                              color: "#333",
                            }}
                          >
                            <b>Packaging Type</b>
                            <br />
                            {docketpdfdata.packagingRequired}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr style={{ verticalAlign: "top" }}>
                    <td
                      colSpan="2"
                      style={{
                        borderTop: "2px solid #000",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      <b>Pickup address:</b>
                      <br />
                      {docketpdfdata.pickupAddress}
                    </td>
                  </tr>

                  <tr style={{ verticalAlign: "top" }}>
                    <td
                      colSpan="2"
                      style={{
                        borderTop: "2px solid #000",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      <b>Delivery address:</b>
                      <br />
                      {docketpdfdata.deliveryAddress}
                    </td>
                  </tr>

                  <tr style={{ verticalAlign: "top" }}>
                    <td
                      colSpan="2"
                      style={{
                        borderTop: "2px solid #000",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      <b>Return address:</b>
                      <br />
                      {docketpdfdata.returnAddress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )
      }
      {
        downloadLink && (
          <a href={downloadLink}
            download="QR_Code.png"
            style={{ display: "none" }}
          />
        )
      }
    </div >
  );
}
