import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import Navbar from "./Navbar";
import Support from "./Support";
import SectionOne from "./SectionOne";
import Footer from "./Footer";
import Choose from "./Choose";
import About from "./About";
import ServiceSection from "./ServiceSection";
import OurServices from "./OurServices";
import InnovativeSolution from "./InnovativeSolution";
import Location from "./Location";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast, ToastContainer } from "react-toastify";
import "./My.css";
import { CurrentUrl } from "../Services/UrlApi";
import TeamPage from "./TeamPage";
import Blogs from "./Blogs";
import TeamAdvisor from "./TeamAdvisor";
let Url = `${CurrentUrl}Orders/`;
const Home = () => {
  const navigate = useNavigate();
  const [trackorder, setTrackorder] = useState({});
  const [orderkey, setOrderkey] = useState("");
  const [trackitemstatus, settrackitemstatus] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog !== null) {
      navigate("/Dashboard");
    }
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    settrackitemstatus([]);
    setSelectedItemId(null);
    setOpen(false);
  };
  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };
  const handleTrackOrder = async () => {
    const token = localStorage.getItem("Logkey");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(
      `${Url}GetOrderedItemStatuss?orderId=${orderkey}`, requestOptions
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    setSelectedItemId(null);
    if (!trackorder.orderItems || trackorder.orderItems.length === 0) {
      toast("No record found");
      settrackitemstatus([]);
    } else {
      settrackitemstatus([]);
      setOpen(true);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleTrackOrder();
    }
  };
  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
    handleTrackOrderItem(itemId);
  };
  const handleTrackOrderItem = async (itemId) => {
    const token = localStorage.getItem("Logkey");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(
      `${Url}TrackingOrderedItemStatuss?ItemId=${itemId}`, requestOptions
    );
    const itemresponse = await response.json();
    settrackitemstatus(itemresponse);
    if (!itemresponse.status || itemresponse.status.length === 0) {
      toast("No record found");
    } else {
      setOpen(true);
    }
  };
  const formatEta = (eta) => {
    let dateParts;
    if (eta.includes('-')) {
      // Format: DD-MM-YYYY
      dateParts = eta.split(/[- :]/);
    } else {
      // Format: MM/DD/YYYY
      dateParts = eta.split(/[\/ :]/);
      // Swap day and month positions
      [dateParts[0], dateParts[1]] = [dateParts[1], dateParts[0]];
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
    const year = parseInt(dateParts[2], 10);

    // Create a Date object with only date (no time)
    const date = new Date(year, month, day);

    // Format the date as desired
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <Navbar />
      <Banner sectionId="home" />
      <Support />
      <ServiceSection />
      <About sectionId="about-us" />
      <SectionOne />
      <OurServices sectionId="our-services" />
      <InnovativeSolution />
      <TeamAdvisor />
      <TeamPage sectionId="team" />
      <Choose />
      {/* <SectionTwo /> */}
      {/* <Contact sectionId="location" /> */}
      {/* <SliderBrands /> */}
      <Location sectionId="contact-us" />
      <Blogs sectionId="blogs" />
      <Footer />
      <Button
        className="track-order"
        variant="contained"
        onClick={handleClickOpen}
      >
        Track Your Order
      </Button>
      <ToastContainer />


      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <div className="track-title">
          <h2>Track Order</h2>
          <span>Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket</span>
          <CancelOutlinedIcon className="close-icon" sx={{ fontSize: 10 }} onClick={handleClose} />
        </div>
        <DialogContent className="dialogcontent-ui" style={{ minWidth: "600px" }}>
          <div className="track-input">
            <input
              required
              value={orderkey}
              onChange={handleorderChange}
              onKeyPress={handleEnterKeyPress}
              placeholder="eg MV-896"
            />
            <button onClick={handleTrackOrder}>
              Track
            </button>
          </div>



          <Box >
            <div className="order-info">
              <div className="row order-head">
                {trackorder.orderId && (
                  <div className="col-sm-6">
                    <h4>Order ID: {trackorder.orderId}</h4>
                    <span >Total Box: {trackorder.noOfBoxes}</span>
                    <span>Booked on : {formatEta(trackorder.bookedAt)}</span>
                  </div>
                )}
                {trackorder.eta && (
                  <div className="col-sm-6">
                    <h4 className="text-right">Current status : {trackorder.status}</h4>
                    <span className="text-right">Last Updated : {formatEta(trackorder.statusDate)}</span>
                    <span className="text-right">ETA : {formatEta(trackorder.eta)}</span>
                  </div>
                )}
              </div>
              <div className="order-tabs">
                <ul className="">
                  {trackorder?.orderItems && trackorder.orderItems.map((status, index) => (
                    <li
                      className={selectedItemId === status.itemId ? 'selected-item' : ''}
                      key={status.itemId}
                      onClick={() => handleItemClick(status.itemId)}
                    >{status.itemId}</li>

                  ))}
                </ul>
              </div>
            </div>


            <ul className={`order-status-ui mt-0 ${trackitemstatus.status ? '' : 'hide-box'}`}>
              {trackitemstatus.status && trackitemstatus.status.map((item, statusIndex) => (
                <li key={statusIndex}
                  className={`outside-li ${item.orderItemRemarks.length === 0 ? 'inactive' : 'active'}`}
                >
                  <div className="status-head">
                    <img src={`${process.env.PUBLIC_URL}/images/${item.orderItemStatus.toLowerCase()}.png`}
                      alt="" />

                    <div className="text">
                      <h4>Your order is {item.orderItemStatus}</h4>
                      <span>{item.orderStatusDate}</span>
                    </div>
                  </div>

                  {item.orderItemRemarks && item.orderItemRemarks.length > 0 && (
                    <>
                      <div className="remark">Remarks</div>
                      <ol className="remarks-ui">
                        {item.orderItemRemarks.map((remark, remarkIndex) => (
                          <li key={remarkIndex}>{remark.remarks} - {remark.statusDate} </li>
                        ))}
                      </ol>
                    </>
                  )}
                </li>
              ))}
            </ul>

          </Box>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;