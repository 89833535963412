import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import VendorSales from "./vendorsales";
import Footer from "./Footer";
const SalesReport = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <VendorSales />
      <Footer />
    </>
  );
};

export default SalesReport;
