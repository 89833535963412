import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Getratebyservice, Getcourierratebyservice } from "../Services/RateApi";
import { Getserviceshavingrate, Getserviceshavingb2crate } from "../Services/ServiceApi";
import Loading from "./Loading";
import DynamicTable from "./DynamicTable";
import { GetUnitTypes, GetAdditionalMastersData } from "../../src/Services/RateApi";

const ViewUploadedCourierRate = () => {
  const [servicenm, setServicenm] = useState("");
  const [inputdata, setInputdata] = useState("");
  const [data, setData] = useState("");
  const [rates, setRates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState("");
  const [tabldata, setTabledata] = useState("");
  let handlingchargesHead = ["from", "to", "unit", "rate"];
  const [handlingchg, setHandlingchg] = useState([]);
  const [additionalchg, setAdditionalchg] = useState("");
  const [additionalchgData, setAdditionalchgData] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [masterdata, setMasterdata] = useState([]);
  const [noRecords, setNoRecords] = useState(false);

  useEffect(() => {
    let vendoridforservices = localStorage.getItem("user_id")
    Getserviceshavingb2crate(vendoridforservices).then((res) => {
      setServicenm(res);
      setLoading(false);
    });
    GetUnitTypes().then((res) => {
      setUnitOptions(res.data);
    });

    GetAdditionalCharges();
  }, []);
  const GetAdditionalCharges = (e) => {
    GetAdditionalMastersData().then((res) => {
      setMasterdata(res.data);
    });
  };
  const getUnitTypeLabel = (unitTypeValue) => {
    const unitTypetobind = unitOptions.find((unit) => unit.id === parseInt(unitTypeValue));
    return unitTypetobind ? unitTypetobind.name : "";
  };
  const renderPreviewOperator = (unitType) => {
    const selectedUnit = unitOptions.find((x) => x.id === parseInt(unitType, 10));
    return selectedUnit ? selectedUnit.name : "";
  };
  const renderPercentageText = (calculationSteps) => {
    console.log('Calculation Steps:', calculationSteps);
    if (!calculationSteps || calculationSteps.length === 0) {
      return '';
    }
    let placeholderText = '';

    calculationSteps.forEach((step, index) => {
      console.log('Step:', step);
      if (index !== 0) {
        if (step.previousOperator === '1') {
          placeholderText += ' + ';
        } else if (step.previousOperator === '2') {
          placeholderText += ' x ';
        } else if (step.previousOperator === '3') {
          placeholderText += ' % ';
        } else if (step.previousOperator === '4') {
          placeholderText += ' - ';
        } else if (step.previousOperator === '5') {
          placeholderText += ' / ';
        }
      }

      if (step.leftSideSelection) {
        console.log('Left Side Selection:', step.leftSideSelection);
        placeholderText += getNameById(step.leftSideSelection);
      } else {
        console.log('Left Side Value:', step.leftSideValue);
        placeholderText += step.leftSideValue || '';
      }
      if (step.operator === '1') {
        placeholderText += ' + ';
      } else if (step.operator === '2') {
        placeholderText += ' x ';
      } else if (step.operator === '3') {
        placeholderText += ' % ';
      } else if (step.operator === '4') {
        placeholderText += ' - ';
      } else if (step.operator === '5') {
        placeholderText += ' / ';
      }

      if (step.rightSideSelection) {
        console.log('Right Side Selection:', step.rightSideSelection);
        placeholderText += getNameById(step.rightSideSelection);
      } else {
        console.log('Right Side Value:', step.rightSideValue);
        placeholderText += step.rightSideValue || '';
      }
    });

    console.log('Placeholder Text:', placeholderText);
    return placeholderText;
  };
  const getNameById = (id) => {
    const item = masterdata.find((item) => item.id === id);
    return item ? item.name : null;
  };
  const inputEvent = (e) => {
    setLoading(true);
    setInputdata(e.target.value);
    let vendorid = localStorage.getItem("user_id");
    Getcourierratebyservice(e.target.value, vendorid).then((res) => {
      setData(res.data);
      setRates(res.data.b2CRates);
      setHandlingchg(res.data.handlingcharges);
      setAdditionalchg(res.data.additionalcharges);
      if (res.data.additionalchargesData) {
        setAdditionalchgData(JSON.parse(res.data.additionalchargesData));
      }
      if (!res.data.rates && !res.data.additionalcharges && !res.data.additionalchargesData) {
        setNoRecords(true);
      } else {
        setNoRecords(false);
      }
      if (res.data.rates) {
        let list = [];
        let listlen = 1;
        for (var i in res.data.rates) {
          listlen++;
          list.push(res.data.rates[i].zoneidto);
          if (listlen > Math.sqrt(res.data.rates.length)) break;
        }
        Bindtablecolumn(list);
        Bindtabledata(list, res.data.rates);
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching rates:", error);
      setLoading(false);
    });
  };

  const Bindtablecolumn = (list) => {
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  const renderAdditionalCharges = () => {
    if (additionalchg) {
      const additionalChargesKeys = {
        min_Weight: "Min Chargable Wt.",
        max_Weight: "Max Allowed Wt.",
        min_ODA: "Min ODA1",
        min_ODA2: "Min ODA2",
        actual_ODA: "ODA1 Per/Kg",
        actual_ODA2: "ODA2 Per/Kg",
        min_Freight: "Min Freight",
        cod: "COD",
        min_FOV: "Min FOV",
        docket_Charge: "Docket Charge",
        actual_FOV: "FOV Percentage",
        minGreenTax: "Min Green Tax",
        fsc: "FSC Percentage",
        greenTaxperkg: "Green Tax Per/Kg",
        gst: "GST"
      };
      return Object.entries(additionalChargesKeys).map(([key, label]) => (
        <div className="col-6" key={key}>
          <span>{label} : </span>
          <span>&#8377;{additionalchg[key]}</span>
        </div>
      ));
    }
    else if (additionalchgData.length > 0) {
      return additionalchgData.map((item, index) => {
        const { name, masterData } = item;
        const { calculationField } = masterData;
        const renderField = (field) => {

          if (field.percentageValueData && field.percentageValueData.labelText) {
            return (
              <div className="width-100">
                <div className="row">
                  {masterData.minValue ? (
                    <div className="form-group col-sm-6">
                      <div class="input-group mr-sm-2 input-group-ui">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            {masterData.minValueData
                              .unitType == 1
                              ? "%"
                              : "$N"}
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder={`Minimum ${masterData.minValueData.label}`}
                        // value={
                        //   masterData.minValueData
                        //     .minValue
                        // }
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="width-100">

                    <div className="form-group">
                      <div class="input-group mr-sm-2 input-group-ui">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            {renderPreviewOperator(field.percentageValueData.unitType)}
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          disabled={field.percentageValueData.isdependent ? true : false}
                          placeholder={renderPercentageText(field.percentageValueData.calculationSteps)}
                        // value={field.percentageValueData.amount}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            );
          } else if (field.unitValueData && field.unitValueData.labelText) {

            const unitTypeLabel = getUnitTypeLabel(field.unitValueData.unitType);
            return (
              <div className="row">
                {masterData.minValue ? (
                  <div className="form-group col-sm-6">
                    <div class="input-group mr-sm-2 input-group-ui">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          {masterData.minValueData
                            .unitType == 1
                            ? "%"
                            : "$N"}
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        placeholder={`Minimum ${masterData.minValueData.label}`}
                      // value={
                      //   masterData.minValueData
                      //     .minValue
                      // }
                      />
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <div class="input-group mr-sm-2 input-group-ui">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        {unitTypeLabel}
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder={field.unitValueData.placeholderText}
                      value={field.unitValueData.unitAmount}
                    />
                  </div>
                </div>
              </div>
            );
          } else if (field.slabValueData && field.slabValueData.labelText) {
            return (
              <React.Fragment key={index}>
                {field.slabValueData.calculationSteps.map((step, idx) => (
                  <div className="row" key={idx}>
                    <div className="row">
                      {masterData.minValue ? (
                        <div className="form-group col-sm-6">
                          <div class="input-group mr-sm-2 input-group-ui">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                {masterData.minValueData
                                  .unitType == 1
                                  ? "%"
                                  : "$N"}
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              disabled
                              placeholder={`Minimum ${masterData.minValueData.label}`}
                            // value={
                            //   masterData.minValueData
                            //     .minValue
                            // }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">From</div>
                          </div>
                          <input type="text" className="form-control" value={step.from} readOnly />
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">To</div>
                          </div>
                          <input type="text" className="form-control" value={step.to} readOnly />
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <select
                          className="form-select"
                          name="unitType"
                          disabled
                          value={step.unitType}
                        >
                          {unitOptions.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-sm-6">
                        <div className="input-group mr-sm-2 input-group-ui">
                          <div className="input-group-prepend">
                            <div className="input-group-text">₹</div>
                          </div>
                          <input type="text" className="form-control" value={step.price} readOnly />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>

            );
          }
          return null;
        };

        return (
          <div className="col-12" key={index}>
            <h6>{name}</h6>
            {renderField(calculationField)}
          </div>
        );
      });
    }
    return null;
  };
  
  if (loading) return <Loading />;
  else {
    return (
      <div>
        <div className="content-wrapper">
          <div className="container  px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 mt-3">
              <h3>View Uploaded Rate</h3>
              <div>
                <Link to="/uploadcourierrate" className="text-white btn btn-info">
                  Upload Rate
                </Link>
              </div>
            </div>
            <div
              className="row"
            >
              <div className="col-md-6 mb-4">
                <span>
                  Select Service Name<span style={{ color: "red" }}>*</span>
                </span>
                <select
                  className="form-control"
                  onChange={inputEvent}
                  name="inputdata"
                  value={inputdata}
                >
                  <option selected hidden>
                    Select
                  </option>
                  {!(servicenm.length > 0)
                    ? ""
                    : servicenm.map((val, i) => {
                      return (
                        <option key={i} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {noRecords ? (
              <div className="alert alert-info" role="alert">
                No records found
              </div>
            ) : (
              <>
                {columns.length > 0 && (
                  <>
                    <h5 className="py-2 mb-0 px-2 text-white" style={{ backgroundColor: "#3f474e" }}>
                      Uploaded Rates
                    </h5>
                    <div className="table-responsive">
                      <table className="table text-nowrap">
                        <thead>
                          <tr>
                            <th>FROM / TO</th>
                            {columns.map((col, index) => (
                              <th key={index}>{col}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>{tabldata}</tbody>
                      </table>
                    </div>
                  </>
                )}
              </>
            )}


            <div className="row justify-content-between" style={{ paddingBottom: "7px" }}>
              {additionalchgData && additionalchgData.length > 0 && (
                <div className="col-md-6 shadow-lg">
                  <h5 className="py-2 mb-0 px-2 text-white mt-4" style={{ backgroundColor: "#3f474e" }}>
                    Additional Charges
                  </h5>
                  <div className="row p-3">{renderAdditionalCharges()}</div>
                </div>
              )}
              {handlingchg.length > 0 && (
                <div className="col-md-6 shadow-lg">
                  <h5
                    className="py-2 mb-0 px-2 text-white mt-4"
                    style={{ backgroundColor: "#3f474e" }}
                  >
                    Handling Charges
                  </h5>
                  <DynamicTable
                    // headers={Object.keys(handlingchg[0])} 
                    headers={handlingchargesHead}
                    data={handlingchg} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ViewUploadedCourierRate;
