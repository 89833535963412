import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BulkOrders from "./BulkOrders";
import Footer from "./Footer";
import BulkOrdersCustomerB2C from "./BulkOrdersCustomerB2C";
const UploadBulkOrdersCustomerB2C = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <BulkOrdersCustomerB2C />
      <Footer />
    </>
  );
};

export default UploadBulkOrdersCustomerB2C;
