import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
const HomeAdmin = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Dashboard />
      <Footer />
    </>
  );
};

export default HomeAdmin;
