import React, { useState, useEffect, useCallback } from "react";
import AddAddress from "./AddAddress";
import Loading from "./Loading";
import { GetAddress } from "../Services/UserApi";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ManageAddress = () => {
  useEffect(() => {
    setLoading(true);
    Bindtable();
  }, []);

  const Bindtable = () => {
    GetAddress().then((res) => {
      setLoading(false);
      setRowData(res.data);
      setColumnName([
        { field: "name", title: "Name" },
        { field: "phone", title: "Mobile no" },
        { field: "city", title: "City" },
        { field: "state", title: "State" },
        { field: "pincode", title: "Poncode" },
        { field: "full_address", title: "Full Address" },
      ]);
    });
  };

  const refreshTable = useCallback(() => {
    Bindtable();
  }, []);
  const [loading, setLoading] = useState(false);
  const [columnname, setColumnName] = useState([]);
  const [rowdata, setRowData] = useState([]);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="content-wrapper">
        <div>
          <div
            style={{ display: "flex", justifyContent: "end", padding: "15px" }}
          >
            <AddAddress refreshTable={refreshTable} />
          </div>
          <div className="p-3">
            <MaterialTable
              title="Address"
              icons={tableIcons}
              columns={columnname}
              data={rowdata}
              options={{
                exportButton: true,
                columnsButton: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default ManageAddress;
