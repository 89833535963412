import React, { useEffect, useState } from "react";
import { GetOrderdata } from "../Services/OrderApi";
import { Typography } from "@mui/material";

const Modalbreakpoint = ({ data, orderid }) => {
  const [allorder, setAllorder] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "500px",
    p: 4,
    overflowY: "scroll",
  };

  useEffect(() => {
    setAllorder("");
    GetOrderdata(localStorage.getItem("user_id"), data).then((res) => {
      const orderdata = res.data.filter((val) => {
        return val.order_id === orderid;
      });
      //console.log(orderdata);
      setAllorder(
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {orderid === "" ? (
            ""
          ) : (
            <>
              <div
                className="row justify-content-between"
                style={{
                  borderBottom: "2px solid #dee2e6",
                  paddingBottom: "7px",
                }}
              >
                <div
                  className="col-6"
                  style={{
                    borderTop: "1px solid grey",
                    borderBottom: "1px solid grey",
                    padding: "5px",
                  }}
                >
                  <span className="UpdateRateSpan">Charges </span>
                </div>{" "}
                <div
                  className="col-6 text-end"
                  style={{
                    borderTop: "1px solid grey",
                    borderBottom: "1px solid grey",
                    padding: "5px",
                  }}
                >
                  <span className="UpdateRateSpan">(&#8377;) Amount</span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">Rate : </span>
                </div>{" "}
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">{orderdata[0].rate}</span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">Chargeable Weight :</span>
                </div>{" "}
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {orderdata[0].chargable_weight}
                  </span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">Docket Charge : </span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].docketcharge}{" "}
                  </span>
                </div>{" "}
                <div className="col-6">
                  <span className="UpdateRateSpan">
                    FOV Charges {` (${orderdata[0].fovPercentage}%)`} :
                  </span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {`${orderdata[0].fov_amount}`}
                  </span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">
                    FSC Amount {` (${orderdata[0].fsc_percent}%)`} :
                  </span>
                </div>{" "}
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {`${orderdata[0].fsc_amount}`}
                  </span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">Green Tax :</span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {`${orderdata[0].greentax_amount}`}
                  </span>
                </div>
                <div className="col-6">
                  <span className="UpdateRateSpan">ODA : </span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].oda_amount}{" "}
                  </span>
                </div>
                <div className="col-6 ">
                  <span className="UpdateRateSpan">Handling Charges :</span>
                </div>{" "}
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].handlingCharge}{" "}
                  </span>
                </div>
                {orderdata[0].isCod ? (
                  <>
                    <div className="col-6">
                      <span className="UpdateRateSpan">COD Amount : </span>
                    </div>
                    <div className="col-6 text-end">
                      <span className="UpdateRateSpan">
                        {" "}
                        {orderdata[0].codAmount}{" "}
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="col-6">
                  <span className="UpdateRateSpan">Taxable Amount : </span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].taxableAmount}{" "}
                  </span>
                </div>{" "}
                <div className="col-6">
                  <span className="UpdateRateSpan">
                    GST Amount {` (${orderdata[0].gst}%)`} :{" "}
                  </span>
                </div>
                <div className="col-6 text-end">
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].gst_Amount}{" "}
                  </span>
                </div>{" "}
                <div
                  className="col-6"
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    marginTop: "8px",
                    padding: "5px",
                  }}
                >
                  <span className="UpdateRateSpan">Total Amount : </span>
                </div>
                <div
                  className="col-6 text-end"
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    marginTop: "8px",
                    padding: "5px",
                  }}
                >
                  <span className="UpdateRateSpan">
                    {" "}
                    {orderdata[0].totalAmount}{" "}
                  </span>
                </div>
              </div>
            </>
          )}
        </Typography>
      );
    });
  }, []);
  return <div>{allorder} </div>;
};

export default Modalbreakpoint;
