import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import UserDetail from "../Components/UserDetail";
import Header from "./Header";

const UserDetailAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header />
      <Sidebar />
      <UserDetail />
      {/* <Footer/>      */}
    </>
  );
};

export default UserDetailAdmin;
