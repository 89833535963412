import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Notification from "./Template/Notification";
import Footer from "./Footer";
const Notifications = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Notification />
      <Footer />
    </>
  );
};

export default Notifications;
