import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import VSetting from "./vsetting";
import Footer from "./Footer";
const VenderSetting = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <VSetting />
      <Footer />
    </>
  );
};

export default VenderSetting;
