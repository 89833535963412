import * as React from "react";
import Card from "@mui/material/Card";
import { toast, ToastContainer } from "react-toastify";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Box } from "@mui/system";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import {
  Deactivateuser,
  activateuser,
  GetVendorList
} from "../Services/AdminApi";
import { CurrentUrl } from "../Services/UrlApi";
import { GetAdminRole } from "../../src/Services/AdminApi";
let Url = `${CurrentUrl}Admin/`;

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}


export default function CustomersDetails() {
  const [rows, setVenders] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const Refreshafterdelete = (emailVal) => {
    GetVendorList(emailVal)
      .then((result) => {
        result.data.sort((a, b) => new Date(b.inserttime) - new Date(a.inserttime));
        setVenders(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };


  const [roleaccessfeature, setroleaccessfeature] = useState({});
  useEffect(() => {
    const roleid = localStorage.getItem("roleid");
    GetAdminRole(roleid).then((response) => {
      setroleaccessfeature(response.data);
      });
  }, []);

  useEffect(() => {
   }, [roleaccessfeature]);

  const Delete = async (emailVal) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${Url}DeleteUserVendor?email=${emailVal}`, {

      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
        // body: JSON.stringify({ email: email }),
        //  body: JSON.stringify(email),
      },
    })
      .then((res) => {
        Refreshafterdelete(emailVal);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };


  const VendorSwitch = ({ email, isActive, activateuser, Deactivateuser }) => {
    const [switchValue, setSwitchValue] = useState(isActive);

    const handleChange = (e) => {
      if (e.target.checked) {
        activateuser(email)
          .then(() => {
            setSwitchValue(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        Deactivateuser(email)
          .then(() => {
            setSwitchValue(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    return (
      <Switch checked={switchValue} onChange={handleChange} />
    );
  };

  const columns = [
    // { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name/Trade Name", flex: 1 },
    { field: "inserttime", headerName: "Date", type: "date", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "isactive",
      flex: 1,
      headerName: "Active / Deactive",
      sortable: false,
      flex: 1,
      renderCell: (params, index) => {
        return (
          // <Switch
          //   checked={params.value}
          //   onChange={(e) => {
          //     // debugger;
          //     let email = params.row.email;
          //     if (e.target.checked) {
          //       activateuser(email);
          //     } else {
          //       Deactivateuser(email);
          //     }
          //     setVenders(rows.map(ee=>{
          //       if(params.id==ee.id)
          //         ee['active']=!ee.active;
          //       return ee;
          //     }));
          //     console.log(rows);
          //   }}
          // />
          <VendorSwitch
            email={params.row.email}
            isActive={params.value}
            activateuser={activateuser}
            Deactivateuser={Deactivateuser}
          />
        );
      },
    },
    {
      field: "action",
      width: 150,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const role = localStorage.getItem("user_role").split(",");
        return (
          <Box className="d-flex">
            {role.includes("Admin") ? (
              <>
                <Button href={'/#/vsetting?email=' + params.row.email}>
                  <Avatar sx={{ width: 40, height: 40, bgcolor: grey[300] }}>
                    <DriveFileRenameOutlineTwoToneIcon
                      fontSize="small"
                      sx={{ color: grey[900] }}
                    />
                  </Avatar>
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              // onClick={() => {
              //   Delete(params.row.email);
              // }}
              onClick={() => deleteClickOpen(params.row.email)}
            >
              <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
  ];

  const [deleteopen, setOpenDelete] = useState(false);
  const [vendortodelete, setVendorToDelete] = useState(null);
  const deleteClickOpen = (orderId) => {
    setVendorToDelete(orderId);
    setOpenDelete(true);
  };

  const deleteClose = () => {
    setVendorToDelete(null);
    setOpenDelete(false);
  };

  const handleDeleteConfirmation = async () => {
    if (vendortodelete) {
      try {
        const response = await Delete(vendortodelete);
        if (response.status === 200) {
          toast.success('Order Deleted Successfully');
          Refreshafterdelete();
          deleteClose(); } else {
          toast.error('Failed to delete order. Please try again.');
        }
      } catch (error) {
        toast.error('An error occurred while deleting the order.');
        console.error(error);
      }
    }
  };

  if (!roleaccessfeature.vendorAccountActiveDeactive) {
    const activeDeactiveColumnIndex = columns.findIndex(column => column.field === "isactive");
    if (activeDeactiveColumnIndex !== -1) {
      columns.splice(activeDeactiveColumnIndex, 1); 
    }
  }

  if (!roleaccessfeature.vendorAccountAction) {
    const activeDeactiveColumnIndex = columns.findIndex(column => column.field === "action");
    if (activeDeactiveColumnIndex !== -1) {
      columns.splice(activeDeactiveColumnIndex, 1); 
    }
  }

  useEffect(() => {
    loadVenders();
  }, []);

  const loadVenders = () => {
    setVenders([]);
    var params = "";
    // if (search != null && search != undefined && search != "") {
    //   params += `search=${search}&`;
    // }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    // if (fromDate != null && fromDate != undefined && fromDate != "") {
    //   params += `fromDate=${fromDate}&`;
    // }
    // if (toDate != null && toDate != undefined && toDate != "") {
    //   params += `todate=${toDate}`;
    // }

    GetVendorList(params)
      .then((result) => {
        result.data.sort((a, b) => new Date(b.inserttime) - new Date(a.inserttime));
        setVenders(
          result.data
        );
      })
      .catch((r) => {
        console.log(r);
      });

  };

  const filteredData = rows.filter((row) => {

    const isSearchMatch = Object.keys(row).some((key) => {
      const value = row[key];
  
      if (value !== null && value !== undefined) {
        if (typeof value === 'string' || value instanceof String) {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return value.toString().includes(search);
      }
  
      return false;
    });


      const orderDate = parseDate(row.updateDate, 'DD-MM-YYYY');
      let isDateInRange = true;

      if (fromDate) {
        const fromDateTime = new Date(fromDate);
        fromDateTime.setHours(0, 0, 0, 0);
        isDateInRange = isDateInRange && orderDate >= fromDateTime;
      }

      if (toDate) {
        const toDateTime = new Date(toDate);
        toDateTime.setHours(23, 59, 59, 999);
        isDateInRange = isDateInRange && orderDate <= toDateTime;
      }

      return isSearchMatch && isDateInRange;
    })

  
function parseDate(dateString, format) {
  const parts = dateString.split('-').map(Number);
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

  return (

    <>
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Venders List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Venders List</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                required
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value="all"
                  label="Report Type"
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                >

                  <MenuItem
                    value="all">All</MenuItem>
                  <MenuItem value="Activate">Active</MenuItem>
                  <MenuItem value="Deactivate">Deactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            {/* <div className="">
              <IconButton
                onClick={() => {
                  loadVenders();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div> */}
          </Card>

          <Card className="custum-shadow mt-3">
              <DataGrid
                componentsProps={{
                  toolbar: {
                    printOptions: {},
                  },
                }}
                sx={{
                  p: "1rem",
                  "@media print": {
                    // '.MuiDataGrid-cell:nth-child(6)':{display:'none'},
                    // '.MuiDataGrid-columnHeaderTitle:nth-child(6)':{display:'none'},
                    ".table-ui": { border: "none" },
                  },
                }}
                className="table-ui"
                getRowId={(row) => row.id ?? row.invoiceNo}
                rows={filteredData}
                columns={columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                autoHeight={true}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => {
                  setRowsPerPage(newPageSize);
                }}
              />
          </Card>
        </div>
      </section>
    </div>
      <ToastContainer />
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={deleteopen}
        onClose={deleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="delete-alert">
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to delete the order?</p>
              <Button onClick={handleDeleteConfirmation} variant="contained">
              Yes
            </Button>
            <Button onClick={deleteClose} autoFocus variant="outlined" className="ml-2">
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      </>
  );

}
