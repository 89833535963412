import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Listofregistration from "./Listofregistration";
import Footer from "./../Footer";
const UserRegistration = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Listofregistration />
      <Footer />
    </>
  );
};

export default UserRegistration;
