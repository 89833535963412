import React from 'react'

const PaymentSuccessInnner = () => {
  return (
    <div>
      <div>Payment Success</div>
      {/* <Button href="#/payment-successful" className="ml-auto">
        OK
      </Button> */}
    </div>
  )
}

export default PaymentSuccessInnner;