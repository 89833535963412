import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Sidebar from "./Sidebar";
import Header from "./Header";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Card from '@mui/material/Card';
import {
  Getexistingrole,
  Createrole,
  Assignrole,
  GetAllUserDetails,
  Activeuser,
  Revokeuser,
} from "../Services/AdminApi";
import Chip from "@mui/material/Chip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  'Customer Purchase Report',
  'Vender Sales Report',
];

export default function AdminHandler() {
  const [allRoles, SetallRoles] = useState([]);
  const [value, setValue] = React.useState(0);
  const [userData, setUserData] = useState([]);
  let Tablesetdt;
  const [Data, setData] = useState({
    rolename: "",
    email: "",
  });
  const bindallroles = () => {
    Getexistingrole().then((res) => {
      SetallRoles(res.data);
    });
  };
  useEffect(() => {
    bindallroles();
    GetuserData();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDelete = (id, rowid) => {
    if (window.confirm("Do you really want to change the role of this user")) {
      const list = [...userData];
      const deleteddatt = list[rowid]["role"].splice(id, 1);
      setUserData(list);
      Revokeuser(list[rowid]["email"], deleteddatt[0]).then((res) => {
        if (res.status === 200)
          toast.success("This user's role has been changed successfully");
        else toast.error("Technical Eror");
      });
    }
  };
  const checkChange = (e) => {
    if (
      window.confirm("Do you really want to change the status of this user")
    ) {
      const list = [...userData];
      if (e.target.checked) {
        Activeuser(list[e.target.id]["email"]).then((res) => {
          if (res.status === 200)
            toast.success("User has been activated successfully");
          else toast.error("Technical Eror");
        });
        list[e.target.id]["isactive"] = true;
      }
      setUserData(list);
    }
  };
  let headers = [
    "name",
    "contact_person_name",
    "email",
    "mobile_no",
    "isactive",
    "role",
  ];
  const GetuserData = () => {
    GetAllUserDetails().then((res) => {
      setUserData(res.data);
    });
  };
  Tablesetdt = userData.map((row, indx) => {
    return (
      <tr>
        {" "}
        <td>{indx + 1}</td>
        {headers.map((head, hid) => {
          return (
            <>
              {head === "role" ? (
                <td key={hid} style={{ textAlign: "left" }}>
                  {row[head].sort().map((val, i) => {
                    return (
                      <>
                        {val === "User" ? (
                          <Chip
                            value={i}
                            label={val}
                            p={1}
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            value={i}
                            label={val}
                            p={1}
                            variant="outlined"
                            onDelete={() => handleDelete(i, indx)}
                          />
                        )}
                      </>
                    );
                  })}
                </td>
              ) : head === "isactive" ? (
                <td key={hid}>
                  <Checkbox
                    id={indx} // defaultChecked={row[head]}
                    checked={row[head]}
                    name="isactive"
                    size="small"
                    onClick={(e) => checkChange(e)}
                    disabled={row[head]}
                  />
                </td>
              ) : (
                <td key={hid}>{row[head]}</td>
              )}
            </>
          );
        })}
      </tr>
    );
  });
  const Savedata = (e) => {
    if (e.target.id === "CreateRole") {
      if (Data.rolename === "") toast.warn("Please enter role name");
      else {
        Createrole(Data.rolename).then((res, i) => {
          if (res.status === 200) {
            bindallroles();
            setData({ rolename: "", email: "" });
            toast.success("Role created sucessfully");
          } else {
            toast.error("technical error");
          }
        });
      }
    }

    // AssignroleAssignrole

    if (e.target.id === "Assignrole") {
      if (Data.rolename === "" && Data.email === "")
        toast.warn("Please enter role name and Email id");
      else {
        Assignrole(Data).then((res) => {
          if (res.status === 200) {
            setData({ rolename: "", email: "" });
            toast.success("Role has been assigned successfully for this user");
            GetuserData();
          } else {
            toast.error("technical error");
          }
        });
      }
    }
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      {" "}
      <Header />
      <Sidebar />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Role</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Role</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <Card className='custum-shadow'>
              <Box
                sx={{ width: '100%' }}
              >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs TabIndicatorProps={{ style: { background: '#7b3131' } }} textColor="Mahogany" value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Create Role" {...a11yProps(0)} />
                    <Tab label="Assign Role" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="tabPanel-ui">
                  <h4 className='mb-4'>Create Role</h4>
                  <Stack direction="row" spacing={2} mt={2}>
                    <TextField
                      required
                      label="Role Name"
                      value={Data.rolename}
                      onChange={(e) => {
                        setData({ ...Data, rolename: e.target.value.trim() });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {/* <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange1}
                        input={<OutlinedInput label="Roles" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <Button sx={{ px: 4 }} variant="contained" id="CreateRole" onClick={(e) => Savedata(e)} >
                      Submit
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={1} mt={5}>
                    {allRoles.length === 0 ? (
                      ""
                    ) : (
                      <table className="table w-50">
                        <thead>
                          <tr>
                            <td>Sr. No.</td>
                            <td>Role Name</td>
                          </tr>
                        </thead>
                        <tbody>
                          {allRoles.map((val, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{val}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </Stack>{" "}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <h4 className='mb-4'>Assign Role</h4>
                  <Stack direction="row" spacing={2} mt={2} mb={2}>
                    <TextField
                      required
                      label="Email"
                      value={Data.email}
                      onChange={(e) => {
                        setData({ ...Data, email: e.target.value.trim() });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormControl sx={{ width: "120px" }}>
                      <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Data.rolename}
                        label="Roles"
                        onChange={(e) => {
                          setData({ ...Data, rolename: e.target.value.trim() });
                        }}
                      >
                        {allRoles.length === 0
                          ? ""
                          : allRoles.map((val) => {
                            return <MenuItem value={val}>{val}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                    <Button
                      sx={{ px: 4 }}
                      variant="contained"
                      id="Assignrole"
                      onClick={(e) => Savedata(e)}
                    >
                      Submit
                    </Button>
                  </Stack>

                  <div className="table table-responsive">
                    <table className="table table-striped text-nowrap" id="mytable">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>LP Name</th>
                          <th>Contact Name</th>
                          <th>Email</th>
                          <th>Mobile No.</th>
                          <th>Active</th> <th> Assigned Roles</th>
                        </tr>
                      </thead>
                      <tbody>{Tablesetdt}</tbody>
                    </table>
                  </div>
                </TabPanel>
              </Box>
            </Card>
          </div>
        </section>

      </div>

      <ToastContainer />
    </>
  );
}
