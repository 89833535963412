import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import CustomersDetails from "./customersdetails";
import Footer from "./Footer";
const CustomerList = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <CustomersDetails />
      <Footer />
    </>
  );
};

export default CustomerList;
