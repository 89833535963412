import React from 'react';
import './TeamPage.css';


const TeamPage = (prop) => {
    const teamMembers = [
        {
            id: 1,
            name: 'Abhinav Kumar',
            designation: 'CO-FOUNDER/ CEO',
            education: 'B.Com(Hons), Chartered Accountant\n 10+ years in Steel Authority of India & Limited',
            linkedin: 'linkedin.com/in/abhinavkumarmvikas',
            imageUrl: 'team2.jpg',
        },
        {
            id: 2,
            name: 'Aditya Batra',
            designation: 'CO-FOUNDER/COO',
            education: 'Supply chain Expert \n 12+ years in Supply Chain Management\n Masters in Computer Applications',
            linkedin: 'linkedin.com/in/adityabatramvikas',
            imageUrl: 'team3.jpg',
        },
        {
            id: 3,
            name: 'Gunjan Singh',
            designation: 'CO-FOUNDER/CTO',
            education: 'Masters in Computer Applications\n 15+ years in IT Industry',
            linkedin: 'linkedin.com/in/gunjansinghmvikas',
            imageUrl: 'team5.jpg',
        },
        {
            id: 4,
            name: 'Rajat Kumar',
            designation: 'CFO',
            education: 'B.com(Hons),Chartered Accountant \n Certified GST & Member \n 5+ years of postqualification',
            linkedin: 'linkedin.com/in/rajatkumarmvikas',
            imageUrl: 'team1.jpg',
        },
        // {
        //     id: 5,
        //     name: 'Pooja Arora',
        //     designation: 'CMO',
        //     education: 'Masters in English & Economics \n10+ years in Content Specialist \nMedia & Advertising',
        //     linkedin: 'linkedin.com/in/pooja-arora-796550b6',
        //     imageUrl: 'team4.jpeg',
        // },
    ];

    return (
        <div className='team-bg' id={prop.sectionId}>
                <div className="container-fluid team-page">
                    <div className="team-heading">Our Amazing Team</div>
                    <div className="team-tagline">Meet the minds behind our success.</div>
                    <div className="row team-cards">
                        {teamMembers.map(member => (
                            <TeamMemberCard key={member.id} member={member} />
                        ))}
                    </div>
                </div>
        </div>
    );
};

const TeamMemberCard = ({ member }) => {
    return (
        <div className="col">
            <div className='team-card'>
            <div className="team-img">
                {/* <img src={member.imageUrl} alt={member.name} className="member-image" /> */}
                <img src={`/images/${member.imageUrl}`} alt={member.name} className="member-image" />
            </div>
            <div className="card-bottom">
                <div className="member-name">{member.name}</div>
                <div className="member-designation">{member.designation}</div>
                <div className="member-education">{member.education}</div>
                <div className='icon-link'>
                <a href={`https://${member.linkedin}`} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s LinkedIn Profile`}>
                <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                </a>
                {/* <a href={`https://${member.linkedin}`} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-x-twitter"></i>
                </a> */}
                {/* <span className="sr-only">{member.name}'s LinkedIn Profile</span> */}
                </div>
            </div>
            </div>
        </div>
    );
};

export default TeamPage;

