import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Reports from "./Reports";
import Footer from "./Footer";
const UserReports = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Reports />
      <Footer />
    </>
  );
};

export default UserReports;
