import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import BarcodeInner from "./BarcodeInner";
import Footer from "./../Footer";
const Barcode = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <BarcodeInner />
      <Footer />
    </>
  );
};

export default Barcode;
