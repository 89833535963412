import React from "react";
const styles = {
  aboutSection: {
    padding: "70px 0 20px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "#000",
    marginBottom: "10px",
  },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  }
}




const OurServices = (prop) => {
  return (
    <div id={prop.sectionId}>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">
          <div className="row align-items-center">
            <div className="image-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                    <img src="./images/our-services.jpg" alt="our-services" />
                </figure>
              </div>
            </div>
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <div className="h2">Our Services </div>
                </div>
                <div className="text"
                  style={styles.text}>
                  We cater to your dynamic needs with care and precision as one of the leading Logistics Companies in Delhi, India. Keep your fears of late deliveries, bad package conditions, and unreasonable costs at bay with MVIKAS Super Logistics!
                  </div><div className="text"
                  style={styles.text}>
                  With our logistics services, get your products delivered across 29,000+ pin codes and internationally.
                  </div><div className="text"
                  style={styles.text}>
                  Trust us to fulfill your logistics needs with our innovative and holistic approach, whether it be support in product picking, tracking, or any inquiries about the products. MVIKAS Super Logistics - Your Most Trusted Logistics Service Provider Company!


                </div>
              </div>
            </div>
            {/* Image Column */}

          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
