import React from 'react'
import Header from '../Admin/Header'
import CustomiseLabel from './CustomiseLabel'
import Footer from './Footer'
import Sidebar from '../Admin/Sidebar'

const CustomiseLabelParent = () => {
   
    return (
        <>
        <Header/>
        <Sidebar />
        <CustomiseLabel />
        {/* <Footer/> */}
        </>
      )
}

export default CustomiseLabelParent