import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import PaymentConfirmationTemplate from "./PaymentConfirmationTemplate";
import Footer from "./../Footer";
const PaymentConfirmation = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <PaymentConfirmationTemplate />
      <Footer />
    </>
  );
};

export default PaymentConfirmation;
