/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { red } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../Services/UserApi";
import axios from "axios";
import {
  GenerateCurrentUserlogin,
  GetUserLastLogin,
  UserLastLogin,
  getAvailableWalletBalance,
} from "../Services/UserApi";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/Input';
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}User/`;
const Header = (props) => {



  const { submitted } = props;
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const Logoutbtn = () => {
    UserLastLogin(localStorage.getItem("user_email")).then((r) => { });

    Logout().then((res) => {
      window.localStorage.clear();
      // localStorage.removeItem("Logkey");
      // localStorage.removeItem("user_id");
      // localStorage.removeItem("user_email");
      // localStorage.removeItem("user_name");
      // localStorage.removeItem("user_role");
      navigate("/");
    });
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (localStorage.getItem('isLoggedIn') === 'true') {
  //       localStorage.removeItem('Logkey');
  //       localStorage.removeItem('isLoggedIn');
  //     }
  //   };
  //   const handleUnload = (event) => {
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);




  const role = localStorage.getItem("user_role").split(",");

  useEffect(() => {
    // debugger;
    const token = localStorage.getItem("Logkey");
    if (props) {
      const user_id = localStorage.getItem("user_id")
      GenerateCurrentUserlogin(token, user_id).then((r) => {
        setUsername(r);
      });
    }
  }, [props]);

  const [lastLoginTime, setLastLoginTime] = useState("");

  useEffect(() => {
    GetUserLastLogin(localStorage.getItem("user_email")).then((r) => {
      setLastLoginTime(r.data.data);
    });
    GetUserLastLogin();
  }, []);

  const [balanceopen, setbalanceOpen] = useState(false);
  const balanceClickOpen = () => {
    setbalanceOpen(true);
    // getPaymentMethodforWallet();
  };
  const balanceClose = () => {
    setbalanceOpen(false);
    setamountoadd("")
  };


  const [availablewalletbalance, setavailablewalletbalance] = useState("")
  const [availablepaymentproviders, setavailablepaymentproviders] = useState([])
  const [selectedpaymentmethod, setselectedpaymentmethod] = useState("")
  const [amountoadd, setamountoadd] = useState(0)
  const [showProgress, setShowProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getcustomerwalletbalance();
    //getPaymentMethodforWallet();
  }, [])


  const getcustomerwalletbalance = async () => {
    let userid = localStorage.getItem("user_id")
      ; try {
        const result = await getAvailableWalletBalance(userid)
        setavailablewalletbalance(result.balance)
      } catch (error) {
        console.log("Error", error);
      }
  }

  // const getPaymentMethodforWallet = async () => {
  //   try {
  //     const result = await getAvailablePaymentMethodforWallet()
  //     setavailablepaymentproviders(result)
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // }

  const addmoney = async () => {

    const token = localStorage.getItem("Logkey");
    const walletpaymenturl = `${Url}InitializeWalletMoney`
    const walletconfirmpaymenturl =  `${Url}AddWalletMoney`;

    let initialbody = {
      customerId: localStorage.getItem("user_id"),
      transactionAmount: parseInt(amountoadd),
      paymentMethod: 1,
      orderId: "order_id",
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {

      const response = await axios.post(walletpaymenturl, initialbody, { headers });
      const razorPayRequestObj = JSON.parse(response.data.razorPayRequest);
      const paymentMethod = response.data.paymentMethod
      const orderId = razorPayRequestObj.id;
      const options = {
        key: `rzp_live_sIm1xpJqoAao6x`,
        amount: 1000,
        name: "MVLoad",
        image: "",
        order_id: orderId,
        handler: (response) => {
          let body = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            customerId: localStorage.getItem("user_id"),
            paymentMethod: paymentMethod,
          };
          setShowProgress(true);
          axios
            .post(walletconfirmpaymenturl, body, { headers })
            .then((response1) => {
              if (response1.data.code === 200) {
                getcustomerwalletbalance();
                setShowProgress(false);
                setbalanceOpen(false);
                setamountoadd("")
                toast.success(response1.data.message)
              }
              else {
                setShowProgress(false);
                toast.error(response1.data.message)
              }
            })
            .catch((err) => console.log(err));
        },
        prefill: {
          name: "MVLoad",
          email: "testuser@mail.com",
        },
        theme: {
          color: "#5d0000",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

    } catch (error) {
      console.log("Error", error);
    }
  }

  const handlepaymentmethodchange = (e) => {
    setselectedpaymentmethod(e.target.value)
  }

  const handleAmountChange = (e) => {
    const enteredValue = e.target.value;

    if (enteredValue.length === 0) {
      setamountoadd('');
      toast.warning('Enter any amount to add.');
    } else if (enteredValue.length > 7) {
      toast.warning('Enter a maximum of 7 digits.');
    } else {
      setamountoadd(enteredValue);
    }
  };

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };


  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-expand-admin navbar-white navbar-light d-flex justify-content-between">
        {/* Left navbar links */}
        <div className="content-header">
          <div className="left-side">
            <a
              className="pushmenu"
              data-widget="pushmenu"
              href="#"
              role="button"
              id="ToggleAuto"
            >
              <img src="images/menu.svg" alt="" />
            </a>
            <div className="welcome-mess">
              <span>Welcome</span>
              <h1 className="main-title">{username.name}</h1>
            </div>
          </div>
          <div className="right-side ml-auto">
            <p className="text-right mb-0 last-login">
              {" "}
              Your last login {lastLoginTime}
            </p>

            <div className="nav-item d-inline-block d-sm-inline-block">
              <>
                {role.includes("User") ? (
                  <div className="btn-group">
                    <button
                      className="btn dropdown-toggle no-arrow wallet-drop"
                      type="button"
                      //onClick={alert("hello")}
                      id="dropdownWallet"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <AccountBalanceWalletRoundedIcon />
                    </button>
                    <ul
                      className="dropdown-menu wallet-dropdown"
                      aria-labelledby="dropdownWallet"
                    >
                      <li className="balance">
                        <span>Available Balance</span>
                        <h4>₹{availablewalletbalance !== null ? availablewalletbalance : '--'}</h4>

                      </li>
                      <li className="addbalance" onClick={balanceClickOpen}>
                        <AddCircleRoundedIcon fontSize="small" /> Add Balance
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )
                }
              </>
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle no-arrow"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0px" }}
                >
                  <span className="user-img">
                    <img src="images/user.svg" alt="" />
                  </span>{" "}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-Log"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <>
                      {role.includes("User") ? (
                        <Link to="/setting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : role.includes("Vendor") ? (
                        <Link to="/vsetting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : (
                        <Link to="/profile">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      )}
                    </>
                  </li>
                  <li>
                    <span
                      className="dropdown-item dropdown-item-Log"
                      onClick={Logoutbtn}
                    >
                      <i className="fas fa-sign-out-alt"></i> Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
      </nav>

      {showProgress && (
        <div className="circular-progress-ui">
          <CircularProgress />
        </div>
      )}
      <ToastContainer />
      <Dialog
        open={balanceopen}
        onClose={balanceClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent
          style={{ maxWidth: '350px' }}>
          <div className="add-money text-center">
            <h2>Add Balance</h2>
            <p>Enter amount how much you want to add in wallet your account.</p>
            <div className="input-box">
              <Input
                placeholder="₹0.00"
                type="number"
                value={amountoadd}
                onChange={handleAmountChange}
                onKeyPress={handleKeyPress}
                inputProps={{ maxLength: 7, pattern: "[0-9]" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {/* <h5>Please select payment method</h5>

            <FormControl fullWidth className="text-left">
              <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handlepaymentmethodchange}
                value={selectedpaymentmethod}
                label="Payment Method"
              >
                {availablepaymentproviders.map((item, id) =>
                (
                  <MenuItem key={id} value={item.id}>
                    {item.paymentMethod}
                  </MenuItem>
                )
                )}
              </Select>
            </FormControl> */}

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: red[50],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}

            <Button variant="outlined" className="btn-pay" onClick={balanceClose} autoFocus>
              Cancel
            </Button>
            <Button variant="contained" className="btn-pay"
              onClick={addmoney}
              autoFocus>
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>



    </div>
  );
};

export default Header;
