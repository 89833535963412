import React from "react";
const styles = {
  aboutSection: {
    padding: "70px 0 20px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "#000",
    marginBottom: "10px",
  },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  },
}
const ServiceSection = () => {
  return (
    <div>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">

        <div className="row align-items-center">
            <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-0">
              <div className="inner-column">
                <div className="sec-title">
                  <h1 className="heading-h1">Best Logistics Service Provider Company in India</h1>
                </div>
                <div className="text" style={styles.text}>
                  We are your answer for a seamless logistics solution!
                </div>
                <div className="text" style={styles.text}>
                  At MVIKAS Super Logistics, we take pride in our innovative technology and expertise in navigating supply chain complexities with precision and efficiency. As one of the best Logistics Companies in India, we provide excellent services tailored to your needs.
                </div>
                <div className="text" style={styles.text}>
                  Connect to experience the difference with MVIKAS Super Logistics; Your most trusted logistics service provider in India.
                </div>
                <div className="text" style={styles.text}>
                  Let us handle your logistics for you!
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceSection;
