import React from "react";
import Header from "./../Header";
import Sidebar from "./../Sidebar";
import Footer from "./../Footer";
import DailyRequirementTemplate from "./DailyRequirementTemplate";
const DailyRequirement = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <DailyRequirementTemplate />
      <Footer />
    </>
  );
};

export default DailyRequirement;
