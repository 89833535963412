import React from "react";
const styles = {
  aboutSection: {
    padding: "70px 0 20px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "#000",
  },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  }
}




const Choose = () => {
  return (
    <div>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">
          <div className="row align-items-center">

            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <div className="h2">Choose The Best Logistics Service Provider Company in India and Delhi NCR</div>
                </div>
                <div className="text"
                  style={styles.text}>
                  As a leading logistics and freight forwarding services provider, we want to make shipping quick and simple for you across PAN India and around the globe. Our commitment to excellent services as the best logistics service provider company makes us different from other companies.
                </div>
                <div className="text"
                  style={styles.text}>
                  We ensure your shipment is on time and without delay. Experience the best freight forwarding service and timely deliveries with real-time tracking solutions with us!
                </div>
                <div className="sec-title mt-4">
                  <div className="h2">Fulfilling More Than You Expected </div>
                </div>
                <ol className="points">
                  <li>PAN India & International Delivery</li>
                  <li>Easy Tracking</li>
                  <li>No Middleman Costs</li>
                  <li>Quick Order Control</li>
                  <li>Smart Warehousing Solutions</li>
                  <li>Safe Transportation</li>
                  <li>Strict SLAs and TAT</li>
                  <li>Excellent Service Standards</li>
                  <li>Cost Optimisation of Order Fulfillment</li>

                </ol>
              </div>
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12  order-2">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <img src="./images/logistics-service.jpg" alt="about" />
                </figure>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Our Customer Support</div>
                <p>Contact our experts 24/7 for any queries or assistance for a seamless shipping experience. Feel free to dial us at  011-46039993
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Our Assurance</div>
                <p>Experience fast, budget-friendly and hassle-free pickup and drop-off services.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="solution">
                <div className="h4">Network</div>
                <p>Get delivered across 29,000+ pin codes nationwide with our wide logistics network.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Choose;
