import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { green, yellow, red } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import { GetWeightDiscrepancy } from "../Services/AdminApi";
import Stack from "@mui/material/Stack";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import debounce from 'lodash/debounce';
import { CurrentUrl } from "../Services/UrlApi";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';

let Url = `${CurrentUrl}User/`;
function CustomToolbar() {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport
                printOptions={{
                    hideToolbar: true,
                }}
            />
        </GridToolbarContainer>
    );
}
const WeightReconcillation = () => {
    const navigate = useNavigate();
    const [rows, setDiscrepencyHistory] = useState([]);
    const [search, setSearch] = useState("");
    const [fromDate, setfromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [status, setStatus] = useState("");
    const rowsPerPageOptions = [5,10, 20];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [open, setOpen] = React.useState(false);
    const [lptoshowlist, setlptoshowlist] = useState([]);
    const [selectedlptoshow, setselectedlptoshow] = useState("");
    const [userId, setUserId] = useState(0);
    const [selectedLPs, setSelectedLPs] = useState([]);
    const [remarks, setRemarks] = useState({ remark: "" });
    const [remarksClose, setRemarksClose] = useState({ remarksClosedispute: "" });
    const { customerId, order_id, lpid } = useParams();
    const [cardData, setCardData] = useState({});
    const [remarksList, setRemarkList] = useState([]);
    const [remarksListOther, setRemarkListOther] = useState([]);
    const [discrepancyId, setDiscrepancyId] = useState(0);
    const [discrepancyIdinremarks, setDiscrepancyIdinremarks] = useState(0);
    const [disputeId, setdisputeId] = useState("");
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openDisputeDialog, setOpenDisputeDialog] = useState(false);
    useEffect(() => {
        loadDiscrepancyHistory();
    }, []);

    const loadDiscrepancyHistory = () => {
        const userid = localStorage.getItem("user_id");
        setUserId(userid);
        setDiscrepencyHistory([]);
        var params = { "CustomerId": userid, };
        GetCustomerDisputeDiscrepancy(params)
            .then((result) => {
                if (result) {
                    setDiscrepencyHistory(result.discrepancyLists);
                    setCardData(result);
                } else {
                    console.log("Response data is undefined or empty.");
                }
            })
            .catch((r) => {
                console.log(r);
            });
    };
    const GetCustomerDisputeDiscrepancy = async (params) => {
        console.log(`${Url}GetCustomerDisputeDiscrepancy`, params);
        const token = localStorage.getItem("Logkey");
        try {
            const response = await axios.get(`${Url}GetCustomerDisputeDiscrepancy`, {
                params: params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching discrepancy:", error);
            throw error;
        }
    };
    useEffect(() => {
        console.log("Fetched ID from URL:", customerId);
    }, []);

    const debouncedOnChange = debounce((value) => {
        setRemarks({ ...remarks, remark: value });
    }, 300);
    const debouncedOnChangClose = debounce((value) => {
        setRemarksClose({ ...remarksClose, remarksClose: value });
    }, 300);
    const AcceptRemarks = (e) => {
        const userid = localStorage.getItem("user_id");
        if (remarks.remark !== "") {
            AcceptSaveRemarks(remarks).then((res) => {
                console.log(`response990`, res);
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        toast.success("Remarks saved successfully");
                        loadDiscrepancyHistory();
                        setOpenAcceptDialog(false);
                        setRemarks({ remark: "" });
                    } else {
                        toast.error("Something went wrong");
                    }
                } else {
                    toast.error(res.data);
                }
            })
        } else {
            // Handle error or warning
        }
    };

    // const AcceptSaveRemarks = async (remarks) => {
    //     console.log(`discrepancyid`, discrepancyId);
    //     const userid = localStorage.getItem("user_id");
    //     const token = localStorage.getItem("Logkey");
    //     return await axios
    //         .post(
    //             `${Url}AcceptDiscrepancyAndDisputeDiscrepancy`,
    //             JSON.stringify({
    //                 "remarks": remarks['remark'],
    //                 "remarksbyId": userid,
    //                 "isAccept": true,
    //                 "discrepencyId": discrepancyId,
    //                 "disputeId": disputeId ? disputeId : 0
    //             }),
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     "Content-Type": "application/json",
    //                     "Access-Control-Allow-Origin": `*`,
    //                 },
    //             }
    //         )
    //         .then((response) => {
    //             loadDiscrepancyHistory();
    //             return response;
    //         })
    //         .catch((error) => {
    //             return error.response;
    //         });
    // }
    const AcceptSaveRemarks = async (remarks) => {
        console.log(`discrepancyid before API call:`, discrepancyId);
        const userid = localStorage.getItem("user_id");
        const token = localStorage.getItem("Logkey");
        return await axios
            .post(
                `${Url}AcceptDiscrepancyAndDisputeDiscrepancy`,
                JSON.stringify({
                    "remarks": remarks['remark'],
                    "remarksbyId": userid,
                    "isAccept": true,
                    "discrepencyId": discrepancyId,
                    "disputeId": disputeId ? disputeId : 0
                }),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": `*`,
                    },
                }
            )
            .then((response) => {
                console.log('API response:', response);
                loadDiscrepancyHistory();
                return response;
            })
            .catch((error) => {
                console.error('API error:', error);
                return error.response;
            });
    }
    
    const DisputeRemarks = (e) => {
        const userid = localStorage.getItem("user_id");
        console.log(`Dispute remarks`, remarksClose);
        if (remarksClose.remarksClosedispute !== "") {
            DisputeSaveRemarks(remarksClose).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        toast.success("Remarks saved successfully");
                        loadDiscrepancyHistory();
                        setOpenDisputeDialog(false);
                        setRemarksClose({ remarksClosedispute: "" });
                    } else {
                        toast.error("Something went wrong");
                    }
                } else {
                    toast.error(res.data);
                }
            })
        } else {
            // Handle error or warning
        }
    };

    const DisputeSaveRemarks = async (remarks) => {
        console.log(`discrepancyid before API call:`, discrepancyId);
        const userid = localStorage.getItem("user_id");
        const token = localStorage.getItem("Logkey");
        return await axios
            .post(
                `${Url}AcceptDiscrepancyAndDisputeDiscrepancy`,
                JSON.stringify({
                    "remarks": remarks['remarksClosedispute'],
                    "remarksbyId": userid,
                    "isAccept": false,
                    "discrepencyId": discrepancyId,
                    "disputeId": disputeId ? disputeId : 0
                }),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": `*`,
                    },
                }
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    const getdiscrepancyRemarks = (id) => {
        setDiscrepancyId(id)
        setRemarkList([]);
        var params = {
            DiscrepencyId: id,
            DisputeId: 0
        };
        GetDiscrepancyRemarks(params)
            .then((result) => {
                if (result) {
                    const filteredRemarks = result.filter(message => message.userId === userId);
                    setRemarkList(filteredRemarks);
                    const filteredRemarksOther = result.filter(message => message.userId != userId);
                    setRemarkListOther(filteredRemarksOther);
                } else {
                    console.log("Response data is undefined or empty.");
                }
            })
            .catch((r) => {
                console.log(r);
            });
    }

    const GetDiscrepancyRemarks = async (params) => {
        console.log(`${Url}GetCustomerDisputeRemarks`, params);
        const token = localStorage.getItem("Logkey");

        try {
            const response = await axios.get(`${Url}GetCustomerDisputeRemarks`, {
                params: params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching discrepancy:", error);
            throw error; // Re-throwing the error to handle it where the function is called
        }
    };

    const columns = [
        { field: "statusDate", width: 180, headerName: "Status Updated On" },
        {
            field: "orderDetail",
            width: 150,
            headerName: "Order Details",
            renderCell: (params) => {
                const orderDetail = params.row.orderDetail.replace(/\n/g, "<br>");
                return <div dangerouslySetInnerHTML={{ __html: orderDetail }} />;
            },
        },
        {
            field: 'shippingDetails',
            headerName: 'Shipping Details',
            width: 150,
            renderCell: (params) => {
                const shippingDetails = params.row.shippingDetails.replace(/\n/g, "<br>");
                return <div dangerouslySetInnerHTML={{ __html: shippingDetails }} />;
            },
        },
        { field: "appliedWeight", headerName: "Applied Weight", width: 130, },
        { field: "chargedWeight", headerName: "Charged Weight", type: "date", width: 130, },
        { field: "extraWeight", headerName: "Excess Weight", width: 130, },
        { field: "excessCharges", headerName: "Excess Charges", width: 130, },
        {
            field: "disputeStatus",
            width: 180,
            headerName: "Status",
            sortable: false,
            renderCell: (params) => {
                const disputeStatus = params.row.disputeStatus;
                // const role = localStorage.getItem("user_role").split(",");
                return (
                    <Box className="d-flex">
                        <span class="badge badge-danger">{disputeStatus}</span>
                    </Box>
                );
            },
        },
        {
            field: "remark",
            width: 150,
            headerName: "Remark",
            sortable: false,
            renderCell: (params) => {
                // const discrepancyidinremarks = params.row.id;
                // setDiscrepancyIdinremarks(discrepancyidinremarks);
                return (
                    <Button size="small" data-toggle="modal" data-target="#remarkspopup"
                        onClick={() => getdiscrepancyRemarks(params.row.id)}
                        variant="contained">View</Button>
                );
            },
        },
        // {
        //     field: "action",
        //     width: 180,
        //     headerName: "Action",
        //     sortable: false,
        //     renderCell: (params) => {
        //         const disputeStatus = params.row.disputeStatus;
        //         const discrepancyid = params.row.id;
        //         const disputeId = params.row.disputeId;
        //         setDiscrepancyId(discrepancyid);
        //         setdisputeId(disputeId)
        //         if (disputeStatus === "Closed" || disputeStatus === "Discrepancy Accepted" || disputeStatus === "Dispute Rejected By Courier") {
        //             // Return null or empty fragment to hide buttons
        //             return null;
        //         } else {
        //             return (
        //                 <>
        //                     {/* <Button className="mr-1" size="small" data-toggle="modal" data-target="#acceptdiscrepancy" variant="contained">Accept</Button> */}
        //                     <Button className="mr-1" size="small" onClick={() => setOpenAcceptDialog(true)} variant="contained">Accept</Button>
        //                     {/* <Button size="small" data-toggle="modal" data-target="#disputediscrepancy" variant="contained">Dispute</Button> */}
        //                     <Button className="mr-1" size="small" onClick={() => setOpenDisputeDialog(true)} variant="contained">Dispute</Button>
        //                 </>
        //             );
        //         }
        //     },
        // },

        {
            field: "action",
            width: 180,
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                const disputeStatus = params.row.disputeStatus;
                const discrepancyid = params.row.id;
                const disputeId = params.row.disputeId;
                // console.log('Setting discrepancyId:', discrepancyid, 'disputeId:', disputeId);
                // setDiscrepancyId(discrepancyid);
                // setdisputeId(disputeId);
                if (disputeStatus === "Closed" || disputeStatus === "Discrepancy Accepted" || disputeStatus === "Dispute Rejected By Courier"  || disputeStatus === "Dispute Discrepancy") {
                    return null;
                } else {
                    return (
                        <>
                                                     {/* <Button className="mr-1" size="small" data-toggle="modal" data-target="#acceptdiscrepancy" variant="contained">Accept</Button>
                                                     <Button className="mr-1" size="small" onClick={() => setOpenAcceptDialog(true)} variant="contained">Accept</Button>
                            {/* <Button size="small" data-toggle="modal" data-target="#disputediscrepancy" variant="contained">Dispute</Button> */}
                            {/* <Button className="mr-1" size="small" onClick={() => setOpenDisputeDialog(true)} variant="contained">Dispute</Button> */} 
                            <Button className="mr-1" size="small" onClick={() => handleAcceptClick(discrepancyid, disputeId)} variant="contained">Accept</Button>
                            <Button className="mr-1" size="small" onClick={() => handleDisputeClick(discrepancyid, disputeId)} variant="contained">Dispute</Button>
                        </>
                    );
                }
            },
        },


    ];
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        // SearchSalesOrder();
    };
    const handleBlur = () => {
        // SearchSalesOrder();
    };
    const handleStatusChange = (e) => {
        const value = e.target.value;
        setStatus(value);
        // SearchSalesOrder();
    };
    const handleFromDateChange = (e) => {
        // setfromDate(e.toString());
        // SearchSalesOrder();
    };
    const handleCloseDialog = () => {
        setOpenAcceptDialog(false);
        setRemarks({ remark: "" });
    };

    const handleCloseDisputeDialog = () => {
        setOpenDisputeDialog(false);
        setRemarksClose({ remarksClosedispute: "" });
    };

    const handleAcceptClick = (discrepancyid, disputeId) => {
        setDiscrepancyId(discrepancyid);
        setdisputeId(disputeId);
        setOpenAcceptDialog(true);
    };

    const handleDisputeClick = (discrepancyid, disputeId) => {
        setDiscrepancyId(discrepancyid);
        setdisputeId(disputeId);
        setOpenDisputeDialog(true);
    };

    const isReadOnly = customerId === "MV-60091";
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="main-title">Weight Discrepancy</h1>
                                <span className="sub-text">Take action on your pending weight discrepancies. track weight disputes and view history</span>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <span>Home</span>
                                    </li>
                                    <li className="breadcrumb-item active">Customers List</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row mb-3 mx-0">
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Total Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.totalDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Total Closed Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.totalClosedDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>New Discrepancy Count</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.newDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Rejected By Courier</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputeRejectedByCourierCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Pending</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputePendingCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Dispute Discrepancy</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.disputeDiscrepancyCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="small-box card-ui white-cardui">
                            <p>Discrepancy Accepted</p>
                            <div className="d-flex align-items-center">
                                <h3 className="mb-0">{cardData.discrepancyAcceptedCount ?? '--'}</h3>
                                {/* <span className="ml-auto">Last 30 days</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        {/* <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
                            <div className="col">
                                <TextField
                                    fullWidth
                                    id="outlined-required"
                                    label="Search"
                                    value={search}
                                    onChange={handleSearchChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className="col datepicker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="From"
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Report Type"
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="Activate">Active</MenuItem>
                                        <MenuItem value="Deactivate">Deactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Card> */}

                        <Card className="custum-shadow mt-3">
                            <DataGrid
                                componentsProps={{
                                    toolbar: {
                                        printOptions: {},
                                    },
                                }}
                                sx={{
                                    p: "1rem",
                                    "@media print": {
                                        ".table-ui": { border: "none" },
                                    },
                                }}
                                className="table-ui"
                                getRowId={(row) => row.id ?? 0}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                autoHeight={true}
                                rowHeight={75}
                                pagination
                                pageSize={rowsPerPage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onPageSizeChange={(newPageSize) => {
                                    setRowsPerPage(newPageSize);
                                }}
                            />
                        </Card>
                    </div>



                    {/* <Button className="ml-auto" data-toggle="modal" data-target="#acceptdiscrepancy" variant="contained">Accept</Button>
                    <Button className="ml-auto" data-toggle="modal" data-target="#remarks" variant="contained">Accept</Button> */}

                </section>
            </div>

            {/* //Accepted Remarks dialog box */}
            {/* <div className="modal fade" id="acceptdiscrepancy" tabindex="-1" role="dialog" aria-labelledby="acceptdiscrepancyLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5>Accept Remarks</h5>

                            <div class="form-group mt-3">
                                <label>Remark</label>
                                <textarea className="form-control" style={{ minHeight: '120px' }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        debouncedOnChange(value);
                                    }}></textarea>
                            </div>

                            <div className="d-flex">
                                <button type="button" onClick={AcceptRemarks} className="btn btn-submit ml-auto">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Dialog open={openAcceptDialog} onClose={handleCloseDialog} maxWidth="sm"
                fullWidth={true} >
                <DialogTitle>
                    Accept Remarks
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Please enter your remarks.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remark"
                        multiline
                        minRows={8}
                        label="Please enter your remarks."
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={remarks.remark}
                        sx={{ mt: 2 }}
                        InputProps={{
                            sx: {
                                minHeight: '120px',
                                padding: '10px'
                            }
                        }}
                        onChange={(e) => setRemarks({ remark: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={AcceptRemarks} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* //Dispute Remarks dialog box */}
            {/* <div className="modal fade" id="disputediscrepancy" tabindex="-1" role="dialog" aria-labelledby="disputediscrepancyLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5>Dispute Remarks</h5>

                            <div class="form-group mt-3">
                                <label>Remark</label>
                                <textarea className="form-control" style={{ minHeight: '120px' }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        debouncedOnChangClose(value);
                                    }}></textarea>
                            </div>

                            <div className="d-flex">
                                <button type="button" onClick={DisputeRemarks} className="btn btn-submit ml-auto">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Dialog
                open={openDisputeDialog}
                onClose={handleCloseDisputeDialog}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>
                    Dispute Remarks
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDisputeDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Please enter your remarks.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="disputeremark"
                        label="Please enter your remarks."
                        type="text"
                        fullWidth
                        multiline
                        minRows={8}
                        variant="outlined"
                        value={remarksClose.remarksClosedispute}
                        onChange={(e) => setRemarksClose({ remarksClosedispute: e.target.value })}
                        sx={{ mt: 2 }}
                        InputProps={{
                            sx: {
                                minHeight: '120px',
                                padding: '10px'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDisputeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={DisputeRemarks} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="modal fade" id="remarkspopup" tabindex="-1" role="dialog" aria-labelledby="remarksLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <CloseIcon className="close ml-auto" data-dismiss="modal" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2', cursor: 'pointer' }} />
                        <div className="modal-body">
                            <h5 className="mb-3">Remarks</h5>
                            {remarksListOther.map((message, index) => (
                                <div className="chat-data">
                                    <PersonPinIcon />
                                    <div className="text">
                                        <p>{message.remarks}</p>
                                        <div className="bottom-text">
                                            <span>{message.userName}</span>
                                            <span>{message.remarkDate}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>

                                {remarksList.map((message, index) => (
                                    <div className="chat-data right-side" key={index}>
                                        <PersonPinIcon />
                                        <div className="text">
                                            <p>{message.remarks}</p>
                                            <div className="bottom-text">
                                                <span>{message.userName}</span>
                                                <span>{message.remarkDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default WeightReconcillation;
